import React from 'react';
import Autosuggest from 'react-autosuggest';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';

function renderSuggestion(claim) {
  return (
    <MenuItem component="div" id={claim.trackedClaimId}>
      {claim.claim}
    </MenuItem>
  );
}

function getSuggestions(value, claims) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue && inputValue.length;

  let suggestions = [];
  if(inputLength !== 0) {
      claims.forEach(claim => {
        if(suggestions.length < 5 && claim.claim.slice(0, inputLength).toLowerCase() === inputValue) {
          suggestions.push(claim);
        } else {
          return;
        }
      });

      if(suggestions.length < 5) {
        claims.forEach(claim => {
          if(suggestions.length < 5 && claim.claim.slice(0, inputLength).toLowerCase() !== inputValue && claim.claim.includes(inputValue)) {
              suggestions.push(claim);
          } else {
            return;
          }

        });
      }
    }
    return suggestions;
}

function getSuggestionValue(claim) {
  return claim.claim;
}

function ClaimAutosuggest(props) {

  const { claims, classes, handleSetClaim, t } = props;

  const [state, setState] = React.useState({
    single: ''
  });

  const [stateSuggestions, setSuggestions] = React.useState(claims || []);

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value, claims));
  };

  const handleSuggestionSelected = (e) => {
    if(handleSetClaim) {
      handleSetClaim([e.target.id]);
    }
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = name => (event, { newValue }) => {
    setState({
      ...state,
      [name]: newValue,
    });
  };

  const renderInputComponent = (inputProps) => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;
    return (
      <Paper className={classes.inputRoot}>
        <InputBase
          className={classes.inputField}
          inputRef={ref}
          {...other}
        />
      </Paper>
    );
  };


  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    onSuggestionSelected: handleSuggestionSelected,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
      <Autosuggest
        {...autosuggestProps}
        focusInputOnSuggestionClick={false}
        inputProps={{
          classes,
          id: 'react-autosuggest-claim-search',
          placeholder: t('action_form.search_placeholder'),
          value: state.single,
          onChange: handleChange('single'),
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
  );
}


ClaimAutosuggest.propTypes = {
  claims: PropTypes.array.isRequired,
  classes: PropTypes.object,
  handleSetClaim: PropTypes.func,
  t: PropTypes.func
};

export default compose(
  withTranslation(),
  withStyles((theme) => ({
    inputRoot: {
      padding: '2px 4px',
      marginTop: '6px',
      alignItems: 'center',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '1px solid black'
      },
      '&:focus': {
        borderColor: '1px solid ' +theme.palette.primary[500],
        boxShadow: '0 0 0 1px ' + theme.palette.primary[500]
      }
    },
    inputField: {
      marginLeft: 8,
      flex: 1
    },
    root: {
      flexGrow: 1
    },
    container: {
      position: 'relative',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
  }))
)(ClaimAutosuggest);

