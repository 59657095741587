import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
export class VerifiedPage extends React.Component {

  constructor(props){
    super(props);
  }

  render() {
    const {t} = this.props;
    return (
      <div className="row container">
        <div className="col-xs4-4">
          <h4>{t('verified_page.already_verified')}</h4>
        </div>
      </div>
    );
  }
}

VerifiedPage.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(VerifiedPage);
