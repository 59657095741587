import RootPage from 'Components/Pages/RootPage';
import LivePage from 'Components/Pages/LivePage';
import ChannelPage from 'Components/Pages/ChannelPage';
import TranscriptChannelPage from 'Components/Pages/TranscriptChannelPage';
import TranscriptBlockPage from 'Components/Pages/TranscriptBlockPage';
import ClaimPage from 'Components/Pages/ClaimPage';
import ClaimsPage from 'Components/Pages/ClaimsPage';
import LogoutPage from 'Components/Pages/LogoutPage';
import NotFoundPage from 'Components/Pages/NotFoundPage';
import MediaPage from 'Components/Pages/MediaPage';
import UserPage from 'Components/Pages/UserPage';
import AdminPage from 'Components/Pages/AdminPage';
import VerifiedPage from 'Components/Pages/VerifiedPage';
import ChangePasswordPage from 'Components/Pages/ChangePasswordPage';
import ActionsPage from 'Components/Pages/ActionsPage';
import ActionStatsPage from 'Components/Pages/ActionStatsPage';
import ActionPage from 'Components/Pages/ActionPage';
import SearchPage from 'Components/Pages/SearchPage';
import AlertsPage from 'Components/Pages/AlertsPage';
import TranscriptRadioPage from 'Components/Pages/TranscriptRadioPage';
import TranscriptAdhocPage from 'Components/Pages/TranscriptAdhocPage';
import TranscriptYoutubePage from 'Components/Pages/TranscriptYoutubePage';
import ImpactPage from 'Components/Pages/ImpactPage';
import SightingsPage from 'Components/Pages/SightingsPage';
import EntityPage from 'Components/Pages/EntityPage';
import EntitiesPage from 'Components/Pages/EntitiesPage';
import CheckworthyPage from 'Components/Pages/CheckworthyPage';
import SchedulePage from 'Components/Pages/SchedulePage';
import DocsPage from 'Components/Pages/DocsPage';
import TranscriptPodcastPage from 'Components/Pages/TranscriptPodcastPage';

export default (
  [
    { path: '/',
      exact: true,
      page: RootPage,
      name: 'root'
    },
    { path: '/docs',
      exact: true,
      page: DocsPage,
      name: 'docs'
    },
    { path: '/home/:topic',
      exact: true,
      page: CheckworthyPage,
      name: 'checkworthyTopic'
    },
    { path: '/home',
      exact: true,
      page: CheckworthyPage,
      name: 'checkworthy'
    },
    { path: '/search',
      page: SearchPage,
      exact: true,
      name: 'search'
    },
    { path: '/live',
      page: LivePage,
      exact: true,
      name: 'live'
    },
    { path: '/alerts/claims/:claimId',
      page: ClaimPage,
      name: 'claim'
    },
    { path: '/alerts/claims',
      page: ClaimsPage,
      name: 'claims',
      fixedHeight: true
    },
    { path: '/alerts/reviewed',
      page: SightingsPage,
      name: 'sightings',
      fixedHeight: true
    },
    { path: '/alerts',
      page: AlertsPage,
      name: 'alerts'
    },
    { path: '/channel-transcript/:transcriptId',
      page: TranscriptChannelPage,
      name: 'transcript-channel'
    },
    { path: '/block-transcript/:transcriptId',
      page: TranscriptBlockPage,
      name: 'transcript-block'
    },
    { path: '/ondemand-transcript/:transcriptId',
      page: TranscriptAdhocPage,
      name: 'transcript-adhoc'
    },
    { path: '/youtube-transcript/:transcriptId',
      page: TranscriptYoutubePage,
      name: 'transcript-youtube'
    },
    { path: '/radio-transcript/:transcriptId',
      page: TranscriptRadioPage,
      name: 'transcript-radio'
    },
    { path: '/podcast-transcript/:transcriptId',
      page: TranscriptPodcastPage,
      name: 'transcript-podcast'
    },
    { path: '/live/channel/:channelId',
      page: ChannelPage,
      name: 'channel'
    },
    { path: '/live/channel',
      page: ChannelPage,
      name: 'channel'
    },
    { path: '/logout',
      page: LogoutPage,
      name: 'logout'
    },
    { path: '/user',
      page: UserPage,
      name: 'user'
    },
    { path: '/media/:section',
      page: MediaPage,
      name: 'media'
    },
    { path: '/media',
      page: MediaPage,
      name: 'media'
    },
    { path: '/actions/entities/:entityId',
      page: EntityPage,
      exact: true,
      name: 'entities'
    },
    { path: '/actions/entities',
      page: EntitiesPage,
      name: 'entities'
    },
    {
      path: '/actions/stats',
      page: ActionStatsPage,
      name: 'action-stats'
    },
    {
      path: '/actions/:actionId',
      page: ActionPage,
      name: 'action-page'
    },
    {
      path: '/actions',
      exact: true,
      page: ActionsPage,
      name: 'actions',
      fixedHeight: true
    },
    { path: '/admin',
      page: AdminPage,
      name: 'admin'
    },
    { path: '/verify',
      page: VerifiedPage,
      name: 'verify'
    },
    { path: '/change-password',
      page: ChangePasswordPage,
      name: 'change-password'
    },
    { path: '/impact',
      page: ImpactPage,
      name: 'impact'
    },
    { path: '/schedules/:scheduleId',
      page: SchedulePage,
      name: 'schedules'
    },
    {
      page: NotFoundPage,
      name: '404'
    }
  ]
);
