import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LiveMatch from 'Components/Live/LiveMatch';
import Chip from '@material-ui/core/Chip';
import claimTypePriorities from 'Config/default/claimTypePriorities';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Tooltip from '@material-ui/core/Tooltip';
import {default as formattedClaimTypes} from 'Config/default/claimTypes';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

export class TranscriptSentence extends PureComponent {

  constructor(props){
    super(props);

    this.state= {
      showOptions: false,
      hasCopyNotice: false,
      showAllTypes: false,
      anchorEl: null,
      initialLoad: true
    };

  }

  componentDidMount() {
    if(this.props.initialSentenceId === this.props.sentenceId) {
      this.scrollToSentenceId(this.props.initialSentenceId);
    }
  }

  scrollToSentenceId = (id) => {
    const sentenceToScrollTo = document.getElementById(id);
    if(sentenceToScrollTo) {
      let yOffset = -190; 
      if(this.props.hasVideo && window.innerWidth < 960) yOffset = -430;
      const y = sentenceToScrollTo.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    } 
  }

  handleShowSentenceOptions = () => {
    this.setState({
      showOptions: true,
      anchorEl: null
    });
  }

  handleHideSentenceOptions = () => {
    this.setState({
      showOptions: false
    });
  }
  toggleShowAllTypes = () => {
    if(this.props.claimTypes.length > 1){
      this.setState({
        showAllTypes: !this.state.showAllTypes
      });
    }
  }


  handleSentenceSelect = () => {
    const {
      handleSentenceSelect,
      hasHighlight,
      canHighlight,
      sentenceId
    } = this.props;

    if(canHighlight) handleSentenceSelect(sentenceId, hasHighlight);
  }

  handleCopySentenceNotice = () => {
    this.setState({
      hasCopyNotice: true
    });

    setTimeout(() => {
      this.setState({
        hasCopyNotice: false
      });
    }, 1000);
  }

  getHighestPriorityClaim(claimTypesToCompare) {
    if(claimTypesToCompare && claimTypesToCompare.length) {
      let topType = claimTypesToCompare[0];
      if(claimTypesToCompare.includes(this.props.selectedClaimType)) {
        topType = this.props.selectedClaimType;
      } else if(claimTypesToCompare.length > 1) {
        let topTypeScore = -1;
        claimTypesToCompare.forEach((type) => {
            if(!topType || claimTypePriorities[type] > topTypeScore) {
            topType = type;
            topTypeScore = claimTypePriorities[type];
          }
        });
      }
      return topType;
    }
    return null;
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  }

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  }

  copySentenceLink = (sentenceId) => {
    if(sentenceId) {
      try {
        let url = new URL(window.location.href);
        url.hash="";
        navigator.clipboard.writeText(url + '#' + sentenceId);
        this.setState({anchorEl: null});
      } catch (e) { //continue 
      }
    }
  }

  copySentenceText = (text) => {
    try {
      navigator.clipboard.writeText(text);
      this.setState({anchorEl: null});
    } catch (e) { //continue 
    }
  }
  render(){
    const {
      canHighlight,
      hasClaims,
      hasHighlight,
      matchedClaims,
      sentence,
      sentenceId,
      sentenceTime,
      hasMatchLinks,
      classes,
      floatMatches,
      isEnriching,
      claimTypes,
      statsChecked,
      t,
      isPlaying,
      canPlaySentence,
      handlePlaySentence
    } = this.props;
    const {
      showOptions,
      showAllTypes,
      anchorEl
    } = this.state;

    let sentenceClass = classes.sentence;

    if(canHighlight && hasHighlight) {
      sentenceClass += " " + classes.highlighted;
    }
    if(hasClaims) {
      sentenceClass += " " + classes.underline;
    }

    if(isPlaying) {
      sentenceClass += " " + classes.playing;
    }
    if(this.props.initialSentenceId === sentenceId) {
      sentenceClass += " " + classes.initialSentence;
    }

    let sentenceClasses = classes.sentenceContainer;
    let sentenceWrapperClass = '';
    if(hasClaims) sentenceClasses = 'hit-' + sentenceId + ' ' + classes.sentenceContainer;
    if(floatMatches) sentenceWrapperClass = classes.floatMatches;
  
    let claimTypeToShow = this.getHighestPriorityClaim(claimTypes);

    return (
    <div id={sentenceId} className={sentenceWrapperClass}>
      <div className={sentenceClasses}>
        {hasClaims ? (
          <div className={classes.sentenceMatches}>
            <LiveMatch
                matches={{
                  exactMatches: matchedClaims || [],
                  autoMatches: statsChecked || []
                }}
                sentence={sentence}
                channel={sentence.canonical_channel_name || ""}
                showLinks={hasMatchLinks}
            />
          </div>
        ): ""}
        <div className={classes.sentenceContent} key={sentenceId}>
              <span className={classes.sentenceMetadata}>
                {sentenceTime && !canPlaySentence && (<Typography variant="subtitle1" color="textSecondary">{sentenceTime}</Typography>)}
                {sentenceTime && canPlaySentence && (<Typography variant="subtitle1" color="textSecondary">{sentenceTime}<PlayArrowIcon className={classes.playSentenceIcon} onClick={() => {handlePlaySentence(sentence.metadata.time_offset_ms);}} /></Typography>)}
                {isEnriching ? (
                  <span className={classes.enrichingIcon}>
                    <Tooltip title={t('transcript_sentence.enrichment_in_progress')}>
                    <HourglassEmptyIcon />
                    </Tooltip>
                  </span>
                ): (
                  <span className={classes.claimTypesSummary} onClick={this.toggleShowAllTypes}>
                    {showAllTypes ? (
                      <span className={classes.expandedClaimTypes}>
                        <span className={classes.claimTypeChip}><Chip size="small" label={t(claimTypeToShow)}  /> </span>
                        {claimTypes && claimTypes.filter(type => type !== claimTypeToShow).map((type) => (
                          <span key={type} className={classes.claimTypeChip}><Chip size="small" label={t(formattedClaimTypes[type])}  /> </span>
                        ))}
                      </span>
                    ):(
                      <span>
                        {claimTypeToShow && (
                          <span className={classes.claimTypeChip}><Chip size="small" label={t(formattedClaimTypes[claimTypeToShow])}  /> 
                            {claimTypes.length > 1 && (' +'+ (claimTypes.length-1))}
                          </span>
                        )}
                      </span>
                    )}
                    </span>
              )}
              
              </span>
              <span className={sentenceClass} onMouseEnter={this.handleShowSentenceOptions}
              onMouseLeave={this.handleHideSentenceOptions}>
                {showOptions && (
                  <span className={classes.sentenceTools}>
                    <MoreVertIcon onClick={this.handleClick} />
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={this.handleClose}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                      <MenuItem
                        value="copy_text" onClick={() => { this.copySentenceText(sentence.sentence_text);}}>
                          {t('transcript_sentence.copy_text')}
                      </MenuItem>
                      <MenuItem
                        value="copy_link"
                        onClick={() => {this.copySentenceLink(sentenceId);}}
                      >
                        {t('transcript_sentence.copy_sentence_link')}
                      </MenuItem>
                    </Menu>
                  </span>
                )}
                <span className={classes.innerSentence+' innerSentence'} onClick={this.handleSentenceSelect}>
                  {sentence.sentence_text}
                </span>
              </span>
            </div>
        </div>
      </div>
    );
    }
}

TranscriptSentence.propTypes = {
  canHighlight: PropTypes.bool,
  handleSentenceSelect: PropTypes.func,
  hasHighlight: PropTypes.bool,
  hasMatchLinks: PropTypes.bool,
  hasClaims: PropTypes.bool,
  matchedClaims: PropTypes.array,
  sentence: PropTypes.object.isRequired,
  sentenceId: PropTypes.number,
  sentenceTime: PropTypes.string,
  t: PropTypes.func,
  transcriptId: PropTypes.number,
  i18n: PropTypes.object,
  classes: PropTypes.object,
  isDemo: PropTypes.bool,
  floatMatches: PropTypes.bool,
  selectedClaimType: PropTypes.string,
  isEnriching: PropTypes.bool,
  claimTypes: PropTypes.array,
  statsChecked: PropTypes.array,
  isPlaying: PropTypes.bool,
  canPlay: PropTypes.bool,
  handlePlaySentence: PropTypes.func,
  canPlaySentence: PropTypes.bool,
  initialSentenceId: PropTypes.number,
  hasVideo: PropTypes.bool
};

export default compose(
  withTranslation(),
  withStyles((theme) => ({
    claimTypesSummary : {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      '& a': {
        color: '#ccc',
        cursor: 'pointer'
      },
      '& a:hover': {
        color: '#000'
      },
      '& i': {
        verticalAlign: 'top'
      }
    },
    innerClaimType : {
      marginRight: '12px',
      display: 'block'
    },
    claimTypeList: {
      display: 'none',
      position: 'absolute',
      left: '40px',
      top: '0',
      paddingLeft: '5px',
      zIndex: '2'
    },
    claimTypeListShown: {
      display: 'flex',
      marginTop: '0',
      height: '43px',
      background: '#f9f9f9',
      '& a': {
        display: 'inline-block',
        marginRight: '3px'
      }
    },
    time: {
      display: 'inline-block',
      color: '#999',
      fontSize: '14px'
    },
    sentenceContainer: {
      display: 'grid'
    },
    sentenceContent: {
      display: 'inline-block',
      position: 'relative',
      padding: '12px 0',
      gridColumn: '1',
      gridRow: '1'
    },
    sentenceMatches: {
      padding: '12px 0',
      gridColumn: '1',
      gridRow: '2',
    },
    innerSentence: {
      color: 'initial',
      textDecoration: 'none',
      display: 'block',
      paddingRight: '27px',
      paddingTop: '3px',
      paddingBottom: '3px'
    },
    sentenceTools: {
      width: '28px',
      opacity: '0.95',
      backgroundColor: 'inherit',
      position: 'absolute',
      right: '1px',
      top: '13px',
      height: '26px',
      textAlign: 'right',
      marginBottom: '12px',
      marginRight: '0',
      color: '#ccc',
      '& i': {
        display: 'inline-block',
        marginBottom: '4px',
        marginRight: '6px',
        fontSize: '20px'
      },
      '& ihover': {
        cursor: 'pointer',
        color: '#000'
      },
      successIcon: {
        color: '#8BC34A',
        '&:hover': {
            color: '#8BC34A'
        }
      }
    },
    sentence: {
      fontSize: '19px',
      fontWeight: '300',
      lineHeight: '1.5',
      margin: '12px 0 0 0',
      display: 'block',
      padding: '0 6px',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        cursor: 'pointer'
      },
    },
    highlighted: {
      backgroundColor: '#FFEE58',
      '&:hover': {
        backgroundColor: '#fcf4ac',
        cursor: 'pointer'
      },
    },
    playing: {
      backgroundColor: theme.palette.background.paper
    },
    underline: {
      textDecoration: 'underline'
    },
    [theme.breakpoints.up('md')]: {
      floatMatches: {
        '& $sentenceContainer': {
          display: 'block'
        },
        '& $sentenceMatches': {
          gridColumn: 'auto',
          gridRow: 'auto',
          float: 'right',
          clear: 'right',
          width: '37%',
          marginRight: '25px'
        }
      },
      time: {
        gridColumn: '1',
        gridRow: '1',
        marginTop: '10px',
        marginLeft: '12px'
      },
      sentenceMatches: {
        width: '60%'
      },
      sentence: {
        gridColumn: '3',
        gridRow: '1',
        margin: '0'
      },
      sentenceContent: {
        width: '60%',
        display: 'grid',
        gridTemplateColumns: 'min-content min-content auto',
        gridRow: 'auto'
      }
    },
    sentenceMetadata: {
      paddingTop: '3px',
      display: 'inline-block',
      paddingLeft: '6px',
      width: '103px',
      color: '#999',
      minHeight: '52px'
    },
    sentenceCheckLoader: {
      gridColumn: 'auto',
      gridRow: 'auto',
      float: 'right',
      clear: 'right',
      width: '37%',
      marginRight: '25px'
    },
    fabLoader: {
      width: '20px',
      height: '20px'
    },
    claimTypeChip: {
      display: 'inline-block',
      color: 'rgba(0, 0, 0, 0.54)',
      margin: '0 3px 3px 0',
      cursor: 'pointer',
    },
    expandedClaimTypes: {
      position: 'absolute',
      left: '0',
      top: '-14px',
      display: 'inline-block',
      backgroundColor: '#fafafa',
      zIndex: '2'
    },
    enrichingIcon: {
      cursor: 'pointer',
      '& .MuiSvgIcon-root' : {
        fontSize: '20px',
        marginLeft: '-3px'
      }
    },
    playSentenceIcon: {
      fontSize: '20px',
      verticalAlign: 'middle',
      marginBottom: '3px',
      '&:hover': {
        color: '#000',
        cursor: 'pointer'
      }
    },
    initialSentence: {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ddd'
    }
  }))
)(TranscriptSentence);
