import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import TranscriptCard from './TranscriptCard';

export class TranscriptCardList extends React.Component {

  constructor(props){
    super(props);

    let fromDate = new Date(Date.now());
    this.state = {
      active: false,
      showDialog: false,
      transcriptId: null,
      transcriptType: null,
      transcript: {
        name: "",
        fromDate: fromDate,
        fromTime: fromDate,
        toDate: fromDate,
        toTime: fromDate
      }
    };

  }

  handleShowDelete = (transcriptId, transcriptType) => {
    this.setState({
      showDialog: !this.state.showDialog,
      transcriptId: transcriptId,
      transcriptType: transcriptType
    });
  }

  handleTranscriptDelete = () => {
    if(this.state.transcriptId){
      this.props.handleDelete(this.state.transcriptId, this.state.transcriptType);
      this.handleShowDelete();
    }
  }

  transcriptList = () => {
    const { 
      transcripts,
      limit, 
      classes, 
      hasCols
    } = this.props;

    let latestTranscripts;
    if(limit && transcripts.length > limit) {
      latestTranscripts = transcripts.slice(0, this.props.limit);
    } else {
      latestTranscripts = transcripts.slice();
    }
    if(!hasCols) {
      return latestTranscripts.map((transcript) => {
        return (
            <TranscriptCard
              key={transcript.transcriptId}
              className={classes.transcript}
              title={transcript.transcriptName}
              id={transcript.transcriptId}
              createdAt={transcript.createdAt}
              createdBy={transcript.createdByDisplayName}
              startTime={transcript.startTime}
              endTime={transcript.endTime}
              handleDelete={this.handleShowDelete}
              canDelete={transcript.createdByDisplayName?.length > 0}
              isOpen={transcript.isOpen}
              type={transcript.transcriptType}
              statistics={transcript.statistics}
              singleLine={true}
          />

        );
      });
    } else {
      return (<ul className={classes.list + " row"}>
        {latestTranscripts.map((transcript) => (
          <li className="col-xs4-4 col-md-4" key={transcript.transcriptId} style={{marginBottom: 0}}>
            <TranscriptCard
              className={classes.transcript}
              title={transcript.transcriptName}
              id={transcript.transcriptId}
              createdAt={transcript.createdAt}
              createdBy={transcript.createdByDisplayName}
              startTime={transcript.startTime}
              endTime={transcript.endTime}
              handleDelete={this.handleShowDelete}
              canDelete={transcript.createdByDisplayName?.length > 0}
              isOpen={transcript.isOpen}
              type={transcript.transcriptType}
              statistics={transcript.statistics}
            />
          </li>
        ))}
      </ul>)
    }
  }

  handleToggle = () => {
    this.setState({active: !this.state.active});
  }

  setValue = (field, value) => {
      this.setState({claim: {
          ...this.state.transcript, [field]: value
      }});
  }

  render = () => {
    const { t, classes } = this.props;
    let transcripts = this.transcriptList();

    return (
      <div className={classes.saved}>
        {transcripts}
        <Dialog
          open={this.state.showDialog}
          onClose={this.handleShowDelete}
        >
          <DialogTitle id="deletetranscript-dialog-title">{t('transcript_card_list.delete_transcript_title')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="deletetranscript-dialog-description">
            {t('transcript_card_list.delete_transcript_description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleShowDelete} color="secondary" variant="contained" autoFocus>
              {t('transcript_card_list.delete_transcript_cancel_button')}
            </Button>
            <Button className="transcript-card-delete-button" onClick={this.handleTranscriptDelete} color="primary" variant="contained" autoFocus>
              {t('transcript_card_list.delete_transcript_delete_button')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

TranscriptCardList.propTypes = {
    isOpen: PropTypes.bool,
    transcripts: PropTypes.array.isRequired,
    handleDelete: PropTypes.func,
    colClass: PropTypes.string,
    rowClass: PropTypes.string,
    limit: PropTypes.number,
    t: PropTypes.func,
    canDelete: PropTypes.bool,
    classes: PropTypes.object,
    showType: PropTypes.bool,
    hasCols : PropTypes.bool
};

export default compose(
  withTranslation(),
  withStyles(() => ({
    list: {
      listStyle: 'none',
      paddingLeft: '0',
      '& li': {
        marginBottom: '24px'
      }
    },
    transcript: {
      marginBottom: '24px'
    },
    title: {
      '& span': {
        verticalAlign: 'middle'
      },
      '& button' : {
        verticalAlign: 'middle',
        marginLeft: '12px',
        display: 'inline-block'
      }
    },
    saved: {
      '& button': {
        backgroundColor: '#009688 !important'
      }
    },
    live: {
      '& button': {
        backgroundColor: '#7F265D !important'
      }
    }
  })))(TranscriptCardList);
