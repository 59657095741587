const liveConstants = {
  SUBTITLE_ARRIVED: 'SUBTITLE_ARRIVED',
  SUBTITLE_SEARCHED: 'SUBTITLE_SEARCHED',
  CLEAR_SUBS: 'CLEAR_SUBS',
  CLEAR_CHANNEL: 'CLEAR_CHANNEL',
  GET_CHANNELS: 'GET_CHANNELS',
  GET_CHANNEL: 'GET_CHANNEL',
  CLEAR_LIVE: 'CLEAR_LIVE'
};

export default liveConstants;