import React from 'react';
import PropTypes from 'prop-types';
import config from'../../config';
import moment from 'moment';
import CandidatesManagerLayout from './SearchManagerLayout';
import nearley from 'nearley';
import grammar from '../../grammars/candidatesSearchGrammar';

const keyCodes = [
  {
    code: 'pub',
    name: 'publications'
  },
  {
    code: 'type',
    name: 'types',
    keyList: config.claimTypes
  },
  {
    code: 'cat',
    name: 'categories',
    keyList: config.mediaCategories
  },
  {
    code: 'topic',
    name: 'topics',
  },
  {
    code: 'who',
    name: 'claimants',
  },
  {
    code: 'group',
    name: 'claimantGroups',
  },
  {
    code: 'match',
    name: 'checkedClaims'
  }
];

export function stringToFilters(queryString) {
  let parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar));
  let filters = {
    exactPhrase: [],
    hasWords: [],
    excludeWords: [],
    publications: [],
    types: [],
    categories: [],
    topics: [],
    claimants: [],
    claimantGroups: [],
    startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  function processParserResults(results) {
    results.forEach((result) => {
      if(result['when']) {
        let days = result['when'][0];
        days = days.match(/([0-9]+)d/);
        if(days.length && days[1]) days = parseInt(days[1],10);
        let startDate = moment().subtract(days, 'days').format('YYYY-MM-DD');
        filters['startDate'] = startDate;
      }

      if(result['excludedWords']) {
        filters['excludeWords'] = filters['excludeWords'].concat(result['excludedWords']);
      }

      if(result['hasWords']) {
        filters['hasWords'] = filters['hasWords'].concat(result['hasWords']);
      }

      if(result['exactPhrase']) {
        filters['exactPhrase'] = filters['exactPhrase'].concat(result['exactPhrase']);
      }

      keyCodes.forEach((keyCode) => {
        if(result[keyCode.code]) {
          if(!filters[keyCode.name]) filters[keyCode.name] = [];
          filters[keyCode.name] = filters[keyCode.name].concat(result[keyCode.code]);
        }
      });
    });
    
  }

  parser.feed(queryString.trim());
  if(parser.results[0 && parser.results[0].length]) processParserResults(parser.results[0]);

  return filters;
}

export function formFieldsToFilters(fields) {
  let convertedFields = {};
  let selectableFields = keyCodes.map(keys => keys.name);
  Object.entries(fields).forEach(([key, value]) => {
    if(key === 'startDate' || key === 'endDate') {
      convertedFields[key] = value;
    } else if (key === 'exactPhrase') {
      let exactPhrases = value.match(/"([^"]*)"/g);
      if(exactPhrases) {
        convertedFields[key] = exactPhrases.map(phrase =>   phrase.substring(1, phrase.length-1));
      } else if(value.length) {
        convertedFields[key] = [value];
      } else {
        convertedFields[key] = [];
      }
    } else if(selectableFields && !selectableFields.includes(key)){
      convertedFields[key] = value && value.trim().split(' ') || []; // TODO this needs to handling mutliple quotes spaced apart
    } else {
      convertedFields[key] = [];
      if(value && value.length){
        value.forEach(option => {
          convertedFields[key].push(option.value);
        });
      }
    }
  });

  return convertedFields;
}

export function filtersToFormFields(filters) {
  let convertedFilters = {};
  let selectableFields = keyCodes.map(keys => keys.name);
  Object.entries(filters).forEach(([key, value]) => {
    if(key === 'startDate' || key === 'endDate') {
      convertedFilters[key] = value;
    } else if (key === 'exactPhrase' && value.length) {
      if(value.length === 1) {
        convertedFilters[key] = value[0];
      } else {
        convertedFilters[key] = '"'+value.join('" "')+'"';
      }
    } else if(selectableFields && !selectableFields.includes(key)){
      convertedFilters[key] = value && value.join(' ') || '';
    } else {
      convertedFilters[key] = [];
      if(value && value.length){
        value.forEach(option => {
          convertedFilters[key].push({value: option, label: option});
        });
      }
    }
  });

  return convertedFilters;
}

export function convertLabelsToKeys(labels, keylist) {
  let keys = labels;

  keys = labels.map((item) => {
    let key = item;
    let matches = keylist.filter(item => item.label = key);
    if(matches && matches.length) key = matches[0].value;
    return key;
  });

  return keys;
}

export function filtersToString(filters) {
  let searchText = [];
  if(filters.exactPhrase.length) {
    let phrases = [];
    filters.exactPhrase.forEach(phrase => {
      phrases.push('"'+phrase+'"');
    });
    searchText.push(phrases.join(" "));
  }

  filters.hasWords.length ? searchText.push(filters.hasWords.join(" ")) : false;

  if(filters.excludeWords.length) {
    let prefixedWords = [];
    filters.excludeWords.forEach(word => {
      prefixedWords.push('-'+word);
    });
    searchText.push(prefixedWords.join(' '));
  }

  keyCodes.forEach(key => {
    if(filters[key.name] && filters[key.name].length)  {
      let quotedFilters = filters[key.name].map(phrase => {
        phrase = phrase+'';
        if(phrase.includes(' ')) {
          return '"'+phrase+'"';
        } else {
          return phrase;
        }
      });
      searchText.push(key.code+":"+quotedFilters.join(','));
    }
  });

  if(filters.startDate.length && filters.endDate.length) {
    let duration = moment.duration(moment.utc(filters.endDate).diff(moment.utc(filters.startDate)));
    let days = Math.ceil(duration.asDays());
    if(days > 1) searchText.push('when:'+(days)+'d');
  }

  searchText = searchText.join(' ');
  return searchText;
}

export function SearchManager(props) {
  const {
    candidates,
    candidatesCount,
    candidatesRequestState,
    claimants,
    trendingClaimants,
    handleSearchFromAdvancedForm,
    handleSearchFromUserInput,
    initialFilters,
    publications,
    publicationsRequestState,
    mediaCategories,
    claimTypes,
    hasMoreCandidates,
    claimantGroups,
    hasQuery,
    suggestedSearches
  } = props;

  let defaultFilters = stringToFilters(initialFilters && initialFilters.text || '');


  const [showSavedSearches, setShowSavedSearches] = React.useState(false);
  const [showTrendingClaimants, setShowTrendingClaimants] = React.useState(false);
  const [showAdvancedSearch, setShowAdvancedSearch] = React.useState(false);
  const [searchText, setSearchText] = React.useState(initialFilters && initialFilters.text || '');
  const [formSearchText, setFormSearchText] = React.useState(initialFilters && initialFilters.text || '');
  const [filters, setFilters] = React.useState(defaultFilters);

  const [formFilters, setFormFilters] = React.useState(filtersToFormFields(defaultFilters));

  function handleAdvancedSearchToggle(isOpen) {
    setShowAdvancedSearch(false);
  }

  function handleEditSavedSearchClick(index) {
    setShowAdvancedSearch(true);
  }

  function onKeywordClick(keyword) {
    handleTextSearch(keyword);
  }

  function handleTrendingClaimantClick(claimant) {
    let searchFilters = {
      ...filters,
      claimants: [claimant],
      topics: []
    };

    let searchText  = filtersToString(searchFilters);
    setFilters(searchFilters);
    handleTextSearch(searchText);
  }

  function handleFilterSearch(fieldsData) {
    let filters = formFieldsToFilters(fieldsData);
    let searchText = filtersToString(filters);
    handleSearchFromAdvancedForm(searchText);
    setFilters(filters);
    setSearchText(searchText);
    setFormSearchText(searchText);
    setFormFilters(fieldsData);
  }

  function handleTextSearch(text) {
    let filters = stringToFilters(text);
    let fieldsData = filtersToFormFields(filters);
    handleSearchFromUserInput(text);
    setFilters(filters);
    setSearchText(text);
    setFormSearchText(text);
    setFormFilters(fieldsData);
  }

  function getMoreCandidates() {
    props.getMoreCandidates(searchText);
  }

  function handleClaimantSearch(claimantText) {
    props.getClaimantsAutoComplete(claimantText);
  }
    return (
      <CandidatesManagerLayout
        candidates={candidates}
        candidatesCount={candidatesCount}
        candidatesRequestState={candidatesRequestState}
        claimants={claimants}
        claimTypes={claimTypes}
        claimantGroups={claimantGroups}
        hasMoreCandidates={hasMoreCandidates}
        hasQuery={hasQuery}
        mediaCategories={mediaCategories}
        publications={publications}
        publicationsRequestState={publicationsRequestState}
        trendingClaimants={trendingClaimants}
        showSavedSearches={showSavedSearches}
        showTrendingClaimants={showTrendingClaimants}
        handleClaimantSearch={handleClaimantSearch}
        getMoreCandidates={getMoreCandidates}
        handleFilterSearch={handleFilterSearch}
        handleTrendingClaimantClick={handleTrendingClaimantClick}
        onKeywordClick={onKeywordClick}
        handleEditSavedSearchClick={handleEditSavedSearchClick}
        handleAdvancedSearchToggle={handleAdvancedSearchToggle}
        formFilters={formFilters}
        formSearchText={formSearchText}
        showAdvancedSearch={showAdvancedSearch}
        handleTextSearch={handleTextSearch}
        setFormFilters={setFormFilters}
        setFormSearchText ={setFormSearchText}
        searchText ={searchText}
        filters={filters}
        setShowSavedSearches={setShowSavedSearches}
        setShowTrendingClaimants={setShowTrendingClaimants}
        suggestedSearches={suggestedSearches}
      />
    );


}

export default SearchManager;

SearchManager.propTypes = {
  candidates: PropTypes.array,
  candidatesCount: PropTypes.number,
  candidatesRequestState: PropTypes.string,
  claimants: PropTypes.array,
  claimantGroups: PropTypes.array,
  claimTypes: PropTypes.array,
  classes: PropTypes.object,
  getMoreCandidates: PropTypes.func,
  getClaimantsAutoComplete: PropTypes.func,
  handleSearchFromAdvancedForm: PropTypes.func,
  handleSearchFromUserInput: PropTypes.func,
  hasMoreCandidates: PropTypes.bool,
  initialFilters: PropTypes.object,
  mediaCategories: PropTypes.array,
  publications: PropTypes.array,
  publicationsRequestState: PropTypes.string,
  trendingClaimants: PropTypes.array,
  topics: PropTypes.array,
  t: PropTypes.func,
  hasQuery: PropTypes.bool,
  suggestedSearches: PropTypes.array
};