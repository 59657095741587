import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router, Switch } from 'react-router-dom';


import privateRoutes from 'Config/privateRoutes';
import publicRoutes from 'Config/publicRoutes';

import { PublicRoute } from './PublicRoute/PublicRoute';
import PrivateRoute from './PrivateRoute/PrivateRoute';

import authActions  from 'Actions/authActions';
import authSelectors from 'Selectors/authSelectors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { I18nextProvider } from 'react-i18next';
import i18n from 'I18n/i18n';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import 'normalize.css/normalize.css';
import 'material-responsive-grid/material-responsive-grid.css';
import './Root.css';
import theme from 'Config/theme';
import noticeSelectors from 'Selectors/noticeSelectors';
import RootError from './RootError';
import TextDirection from './RTL';
import customMomentLocales from 'I18n/moment';
import moment from 'moment';

let Muitheme;


export class Root extends Component {

  constructor(props) {
    super(props);

    // get configuation json and attempt to load user session from local storage
    props.initialiseApp();

    // monitor location changes for google analytics
    props.locationChanged(props.history.location);
    props.history.listen(location => {
      props.locationChanged(location);
    });

  }

  render() {
    const {
      isLoggedIn,
      appIsLoading,
      locale,
      themeColor,
      notice,
      history,
      textDirection
    } = this.props;

    Muitheme = createTheme({
      ...theme[themeColor],
      direction: textDirection,
    });
    if(locale) {
      Object.keys(customMomentLocales).map(function(key, index) {
        if(customMomentLocales[key])  moment.updateLocale(key, customMomentLocales[key]);
      });
      moment.locale(locale.toLowerCase());
    }

    i18n.changeLanguage(locale);
    const lang = locale && locale.split('-')[0];

    if (notice) {
      return (
        <I18nextProvider i18n={i18n}>
          <RootError notice={notice} />
        </I18nextProvider>
      );
    } else if(appIsLoading){
      return (
        <div style={{textAlign: 'center', height: '100%', marginTop: '40vh'}}>
          <CircularProgress />
        </div>
      );
    } else {
      if(lang){
        document.body.setAttribute("dir", textDirection);
        document.body.setAttribute("lang", lang);
      }
      return (
        <TextDirection hasRTL={textDirection === 'rtl'}>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={Muitheme}>
              <Router history={history}>
                <Switch>
                  {isLoggedIn ? (
                    privateRoutes.map((route, index) => (
                      <PrivateRoute
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.page}
                        name={route.name}
                        history={history}
                        fixedHeight={route.fixedHeight}
                      />
                    ))
                  ) : (
                    publicRoutes.map((route, index) => (
                      <PublicRoute
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.page}
                        name={route.name}
                        history={history}
                      />
                    ))
                  )}
                </Switch>
              </Router>
            </ThemeProvider>
          </I18nextProvider>
        </TextDirection>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: authSelectors.getIsLoggedIn(state),
    appIsLoading: authSelectors.getAppIsLoading(state),
    locale: authSelectors.getLocale(state),
    themeColor: authSelectors.getThemeColor(state),
    textDirection: authSelectors.getTextDirection(state),
    notice: noticeSelectors.getNoticeById(state, 'root_notice'),
  };
}

const mapDispatchToProps = {
  initialiseApp: authActions.initialiseApp,
  locationChanged: authActions.locationChanged
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Root);

Root.propTypes = {
  history: PropTypes.object.isRequired,
  initialiseApp: PropTypes.func,
  locationChanged: PropTypes.func,
  authSelectors: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  appIsLoading: PropTypes.bool,
  locale: PropTypes.string,
  themeColor: PropTypes.string,
  notice: PropTypes.object,
  permissions: PropTypes.array,
  textDirection: PropTypes.string
};
