import { put, takeEvery } from 'redux-saga/effects';

import adminConstants from 'Constants/adminConstants';

import adminActions from 'Actions/adminActions';
import noticeActions from 'Actions/noticeActions';
import successMessages from 'Config/default/successMessages';
import { Media } from 'Models/Media';

const delay = (ms) => new Promise(res => setTimeout(res, ms));

export function* processGetUsers(action) {

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/users/`,
    }
  });
}

export function* processChangeUserPassword(action) {

  let body = {
    password: action.password,
    user_id: action.userId
  };

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/authenticate/password/`,
      method: 'POST',
      body: body
    },
    request: {
      email: action.email
    }
  });

}

export function* processDeleteUser(action) {

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/users/${action.userId}/`,
      method: 'DELETE'
    },
    request: {
      userId: action.userId
    }
  });

}


export function* processResendVerify(action) {

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/users/${action.userId}/verify/`,
      method: 'POST'
    },
    request: {
      email: action.email
    }
  });

}

export function* processChangeUserPasswordSuccess(action) {
  yield put(adminActions.stopEditingUser());
  const msg = successMessages['USER_PASSWORD_CHANGED'];
  yield put(noticeActions.addTemporaryNotice(msg.displayCode, {
    messageKey: msg.message,
    position: "bottom",
    type: "success"
  }));
}

export function* processResendVerifySuccess(action) {
  const msg = successMessages['VERIFICATION_SENT'];
  yield put(noticeActions.addTemporaryNotice(msg.displayCode, {
    messagekey: msg.message,
    position: "bottom",
    type: "success"
  }));
}

export function* processUpdateUser(action) {

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/users/${action.user.userId}/`,
      method: 'PUT',
      body: {
        display_name: action.user.name,
        role: action.user.role
      }
    },
    request: {
      userId: action.user.userId
    }
  });

}

export function* processCreateUser(action) {

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/users/`,
      method: 'POST',
      body: {
        display_name: action.user.name,
        role: action.user.role,
        email: action.user.email
      }
    },
    request: {
      email: action.user.email
    }
  });

}

export function* processUsersChange(action) {
  yield put(adminActions.stopEditingUser());
  yield put(adminActions.getUsers());
}

export function* processGetOrganisationMedia(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/publications_v2/`,
    }
  });
}

export function* processCreateOrganisationMedia(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/publications_v2/`,

      method: 'POST',
      body: Media.toApi(action.data)
    }
  });
}

export function* processUpdateOrganisationMedia(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/publications_v2/${action.data.publicationId}/`,
      method: 'PATCH',
      body: Media.toApi(action.data)
    }
  });
}

export function* processDeleteOrganisationMedia(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/publications_v2/${action.data}/`,
      method: 'DELETE',
    }
  });
}

export function* processMediaChange() {
  yield put(adminActions.stopEditingMedia());
  yield put(adminActions.getOrganisationMedia());
}

export function* adminSagas(){
  yield takeEvery(adminConstants.GET_USERS, processGetUsers);
  yield takeEvery(adminConstants.CHANGE_USER_PASSWORD, processChangeUserPassword);
  yield takeEvery(`${adminConstants.CHANGE_USER_PASSWORD}_SUCCESS`, processChangeUserPasswordSuccess);
  yield takeEvery(adminConstants.DELETE_USER, processDeleteUser);
  yield takeEvery(adminConstants.RESEND_VERIFY_USER, processResendVerify);
  yield takeEvery(`${adminConstants.RESEND_VERIFY_USER}_SUCCESS`, processResendVerifySuccess);
  yield takeEvery(adminConstants.UPDATE_USER, processUpdateUser);
  yield takeEvery(`${adminConstants.UPDATE_USER}_SUCCESS`, processUsersChange);
  yield takeEvery(adminConstants.CREATE_USER, processCreateUser);
  yield takeEvery(`${adminConstants.CREATE_USER}_SUCCESS`, processUsersChange);
  yield takeEvery(`${adminConstants.GET_ORG_MEDIA}`, processGetOrganisationMedia);
  yield takeEvery(`${adminConstants.CREATE_ORG_MEDIA}`, processCreateOrganisationMedia);
  yield takeEvery(`${adminConstants.UPDATE_ORG_MEDIA}`, processUpdateOrganisationMedia);
  yield takeEvery(`${adminConstants.DELETE_ORG_MEDIA}`, processDeleteOrganisationMedia);
  yield takeEvery(`${adminConstants.CREATE_ORG_MEDIA}_SUCCESS`, processMediaChange);
  yield takeEvery(`${adminConstants.UPDATE_ORG_MEDIA}_SUCCESS`, processMediaChange);
  yield takeEvery(`${adminConstants.DELETE_ORG_MEDIA}_SUCCESS`, processMediaChange);
}