import { put, takeEvery,  select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import * as utils from '../utils';
import constants from '../constants';
import config from '../config';
import authSelectors from 'Selectors/authSelectors';

import authActions from 'Actions/authActions';
import noticeActions from 'Actions/noticeActions';
import successMessages from 'Config/default/successMessages';
import errorMessages from 'Config/default/errorMessages';

const delay = (ms) => new Promise(res => setTimeout(res, ms));
const getAppConfig = state => state.appState;


export function* processInitialise(action){
  yield put({ type: constants.auth.LOAD_APP_CONFIG});
}

export function* processLoadAppConfigSuccess(action){
  yield put({ type: constants.auth.LOAD_USER });
}

export function* processLoginRequest(action) {
  let requestBody = {
    'email': action.email,
    'password': action.password
  };

  if(action.organisation && action.organisation !== "") {
    requestBody.organisation_name = action.organisation;
  }
  
   yield put({
     type: 'FETCH',
     requestType: action.type,
     payload: {
       path: `/authenticate/`,
       method: 'POST',
       body: requestBody
    }
   });
}

export function* processLoginRequestSuccess(action) {
  let respJson = action.response;
  if(respJson && respJson.access_token, respJson.user){
    //TODO: organisation name should be in the login response
    yield delay(540000);
    yield put({
      type: constants.auth.REFRESH_TOKEN
    });

  } else if (respJson && respJson.message)  {
    const msg = errorMessages['WRONG_EMAIL_PASSWORD'];
    yield put(noticeActions.addNotice(msg.displayCode, {
      messageKey: msg.message
    }));
  }
}

export function* processLoginRequestFailure(action){
  const msg = errorMessages['COULD_NOT_LOGIN'];
  yield put(noticeActions.addNotice(msg.displayCode, {
    messageKey: msg.message
  }));
}

export function* processLogoutRequest(action) {
  let requestTypes = utils.createRequestTypes(action.type);

  yield put({ type: requestTypes.REQUEST });
  try {
    yield localStorage.removeItem('user');
    yield put({ type: constants.auth.CLEAR_DATA });
    yield put({ type: requestTypes.SUCCESS });
    yield put(push('/'));
  } catch (error){
    yield put({ type: requestTypes.FAILURE });
  }
}

export function* processLoadUserRequest(action) {

  function verifyUserStructure(user) {
    let isCorrect = true;
    let requiredKeys = ['organisation','email','organisationId', 'role', 'userId', 'displayName', 'isVerified'];
    requiredKeys.forEach( key => {
      isCorrect = isCorrect && user[key];
    });
    return isCorrect;
  }

  // get user json from local storage
  let appConfig = yield select(getAppConfig);
  let auth = JSON.parse(localStorage.getItem('user'));
  if(auth){
    let hasCorrectUserFormat = auth.user && verifyUserStructure(auth.user);
    if(hasCorrectUserFormat && auth.accessToken && auth.refreshToken && auth.user && auth.user.email) {
      // put user details from localStorage into the app state
      yield put({ type: `${constants.auth.LOAD_USER}_SUCCESS`, auth: auth });
      // refresh token that may be stale
      yield put({ type: `${constants.auth.REFRESH_TOKEN}`});
    } else {
      yield put({ type: `${constants.auth.LOAD_USER}_FAILURE`});
    }
  } else {
    yield put({ type: `${constants.auth.LOAD_USER}_FAILURE`});
  }
}

export function* processRefreshLoop(action) {
  let auth = JSON.parse(localStorage.getItem('user'));
  if(auth && auth.accessToken && auth.refreshToken && auth.user && auth.user.email) {
    yield put({ type: `${constants.auth.LOGIN}_SUCCESS`, response: auth });
    yield put({ type: `${constants.auth.REFRESH_TOKEN}`});

  }
}

export function* processClearData(action) {
  yield put({ type: constants.claims.CLEAR_CLAIMS });
  yield put({ type: constants.live.CLEAR_LIVE });
  yield put({ type: constants.notices.CLEAR_NOTICES });
  yield put({ type: constants.transcript.CLEAR_TRANSCRIPT });
  yield put({ type: constants.trends.CLEAR_TRENDS });

}

export function* processRefreshTokenRequest(action) {
  yield put({ type: constants.auth.REFRESHING_TOKEN });

  let refreshToken = yield select(authSelectors.getRefreshToken);

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/authenticate/refresh/`,
      method: 'POST',
      body: {
        refresh_token: refreshToken
      }
    }
  });

}

export function* processRefreshTokenRequestSuccess(action){
  let json = action.response;
  yield put({ type: constants.auth.TOKEN_REFRESHED, response: json});
  let user = JSON.parse(localStorage.getItem('user'));
  user.accessToken = json.access_token;
  localStorage.setItem('user', JSON.stringify(user));
  let unauthorisedActions = yield select(authSelectors.getUnauthorisedActions);

  for (let action of unauthorisedActions) {
    yield put({ type: constants.auth.CLEAR_UNAUTHORISED_ACTIONS });
    yield put(action);
  }
  yield delay(540000); //540000 is 9 minutes
  yield put({
    type: constants.auth.REFRESH_TOKEN
  });
}

export function* processRefreshTokenRequestFailure(action){
  yield put({ type: `${constants.auth.LOAD_USER}_FAILURE`});
}


export function* processChangePassword(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/authenticate/password/`,
      method: 'POST',
      body: {
        password: action.password
      }
    }
  });
}

export function* processChangePasswordSuccess(action) {
  const msg = successMessages['PASSWORD_CHANGED'];
  yield put(noticeActions.addTemporaryNotice(msg.displayCode,{
    messageKey: msg.message,
    type: "success"
  },
  2000));
}

export function* processForgotPassword(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/authenticate/password/reset/`,
      method: 'POST',
      body: {
        email: action.email
      }
    },
    request: {
      email: action.email
    }
  });

}

export function* processChangePasswordWithToken(action) {

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/authenticate/password/reset-token/`,
      method: 'POST',
      body: {
        password_reset_token: action.token,
        password: action.password
      }
    }
  });

}

export function* processChangePasswordWithTokenSuccess(action) {
  const msg = successMessages['YOUR_PASSWORD_CHANGED'];
  yield put(noticeActions.addNotice(msg.displayCode, {
    messageKey: msg.message,
    type: "success"
  }));
  yield put(push('/'));
}

export function* processForgotPasswordSuccess(action) {
  const msg = successMessages['RESET_PASSWORD_EMAIL'];
  yield put(noticeActions.addNotice(msg.displayCode, {
    messageKey: msg.message,
    type: "success"
  }));
}

export function* processLoadAppConfig(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/assets/init/conf.json`
    }
  });
}

export function* processLoadAppConfigFailure(action) {
  yield put(authActions.logout());
}

export function* authSagas(){
  yield takeEvery(constants.auth.INITIALISE_APP, processInitialise);
  yield takeEvery(constants.auth.LOGIN, processLoginRequest);
  yield takeEvery(`${constants.auth.LOGIN}_SUCCESS`, processLoginRequestSuccess);
  yield takeEvery(`${constants.auth.LOGIN}_FAILURE`, processLoginRequestFailure);
  yield takeEvery(`${constants.auth.LOAD_APP_CONFIG}_FAILURE`, processLoadAppConfigFailure);
  yield takeEvery(constants.auth.LOGOUT, processLogoutRequest);
  yield takeEvery(constants.auth.LOAD_USER, processLoadUserRequest);
  yield takeEvery(constants.auth.REFRESH_TOKEN, processRefreshTokenRequest);
  yield takeEvery(`${constants.auth.REFRESH_TOKEN}_SUCCESS`, processRefreshTokenRequestSuccess);
  yield takeEvery(`${constants.auth.REFRESH_TOKEN}_FAILURE`, processRefreshTokenRequestFailure);
  yield takeEvery(constants.auth.CLEAR_DATA, processClearData);
  yield takeEvery(constants.auth.CHANGE_PASSWORD, processChangePassword);
  yield takeEvery(`${constants.auth.CHANGE_PASSWORD}_SUCCESS`, processChangePasswordSuccess);
  yield takeEvery(constants.auth.FORGOT_PASSWORD, processForgotPassword);
  yield takeEvery(`${constants.auth.FORGOT_PASSWORD}_SUCCESS`, processForgotPasswordSuccess);
  yield takeEvery(`${constants.auth.CHANGE_PASSWORD_WITH_TOKEN}`, processChangePasswordWithToken);
  yield takeEvery(`${constants.auth.CHANGE_PASSWORD_WITH_TOKEN}_SUCCESS`, processChangePasswordWithTokenSuccess);
  yield takeEvery(constants.auth.LOAD_APP_CONFIG, processLoadAppConfig);
  yield takeEvery(`${constants.auth.LOAD_APP_CONFIG}_SUCCESS`, processLoadAppConfigSuccess);
}