import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import { withTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import ActionAutosuggest from '../Claim/ClaimAutosuggest';
import Notice from 'Components/Common/Notice';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon  from '@material-ui/icons/Close';
import { withTheme } from '@material-ui/core/styles';
import WikiAutosuggest from 'Components/Common/WikiAutosuggest';

export class ActionForm extends Component {

  constructor(props) {
      super(props);

      let intervention = props.intervention || {};
      let intervened_at = null;
      let resolved_at = null;
      let publication_date = null;

      if(intervention.resolved_at) resolved_at = new Date(intervention.resolved_at);
      if(intervention.intervened_at) {
        intervened_at = new Date(intervention.intervened_at);
      } else {
        intervened_at = new Date(Date.now());
      }
      if(intervention && intervention.publication_date) {
        publication_date = new Date(intervention.publication_date);
      } else {
        publication_date = new Date(Date.now());
      }

      const isCreate = Boolean(intervention && !intervention.intervention_id);
      const hasMediaSighting = Boolean(intervention && intervention.media_hash);
      const isCreateWithMediaSighting = isCreate && hasMediaSighting;

      let sightingType;
      if(hasMediaSighting) {
        sightingType = 'indexed_media';
      } else {
        sightingType = 'manual_entry';
      }

      this.state = {
          submitted: false,
          showResponseFields: false,
          intervention: {
            intervention_id: intervention.intervention_id || null,
            sighting_type: sightingType,
            media_hash: intervention.media_hash || null,
            status: intervention.status || 'not_started',
            intervened_at: intervened_at,
            intervention_type:  intervention.intervention_type || 'correction_request',
            intervention_target: intervention.intervention_target || '',
            wikidata_intervention_target_id: intervention.wikidata_intervention_target_id || '',
            notes: intervention.notes || '',
            resolved_at: resolved_at,
            resolution_type: intervention.resolution_type || null,
            resolution_url: intervention.resolution_url || '',
            resolution_summary: intervention.resolution_summary || '',
            tracked_claim_id: intervention.tracked_claim_id,
            text: intervention.text,
            category: intervention.category || null,
            publication_date: publication_date,
            url: intervention.url || '',
            author_name: intervention.author_name || '',
            wikidata_author_name_id: intervention.wikidata_author_name_id || '',
            publication: intervention.publication || '',
            wikidata_publication_id: intervention.wikidata_publication_id || '',
            action_due_to_afc: intervention.action_due_to_afc || isCreateWithMediaSighting
          },
          isCreate: isCreate,
          hasMediaSighting: hasMediaSighting,
          isCreateWithMediaSighting: isCreateWithMediaSighting,
          selectValues: {
            author_name: intervention.author_name || '',
            publication: intervention.publication || '',
          },

      };
  }

  getOptionForValue = (value, options) => {
    let selectOption = value;
    options = options.filter(option => option.value == value);
    if(options.length === 1) selectOption = options[0];
    return selectOption;
  }

  handleClaimChange = (value) => {

    if(value[0] && value[0].length) {
      this.setState({intervention: {
          ...this.state.intervention,
          tracked_claim_id: parseInt(value[0],10)
      }});
    } else {
      this.setState({intervention: {
          ...this.state.intervention,
          tracked_claim_id: null
      }});
    }

  }

  removeInterventionClaim = () => {
    this.setState({intervention: {
        ...this.state.intervention,
        tracked_claim_id: null
    }});
  };

  handleSubmit = (e)  => {
    if(e.preventDefault) e.preventDefault();

    this.setState({ submitted: true });

    let intervention = {
      ...this.state.intervention
    };

    if(intervention.tracked_claim_id && intervention.text && intervention.category && intervention.publication) {

      //remove null values from intervention
      for (const prop in intervention) {
        if(Object.prototype.hasOwnProperty.call(intervention, prop)) {
          if(intervention[prop] === null || (!intervention.intervention_id &&  intervention[prop] === '')) delete intervention[prop];
        }
      }

      //convert valid Date objects in date strings
      if(intervention.intervened_at) {
        if(intervention.intervened_at !== 'Invalid Date'){
          intervention.intervened_at = moment(intervention.intervened_at).format('YYYY-MM-DDTHH:mm:ss');
        } else {
          delete intervention.intervened_at;
        }
      }
      if(intervention.resolved_at) {
        if(intervention.resolved_at !== 'Invalid Date'){
          intervention.resolved_at = moment(intervention.resolved_at).format('YYYY-MM-DDTHH:mm:ss');
        } else {
          delete intervention.resolved_at;
        }
      }
      if(intervention.publication_date) {
        if(intervention.publication_date !== 'Invalid Date'){
          intervention.publication_date = moment(intervention.publication_date).format('YYYY-MM-DDTHH:mm:ss');
        } else {
          delete intervention.publication_date;
        }
      }

      this.props.handleSubmit(intervention);
    }
  }

  setValue = (e) =>{
    this.setState({intervention: {
        ...this.state.intervention,
        [e.target.name]: e.target.value
    }});
  }

  setValueForField = (field, value) =>{
    this.setState({intervention: {
        ...this.state.intervention,
        [field]: value
    }});
  }

  setValueForWikiId = (field, fieldId, label, value) => {
    this.setState({
      intervention: {
        ...this.state.intervention,
        [field]: label,
        [fieldId]: value
      },
      selectValues: {
        ...this.state.selectValues,
        [field]: label
      }
    });
  }

  /*
  the idea here is that when the value changes for the author name
  the wikidata id is cleared.
  */
  setValueForWikiField = (field, fieldId, value) => {
    if(value !== this.state.selectValues[field]) {
      this.setState({
        intervention: {
          ...this.state.intervention,
          [field]: value,
          [fieldId]: ''
        },
        selectValues: {
          ...this.state.selectValues,
          [field]: ''
        }
      });
    } else {
      this.setState({
        intervention: {
          ...this.state.intervention,
          [field]: value
        }
      });
    }

  }

  setActionDate = (date) => {
    this.setState({intervention: {
        ...this.state.intervention,
        intervened_at: date
    }});
  }

  setResolutionDate = (date) => {
    this.setState({intervention: {
        ...this.state.intervention,
        resolved_at: date
    }});
  }

  handleSightingFoundToggle = () => {
    this.setState({intervention: {
        ...this.state.intervention,
        action_due_to_afc: !this.state.intervention.action_due_to_afc
    }});
  }

    render() {
      const {
        t,
        trackedClaims,
        errorNotice,
        suggestedCategories,
        statusTypes,
        actionTypes,
        actionResolutionTypes,
        classes,
        theme
      } = this.props;

      const {
        intervention,
        submitted,
        hasMediaSighting,
        isCreateWithMediaSighting
      } = this.state;

      let selectedClaim;

      if(intervention.tracked_claim_id) {
        selectedClaim = trackedClaims.filter(claim => claim.trackedClaimId === intervention.tracked_claim_id)[0];
      }

      const selectStyles = {
        control: (provided, state) => {
          const borderColor = state.isFocused ? theme.palette.primary[500] : provided.borderColor;
          const boxShadow = state.isFocused ? '0 0 0 1px ' + theme.palette.primary[500] : provided.boxShadow;
          return { ...provided, borderColor, boxShadow,
          '&:hover': {
            borderColor
          } };
        }
      };

      const requiredField = (<div className={classes.requiredFieldNotice}>
        {t('action_form.required_field')}
      </div>);
      return (
        <form onSubmit={this.handleSubmit} aria-label="Action form">
          <div className={classes.formField}>
            <InputLabel shrink htmlFor="status">{t("action_form.status")}</InputLabel>
            <Select
              defaultValue={this.getOptionForValue(intervention.status, statusTypes)}
              placeholder={t('action_form.status')}
              onChange={({value}) => { this.setValueForField('status', value); }}
              name="status"
              options={statusTypes}
              styles={selectStyles}
            />
          </div>
          <div className={classes.formField}>
            <InputLabel shrink htmlFor="intervened_at">{t("action_form.field_action_date_label")}</InputLabel>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardDatePicker
                disableToolbar
                variant="dialog"
                format="YYYY-MM-DD"
                margin="normal"
                id="action-date-picker"
                name="intervened_at"
                fullWidth={true}
                value={intervention.intervened_at}
                onChange={this.setActionDate}
                placeholder={t('action_form.action_date')}
                cancelLabel={t('action_form.cancel')}
                okLabel={t('action_form.ok')}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.formField}>
            <InputLabel shrink htmlFor="intervention_type">{t('action_form.action_type')}</InputLabel>
            <Select
              defaultValue={this.getOptionForValue(intervention.intervention_type, actionTypes)}
              placeholder={t('action_form.action_type')}
              onChange={({value}) => { this.setValueForField('intervention_type', value); }}
              name="intervention_type"
              options={actionTypes}
              styles={selectStyles}
            />
          </div>
          <div className={classes.formField}>
            <InputLabel shrink htmlFor="intervention_target">{t('action_form.action_target')}</InputLabel>
             <WikiAutosuggest
                placeholder={t('action_form.action_target')}
                handleSuggestionSelect={({label, value})=>{
                  this.setValueForWikiId('intervention_target', 'wikidata_intervention_target_id', label, value);
                }}
                handleChange={(value) => { this.setValueForWikiField('intervention_target', 'wikidata_intervention_target_id', value); }}
                name="intervention_target"
                value={intervention.intervention_target}
                wikiId={intervention.wikidata_intervention_target_id}
                isDisabled={isCreateWithMediaSighting}
             />
          </div>
          <div className={classes.formField}>
            <InputLabel shrink htmlFor="notes">{t("action_form.field_notes_label")}</InputLabel>
            <TextField
              type="notes"
              name="notes"
              value={intervention.notes}
              onChange={this.setValue}
              fullWidth={true}
              multiline
              variant="outlined"
            />
          </div>
          <div className={classes.formField}>
          <InputLabel shrink htmlFor="resolved_at">{t("action_form.resolution_date")}</InputLabel>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardDatePicker
                disableToolbar
                variant="dialog"
                format="YYYY-MM-DD"
                margin="normal"
                id="resolution-date-picker"
                name="resolved_at"
                fullWidth={true}
                placeholder={t('action_form.resolution_date')}
                value={intervention.resolved_at}
                onChange={this.setResolutionDate}
                cancelLabel={t('action_form.cancel')}
                okLabel={t('action_form.ok')}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.formField}>
            <InputLabel shrink htmlFor="resolution_type">{t("action_form.resolution_type")}</InputLabel>
            <Select
              defaultValue={this.getOptionForValue(intervention.resolution_type, actionResolutionTypes)}
              onChange={({value}) => { this.setValueForField('resolution_type', value); }}
              placeholder={t("action_form.resolution_type")}
              name="resolution_type"
              options={actionResolutionTypes}
              styles={selectStyles}
            />
          </div>
          <div className={classes.formField}>
            <InputLabel shrink htmlFor="resolution_url">{t('action_form.resolution_url')}</InputLabel>
            <TextField
              type="resolution_url"
              name="resolution_url"
              value={intervention.resolution_url}
              onChange={this.setValue}
              fullWidth={true}
              variant="outlined"
              multiline
            />
          </div>
          <div className={classes.formField}>
            <InputLabel shrink htmlFor="resolution_summary">{t("action_form.field_action_resolution_summary_label")}</InputLabel>
            <TextField
              type="resolution_summary"
              name="resolution_summary"
              value={intervention.resolution_summary}
              onChange={this.setValue}
              fullWidth={true}
              variant="outlined"
              multiline
            />
          </div>
          <div className={classes.formField}>
            <div>
              {selectedClaim && selectedClaim.claim_priority ? (
                <div>
                  <InputLabel shrink >{t('action_form.claim_priority')}</InputLabel>
                  <Chip size="small" label={selectedClaim.claim_priority}  />
                </div>
              ):''}
              <InputLabel shrink >{t('action_form.claim')} <span className={classes.requiredSymbol}>*</span></InputLabel>
              {selectedClaim ? (
                <div className={classes.claimChip} label={selectedClaim.claim}>
                  <CloseIcon onClick={this.removeInterventionClaim} className={classes.close} />
                  {selectedClaim.claim}
                </div>
              ): (
                <ActionAutosuggest
                  handleSetClaim={this.handleClaimChange}
                  claims={trackedClaims}
                />
              )}
              {submitted && !intervention.tracked_claim_id ? requiredField : ''}
            </div>
          </div>
          <div>
            {hasMediaSighting ? (
               <div>
                <h4>{t('action_form.media_sighting')}</h4>
                {!intervention.intervention_id ? (
                  <p>{t('action_form.media_sighting_no_edit')}</p>
                ): ''}
             </div>
            ): (
              <h4>{t("action_form.custom_sighting")}</h4>
            )}
          </div>
          <div className={classes.formField}>
            <InputLabel shrink htmlFor="text">{t('action_form.sighting_text')} <span className={classes.requiredSymbol}>*</span></InputLabel>
            <TextField
              type="text"
              name="text"
              fullWidth={true}
              value={intervention.text}
              onChange={(e) => { this.setValueForField('text', e.target.value); }}
              disabled={isCreateWithMediaSighting}
              variant="outlined"
              multiline={true}
              style={{ marginTop: 11 }}
            />
            {submitted && !intervention.text ? requiredField : ''}
          </div>

          <div className={classes.formField+' sighting-category-field'}>
            <InputLabel shrink htmlFor="category">{t('action_form.sighting_category')} <span className={classes.requiredSymbol}>*</span></InputLabel>
            <Select
              placeholder={t('action_form.sighting_category')}
              defaultValue={this.getOptionForValue(intervention.category, suggestedCategories)}
              onChange={({value}) => { this.setValueForField('category', value); }}
              name="category"
              options={suggestedCategories}
              styles={selectStyles}
              isDisabled={isCreateWithMediaSighting}
            />
            {submitted && !intervention.category ? requiredField : ''}
          </div>
          <div className={classes.formField}>
            <InputLabel shrink htmlFor="publication_date">{t("action_form.field_sighting_date_label")}</InputLabel>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardDatePicker
                disableToolbar
                format="YYYY-MM-DD"
                margin="normal"
                id="publication-date-picker"
                name="publication_date"
                fullWidth={true}
                value={intervention.publication_date}
                onChange={(value) => { this.setValueForField('publication_date', value); }}
                cancelLabel={t('action_form.cancel')}
                okLabel={t('action_form.ok')}
                disabled={isCreateWithMediaSighting}
                variant="dialog"
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.formField}>
            <InputLabel shrink htmlFor="url">{t("action_form.field_sighting_url_label")}</InputLabel>
            <TextField
              type="url"
              name="url"
              fullWidth={true}
              value={intervention.url}
              onChange={(e) => { this.setValueForField('url', e.target.value); }}
              disabled={isCreateWithMediaSighting}
              variant="outlined"
              multiline
            />
          </div>
          <div className={classes.formField}>
            <InputLabel shrink htmlFor="author_name">{t('action_form.sighting_author')}</InputLabel>
             <WikiAutosuggest
                placeholder={t('action_form.sighting_author')}
                handleSuggestionSelect={({label, value})=>{
                  this.setValueForWikiId('author_name', 'wikidata_author_name_id', label, value);
                }}
                handleChange={(value) => { this.setValueForWikiField('author_name', 'wikidata_author_name_id', value); }}
                name="author_name"
                value={intervention.author_name}
                wikiId={intervention.wikidata_author_name_id}
                isDisabled={isCreateWithMediaSighting}
             />
          </div>
          <div className={classes.formField+ ' publication-field'} >
            <InputLabel shrink htmlFor="publication">{t('action_form.sighting_publication')} <span className={classes.requiredSymbol}>*</span></InputLabel>
            <WikiAutosuggest
                placeholder={t('action_form.sighting_publication')}
                handleSuggestionSelect={({label, value})=>{
                  this.setValueForWikiId('publication', 'wikidata_publication_id', label, value);
                }}
                handleChange={(value) => { this.setValueForWikiField('publication', 'wikidata_publication_id', value); }}
                name="publication"
                value={intervention.publication}
                wikiId={intervention.wikidata_publication_id}
                isDisabled={isCreateWithMediaSighting}
             />
             {submitted && !intervention.publication ? requiredField : ''}
          </div>
          <div className={classes.formField}>
            <Checkbox
              checked={isCreateWithMediaSighting ? true : intervention.action_due_to_afc}
              onChange={this.handleSightingFoundToggle}
              color="primary"
              value={true}
              name="action_due_to_afc"
            />
            <span>{t('action_form.discovered_through')}</span>
          </div>
          <div className={classes.formField}>
            {errorNotice ? (
              <Notice message={errorNotice} type="error" />
            ): ""}
            </div>
            <div className={classes.formField}>
              <Button
                role="button"
                type="submit"
                color="primary"
                variant="contained"
              >{!intervention.intervention_id ? t("action_form.button_create") : t("action_form.button_update")}</Button>
          </div>
        </form>
      );
    }
}

ActionForm.propTypes = {
  handleSubmit : PropTypes.func.isRequired,
  intervention: PropTypes.object,
  suggestedCategories: PropTypes.array,
  trackedClaims: PropTypes.array,
  statusTypes: PropTypes.array.isRequired,
  errorNotice: PropTypes.string,
  t: PropTypes.func,
  actionTypes: PropTypes.array,
  actionResolutionTypes: PropTypes.array,
  classes: PropTypes.object,
  theme: PropTypes.object
};

export default compose(
  withTranslation(),
  withTheme,
  withStyles((theme) => ({
      formField: {
        marginTop: '12px',
        '& .MuiOutlinedInput-multiline': {
          padding: '9px 14px'
        }
      },
      requiredSymbol: {
        color: '#b94a48',
        fontWeight: 'bold'
      },
      requiredFieldNotice: {
        color: '#b94a48',
        fontSize: '12px',
        marginTop: '12px'
      },
      claimChip: {
        backgroundColor: '#eee',
        borderRadius: '5px',
        position: 'relative',
        padding: '6px 24px 6px 6px',
        fontSize: '12px',
        lineHeight: '1.5'
      },
      close: {
        position: 'absolute',
        top: '4px',
        right: '2px',
        width: '30px',
        color: '#bbb',
        cursor: 'pointer',
        fontSize: '1.3rem'
      }
  }))
)(ActionForm);
