import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Select from 'react-select';

function ActionTableFilterValues(props) {
  const {
    handleValuesSelect,
    classes,
    t,
    field
  } = props;

  let selectedValues = props.selectedValues;
  let possibleValues = props.possibleValues || [];

  if(field === 'status') {
    selectedValues = selectedValues.map(value => ({value: value, label: t('action_statuses.'+value)}));
    possibleValues = possibleValues.map(poss => ({value: poss.name, label: t('action_statuses.'+poss.name)}));
  }
  if(field === 'category') {
    selectedValues = selectedValues.map(value => ({value: value, label: t('media_categories.'+value)}));
    possibleValues = possibleValues.map(poss => ({value: poss.name, label: t('media_categories.'+poss.name)}));
  }
  
  if(field === 'publication' || field === 'author_name') {
    selectedValues = selectedValues.map(value => ({value: value, label: value}));
    possibleValues = possibleValues.map(poss => ({value: poss.name, label: poss.name}));
  }

  const [selectValue, setSelectValue] = React.useState(selectedValues);

  function handleValuesChange(values){
    setSelectValue(values);
    let value = [];
    if(values && values.length) value = values.map(option => option.value);
    handleValuesSelect(value);
  }

  return (
    <div className={classes.valueSelector} style={{ width: '250px'}}>
      <Select
        value={selectValue}
        isMulti
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={(values) => {
          handleValuesChange(values);
        }}
        options={possibleValues}
        fullWidth={true}
      />
    </div>
  );
}

export default compose(
  withTranslation(),
  withStyles((theme) => ({
  valueSelector: {
    display: 'inline-block',
    zIndex: '2',
    marginLeft: '12px',
    maxWidth: '300px',
    overflow: 'visible',
    verticalAlign: 'middle'
  },
  singleTypeChip: {
    marginRight: '3px'
  },
  possibleValues: {
    padding: '12px'
  },
  valueSelectorBox : {
    fontWeight: '16px',
    border: '1px solid rgb(219, 219, 219)',
    display: 'inline-block',
    padding: '6px'
  },
  valueSelectorDefault : {
    color: '#666',
  }
}))
)(ActionTableFilterValues);

ActionTableFilterValues.propTypes = {
  handleValuesSelect: PropTypes.func,
  selectedValues: PropTypes.array,
  possibleValues: PropTypes.array,
  type: PropTypes.string,
  filterText: PropTypes.string,
  classes: PropTypes.object,
  t: PropTypes.func,
  field: PropTypes.string
};