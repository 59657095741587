import noticeConstants from 'Constants/noticeConstants';

export function addNotice(ref, notice){
  return {
      type: noticeConstants.ADD_NOTICE,
      ref: ref,
      notice: notice
  };
}

export function addTemporaryNotice(ref, notice, duration){
  return {
      type: noticeConstants.ADD_TEMP_NOTICE,
      ref: ref,
      notice: notice,
      duration: duration || 4000
  };
}

export function removeNotice(ref){
  return {
      type: noticeConstants.REMOVE_NOTICE,
      ref: ref
  };
}

export function clearNotices(){
  return {
    type: noticeConstants.CLEAR_NOTICES
  };
}

export default {
  addNotice: addNotice,
  addTemporaryNotice: addTemporaryNotice,
  removeNotice: removeNotice,
  clearNotices: clearNotices
};