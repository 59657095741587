import React from 'react';
import PropTypes from 'prop-types';
import SortableTable from 'Components/Common/SortableTable';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const DigestDismissedSightings = (props) => {
    const {
      dismissedSightings,
      removeMatchedSighting,
      canViewClaim,
      t
    } = props;
    return (
      <SortableTable
        rows={dismissedSightings}
        columns={[
          {
              width: 60,
              label: t('digest_dismissed_sightings.claim'),
              dataKey: 'tracked_claim_text',
              formatting: (data, rowData) => {
                if(rowData.tracked_claim_id && canViewClaim) {
                  return (<Link to={"/alerts/claims/"+rowData.tracked_claim_id}>{data}</Link>);
                } else {
                  return <span>{data}</span>;
                }
              }
          },
          {
            width: 60,
            label: t('digest_dismissed_sightings.alert_text'),
            dataKey: 'text',
            formatting: (data, rowData) => {
              if(rowData.url) {
                return <a href={rowData.url}>{data}</a>;
              } else {
                return <span>{data}</span>;
              }
            }
          },
          {
            width: 60,
            label: t('digest_dismissed_sightings.publication_date'),
            dataKey: 'publication_date',
            formatting: (data) => {
              if(data && data !== '') {
                return data.split('T')[0];
              } else {
                return data;
              }
            }
          },
          {
            width: 90,
            label: ' ',
            disableSort: true,
            dataKey: 'tracked_claim_id',
            formatting: (data, rowData) => {
              return (<Tooltip title={t('digest_dismissed_sightings.remove_dismissed')}>
              <IconButton size="small" onClick={() => {
                removeMatchedSighting(data, rowData.media_hash);
              }}><ClearIcon /></IconButton>
              </Tooltip>);
            }
          }
        ]}
      />
    );
};

DigestDismissedSightings.propTypes = {
  dismissedSightings: PropTypes.array,
  removeMatchedSighting: PropTypes.func,
  canViewClaim: PropTypes.bool,
  t: PropTypes.func
};


export default compose(
  withTranslation(),
  React.memo
)(DigestDismissedSightings);

