import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import NoticeContainer from 'Components/Common/NoticeContainer';
import ParticleCard from 'Components/Common/ParticleCard';


export const PublicRoute = ({ component: Component, ...rest }) => {
    return (<Route {...rest} render={props => {
        return (
          <ParticleCard>
              <section>
                <Component {...props} />
                <NoticeContainer displayCode="general_notice" dismissible={true} />
              </section>
          </ParticleCard>
      );
    }} />);
};

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired
};
