import authSelectors from 'Selectors/authSelectors';

export function getSightingCategoryDisplayName(sightingCategoryId) {
  
    let sightingCategoryDisplayNameById = {
      'online-newspapers': 'Online Newspapers',
      'social-media': 'Social Media',
      'parliament': 'Parliament'
    };
  
    return sightingCategoryDisplayNameById[sightingCategoryId] || sightingCategoryId;
  }
  
  export function getSightingCategories(state) {
    let sightingCategories = {};
    const config = authSelectors.getConfig(state);
    if(config.sightingCategories) sightingCategories = config.sightingCategories;
  
    return sightingCategories;
  }
  
  export function getSightingCategoriesList(state) {
  
    let categories = getSightingCategories(state);
    let categoriesList = [];
  
    for (let key of Object.keys(categories)) {
      categoriesList.push({
        value: key,
        label: categories[key].title
      });
    }
    return categoriesList;
  }

export default ({
    getSightingCategoryDisplayName: getSightingCategoryDisplayName,
    getSightingCategories: getSightingCategories,
    getSightingCategoriesList: getSightingCategoriesList
});
