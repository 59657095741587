import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import entityActions from 'Actions/entityActions';
import interventionActions from 'Actions/interventionActions';
import claimActions from 'Actions/claimActions';
import interventionSelectors from 'Selectors/interventionSelectors';
import claimSelectors from 'Selectors/claimSelectors';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import SortableTable from 'Components/Common/SortableTable';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export class EntitiesPage extends React.Component {

  constructor(props){
    super(props);
    this.props.interventionActions.loadInterventions();
    this.props.claimActions.loadClaims();

    this.state = {
      filterText: '',
      tabValue: 0
    };
  }

  filterChange = (e) => {
    this.setState({'filterText': e.target.value });
  }

  handleTabChange = (e, newValue) => {
    this.setState({ tabValue: newValue });
  }
  render() {
    let {
      t,
      classes,
      sightingPublications,
      sightingAuthors,
      claimAuthors
    } = this.props;

    const {
      filterText,
      tabValue
    } = this.state;

      if(filterText != '') {
       sightingPublications = sightingPublications.filter(item => item.name.toLowerCase().includes(filterText.toLowerCase())); 
       sightingAuthors = sightingAuthors.filter(item => item.name.toLowerCase().includes(filterText.toLowerCase())); 
       claimAuthors = claimAuthors.filter(item => item.name.toLowerCase().includes(filterText.toLowerCase())); 
      
      }

      return (
        <div className={classes.content}>
          <Typography variant="h2" component="h2">{t('entities_page.entities')}</Typography>
          <p>
            <SearchIcon className={classes.searchIcon} />
            <TextField
              className={classes.searchClaimTextField}
              id="standard-name"
              label={t('entities_page.filter_by_entity')}
              value={filterText}
              onChange={this.filterChange}
              margin="dense"
            />
          </p>
          
          <Box>
                <Tabs value={tabValue} onChange={this.handleTabChange}>
                  <Tab id="tab-action-pub"label={t('entities_page.action_pubs', {count: sightingPublications.length})} />
                  <Tab id="tab-action-auth" label={t('entities_page.action_authors', {count: sightingAuthors.length})} />
                  <Tab id="tab-claim-auth" label={t('entities_page.claim_authors',{count: claimAuthors.length})} />
                </Tabs>
              </Box>
          <Box hidden={tabValue !== 0}>
            <SortableTable
              rows={sightingPublications}
              columns={[
                {
                    width: 60,
                    label: t('entities_page.pub_name'),
                    dataKey: 'name',
                    formatting: (data, rowData) => {
                      return <Link to={'/actions/entities/'+rowData.id}>{data}</Link>;
                    }
                },
                {
                  label: t(['entities_page.num_of_actions_with_sightings']),
                  dataKey: 'count'
                }
                ]}
            />
          </Box>
          <Box hidden={tabValue !== 1}>
          <SortableTable
            rows={sightingAuthors}
            columns={[
              {
                  width: 60,
                  label: t('entities_page.author_name'),
                  dataKey: 'name',
                  formatting: (data, rowData) => {
                    return <Link to={'/actions/entities/'+rowData.id}>{data}</Link>;
                  }
              },
              {
                label: t('entities_page.num_ations_with_author'),
                dataKey: 'count'
              }
              ]}
          />
          </Box>
          <Box hidden={tabValue !== 2}>
          <SortableTable
            rows={claimAuthors}
            columns={[
              {
                  width: 60,
                  label: t('entities_page.author_name'),
                  dataKey: 'name',
                  formatting: (data, rowData) => {
                    return <Link to={'/actions/entities/'+rowData.id}>{data}</Link>;
                  }
              },
              {
                label: t('entities_page.num_claims_with_author'),
                dataKey: 'count'
              }
              ]}
          />
          </Box>
        </div>
      );
  }
}

EntitiesPage.propTypes = {
  claim: PropTypes.object,
  match: PropTypes.object.isRequired,
  claimActions: PropTypes.object,
  interventionActions: PropTypes.object,
  noticeActions: PropTypes.object,
  classes: PropTypes.object,
  matchedSightings: PropTypes.array,
  actions: PropTypes.array,
  t: PropTypes.object,
  isEditingClaim: PropTypes.bool,
  claimAuthors: PropTypes.array,
  sightingAuthors: PropTypes.array,
  sightingPublications: PropTypes.array
};

function mapStateToProps(state) {
  return {
    sightingPublications: interventionSelectors.getAllWikiPublications(state),
    sightingAuthors: interventionSelectors.getAllWikiAuthors(state),
    claimAuthors: claimSelectors.getAllWikiAuthors(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    entityActions: bindActionCreators(entityActions, dispatch),
    claimActions: bindActionCreators(claimActions, dispatch),
    interventionActions: bindActionCreators(interventionActions, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(),
  withStyles((theme) => ({
    grid: {
      display: 'grid',
      gridTemplateColumns: 'auto 300px',
      gridGap: '30px'
    },
    mainContent: {
      gridColumn: '1',
      alignSelf: 'start',
      padding: '12px'
    },
    secondaryContent: {
      gridColumn: '2'
    },
    cardContent: {
      padding: '12px',
      marginBottom: '24px'
    },
    content: {
      marginTop: '24px',
      margin: '0 auto'
    },
    claimCardLink: {
      color: '#009688',
    },
    paragraphText: {
      lineHeight: '1.3',
      marginBottom: '12px'
    },
    searchIcon: {
      margin: '0 6px 6px 0 ',
      verticalAlign: 'bottom'
    }
}))
)(EntitiesPage);
