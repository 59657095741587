import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import candidateActions from 'Actions/candidateActions';
import claimActions from 'Actions/claimActions';
import candidateSelectors from 'Selectors/candidateSelectors';
import noticeActions  from 'Actions/noticeActions';
import interventionActions from 'Actions/interventionActions';
import noticeSelectors from 'Selectors/noticeSelectors';
import moment from 'moment';
import CheckworthyTopicControls from 'Components/Checkworthy/CheckworthyTopicControls';
import CheckworthyTopicList from 'Components/Checkworthy/CheckworthyTopicList';
import CheckworthyAllTopics from 'Components/Checkworthy/CheckworthyAllTopics';

export class CheckworthyPage extends React.Component {

  constructor(props){
    super(props);
    let currentDay = (new Date()).toISOString().split('T')[0];
    let yesterday = moment(this.currentDay).subtract(1, 'd').locale('en').format('YYYY-MM-DD');
    const urlParams = new URLSearchParams(location.search);
    const params = {
      day: urlParams.get('day')
    };

    if(params.day) {
      currentDay = (new Date(params.day)).toISOString().split('T')[0];
    }

    const startTime = moment(currentDay+ " "+ "00:00:00").locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');
    const endTime =  moment(currentDay+ " "+ "23:59:59").locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');

    props.candidateActions.loadPublications();

    let urlTopic;
    if (props.match.params['topic']) {
      urlTopic = props.match.params['topic'];
      props.candidateActions.loadTopics(startTime, endTime, urlTopic);
    } else {
      props.candidateActions.loadTopicSummaries(startTime, endTime);
    }

    this.state = {
      topic: urlTopic || null,
      currentDay: currentDay,
      startTime: startTime,
      endTime: endTime,
      yesterday: yesterday
    };

  }

  handleTopicDateChange = (time, topic) => {
    const startTime = moment(time+ " "+ "00:00:00").locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');
    const endTime =  moment(time+ " "+ "23:59:59").locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');

    this.setState({
      startTime: startTime,
      endTime: endTime,
      topic: topic,
      currentDay: time
    });

    this.props.history.push('/home/' + topic);
    this.props.candidateActions.loadTopics(
      startTime,
      endTime,
      topic
    );
  }

  showMoreSentences = () => {
    this.setState({
        showMore: true
    });
  }

  render() {
    const {
      classes,
      t,
      checkworthyArticles,
      checkworthyTopics,
      topicMediaSentences,
      topicsWithoutSentences,
      history,
      isGettingTopics
    } = this.props;

    const {
      currentDay,
      topic,
    } = this.state;

    const {
      confirmSighting,
      removeReviewedSighting,
      dismissSighting
    } = this.props.candidateActions;

    return (
      <div className="row container">
        <div className="col-xs4-4">
          {topic != null && topic in checkworthyTopics ? (
            <div className={classes.content}>
              <CheckworthyTopicControls
                checkworthyTopics={checkworthyTopics}
                currentTopic={topic}
                handleTopicDateChange={this.handleTopicDateChange}
                currentDate={currentDay}
                history={history}
              />
              <CheckworthyTopicList
                currentTopic={topic}
                topicDisplayName={checkworthyTopics[topic]}
                dismissSighting={dismissSighting}
                removeReviewedSighting={removeReviewedSighting}
                confirmSighting={confirmSighting}
                checkworthyArticles={checkworthyArticles}
                isGettingTopics={isGettingTopics}
              />
            </div>
          ) : (
              <div>
              <div className={classes.content}>
                <CheckworthyAllTopics
                  topicMediaSentences={topicMediaSentences}
                  dismissSighting={dismissSighting}
                  removeReviewedSighting={removeReviewedSighting}
                  confirmSighting={confirmSighting}
                  topicsWithoutSentences={topicsWithoutSentences}
                  isGettingTopics={isGettingTopics}
                  currentDay={currentDay}
                  handleTopicDateChange={this.handleTopicDateChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}


function mapStateToProps(state, ownProps) {
    let topicParam;
    if(ownProps.match.params['topic']) topicParam = ownProps.match.params['topic'];
    return {
      notice: noticeSelectors.getNoticeById(state, 'page_notice'),
      checkworthyArticles: candidateSelectors.getCheckworthySentences(state, topicParam),
      topicMediaSentences: candidateSelectors.getCheckworthyTopicSummaries(state),
      topicsWithoutSentences: candidateSelectors.getCheckworthyTopicsWithoutSentences(state),
      checkworthyTopics: candidateSelectors.getCheckworthyDisplayNames(state),
      isGettingTopics: candidateSelectors.getRequestState(state, 'topics') === 'pending',

    };
}

function mapDispatchToProps(dispatch) {
    return {
      candidateActions: bindActionCreators(candidateActions, dispatch),
      claimActions: bindActionCreators(claimActions, dispatch),
      noticeActions: bindActionCreators(noticeActions, dispatch),
      interventionActions: bindActionCreators(interventionActions, dispatch)
    };
  }

CheckworthyPage.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object,
  themeColor: PropTypes.string,
  locale: PropTypes.string,
  authActions: PropTypes.object,
  classes:  PropTypes.object,
  candidateActions: PropTypes.object,
  claimActions: PropTypes.object,
  digestData: PropTypes.array,
  interventionActions: PropTypes.object,
  isEditingIntervention: PropTypes.bool,
  publications: PropTypes.array,
  sightingCategories: PropTypes.array,
  loadDigestCandidates: PropTypes.func,
  getDigestSentences: PropTypes.func,
  reviewedRequest: PropTypes.string,
  candidatesRequest: PropTypes.string,
  dismissSighting: PropTypes.func,
  removeMatchedSighting: PropTypes.func,
  permissions: PropTypes.array,
  loadPublications: PropTypes.func,
  claimMatchThreshold: PropTypes.number,
  checkworthyArticles: PropTypes.object,
  i18n: PropTypes.object,
  checkworthyTopics: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  topicMediaSentences: PropTypes.object,
  topicsWithoutSentences: PropTypes.array,
  isGettingTopics: PropTypes.bool,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(),
  withStyles((theme) => ({
    upperContent: {
      backgroundColor: '#fff',
      padding: '34px',
      marginLeft: '-12px',
      marginRight: '-12px'
    },
    content: {
        maxWidth: '1000px',
        margin: '0 auto',
    },
    '*[dir="rtl"]' : {
      content: {
        border: '1px solid red'
      }
    },
  }))
)(CheckworthyPage);
