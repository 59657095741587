import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from 'Components/Common/TablePagination';

function descendingTextComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function descendingNumberComparator(a, b, orderBy) {
  let aN = parseInt(a[orderBy],10);
  let bN = parseInt(b[orderBy],10);
  if (bN < aN) {
    return -1;
  }
  if (bN > aN) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy, dataType) {
  if(dataType === 'number') {
    return order === 'desc'
    ? (a, b) => descendingNumberComparator(a, b, orderBy)
    : (a, b) => -descendingNumberComparator(a, b, orderBy);
  } else {
    return order === 'desc'
    ? (a, b) => descendingTextComparator(a, b, orderBy)
    : (a, b) => -descendingTextComparator(a, b, orderBy);
  }
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function SortableTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const handleColumnSort = (order, orderBy) => {
    const isAsc = order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(orderBy);
  };
  const { rows, columns, classes, hasPagination }  = props;
  const sortedRows = stableSort(rows, getComparator(order, orderBy));
  let displayRows = sortedRows;
  let paginationStart, paginationEnd;
  let totalPages = 1;

  if(hasPagination) {
    let rowsByPage = {};
    const pageSize = 20;
    if(sortedRows.length) totalPages = Math.ceil(sortedRows.length/pageSize);
    for (let page = 1; page <= totalPages; page++) {
      let start, end;
      if(page > 1) {
        start = pageSize*(page-1);
        if(page === totalPages) {
          end = sortedRows.length;
        } else {
          end = pageSize*page;
        }
      } else {
        start = page-1;
        end = pageSize*page;
      }
      rowsByPage[page] = {
        start: start,
        end: end,
        rows: sortedRows.slice(start, end)
      };
    }
    paginationStart = rowsByPage[currentPage].start;
    paginationEnd = rowsByPage[currentPage].end;
    displayRows = rowsByPage[currentPage].rows;
  }
  const handleSetPage = (pageNum) => {
    setCurrentPage(pageNum);
  };

  return (
    <div className={classes.root}>
      {hasPagination ? (
        <TablePagination
          totalPages={totalPages}
          start={paginationStart}
          end={paginationEnd}
          currentPage={currentPage}
          handleSetPage={handleSetPage}
          totalResults={rows.length}
        />
      ): ''}
      <Paper className={classes.paper}>

          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.dataKey}
                    padding="normal"
                    sortDirection={orderBy === column.dataKey ? order : false}
                    align="left"
                  >
                    {column.disableSort ? column.label : (
                    <TableSortLabel
                      active={orderBy === column.dataKey}
                      direction={orderBy === column.dataKey ? order : 'asc'}
                      onClick={() => {  handleColumnSort(order, column.dataKey,  column.dataType); }}
                    >
                      {column.label}
                      {orderBy === column.dataKey ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayRows.map((row, index) => (
                  <TableRow
                    tabIndex={-1}
                    key={'tableRow' + index}
                  >
                    {columns.map((column, index) => (
                      <TableCell align="left" key={'tableColumn' + index}>
                        {column.formatting ? column.formatting( row[column.dataKey], row ) : row[column.dataKey]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
      </Paper>
      {hasPagination ? (
        <TablePagination
          totalPages={totalPages}
          start={paginationStart}
          end={paginationEnd}
          currentPage={currentPage}
          handleSetPage={handleSetPage}
          totalResults={rows.length}
        />
      ): ''}
    </div>
  );
}

export default withStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%'
  },
  table: {
    minWidth: 750,
    '& a': {
      color: theme.palette.primary[500]
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  claimLink: {
    color: '#009688'
  },
  tableCellHeader: {
    minWidth: '120px'
  }
}))(SortableTable);

SortableTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  classes: PropTypes.object,
  hasPagination: PropTypes.bool
};
