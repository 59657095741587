import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import UserChangePasswordFormContainer from 'Components/User/UserChangePasswordFormContainer';
import { bindActionCreators, compose } from 'redux';
import authActions from 'Actions/authActions';
import authSelectors from 'Selectors/authSelectors';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

export class UserPage extends React.Component {

  constructor(props){
    super(props);
  }

  handleChangeSetting  = (e) => {
    if(e.target.name === 'theme') {
      this.props.authActions.changeThemeColor(e.target.value);
    } else if(e.target.name === 'locale') {
      this.props.authActions.changeLocale(e.target.value);
    }
  }

  render () {
    const {
      classes,
      t,
      themeColor,
      locale,
      canChangeTheme,
      canChangeLanguage
    } = this.props;

    return (
      <div className="row container">
        <div className="col-xs4-4">
          <div className={classes.content}>
          <Typography variant="h2" component="h2">{t('user_page.page_title')}</Typography>
          <Typography variant="h6" component="h6">{t('user_page.change_password_title')}</Typography>
          <UserChangePasswordFormContainer />
          {canChangeTheme && (
            <div>
              <Typography variant="h2" component="h2">{t('user_page.settings.title')}</Typography>
              <div style={{marginBottom: '12px'}}>
                <InputLabel htmlFor="theme">{t('user_page.settings.theme')}</InputLabel>
                <Select
                  value={themeColor}
                  onChange={this.handleChangeSetting}
                  inputProps={{
                    name: 'theme',
                    id: 'theme'
                  }}
                >
                  <MenuItem value="light">{t('user_page.settings.theme.light')}</MenuItem>
                  <MenuItem value="dark">{t('user_page.settings.theme.dark')}</MenuItem>
                </Select>
              </div>
            </div>
          )}
          {canChangeLanguage && (
            <div>
              <InputLabel htmlFor="locale">{t('user_page.settings.language')}</InputLabel>
              <Select
                label="Language"
                value={locale}
                onChange={this.handleChangeSetting}
                inputProps={{
                  name: 'locale',
                  id: 'locale'
                }}
              >
                <MenuItem value="en">{t('user_page.settings.language.en')}</MenuItem>
                <MenuItem value="fr">{t('user_page.settings.language.fr')}</MenuItem>
                <MenuItem value="ar">{t('user_page.settings.language.ar')}</MenuItem>
              </Select>
            </div>
          )}
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    locale: authSelectors.getLocale(state),
    themeColor: authSelectors.getThemeColor(state),
    canChangeTheme: authSelectors.getCanChangeTheme(state),
    canChangeLanguage: authSelectors.getCanChangeLanguage(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

UserPage.propTypes = {
  t: PropTypes.func,
  themeColor: PropTypes.string,
  locale: PropTypes.string,
  authActions: PropTypes.object,
  classes:  PropTypes.object,
  canChangeTheme: PropTypes.bool,
  canChangeLanguage: PropTypes.bool
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(),
  withStyles((theme) => ({
    content: {
      marginTop: '24px'
    }
}))
)(UserPage);
