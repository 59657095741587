import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';

// Configure JSS
let jss; 

function TextDirection(props) {
 jss = create({ plugins: [...jssPreset().plugins, rtl({ enabled: props.hasRTL })] });
  return (
    <StylesProvider jss={jss}>
      {props.children}
    </StylesProvider>
  );
}


TextDirection.propTypes = {
    hasRTL: PropTypes.bool,
    children: PropTypes.object
  };
  
export default TextDirection;