import moment from 'moment';
import Papa from 'papaparse';
import interventionSelectors from 'Selectors/interventionSelectors';
import sightingSelectors from 'Selectors/sightingSelectors';
import authSelectors from 'Selectors/authSelectors';

export function getClaims(state) {
  return state.claimState.claims.allIds.map(claimId => state.claimState.claims.byId[claimId]);
}

export function getIsRequestingClaims(state) {
  return state.claimState.requestState === 'pending';
}

export function getStarredClaims(state) {
  let claims = state.claimState.claims.allIds.map(claimId =>  state.claimState.claims.byId[claimId]);
  return claims.filter(claim => claim.starred);
}

export function getClaimsCount(state) {
  return state.claimState.claims.allIds.length;
}

export function getMatchedSightingsByClaimId(state, claimId) {

  let claimSightings = state.claimState.reviewedSightings.byClaimId[claimId];
  if(claimSightings && claimSightings.allMediaHashes) {
      return claimSightings.allMediaHashes.map(mediaHash => claimSightings.byMediaHash[mediaHash]);
  } else {
    return [];
  }
}

export function getMatchedSightingsIds(state) {
  return state.claimState.reviewedSightings.allIds;
}

export function getMatchedSightingsCount(state) {
  if(state.claimState.reviewedSightings.allIds) {
      return state.claimState.reviewedSightings.allIds.length;
  } else {
    return 0;
  }
}

export function getReviewedSightingsRequestState(state) {
  return state.claimState.reviewedSightings.requestState;
}

export function getTotalSightingsCount(state) {
  let allSightings = [];
  let allSightingsMediaHashes = [];
  for(let claimId in state.claimState.reviewedSightings.byClaimId) {
    if(state.claimState.reviewedSightings.byClaimId[claimId]) {
      let reviewedSightings = state.claimState.reviewedSightings.byClaimId[claimId].allMediaHashes.map(mediaHash => state.claimState.reviewedSightings.byClaimId[claimId].byMediaHash[mediaHash]);

      allSightings = [
        ...allSightings,
        ...reviewedSightings
      ];
    }
  }
  allSightings = allSightings.filter(reviewedSighting => reviewedSighting.is_matched === true);

  state.interventionState.interventions.all.forEach(interventionId => {
    allSightings.push(state.interventionState.interventions.byId[interventionId].stored_sighting);
  });

  allSightings = allSightings.filter((sighting) => {
    if(!sighting.media_hash) {
      return true;
    } else if(!allSightingsMediaHashes.includes(sighting.media_hash)){
      allSightingsMediaHashes.push(sighting.media_hash);
      return true;
    } else {
      return false;
    }
  });
  return allSightings.length;
}

export function getTotalSightingsWithActionsCount(state) {
  let allSightings = [];
  let allSightingsMediaHashes = [];

  state.interventionState.interventions.all.forEach(interventionId => {
    allSightings.push(state.interventionState.interventions.byId[interventionId].stored_sighting);
  });

  allSightings = allSightings.filter((sighting) => {
    if(!sighting.media_hash) {
      return true;
    } else if(!allSightingsMediaHashes.includes(sighting.media_hash)){
      allSightingsMediaHashes.push(sighting.media_hash);
      return true;
    } else {
      return false;
    }
  });
  return allSightings.length;
}


export function getConfirmedSightingsWithActionCount(state) {
  let allReviewedSightings = [];
  for(let claimId in state.claimState.reviewedSightings.byClaimId) {
    if(state.claimState.reviewedSightings.byClaimId[claimId]) {
      let reviewedSightings = state.claimState.reviewedSightings.byClaimId[claimId].allMediaHashes.map(mediaHash => state.claimState.reviewedSightings.byClaimId[claimId].byMediaHash[mediaHash]);

      allReviewedSightings = [
        ...allReviewedSightings,
        ...reviewedSightings
      ];
    }
  }
  allReviewedSightings = allReviewedSightings.filter(reviewedSighting => reviewedSighting.is_matched === true);

  let allActionSightingsMediaHashes = [];
  state.interventionState.interventions.all.forEach(interventionId => {
    allActionSightingsMediaHashes.push(state.interventionState.interventions.byId[interventionId].stored_sighting.media_hash);
  });

  allReviewedSightings = allReviewedSightings.filter(reviewedSighting => allActionSightingsMediaHashes.includes(reviewedSighting.media_hash));

  return allReviewedSightings.length;

}

export function getMediaSightingsCount(state) {
  let allActionSightings = [];
  state.interventionState.interventions.all.forEach(interventionId => {
    allActionSightings.push(state.interventionState.interventions.byId[interventionId].stored_sighting);
  });

  allActionSightings = allActionSightings.filter(actionSighting => actionSighting.sighting_type !== 'manual_entry');

  return allActionSightings.length;

}

export function getMediaSightingsFromAlphaCount(state) {
  let allActionSightings = [];
  state.interventionState.interventions.all.forEach(interventionId => {
    if(state.interventionState.interventions.byId[interventionId].action_due_to_afc) {
      allActionSightings.push(state.interventionState.interventions.byId[interventionId].stored_sighting);
    }
  });

  allActionSightings = allActionSightings.filter(actionSighting => actionSighting.sighting_type !== 'manual_entry');

  return allActionSightings.length;
}

export function getCustomSightingsCount(state) {
  let allActionSightings = [];
  state.interventionState.interventions.all.forEach(interventionId => {
    allActionSightings.push(state.interventionState.interventions.byId[interventionId].stored_sighting);
  });

  allActionSightings = allActionSightings.filter(actionSighting => actionSighting.sighting_type === 'manual_entry');

  return allActionSightings.length;

}

export function getCustomSightingsFromAlphaCount(state) {
  let allActionSightings = [];
  state.interventionState.interventions.all.forEach(interventionId => {
    if(state.interventionState.interventions.byId[interventionId].action_due_to_afc) {
      allActionSightings.push(state.interventionState.interventions.byId[interventionId].stored_sighting);
    }
  });

  allActionSightings = allActionSightings.filter(actionSighting => actionSighting.sighting_type === 'manual_entry');

  return allActionSightings.length;

}

export function getClaimsWithPatternsCount(state) {
  let count = 0;
  let patternCount = 0;
  if(state.claimState.claims.allIds.length) {
    let claims = state.claimState.claims.allIds.map(claimId => state.claimState.claims.byId[claimId]);
    claims.forEach(claim => {
      if(claim.pattern && claim.pattern !== ''){
        patternCount++;
      }
      count++;
    });

  }
  return patternCount;
}

export function getPatternPercentage(state) {
  let count = 0;
  let patternCount = 0;
  if(state.claimState.claims.allIds.length) {
    let claims = state.claimState.claims.allIds.map(claimId => state.claimState.claims.byId[claimId]);
    claims.forEach(claim => {
      if(claim.pattern && claim.pattern !== ''){
        patternCount++;
      }
      count++;
    });

  }
  if(count && patternCount){
    return (Math.floor((patternCount/count) * 100));
  } else {
    return 0;
  }
}


export function getSortedAndFilteredClaims(state) {
  let items =  state.claimState.claims.allIds.map(claimId => state.claimState.claims.byId[claimId]);
  let order = state.claimState.orderedBy;
  let filters = Object.assign({}, state.claimState.filters);

  if(filters.dateRange) {
    if(filters.dateRange.dateFrom) {
      items = items.filter(item => {
        return moment(item.created_at).isAfter(filters.dateRange.dateFrom);
      });
    }
    if(filters.dateRange.dateUntil) {
      items = items.filter(item =>  moment(item.created_at).isBefore(filters.dateRange.dateUntil));
    }
  }

  if(filters.containingText !== '') {
    items = items.filter(item => (item.claim || "").toLowerCase().indexOf(filters.containingText.toLowerCase()) != -1);
  }

  if(filters.starred) {
    if(filters.starred === 'starred') {
      items = items.filter(item => item.starred);
    } else  if(filters.starred === 'not-starred') {
      items = items.filter(item => !item.starred);
    }
  }

  if(filters.pattern) {
    if(filters.pattern === 'pattern') {
      items = items.filter(item => (item.pattern && item.pattern !== ''));
    } else if (filters.pattern === 'no-pattern') {
      items = items.filter(item => (!item.pattern || item.pattern === ''));
    }
  }

  if(order === 'desc') items = items.reverse();

  return items;
}

export function getMatches(state) {
  return state.claimState.results || {};
}

export function getMatchesById(state, id) {
  return state.claimState.results[id] || {};
}

export function getClaimByEditingId(state) {
  return state.claimState.claims.byId[state.claimState.editingClaimId];
}

export function getClaimById(state, claimId) {

  return state.claimState.claims.byId[claimId];
}

export function getIsEditing(state) {
  return state.claimState.editingClaim;
}

export function getSortedBy(state) {
  return state.claimState.sortedBy;
}

export function getOrderedBy(state) {
  return state.claimState.orderedBy;
}

export function getHasReceivedClaims(state) {
  return state.claimState.hasReceivedClaims;
}

export function getClaimText(state, claimId) {
  let claimText = '';
  if(state.claimState.claims.byId && state.claimState.claims.byId[claimId]) {
    claimText = state.claimState.claims.byId[claimId].claim;
  }
  return claimText;
}

export function getClaimFackcheck(state, claimId) {
  let claimUrl = '';
  if(state.claimState.claims.byId && state.claimState.claims.byId[claimId]) {
    claimUrl = state.claimState.claims.byId[claimId].factcheck_url;
  }
  let claim = state.claimState.claims.byId[claimId];
  return claimUrl;
}

export function getFilters(state) {
  return state.claimState.filters;
}

export function generateCSV(state) {
  let csvContent;
  let items = getSortedAndFilteredClaims(state);
  if(items) {
    csvContent = "data:text/csv;charset=utf-8," + Papa.unparse(items, { columns: ['claim', 'conclusion', 'factcheck_url', 'created_at', 'updated_at']});
  }
  return csvContent;
}

export function getClaimsByClaimIds(state, claimIds) {
  let selectedClaims = [];
  if(claimIds && state.claimState.claims.allIds.length){
    selectedClaims = state.claimState.claims.allIds.filter(item => ( claimIds.indexOf(item['tracked_claim_id']) !== -1));
    selectedClaims = selectedClaims.map(claimId => state.claimState.claims.byId[claimId]);
  }
  return selectedClaims;
}

export function getCheckingSentences(state) {
  return state.claimState.checkingSentences;
}

export function getYearsFromClaims(state) {
    let years = [];
    let claims = state.claimState.claims.allIds.map(claimId => state.claimState.claims.byId[claimId]);
    claims.forEach(claim => {
      let claimYear = claim.created_at.split('-')[0];
      claimYear = parseInt(claimYear,10);
      if(!years.includes(claimYear)) years.push(claimYear);
    });
    years.sort();
    return years;
}

export function getMatchedSightingsForEditingId(state) {
  if(state.claimState.claims.byId[state.claimState.editingClaimId]) {
    return state.claimState.reviewedSightings.byClaimId[state.claimState.editingClaimId];
  } else {
    return [];
  }
}

export function getActionSigthingsForEditingId(state) {

  let actionSightings = [];

  if(state.claimState.editingClaimId) {
    let interventions = interventionSelectors.getInterventionsByClaimId(state, state.claimState.editingClaimId);
    interventions.forEach((intervention) => {
      let actionSightingsWithEditingId  = actionSightings.filter(actionSighting => actionSighting.storedSightingId === intervention.stored_sighting.stored_sighting_id);
      if(!actionSightingsWithEditingId.length){
        actionSightings.push({
          url: intervention.stored_sighting.url,
          text: intervention.stored_sighting.text,
          mediaHash: intervention.stored_sighting.media_hash || '',
          storedSightingId: intervention.stored_sighting.stored_sighting_id,
        });
      }
    });
  }

  return actionSightings;

}


export function getActionsCountBySightingId(state, sightingId) {

  let actions = [];

  state.interventionState.interventions.all.forEach((interventionId) => {
    let intervention = state.interventionState.interventions.byId[interventionId];
    if(intervention.stored_sighting.stored_sighting_id === sightingId && !actions.includes(intervention.intervention_id)){
      actions.push(intervention.intervention_id);
    }
  });

  return actions.length;

}

export function getReviewedSightings(state) {
  // needs to combine reviewed sightings data
  let  allReviewedSightings = [];

  for(const claimId in state.claimState.reviewedSightings.byClaimId) {
    if(state.claimState.reviewedSightings.byClaimId[claimId]){
      let reviewedSightingsForClaim = state.claimState.reviewedSightings.byClaimId[claimId].allMediaHashes.map((mediaHash) => {
        let storedSighting = state.claimState.reviewedSightings.byClaimId[claimId].byMediaHash[mediaHash];
        storedSighting.category;
        storedSighting.category_label = sightingSelectors.getSightingCategoryDisplayName(storedSighting.category);
        storedSighting.actionsCount = getActionsCountBySightingId(state, storedSighting.stored_sighting_id);
        let sightingClaim = state.claimState.claims.byId[storedSighting.tracked_claim_id];
        if(sightingClaim) storedSighting.tracked_claim_text = sightingClaim.claim;
        return storedSighting;
      });
      allReviewedSightings = [
        ...allReviewedSightings,
        ...reviewedSightingsForClaim
      ];
    }
  }
  allReviewedSightings.sort((sightingA, sightingB) => {
    let sightingAPublicationDate = new Date(sightingA.publication_date);
    let sightingBPublicationDate = new Date(sightingB.publication_date);
    if (sightingAPublicationDate > sightingBPublicationDate) return -1;
    if (sightingAPublicationDate < sightingBPublicationDate) return 1;
    return 0;
  });


  let hashes = [];
  let dedupedSightings = [];
  allReviewedSightings.forEach((sighting)=> {
    let stub = sighting.tracked_claim_id+sighting.media_hash;
    if(!hashes.includes(stub)) {
      hashes.push(stub);
      dedupedSightings.push(sighting);
    }
  });

  return dedupedSightings;
}


export function getConfirmedSightings(state) {
  // needs to combine reviewed sightings data
  let  allReviewedSightings = [];

  for(const claimId in state.claimState.confirmedSightings.byClaimId) {
    if(state.claimState.confirmedSightings.byClaimId[claimId]){
      let reviewedSightingsForClaim = state.claimState.confirmedSightings.byClaimId[claimId].allMediaHashes.map((mediaHash) => {
        let storedSighting = state.claimState.confirmedSightings.byClaimId[claimId].byMediaHash[mediaHash];
        if (!storedSighting) return null;
        storedSighting.category;
        storedSighting.category_label = sightingSelectors.getSightingCategoryDisplayName(storedSighting.category);
        storedSighting.actionsCount = getActionsCountBySightingId(state, storedSighting.stored_sighting_id);
        let sightingClaim = state.claimState.claims.byId[storedSighting.tracked_claim_id];
        if(sightingClaim) storedSighting.tracked_claim_text = sightingClaim.claim;
        return storedSighting;
      }).filter(Boolean); // Remove any null entries

      allReviewedSightings = [
        ...allReviewedSightings,
        ...reviewedSightingsForClaim
      ];
    }
  }

  allReviewedSightings.sort((sightingA, sightingB) => {
    let sightingAPublicationDate = new Date(sightingA.publication_date);
    let sightingBPublicationDate = new Date(sightingB.publication_date);
    if (sightingAPublicationDate > sightingBPublicationDate) return -1;
    if (sightingAPublicationDate < sightingBPublicationDate) return 1;
    return 0;
  });

  let hashes = [];
  let dedupedSightings = [];
  allReviewedSightings.forEach((sighting)=> {
    let stub = sighting.tracked_claim_id+sighting.media_hash;
    if(!hashes.includes(stub)) {
      hashes.push(stub);
      dedupedSightings.push(sighting);
    }
  });

  return dedupedSightings;
}

export function getConfirmedSightingsCount(state) {
  let count  = null;
  if (state.claimState.confirmedSightings.count) count = state.claimState.confirmedSightings.count;
  return count;
}

export function getConfirmedSightingsCursor(state) {
  let cursor  = null;
  if (state.claimState.confirmedSightings.nextCursor) cursor = state.claimState.confirmedSightings.nextCursor;
  return cursor;
}

export function getIsGettingConfirmedSightings(state) {
  return state.claimState.confirmedSightings.requestState == 'pending';
}

export function getDismissedSightings(state) {
  // needs to combine reviewed sightings data
  let  allReviewedSightings = [];

  for(const claimId in state.claimState.dismissedSightings.byClaimId) {
    if(state.claimState.dismissedSightings.byClaimId[claimId]){
      let reviewedSightingsForClaim = state.claimState.dismissedSightings.byClaimId[claimId].allMediaHashes.map((mediaHash) => {
        let storedSighting = state.claimState.dismissedSightings.byClaimId[claimId].byMediaHash[mediaHash];
        if (!storedSighting) return null;
        storedSighting.category;
        storedSighting.category_label = sightingSelectors.getSightingCategoryDisplayName(storedSighting.category);
        storedSighting.actionsCount = getActionsCountBySightingId(state, storedSighting.stored_sighting_id);
        let sightingClaim = state.claimState.claims.byId[storedSighting.tracked_claim_id];
        if(sightingClaim) storedSighting.tracked_claim_text = sightingClaim.claim;
        return storedSighting;
      }).filter(Boolean);
      allReviewedSightings = [
        ...allReviewedSightings,
        ...reviewedSightingsForClaim
      ];
    }
  }
  allReviewedSightings.sort((sightingA, sightingB) => {
    let sightingAPublicationDate = new Date(sightingA.publication_date);
    let sightingBPublicationDate = new Date(sightingB.publication_date);
    if (sightingAPublicationDate > sightingBPublicationDate) return -1;
    if (sightingAPublicationDate < sightingBPublicationDate) return 1;
    return 0;
  });


  let hashes = [];
  let dedupedSightings = [];
  allReviewedSightings.forEach((sighting)=> {
    let stub = sighting.tracked_claim_id+sighting.media_hash;
    if(!hashes.includes(stub)) {
      hashes.push(stub);
      dedupedSightings.push(sighting);
    }
  });

  return dedupedSightings;
}

export function getDismissedSightingsCount(state) {
  let count = 0;
  if (state.claimState.dismissedSightings.count) count = state.claimState.dismissedSightings.count;
  return count;
}

export function getDismissedSightingsCursor(state) {
  let cursor = 0;
  if (state.claimState.dismissedSightings.nextCursor) cursor = state.claimState.dismissedSightings.nextCursor;
  return cursor;
}

export function getIsGettingDismissedSightings(state) {
  return state.claimState.dismissedSightings.requestState == 'pending';
}

export function getClaimsByAuthorId(state, authorId) {
  let claims = getClaims(state);
  claims = claims.filter(claim => claim.authorId === authorId);
  return claims;
}

export function getAllWikiAuthors(state) {
  let claims = getClaims(state);
  claims = claims.filter(claim => claim.authorId);
  let allAuthIds = [];
  let authors = [];
  claims.forEach((claim) => {
    let count = 1;
    if(!allAuthIds.includes(claim.authorId)) {
      allAuthIds.push(claim.authorId);
      authors[claim.authorId] = claim.authorName;
    } else {
      count = authors[claim.authorId].count + 1;
    }

    authors[claim.authorId] = {
      name: claim.authorName,
      count: count
    };
  });
  authors = allAuthIds.map(id => ({
    name: authors[id].name,
    id: id,
    count: authors[id].count
  }));
  authors.sort(function(a, b) {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  return authors;
}


export function getSummarisedMatches(state) {

  let matches = getMatches(state);
  let sentencesByClaims = {};
  let claimIds = [];
  let totalMatchCount = 0;
  if(matches) {
    let sentenceIds = Object.keys(matches);
    sentenceIds.forEach((sentenceId) => {
      if(matches[sentenceId].autoMatches.length) {
        totalMatchCount += matches[sentenceId].autoMatches.length;
      }
      if(matches[sentenceId].exactMatches.length) {
        matches[sentenceId].exactMatches.forEach((match) => {
          if(!sentencesByClaims[match.tracked_claim_id]) {
            claimIds.push(match.tracked_claim_id);
            sentencesByClaims[match.tracked_claim_id] = {
              sentences: [],
              claim: match.claim
            };
          }
          sentencesByClaims[match.tracked_claim_id].sentences.push({
            sentenceId: sentenceId,
            sentenceText: matches[sentenceId].sentenceText
          });
        });
        totalMatchCount += matches[sentenceId].exactMatches.length;
      }
      
    });
  }

  return {
    sentencesByClaims,
    claimIds,
    totalMatchCount
  };
}
export function getHasTPFC(state) {
  const config = authSelectors.getConfig(state);
  return config.hasTPFC;
}

export default ({
  getClaims,
  getClaimsCount,
  getClaimsWithPatternsCount,
  getPatternPercentage,
  getSortedAndFilteredClaims,
  getMatches,
  getMatchesById,
  getClaimByEditingId,
  getIsEditing,
  getSortedBy,
  getOrderedBy,
  getClaimText,
  getHasReceivedClaims,
  getFilters,
  getStarredClaims,
  generateCSV,
  getClaimsByClaimIds,
  getClaimFackcheck,
  getIsRequestingClaims,
  getCheckingSentences,
  getYearsFromClaims,
  getMatchedSightingsForEditingId,
  getActionSigthingsForEditingId,
  getMatchedSightingsCount,
  getMatchedSightingsByClaimId,
  getClaimById,
  getMatchedSightingsIds,
  getActionsCountBySightingId,
  getReviewedSightings,
  getConfirmedSightingsWithActionCount,
  getCustomSightingsCount,
  getCustomSightingsFromAlphaCount,
  getMediaSightingsFromAlphaCount,
  getMediaSightingsCount,
  getTotalSightingsCount,
  getTotalSightingsWithActionsCount,
  getClaimsByAuthorId,
  getAllWikiAuthors,
  getReviewedSightingsRequestState,
  getSummarisedMatches,
  getConfirmedSightings,
  getConfirmedSightingsCount,
  getConfirmedSightingsCursor,
  getIsGettingConfirmedSightings,
  getDismissedSightings,
  getDismissedSightingsCount,
  getDismissedSightingsCursor,
  getIsGettingDismissedSightings,
  getHasTPFC
});
