import authConstants from '../constants/authConstants';
import initialState from './initialState';

export default function authState(state = initialState.authState, action) {
  let unauthorisedActions;
  switch (action.type) {
    case `${authConstants.LOGIN}_REQUEST`:
      return {
        ...state,
        loginError: null
      };
    case `${authConstants.LOGIN}_SUCCESS`: {
      let newSession = {
        user: {
          email: action.response.user.email,
          userId: action.response.user.user_id,
          displayName: action.response.user.display_name,
          organisation: action.response.user.organisation.name,
          organisationId: action.response.user.organisation.organisation_id,
          organisationDisplayName: action.response.user.organisation.display_name,
          role: action.response.user.role,
          isVerified: action.response.user.verified
        },
        accessToken: action.response.access_token,
        refreshToken: action.response.refresh_token
      };
      localStorage.setItem('user', JSON.stringify(newSession));
      let conf = {};
      if(action.response.config){
        const userConf = action.response.config[action.response.user.organisation.name];
        if(userConf) {
          conf.locale = userConf.default?.language || 'en';
          conf.thresholds = {
            claimMatch:  userConf.frontend.claim_match_threshold,
            checkworthiness: userConf.frontend.checkworthy_threshold,
          }
          conf.additionalLogos = userConf.frontend.additional_logos;
          conf.claimantGroups = userConf.frontend.claimant_groups;
          conf.permissions = userConf.frontend.permissions;
        }
      }
      return {
        ...state,
        user: {...newSession.user},
        accessToken: newSession.accessToken,
        refreshToken: newSession.refreshToken,
        loginRequestState: 'success',
        config: conf
      };
    }
    case `${authConstants.LOGIN}_PENDING`:
      return {
        ...state,
        loginRequestState: 'pending'
      };
    case `${authConstants.LOGIN}_FAILURE`:
      let possibleOrgs = null;
      if(action.response && action.response.organisations && action.response.organisations.length) {
        possibleOrgs = [...action.response.organisations];
      }
      return Object.assign({}, state, {
        loginFailed: true,
        loginError: action.error.message,
        possibleOrgs: possibleOrgs,
        loginRequestState: 'failed'
      });

    case `${authConstants.LOAD_USER}_SUCCESS`:
      if(action.auth){
        return {
          ...state,
          accessToken: null,
          refreshToken: action.auth.refreshToken,
          user: {
            email: action.auth.user.email,
            userId: action.auth.user.userId,
            displayName: action.auth.user.displayName,
            organisation: action.auth.user.organisation,
            organisationId: action.auth.user.organisationId,
            organisationDisplayName:  action.auth.user.organisationDisplayName,
            role: action.auth.user.role,
            isVerified: action.auth.user.isVerified
          }
        };
      } else {
        return state;
      }
    case `${authConstants.LOAD_USER}_FAILURE`:
      localStorage.removeItem('user');
      return Object.assign({}, initialState.authState, { initialised: true });


    case authConstants.LOGOUT:
      return Object.assign({}, initialState.authState, { initialised: true});

    case authConstants.REFRESHING_TOKEN:
      return {
        ...state,
        pendingRefreshingToken: true,
        tokenIsValid: false
      };

    case `${authConstants.REFRESHING_TOKEN}_FAILURE`:
        localStorage.removeItem('user');
        return Object.assign({}, initialState.authState, { initialised: true });

    case authConstants.TOKEN_REFRESHED:
      let conf = {};
      if(action.response.config){
        const userConf = action.response.config[action.response.user.organisation.name];
        conf.locale = userConf.default.language
        conf.thresholds = {
          claimMatch:  userConf.frontend.claim_match_threshold,
          checkworthiness: userConf.frontend.checkworthy_threshold,
        }
        conf.additionalLogos = userConf.frontend.additional_logos;
        conf.claimantGroups = userConf.frontend.claimant_groups;
        conf.permissions = userConf.frontend.permissions;
      }
      return {
        ...state,
        pendingRefreshingToken: null,
        tokenIsValid: true,
        accessToken: action.response.access_token,
        initialised: true,
        config: conf
      };

    case authConstants.UNAUTHORISED_ACTION:
      let unauthorisedActions = state.unauthorisedActions.slice();
      unauthorisedActions.push(action.action);
      return {
        ...state,
        unauthorisedActions: unauthorisedActions
      };

    case authConstants.CLEAR_UNAUTHORISED_ACTIONS:
      return { ...state,
        unauthorisedActions: []
      };

    default:
      return state;
  }
}