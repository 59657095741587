import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import SortableTable from 'Components/Common/SortableTable';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

function MediaPublicationsTable(props) {

  const {
    classes,
    addMedia,
    canEdit,
    organisationMedia,
    t,
    showDeleteMediaDialog,
    editMedia
  } = props;
  
  return (
    <div className={classes.cc}>
        <div className={classes.addMediaButton}>
            {canEdit && <Button className="addMediaButton" type="submit" variant="contained" color="primary" onClick={addMedia}>{t('organisation_media.add_media')}</Button>}
        </div>
        {organisationMedia.length !== 0 ? (
          <SortableTable
          rows={organisationMedia}
          columns={[
            {
              width: 90,
              label: ' ',
              disableSort: true,
              dataKey: 'edit',
              formatting: (data, rowData) => {
                if(canEdit) {
                  return (
                      <Button onClick={() => {
                        editMedia(rowData);
                      }} color="primary" variant="contained">{t('organisation_media.button.edit')}</Button>       
                  );
                } else {
                  return null;
                }
              }
          },
            {
              label: t('organisation_media.publication_name'),
              dataKey: 'publicationName'
            },
            {
              label: t('organisation_media.url'),
              dataKey: 'mediaUrl'
            },
            {
              label: t('organisation_media.status'),
              dataKey: 'mediaIsValid',
              formatting: (data, rowData) => {
                if (rowData.mediaIsValid === null) {
                  return t('organisation_media.no_data');
                } else if (rowData.mediaIsValid === true) {
                  return (
                    <Tooltip title={t('organisation_media.ran_successfully', { time: moment.utc(rowData.mediaLastScraped).local().fromNow()})}>
                      <IconButton size="small"><CheckIcon /></IconButton>
                    </Tooltip>
                  );
                } else if (rowData.mediaIsValid === false) {
                  if (rowData.mediaLastScraped) {
                    return (
                      <Tooltip title={t('organisation_media.failing_since',{time: moment.utc(rowData.mediaLastScraped).local().fromNow()})}>
                        <IconButton size="small"><ErrorIcon /></IconButton>
                      </Tooltip>
                    );
                  } else {
                    return (
                      <Tooltip title={t('organisation_media.failing')}>
                        <IconButton size="small"><ErrorIcon /></IconButton>
                      </Tooltip>
                    );
                  }
                }
              }
            },
            {
              width: 90,
              label: ' ',
              disableSort: true,
              dataKey: 'Delete',
              formatting: (data, rowData) => {
                if(canEdit) {
                  return (
                    <Tooltip title={t('organisation_media.delete_feed')}>
                      <IconButton size="small" onClick={() => { showDeleteMediaDialog(rowData);}}><DeleteIcon /></IconButton>
                    </Tooltip>
                  );
                } else {
                  return null;
                }
              }
            }
          ]}
        />
        ): ""}
        </div>
  );
}

export default compose(
  withTranslation(),
  withStyles((theme) => ({
    addMediaButton: {
        marginBottom: '12px',
        paddingTop: '16px',
        marginLeft: '16px',
        display: 'block'
      },
  }))
)(MediaPublicationsTable);

MediaPublicationsTable.propTypes = {
  classes: PropTypes.object,
  addMedia: PropTypes.func,
  canEdit: PropTypes.bool,
  organisationMedia: PropTypes.array,
  t: PropTypes.func,
  showDeleteMediaDialog: PropTypes.func,
  editMedia: PropTypes.func
};
