import React from 'react';
import UserChangePasswordFormContainer from 'Components/User/UserChangePasswordFormContainer';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export class ChangePasswordPage extends React.Component {

  constructor(props){
    super(props);

  }

  render() {
    const { t } = this.props;
    return (
      <div className="row container">
        <div className="col-xs4-4">
          <h4>{t('change_password_page.page_title')}</h4>
          <UserChangePasswordFormContainer />
        </div>
      </div>
    );
  }
}


ChangePasswordPage.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(ChangePasswordPage);
