import authConstants from 'Constants/authConstants';


export function login(organisation, email, password) {
  return {
    type: authConstants.LOGIN,
    organisation: organisation,
    email: email,
    password: password
  };
}

export function logout() {
  return {
    type: authConstants.LOGOUT
  };
}

export function locationChanged(location, user) {
  return {
    type: authConstants.LOCATION_CHANGED,
    location: location
  };
}

export function loadUser() {
  return {
    type: authConstants.LOAD_USER
  };
}

export function changePassword(password) {
  return {
    type: authConstants.CHANGE_PASSWORD,
    password: password
  };
}

export function forgotPassword(email) {
  return {
    type: authConstants.FORGOT_PASSWORD,
    email: email
  };
}

export function changePasswordWithToken(token, password) {
  return {
    type: authConstants.CHANGE_PASSWORD_WITH_TOKEN,
    password: password,
    token: token
  };
}

export function changeThemeColor(color) {
  return {
    type: authConstants.CHANGE_THEME_COLOR,
    color: color
  };
}

export function changeLocale(locale) {
  return {
    type: authConstants.CHANGE_CURRENT_LOCALE,
    locale: locale
  };
}

export function initialiseApp(){
  return {
    type: authConstants.INITIALISE_APP
  };
}
export default {
  login: login,
  logout: logout,
  locationChanged: locationChanged,
  loadUser: loadUser,
  changePassword: changePassword,
  forgotPassword: forgotPassword,
  changePasswordWithToken: changePasswordWithToken,
  initialiseApp: initialiseApp,
  changeThemeColor: changeThemeColor,
  changeLocale: changeLocale
};
