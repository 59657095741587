import initialState from './initialState';

export default function entityState(state = initialState.entityState, action) {
  switch (action.type) {

    case 'GET_ENTITY_SUCCESS':
      let newEntity = action.response.entities[action.request.ref];
      let occupations = {
        'Q82955': 'Politician',
        'Q1930187': 'Journalist'
      };
      let politicalParties = {
        'Q9626': 'Conservative Party',
        'Q9624': 'Liberal Democrats',
        'Q9630': 'Labour Party'
      };
      
      let occupation = '';
      let memberOf = '';
      let ownedBy = '';
      let image = '';
      let isHuman = false;


      if(newEntity.claims['P31']) {
        let instanceOfId = newEntity.claims['P31'][0].mainsnak.datavalue.value.id;
        if(instanceOfId === 'Q5') isHuman = true;
      }

      if(newEntity.claims['P106']) {
        let occupationId = newEntity.claims['P106'][0].mainsnak.datavalue.value.id;
        occupation = occupations[occupationId] || '';
      }

      if(newEntity.claims['P106']) {
        let occupationIds = newEntity.claims['P106'].filter(occ => occ.rank === 'preferred');
        if(occupationIds.length){
          occupationIds = occupationIds.map(occ => occ.mainsnak.datavalue.value.id);
        } else {
          occupationIds = newEntity.claims['P106'].map(occ => occ.mainsnak.datavalue.value.id);
        }
        occupationIds = occupationIds.map(occ => occupations[occ] || '');
        occupation = occupationIds.join(', ');
      }
      if(newEntity.claims['P102']) {
        let partyId = newEntity.claims['P102'][0].mainsnak.datavalue.value.id;
        memberOf = politicalParties[partyId] || '';
      }

      if(newEntity.claims['P127']) {
        ownedBy = newEntity.claims['P127'][0].mainsnak.datavalue.value.id;
      }

      // use logo if it has one, otherwise use image
      if(newEntity.claims['P154']) {
        image = newEntity.claims['P154'][0].mainsnak.datavalue.value;
      } else if(newEntity.claims['P18']) {
        image = newEntity.claims['P18'][0].mainsnak.datavalue.value;
      }

      return {
         ...state,
         entities: {
           ...state.entities,
           [action.request.ref]: {  
               label: newEntity.labels.en.value, 
               description: newEntity.descriptions.en.value,
               url: newEntity.sitelinks.enwiki.url,
               occupation: occupation,
               memberOf: memberOf,
               ownedBy: ownedBy,
               image: image,
               wikiId: action.request.ref,
               isHuman: isHuman
           }
         }
      };

    case 'GET_SUGGESTIONS_SUCCESS':
        let results = action.response.search.map(result => ({value: result.id, label: result.label, description: result.description}));
        return {
            ...state,
            suggestions: results
        };
    default:
      return state;

  }
}
