import React from 'react';
import PropTypes from 'prop-types';

import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js/dist/plotly-basic';

// to display for auto matches
const LiveMatchRoboChart = (props) => {
  const Plot = createPlotlyComponent(Plotly);

  const  {
    data,
    layout
  } =   props;

  return(
      <Plot
        data={data}
        style={{width: '100%', height: '300px', margin: '20px 0', clear: "both"}}
        useResizeHandler={true}
        config={{displayModeBar: false}}
        layout={layout}
      />
  );
};

LiveMatchRoboChart.propTypes = {
  data: PropTypes.array,
  layout: PropTypes.object
};

export default LiveMatchRoboChart;
