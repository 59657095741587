import noticeConstants from 'Constants/noticeConstants';
import initialState from './initialState';

export default function noticeState(state = initialState.noticeState, action) {
  let notices;
  switch (action.type) {

    case noticeConstants.ADD_NOTICE:
      return {
         ...state,
         notices: {
           ...state.notices,
           [action.ref]: action.notice
         }
      };

    case noticeConstants.ADD_TEMP_NOTICE:
      return {
         ...state,
         notices: {
           ...state.notices,
           [action.ref]: action.notice
         }
      };

    case noticeConstants.REMOVE_NOTICE:
      notices = {...state.notices};
      delete notices[action.ref];
      return {
        ...state,
        notices: notices
      };

    case noticeConstants.CLEAR_NOTICES:
      return {...initialState.noticeState};
    default:
      return state;
    }
}
