import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import ContentEditable from 'react-simple-contenteditable';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import moment from 'moment';
import { withTranslation, Trans } from 'react-i18next';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Button from '@material-ui/core/Button';

export class TranscriptDetailsVideo extends React.Component {

  constructor(props){
    super(props);

    let date = moment(Date.now()).format('DD/MM/YY');

    this.state = {
      date: date,
      name: props.transcript.transcriptName,
      currentTime: props.selectedSentenceTime || 0
    };
    this.props.handleSentenceTimeChange(props.selectedSentenceTime)

    this.canPlayVideo = !this.props.transcript.isOpen;

    if(!window.onYouTubeIframeAPIReady) {
      let tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      tag.id = "youtube-player-script";
      this.videoId = this.getVideoUrl()
      if(!document.getElementById("youtube-player-script") && this.videoId) {
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      } else {
        this.canPlayVideo = false
      }
      
      window.onYouTubeIframeAPIReady = () => {
        this.addVideoPlayer()
      }

      window.onPlayerReady = () => {}

      window.playVideo = (time) => {
        if(time) {
          this.player.seekTo(time);
          this.player.playVideo();
        } else {
          this.player.playVideo()
        }
        
      }
      this.reportSeconds = null;
      window.onPlayerStateChange = (event) => {
        if(event.data === 3) {
          this.reportSeconds = setInterval(() => {
            this.handleTimeChange(this.player.getCurrentTime())
          }, 500)
        } else if (event.data === 2) {
          clearInterval(this.reportSeconds)
        }
      }
    }
  }


  componentDidMount = () => {
    this.addVideoPlayer()
  }

  addVideoPlayer = () => {
    let videoId =  this.getVideoUrl()
    if(!this.props.transcript.isOpen && window.YT && videoId) {
      this.player = new window.YT.Player('transcript-youtube-player', {
        height: '100%',
        width: '100%',
        videoId: videoId,
        host: 'https://www.youtube-nocookie.com',
        playerVars: {
          'playsinline': 1,
          origin: window.location.host
        },
        events: {
          'onReady': window.onPlayerReady,
          'onStateChange': window.onPlayerStateChange
        }
      });
      
      this.canPlayVideo = true
    } else {

      this.canPlayVideo = false
    }
  }

  getVideoUrl = () => {
    let videoId = null;
    const sourceUrl = this.props.transcript.sourceUrl;
    /*
      current no good way to scrub through previously live videos
      if(sourceUrl.startsWith('https://www.youtube.com/live/')) {
      videoId = sourceUrl.split('/')[4].split('?')[0]
    } else 
    */
    if(sourceUrl.startsWith('https://www.youtube.com/')) {
      videoId = sourceUrl.split('=')[1];
    } else if(sourceUrl.startsWith('https://youtu.be/')) {
      videoId = sourceUrl.split('/')[3].split('?')[0];
    }

    return videoId
  }

  handleStop = () => {
    this.props.handleStopTranscript(this.props.transcript.transcriptId);
  }

  handleNameOnChange = () =>{
    return null;
  }

  handleTimeChange = (currentTime) =>{
    const time = parseInt(Math.floor(currentTime),10)
    if(Number.isInteger(time) && (time !== this.state.currentTime)) {
      this.setState({
        currentTime: time
      },() => {
        this.props.handleSentenceTimeChange(time)
      })
      
    }
  }


  handleNameKeyDown = (e) => {
    if(e.key === 'Enter'){
      e.preventDefault();
      if(e.target.innerHTML !== '') {
        this.props.handleChangeName(this.props.transcript.transcriptId, e.target.innerHTML);
        this.setState({ name: e.target.innerHTML });
        e.target.blur();
      }
    }
  }

  render() {
    const {
      canChangeName,
      classes,
      notice,
      t,
      isOpen
    } = this.props;

    const {
      channelName,
      startTime,
      endTime,
      sourceUrl
    } = this.props.transcript;

    let sourceLink = sourceUrl ? (
      <span> Source: <a href={sourceUrl} target="_blank" className={classes.sourceUrlLink}>YouTube<OpenInNewIcon className={classes.openInNewIcon} /></a></span>
    ) : '';

    let dates = '';
    if(startTime) {
      let momentStartDate = moment.utc(startTime).local();
      if(!endTime) {
          dates = (<div className={classes.startDate}>{momentStartDate.format('YYYY-MM-DD')}</div>);
      } else {
        let momentEndDate = moment.utc(endTime).local();
        if(momentStartDate.format('YYYY-MM-DD') === momentEndDate.format('YYYY-MM-DD')){
          let momentDuration = moment.duration(momentEndDate.diff(momentStartDate));
          let duration = parseFloat(momentDuration.asHours()).toFixed(2);
          if(duration < 1) {
            duration = (duration * 100) + 'mins';
          } else {
            duration = duration+'hrs';
          }
          dates = (
            <div className={classes.startDate}>
              {momentStartDate.format('YYYY-MM-DD HH:mm') +' to '+ momentEndDate.format('HH:mm')} — {duration}
            </div>
          );
        } else {
          dates = (
            <div className={classes.startDate}>
              <Trans i18nKey="transcript_details.date_range" parent="div">
                {momentStartDate.format('YYYY-MM-DD')} to  {momentEndDate.format('YYYY-MM-DD')}
              </Trans>
            </div>
          );
        }
      }
    }

    let showNotice = '';
    if(notice) {
      showNotice = (<CheckIcon />);
    }

    let youtubePlayerClasses = classes.youtubePlayerContainer;
    if(!this.canPlayVideo) {
      youtubePlayerClasses += ' ' + classes.hiddenPlayer;
    }

    let stopButton = '';
    if(isOpen) {
      stopButton = (
      <div className={classes.stopButton}>
        <Button
            label={t('transcript_details.stop_button')}
            onClick={this.handleStop}
            color="primary"
            variant="contained"
        >{t('transcript_details.stop_button')}</Button>
      </div>
      );
    }
    return (
      <div className={classes.details}>
        <div className={classes.mainHeader}>
          <div className={classes.mainHeaderDetails}>
            <Typography variant="h3" color="textPrimary" className={classes.transcriptTitleContainer}>
              {canChangeName ? (
                  <ContentEditable
                    html={this.state.name}
                    tagName="span"
                    className={classes.transcriptTitle}
                    onChange={this.handleNameOnChange}
                    onKeyPress={this.handleNameKeyDown}
                    contentEditable="plaintext-only"
                  />
              ): (
                <span className={classes.transcriptTitle}>{this.state.name}</span>
              )}
            </Typography>
          </div>
          <span className={classes.source}>{sourceLink} {stopButton}</span>
          <div className={youtubePlayerClasses}>
          <div className={classes.youtubePlayer}>
            <div id="transcript-youtube-player" />
          </div>
          </div>
        </div>
      </div>
    );
  }
}

TranscriptDetailsVideo.propTypes = {
    classes: PropTypes.object,
    transcript: PropTypes.object,
    notice: PropTypes.object,
    t: PropTypes.func,
    i18n: PropTypes.object,
    canChangeName: PropTypes.bool,
    handleChangeName: PropTypes.func,
    handleStopTranscript: PropTypes.func,
    selectedSentenceTime: PropTypes.number,
    handleSentenceTimeChange: PropTypes.func,
    isOpen: PropTypes.bool
};

export default compose(
  withTranslation(),
  withStyles((theme) => ({
    stopButton: {
      display: 'inline-block',
      marginLeft: '6px'
    },
    mainHeader: {
      marginRight: '45px',
    },
    avatar: {
      display: 'inline-block',
      verticalAlign: 'top',
      margin: '8px 24px 8px 0',
      '& img': {
        width: '56px',
        height: '56px'
      }
    },
    startDate: {
      display: 'inline-block',
      fontSize: '15px',
      marginTop: '0',
      marginBottom: '0',
      marginRight: '12px'
    },
    youtubePlayerContainer: {
      position: 'absolute',
      top: '112px',
      height: '250px',
      width: '100%',
      textAlign: 'center',
      backgroundColor: theme.palette.background.paper,
      borderBottom: '1px solid ' + theme.palette.divider,
    },
    youtubePlayer: {
      width: '400px',
      height: '100%',
      display: 'inline-block'
    },
    hiddenPlayer: {
      display: 'none'
    },
    details: {
      padding: '24px 0 16px 0', 
      position: 'fixed',
      zIndex: '1',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid ' + theme.palette.divider,
      borderWidth: '1px 0',
      marginTop: '0',
      marginBottom: '12px',
      marginLeft: '-12px',
      marginRight: '-12px',
      '& h3 span' : {
        fontSize: '28px'
      },
      '& label': {
        display: 'inline-block',
        marginLeft: '12px',
        verticalAlign: 'top'
      }
    },
    transcriptTitle: {
      display: 'block',
      margin: '0 22px 0 12px',
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '44px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingRight: '50px'

    },
    [theme.breakpoints.up('sm')] : {
      details: {
        marginTop: '0',
        padding: '24px 0 16px 0', 
      },
    },
    [theme.breakpoints.up('md')] : {
      details: {
        padding: '24px 0 16px 0', 
        marginTop: '0',
        '& form' : {
          width: '640px;'
        }
      },
      avatar: {
        marginLeft: '24px'
      },
      transcriptTitle: {
        fontSize: '34px'
      },
      youtubePlayerContainer: {
        top: '112px',
        right: '0',
        left: 'auto',
        height: '310px',
        width: '40%',
        backgroundColor: 'none',
        borderBottom: 'none',
      },
      youtubePlayer: {
        width: '100%'
      },
    },
    historic: {
      fontSize: '15px',
      padding: '0 12px',
      display: 'inline-block',
      color: '#666'
    },
    meta: {
      marginTop: '4px',
      marginBottom: '8px'
    },
    source : {
      marginBottom: '4px',
      display: 'block',
      marginLeft: '12px'
    },
    nameAndDate: {
      display: 'inline-block'
    },
    transcriptTitleContainer: {
      display: 'block',
      margin: '0 12px 0 0'
    },
    transcriptDate: {
      display: 'inline-block',
      marginLeft: '12px'
    },
    nameUpdate: {
      fontSize: '24px',
      color: '#8BC34A'
    },
    sourceUrlLink: {
      color: theme.palette.primary[500],
      display: 'inline-block',
      marginRight: '12px'
    },
    openInNewIcon: {
      width: '15px',
      margin: '0 0 0 6px',
      verticalAlign: 'middle'
    },
}))
)(TranscriptDetailsVideo);