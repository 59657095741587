import initialState from './initialState';
import interventionConstants from 'Constants/interventionConstants';

export default function interventionState(state = initialState.interventionState, action) {
  switch (action.type) {

    case `${interventionConstants.GET_INTERVENTIONS}_PENDING`:
      return {
        ...state,
          interventions: {
            ...state.interventions,
            requestState: 'pending'
        }
      };
    case `${interventionConstants.GET_INTERVENTIONS}_SUCCESS`: {

      let interventionsById = {};
      let interventionsIds = [];

      let interventions = [...action.response.interventions];
      interventions.sort((a,b) => {
        let aClaim = a['intervened_at'];
        let bClaim = b['intervened_at'];
        if (aClaim > bClaim)
          return -1;
        if (aClaim < bClaim)
          return 1;
        return 0;
      });
      interventions.forEach((intervention) => {
        interventionsIds.push(intervention.intervention_id);
        interventionsById[intervention.intervention_id] = intervention;
      });

      return {
        ...state,
          interventions: {
            ...state.interventions,
            byId: interventionsById,
            all: interventionsIds,
            requestState: 'success'
          }
      };

    }
    case `${interventionConstants.GET_INTERVENTION}_SUCCESS`: {

      let interventionsById = {};
      let interventionsIds = [];

      let interventions = [...action.response.interventions];
      interventions.sort((a,b) => {
        let aClaim = a['intervened_at'];
        let bClaim = b['intervened_at'];
        if (aClaim > bClaim)
          return -1;
        if (aClaim < bClaim)
          return 1;
        return 0;
      });
      interventions.forEach((intervention) => {
        interventionsIds.push(intervention.intervention_id);
        interventionsById[intervention.intervention_id] = intervention;
      });

      return {
        ...state,
          interventions: {
            ...state.interventions,
            byId: interventionsById,
            all: interventionsIds,
            requestState: 'success'
          }
      };
    }
    case `${interventionConstants.GET_INTERVENTIONS_BY_CLAIM_ID}_PENDING`:
      return {
        ...state,
          interventions: {
            ...state.interventions,
            requestState: 'pending'
        }
      };

    case `${interventionConstants.GET_INTERVENTIONS_BY_CLAIM_ID}_SUCCESS`:

      let claimInterventionsById = {};
      let claimInterventionsIds = [];

      action.response.interventions.forEach((intervention) => {
        claimInterventionsIds.push(intervention.intervention_id);
        claimInterventionsById[intervention.intervention_id] = intervention;
      });

      return {
        ...state,
          interventions: {
            ...state.interventions,
            byId: claimInterventionsById,
            all: claimInterventionsIds,
            requestState: 'success'
          }
      };

    case `${interventionConstants.CREATE_INTERVENTION}_SUCCESS`: {
      let allInterventions = state.interventions.all.slice();
      allInterventions.unshift(action.response.intervention_id);

      let createInterventionsById = {...state.interventions.byId};
      createInterventionsById[action.response.intervention_id] = action.response;

      return {
          ...state,
          interventions: {
            ...state.interventions,
            all: allInterventions,
            byId: createInterventionsById
          }
      };
    }
    case `${interventionConstants.DELETE_INTERVENTION}_SUCCESS`: {
      let allInterventions = state.interventions.all.slice();
      allInterventions = allInterventions.filter(interventionId => interventionId !== action.request.interventionId);

      let deleteinterventionsById = {...state.interventions.byId};
      delete deleteinterventionsById[action.request.interventionId];

      return {
          ...state,
          interventions: {
            ...state.interventions,
            all: allInterventions,
            byId: deleteinterventionsById
          }
      };
    }
    case interventionConstants.START_EDITING_INTERVENTION:
      return {
          ...state,
          interventions: {
            ...state.interventions,
            isEditing: true
          }
      };

    case interventionConstants.STOP_EDITING_INTERVENTION:
      return {
          ...state,
          interventions: {
            ...state.interventions,
            isEditing: false
          }
      };

    case `${interventionConstants.UPDATE_INTERVENTION}_SUCCESS`:
      return {
          ...state,
          interventions: {
            ...state.interventions,
            byId: {
              ...state.interventions.byId,
              [action.response.intervention_id]: action.response
            }
          }
      };

    case interventionConstants.SET_INTERVENTIONS_SORT:
      return {
          ...state,
          sortedBy: action.sort
      };

    case interventionConstants.SET_INTERVENTIONS_ORDER:
      return {
          ...state,
          orderedBy: action.order
      };

    default:
      return state;
  }
}