export const authConstants = {
    LOGIN: 'LOGIN',
    LOGOUT: 'USERS_LOGOUT',
    LOAD_USER: 'LOAD_USER',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    REFRESHING_TOKEN: 'REFRESHING_TOKEN',
    TOKEN_REFRESHED: 'TOKEN_REFRESHED',
    UNAUTHORISED_ACTION: 'UNAUTHORISED_ACTION',
    LOCATION_CHANGED: 'LOCATION_CHANGED',
    CLEAR_DATA: 'CLEAR_DATA',
    CLEAR_UNAUTHORISED_ACTIONS: 'CLEAR_UNAUTHORISED_ACTIONS',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    CHANGE_PASSWORD_WITH_TOKEN: 'CHANGE_PASSWORD_WITH_TOKEN',
    CHANGE_THEME_COLOR: 'CHANGE_THEME_COLOR',
    CHANGE_CURRENT_LOCALE: 'CHANGE_CURRENT_LOCALE',
    INITIALISE_APP: 'INITIALISE_APP',
    LOAD_APP_CONFIG: 'LOAD_APP_CONFIG'
};

export default authConstants;
