import {default as allConfig} from '../config';
import permissions from '../config/default/permissions';

export function getOrgDisplayName(state) {
  if(state.authState.user){
    const config = getConfig(state);
    if(config.displayName) {
      return config.displayName;
    } else {
      return state.authState.user.organisationDisplayName;
    }
  } else {
    return '';
  }
}

export function getUnauthorisedActions(state) {
  return state.authState.unauthorisedActions;
}

export function getRefreshToken(state) {
  return state.authState.refreshToken;
}

export function getPermissions(state) {
    const config = getConfig(state);

    let allPermissions = [...permissions, ...config.permissions]

    if(state.authState.user && state.authState.user.role === 'administrator'){
      allPermissions = [...allPermissions, 'admin']
    }

    if(state.authState.config.permissions) {
      allPermissions = [...allPermissions, ...state.authState.config.permissions]
    }

    return allPermissions;
}

export function getConfig() {
  return allConfig.defaultOrg;
}

export function getHasPagePermission(state, page) {
  let hasPermission = false;
  const permissions = getPermissions(state);
  if(permissions.indexOf(page) !== -1) hasPermission = true;

  return hasPermission;
}

export function getUser(state) {
  return state.authState.user || {
    email: '',
    organisation: '',
    role: ''
  };
}

export function getIsLoggedIn(state) {
  if(state.authState.user && state.authState.accessToken) {
    return true;
  } else {
    return false;
  }
}

export function getIsAppReady(state) {
  if(state.appState.authBase && state.authState.accessToken && state.authState.user) {
    return true;
  } else {
    return false;
  }
}

export function getPossibleOrganisations(state){
  let orgs = [];
  let orgList = null;
  if(state.authState.possibleOrgs) {
    orgs = [...state.authState.possibleOrgs];
    orgList = orgs.map((org) => {
      let displayName = org.display_name || org.name;
      return {
        value: org.name,
        label: displayName
      };
    });

    orgList.sort((a,b) => {
      let aLabel = a.label.toLowerCase();
      let bLabel = b.label.toLowerCase();
      if (aLabel > bLabel) return 1;
      if (aLabel < bLabel) return -1;
      return 0;
    });
  }

  return orgList;
}

export function getOrgAvatarImg(state){
  return `${state.appState.apiBase}/organisations/${state.authState.user.organisation}/logo`;
}

export function getAppConfig(state){
  return state.appState;
}

export function getHasAppConfig(state){
  return state.appState && state.appState.apiBase !== "";
}

export function getAppIsLoading(state) {
  return !(getHasAppConfig(state) && state.authState.initialised && (!state.authState.user || (state.authState.user && state.authState.accessToken)));
}

export function getMqttUrl(state) {
    return state.appState.mqtt || "";
}

export function getLocale(state) {
    return state.appState.locale || state.authState.config?.locale || "en";
}

export function getThemeColor(state) {
    return state.appState.theme || "light";
}

export function getIsAdmin(state) {
  return state.authState.user.role === "administrator";
}

export function getAuthorisedRoutes(state) {
    let tln = [];
    const permissions = getPermissions(state);
    if(permissions.includes('checkworthy')) tln.push({ key: 'checkworthy', to: '/home/' });
    if(permissions.includes('search')) tln.push({ key: 'search', to: '/search/' });
    if(permissions.includes('alerts')) tln.push({ key: 'alerts', to: '/alerts/' });
    if(permissions.includes('live')) tln.push({ key: 'live', to: '/live/' });
    if(permissions.includes('actions')) tln.push({ key: 'actions', to: '/actions/' });

    return tln;
}

export function getPagePermissions(state) {
  return getPermissions(state);
}

export function getAdditionalLogos(state) {
  return state.authState.config.additionalLogos;
}

export function getHomePage(state) {
  const permissions = getPermissions(state);
  if(permissions.includes('checkworthy')) {
    return 'checkworthy'
  }
  return 'search'
}

export function getIsLoggingIn(state) {
  return state.authState.loginRequestState === 'pending';
}

export function getTextDirection(state) {
  const config = getConfig(state);
  const locale = getLocale(state);
  const lang = locale.split('-')[0];
  if(lang && config.rtlLanguages?.includes(lang)) {
    return 'rtl';
  }
  return 'ltr';
}

export function getCanChangeTheme(state) {
  const config = getConfig(state);
  return config.canChangeTheme || false;
}

export function getCanChangeLanguage(state) {
  const config = getConfig(state);
  return config.canChangeLanguage || false;
}

export function getMediaLocale(state) {
  const config = getConfig(state);
  return 'en';
}

export default ({
  getOrgDisplayName,
  getUnauthorisedActions,
  getRefreshToken,
  getPermissions,
  getUser,
  getIsLoggedIn,
  getOrgAvatarImg,
  getAppConfig,
  getAppIsLoading,
  getMqttUrl,
  getLocale,
  getThemeColor,
  getHasPagePermission,
  getAuthorisedRoutes,
  getIsAdmin,
  getConfig,
  getHomePage,
  getPossibleOrganisations,
  getIsLoggingIn,
  getTextDirection,
  getCanChangeTheme,
  getCanChangeLanguage,
  getMediaLocale,
  getAdditionalLogos,
  getPagePermissions
});