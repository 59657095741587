import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Notice from 'Components/Common/Notice';

function RootError(props) {
  const {
    notice,
    classes,
    t
  } = props;


  return (
    <div style={{textAlign: 'center', height: '100%', margin: '40vh auto 0 auto', width: '500px'}}>
      <Notice message={notice.message} type={notice.type}/>
    </div>
  );
}


export default compose(
  withTranslation(),
  withStyles(() => ({
    candidatesManager: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    candidatesTable: {
      flexGrow: 1,
      height: '100%'
    }
  }))
)(RootError);

RootError.propTypes = {
  notice: PropTypes.object,
  classes: PropTypes.object,
  t: PropTypes.func
};


