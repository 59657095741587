import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

export function SearchSavedSearches(props) {

  const {
    classes,
    searchText,
    onSavedSearchClick,
    onEditSavedSearchClick,
    t
  } = props;
  
  const [savedSearches, setSavedSearches] = React.useState({isLoaded: false, data: []});
  const [loadedSavedSearchIndex, setLoadedSavedSearchIndex] = React.useState(-1);
  const [savedSearchName, setSavedSearchName] = React.useState('');
  const [showMore, setShowMore] = React.useState(false);
  const [showSavedSearchDialog, setShowSavedSearchDialog] = React.useState(false);
  const isEditing = (loadedSavedSearchIndex != -1);
  const loadedSavedSearchName = isEditing ? savedSearches.data[loadedSavedSearchIndex].name : '';
  const loadedSavedSearchText = isEditing ? savedSearches.data[loadedSavedSearchIndex].searchText : '';
  function handleShowMoreToggle(e) {
    setShowMore(!showMore);
  }
  
  if (!savedSearches.isLoaded) {
    
    if (localStorage.getItem('candidatesSavedSearches') === null) {
      localStorage.setItem('candidatesSavedSearches', JSON.stringify([]));
    }
    
    setSavedSearches({
      isLoaded: true,
      data: (JSON.parse(localStorage.getItem('candidatesSavedSearches')))
    });
  }

  function saveSearch(){
    setShowSavedSearchDialog(false);

    if (isEditing && savedSearchName === savedSearches.data[loadedSavedSearchIndex].name) {
      // Save over the existing data
      savedSearches.data[loadedSavedSearchIndex].searchText = searchText;
      localStorage.setItem('candidatesSavedSearches', JSON.stringify(savedSearches.data));
      setSavedSearches({isLoaded: true, data: [...savedSearches.data]});
    } else {
      // Add a new entry (Always save to local storage first)
      let newData = [...savedSearches.data, {name: savedSearchName, searchText: searchText}];
      localStorage.setItem('candidatesSavedSearches', JSON.stringify(newData));
      setSavedSearches({isLoaded: true, data: newData});
    }
  }

  function handleSavedSearchClick(e, index) {
    e.stopPropagation();
    setLoadedSavedSearchIndex(index);
    onSavedSearchClick(savedSearches.data[index].searchText);
  }
  
  function handleEditClick(e, index) {
    e.stopPropagation();
    setLoadedSavedSearchIndex(index);
    onEditSavedSearchClick(index);
  }
  
  function handleDeleteClick(e, index) {
    e.stopPropagation();
    
    let newData = savedSearches.data.slice(0, index).concat(savedSearches.data.slice(index + 1));
    localStorage.setItem('candidatesSavedSearches', JSON.stringify(newData));
    setSavedSearches({isLoaded: true, data: newData});
  }
  
  function handleHideSavedSearchDialog() {
    setShowSavedSearchDialog(false);
  }

  function handleShowSavedSearchDialog() {
    setSavedSearchName(loadedSavedSearchName);
    setShowSavedSearchDialog(true);
  }

  function handleChangeSearchName(e) {
    setSavedSearchName(e.target.value);
  }

  return (
    <Card className={classes.container + (showMore ? ' ' + classes.containerShowMore : '')} variant="outlined">
      <h3><SaveIcon color="primary" className={classes.icon}/>{t('candidates_saved_searches.saved_search_header')}</h3>
      <List className={classes.list + (showMore ? ' ' + classes.listShowMore : '')}>
        {
          (savedSearches && savedSearches.isLoaded && savedSearches.data && savedSearches.data.length > 0) &&
            savedSearches.data.map((savedSearch, index) =>
              (
                <li key={index}
                    className={classes.listItem}
                    onClick={(e) => handleSavedSearchClick(e, index)}>
                  <span className={classes.listItemText}>{index + 1}. {savedSearch.name}</span>
                  <EditIcon color="primary"
                    className={classes.editIcon}
                    onClick={(e) => handleEditClick(e, index)}/>
                  <DeleteIcon color="primary"
                    className={classes.deleteIcon}
                    onClick={(e) => handleDeleteClick(e, index)}/>
                </li>
              )
            )
        }
      </List>
      {
        (!savedSearches || !savedSearches.data || savedSearches.data.length == 0) &&
          <Box className={classes.noneFound}>{t('candidates_saved_searches.saved_search_no_saved')}</Box>
      }
      <Box m={1}/>
      <h5 className={classes.showMore} onClick={handleShowMoreToggle}>
      {
        (savedSearches && savedSearches.data && savedSearches.data.length > 10) &&
          (showMore ?
          <Box><span>{t('candidates_saved_searches.saved_search_show_less')}</span> <ExpandLessIcon className={classes.icon}/></Box> :
          <Box><span>{t('candidates_saved_searches.saved_search_show_more')}</span> <ExpandMoreIcon className={classes.icon}/></Box>)
      }
      </h5>
      {searchText !== '' &&
        <Button
          variant="contained"
          color="primary"
          onClick={handleShowSavedSearchDialog}>
          {t('candidates_saved_searches.saved_search_button')}
        </Button>
      }
            <Dialog
              open={showSavedSearchDialog}
              onClose={handleHideSavedSearchDialog}
            >
              <DialogTitle id="deleteaction-dialog-title">{t('candidates_saved_searches.dialog.title')}</DialogTitle>
              <DialogContent>
                  {t('candidates_saved_searches.saved_search_enter_name')}
                  <TextField className={classes.searchNamedTextField}
                    fullWidth={true}
                    label={t('candidates_saved_searches.name_field.label')}
                    margin="normal"
                    name="claim"
                    onChange={handleChangeSearchName}
                    type="text"
                    value={savedSearchName}
                  />
                  {isEditing && t('candidates_saved_searches.saved_search_override_save',{ savedSearchName: loadedSavedSearchName})}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleHideSavedSearchDialog} color="secondary" variant="contained" autoFocus>
                  {t('candidates_saved_searches.dialog.cancel')}
                </Button>
                <Button onClick={saveSearch} color="primary" variant="contained" autoFocus>
                  {t('candidates_saved_searches.dialog.save')}
                </Button>
              </DialogActions>
            </Dialog>
    </Card>
  );
}

SearchSavedSearches.propTypes  = {
  classes: PropTypes.object,
  searchText: PropTypes.string,
  onSavedSearchClick: PropTypes.func,
  onEditSavedSearchClick: PropTypes.func,
  t: PropTypes.func
};

export default compose(
  withTranslation(),
  withStyles((theme) => ({
    searchNamedTextField: {
      marginBottom: '24px'
    },
    container: {
      boxShadow: theme.outline.boxShadow,
      border: theme.outline.border,
      padding: '0 15px 15px 15px',
    },
    containerShowMore: {
      maxHeight: '1040px',
    },
    icon: {
      verticalAlign: 'middle',
      marginRight: '10px'
    },
    list: {
      maxHeight: '255px',
      overflow: 'hidden'
    },
    listShowMore: {
      maxHeight: '876px',
    },
    listItem: {
      padding: '2px 0',
      '&:hover': {
        backgroundColor: theme.palette.primary[50],
        cursor: 'pointer'
      }  
    },
    listItemText: {
      display: 'inline-block',
      maxWidth: '224px',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    editIcon: {
      width: '16px',
      height: '16px',
      position: 'absolute',
      right: '22px'
    },
    deleteIcon: {
      width: '16px',
      height: '16px',
      position: 'absolute',
      right: 0
    },
    showMore: {
      margin: '10px 0'
    },
    noneFound: {
      textAlign: 'center',
      marginBottom: '30px'
    }
  }))
)(SearchSavedSearches);