import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Notice from 'Components/Common/Notice';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker
  } from '@material-ui/pickers';


export class TranscriptScheduleForm extends Component {

  constructor(props) {
      super(props);

      let schedule = {};
      if(props.schedule) {
        schedule = {
          ...props.schedule,
          start_time: moment().format('YYYY-MM-DD ' + props.schedule.start_time),
          end_time: moment().add(1,'hour').format('YYYY-MM-DD ' + props.schedule.end_time),
        };
      } else {
        schedule = {
          display_name: "",
          publication_id: "",
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          days: [(moment().day() + 6) % 7 + 1],
          start_time: moment().format('YYYY-MM-DD HH:mm'),
          end_time: moment().add(1,'hour').format('YYYY-MM-DD HH:mm')
        };
      }
      
      this.state = {
          submitted: false,
          schedule: {
            ...schedule
          }
      };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if(!this.props.processingSubmit) {
      const { schedule } = this.state;
      let scheduleFields = {
        "display_name": schedule.display_name,
        "type": "weekly",
        "configuration": {
          "timezone": schedule.timezone,
          "days": schedule.days,
          "start_time": moment(schedule.start_time).locale('en').format('HH:mm'),
          "end_time": moment(schedule.end_time).locale('en').format('HH:mm')
        }
      };
      if(schedule.uuid) {
        scheduleFields.uuid = schedule.uuid;
      } else {
        scheduleFields.publication_id = schedule.publication_id;
      }

      this.props.handleSubmit(scheduleFields);
      this.setState({ submitted: true });
    }
  }

  setDateTime = (field, date) => {
    // clear any existing error notices
    if (this.props.errorNotice) this.props.formFieldChange();
    
    // get current times from state
    let { start_time, end_time } = this.state.schedule;
    let timeLimitError = "";

    // function to enforce 4-hour limit
    const checkFourHourLimit = (startTime, endTime, isUserAction) => {
      // if end time is before start time, assume it's next day
      if (moment(endTime).isBefore(moment(startTime))) {
        endTime = moment(endTime).add(1, 'day');
      }
      
      // calculate hours between times
      const hoursDiff = moment.duration(moment(endTime).diff(moment(startTime))).asHours();
      const maxEndTime = moment(startTime).add(4, "hours");
      
      // if over 4 hours, return adjusted time and error message
      if (hoursDiff > 4) {
        return {
          adjustedTime: maxEndTime,
          error: isUserAction ? this.props.t('transcript_schedule_form.four_hour_limit_error', { 
            adjustedTime: maxEndTime.format("hh:mm A") 
          }) : ""
        };
      }
      
      return { adjustedTime: endTime, error: "" };
    };
  
    // handle start time changes
    if (field === "start_time") {
      start_time = date;
      // always set end time to 1 hour after start time
      end_time = moment(start_time).add(1, "hour");
    }
    
    // handle end time changes
    if (field === "end_time") {
      // only check 4-hour limit when user is setting end time
      const result = checkFourHourLimit(start_time, date, true);
      end_time = result.adjustedTime;
      timeLimitError = result.error;
    }
  
    // update state
    this.setState({
      schedule: {
        ...this.state.schedule,
        start_time,
        end_time
      },
      timeLimitError
    });
  };

  setValue = (e) => {
    if(this.props.errorNotice) this.props.formFieldChange();
    let field = e.target.name;
    this.setState({schedule: {
        ...this.state.schedule, [field]: e.target.value
    }});
  }

  toggleRepeatDay = (day) => {
    let changedDays = [];
    if(this.state.schedule.days.includes(day)) {
      changedDays = this.state.schedule.days.filter(item => item !== day);
    } else {
      changedDays = [
        ...this.state.schedule.days,
        day
      ];
    }
    this.setState({
      schedule: {
        ...this.state.schedule,
        days: changedDays
      }
    });
  }

  render() {
    const {
      t,
      noticeError,
      classes,
      radioStations,
      errorNotice,
      processingSubmit
    } = this.props;

      return (
        <form id="add-schedule-form"onSubmit={this.handleSubmit}>
          {noticeError && noticeError.message && (
            <div className={classes.noticeMessage}>
            <Notice message={noticeError.message} type={"error"} />
            </div>
          )}
          <TextField
            type="text"
            name="display_name"
            fullWidth={true}
            value={this.state.schedule.display_name}
            onChange={this.setValue}
            label={t('transcript_schedule_form.schedule_name')}
            required
          />
          <FormControl fullWidth={true} margin="normal">
            <InputLabel htmlFor="publication_id">{t('transcript_schedule_form.source')}</InputLabel>
            <Select
              value={this.state.schedule.publication_id}
              onChange={this.setValue}
              fullWidth={true}
              disabled={!!this.state.schedule.uuid}
              required
              inputProps={{ name: 'publication_id', id: 'publication_id' }}
            >
              {radioStations.map((station) => (
                <MenuItem key={station.value} value={station.value}>{station.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputLabel className={classes.dayRepeatLabel}>{t('transcript_schedule_form.repeat_on')}</InputLabel>
          <Tooltip title={t('transcript_schedule_form.day_of_the_week.monday')}>
            <Fab classes={{ root: classes.dayRoot}}
              color={this.state.schedule.days.includes(1)?"primary":"default"} 
              onClick={()=>{ this.toggleRepeatDay(1); }} size="small">
              {t('transcript_schedule_form.day_of_the_week.letter_monday')}
            </Fab>
          </Tooltip>
          <Tooltip title={t('transcript_schedule_form.day_of_the_week.tuesday')}>
            <Fab classes={{ root: classes.dayRoot}}
              color={this.state.schedule.days.includes(2)?"primary":"default"}
              onClick={()=>{ this.toggleRepeatDay(2); }} size="small"> 
              {t('transcript_schedule_form.day_of_the_week.letter_tuesday')}
            </Fab>
          </Tooltip>
          <Tooltip title={t('transcript_schedule_form.day_of_the_week.wednesday')}>
            <Fab classes={{ root: classes.dayRoot}}
              color={this.state.schedule.days.includes(3)?"primary":"default"}
              onClick={()=>{ this.toggleRepeatDay(3); }} size="small">
              {t('transcript_schedule_form.day_of_the_week.letter_wednesday')}
            </Fab>
          </Tooltip>
          <Tooltip title={t('transcript_schedule_form.day_of_the_week.thursday')}>
            <Fab classes={{ root: classes.dayRoot}}
              color={this.state.schedule.days.includes(4)?"primary":"default"}
              onClick={()=>{ this.toggleRepeatDay(4); }} size="small">
              {t('transcript_schedule_form.day_of_the_week.letter_thursday')}
            </Fab>
          </Tooltip>
          <Tooltip title={t('transcript_schedule_form.day_of_the_week.friday')}>
            <Fab classes={{ root: classes.dayRoot}}
              color={this.state.schedule.days.includes(5)?"primary":"default"}
            onClick={()=>{ this.toggleRepeatDay(5); }} size="small">
              {t('transcript_schedule_form.day_of_the_week.letter_friday')}
            </Fab>
          </Tooltip>
          <Tooltip title={t('transcript_schedule_form.day_of_the_week.saturday')}>
            <Fab classes={{ root: classes.dayRoot}}
              color={this.state.schedule.days.includes(6)?"primary":"default"}
              onClick={()=>{ this.toggleRepeatDay(6); }} size="small">
              {t('transcript_schedule_form.day_of_the_week.letter_saturday')}
            </Fab>
          </Tooltip>
          <Tooltip title={t('transcript_schedule_form.day_of_the_week.sunday')}>
            <Fab classes={{ root: classes.dayRoot}}
              color={this.state.schedule.days.includes(7)?"primary":"default"}
              onClick={()=>{ this.toggleRepeatDay(7); }} size="small">
               {t('transcript_schedule_form.day_of_the_week.letter_sunday')}
            </Fab>
          </Tooltip>
          <FormControl fullWidth={true} margin="normal">
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardTimePicker
                className={classes.fromTime}
                margin="normal"
                id="start_time"
                name="start_time"
                label={t('transcript_schedule_form.start_time')}
                value={this.state.schedule.start_time}
                onChange={(date) => { this.setDateTime('start_time', date); }}
                cancelLabel={t('transcript_schedule_form.cancel')}
                okLabel={t('transcript_schedule_form.ok')}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl fullWidth={true} margin="normal">
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardTimePicker
                className={classes.fromTime}
                margin="normal"
                id="end_time"
                name="end_time"
                label={t('transcript_schedule_form.end_time')}
                value={this.state.schedule.end_time}
                onChange={(date) => { this.setDateTime('end_time', date); }}
                cancelLabel={t('transcript_schedule_form.cancel')}
                okLabel={t('transcript_schedule_form.ok')}
              />
            </MuiPickersUtilsProvider>
            {this.state.timeLimitError && (
              <p className={classes.timeLimitMessage}>
                {this.state.timeLimitError}
              </p>
            )}
          </FormControl>
          <FormControl fullWidth={true} margin="normal">
            <InputLabel htmlFor="timezone">{t('transcript_schedule_form.timezone')}</InputLabel>
            <Select
              value={this.state.schedule.timezone}
              onChange={this.setValue}
              fullWidth={true}
              required
              inputProps={{ name: 'timezone', id: 'timezone' }}
            >
              {Intl.supportedValuesOf('timeZone').map((tz) => (
                <MenuItem key={tz} value={tz}>{tz}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {errorNotice &&  <Notice message={errorNotice.message} messageKey={errorNotice.messageKey} type={errorNotice.type} />}
          <div className={classes.button}>   
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
            >
              {this.state.schedule.uuid ? t('transcript_schedule_form.update_button'):t('transcript_schedule_form.create_button')}
            </Button>
          </div>
          {processingSubmit && <CircularProgress />}
        </form>
      );
    }
}

TranscriptScheduleForm.propTypes = {
  handleSubmit: PropTypes.func,
  t: PropTypes.func,
  types: PropTypes.object,
  noticeError: PropTypes.object,
  i18n: PropTypes.object,
  radioSources: PropTypes.array,
  classes: PropTypes.object,
  radioStations: PropTypes.array,
  schedule: PropTypes.object,
  errorNotice: PropTypes.object,
  processingSubmit: PropTypes.bool,
  formFieldChange: PropTypes.func
};

export default compose(
    withTranslation(),
    withStyles((theme) => ({
        noticeMessage: {
          marginBottom: '12px'
        },
        button: {
          marginTop: '12px',
          marginBottom: '12px'
        },
        dayRoot: {
          marginRight: '6px !important',
          boxShadow: 'none'
        },
        dayRepeatLabel: {
          fontSize: '12px',
          marginTop: '16px',
          marginBottom: '8px'
        },
        timeLimitMessage: {
          fontSize: '12px',
          marginTop: '4px'
        }
    }))
  )(TranscriptScheduleForm);
