import React, { Component } from 'react';

import UserManagerContainer from 'Components/User/UserManagerContainer';

export class AdminPage extends Component {

  render() {
    return (
      <div className="row container">
        <div className="col-xs4-4 col-lg-12">
          <UserManagerContainer />
        </div>
      </div>
    );
  }
}

export default AdminPage;
