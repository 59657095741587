import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import PasswordField from 'Components/Common/PasswordField';
import Notice from 'Components/Common/Notice';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

export function UserChangePasswordForm(props) {
  const [password, setPassword] = React.useState('');

  const {
    onSubmit,
    notice,
    t,
    isVerifying,
    classes
  } = props;

  const handlePasswordChange = (e, el) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e, password) => {
    e.preventDefault();
    onSubmit(password);
  };

  return (
    <form className={classes.changePasswordForm} onSubmit={(e) => { handleSubmit(e, password);}}>
      <PasswordField
        name="password"
        label={t('user_change_password_form.enter_new_password')}
        password={password}
        onFieldChange={handlePasswordChange}
      />
      <div className={classes.submitButton}>
       
        <Button type="submit" variant="contained" color="primary">{isVerifying ? 'Set Password': t('user_change_password_form.change_password')}</Button>
      </div>
      {notice ? (
        <Notice message={notice.message} type={notice.type} />
      ): ''}

    </form>
  );
}

UserChangePasswordForm.propTypes  = {
  onSubmit: PropTypes.func.isRequired,
  notice: PropTypes.object,
  t: PropTypes.func,
  isVerifying: PropTypes.bool,
  classes: PropTypes.object
};

export default compose(
  withStyles(() => ({
    submitButton: {
      marginTop: '12px'
    },
    changePasswordForm: {
      maxWidth: '500px',
      marginBottom: '12px'
    }
  })),
  withTranslation()
)(UserChangePasswordForm);
