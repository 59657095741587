import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import UserArea from 'Components/User/UserArea';
import authSelectors from 'Selectors/authSelectors';
import noticeSelectors from 'Selectors/noticeSelectors';
import noticeActions from 'Actions/noticeActions';
import authActions from 'Actions/authActions';

export class UserAreaContainer extends Component {

  constructor(props) {
      super(props);

  }

  handleLoginSubmit = (organisation, email, password) => {
    this.props.noticeActions.removeNotice('page_notice');
    if (email && password) {
        this.props.authActions.login(organisation, email, password);
    }
  }

  handleForgotSubmit = (email) => {
    this.props.noticeActions.removeNotice('page_notice');
    this.props.authActions.forgotPassword(email);
  }

  handleAreaToggle = () => {
    this.props.noticeActions.removeNotice('page_notice');
  }

  addErrorNotice = (message) => {
    this.props.noticeActions.addNotice(
      'page_notice', {
        message: message,
        type: 'error'
      }
    );
  }

  removeErrorNotice = () => {
    this.props.noticeActions.removeNotice('page_notice');
  }

  render() {
    const {
      notice,
      possibleOrgs,
      isLoggingIn
    } = this.props;
    
    return (
      <UserArea
        notice={notice}
        possibleOrgs={possibleOrgs}
        defaultOrganisation={'fullfact'}
        handleLoginSubmit={this.handleLoginSubmit}
        handleForgotSubmit={this.handleForgotSubmit}
        handleAreaToggle={this.handleAreaToggle}
        addErrorNotice={this.addErrorNotice}
        removeErrorNotice={this.removeErrorNotice}
        isLoggingIn={isLoggingIn}
      />
    );
  }
}

UserAreaContainer.propTypes = {
  notice: PropTypes.object,
  noticeActions: PropTypes.object,
  authActions: PropTypes.object,
  possibleOrgs: PropTypes.array,
  isLoggingIn: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    notice: noticeSelectors.getNoticeById(state, 'page_notice'),
    possibleOrgs: authSelectors.getPossibleOrganisations(state),
    isLoggingIn: authSelectors.getIsLoggingIn(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
     authActions: bindActionCreators(authActions, dispatch),
     noticeActions: bindActionCreators(noticeActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAreaContainer);
