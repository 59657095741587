import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

export function UserForgotPasswordForm(props){

  const {
    email,
    onFieldChange,
    t,
    classes,
    onSubmit
  } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(email);
  };

  const handleFieldChange = (e) => {
    onFieldChange(e.target.name, e.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className={classes.forgotPasswordForm}>
    <div className={classes.forgotPasswordEmail}>
        <TextField
          type="email"
          name="email"
          value={email}
          onChange={handleFieldChange}
          label={t('user_forgot_password_form.email')}
          required={true}
          fullWidth={true}
        />
        </div>
        <div>
          <Button type="submit" color="primary" variant="contained">{t('user_forgot_password_form.reset_button')}</Button>
        </div>
    </form>
  );
}

UserForgotPasswordForm.propTypes  = {
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string,
  onFieldChange: PropTypes.func,
  t: PropTypes.func,
  classes: PropTypes.object
};

export default compose(
  withStyles(() => ({
    forgotPasswordForm: {
      padding: '0 24px'
    },
    orgSelector: {
      textAlign: 'left'
    },
    forgotPasswordEmail: {
      margin: '12px 0 24px 0'
    }
  })),
  withTranslation()
)(UserForgotPasswordForm);
