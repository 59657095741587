import React from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import EnhancedTable from 'Components/Common/EnhancedTable';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import {Link} from 'react-router-dom';
import ActionControlPanel from 'Components/Action/ActionControlPanel';
import Chip from '@material-ui/core/Chip';
import { addComparisonsToFilter } from 'Components/Action/ActionTableFilters';
import Papa from 'papaparse';
import Notice from 'Components/Common/Notice';
import Tooltip from '@material-ui/core/Tooltip';

function ActionManager(props) {
  const {
    actions, // array of actions
    classes, // used for styles
    handleCreate,
    handleDelete,
    handleEdit,
    handleFilterChange, //  fire action for analytics and updates url
    initialFilters, // taken from url
    t,
    errorNotice,
    canViewClaim
  } = props;

  const filterableFields = props.filterableFields.map(field => {
    let display = field.name;
    let keys = {
      status: 'action_manager.column_header_status',
      publication: 'action_manager.column_header_publication',
      author_name: 'action_manager.column_header_author',
      tracked_claim_id: 'action_manager.column_header_claim_text',
      category: 'action_manager.column_header_sighting_category'
    }
    if(keys[field.name]) display = t(keys[field.name]);
    return {
      ...field,
      display: display
    };
  });

  let defaultFilters = initialFilters || {
    from: null,
    until: null,
    cases: [],
    text: ''
  };

  const [filters, setFilters] = React.useState(defaultFilters);

  function setDates(dates) {
    setFilters({
      ...filters,
      from: dates.startDate,
      until: dates.endDate
    });
    handleFilterChange({
      ...filters,
      from: dates.startDate,
      until: dates.endDate
    });
  }

  function setFilterText(text) {
    updateFilter('text', text);
  }

  function applyFilters(cases) {
    updateFilter('cases', cases);
  }

  function updateFilter(field, value) {

    setFilters({
      ...filters,
      [field]: value
    });
    handleFilterChange({
      ...filters,
      [field]: value
    });
  }

  let fromDateNum;
  let untilDateNum;
  let modifiedData = actions;

  if(filters.from) fromDateNum = parseInt((filters.from.format('YYYYMMDD')),10);
  if(filters.until) untilDateNum = parseInt((filters.until.format('YYYYMMDD')),10);

  modifiedData = modifiedData.filter(item => {

    if(!item.resolved_at) item.resolved_at = '';
    let includeItem = true;
    if(filters.text && filters.text !== '') {
      let matchesClaim = item.tracked_claim_text.toLowerCase().includes(filters.text.toLowerCase());
      let matchesPubSightingText = item.text.toLowerCase().includes(filters.text.toLowerCase());
      let matchesResSummary = item.resolution_summary.toLowerCase().includes(filters.text.toLowerCase());
      includeItem = matchesClaim || matchesPubSightingText || matchesResSummary;
    }

    if(includeItem && (fromDateNum || untilDateNum)) {
      let withinRange = false;
      if(item.intervened_at) {
        let simpleDate = item.intervened_at.split('T')[0];
        let numberDate = parseInt(simpleDate.replace(/-/gi, ''),10);
        let afterStart = numberDate >= fromDateNum;
        let beforeEnd = numberDate <= untilDateNum;

        if(fromDateNum && untilDateNum) {
          withinRange = afterStart && beforeEnd;
        } else if(!untilDateNum) {
          withinRange = afterStart;
        } else {
          withinRange = beforeEnd;
        }
      }
      includeItem = withinRange;
    }

    if(includeItem && filters.cases.length) {

      let matchesFilter = true;
      filters.cases.forEach((filter) => {
        if(filter.field && filter.field !== '' && filter.values.length) {
          let fieldValues = item[filter.field];
          matchesFilter = matchesFilter && filter.compareFunc(fieldValues, filter.values);
        }
      });
      includeItem = matchesFilter;
    }

    return includeItem;

  });


  function filterByChip(field, data, type) {
    let c = {
      field: field,
      values: [data],
      type: type
    };
    c = addComparisonsToFilter(c, props.t);

    setFilters({
      ...filters,
      cases: [c]
    });
    handleFilterChange({
      ...filters,
      cases: [c]
    });
  }

  function generateCSV(actions) {    
    let csvContent;
    if(actions) {
      csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(Papa.unparse(actions, {
        columns: [
          'action_due_to_afc', 
          'author_name', 
          'category_label', 
          'intervened_at', 
          'intervention_id', 
          'intervention_type_label',
          'intervention_target',
          'notes',
          'publication', 
          'publication_date', 
          'resolution_summary',
          'resolution_type_label',
          'resolution_url', 
          "resolved_at",
          'sighting_type',
          'status',
          'stored_sighting_id',
          'text',
          'tracked_claim_factcheck',
          'tracked_claim_id',
          'tracked_claim_text',
          'url',
          'wikidata_intervention_target_id',
          'wikidata_author_name_id',
          'wikidata_publication_id'
        ],
        quotes: true,
        quoteChar: '"'
      }));
    }
    return csvContent;
  }
  return (
    <div className={classes.claimsManager}>
      <div>
        <ActionControlPanel
          applyFilter={applyFilters}
          dateRangeChange={setDates}
          filterableFields={filterableFields}
          handleCreateAction={handleCreate}
          handleDatesChange={setDates}
          handleFilterTextChange={setFilterText}
          initialFilters={defaultFilters}
          resultsCount={modifiedData.length}
          resultsCSV={generateCSV(modifiedData)}
        />
        </div>
        {errorNotice && (
        <div className={classes.noticeContainer}>
        <Notice
            type={errorNotice.type}
            message={errorNotice.message}
        />
        </div>
        )}
      <div className={classes.claimsTable}>
        <EnhancedTable
          rows={modifiedData}
          height="100%"
          fixedWidth={2970}
          columns={[
            {
              width: 90,
              label: ' ',
              disableSort: true,
              dataKey: 'edit',
              formatting: (data, rowData) => {
                return (
                  <Button role="button" aria-label="edit" onClick={() => {
                  handleEdit(rowData);
                }} color="primary" variant="contained">{t('action_manager.edit_button')}</Button>
                );
              }
            },
            {
              width: 140,
              label:  t('action_manager.column_header_status'),
              dataKey: 'status_label',
              formatting: (data, rowData) => {
                if(data && filterableFields.length) {
                  let color = '#e0e0e0';
                  const statusColorMap = {
                    "not_started": "rgb(255, 165, 132)",
                    "awaiting_response": "rgb(186, 182, 239)",
                    "awaiting_response_chased": "rgb(216, 187, 3)",
                    "response_received": "rgb(146, 195, 213)",
                    "further_follow_up": "rgb(255, 168, 80)",
                    "holding_reply_received": "rgb(246, 174, 1)",
                    "closed": "rgb(99, 210, 112)",
                    "closed_unresolved": "rgb(221, 170, 233)",
                    "closed_partially": "rgb(144, 206, 27)",
                  };
                  if(statusColorMap[rowData.status]) color = statusColorMap[rowData.status];

                  return (<Tooltip title={t(data)}><Chip
                    className={classes.chip}
                    key={data}
                    label={t(data)}
                    size="small"
                    style={{backgroundColor: color}}
                    onClick={() => { filterByChip('status', rowData.status, 'single');}}
                  /></Tooltip>);
                } else {
                  return data;
                }
              }
            },
            {
              width: 200,
              label: t('action_manager.column_header_claim_text'),
              dataKey: 'tracked_claim_text',
              formatting: (data, rowData) => {
                if(canViewClaim) {
                  return <Link to={"/alerts/claims/"+rowData.tracked_claim_id}>{data}</Link>;
                } else {
                  return data;
                }
              }
            },
            {
              width: 100,
              label: t('action_manager.column_header_factcheck'),
              dataKey: 'tracked_claim_factcheck',
              disableSort: true,
              formatting: (data) => {
                return data ? (<a href={data}><LinkIcon /></a>): '';
              }
            },
            {
              width: 140,
              label: t('action_manager.column_header_publication'),
              dataKey: 'publication',
              formatting: (data) => {
                if(data && filterableFields.length) {
                  let color = '#e0e0e0';
                  return (<Tooltip title={data}><Chip
                    className={classes.chip}
                    key={data}
                    label={data}
                    size="small"
                    style={{backgroundColor: color}}
                    onClick={() => { filterByChip('publication', data, 'single');}}
                  /></Tooltip>);
                } else {
                  return data;
                }
              }
            },
            {
              width: 200,
              label: t('action_manager.sighting_category'),
              dataKey: 'category_label'
            },
            {
              width: 300,
              label: t('action_manager.column_header_publication_text'),
              dataKey: 'text',
              formatting: (data, rowData) => {
                if(rowData.url) {
                  return <a href={rowData.url}>{data}</a>;
                } else {
                  return <span>{data}</span>;
                }
              }
            },
            {
              width: 150,
              label: t('action_manager.column_header_publication_date'),
              dataKey: 'publication_date',
              formatting: (data) => {
                if(data && data !== '') {
                  return data.split('T')[0];
                } else {
                  return data;
                }
              }
            },
            {
              width: 170,
              label: t('action_manager.column_header_author'),
              dataKey: 'author_name',
              formatting: (data) => {
                if(data && filterableFields.length) {
                  let color = '#e0e0e0';
                  return (<Tooltip title={data}><Chip
                    className={classes.chip}
                    key={data}
                    label={data}
                    size="small"
                    style={{backgroundColor: color}}
                    onClick={() => { filterByChip('author_name', data,  'single');}}
                  /></Tooltip>);
                } else {
                  return data;
                }
              }
            },
            {
              width: 135,
              label: t('action_manager.column_header_action_date'),
              dataKey: 'intervened_at',
              formatting: (data) => {
                if(data && data !== '') {
                  return data.split('T')[0];
                } else {
                  return data;
                }
              }
            },
            {
              width: 190,
              label: t('action_manager.action_type'),
              dataKey: 'intervention_type_label'
            },
            {
              width: 190,
              label: t('action_manager.action_target'),
              dataKey: 'intervention_target'
            },
            {
              width: 190,
              label: t('action_manager.column_header_notes'),
              dataKey: 'notes'
            },
            {
              width: 150,
              label: t('action_manager.column_header_resolution_date'),
              dataKey: 'resolved_at',
              formatting: (data) => {
                if(data && data !== '') {
                  return data.split('T')[0];
                } else {
                  return data;
                }
              }
            },
            {
              width: 190,
              label: t('action_manager.resolution_type'),
              dataKey: 'resolution_type_label'
            },
            {
              width: 190,
              label: t('action_manager.resolution_url'),
              dataKey: 'resolution_url',
              formatting: (data) => {
                return <a href={data}>{data}</a>;
              }
            },
            {
              width: 190,
              label: t('action_manager.column_header_resolution_summary'),
              dataKey: 'resolution_summary'
            },
            {
              width: 60,
              label: ' ',
              disableSort: true,
              dataKey: 'delete',
              formatting: (data, rowData) => {
                return (    
                  <IconButton role="button" aria-label="open confirm delete" onClick={() => {
                    handleDelete(rowData.intervention_id);
                  }}>
                    <Tooltip title={t('action_manager.delete_button')}>
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>
                );
              }
            }
          ]}
        />
      </div>
  </div>
  );
}



export default compose(
  withTranslation(),
  withStyles(() => ({
    claimsManager: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    claimsTable: {
      flexGrow: 1,
      height: '100%',
      width: '100%',
      overflowY: 'scroll'
    },
    chip: {
      textOverflow: 'ellipsis',
      marginBottom: '3px',
      maxWidth: '100%',
      overflow: 'hidden',
      display: 'inline-block',
      justifyContent: 'left',
      lineHeight: '1.8',
      '& .MuiChip-label' : {
        display: 'inline'
      }
    },
    noticeContainer: {
      marginTop:'12px'
    }
  }))
)(ActionManager);

ActionManager.propTypes = {
  classes: PropTypes.object,
  actions: PropTypes.array,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleCreate: PropTypes.func,
  filterableFields: PropTypes.array.isRequired,
  handleFilterChange: PropTypes.func,
  initialFilters: PropTypes.object,
  t: PropTypes.func,
  resultsCSV: PropTypes.string,
  errorNotice: PropTypes.object,
  canViewClaim: PropTypes.bool
};
