import { put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import transcriptConstants from 'Constants/transcriptConstants';
import noticeActions from 'Actions/noticeActions';
import transcriptActions from 'Actions/transcriptActions';
import * as utils from '../utils';
import successMessages from 'Config/default/successMessages';

const delay = (ms) => new Promise(res => setTimeout(res, ms));

// all transcripts
export function* processGetAllTranscripts(action) {
  yield put({ type: 'FETCH', requestType: action.type, payload: {
    path: `/transcripts_v2/all`
  }});
}

// block transcripts 
export function* processGetTranscript(action) {
  let transcriptType = action.transcriptType;
  if(transcriptType === 'radio') transcriptType = 'radio_v2';
  yield put({ type: 'FETCH', requestType: action.type, payload: {
    path: `/transcripts_v2/${transcriptType}/${action.id}/`
  }});
}

export function* processGetTranscriptUrl(action) {
  let transcriptType = action.transcriptType;
  if(transcriptType === 'radio') transcriptType = 'radio_v2';
  yield put({ type: 'FETCH', requestType: action.type, payload: {
    path: `/transcripts_v2/${transcriptType}/${action.id}/media`
  }});
}

export function* processGetTranscripts(action) {
  let transcriptType = action.transcriptType;
  if(transcriptType === 'radio') transcriptType = 'radio_v2';
   yield put({ type: 'FETCH', requestType: action.type, payload: {
     path: `/transcripts_v2/${transcriptType}/`
   }});
}

export function* processGetTranscriptSentences(action) {
  let transcriptType = action.transcriptType;
  if(transcriptType === 'radio') transcriptType = 'radio_v2';
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/transcript-sentences/${transcriptType}/?transcript_id=${action.id}`
    }
  });
}

export function* processCreateTranscript(action) {
  let transcriptType = action.transcriptType;
  if(transcriptType === 'radio') transcriptType = 'radio_v2';

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/transcripts_v2/${transcriptType}/`,
      method: 'POST',
      body: action.transcript,
    },
    request: {
      transcriptType: action.transcriptType
    }
  });
}

export function* processDeleteTranscript(action) {
  let transcriptType = action.transcriptType;
  if(transcriptType === 'radio') transcriptType = 'radio_v2';
  yield put({ 
    type: 'FETCH', 
    requestType: action.type, 
    payload: {
      path: `/transcripts_v2/${transcriptType}/${action.id}/`, 
      method: 'DELETE'
    },
    request: {
      transcriptType: action.transcriptType
    }
  });
}

export function* processChangeTranscriptName(action) {
  let transcriptType = action.transcriptType;
  if(transcriptType === 'radio') transcriptType = 'radio_v2'
  yield put({ type: 'FETCH', requestType: action.type, payload: {
    path: `/transcripts_v2/${transcriptType}/${action.transcriptId}/`,
    method: 'PATCH',
    body: {
        "transcript_name": action.name
    }
  }});
}

export function* processCreateTranscriptSuccess(action) {
  let type = action.request.transcriptType;
  if(type === 'radio_v2') type = 'radio'
  yield put(push(`/${type}-transcript/${action.response.transcript_id}`));
}

export function* processDeleteTranscriptSuccess(action) {

  const actionConstant = 'GET_'+action.request.transcriptType.toUpperCase()+'_TRANSCRIPTS';
  let transcriptType = action.request.transcriptType;
  if(transcriptType === 'radio') transcriptType = 'radio_v2';
  yield put({ type: 'FETCH', requestType: transcriptConstants[actionConstant], payload: {
    path: `/transcripts_v2/${action.request.transcriptType}/`
  }});
}

export function* processChangeNameSuccessNotice(action) {
  const msg = successMessages['CHANGE_TRANSCRIPT_NAME'];
  yield put(noticeActions.addNotice(msg.displayCode, {
    messageKey: msg.message
  }));
  yield delay(1000);
  yield put(noticeActions.removeNotice('change_name'));
}

export function* processStopTranscript(action) {
  let body = {
      "is_open": false
  };
  if(action.time) {
    body['end_time'] = action.time;
  }

  let transcriptType = action.transcriptType;
  if(transcriptType === 'radio') transcriptType = 'radio_v2'
  yield put({ 
    type: 'FETCH', 
    requestType: action.type, 
    payload: {
      path: `/transcripts_v2/${transcriptType}/${action.id}/`,
      method: 'PATCH',
      body: body
    },
    request: {
      transcriptType: action.transcriptType,
      id: action.id
    }
  });
}

export function* processModifcationStopTranscript(action) {
  yield put({ type: transcriptConstants.CLOSE_TRANSCRIPT });
  
}

export function* processTransformTranscriptSentences(action) {
  if(action.response.transcript_sentences) {
    if(action.request && action.request.sentenceId) {
      let sentenceIndex;
      let croppedSentences = [];
      action.response.transcript_sentences.forEach((stc,i) => {
          if(stc.transcript_sentence_id === action.request.sentenceId) {
            sentenceIndex = i;
          } else if(!sentenceIndex) {
            
            croppedSentences.push(stc);
          }
      });
      action.response.transcript_sentences = croppedSentences;
    }
    action.response.transcript_sentences = action.response.transcript_sentences.map(sentence => {
      if(action.response.transcript.type_name === 'radio_v2' || action.response.transcript.type_name === 'radio'){
        sentence.created_at = sentence.metadata.broadcast_time;
      }
      sentence = utils.transformSentence(sentence);
      return sentence;
    });
  }
  yield put({ type: transcriptConstants.LOAD_TRANSCRIPT_SENTENCES, response: action.response });
}

export function* processHighlightSentence(action) {

  yield put({ type: 'FETCH', requestType: action.type, payload: {
    path: `/transcript-sentences/${action.transcriptType}/${action.sentenceId}/`,
    method: 'PATCH',
    body: {"is_highlighted":true}
  }});
}

export function* processUnhighlightSentence(action) {
  yield put({ type: 'FETCH', requestType: action.type, payload: {
    path: `/transcript-sentences/${action.transcriptType}/${action.sentenceId}/`,
    method: 'PATCH',
    body: {"is_highlighted":false}
  }});
}

export function* processGetTranscriptSchedules(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/media/schedules/`,
    }
  });
}

export function* processGetTranscriptSchedule(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/media/schedules/${action.data}/`,
    }
  });
}

export function* processGetTranscriptRadioStations(action) {
  yield put({ type: 'FETCH', requestType: action.type, payload: {
    path: `/publications_v2/`
  }});
}

export function* processCreateRadioTranscriptSchedule(action) {
  let scheduleBody = {...action.schedule};
  delete scheduleBody.uuid;

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/media/schedules/`,
      method: 'POST',
      body: scheduleBody
    }
  });
  
}

export function* processUpdateTranscriptSchedule(action) {

  let uuid = action.data.uuid;
  let updateData = { ...action.data };
  delete updateData.uuid;

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/media/schedules/${uuid}/`,
      method: 'PUT',
      body: updateData
    }
  });
}


export function* processDeleteTranscriptSchedule(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/media/schedules/${action.data}/`,
      method: 'DELETE',
    }
  });
}

export function* processCreateTranscriptScheduleSuccess() {
  yield put(transcriptActions.getSchedules());
  yield put(transcriptActions.stopEditingSchedule());
}

export function* processDeleteTranscriptScheduleSuccess() {
  yield put(transcriptActions.getSchedules());
}

export function* processGetScheduleTranscripts(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/transcripts_v2/radio_v2/?schedule_uuid=${action.id}`
    }
  });
}

export function* processGetMoreScheduleTranscripts(action) {
  let requestPath = `/transcripts_v2/radio_v2/?schedule_uuid=${action.id}`;
  if(action.page) requestPath += ('&' + action.page);
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: requestPath
    }
  });
}

export function* processGetMoreTranscripts(action) {
  yield put({ 
    type: 'FETCH', 
    requestType: action.type, 
    payload: {
      path: `/transcripts_v2/all?page=${action.data.page}`
    }
  });
}

export function* processGetLatestsTranscriptSchedules(action) {
  yield put({ 
    type: 'FETCH', 
    requestType: action.type, 
    payload: {
      path: `/transcripts_v2/radio_v2/schedule_latest/`
    }
  });
}

export function* processGetTranscriptSchedulesSuccess() {
  yield put(transcriptActions.getLatestTranscriptSchedules());
}

export function* processUpdateTranscriptScheduleSuccess() {
  yield put(transcriptActions.getSchedules());
  yield put(transcriptActions.stopEditingSchedule());
}

export function* transcriptSagas(){

  //general transcript actions
  yield takeEvery(transcriptConstants.GET_ALL_TRANSCRIPTS, processGetAllTranscripts);
  yield takeEvery(transcriptConstants.HIGHLIGHT_SENTENCE, processHighlightSentence);
  yield takeEvery(transcriptConstants.UNHIGHLIGHT_SENTENCE, processUnhighlightSentence);
  yield takeEvery(transcriptConstants.GET_SCHEDULE_TRANSCRIPTS, processGetScheduleTranscripts);
  yield takeEvery(transcriptConstants.GET_MORE_TRANSCRIPTS, processGetMoreTranscripts);
  yield takeEvery(transcriptConstants.GET_MORE_SCHEDULE_TRANSCRIPTS, processGetMoreScheduleTranscripts);
  yield takeEvery(transcriptConstants.GET_LATEST_TRANSCRIPT_SCHEDULES, processGetLatestsTranscriptSchedules);
  //block transcripts
  yield takeEvery(transcriptConstants.CREATE_BLOCK_TRANSCRIPT, processCreateTranscript);
  yield takeEvery(`${transcriptConstants.CREATE_BLOCK_TRANSCRIPT}_SUCCESS`, processCreateTranscriptSuccess);
  yield takeEvery(transcriptConstants.GET_BLOCK_TRANSCRIPT, processGetTranscript);
  yield takeEvery(transcriptConstants.GET_BLOCK_TRANSCRIPTS, processGetTranscripts);
  yield takeEvery(transcriptConstants.GET_BLOCK_TRANSCRIPT_SENTENCES, processGetTranscriptSentences);
  yield takeEvery(`${transcriptConstants.GET_BLOCK_TRANSCRIPT_SENTENCES}_SUCCESS`, processTransformTranscriptSentences);
  yield takeEvery(transcriptConstants.UPDATE_BLOCK_TRANSCRIPT_NAME, processChangeTranscriptName);
  yield takeEvery(`${transcriptConstants.UPDATE_BLOCK_TRANSCRIPT_NAME}_SUCCESS`, processChangeNameSuccessNotice);
  yield takeEvery(transcriptConstants.DELETE_BLOCK_TRANSCRIPT, processDeleteTranscript);
  yield takeEvery(`${transcriptConstants.DELETE_BLOCK_TRANSCRIPT}_SUCCESS`, processDeleteTranscriptSuccess);

  
  //channel transcripts
  yield takeEvery(transcriptConstants.CREATE_CHANNEL_TRANSCRIPT, processCreateTranscript);
  yield takeEvery(`${transcriptConstants.CREATE_CHANNEL_TRANSCRIPT}_SUCCESS`, processCreateTranscriptSuccess);
  yield takeEvery(transcriptConstants.GET_CHANNEL_TRANSCRIPT, processGetTranscript);
  yield takeEvery(transcriptConstants.GET_CHANNEL_TRANSCRIPTS, processGetTranscripts);
  yield takeEvery(transcriptConstants.GET_CHANNEL_TRANSCRIPT_SENTENCES, processGetTranscriptSentences);
  yield takeEvery(`${transcriptConstants.GET_CHANNEL_TRANSCRIPT_SENTENCES}_SUCCESS`, processTransformTranscriptSentences);
  yield takeEvery(transcriptConstants.UPDATE_CHANNEL_TRANSCRIPT_NAME, processChangeTranscriptName);
  yield takeEvery(`${transcriptConstants.UPDATE_CHANNEL_TRANSCRIPT_NAME}_SUCCESS`, processChangeNameSuccessNotice);
  yield takeEvery(transcriptConstants.DELETE_CHANNEL_TRANSCRIPT, processDeleteTranscript);
  yield takeEvery(`${transcriptConstants.DELETE_CHANNEL_TRANSCRIPT}_SUCCESS`, processDeleteTranscriptSuccess);
  yield takeEvery(transcriptConstants.STOP_CHANNEL_TRANSCRIPT, processStopTranscript);
  yield takeEvery(transcriptConstants.MODIFICATION_STOP_TRANSCRIPT, processModifcationStopTranscript);
  
  //radio schedules
  yield takeEvery(transcriptConstants.GET_RADIO_TRANSCRIPTS, processGetTranscripts);
  yield takeEvery(transcriptConstants.GET_RADIO_TRANSCRIPT, processGetTranscript);
  yield takeEvery(transcriptConstants.GET_RADIO_TRANSCRIPT_SENTENCES, processGetTranscriptSentences);
  yield takeEvery(`${transcriptConstants.GET_RADIO_TRANSCRIPT_SENTENCES}_SUCCESS`, processTransformTranscriptSentences);
  yield takeEvery(transcriptConstants.GET_TRANSCRIPT_SCHEDULES, processGetTranscriptSchedules);
  yield takeEvery(`${transcriptConstants.GET_TRANSCRIPT_SCHEDULES}_SUCCESS`, processGetTranscriptSchedulesSuccess);
  yield takeEvery(transcriptConstants.GET_TRANSCRIPT_SCHEDULE, processGetTranscriptSchedule);
  yield takeEvery(transcriptConstants.GET_TRANSCRIPT_RADIO_STATIONS, processGetTranscriptRadioStations);
  yield takeEvery(transcriptConstants.CREATE_RADIO_TRANSCRIPT, processCreateTranscript);
  yield takeEvery(`${transcriptConstants.CREATE_RADIO_TRANSCRIPT}_SUCCESS`, processCreateTranscriptSuccess);
  yield takeEvery(transcriptConstants.CREATE_RADIO_TRANSCRIPT_SCHEDULE, processCreateRadioTranscriptSchedule);
  yield takeEvery(`${transcriptConstants.CREATE_RADIO_TRANSCRIPT_SCHEDULE}_SUCCESS`, processCreateTranscriptScheduleSuccess);
  yield takeEvery(transcriptConstants.DELETE_TRANSCRIPT_SCHEDULE, processDeleteTranscriptSchedule);
  yield takeEvery(`${transcriptConstants.DELETE_TRANSCRIPT_SCHEDULE}_SUCCESS`, processDeleteTranscriptScheduleSuccess);
  yield takeEvery(transcriptConstants.UPDATE_TRANSCRIPT_SCHEDULE, processUpdateTranscriptSchedule);
  yield takeEvery(`${transcriptConstants.UPDATE_TRANSCRIPT_SCHEDULE}_SUCCESS`, processUpdateTranscriptScheduleSuccess);
  yield takeEvery(transcriptConstants.GET_RADIO_TRANSCRIPT_URL, processGetTranscriptUrl);

  //adhoc transcripts
  yield takeEvery(transcriptConstants.GET_ADHOC_TRANSCRIPT, processGetTranscript);
  yield takeEvery(transcriptConstants.GET_ADHOC_TRANSCRIPTS, processGetTranscripts);
  yield takeEvery(transcriptConstants.GET_ADHOC_TRANSCRIPT_SENTENCES, processGetTranscriptSentences);
  yield takeEvery(`${transcriptConstants.GET_ADHOC_TRANSCRIPT_SENTENCES}_SUCCESS`, processTransformTranscriptSentences);
  yield takeEvery(transcriptConstants.DELETE_ADHOC_TRANSCRIPT, processDeleteTranscript);
  yield takeEvery(`${transcriptConstants.DELETE_ADHOC_TRANSCRIPT}_SUCCESS`, processDeleteTranscriptSuccess);
  yield takeEvery(transcriptConstants.STOP_ADHOC_TRANSCRIPT, processStopTranscript);
  yield takeEvery(transcriptConstants.UPDATE_ADHOC_TRANSCRIPT_NAME, processChangeTranscriptName);

  //youtube transcripts
  yield takeEvery(transcriptConstants.CREATE_YOUTUBE_TRANSCRIPT, processCreateTranscript);
  yield takeEvery(`${transcriptConstants.CREATE_YOUTUBE_TRANSCRIPT}_SUCCESS`, processCreateTranscriptSuccess);
  yield takeEvery(transcriptConstants.GET_YOUTUBE_TRANSCRIPT, processGetTranscript);
  yield takeEvery(transcriptConstants.GET_YOUTUBE_TRANSCRIPTS, processGetTranscripts);
  yield takeEvery(transcriptConstants.GET_YOUTUBE_TRANSCRIPT_SENTENCES, processGetTranscriptSentences);
  yield takeEvery(`${transcriptConstants.GET_YOUTUBE_TRANSCRIPT_SENTENCES}_SUCCESS`, processTransformTranscriptSentences);
  yield takeEvery(transcriptConstants.DELETE_YOUTUBE_TRANSCRIPT, processDeleteTranscript);
  yield takeEvery(`${transcriptConstants.DELETE_YOUTUBE_TRANSCRIPT}_SUCCESS`, processDeleteTranscriptSuccess);
  yield takeEvery(transcriptConstants.STOP_YOUTUBE_TRANSCRIPT, processStopTranscript);
  yield takeEvery(transcriptConstants.UPDATE_YOUTUBE_TRANSCRIPT_NAME, processChangeTranscriptName);
  

  //podcast transcripts
  yield takeEvery(transcriptConstants.GET_PODCAST_TRANSCRIPT, processGetTranscript);
  yield takeEvery(transcriptConstants.GET_PODCAST_TRANSCRIPTS, processGetTranscripts);
  yield takeEvery(`${transcriptConstants.GET_PODCAST_TRANSCRIPT_SENTENCES}_SUCCESS`, processTransformTranscriptSentences);
  yield takeEvery(transcriptConstants.GET_PODCAST_TRANSCRIPT_URL, processGetTranscriptUrl);
  yield takeEvery(transcriptConstants.GET_PODCAST_TRANSCRIPT_SENTENCES, processGetTranscriptSentences)

}
