import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import claimActions from 'Actions/claimActions';
import noticeActions from 'Actions/noticeActions';
import interventionActions from 'Actions/interventionActions';

import ClaimManager from 'Components/Claim/ClaimManager';
import ClaimFormContainer from 'Components/Claim/ClaimFormContainer';
import claimSelectors from 'Selectors/claimSelectors';
import authSelectors from 'Selectors/authSelectors';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export class ClaimsPage extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showDialog: false,
      showClaimForm: false,
      selectedClaimId: null
    };
    props.claimActions.loadClaims();
    props.interventionActions.loadInterventions();

  }

  componentWillUnmount() {
    this.props.claimActions.clearClaims();
    this.props.noticeActions.clearNotices();
  }

  handleFilterTextChange = (value) => {
    this.props.claimActions.setClaimsFilterText(value);
  }

  saveClaim = (claim) => {
    this.props.claimActions.saveClaim(claim, 'claims');
  }

  deleteClaim = (claimId) => {
    this.props.claimActions.deleteClaim(claimId);
  }

  starClaim = (claimId) => {
    this.props.claimActions.starClaim(claimId);
  }

  unstarClaim = (claimId) => {
    this.props.claimActions.unstarClaim(claimId);
  }

  handleDelete = (claimId) => {
    this.setState({
      selectedClaimId: claimId,
      showDialog: true
    });
  }

  handleClaimDelete = () => {
      this.props.claimActions.deleteClaim(this.state.selectedClaimId);
      this.setState({
        showDialog: false
      });
  }

  hideDelete = () => {
    this.setState({
      selectedClaimId: null,
      showDialog: false
    });
  }

  handleEditClaim = (claimId) => {
    this.props.claimActions.startEditingClaim(claimId);
    this.props.claimActions.getMatchedSightingsForClaim(claimId);
  }

  render() {

    const {
      hasReceivedClaims,
      isRequestingClaims,
      claims,
      t,
      classes,
      permissions
    } = this.props;
    let claimsTable = "";
    if(isRequestingClaims) {
      claimsTable = (<div className={classes.progress}>
        <CircularProgress />
      </div>);
    } else if (hasReceivedClaims) {
      claimsTable = (
        <ClaimManager
          claims={claims}
          handleEditClaim={this.handleEditClaim}
          handleDelete={this.handleDelete}
          handleStarClaim={this.starClaim}
          handleUnstarClaim={this.unstarClaim}
          canAddClaims={true}
          canViewClaimDetails={permissions.includes('claim')}
        />
      );
    }

    return (
      <div className={classes.claimsPage}>
        {claimsTable}
        <Dialog
          open={this.state.showDialog}
          onClose={this.handleShowDelete}
        >
          <DialogTitle id="deleteclaim-dialog-title">{t('claims_page.delete_claim_title')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="deleteclaim-dialog-description">
              {t('claims_page.delete_claim_description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button onClick={this.hideDelete} color="secondary" variant="contained" autoFocus>
              {t('claims_page.delete_claim_cancel')}
            </Button>
            <Button className="claim-dialog-delete-button" onClick={this.handleClaimDelete} color="primary" variant="contained" autoFocus>
              {t('claims_page.delete_claim_delete')}
            </Button>
          </DialogActions>
        </Dialog>
        <ClaimFormContainer
          showPane={this.props.isEditingClaim}
          saveClaim={this.saveClaim}
        />
      </div>
    );
  }
}

ClaimsPage.propTypes = {
  claimActions: PropTypes.object.isRequired,
  noticeActions: PropTypes.object,
  claims:  PropTypes.array,
  hasReceivedClaims:  PropTypes.bool,
  filters: PropTypes.object,
  selectedClaims: PropTypes.array,
  isEditingClaim: PropTypes.bool,
  isRequestingClaims: PropTypes.bool,
  t: PropTypes.func,
  interventionActions: PropTypes.object,
  classes: PropTypes.object,
  permissions: PropTypes.array
};

function mapStateToProps(state) {
  return {
    claims: claimSelectors.getClaims(state),
    filters: claimSelectors.getFilters(state),
    hasReceivedClaims: claimSelectors.getHasReceivedClaims(state),
    isRequestingClaims: claimSelectors.getIsRequestingClaims(state),
    isEditingClaim: claimSelectors.getIsEditing(state),
    permissions: authSelectors.getPermissions(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    claimActions: bindActionCreators(claimActions, dispatch),
    noticeActions: bindActionCreators(noticeActions, dispatch),
    interventionActions: bindActionCreators(interventionActions, dispatch)
  };
}

export default compose(
  withStyles(() => ({
    topRow: {
      marginTop: '12px'
    },
    progress: {
      textAlign: 'center',
      marginTop: '24px'
    },
    claimsPage: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
  })),
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ClaimsPage);
