import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import Link from '@material-ui/core/Link';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Tooltip from '@material-ui/core/Tooltip';

const SearchSentence = (props) => {
    const {
      classes,
      dismissSighting,
      removeReviewedSighting,
      confirmSighting,
      key,
      isConfirmed,
      mediaHash,
      url,
      publication,
      text,
      authorName,
      category,
      favicon
    } = props;

    const [currentSentenceHoverMediaHash, setCurrentSentenceHoverMediaHash] = React.useState('');

    let sentenceButtonClass = classes.sentenceButtons;
    if(isConfirmed || currentSentenceHoverMediaHash) sentenceButtonClass = classes.sentenceButtonsVisible;
    return (
      <div key={key}>
        {authorName?.length && (<span className={classes.authorName}>{authorName}</span>)}
        <span className={classes.claimPubGrid + (isConfirmed ? ' '+ classes.confirmedMatch : '')}
          onMouseEnter={() => { setCurrentSentenceHoverMediaHash(key); }}
          onMouseLeave={() => { setCurrentSentenceHoverMediaHash(''); }}
          >
            <span className={classes.publicationIcon}>
              <Tooltip title={publication}>
                <img src={favicon} />
              </Tooltip>
            </span>
            <span className={classes.publicationLink}>
              <Link target="_blank" href={url}>
                <span>{text}<OpenInNewIcon className={classes.openInNewIcon} /></span>
              </Link>
            </span>
          <span className={sentenceButtonClass}> 
            <span className={classes.sentenceDismissButton} onClick={()=> { 
              dismissSighting(category, mediaHash);
            }}><CloseIcon /></span>
            {isConfirmed ? (
              <span className={classes.sentenceConfirmButton} onClick={()=> { 
                removeReviewedSighting(category, mediaHash);}}><DoneIcon style={{ color:'#009688' }} />
              </span>
            ): (
              <span className={classes.sentenceConfirmButton} onClick={()=> { 
                confirmSighting(category, mediaHash);
                }}><DoneIcon />
              </span>
            )}
          </span>
        </span>
      </div>
    );
};

SearchSentence.propTypes = {
  locale: PropTypes.string,
  key: PropTypes.string,
  isConfirmed: PropTypes.bool,
  mediaHash: PropTypes.string,
  url: PropTypes.string,
  publication: PropTypes.string,
  text: PropTypes.string,
  classes:  PropTypes.object,
  category: PropTypes.string,
  dismissSighting: PropTypes.func,
  removeReviewedSighting: PropTypes.func,
  confirmSighting: PropTypes.func,
  authorName: PropTypes.string,
  favicon: PropTypes.string
};

export default compose(
  withStyles((theme) => ({
    tpfcToggle: {
      float: 'right'
    },
    numberOfClaims: {
      marginTop: '20px'
    },
    digestHistoryDate: {
      marginBottom: '10px',
      marginRight: '4px',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    digestHistory: {
      float: 'right',
      color: '#555'
    },
    digestHistoryBack : {
      cursor: 'pointer',
      '& svg' : {
        fontSize: '20px'
      }
    },
    digestHistoryForward : {
      cursor: 'pointer',
      '& svg' : {
        fontSize: '20px'
      }
    },
    digestHistoryForwardDisabled : {
      '& svg' : {
        fontSize: '20px',
        color: '#ccc'
      }
    },
    claimLayout: {
      columnCount: '1',
      width: '100%'
    },
    '@media (min-width: 800px)' : {
      claimLayout: {
        columnCount: '2',
      }
    },
    claimContent: {
      breakInside: 'avoid-column',
      marginBottom: '16px'
    },
    confirmedMatch: {
      paddingLeft: '0 !important',
      backgroundColor: '#fff',
      borderLeft: '2px solid ' + theme.palette.primary[100] + ' !important',
    },
    openInNewIcon: {
        width: '15px',
        margin: '0 0 0 6px',
        verticalAlign: 'middle'
      },
    claimgrid: {
        display: 'grid',
        gridTemplateColumns: 'min-content 1fr',
        alignItems: 'top',
      },
    claimantLink: {
      marginTop: '6px',
      display: 'block',
      marginRight: '12px'
    },
    sentenceButtons: {
      marginLeft: '6px',
      cursor: 'pointer',
      '& svg' : {
        color: '#bbb'
      },
      visibility: 'hidden',
      paddingTop: '3px'
    },
    sentenceButtonsVisible: {
      marginLeft: '6px',
      cursor: 'pointer',
      '& svg' : {
        color: '#bbb'
      }
    },
    claimTitleLink: {
      color: theme.palette.primary[500]
    },
    content: {
      marginTop: '24px',
      maxWidth: '950px',
      margin: '0 auto'
    },
    authorName: {
      fontWeight: 'bold',
      color: '#777',
      fontSize: '14px',
      paddingLeft: '35px'
    },
    articleBox: {
        marginBottom: '18px'
      },
      clearBoth: {
        clear: 'both'
      },
      resultsCount: {
        float: 'left',
        marginBottom: '12px',
        marginTop: '0'
      },
      sortForm: {
        float: 'right',
        marginTop: '15px'
      },
      sortBy: {
        float: 'right',
        marginRight: '15px'
      },
      headline: {
        margin: '0 0 15px 0'
      },
      publication: {
        verticalAlign: 'middle'
      },
      publicationSeparator: {
        verticalAlign: 'middle'
      },
      publicationDate: {
        verticalAlign: 'middle'
      },
      wikiImage: {
        width: '20px'
      },
      wikiURL: {
        display: 'block',
        marginLeft: '10px'
      },
      img: {
        height: '45px',
        width: '45px'
      },
      claimPubGrid: {
        paddingLeft: '2px',
        display: 'grid',
        gridTemplateColumns: 'min-content 1fr min-content',
        '&:hover': {
            '& $sentenceButtons': {
              visibility: 'visible'
            }
        },
        marginBottom: '8px'
      },
      publicationLink: {
          wordBreak: 'break-word',
          paddingTop: '6px',
          display: 'inline-block',
          fontSize: '14px',
          lineHeight: '1.3',
          '& a': {
            color: '#333',
          },
          '& a:hover': {
            color: theme.palette.primary[500],
          }
      },
      publicationIcon: {
        padding: '8px 8px 0 8px'
      }
})),
React.memo
)(SearchSentence);
