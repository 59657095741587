import authSelectors from 'Selectors/authSelectors';

export function getTranscriptsCount(state) {
  if(state.transcriptState.transcripts) {
    return state.transcriptState.transcripts.length;
  } else {
    return 0;
  }
}

export function getMatchesInTranscript(state) {
  let count = 0;
  if(state.authState.user.role) {
    state.transcriptState.sentences.all.forEach(sentenceId => {
      let results = state.claimState.results[sentenceId];
      if(results && results.matchCount) count += results.matchCount;
    });
  }
  return count;
}

export function getSentenceIds(state) {
  return state.transcriptState.sentences.all;
}

export function getSentenceById(state, sentenceId) {
  let filteredSentences = state.transcriptState.sentences.all.filter(sid =>  sid = sentenceId);
  if (filteredSentences.length === 1) {
    return state.transcriptState.sentences.byId[sentenceId];
  }
  return null;
}

export function getSentences(state) {
  let filteredSentences = state.transcriptState.sentences.all.map(sentenceId => {
    let sentence = state.transcriptState.sentences.byId[sentenceId];
    return {
      ...sentence,
      enrichments: {
        ...sentence.enrichments,
        stats_checks: []
      }
    };
  });
  return filteredSentences.filter(stc => stc.transcript_sentence_id);
}

export function getTranscripts(state) {
  const {
    transcripts
  } = state.transcriptState;
  return {
    'channel': transcripts['channel'].items,
    'adhoc': transcripts['adhoc'].items,
    'radio': transcripts['radio'].items,
    'block': transcripts['block'].items,
    'youtube': transcripts['youtube'].items,
    'podcast': transcripts['podcast'].items
  }
}

export function getCombinedTranscripts(state) {

  return state.transcriptState.transcripts.all.items
}


export function getTranscriptsByType(state, type) {
  return state.transcriptState.transcripts[type].items.map(transcript => ({
    ...transcript,
    statistics: {
      ...transcript.statistics,
    }
  }));
}

export function getRadioStations(state) {
  return state.transcriptState.radioStations.all.map((stationId) => ({
    label: state.transcriptState.radioStations.byId[stationId].display_name,
    value: stationId
  }))
 }

 export function getRadioStationMediaCodes(state) {
  return state.transcriptState.radioStations.all.map((stationId) => ({
    label: state.transcriptState.radioStations.byId[stationId].display_name,
    value: state.transcriptState.radioStations.byId[stationId].media_code
  }))
 }
 
export function getSchedules(state) {
  if(state.transcriptState.schedules.all.length){
    const config = authSelectors.getConfig(state);
    return state.transcriptState.schedules.all.map((scheduleId) => {
      const schedule = state.transcriptState.schedules.byId[scheduleId];
      let recentTranscripts = [];
      if(schedule.recent_transcripts) {
        recentTranscripts = schedule.recent_transcripts.map((recentTranscript) => {
          return {
            ...recentTranscript,
            statistics: {
              ...recentTranscript.statistics,
            }
          };
        });
      }
      return {
        display_name: schedule.display_name,
        publication_id: schedule.publication_id,
        start_time: schedule.configuration.start_time,
        end_time: schedule.configuration.end_time,
        timezone: schedule.configuration.timezone,
        days: schedule.configuration.days,
        recent_transcripts: recentTranscripts,
        most_recent_transcript_time: recentTranscripts[0]?.createdAt,
        displayRadioStation: schedule.publication_name || '',
        displaySchedulePeriod: config.transcript.schedulePeriods["weekly"].display,
        uuid: schedule.uuid,
        next_expected_transcript_time: schedule.next_times.start_time,
        scheduleName: schedule.display_name,
        updated_by_name: schedule.updated_by_name,
        updated_at: schedule.updated_at,
        created_by_name: schedule.created_by_name,
        created_at: schedule.created_at
      };
    });
  } else {
    return [];
  }
}

export function getTranscriptDetails(state) {
  let formattedTranscriptDetails = {};
  const config = authSelectors.getConfig(state);
  if(state.transcriptState.details.transcriptName) {
    let channelName = state.transcriptState.details.channelName;
    if(config.channels[state.transcriptState.details.channelName]) channelName = config.channels[state.transcriptState.details.channelName].name;
    formattedTranscriptDetails = {
      ...state.transcriptState.details,
      channelName: channelName
    };
  }
  return formattedTranscriptDetails;
}

export function getTranscriptId(state) {
  return state.transcriptState.transcript.transcriptId;
}

export function getIsRequestingSentences(state) {
  return state.transcriptState.sentences.requestState === 'pending';
}

export function getHasReceivedSentencesSuccess(state) {
  return state.transcriptState.sentences.requestState === 'success';
}

export function getTranscriptMatches(state) {
  return {
    ...state.transcriptState.matches,
    totalMatchCount: state.transcriptState.matches.totalMatchCount - state.transcriptState.matches.totalStatsCount,
    statsChecks: {}
  };
}

export function getRequestState(state, subState) {
  return state.transcriptState[subState].requestState;
}

export function getLatestTranscriptConfig(state) {
  const config = authSelectors.getConfig(state);
  return config.latestTranscripts;
}

export function getScheduleDetails(state, scheduleId) {
  let schedules = getSchedules(state);
  if(schedules.length){
    schedules = schedules.filter(schedule => schedule.uuid === scheduleId);
    if(schedules[0]) return schedules[0];
  }
  return null;
}

export function getIsEditingSchedule(state) {
  return state.transcriptState.editingSchedule;
}

export function getIsCreatingSchedule(state) {
  return state.transcriptState.creatingSchedule;
}

export function getIsUpdatingSchedule(state) {
  return state.transcriptState.updatingSchedule;
}

export function getTranscriptTypes(state) {
  let transcriptTypes = [];
  let permissions = authSelectors.getPermissions(state);
  if(permissions.includes('transcript-channel')) transcriptTypes.push('channel');
  if(permissions.includes('transcript-block')) transcriptTypes.push('block');
  if(permissions.includes('transcript-youtube')) transcriptTypes.push('youtube');
  if(permissions.includes('transcript-radio')) transcriptTypes.push('radio');
  return transcriptTypes;
}

export function getNextTranscriptsPage(state, type) {
  return (state.transcriptState.transcripts[type] && state.transcriptState.transcripts[type].nextPageOffset) || 10;
}

export function getHasMoreTranscripts(state) {
  return state.transcriptState.transcripts.all && state.transcriptState.transcripts.all.hasNextPage;
}

export function getIsGettingMoreTranscripts(state) {
  return state.transcriptState.transcripts.all && state.transcriptState.transcripts.all.requestState === 'pending';
}

export function getSentenceIdAtTime(state) {
  let nearestSentenceId = null;
  const audioTime = state.transcriptState.transcriptTime;

  const sentences = state.transcriptState.sentences.all.map(sentenceId => {
    return state.transcriptState.sentences.byId[sentenceId];
  })

  if(Number.isInteger(audioTime) && sentences.length) {
    for (let i = 0; i < sentences.length; i++) {
      let sentenceTime = Math.floor(sentences[i].metadata.time_offset_ms / 1000);
      if (sentenceTime === audioTime) {
        nearestSentenceId = sentences[i].transcript_sentence_id;
        break;
      } else if(sentenceTime < audioTime) {
        nearestSentenceId = sentences[i].transcript_sentence_id;
      } else {
        break;
      }
    }
  }

  return nearestSentenceId
}

export function getLiveLandingView(state) {
  return state.transcriptState.liveLandingView;
}

export default ({
  getTranscriptsCount,
  getMatchesInTranscript,
  getSentenceIds,
  getSentences,
  getTranscripts,
  getTranscriptDetails,
  getTranscriptId,
  getIsRequestingSentences,
  getHasReceivedSentencesSuccess,
  getSchedules,
  getRadioStations,
  getRadioStationMediaCodes,
  getTranscriptMatches,
  getRequestState,
  getTranscriptsByType,
  getLatestTranscriptConfig,
  getScheduleDetails,
  getIsEditingSchedule,
  getIsCreatingSchedule,
  getIsUpdatingSchedule,
  getTranscriptTypes,
  getNextTranscriptsPage,
  getHasMoreTranscripts,
  getIsGettingMoreTranscripts,
  getSentenceById,
  getSentenceIdAtTime,
  getCombinedTranscripts,
  getLiveLandingView
});
