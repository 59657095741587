import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation, Trans } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import claimActions from 'Actions/claimActions';
import noticeActions from 'Actions/noticeActions';
import interventionActions from 'Actions/interventionActions';
import claimSelectors from 'Selectors/claimSelectors';
import interventionSelectors from 'Selectors/interventionSelectors';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import ClaimConfirmedMatch from 'Components/Claim/ClaimConfirmedMatch';
import ClaimFormContainer from 'Components/Claim/ClaimFormContainer';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

export class ClaimPage extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showDialog: false,
      showClaimForm: false
    };

    this.claimId = props.match.params['claimId'];

    props.claimActions.loadClaim(this.claimId);
    props.claimActions.getMatchedSightingsForClaim(this.claimId);
    props.interventionActions.loadInterventionsByClaimId(this.claimId);


  }

  componentWillUnmount() {
    this.props.claimActions.clearClaims();
    this.props.noticeActions.clearNotices();
  }

  handleFilterTextChange = (value) => {
    this.props.claimActions.setClaimsFilterText(value);
  }

  saveClaim = (claim) => {
    this.props.claimActions.saveClaim(claim, 'claims');
  }

  deleteClaim = () => {
    this.props.claimActions.deleteClaim(this.claimId);
  }

  starClaim = () => {
    this.props.claimActions.starClaim(this.claimId);
  }

  unstarClaim = () => {
    this.props.claimActions.unstarClaim(this.claimId);
  }

  handleDelete = () => {
    this.setState({
      selectedClaimId: this.claimId,
      showDialog: true
    });
  }

  handleClaimDelete = () => {
      this.props.claimActions.deleteClaim(this.state.selectedClaimId);
      this.setState({
        showDialog: false
      });
  }

  hideDelete = () => {
    this.setState({
      selectedClaimId: null,
      showDialog: false
    });
  }

  handleEditClaim = (claimId) => {
    this.props.claimActions.startEditingClaim(claimId);
    this.props.claimActions.getMatchedSightingsForClaim(claimId);
  }

  render() {
    const {
      claim,
      classes,
      matchedSightings,
      actions,
      t
    } = this.props;
      return (
        <div>
        <ClaimFormContainer
          showPane={this.props.isEditingClaim}
          saveClaim={this.saveClaim}
        />
        <div className="row container">
          <div className="col-xs4-4">
            <div className={classes.content}>
              {claim ? (
                <div>
                  <Typography variant="h2" component="h2">{t('claim_page.claim_id', {id: claim.tracked_claim_id})}</Typography>
                  <div className={classes.grid}>
                  <Card className={classes.mainContent}  variant="outlined">
                    <Typography variant="h3" component="h3">{claim.claim}</Typography>
                    <Typography variant="h6" component="h4">{t('claim_page.conclusion')}</Typography>
                    <p className={classes.paragraphText}>{claim.conclusion}</p>
                    <Typography variant="h6" component="h4">{t('claim_page.factcheck')}</Typography>
                    <p><a className={classes.claimCardLink} href={claim.factcheck_url} target="_blank">{claim.factcheck_url}</a></p>
                    {claim.external_claim_id === null ? (
                    <Button onClick={() => {this.handleEditClaim(claim.tracked_claim_id);}} color="primary" variant="contained">{t('claim_page.edit_claim')}</Button>
                    ): (
                    <Tooltip className={classes.claimReviewIcon} title={t('claim_manager.synced_claims_cannot_edit')}>
                      <CheckBoxOutlinedIcon />
                   </Tooltip>)}
                  </Card>
                    <div className={classes.secondaryContent}>
                    <Card className={classes.cardContent}  variant="outlined">
                      <Typography variant="h3" component="h3">{t('claim_page.actions')}</Typography>
                      {actions.length ? (
                        <div>
                        <p className={classes.paragraphText}>{t('claim_page.total_num_actions', {count: actions.length})}</p>
                        <p className={classes.paragraphText}> <Link to={"/actions/?cases=~(~(field~%27tracked_claim_id~values~(~%27"+claim.tracked_claim_id+")))"} className={classes.claimCardLink}>{t('claim_page.view_all_actions')}</Link></p>
                        </div>
                      ): (
                        <p className={classes.paragraphText}>
                          <Trans i18nKey="claim_page.no_actions_taken" parent="div">
                          No actions have yet been taken. Try finding claims to take action on via <Link to={"/alerts"} className={classes.claimCardLink}>alerts page</Link>.</Trans>
                        </p>
                      )}
                    </Card>
                    <Card className={classes.cardContent} variant="outlined">
                      <Typography variant="h3" component="h3">{t('claim_page.confirmed_alerts')}</Typography>
                          {matchedSightings.length ? (
                            <div>
                                <Typography variant="h6" component="h3">{t('claim_page.from_alerts', {count: matchedSightings.length})}</Typography>
                                {matchedSightings.map((result, id) => (
                                    <ClaimConfirmedMatch
                                        url={result.url}
                                        text={result.text}
                                        key={result + id}
                                        mediaHash={result.media_hash}
                                        claimId={claim.tracked_claim_id}
                                      />
                                ))}
                            </div>
                          ): (
                            <p className={classes.paragraphText}>
                              <Trans i18nKey="claim_page.no_confirmed_alerts" parent="div">
                              No confirmed alerts. Visit the <Link to={"/alerts"} className={classes.claimCardLink}>alerts page</Link> daily to find some.
                              </Trans>
                            </p>
                          )}
                  </Card>
                  </div>
                </div>
                </div>
              ): <CircularProgress />}
            </div>
          </div>
        </div>
        </div>
      );
  }
}

ClaimPage.propTypes = {
  claim: PropTypes.object,
  match: PropTypes.object.isRequired,
  claimActions: PropTypes.object,
  interventionActions: PropTypes.object,
  noticeActions: PropTypes.object,
  classes: PropTypes.object,
  matchedSightings: PropTypes.array,
  actions: PropTypes.array,
  t: PropTypes.func,
  isEditingClaim: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  const claimId = ownProps.match.params['claimId'];
  return {
    claim: claimSelectors.getClaimById(state, claimId),
    matchedSightings: claimSelectors.getMatchedSightingsByClaimId(state, claimId),
    actions: interventionSelectors.getInterventionsByClaimId(state, claimId),
    isEditingClaim: claimSelectors.getIsEditing(state),

  };
}

function mapDispatchToProps(dispatch) {
  return {
    claimActions: bindActionCreators(claimActions, dispatch),
    noticeActions: bindActionCreators(noticeActions, dispatch),
    interventionActions: bindActionCreators(interventionActions, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(),
  withStyles((theme) => ({
    grid: {
      display: 'grid',
      gridTemplateColumns: 'auto 300px',
      gridGap: '30px'
    },
    mainContent: {
      gridColumn: '1',
      alignSelf: 'start',
      padding: '12px'
    },
    secondaryContent: {
      gridColumn: '2'
    },
    cardContent: {
      padding: '12px',
      marginBottom: '24px'
    },
    content: {
      marginTop: '24px',
      margin: '0 auto'
    },
    claimCardLink: {
      color: '#009688',
    },
    paragraphText: {
      lineHeight: '1.3',
      marginBottom: '12px'
    },
    claimReviewIcon: {
      color: '#e37262'
    }
}))
)(ClaimPage);
