import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Particles from 'react-particles-js';
import { useTheme } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';

const ParticleCard = (props) => {

  const theme = useTheme();
  let bgColor = '#fff';
  let lnColor = '#000';

  if(theme) {
    lnColor = theme.palette.primary[500];
    bgColor = theme.palette.background.default;
  }
  const {
    children,
    classes
  } = props;

  return (
    <section>
     <Particles className={classes.container} params={{
      particles: {
      color: {
        value: lnColor
      },
      shape: {
        type: "circle",
          stroke: {
          width: 1,
          color: "#fff"
        },
      },
      number: {
          value: 40,
          density: {
          enable: true,
      }
      },
      line_linked: {
          color: lnColor
      }
      }
      }}
      style={{
          width: '100%',
          height: '100%',
          backgroundColor: bgColor
      }}
        />
      <div className={classes.inner}>
        <Paper className={classes.card}>
            {children}
        </Paper>
      </div>
      </section>
  );
};

ParticleCard.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.object
};

export default withStyles(() => ({
  container:  {
    background: '#eee',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: '0px',
    left: '0px',
    zIndex: '2'
  },
  inner: {
    position: 'absolute',
    top: '100px',
    left: '0',
    width: '100%',
    zIndex: '100',
    textAlign: 'center',
    ['@media (max-width: 450px)']: {
      left: '0',
      top: '10px',
      marginLeft: '0'
    }
  },
  card: {
    width: '400px',
    paddingTop: '24px',
    display: 'inline-block',
    ['@media (max-width: 450px)']: {
      width: '100%',
      height: '100%'
    }
  }
}))(ParticleCard);
