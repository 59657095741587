const claimTypes =  {
  quantity: "candidates_search.claim_types.quantity",
  correlation: "candidates_search.claim_types.correlation",
  opinion: "candidates_search.claim_types.opinion",
  other: "candidates_search.claim_types.other",
  personal: "candidates_search.claim_types.personal",
  predictions: "candidates_search.claim_types.predictions",
  rules: "candidates_search.claim_types.rules",
  support: "candidates_search.claim_types.support",
  voting: "candidates_search.claim_types.voting"
};

export default claimTypes;
