import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons//Help';
import Tooltip from '@material-ui/core/Tooltip';
import claimSelectors from 'Selectors/claimSelectors';
import claimActions from 'Actions/claimActions';
import interventionActions from 'Actions/interventionActions';
import candidateActions from 'Actions/candidateActions';
import candidateSelectors from 'Selectors/candidateSelectors';
import { withTranslation } from 'react-i18next';

export class ImpactPage extends React.Component {

  constructor(props){
    super(props);
    props.claimActions.loadClaims();
    props.claimActions.getConfirmedSightings();
    props.claimActions.getDismissedSightings();
    props.interventionActions.loadInterventions();
  }

  render() {

    const {
      classes,
      confirmedSightingsCount,
      dismissedSightingsCount,
      confirmedSightingsWithActionCount,
      customSightingsCount,
      customSightingsFromAlphaCount,
      mediaSightingsCount,
      mediaSightingsFromAlphaCount,
      totalSightingsCount,
      totalSightingsWithActionsCount,
      t
    } = this.props;

    return (
      <div className="row container">
        <div className="col-xs4-4">
          <div className={classes.content}>
            <Typography variant="h2" component="h2">Impact</Typography>
            <br/>
            <Typography variant="h3" component="h3">Finding misinformation</Typography>
            <Typography variant="h4" component="h4">Reviewed Sightings</Typography>
            <p>Currently only includes sightings that have been reviewed through digest
            and come from our monitored media.</p>

            <ul><li>Number of Dismissed Sightings: {dismissedSightingsCount}</li>
            <li>Number of Confirmed Sightings{confirmedSightingsCount}</li></ul>
            <ul><li>Number of Confirmed Sightings with actions: {confirmedSightingsWithActionCount}  <Tooltip title="Because a confirmed sighting is created using our monitored media it is possible to take action on a confirmed sighting."><HelpIcon className={classes.helpIcon} fontSize="small"/></Tooltip></li></ul>

            <Typography variant="h4" component="h4">Custom Sightings</Typography>
            <p>A custom sighting is created when a user creates an action and the source of the sighting is outside of alpha. Because of this all Custom Sightings have an action associated with them.</p>
            <ul><li>Number of Custom Sightings: {customSightingsCount}</li>
            <li>Number of Custom Sightings found through alpha: {customSightingsFromAlphaCount} <Tooltip title="The number of custom sightings found through alpha should be zero, if it is not this suggest a difficulty in taking action on media found in alpha. One scenario might be that it was spotted but later cannot be found."><HelpIcon className={classes.helpIcon} fontSize="small"/></Tooltip></li></ul>


            <Typography variant="h4" component="h4">Media Sightings</Typography>
            <p>A media sighting is created when an action is taken on our media. This was historically using the sightings that appear through trends searches. As such, all media sightings have an action.</p>
            <ul><li>Number of Media Sightings: {mediaSightingsCount}</li>
            <li>Number of Media Sightings found through alpha: {mediaSightingsFromAlphaCount}</li></ul>


            <Typography variant="h4" component="h4">All Sightings</Typography>

            <p>Total sightings includes all media sightings, custom sightings, and confirmed sightings.</p>
            <ul><li>Number of Total Sightings: {totalSightingsCount}</li></ul>
            <ul><li>Number of Sightings with Actions: {totalSightingsWithActionsCount} <Tooltip title="Because confirmed sightings can happen outside of creating an action there should generally be less sightings with actions than Total Sightings."><HelpIcon className={classes.helpIcon} fontSize="small"/></Tooltip></li></ul>

            </div>
        </div>
      </div>
    );
  }
}

ImpactPage.propTypes = {
    claimActions: PropTypes.object,
    interventionActions: PropTypes.object,
    candidateActions: PropTypes.object,
    classes: PropTypes.object,
    annotationTasksCount: PropTypes.string,
    dismissedSightingsCount: PropTypes.number,
    confirmedSightingsCount: PropTypes.number,
    confirmedSightingsWithActionCount: PropTypes.number,
    customSightingsCount: PropTypes.number,
    customSightingsFromAlphaCount: PropTypes.number,
    mediaSightingsCount: PropTypes.number,
    mediaSightingsFromAlphaCount: PropTypes.number,
    totalSightingsCount: PropTypes.number,
    totalSightingsWithActionsCount: PropTypes.number,
    t: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    claimActions: bindActionCreators(claimActions, dispatch),
    interventionActions: bindActionCreators(interventionActions, dispatch),
    candidateActions: bindActionCreators(candidateActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    dismissedSightingsCount: claimSelectors.getDismissedSightingsCount(state),
    confirmedSightingsCount: claimSelectors.getConfirmedSightingsCount(state),
    confirmedSightingsWithActionCount: claimSelectors.getConfirmedSightingsWithActionCount(state),
    customSightingsCount: claimSelectors.getCustomSightingsCount(state),
    customSightingsFromAlphaCount: claimSelectors.getCustomSightingsFromAlphaCount(state),
    mediaSightingsCount: claimSelectors.getMediaSightingsCount(state),
    annotationTasksCount: candidateSelectors.getCompletedAnnotationTasksCount(state),
    mediaSightingsFromAlphaCount: claimSelectors.getMediaSightingsFromAlphaCount(state),
    totalSightingsCount: claimSelectors.getTotalSightingsCount(state),
    totalSightingsWithActionsCount: claimSelectors.getTotalSightingsWithActionsCount(state)
  };
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles((theme) => ({
    content: {
      marginTop: '24px',
      maxWidth: '800px',
    },
    helpIcon: {
      color: '#888'
    }
}))
)(ImpactPage);
