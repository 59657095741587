import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import PasswordField from 'Components/Common/PasswordField';
import { compose } from 'redux';
import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
export class UserLoginForm extends Component {

  constructor(props) {
      super(props);

      this.state = {
          password: '',
      };

  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.handleSubmit(
      this.props.organisation, 
      this.props.email, 
      this.state.password
    );
  }

  handleOrgChange = (org) => {
    if(org) {
      this.props.removeErrorNotice();
    }
    this.props.onFieldChange('organisation', org);
  }
  handleEmailChange = (e) => {
    this.props.onFieldChange(e.target.name, e.target.value);
  }
  handlePasswordChange = (e) => {
    this.setState({[e.currentTarget.name]: e.currentTarget.value});
  }
  
  render() {

    const { password } = this.state;
    const { 
      possibleOrgs,
      t,
      classes,
      email,
      theme,
      isLoggingIn
    } = this.props;


    const selectStyles = {
      control: (provided, state) => {
        const borderColor = state.isFocused ? theme.palette.primary[500] : provided.borderColor;
        const boxShadow = state.isFocused ? '0 0 0 1px ' + theme.palette.primary[500] : provided.boxShadow;
        return { ...provided, borderColor, boxShadow,
        '&:hover': {
          borderColor
        } };
      }
    };

    return (
          <form onSubmit={this.handleSubmit} className={classes.loginForm}>
            {possibleOrgs && (
            <FormControl fullWidth={true}>
              <InputLabel shrink htmlFor="organisation">{t('user_login_form.organisation')}</InputLabel>
              <Select
                defaultValue=""
                onChange={({value}) => { this.handleOrgChange(value); }}
                placeholder={t("user_login_form.organisation")}
                name="organisation"
                inputId="organisation"
                options={possibleOrgs}
                styles={selectStyles}
              />
            </FormControl>
          )}
            <div className={classes.loginFormField}>
            <TextField
              type="text"
              name="email"
              value={email}
              onChange={this.handleEmailChange}
              label={t('user_login_form.email')}
              required={true}
              fullWidth={true}
              autoComplete="off"
            />
            </div>
            <PasswordField
              label={t('user_login_form.password')}
              name="password"
              password={password}
              onFieldChange={this.handlePasswordChange}
            />
            <div className={classes.button}>
              {isLoggingIn ? (
                <CircularProgress />
              ): (
                <Button type="submit" color="primary" variant="contained">{t('user_login_form.login_button')}</Button>
              )}
            </div>
          </form>
    );
  }
}

UserLoginForm.propTypes = {
  possibleOrgs: PropTypes.array,
  email: PropTypes.string,
  defaultOrganisation: PropTypes.string,
  handleSubmit: PropTypes.func,
  onFieldChange: PropTypes.func,
  t: PropTypes.func,
  theme: PropTypes.object,
  classes: PropTypes.object,
  removeErrorNotice: PropTypes.func,
  isLoggingIn: PropTypes.bool
};

export default compose(
  withStyles(() => ({
    loginFormField: {
      margin: '12px 0'
    },
    loginPage: {
      backgroundColor: '#eee',
      position: 'fixed',
      width: '100vw',
      top: '0px',
      left: '0px',
      zIndex: '2',
      alignItems: 'left'
    },
    loginForm: {
      padding: '32px 24px 0 24px',
      textAlign: 'left',
      '& h1': {
        textAlign: 'center'
      }
    },
    button: {
      textAlign: 'center',
      marginTop: '24px'
    },
    error: {
      color: '#333',
      textAlign: 'center',
      backgroundColor: '#ffcdd2',
      padding: '16px 12px'
    },
    orgSelector: {
      textAlign: 'left'
    }
  })),
  withTranslation(),
  withTheme
)(UserLoginForm);
