
export default function fetchCall(params) {

  let payload = { headers: { 'Content-Type': 'application/json' } };
  if(params.token && !params.excludeToken) payload.headers.Authorisation = `Bearer ${params.token}`;
  if(params.method) payload.method = params.method;
  if(params.body) {
    if(params.method === 'GET' && params.excludeToken){
      let searchParams = new URLSearchParams('');
      for(let key in params.body) {
        if(Object.prototype.hasOwnProperty.call(params.body, key)) {
          searchParams.set(key, params.body[key]);
        }
      }
      return fetch(params.url + '?' + searchParams.toString());
    } else {
      payload.body = JSON.stringify(params.body);
      return fetch(
        params.url,
        payload
      );
    }
  } else {
    return fetch(
      params.url,
      payload
    );
  }
}
