import React from 'react';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import claimActions from 'Actions/claimActions';
import claimSelectors from 'Selectors/claimSelectors';

export function ClaimConfirmedMatch(props){

  const {classes} = props;
  function removeMatchedSighting(claimId, mediaHash){
    props.claimActions.removeMatchedSighting(claimId, mediaHash);
  }

  return (
    <div className={classes.result}>
      <div className={classes.claimPubGrid}>
        <span className={classes.confirmedMatch}>
          <a className={classes.confirmedMatchLink} target="_blank" href={props.url}>{props.text} <OpenInNewIcon /></a>
        </span>
        {props.mediaHash && (
          <span className={classes.claimantDismissButton}>
            <span onClick={()=> { removeMatchedSighting(props.claimId, props.mediaHash);}}><CloseIcon /></span>
          </span>
        )}
      </div>
    </div>
  );
}

ClaimConfirmedMatch.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  classes: PropTypes.object,
  claimActions: PropTypes.object,
  claimId: PropTypes.number,
  mediaHash: PropTypes.string
};

function mapStateToProps(state) {
  return {
    claims: claimSelectors.getClaims(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    claimActions: bindActionCreators(claimActions, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles((theme) => ({
    claimPubGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr min-content'
    },
    confirmedMatchLink: {
      color: '#009688',
      textDecoration: 'underline',
      '& svg' : {
        fontSize: '12px'
      },
    },
    claimantDismissButton: {
      marginLeft: '6px',
      cursor: 'pointer',
      '& svg' : {
        color: '#555'
      }
    },
    result: {
      margin: '12px 0',
      padding: '12px 0',
      lineHeight: '1.5',
      borderTop: '1px solid #ddd'
    }
}))
)(ClaimConfirmedMatch);