import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import adminState from './adminReducers';
import authState from './authReducers';
import appState from './appReducers';
import channelState from './channelReducers';
import candidateState from './candidateReducers';
import claimState from './claimReducers';
import interventionState from './interventionReducers';
import noticeState from './noticeReducers';
import transcriptState from './transcriptReducers';
import entityState from './entityReducers';

const rootReducer = combineReducers({
  adminState,
  appState,
  authState,
  candidateState,
  channelState,
  claimState,
  interventionState,
  noticeState,
  transcriptState,
  entityState,
  routing: routerReducer
});

export default rootReducer;
