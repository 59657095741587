const monitoredMedia = [
    "online-newspapers",
    "social-media",
    "radio",
    "parliament",
    "youtube-channel",
    "podcast"
];
  
  export default monitoredMedia;
  
