import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import claimSelectors from 'Selectors/claimSelectors';
import claimActions from 'Actions/claimActions';
import noticeActions from 'Actions/noticeActions';
import NoticeContainer from 'Components/Common/NoticeContainer';
import AddEditDrawer from 'Components/Common/AddEditDrawer';
import ClaimConfirmedMatch from 'Components/Claim/ClaimConfirmedMatch';
import ClaimForm from './ClaimForm';

export class ClaimFormContainer extends Component {

    constructor(props){
        super(props);
        this.state =  {
          callbacks : {},
          search: ''
        };
    }

    handleToggle = () => {
      if(this.props.showPane) {
        this.props.claimActions.stopEditingClaim();
        this.setState({search: ''});
      } else {
        this.props.claimActions.startEditingClaim();
      }
    }

    setValue = (field, value) => {
      this.setState({claim: {
          ...this.state.claim, [field]: value
      }});
    }

    saveClaim = (claim) => {
      this.props.saveClaim(claim);
    }

    render() {
      const { 
        showPane, 
        claim, 
        t, 
        matchedSightings, 
        actionSightings
      } = this.props;
      
      const claimId = claim ? claim.trackedClaimId : 0;
      const title = claim ? t('claim_form.title_edit_claim') : t('claim_form.title_new_claim');

        return (
          <div>
            <AddEditDrawer
              handleToggle={this.handleToggle}
              isActive={showPane || false}
              title={title}
            >
              <section>
                <ClaimForm
                  handleSaveClaim={this.saveClaim}
                  initialClaim={claim}
                />
                {actionSightings && actionSightings.length ? (
                  <div>
                    <h3>{t('claim_form.action_sightings')}</h3>
                    {actionSightings.map((result, id) => (
                        <ClaimConfirmedMatch
                            url={result.url}
                            text={result.text}
                            key={result + id}
                            mediaHash={result.media_hash}
                            claimId={claimId}
                          />
                    ))}
                  </div>
                ):''}
                {matchedSightings && matchedSightings.length ? (
                  <div>
                    <h3>{t('claim_form.confirmed_sightings')}</h3>
                    {matchedSightings.map((result, id) => (
                        <ClaimConfirmedMatch
                            url={result.url}
                            text={result.text}
                            key={result + id}
                            mediaHash={result.media_hash}
                            claimId={claimId}
                          />
                    ))}
                  </div>
                ):''}
                <NoticeContainer displayCode="claim_edit_error" dismissible={true} />
              </section>
            </AddEditDrawer>
          </div>
        );
    }
}


ClaimFormContainer.propTypes = {
  claimActions: PropTypes.object.isRequired,
  noticeActions: PropTypes.object,
  triggerButton: PropTypes.object,
  showPane: PropTypes.bool,
  claim: PropTypes.object,
  saveClaim: PropTypes.func,
  t: PropTypes.func,
  matchedSightings: PropTypes.array,
  actionSightings: PropTypes.array
};

function mapStateToProps(state, ownProps) {
  return {
    claim: claimSelectors.getClaimByEditingId(state),
    showPane: claimSelectors.getIsEditing(state),
    matchedSightings: claimSelectors.getMatchedSightingsForEditingId(state),
    actionSightings: claimSelectors.getActionSigthingsForEditingId(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    claimActions: bindActionCreators(claimActions, dispatch),
    noticeActions: bindActionCreators(noticeActions, dispatch)
  };
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ClaimFormContainer);
