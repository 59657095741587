import channelConstants from 'Constants/channelConstants';
import * as utils from '../utils';

export function subtitleArrived(subtitle, interim) {
  subtitle = JSON.parse(subtitle);
  subtitle = utils.transformSentence(subtitle);
  return {
    type: channelConstants.SUBTITLE_ARRIVED,
    subtitle: subtitle,
    interim: interim
  };
}

export function clearLiveSubtitles(){
  return {
    type: channelConstants.CLEAR_SUBS
  };
}

export function clearChannel(){
  return {
    type: channelConstants.CLEAR_CHANNEL
  };
}

export function loadChannels(){
  return {
    type: channelConstants.GET_CHANNELS
  };
}

export function loadChannel(id){
  return {
    type: channelConstants.GET_CHANNEL,
    id: id
  };
}

export default {
  subtitleArrived: subtitleArrived,
  clearLiveSubtitles: clearLiveSubtitles,
  clearChannel: clearChannel,
  loadChannels: loadChannels,
  loadChannel: loadChannel
};