import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation, Trans } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import entityActions from 'Actions/entityActions';
import entitySelectors from 'Selectors/entitySelectors';
import Typography from '@material-ui/core/Typography';

export class EntityDetails extends React.Component {

  constructor(props){
    super(props);
  }

  render() {
    const {
      t,
      entity,
      classes
    } = this.props;
      return (
        <div className={classes.details}>
          <Typography variant="h2" component="h2">
            {t('entity_details.entity', { entity: entity.label})}
          </Typography>
          <p><Link to={'/entities/'}>{t('entity_details.back_to_all')}</Link></p>
          <div className={classes.grid}>
            <div> <img width="200"src={entity.image} /></div>
            <div>
          <ul>
            <li>{entity.description}</li>
            <li>
              <Trans i18nKey="entity_details.wikidata_id" parent="div">
                Wikidata id: <a href={"https://www.wikidata.org/wiki/" +entity.wikiId} target="_blank">{entity.wikiId}</a>
              </Trans>
            </li>
            <li>
              <Trans i18nKey="entity_details.wikidata_url" parent="div">
              Wikipedia url: <a href={entity.url} target="_blank">{entity.url}</a>
              </Trans>
            </li>
          </ul>
            {entity.isHuman ? (
              <ul>
                  <li>{entity.occupation}{t('entity_details.occupation', { occupation: entity.occupation})}</li>
                  <li>{entity.memberOf}{t('entity_details.member_of', { org: entity.memberOf})}</li>
              </ul>            
            ): (
              <ul>
                <li>{entity.memberOf}{t('entity_details.owned_by', { owner: entity.ownedBy})}</li>
              </ul>
            )}
            </div>
            </div>
        </div>
      );
  }
}

EntityDetails.propTypes = {
  claim: PropTypes.object,
  match: PropTypes.object.isRequired,
  claimActions: PropTypes.object,
  interventionActions: PropTypes.object,
  noticeActions: PropTypes.object,
  classes: PropTypes.object,
  matchedSightings: PropTypes.array,
  actions: PropTypes.array,
  t: PropTypes.object,
  isEditingClaim: PropTypes.bool,
  entityId: PropTypes.string,
  entity: PropTypes.object
};


function mapStateToProps(state, ownProps) {
  const entityId = ownProps.entityId;
  return {
    entity: entitySelectors.getEntity(state, entityId)
  };
}


function mapDispatchToProps(dispatch) {
  return {
    entityActions: bindActionCreators(entityActions, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(),
  withStyles((theme) => ({
    grid: {
      display: 'grid',
      gridTemplateColumns: '212px auto',
      marginBottom: '12px'
    },
    mainContent: {
      gridColumn: '1',
      alignSelf: 'start',
      padding: '12px'
    },
    secondaryContent: {
      gridColumn: '2'
    },
    cardContent: {
      padding: '12px',
      marginBottom: '24px'
    },
    details: {
      '& a': {
        color: theme.palette.primary[500]
      }
    },
    claimCardLink: {
      color: '#009688',
    },
    paragraphText: {
      lineHeight: '1.3',
      marginBottom: '12px'
    }
}))
)(EntityDetails);
