import React from 'react';
import { compose } from 'redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ActionTableFilterValues from './ActionTableFilterValues';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';

function ActionTableFilterForm(props) {
  const {
    comparison,
    field,
    fieldType,
    filterableFields,
    possibleValues,
    values,
    handleFilterChange,
    classes,
    t
  } = props;

  function handleFieldChange(e) {
    handleFilterChange({
      field: e.target.value,
      values: [],
      comparison: comparison
    });
  }

  function handleTextFieldChange(e) {
    handleFilterChange({
      field: field,
      values: [e.target.value]
    });
  }

  function handleValuesSelect(values) {
    let newValues = values.slice();
    handleFilterChange({
      field: field,
      values: newValues,
      comparison: comparison
    });

  }

  return (
    <div className={classes.filterForm}>
      <FormControl className={classes.filterFormField}>
       <Select
         value={field}
         onChange={handleFieldChange}
         inputProps={{
           name: 'field',
           id: 'field',
         }}
       >
        {filterableFields.map((field) => {
          return <MenuItem key={field.name} value={field.name}>{field.display || field.name}</MenuItem>;
        })}
       </Select>
     </FormControl>
     {field && field !== '' ? (
       <span>
          {fieldType === 'text' ? (
            <TextField 
              placeholder={t('intervention_table_filter_form.claim_id')}
              onChange={handleTextFieldChange}
              type="number"
            />
          ): (
            <ActionTableFilterValues
              type={fieldType}
              possibleValues={possibleValues}
              selectedValues={values}
              handleFilterChange={handleFilterChange}
              handleValuesSelect={handleValuesSelect}
              field={field}
            />
          )}
       </span>
    ): ''}
    </div>
  );
}

export default compose(
  withTranslation(),
  withStyles(() => ({
  filterForm: {
    display: 'inline-block'
  },
  filterFormField : {
    verticalAlign: 'middle',
    marginRight: '12px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'rgb(0, 132, 137)'
    }
  },
  filterFormComparison: {
    vertialAlign: 'middle'
  }
})))(ActionTableFilterForm);

ActionTableFilterForm.propTypes = {
  comparison: PropTypes.object,
  field: PropTypes.string,
  fieldType: PropTypes.string,
  filterableFields: PropTypes.array.isRequired,
  possibleValues: PropTypes.array,
  values: PropTypes.array,
  handleFilterChange: PropTypes.func,
  classes: PropTypes.object,
  t: PropTypes.func
};