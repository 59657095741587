import { all, put, takeEvery } from 'redux-saga/effects';

import noticeConstants from 'Constants/noticeConstants';

const delay = (ms) => new Promise(res => setTimeout(res, ms));

export function* processAddTemporaryNotice(action) {
  yield delay(action.duration);
  yield put({
    type: noticeConstants.REMOVE_NOTICE,
    ref: action.ref
  });
}

export function* noticeSagas(){
  yield takeEvery(noticeConstants.ADD_TEMP_NOTICE, processAddTemporaryNotice);
}