import React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

function NavSiteLogo(props) {
  const { classes } = props;
  return (
    <div className={classes.logoContainer}>
      <div className={classes.siteLogo}>
        <NavLink to="/">
          <span className={classes.company}>Full Fact</span> AI
        </NavLink>
      </div>
    </div>
  );
}

NavSiteLogo.propTypes  = {
  classes: PropTypes.object
};

export default withStyles((theme) => ({
  siteLogo: {
    fontSize: '34px',
    fontStyle: 'italic',
    fontFamily: '\'Nunito\', sans-serif',
    fontWeight: 'normal',
    margin: '8px auto 0 auto',
    '& a': {
        textDecoration: 'none',
        color: theme.palette.text.primary
    }
  },
  company: {
    display: 'block',
    fontSize: '11px',
    marginBottom: '-2px'
  },
  logoContainer: {
    display: 'inline-block',
    marginLeft: '12px'
  },
  '@media (min-width: \'640px\')': {
    logoContainer: {
      marginLeft: '0'
    }
  }
}))(NavSiteLogo);