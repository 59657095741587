import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import NoticeContainer from 'Components/Common/NoticeContainer';
import TranscriptSentencesContainer  from "Components/Transcript/TranscriptSentencesContainer";
import TranscriptDetails from 'Components/Transcript/TranscriptDetails';

import noticeActions from 'Actions/noticeActions';
import transcriptActions from 'Actions/transcriptActions';
import transcriptSelectors from 'Selectors/transcriptSelectors';

class TranscriptChannelPage extends React.Component {

  constructor(props){
    super(props);
    props.transcriptActions.getChannelTranscript(props.match.params['transcriptId']);
  }

  componentWillUnmount = () => {
      this.props.transcriptActions.clearTranscript();
      this.props.noticeActions.clearNotices();
  }

  loadTranscriptSentences = (transcriptId, sentenceId) => {
    this.props.transcriptActions.getChannelTranscriptSentences(transcriptId, sentenceId);
  }

  handleSentenceHighlight = (transcriptId, sentenceId, hasHighlight) => {
      if(hasHighlight) {
        this.props.transcriptActions.unhighlightSentence('channel', transcriptId, sentenceId);
      } else {
        this.props.transcriptActions.highlightSentence('channel', transcriptId, sentenceId);
      }
  }


  render() {
    const {
      transcript,
      transcriptActions,
      classes
    } = this.props;

    return (
      <section>
        <div className="row">
          <div className="col-xs4-4 col-lg-12">
            {transcript && transcript.transcriptName ? (
              <TranscriptDetails
                transcript={transcript}
                handleChangeName={transcriptActions.updateChannelTranscriptName}
                handleStopTranscript={transcriptActions.stopChannelTranscript}
                canChangeName={true}
                isOpen={transcript.isOpen}
              />
            ): ''}
          </div>
        </div>
          <div className={classes.content}>
            <NoticeContainer displayCode="page_notice" dismissible={true} />
          </div>
        {transcript && transcript.transcriptName ? (
          <TranscriptSentencesContainer
            isOpen={transcript.isOpen}
            transcriptId={transcript.transcriptId}
            colClass="col-full-row"
            loadTranscriptSentences={this.loadTranscriptSentences}
            showTime={true}
            handleSentenceHighlight={this.handleSentenceHighlight}
            initialSentenceId={parseInt(this.props.history.location.hash?.substring(1),10)}
          />
        ): ''}
      </section>
    );
  }
}

TranscriptChannelPage.propTypes = {
  transcriptActions: PropTypes.object.isRequired,
  noticeActions: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  transcript: PropTypes.object,
  classes: PropTypes.object
};

function mapStateToProps(state) {
  return {
    transcript: transcriptSelectors.getTranscriptDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transcriptActions: bindActionCreators(transcriptActions, dispatch),
    noticeActions: bindActionCreators(noticeActions, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles((theme) => ({
    content: {
      marginTop: '24px'
    }
}))
)(TranscriptChannelPage);