import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withTranslation } from 'react-i18next';
import LiveMatch from 'Components/Live/LiveMatch';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import Chip from '@material-ui/core/Chip';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

export class LiveChannelSentence extends Component{

  constructor(props){
    super(props);
    this.createTranscript = this.createTranscript.bind(this);
  }

  createTranscript(){
    let data = {
      type: 'channel',
      name: this.props.display + ' ' + moment.utc(this.props.sentence.broadcast_time).local().format('YYYY-MM-DD'),
      source: this.props.sentence.canonical_channel_name,
      isLive: true,
      startDate: moment.utc(this.props.sentence.broadcast_time).subtract(1, 's').format('YYYY-MM-DDTHH:mm:ss')
    };
    this.props.createTranscript(data);
  }

  render(){
    const {
      sentence,
      showClaimDetection,
      isFactchecker,
      classes
    } = this.props;

    let matches = this.props.results  || {};
    let hasResults = false;
    let sentenceClass = classes.sentence;
    let sentenceStyle = {};
    let color;
    hasResults = matches && matches.matchCount || false;
    let type = null;
    if(matches.types && matches.types.length) type = matches.types[0];

    if(matches) {
      if (hasResults) {
          color = sentence.color;
          sentenceClass += " " + classes.underline;
          sentenceStyle = {
            color: color,
            fontWeight: '300'
          };
      }

      if(matches.claimDetected && showClaimDetection){
        sentenceStyle.fontWeight = '500';
      }

    }

    let claimTypes = {
      'personal': {
        name: 'Personal',
        color: 'rgb(187, 212, 238)'
      },
      'quantity': {
        name: 'Quantity',
        color: 'rgb(204, 238, 254)'
      },
      'correlation': {
        name: 'Correlation',
        color: 'rgb(254, 221, 204)'
      },
      'rules':{
        name: 'Rules',
        color: 'rgb(221, 229, 153)'
      },
      'predictions': {
        name: 'Predictions',
        color: 'rgb(238, 238, 0)'
      },
      'voting': {
        name: 'Voting',
        color: 'rgb(246, 238, 195)'
      },
      'opinion': {
        name: 'Opinion',
        color: 'rgb(246, 195, 178)'
      },
      'support': {
        name: 'Support',
        color: '#fed4dd'
      },
    };
    
    let filteredSubtitle = this.props.sentence.text.replace(/&apos;/g, '’');
    filteredSubtitle = filteredSubtitle.replace(/Â£/g, '£');

      return (
        <div className={classes.sentenceContainer}>
        {isFactchecker && hasResults ? (
            <div className={classes.sentenceMatches}>
                <LiveMatch
                  matches={matches}
                  color={color}
                  sentence={this.props.sentence}
                  channel={this.props.sentence.canonical_channel_name || ""}
                  isFactchecker={true}
                />
            </div>
          ) : ""}
          <div
            className={classes.sentenceContent}
            key={this.props.sentence.id}>
              <span className={classes.transcriptButton}>
                <a onClick={this.createTranscript}><LowPriorityIcon /></a>
              </span>
              <span className={classes.time}>
                {moment.utc(this.props.sentence.broadcast_time).local().format('HH:mm:ss')}
                <div className={classes.sentenceChip}>
                {type && claimTypes[type] && <Chip size="small" label={claimTypes[type].name} style={{backgroundColor: claimTypes[type].color}} />}
                </div>
              </span>
              <span className={sentenceClass} style={sentenceStyle}>
                <span className={classes.innerSentence}>
                  {filteredSubtitle}
                </span>
              </span>
            </div>
          </div>
        );
      }
}

LiveChannelSentence.propTypes = {
  results: PropTypes.object,
  sentence: PropTypes.object.isRequired,
  createTranscript: PropTypes.func,
  display: PropTypes.string,
  showClaimDetection:  PropTypes.bool,
  isFactchecker: PropTypes.bool,
  i18n: PropTypes.object,
  classes: PropTypes.object
};

export default compose(
  withStyles(() => ({
    transcriptButton: {
      display: 'inline-block',
      position: 'relative',
      color: '#999',
      cursor: 'pointer',
      '&:hover': {
        color: '#000'
      },
      ['@media (min-width: 1000px)']: {
        gridColumn: '2',
        gridRow: '1',
        marginRight: '12px',
        marginTop: '2px'
      }
    },
    time: {
      display: 'inline-block',
      color: '#999',
      verticalAlign: 'top',
      marginTop: '3px',
      marginLeft: '6px',
      fontSize: '14px',
      ['@media (min-width: 1000px)']: {
        gridColumn: '1',
        gridRow: '1',
        marginTop: '6px',
        marginRight: '12px'
      }
    },
    sentenceContainer: {
      display: 'grid',
      ['@media (min-width: 1000px)']: {
        display: 'block'
      }
    },
    sentenceContent: {
      display: 'inline-block',
      gridColumn: '1',
      gridRow: '1',
      ['@media (min-width: 1000px)']: {
        width: '60%',
        padding: '12px 0',
        display: 'grid',
        gridTemplateColumns: 'min-content min-content auto',
        gridRow: 'auto'
      }
    },
    sentenceMatches: {
      padding: '12px 0',
      gridColumn: '1',
      gridRow: '2',
      ['@media (min-width: 1000px)']: {
        gridColumn: 'auto',
        gridRow: 'auto',
        float: 'right',
        clear: 'right',
        width: '38%'
      }
    },
    sentence: {
      fontSize: '19px',
      fontWeight: '300',
      lineHeight: '1.5',
      margin: '12px 0',
      display: 'block',
      ['@media (min-width: 1000px)']: {
        gridColumn: '3',
        gridRow: '1',
        margin: '0'
      }
    },
    sentenceChip: {
      minWidth: '80px',
      marginTop: '4px'
    },
    innerSentence: {
      color: 'initial',
      textDecoration: 'none'
    },
    underline: {
      textDecoration: 'underline'
    }
  })),
  withTranslation()
)(LiveChannelSentence);
