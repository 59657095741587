export function getNoticeById(state, id) {
  if(state.noticeState.notices) {
    let notice = state.noticeState.notices[id];
    if (notice && (notice.message || notice.messageKey)) {
      return notice;
    }
  } 
  return null;
}

export function getNotices(state) {
  return state.noticeState.notices;
}

export default ({
  getNoticeById: getNoticeById,
  getNotices: getNotices
});