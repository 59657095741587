/*eslint-disable no-unused-vars*/
import { Client, Message } from 'paho.mqtt.js';
import config from '../../config';
import * as utils from '../../utils';

class MQTTFeed {

  constructor(url, channel, callback){
    this.client = {
      disconnect: function(){}
    };
    this.callback = callback;
    // The full URL of the server
    this.url = url;
    // Since tuning takes 10ms or so, we need to make sure we don't attempt to double-tune,
    // otherwise we'll end up with double subtitles
    this.mutex = {blocked: false};
    this.clientId = utils.generateId();
    if(channel) this.tune(channel);
    this.onMessageArrived.bind(this);
    this.onConnect.bind(this);
    this.onFailure.bind(this);
  }

  tune(channel) {
    if (this.mutex.blocked) return;
    this.mutex.blocked = true;
    /*
    Move to this channel
    */
    // Clear up old client if switching channels

    //console.log("[MQTT] Disconnecting " + this.clientId);
    if(this.client) this.client.disconnect();

    let user = JSON.parse(localStorage.getItem('user'));
    if(!user) return;
    let password = user.accessToken;

    // Store the global channel name
    this.tuned_channel = channel;
    this.client = new Client(this.url, this.clientId);
    this.client.onConnectionLost = this.onConnectionLost;
    this.client.onMessageArrived = this.onMessageArrived();

    this.client.connect({
      userName: 'user',
      password: password,
      useSSL: true,
      onSuccess: this.onConnect(),
      onFailure: this.onFailure()
    });
  }

  onConnect() {
    return () => {
      if(Array.isArray(this.tuned_channel)) {
        this.tuned_channel.forEach(cha => {
          this.client.subscribe(cha);
        });
      } else {
        this.client.subscribe(this.tuned_channel);
      }
      this.mutex.blocked = false;
    };
  }

  onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      //console.log("[MQTT] onConnectionLost:" + responseObject.errorMessage);
    }
  }

  onFailure() {
    return (errorObj) => {
      if(errorObj.errorCode === 8 || errorObj.errorCode === 7) {
        let user = JSON.parse(localStorage.getItem('user'));
        if(!user) return;
        let password = user.accessToken;
        this.client.connect({
          userName: 'user',
          password: password,
          useSSL: true,
          onSuccess: this.onConnect(),
          onFailure: this.onFailure()
        });
      }
    };
  }

  onMessageArrived() {
    return (message) => {
      this.callback(message.payloadString);
    };
  }
}

export default MQTTFeed;
