import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'I18n/translations/en.json';
import ar from 'I18n/translations/ar.json';
import fr from 'I18n/translations/fr.json';
i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'en',
    resources: {
      'en': {
        translation: en
      },
      'fr': {
        translation: fr
      },
      'ar': {
        translation: ar
      }
    },
    keySeparator: false,
    react: {
      wait: true,
      defaultTransParent: true
    }
  });


export default i18n;