import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import CheckworthyTopicsTopTen from 'Components/Checkworthy/CheckworthyTopicsTopTen';
import Button from '@material-ui/core/Button';

export function CheckworthyTopicList(props) {
  const {
    classes,
    t,
    currentTopic,
    dismissSighting,
    removeReviewedSighting,
    confirmSighting,
    checkworthyArticles,
    topicDisplayName,
    isGettingTopics
  } = props;

  const [showMore, setShowMore] = React.useState(false);

  function toggleShowMore(){
    setShowMore(!showMore);
  }

  const numOfSentences = showMore ? 20 : 10;
  checkworthyArticles.articles = [
    ...checkworthyArticles.articles.slice(0, numOfSentences)
  ]

  return (
    <div>
        <div className={classes.topTen}>
        <CheckworthyTopicsTopTen
            checkworthyArticles={checkworthyArticles}
          starredSentences={[]}
          topicCode={currentTopic}
          topicDisplayName={topicDisplayName}
            dismissSighting={dismissSighting}
            removeReviewedSighting={removeReviewedSighting}
            confirmSighting={confirmSighting}
            isGettingTopics={isGettingTopics}
        />
        </div>
        {checkworthyArticles.articles.length > 10 && !showMore && (
        <Button
            className={classes.showmore}
            variant="contained"
            color="primary"
            onClick={toggleShowMore}>
          {t('candidates_manager.saved_search_show_more')}
        </Button>
        )}
    </div>
  );

}

CheckworthyTopicList.propTypes = {
  dismissSighting: PropTypes.func,
  removeReviewedSighting: PropTypes.func,
  confirmSighting: PropTypes.func,
  yesterday: PropTypes.string,
  classes: PropTypes.object,
  checkworthyArticles: PropTypes.object,
  t: PropTypes.func,
  currentTopic: PropTypes.string,
  topicDisplayName: PropTypes.string,
  isGettingTopics: PropTypes.bool
};

export default compose(
  withTranslation(),
  withStyles(() => ({
    topTen: {
        marginTop: '12px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '3px',
        background: '#fff',
        padding: '0 32px'
    },
    showmore: {
        margin: '12px 0 32px 0'
    }
  }
)))(CheckworthyTopicList);
