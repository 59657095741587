import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Redirect } from 'react-router-dom';
import privateRoutes from 'Config/privateRoutes';
import authSelectors from 'Selectors/authSelectors';

export class RootPage extends React.Component {

  constructor(props){
    super(props);

    let redirect = '';
    if(props.user && props.user.role) {
      const urlParams = new URLSearchParams(props.history.location.search);
      const nextPath = urlParams.get('next');
      if(nextPath && nextPath.length) {
        redirect = decodeURIComponent(nextPath);

      } else {
        let routes = privateRoutes.filter(route => route.name === props.homePage);
        if(routes.length === 1) {
          redirect = routes[0].path;
        }
      }
    }
    this.state = {
      redirect: redirect
    };
  }


  render() {
    const { redirect } = this.state;

    if (redirect !== '') {
       return <Redirect to={redirect} />;
    }
    return null;
  }

}

RootPage.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  homePage: PropTypes.string
};

function mapStateToProps(state) {
  return {
    user: authSelectors.getUser(state),
    homePage: authSelectors.getHomePage(state)
  };
}

export default connect(
  mapStateToProps,
  null
)(RootPage);
