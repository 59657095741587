const actionStatuses =  {
    not_started: 'action_statuses.not_started',
    awaiting_response: 'action_statuses.awaiting_response',
    awaiting_response_chased: 'action_statuses.awaiting_response_chased',
    response_received: 'action_statuses.response_received',
    further_follow_up: 'action_statuses.further_follow_up',
    holding_reply_received: 'action_statuses.holding_reply_received',
    closed: 'action_statuses.closed_resolved',
    closed_unresolved: 'action_statuses.closed_unresolved',
    closed_partially: 'action_statuses.closed_partially'
  };
  
  export default actionStatuses;
  
