const channel = {
    'bbc1': {
        image: '/assets/images/channels/bbc1.png',
        name: 'BBC One'
    },
    'bbc2': {
        image: '/assets/images/channels/bbc2.png',
        name: 'BBC Two'
    },
    'bbcnews': {
        image: '/assets/images/channels/bbcnews.png',
        name: 'BBC News'
    },
    'bbcparl': {
        image: '/assets/images/channels/bbcparl.png',
        name: 'BBC Parliament'
    },
    'bbc1sco': {
        image: '/assets/images/channels/bbc1sco.png',
        name: 'BBC One Scotland'
    },
    'bbc1wales': {
        image: '/assets/images/channels/bbc1wales.png',
        name: 'BBC One Wales'
    },
};

export default channel;
