import React from 'react';
import PropTypes from 'prop-types';
import SearchField from './SearchField';
import SearchResults from './SearchResults';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import SearchTrendingCard from 'Components/Search/SearchTrendingCard';
import SearchSavedSearches from 'Components/Search/SearchSavedSearches';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next';
import { compose } from 'redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

export function SearchManagerLayout(props) {
  const {
    candidates,
    candidatesCount,
    claimants,
    classes,
    claimTypes,
    claimantGroups,
    hasMoreCandidates,
    mediaCategories,
    publications,
    trendingClaimants,
    showSavedSearches,
    showTrendingClaimants,
    handleClaimantSearch,
    getMoreCandidates,
    handleFilterSearch,
    handleTrendingClaimantClick,
    onKeywordClick,
    formFilters,
    showAdvancedSearch,
    handleEditSavedSearchClick,
    handleAdvancedSearchToggle,
    formSearchText,
    handleTextSearch,
    t,
    setFormFilters,
    setFormSearchText,
    searchText,
    filters,
    setShowSavedSearches,
    setShowTrendingClaimants,
    suggestedSearches,
    candidatesRequestState,
    publicationsRequestState,
    i18n,
  } = props;

  const showSavedSearchesClass = showSavedSearches ? ' ' + classes.showing: '';
  const showTrendingClaimantsClass = showTrendingClaimants ? ' ' + classes.showing: '';

  return (
    <div className={classes.candidates}>
      <div className={classes.grid}>
        <div className={classes.candidatesSearch}>
          <SearchField
            fieldsData={formFilters}
            searchText={formSearchText}
            onTextSearch={handleTextSearch}
            onFilterSearch={handleFilterSearch}
            onFiltersChange={setFormFilters}
            onSearchTextChange={setFormSearchText}
            publications={publications}
            types={claimTypes}
            categories={mediaCategories}
            claimants={claimants}
            onAdvancedSearchToggle={handleAdvancedSearchToggle}
            forceOpenAdvancedSearch={showAdvancedSearch}
            handleClaimantSearch={handleClaimantSearch}
            claimantGroups={claimantGroups}
            />
        </div>
        <div className={classes.secondaryContent}>
          <div className={classes.secondaryContentLinks}>
              <span className={classes.secondaryContentLink} onClick={() => {
                setShowSavedSearches(!showSavedSearches);
              }}>{t('candidates_manager.saved_searches')}</span>
      
              <span className={classes.secondaryContentLink} onClick={() => {
                setShowTrendingClaimants(!showTrendingClaimants);
              }}>{t('candidates_manager.trending_claimants')}</span>
          </div> 
          <div>
              <div className={classes.savedSearches+showSavedSearchesClass}>
                <SearchSavedSearches
                  searchText={searchText}
                  onEditSavedSearchClick={handleEditSavedSearchClick}
                  onSavedSearchClick={onKeywordClick}
                  suggestedSearches={suggestedSearches}
                />
              </div>
              {publications.length !== 0 && ( 
                <div className={classes.trendingClaimants+showTrendingClaimantsClass}>
                  <SearchTrendingCard
                    title={t('candidates_manager.trending_claimants')}
                    icon={<ChatBubbleIcon color="primary"/>}
                    noResultsText={t('candidates_manager.no_claimants_found')}
                    keywords={trendingClaimants}
                    onKeywordClick={handleTrendingClaimantClick}
                    showListOrder={true}
                  />
                </div>
              )}
          </div>
        </div>
        <div className={classes.mainContent}>
          {candidatesRequestState === 'pending' || publicationsRequestState === 'pending' ? (
            <section>
              <CircularProgress />
            </section>
          ) : (
            <section>
              {publications.length === 0 ? (
                <div className={classes.defaultResultsText}>
                  <Trans i18nKey="candidates_manager.media_required" parent="div">
                    Candidates search requires an organisation to have at least one media source defined. Please check your <Link className={classes.pubLink} to="/media">publications</Link>.
                  </Trans>
                </div>
              ) : (
                <section>
                  <section>
                  {candidatesRequestState === 'success' && candidates?.length > 0 && (
                    <div>
                      <div>
                      <SearchResults
                        candidates={candidates}
                        candidatesCount={candidatesCount}
                        hasMoreCandidates={hasMoreCandidates}
                        getMoreCandidates={getMoreCandidates}
                      />
                      </div>
                    </div>
                  )}
                  {candidatesRequestState === 'success' && candidates?.length === 0 && (
                    <div>
                      {t('candidates_manager.no_candidates', { 
                        startDate: moment(filters.startDate).format('DD/MM/YYYY'), 
                        endDate:  moment(filters.endDate).format('DD/MM/YYYY'),
                        interpolation: { escapeValue: false }
                      })}
                    </div>
                  )}
                  </section>
                </section>
              )}
            </section>
          )}
        </div>
      </div>
    </div>
  );
}

export default compose(
  withTranslation(),
  withStyles((theme) => ({
    defaultResultsText: {
      marginBottom: '24px',
      border: '1px solid #ccc',
      padding: '15px',
      borderRadius: '5px',
      lineHeight: '1.4'
    },
    candidates: {
      maxWidth: '1680px',
      padding: '20px 30px 0 30px'
    },
    icon: {
      verticalAlign: 'middle',
      marginRight: '10px'
    },
    savedSearches: {
      display: 'none'
    },
    trendingClaimants : {
      display : 'none'
    },
    showing: {
      display: 'block'
    },
    secondaryContentLink: {
      display: 'inline-block',
      cursor: 'pointer',
      color: theme.palette.primary[500],
      margin: '12px 18px 0 0'
    },
    secondaryContentLinks: {
      marginBottom: '12px'
    },
    pubLink: {
      color: theme.palette.primary[500]
    },
    '@media screen and (min-width: 800px)': {
      grid: {
        display: 'grid',
        gridTemplateColumns: 'auto 300px',
        gridTemplateRows: '50px auto',
        gridGap: '30px'
      },
      showing: {
        display: 'none'
      },
      candidates: {
        marginTop: '30px'
      },
      candidatesSearch: {
        gridColumn: '1',
        gridRow: '1'
      },
      mainContent: {
        gridColumn: '1',
        gridRow: '2',
        alignSelf: 'start'
      },
      secondaryContent: {
        gridColumn: '2',
        gridRow: '1 / span 2'
      },
      secondaryContentLinks: {
        display: 'none'
      },
      savedSearches: {
        display: 'block',
        marginBottom: '30px'
      },
      trendingClaimants : {
        display : 'block',
        marginBottom: '30px'
      }
    }
  }))
)(SearchManagerLayout);

SearchManagerLayout.propTypes = {
  candidates: PropTypes.array,
  candidatesCount: PropTypes.number,
  candidatesRequestState: PropTypes.string,
  claimants: PropTypes.array,
  claimantGroups: PropTypes.array,
  claimTypes: PropTypes.array,
  classes: PropTypes.object,
  getMoreCandidates: PropTypes.func,
  getClaimantsAutoComplete: PropTypes.func,
  handleSearchFromAdvancedForm: PropTypes.func,
  handleSearchFromUserInput: PropTypes.func,
  hasMoreCandidates: PropTypes.bool,
  initialFilters: PropTypes.object,
  mediaCategories: PropTypes.array,
  publications: PropTypes.array,
  publicationsRequestState: PropTypes.string,
  trendingClaimants: PropTypes.array,
  hasQuery: PropTypes.bool,
  t: PropTypes.func,
  showSavedSearches: PropTypes.bool,
  handleClaimantSearch: PropTypes.func,
  handleFilterSearch: PropTypes.func,
  handleTrendingClaimantClick: PropTypes.func,
  onKeywordClick: PropTypes.func,
  formFilters: PropTypes.object,
  showAdvancedSearch: PropTypes.bool,
  handleEditSavedSearchClick: PropTypes.func,
  handleAdvancedSearchToggle: PropTypes.func,
  formSearchText: PropTypes.string,
  handleTextSearch: PropTypes.func,
  setFormFilters: PropTypes.func,
  setFormSearchText: PropTypes.func,
  searchText: PropTypes.string,
  filters: PropTypes.object,
  setShowSavedSearches: PropTypes.func,
  suggestedSearches: PropTypes.array,
  showTrendingClaimants: PropTypes.bool,
  setShowTrendingClaimants: PropTypes.func,
  i18n: PropTypes.object
};