const analytics = {
    account: 'UA-15705567-6',
    ga4account: 'G-JVW86FQXG6',
    events: {
      CREATE_INTERVENTION:  {
          category: 'Trends',
          action: 'Created Intervention'
      },
      DELETE_INTERVENTION: {
          category: 'Trends',
          action: 'Deleted Intervention'
      },
      CREATE_CLAIM: {
          category: 'Trends',
          action: 'Create Claim'
      },
      UPDATE_CLAIM: {
          category: 'Trends',
          action: 'Update Claim'
      },
      STAR_CLAIM: {
          category: 'Trends',
          action: 'Star Claim'
      },
      UNSTAR_CLAIM: {
          category: 'Trends',
          action: 'Unstar Claim'
      },
      DELETE_CLAIM: {
          category: 'Trends',
          action: 'Delete Claim'
      },
      HIGHLIGHT_SENTENCE: {
          category: 'Live',
          action: 'Highlighted Transcript Sentence'
      },
      UNHIGHLIGHT_SENTENCE: {
          category: 'Live',
          action: 'Unhighlighted Transcript Sentence'
      },
      CHANGE_NAME: {
          category: 'Live',
          action: 'Changed Transcript Name'
      },
      GET_CHANNEL_TRANSCRIPT: {
        category: 'Live',
        action: 'Get Channel Transcript'
      },
      GET_RADIO_TRANSCRIPT: {
        category: 'Live',
        action: 'Get Radio Transcript'
      },
      GET_ADHOC_TRANSCRIPT: {
        category: 'Live',
        action: 'Get Adhoc Transcript'
      },
      GET_YOUTUBE_TRANSCRIPT: {
        category: 'Live',
        action: 'Get Youtube Transcript'
      },
      GET_BLOCK_TRANSCRIPT: {
        category: 'Live',
        action: 'Get Block Transcript'
      },
      LOGIN_SUCCESS: {
          category: 'Auth',
          action: 'Login Success'
      },
      LOGIN_FAILURE: {
          category: 'Auth',
          action: 'Login Failure'
      },
      TOKEN_REFRESHED: {
          category: 'Auth',
          action: 'Token Refreshed'
      },
      UNAUTHORISED_ACTION: {
          category: 'Auth',
          action: 'Unauthorised'
      },
      LOGOUT: {
          category: 'Auth',
          action: 'Logout Success'
      },
      CHANGE_PASSWORD: {
          category: 'Auth',
          action: 'Change Password'
      },
      LONG_CANDIDATES_URL: {
          category: 'Candidates',
          action: 'URL TOO LONG'
      },
      GET_CANDIDATES_INITIAL_URL_LOAD: {
        category: 'Candidates',
        action: 'Load Candidates from URL',
        label: 'query'
      },
      GET_CANDIDATES_FROM_SEARCH_BOX: {
          category: 'Candidates',
          action: 'Load Candidates from Search Box',
          label: 'query'
      },
      GET_CANDIDATES_FROM_ADVANCED_FILTERS: {
          category: 'Candidates',
          action: 'Load Candidates from Advanced Filters',
          label: 'query'
      },
      CREATE_BLOCK_TRANSCRIPT: {  
        category: 'Live',
        action: 'Create Block Transcript'
      },
      CREATE_CHANNEL_TRANSCRIPT: {  
        category: 'Live',
        action: 'Create Channel Transcript'
      },
      CREATE_YOUTUBE_TRANSCRIPT: {  
        category: 'Live',
        action: 'Create Youtube Transcript'
      },
      DELETE_BLOCK_TRANSCRIPT: {
        category: 'Live',
        action: 'Delete Transcript'
      },
      DELETE_CHANNEL_TRANSCRIPT: {
        category: 'Live',
        action: 'Delete Transcript'
      },
      DELETE_ADHOC_TRANSCRIPT: {
        category: 'Live',
        action: 'Delete Transcript'
      },
      DELETE_YOUTUBE_TRANSCRIPT: {
        category: 'Live',
        action: 'Delete Transcript'
      },
      STOP_CHANNEL_TRANSCRIPT: {
        category: 'Live',
        action: 'Stop Transcript'
      },
      STOP_YOUTUBE_TRANSCRIPT: {
        category: 'Live',
        action: 'Stop Transcript'
      },
      UPDATE_BLOCK_TRANSCRIPT_NAME: {
        category: 'Live',
        action: 'Update Transcript Name'
      },
      UPDATE_CHANNEL_TRANSCRIPT_NAME: {
        category: 'Live',
        action: 'Update Transcript Name'
      },
      UPDATE_YOUTUBE_TRANSCRIPT_NAME: {
        category: 'Live',
        action: 'Update Transcript Name'
      },
      CREATE_RADIO_TRANSCRIPT_SCHEDULE: {
        category: 'Live',
        action: 'Create Schedule'
      },
      DELETE_TRANSCRIPT_SCHEDULE: {
        category: 'Live',
        action: 'Delete Schedule'
      },
      UPDATE_TRANSCRIPT_SCHEDULE: {
        category: 'Live',
        action: 'Update Schedule'
      },
      ADD_MATCHED_SIGHTING: {
        category: 'Alerts',
        action: 'Confirm Alerts Sighting'
      },
      DISMISS_SIGHTING: {
        category: 'Alerts',
        action: 'Dismiss Alerts Sighting'
      },
      CANDIDATES_GET_DIGEST: {
        category: 'Alerts',
        action: 'Get Alerts Sightings'
      },
      CONFIRM_CHECKWORTHY_SIGHTING: {
        category: 'Checkworthy',
        action: 'Confirm Checkworthy Sighting'
      },
      DISMISS_CHECKWORTHY_SIGHTING: {
        category: 'Checkworthy',
        action: 'Dismiss Checkworthy Sighting'
      },
      CANDIDATES_GET_TOPICS: {
        category: 'Checkworthy',
        action: 'Get Checkworthy Topics'
      },
      CREATE_USER: {
        category: 'Admin',
        action: 'Create User'
      },
      DELETE_USER: {
        category: 'Admin',
        action: 'Delete User'
      },
      RESEND_VERIFY_USER: {
        category: 'Admin',
        action: 'Resent User Invite'
      },
      CREATE_ORG_MEDIA: {
        category: 'Admin',
        action: 'Create Media'
      },
      UPDATE_ORG_MEDIA: {
        category: 'Admin',
        action: 'Update Media'
      },
      DELETE_ORG_MEDIA: {
        category: 'Admin',
        action: 'Delete Media'
      },
    }
};

export default analytics;