import interventionConstants from 'Constants/interventionConstants';

export function createIntervention(intervention) {
  return {
    type: interventionConstants.CREATE_INTERVENTION,
    intervention: intervention
  };
}

export function updateIntervention(interventionId, intervention) {
  return {
    type: interventionConstants.UPDATE_INTERVENTION,
    interventionId: interventionId,
    intervention: intervention
  };
}

export function deleteIntervention(interventionId) {
  return {
    type: interventionConstants.DELETE_INTERVENTION,
    id: interventionId
  };
}

export function getIntervention(interventionId) {
  return {
    type: interventionConstants.GET_INTERVENTION,
    id: interventionId
  };
}


export function loadInterventions() {
  return {
    type: interventionConstants.GET_INTERVENTIONS
  };
}

export function loadInterventionsByClaimId(claimId) {
  return {
    type: interventionConstants.GET_INTERVENTIONS_BY_CLAIM_ID,
    id: claimId
  };
}

export function startEditingIntervention() {
  return {
    type: interventionConstants.START_EDITING_INTERVENTION
  };
}

export function stopEditingIntervention() {
  return {
    type: interventionConstants.STOP_EDITING_INTERVENTION
  };
}

export function setInterventionsOrderBy(order) {
  return {
    type: interventionConstants.SET_INTERVENTIONS_ORDER,
    order: order
  };
}

export function setInterventionsSortBy(sort) {
  return {
    type: interventionConstants.SET_INTERVENTIONS_SORT,
    sort: sort
  };
}

export function changeActionsFilter(){
  return {
    type: interventionConstants.CHANGE_ACTIONS_FILTERS
  };
}

export function longActionsUrl(){
  return {
    type: interventionConstants.LONG_ACTIONS_URL
  };
}

export default {
  createIntervention: createIntervention,
  deleteIntervention: deleteIntervention,
  loadInterventions: loadInterventions,
  loadInterventionsByClaimId: loadInterventionsByClaimId,
  updateIntervention: updateIntervention,
  startEditingIntervention: startEditingIntervention,
  stopEditingIntervention: stopEditingIntervention,
  setInterventionsOrderBy: setInterventionsOrderBy,
  setInterventionsSortBy: setInterventionsSortBy,
  changeActionsFilter: changeActionsFilter,
  longActionsUrl: longActionsUrl,
  getIntervention: getIntervention
};

