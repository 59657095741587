import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

export class ClaimForm extends Component {

  constructor(props){
    super(props);

    let claim = props.initialClaim || {};

    this.state = {
      claim: {
        claim: claim.claim || '',
        conclusion: claim.conclusion || '',
        factcheckUrl: claim.factcheckUrl || '',
        trackedClaimId: claim.trackedClaimId || null,
        externalClaimId: claim.externalClaimId || null
      } 
    };

  }

  setValue = (e) => {
    this.setState({claim: {
        ...this.state.claim, [e.target.name]: e.target.value
    }});
  }

  handleSaveClaim = (e) => {
    e.preventDefault();
    this.props.handleSaveClaim(this.state.claim);
  }

  render(){
    const { initialClaim, t, classes } = this.props;
    const { claim } = this.state;

    return (
      <form id="create-claim-form"  role="form" onSubmit={this.handleSaveClaim}>
        <TextField
          fullWidth={true}
          label={t('claim_form.field_claim_label')}
          margin="normal"
          multiline={true}
          name="claim"
          onChange={this.setValue}
          row={2}
          type="text"
          value={claim.claim}
          required={true}
        />
        <TextField
          fullWidth={true}
          label={t('claim_form.field_conclusion_label')}
          margin="normal"
          multiline={true}
          name="conclusion"
          onChange={this.setValue}
          row={2}
          type="text"
          value={claim.conclusion}
        />
        <TextField
          fullWidth={true}
          label={t('claim_form.factcheck_url_label')}
          margin="normal"
          multiline={true}
          name="factcheckUrl"
          onChange={this.setValue}
          row={2}
          type="text"
          value={claim.factcheckUrl}
        />
        <div className={classes.save}>
          <Button role="button" type="submit" color="primary" variant="contained">
            {initialClaim ? t('claim_form.update_button') : t('claim_form.add_button')}
          </Button>
        </div>
      </form>
    );
  }
}

ClaimForm.propTypes = {
  handleSaveClaim: PropTypes.func,
  initialClaim: PropTypes.object,
  classes: PropTypes.object,
  t: PropTypes.func
};


export default compose(
  withTranslation(),
  withStyles((theme) => ({
    button : {
      display: 'inline-block'
    },
    close: {
      float: 'right',
      cursor: 'pointer',
      lineHeight: '3.5'
    },
    save: {
      marginTop: '12px',
      marginBottom: '24px'
    },
    checkField: {
      flex: 1
    },
    notice: {
      marginBottom: '12px'
    },
    checkButton: {
      marginTop: '12px',
      width: '70px',
      marginLeft: '6px',
      verticalAlign: 'bottom'
    },
    fieldTextValue: {
      marginTop: '24px',
      display: 'flex',
      width: '100%',
      paddingTop: '24px'
    }
})))(ClaimForm);


