const candidateConstants = {
    CANDIDATES_GET: 'GET_CANDIDATES',
    CANDIDATES_GET_DIGEST: 'GET_DIGEST_CANDIDATES',
    CANDIDATES_GET_MORE: 'GET_MORE_CANDIDATES',
    CANDIDATES_GET_FROM_URL: 'GET_CANDIDATES_INITIAL_URL_LOAD',
    CANDIDATES_GET_FROM_SEARCH_BOX: 'GET_CANDIDATES_FROM_SEARCH_BOX',
    CANDIDATES_GET_FROM_ADVANCED: 'GET_CANDIDATES_FROM_ADVANCED_FILTERS',
    CANDIDATES_SET_FILTERS: 'CHANGE_CANDIDATES_FILTERS',
    CANDIDATES_LONG_URL: 'LONG_CANDIDATES_URL',
    APP_SET_EXPERIMENT: 'SET_EXPERIMENT',
    CLAIMANTS_GET_AUTOCOMPLETE: 'CLAIMANTS_GET_AUTOCOMPLETE',
    CLAIMANTS_GET_TRENDING: 'GET_TRENDING_CLAIMANTS',
    GET_PUBLICATIONS: 'GET_PUBLICATIONS',
    CANDIDATES_GET_TOPICS: 'CANDIDATES_GET_TOPICS',
    CANDIDATES_GET_SOCIAL_MEDIA: 'CANDIDATES_GET_SOCIAL_MEDIA',
    CANDIDATES_GET_TOPIC_SUMMARIES: 'CANDIDATES_GET_TOPIC_SUMMARIES',
    DISMISS_CHECKWORTHY_SIGHTING: 'DISMISS_CHECKWORTHY_SIGHTING',
    DELETE_CHECKWORTHY_SIGHTING: 'DELETE_CHECKWORTHY_SIGHTING',
    CONFIRM_CHECKWORTHY_SIGHTING: 'CONFIRM_CHECKWORTHY_SIGHTING'
  };
  
  export default candidateConstants;