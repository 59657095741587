import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import EnhancedTableDateRange from 'Components/Common/EnhancedTableDateRange';
import ActionTableFilters from 'Components/Action/ActionTableFilters';
import EnhancedTableSearch from 'Components/Common/EnhancedTableSearch';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import moment from 'moment';

export function ActionControlPanel(props) {

  const {
    classes,
    resultsCount,
    handleCreateAction,
    t,
    applyFilter,
    initialFilters,
    resultsCSV
  } = props;

  const defaultFilters = initialFilters || {
    cases: [],
    from: null,
    text: '',
    until: null
  };
  const [filterText, setFilterText] = React.useState(defaultFilters.text);
  const [filters, setFilters] = React.useState(defaultFilters.cases);
  const [fromDate, setFromDate] = React.useState(defaultFilters.from);
  const [untilDate, setUntilDate] = React.useState(defaultFilters.until);

  const result = resultsCount || 0;
  function handleFilterTextChange(filterText){
    setFilterText(filterText);
    props.handleFilterTextChange(filterText);
  }

  function handleDatesChange(dates){
    setFromDate(dates.startDate);
    setUntilDate(dates.endDate);
    props.handleDatesChange(dates);
  }

  return (
    <div className={classes.controlPanel}>
      <span className={classes.statsLink}>
        <Link to={"/actions/stats"} >{t('action_control_panel.statistics')}
        <InsertChartIcon /></Link>
      </span>
    {props.filterableFields && props.filterableFields.length !== 0 && (
      <div className={classes.tableFilters}>
        <ActionTableFilters
          filterableFields={props.filterableFields}
          applyFilter={applyFilter}
          initialFilters={filters}
        />
      </div>
    )}
      <div className={classes.createButton}>
        <Fab color="primary" aria-label="add" size="small" onClick={handleCreateAction}><AddIcon /></Fab>
      </div>

      <div className={classes.textFilter}>
        <EnhancedTableSearch
          filterText={filterText}
          filterTextLabel={t('action_control_panel.filter_by_text')}
          handleFilterTextChange={handleFilterTextChange}
        />
      </div>
      <div className={classes.dateRange}>
        <EnhancedTableDateRange
          startDate={fromDate}
          endDate={untilDate}
          handleDatesChange={handleDatesChange}
        />
      </div>
      <div className={classes.totalResultsCount}>
          {result && resultsCSV ? (
            <a className={classes.showSelectedLink} href={resultsCSV} download={"actions_data_" + moment().format('YYYY-MM-DD_HH-mm-ss')+ ".csv"}><SaveAltIcon /></a>
          ): ''}   {t('action_control_panel.results', { count: result })}

      </div>
    </div>
  );
}


ActionControlPanel.propTypes  = {
  applyFilter: PropTypes.func,
  canCreateItem: PropTypes.bool,
  classes: PropTypes.object,
  dateRangeChange: PropTypes.func,
  filterableFields: PropTypes.array,
  filterableFieldsById: PropTypes.object,
  filtersChange: PropTypes.func,
  filterTextChange: PropTypes.func,
  handleCreateAction: PropTypes.func,
  handleDatesChange: PropTypes.func,
  handleFiltersChange: PropTypes.func,
  handleFilterTextChange: PropTypes.func,
  i18n: PropTypes.object,
  initialFilters: PropTypes.object,
  resultsCount: PropTypes.number,
  t: PropTypes.func,
  resultsCSV: PropTypes.string,
};


export default compose(
  withTranslation(),
  withStyles((theme) => ({
  controlPanel: {
    margin: '0 12px'
  },
  statsLink: {
    marginTop: '26px',
    float: 'right',
    '& a': {
      color: theme.palette.primary[500],
      textDecoration: 'none',
      verticalAlign: 'middle',
    },
    '& svg': {
      verticalAlign: 'middle',
      marginLeft: '6px'
    },
    verticalAlign: 'bottom',
  },
  createButton: {
    verticalAlign: 'bottom',
    marginTop: '12px',
    marginRight: '12px',
    display: 'inline-block'
  },
  textFilter : {
    display: 'inline-block',
    verticalAlign: 'top',
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.primary
    }
  },
  totalResultsCount: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    marginBottom: '6px'
  },
  dateRange: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    marginBottom: '3px',
    boxSizing: 'border-box',
    margin: '0 16px 3px 16px',
    '& .DateInput_input': {
      boxSizing: 'border-box'
    }
  },
  tableFilters: {
    marginTop: '12px',
    float: 'right'
  },
  showSelectedLink: {
    color: '#009688',
    cursor: 'pointer',
    fontSize: '16px',
    verticalAlign: 'middle'
  }
})))(ActionControlPanel);
