import React from 'react';
import PropTypes from 'prop-types';

import LiveChannelLink from 'Components/Live/LiveChannelLink';
import { withStyles } from '@material-ui/core/styles';

const LiveChannelDetails = React.memo(function MyComponent(props) {
  const {
    classes,
    display,
    dateTime,
    code
  } = props;
  return (
      <div className={classes.details}>
        <div className={classes.avatar}>
          <LiveChannelLink
            code={code}
            display={display}
            hideWithoutImage={true}
          />
        </div>
        <div className={classes.nameAndDate}>
          <h3 className={classes.name}>{display}</h3>
          <div className={classes.startDate}>{dateTime}</div>
        </div>
      </div>
    );
});

LiveChannelDetails.propTypes = {
    display: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    dateTime: PropTypes.string.isRequired,
    classes: PropTypes.object
};

export default withStyles((theme) => ({
  details: {
    padding: '24px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid ' + theme.palette.divider,
    borderWidth: '1px 0',
    marginTop: '0',
    marginBottom: '12px',
    marginLeft: '-12px',
    marginRight: '-12px',
    '& label': {
      display: 'inline-block',
      marginLeft: '12px',
      verticalAlign: 'top'
    }
  },
  '@media (min-width:960px)': {
    details: {
      '& form' : {
        width: '640px'
      }
    }
  },
  startDate: {
    display: 'inline-block',
    fontSize: '15px',
    marginTop: '0',
    marginBottom: '0',
    marginLeft: '6px',
    color: theme.palette.text.secondary
  },
  name: {
    margin: '12px 12px 6px 0',
    fontSize: '34px',
    fontWeight: '400',
    lineHeight: '34px',
    color: theme.palette.text.primary
  },
  nameAndDate: {
    display: 'inline-block'
  },
  avatar: {
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '8px 24px 8px 0',
    '& img': {
      width: '56px',
      height: '56px'
    },
    ['@media (min-width: 960px)']: {
      marginLeft: '24px'
    }
  }
}))(LiveChannelDetails);
