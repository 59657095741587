import React from 'react';
import PropTypes from 'prop-types';
import { default as OutboundLink } from '@material-ui/core/Link';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import LiveMatchRoboChart from './LiveMatchRoboChart';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const LiveMatchStats = (props) => {

  const { t, i18n, classes } = props;
  const {
    short_msg
  } = props.match;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let verdict = props.match.result;
  let formattedMetrics = {};
  let rateDislayNames = {
    'ons_inflation_cpi_rates': 'cpi rates',
    'ons_inflation_cpih_rates': 'cpih rates',
    'ons_inflation_rpi_rates': 'rpi rates'
  };

  let contextKeys = Object.keys(props.match.evidence.context);

  contextKeys.forEach((contextKey) => {
    let raw_series = props.match.evidence.context[contextKey].observations;
    let data = {
      x: [],
      y: [],
      type: "line"
    };
    let metadata = props.match.evidence.context[contextKey];

    raw_series.forEach((seriesData) => {
      data.x.push(seriesData[0].substr(0,4)+'-'+seriesData[0].substr(4,2)+'-'+seriesData[0].substr(6,2)); //date
      data.y.push(seriesData[1]); //value
    });

    let layoutConfig = {
      margin: { l: 40, r: 15, b: 60, t: 30, pad: 0, y: 10 },
      autosize: true,
      showlegend: false,
      barmode: 'relative',
      xaxis : {
        showticklabels: true,
        type: 'date',
        tickformat: '%Y-%m-%d',
        autorange: true
      },
      yaxis: {
        rangemode: 'tozero'
      }
    };

    if(data.x.length <= 6) {
      layoutConfig.xaxis.nticks = data.x.length;
    }

    if(props.match.extracted && props.match.extracted.start_date && props.match.extracted.end_date) {
      let time_from = props.match.extracted.start_date;
      let time_to = props.match.extracted.end_date;
      
      let timeFrom = parseInt(time_from, 10);
      let timeTo = parseInt(time_to, 10);

      let yearNums = [];
      data.x.forEach(year => {
        yearNums.push(parseInt(year, 10));
      });
  
      yearNums = yearNums.sort();

      
      let latestYear;
      let earliestYear = yearNums[0];
      if(yearNums.length > 1) latestYear = yearNums[yearNums.length-1];
      if(earliestYear && latestYear && timeFrom >= earliestYear && timeTo <= latestYear) {
        layoutConfig.shapes = [
            {
                'type': 'rect',
                'xref': 'x',
                'yref': 'paper',
                'x0': time_from,
                'y0': 0,
                'x1': time_to,
                'y1': 1,
                'fillcolor': '#d3d3d3',
                'opacity': 0.2,
                'line': {
                    'width': 0,
                }
            }
          ];
      }
      
      let valueAtFrom = '';
      
      data.x.forEach(year => {
        if(time_to && year === time_to+'') {
          valueAtFrom = year.value;
        }
      });

    }
  
    if(metadata.unit === '%') {
      layoutConfig.yaxis.tickformat=".1%";
      data.y = data.y.map(val => val / 100);
    }


    formattedMetrics[contextKey] = {
      data: {...data},
      layoutConfig: {...layoutConfig},
      metadata: {...metadata}
    };
  });

return(
    <div className={classes.roboMatch}>
      <div className={classes.trendAnalysis}>
        <h3>{short_msg}</h3>
        <div>{t('live_match_stats.field_verdict')} <span>{verdict}</span></div>
      </div>  
      <div className={classes.chartTabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          {contextKeys.map((key) => (
            <Tab key={'tab'+key} label={rateDislayNames[key]} />
          ))}
        </Tabs>
        {contextKeys.map((key, index) => (
          <div key={"tabcontent"+key+index}>
            {value === index && (
              <div>
                  <LiveMatchRoboChart
                    data={[formattedMetrics[key].data]}
                    layout={formattedMetrics[key].layoutConfig}
                  />
                  <div className={classes.sourceDetails}>
                    <OutboundLink
                      href={formattedMetrics[key].metadata.url}
                      target="_blank"
                    >{formattedMetrics[key].metadata.title} ({moment(formattedMetrics[key].metadata.releaseDate).format('YYYY-MM-DD')})</OutboundLink>
                  </div>
                </div>
              )}
          </div>
        ))}
      </div>
    </div>
  );
};

LiveMatchStats.propTypes = {
  match: PropTypes.object.isRequired,
  t: PropTypes.func,
  i18n: PropTypes.object,
  classes: PropTypes.object
};

export default compose(
  withStyles(() => ({
    roboMatch: {
      overflow: 'auto',
      '& dl': {
        display: 'grid',
        '& dt': {
          fontWeight: 'bold',
          gridColumn: '1/2',
          textAlign: 'right',
          padding: '0 12px 0 0'
        },
        '& dd': {
          textAlign: 'left',
          gridColumn: '2/2',
          margin: '0'
        }
      }
    },
    claimContext: {
      marginTop: '12px',
      float: 'right',
      textAlign: 'right',
      maxWidth: '40%',
      lineHeight: '1.5'
    },
    sourceDetails: {
      float: 'right'
    },
    trendAnalysis: {
      float: 'left',
      lineHeight: '1.5',
      '& h3': {
        marginTop: '0'
      }
    },
    chartTabs: {
      clear: 'left',
      '& .MuiTab-root': {
        minWidth: 'auto'
      }
    }
  })),
  withTranslation())(LiveMatchStats);
