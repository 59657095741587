import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Select from 'react-select';
import MuiSelect from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import moment from 'moment';

export function SearchField(props) {

  const {
    classes,
    fieldsData,
    searchText,
    onTextSearch,
    onFilterSearch,
    onFiltersChange,
    onSearchTextChange,
    claimants,
    forceOpenAdvancedSearch,
    onAdvancedSearchToggle,
    handleClaimantSearch,
    possibleClaims,
    t
  } = props;

  const publications = props.publications?.map(pub => ({ value: pub, label: pub})) || [];
  const types = props.types?.map(type => ({ value: type.value, label: t(type.label)})) || [];
  const categories = props.categories?.map(cat => ({ value: cat.value, label: t(cat.label)})) || [];
  const claimantGroups = props.claimantGroups?.map(cat => ({ value: cat.label, label: cat.label})) || [];

  const [showAdvancedSearch, setShowAdvancedSearch] = React.useState(false);

  let claimantAutoCompleteTextTimer;

  if(fieldsData.checkedClaims && fieldsData.checkedClaims.length && possibleClaims && possibleClaims.length) {
    fieldsData.checkedClaims = fieldsData.checkedClaims.map(claim => {
      let matchedPossible = possibleClaims.filter(pclaim => parseInt(pclaim.value,10) === parseInt(claim.value,10));
      if(matchedPossible.length) return matchedPossible[0];
      return claim;
    });
  }
  function handleSearch(e) {
    e.preventDefault(e);
    onTextSearch(searchText) ;
    setShowAdvancedSearch(false);
  }

  function handleSearchTextChange(e){
    onSearchTextChange(e.target.value);
  }

  function handleFilterChange(field, value){
    onFiltersChange({
      ...fieldsData,
      [field]: value
    });
  }

  //convert filters to a query string
  function handleFiltersSubmit(e) {
    e.preventDefault(e);
    onFilterSearch(fieldsData);
    setShowAdvancedSearch(false);
  }

  return (
    <section className={classes.searchContainer}>
      <form onSubmit={(e)=>{ handleSearch(e);}} className={classes.searchBar} aria-label="search box">
        <div className={classes.searchBarInner}>
        {searchText && searchText.length ? (
        <Tooltip title={t('candidates_search.clear_search')}>
        <IconButton aria-label="clear search" className={classes.clearSearchButton} size="small" onClick={() => {
            onSearchTextChange('');
            onTextSearch('') ;
            setShowAdvancedSearch(false);
          }}>
          <CloseIcon />
        </IconButton>
        </Tooltip>
        ): ''}
          <TextField
            className={classes.searchTextField}
            variant="outlined"
            onChange={handleSearchTextChange}
            onFocus={() => {
              setShowAdvancedSearch(false);
            }}
            
            value={searchText}
            placeholder={t('candidates_search.placeholder')}
            InputProps={{
              startAdornment: <IconButton type="submit" size="small" className={classes.searchInnerBoxIcon} onClick={(e) => {
                handleSearch(e);
              }}><SearchIcon /></IconButton>,
              endAdornment: (<Tooltip title={t('candidates_search.tooltip_advanced_search')}>
                <IconButton aria-label="show advanced search" className={classes.advanceToggle} size="small" onClick={() => {
                  let isOpen = (showAdvancedSearch || forceOpenAdvancedSearch);
                    onAdvancedSearchToggle(isOpen);
                    setShowAdvancedSearch(!isOpen);
                  }}>
                  {showAdvancedSearch || forceOpenAdvancedSearch ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
              </Tooltip>)
            }}
          />
        </div>
      </form>
      {showAdvancedSearch || forceOpenAdvancedSearch ? (
      <Card className={classes.formContainer}>
        <CardContent>
        <form onSubmit={(e)=>{ handleFiltersSubmit(e);}} aria-label="advanced search">
          <div className={classes.formControl}>
            <InputLabel  htmlFor="exact-phrase">{t('candidates_search.form.exact_phrase')}</InputLabel>
            <TextField
              type="exact-phrase"
              name="exact-phrase"
              value={fieldsData.exactPhrase || ''}
              onChange={(e)=> {
                handleFilterChange('exactPhrase',  e.target.value);
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.formControl}>
            <InputLabel htmlFor="has-words">{t('candidates_search.form.has_words')}</InputLabel>
            <TextField
              type="has-words"
              name="has-words"
              value={fieldsData.hasWords || ''}
              onChange={(e)=> {
                handleFilterChange('hasWords', e.target.value);
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.formControl}>
            <InputLabel htmlFor="exclude-words">{t('candidates_search.form.exclude_words')}</InputLabel>
            <TextField
              type="exclude-words"
              name="exclude-words"
              value={fieldsData.excludeWords || ''}
              onChange={(e)=> {
                handleFilterChange('excludeWords',  e.target.value);
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.formControl}>
            <InputLabel htmlFor="startDate">{t('candidates_search.form.date')}</InputLabel>
            <MuiSelect
                 value={fieldsData.startDate || ''}
                 onChange={(e)=> {
                   handleFilterChange('startDate',  e.target.value);
                 }}
                 inputProps={{
                   name: 'startDate',
                   id: 'candidatesSearch-startDate'
                 }}
               >
               <MenuItem value={(()=>{ return moment().subtract(1,'days').format('YYYY-MM-DD'); })()}>{t('candidates_search.form.date_choice.24hr')}</MenuItem>
               <MenuItem value={(()=>{ return moment().subtract(2,'days').format('YYYY-MM-DD');})()}>{t('candidates_search.form.date_choice.2days')}</MenuItem>
               <MenuItem value={(()=>{ return moment().subtract(7,'days').format('YYYY-MM-DD');})()}>{t('candidates_search.form.date_choice.week')}</MenuItem>
              </MuiSelect>
          </div>
          <div className={classes.formControl}>
            <InputLabel htmlFor="publications">{t('candidates_search.form.publications')}</InputLabel>
            <Select
              value={fieldsData.publications || ''}
              isMulti
              name="publications"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(values) => {
                handleFilterChange('publications', values);
              }}
              options={publications}
            />
          </div>
          <div className={classes.formControl}>
            <InputLabel htmlFor="types">{t('candidates_search.form.claim_types')}</InputLabel>
            <Select
              value={fieldsData.types || ''}
              isMulti
              name="types"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(values) => {
                handleFilterChange('types', values);
              }}
              options={types}
            />
          </div>
          <div className={classes.formControl}>
            <InputLabel htmlFor="categories">{t('candidates_search.form.categories')}</InputLabel>
            <Select
              value={fieldsData.categories || ''}
              isMulti
              name="categories"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(values) => {
                handleFilterChange('categories',  values);
              }}
              options={categories}
            />
          </div>
          <div className={classes.formControl}>
            <InputLabel htmlFor="claimants">{t('candidates_search.form.claimants')}</InputLabel>
            <Select
              value={fieldsData.claimants || ''}
              isMulti
              name="claimants"
              className="basic-multi-select"
              classNamePrefix="select"
              onInputChange={(values) => {
                clearTimeout(claimantAutoCompleteTextTimer);
                claimantAutoCompleteTextTimer = setTimeout(() => {
                  handleClaimantSearch(values);
                },250); 
              }}
              onChange={(values) => {
                handleFilterChange('claimants',  values);
              }}
              options={claimants}
            />
          </div>
          {claimantGroups.length > 0 &&
            <div className={classes.formControl}>
              <InputLabel htmlFor="claimantGroups">{t('candidates_search.form.claimant_groups')}</InputLabel>
              <Select
                value={fieldsData.claimantGroups || ''}
                isMulti
                name="claimantGroups"
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(values) => {
                  handleFilterChange('claimantGroups',  values);
                }}
                options={claimantGroups}
              />
            </div>
          }
          <Button className={classes.formSubmit} color="primary" variant="contained" type="submit">{t('candidates_search.form.search_button')}</Button>
          </form>
        </CardContent>
      </Card>
    ): ""}
    </section>
  );
}

SearchField.propTypes  = {
  classes: PropTypes.object,
  className: PropTypes.string,
  searchText: PropTypes.string,
  fieldsData: PropTypes.object,
  onTextSearch:  PropTypes.func,
  onFilterSearch:  PropTypes.func,
  onFiltersChange: PropTypes.func,
  onSearchTextChange: PropTypes.func,
  publications: PropTypes.array,
  types: PropTypes.array,
  categories: PropTypes.array,
  claimants: PropTypes.array,
  forceOpenAdvancedSearch: PropTypes.bool,
  onAdvancedSearchToggle: PropTypes.func,
  handleSpecialFilterChange: PropTypes.func,
  setSearchText : PropTypes.func,
  handleClaimantSearch: PropTypes.func,
  claimantGroups: PropTypes.array,
  possibleClaims: PropTypes.array,
  t: PropTypes.func
};

export default compose(
  withTranslation(),
  withStyles(() => ({
    searchTextField: {
      width: '100%',
      backgroundColor: '#fff'
    },
    searchContainer: {
      position: 'relative'
    },
    advanceToggle: {
      position: 'absolute',
      right: '8px',
      top: '12px',
      zIndex: '1',
      backgroundColor: '#fff'
    },
    clearSearchButton: {
      position: 'absolute',
      right: '38px',
      top: '12px',
      zIndex: '1',
      backgroundColor: '#fff'
    },
    formContainer: {
      position: 'absolute',
      zIndex: '1',
      overflow: 'visible',
      width: '100%'
    },
    formControl: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '250px auto',
      alignItems: 'center',
      marginBottom: '12px'
    },
    formSubmit: {
      float: 'right',
      overflow: 'auto',
      marginBottom: '16px'
    },
    searchBar: {
      display: 'grid',
      gridTemplateColumns: 'auto min-content',
    },
    searchBarInner: {
      position: 'relative'
    },
    searchInnerBoxIcon: {
      marginRight: '3px'
    }
  }))
)(SearchField);