import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import TranscriptSentence from "Components/Transcript/TranscriptSentence";
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import claimTypes from 'Config/default/claimTypes';

export class TranscriptSentences extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstFeedSentenceId: null,
      transcriptFilter: '',
      highlightsOnly: false,
      showCopySuccess: false,
      selectedSentenceId: null,
      claimTypesFilter: 'Unfiltered'
    };
  }

  handleTranscriptFilterChange = (e) => {
    if(e.target.value && e.target.value.length) {
      this.setState({
        transcriptFilter: e.target.value
      });
    } else {
      this.setState({
        transcriptFilter: ''
      });
    }
  }

  handleHighlightToggle = () => {
    this.setState({
      highlightsOnly: !this.state.highlightsOnly
    });
  }

  handleToggle = (field) => {
    this.setState({
      [field]: !this.state[field],
      highlightsOnly: false
    });
  }

  copySuccess = () => {
    this.setState({
      showCopySuccess: true
    });

    setTimeout(() => {
      this.setState({
        showCopySuccess: false
      });
    }, 1000);
  }

  setClaimTypesFilter = (e) => {
    this.setState({
      claimTypesFilter: e.target.value
    });
  }

  render() {
    const {
      canHighlight,
      handleSentenceSelect,
      hasMatchLinks,
      hasReceivedSentences,
      isOpen,
      isRequestingSentences,
      sentences,
      t,
      classes,
      floatMatches,
      showTime,
      currentlyPlayingSentence,
      canPlaySentences,
      handlePlaySentence,
      initialSentenceId,
      hasVideo
    } = this.props;

    const {
      highlightsOnly
    } = this.state;

    let claimTypeCounts = {};
    let totalClaimCounts = 0;
    let claimTypeKeys = [];
    let sentencesWithClaimTypes = sentences.filter(stc => stc.enrichments && stc.enrichments.detected_claims && stc.enrichments.detected_claims.length > 0 );
    if(sentencesWithClaimTypes.length) {
      sentencesWithClaimTypes.forEach((stc) => {
          stc.enrichments.detected_claims.forEach((claimType) => {
            if(!claimTypeCounts[claimType]) claimTypeCounts[claimType] = 0;
            claimTypeCounts[claimType]++;
            totalClaimCounts++;
          });
        });
      claimTypeKeys=Object.keys(claimTypeCounts);
    }
    if(isRequestingSentences || (isOpen && !sentences.length)) {
      return <CircularProgress />;
    } else if (!isOpen && hasReceivedSentences && !sentences.length) {
      return <div className={classes.noSentences}>{t('transcript_sentences.no_sentences_found')}</div>;
    } else {
        let sentencesToRender = sentences.map(stc => {
          const hasHighlight = stc.is_highlighted;
          const hasResults = stc.enrichments && ((stc.enrichments.matched_claims && stc.enrichments.matched_claims.length > 0) || (stc.enrichments.stats_checks && stc.enrichments.stats_checks.length > 0));
          let claimsToFilter = [this.state.claimTypesFilter];
          if(this.state.claimTypesFilter === 'Any') claimsToFilter = claimTypeKeys;
          if(((this.state.claimTypesFilter === 'Unfiltered' || stc.enrichments && stc.enrichments.detected_claims && stc.enrichments.detected_claims.some(c=>claimsToFilter.includes(c))) && (!highlightsOnly || (highlightsOnly && hasHighlight)))){
          let sentenceTime = '';
          if(showTime) {
            if (stc.metadata.broadcast_time) {
              sentenceTime = moment.utc(stc.metadata.broadcast_time).local().format('HH:mm:ss');
            } else {
              let date = new Date(null)
              date.setSeconds(Math.floor(stc.metadata.time_offset_ms/1000));
              sentenceTime = date.toISOString().slice(11, 19);
            }
          }
            return (
              <TranscriptSentence
                key={stc.transcript_sentence_id}
                sentence={stc}
                sentenceId={stc.transcript_sentence_id}
                sentenceTime={sentenceTime}
                matchedClaims={stc.enrichments && stc.enrichments.matched_claims || []}
                statsChecked={stc.enrichments && stc.enrichments.stats_checks || []}
                claimTypes={stc.enrichments && stc.enrichments.detected_claims || []}
                hasClaims={hasResults}
                hasHighlight={hasHighlight}
                hasMatchLinks={hasMatchLinks}
                canHighlight={canHighlight}
                handleSentenceSelect={handleSentenceSelect}
                floatMatches={floatMatches}
                selectedClaimType={this.state.claimTypesFilter}
                isEnriching={!stc.enrichments}
                isPlaying={currentlyPlayingSentence === stc.transcript_sentence_id || false}
                canPlaySentence={canPlaySentences}
                handlePlaySentence={handlePlaySentence}
                initialSentenceId={initialSentenceId}
                hasVideo={hasVideo}
              />
            );
          }
        });
        return (
          <div className={hasVideo && classes.videoLayout}>
            <div className={classes.claimTypeFilter}>
              <FormControl fullWidth={true} margin="normal">
                <InputLabel htmlFor="type">{t('transcript_sentences.filter_by_claim_types')}</InputLabel>
                <Select
                  disabled={!(claimTypeKeys.length > 0)}
                  value={this.state.claimTypesFilter}
                  onChange={this.setClaimTypesFilter}
                  fullWidth={true}
                  inputProps={{
                    name: 'claimTypesFilter',
                    id: 'claimTypesFilter',
                  }}
                >
                  <MenuItem key="Unfiltered" value="Unfiltered">{t('transcript_sentences.unfiltered')}</MenuItem>
                  <MenuItem key="Any" value="Any">
                    {t('transcript_sentences.any_count', { count: totalClaimCounts})}
                  </MenuItem>
                  {claimTypeKeys.map((key) => {
                    let claimTypeDisplay = key;
                    if(claimTypes[key]) claimTypeDisplay = claimTypes[key];
                    return (<MenuItem key={key} value={key}>{t(claimTypeDisplay)} ({claimTypeCounts[key]}) </MenuItem>);
                  })}
                </Select>
              </FormControl>
              </div>
            {canHighlight ? (
                <div className={classes.highlightSwitchMenu}>
                  <FormControlLabel control={
                  <Switch className={classes.toggleButton}
                    checked={this.state.highlightsOnly}
                    onChange={this.handleHighlightToggle}
                    inputProps={{ 'aria-label': 'toggle to show only hightlights' }}
                    color="primary"
                  />} label={t('transcript_sentences.field_highlights_only')} />
                </div>
            ): ''}
            <div className={this.props.colClass + ' ' + classes.sentences}>
              {sentencesToRender}
            </div>
          </div>
        );
      }
    }
  }



export default compose(
  withStyles((theme) => ({
    highlightSwitchMenu: {
      display: 'inline-block',
      cursor: 'pointer',
      verticalAlign: 'middle',
      '& label': {
        display: 'inline'
      }
    },
    noSentences: {
      marginTop: '12px'
    },
    toggleButton: {
      marginRight: '12px',
      verticalAlign: 'middle'
    },
    successIcon: {
      color: '#8BC34A',
      '&:hover': {
        color: '#8BC34A'
      }
    },
    claimDetectionToggle: {
      display: 'inline-block',
      marginRight: '12px',
      marginTop: '3px',
      verticalAlign: 'middle'
    },
    sentences: {
      paddingBottom: '24px',
      paddingLeft: '0'
    },
    filterSearchIcon: {
      verticalAlign: 'bottom',
      paddingLeft: '6px',
      display: 'inline-block',
      marginTop: '21px'
    },
    filterSearchTextField: {
      flex: '1',
      verticalAlign: 'bottom',
      paddingRight: '12px',
      display: 'inline-block'
    },
    claimTypeFilter: {
      width: '150px',
      display: 'inline-block',
      verticalAlign: 'bottom',
      marginRight: '24px'
    },
    videoLayout: {
      paddingTop: '233px'
    },
    [theme.breakpoints.up('md')] : {
      videoLayout: {
        paddingTop: '0'
      }
    }
  })),
  withTranslation()
)(TranscriptSentences);

TranscriptSentences.propTypes = {
  canCheckSentence: PropTypes.bool,
  canHighlight: PropTypes.bool,
  colClass: PropTypes.string,
  handleAttachSpeakerToSentence: PropTypes.func,
  handleDeattachSpeakerFromSentence: PropTypes.func,
  handleSentenceSelect: PropTypes.func,
  hasMatchLinks: PropTypes.bool,
  hasReceivedSentences: PropTypes.bool,
  i18n: PropTypes.object,
  isDemo: PropTypes.bool,
  isOpen: PropTypes.bool,
  isRequestingSentences: PropTypes.bool,
  sentences: PropTypes.array,
  t: PropTypes.func,
  transcriptId: PropTypes.number,
  classes: PropTypes.object,
  floatMatches: PropTypes.bool,
  showTime: PropTypes.bool,
  canPlaySentence: PropTypes.bool,
  currentlyPlayingSentence: PropTypes.number,
  handlePlaySentence: PropTypes.func,
  canPlaySentences: PropTypes.bool,
  initialSentenceId: PropTypes.number,
  hasVideo: PropTypes.bool
};
