import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

export class ClaimFilters extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      filters : {}
    };

    this.filterOptions = {
      starred: [
        { value: 'starred', label: props.t('claim_filters.options_starred')},
        { value: 'not-starred', label: props.t('claim_filters.options_not_starred')}
      ]
    };
  }

  setValue = (field, e) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [field]: e.target.value
      }
    }, () => {
      this.props.handleFilter(this.state.filters);
    });

  }

  removeFilter = (field) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [field]: null
      }
    }, () => {
      this.props.handleFilter(this.state.filters);
    });
  }

  render(){
    const { t, classes } = this.props;
    return (
      <div className={classes.filters}>
        {this.state.filters.starred ? (
          <div className={classes.filterChip}>
            <Chip
              label={this.filterOptions.starred.filter(filter => filter.value === this.state.filters.starred )[0].label}
              onDelete={() => {this.removeFilter('starred');}}
            />
            </div>
        ) : (
          <div className={classes.dropdownFilter}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="starred-filter">{t('claim_filters.label_starred')}</InputLabel>
              <Select
                value={''}
                autoWidth={true}
                className={classes.selectMenu}
                onChange={(value) => { this.setValue('starred', value);}}
                inputProps={{
                  name: 'starred',
                  id: 'starred-filter',
                }}
              >
                {this.filterOptions.starred.map(item => (
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
      </div>
    );
  }
}

ClaimFilters.propTypes  = {
  filters: PropTypes.object,
  handleFilter: PropTypes.func.isRequired,
  t: PropTypes.func,
  classes: PropTypes.object
};

export default compose(
  withStyles(() => ({
    selectMenu: {
      width: '100px'
    },
    filters: {
      display: 'inline-block',
      verticalAlign: 'bottom'
    },
    dropdownFilter: {
      display: 'inline-block',
      marginRight: '12px'
    },
    filterChip: {
      marginRight: '6px',
      marginTop: '18px',
      verticalAlign: 'bottom',
      display: 'inline-block'
    }
  })),
  withTranslation()
)(ClaimFilters);
