import authSelectors from 'Selectors/authSelectors';
import claimSelectors from 'Selectors/claimSelectors';
import moment from 'moment';

function getUniqueActionClaimIds(state) {
  let claimIds = [];
  let actions = state.interventionState.interventions.all.map((interventionId) => {
    return state.interventionState.interventions.byId[interventionId];
  });
  actions.forEach((action) => {
    if(!claimIds.includes(action.tracked_claim_id)) claimIds.push(action.tracked_claim_id);
  });
  return claimIds;
}

function getStatusLabel(state, status) {
  const config = authSelectors.getConfig(state);
  if(config.actionStatuses && config.actionStatuses[status]) {
    status = config.actionStatuses[status];
  }
  return status;
}

function getInterventionTypeLabel(state, type) {
  const config = authSelectors.getConfig(state);
  if(config.actionTypes && config.actionTypes[type]) {
    type = config.actionTypes[type];
  }
  return type || '';
}

function getResolutionTypeLabel(state, type) {
  const config = authSelectors.getConfig(state);
  if(config.actionResolutionTypes && config.actionResolutionTypes[type]) {
    type = config.actionResolutionTypes[type];
  }
  return type || '';
}

function getInterventionSightingCategoryTitle(state, category) {
  const config = authSelectors.getConfig(state);
  if(config.sightingCategories && config.sightingCategories[category]) {
    category = config.sightingCategories[category].title;
  }
  return category;
}


export function getIsRequestingInterventions(state){
  return state.interventionState.interventions.requestState === 'pending';
}

export function getHasInterventions(state){
  return state.interventionState.interventions.requestState === 'success';
}

export function getInterventionById(state, id){
  return state.interventionState.interventions.byId[id];
}

export function getInterventionsList(state){
  let interventionsList = [];
  state.interventionState.interventions.all.forEach((intervention_id) => {
    let intervention = getInterventionById(state, intervention_id);
    intervention =  formatIntervention(state, intervention);
    let modifiedIntervention = {
      action_due_to_afc: intervention.action_due_to_afc, 
      author_name: intervention.author_name, 
      intervened_at:intervention.intervened_at, 
      publication: intervention.publication, 
      resolved_at: intervention.resolved_at, 
      status: intervention.status, 
      status_label: intervention.status_label, 
      tracked_claim_factcheck: intervention.tracked_claim_factcheck, 
      tracked_claim_id: intervention.tracked_claim_id, 
      tracked_claim_text: intervention.tracked_claim_text, 
      intervention_id: intervention.intervention_id
    };

    interventionsList.push(modifiedIntervention);
  });
  return interventionsList;
}

export function getActionsCount(state){
  return state.interventionState.interventions.all.length || 0;
}

export function mapFieldOptionsToTranslationKeys(state, fieldName, options) {
  const config = authSelectors.getConfig(state);
  if(fieldName === 'status') {
    options = options.map((option) => ({
      ...option,
      display: config.actionStatuses[option.name]
    }));
  } else if(fieldName === 'category') {
    options = options.map((option) => ({
      ...option,
      display: config.mediaCategories[option.name]
    }));
  }
  return options;
}

export function getFilterableFields(state) {
  let uniqueFieldData = [];
  let fieldTypes = {
    status: 'single',
    publication: 'single',
    author_name: 'single',
    tracked_claim_id: 'text',
    category: 'single'
  };
  let items = getFormattedInterventions(state);

  if(items.length) {
    items.forEach((item) => {
      for (let field in item) {

        if (Object.prototype.hasOwnProperty.call(item, field)) {
          if(fieldTypes[field]) {
            let uniqueField = uniqueFieldData.filter(ufield => ufield.name === field);
            if(uniqueField && uniqueField.length) {
              uniqueField = uniqueField[0];
            } else {
              uniqueField = {
                name: field,
                type: fieldTypes[field], 
                options: []
              };
            }
            if(typeof item[field] === "string"){
              if(!uniqueField.options.includes(item[field])) {
                uniqueField.options.push(item[field]);
              }
            } else if(Array.isArray(item[field])) {
              item[field].forEach((item) => {
                if(!uniqueField.options.includes(item)) {
                  uniqueField.options.push(item);
                }
              });
            }
            uniqueFieldData = uniqueFieldData.filter(ufield => ufield.name !== field);
            uniqueField.options = uniqueField.options.sort();
            uniqueFieldData.push(uniqueField);
          }
        }
      }
    });


    uniqueFieldData = uniqueFieldData.map((fieldData) => {
      let formattedOptions = [];
      if(fieldData.options.length) formattedOptions = fieldData.options.map(opt => ({name: opt}));
      let enrichedFieldOptions = mapFieldOptionsToTranslationKeys(state, fieldData.name, formattedOptions);
      return {
        ...fieldData,
        options: enrichedFieldOptions
      };
    });
  }

  return uniqueFieldData;
}

export function getIsEditing(state){
  return state.interventionState.interventions.isEditing || false;
}

function getYearsFromActions(state) {
  let actions = state.interventionState.interventions.all.slice();
  let years = [];
  actions.forEach(actionIds => {
    let action = state.interventionState.interventions.byId[actionIds];
    let year = parseInt(action.intervened_at.split('-')[0], 10);
    if(!years.includes(year)) years.push(year);
  });
  years.sort().reverse();
  return years;
}

export function getAverageInterventionChartData(state){

  // get all interventions
  let actions = state.interventionState.interventions.all.slice();

  let chartDateCounts = {};
  // get full interventions by id
  actions.forEach(interventionId => {
    let intervention = state.interventionState.interventions.byId[interventionId];
    let simplifiedDate = moment(intervention.intervened_at).format('YYYY-MM-01');
    if(chartDateCounts[simplifiedDate]) {
      chartDateCounts[simplifiedDate] = ++chartDateCounts[simplifiedDate];
    } else {
      chartDateCounts[simplifiedDate] = 1;
    }
  });

  let actionYears = getYearsFromActions(state);
  let chartDate = [];
  actionYears.forEach(actionYear => {
      let yearlyChartData = {
        x: [],
        y: [],
        type: 'line',
        hoverinfo: "x+y",
        line: { shape: 'spline' },
        name: actionYear+'',
        marker: {
          size: 9
        }
      };
      let date = moment(actionYear+'-01-01');
      for (let i = 0; i < 12; i++) {
        date.month(i);
        let simpleDate = date.format('YYYY-MM-DD');
        let displayDate = date.format('MMM');
        yearlyChartData.x.push(displayDate);
        yearlyChartData.y.push(chartDateCounts[simpleDate] || 0);
      }
      chartDate.push(yearlyChartData);
  });

  return  chartDate;

}

export function getInterventionTurnaroundAverages(state){
  let pubAverages = {};
  // get all interventions
  let interventions = state.interventionState.interventions.all;

  // get full interventions by id
  interventions.forEach(interventionId => {
    let intervention = state.interventionState.interventions.byId[interventionId];
    if((intervention.intervened_at && intervention.resolved_at) && (moment(intervention.resolved_at).isAfter(intervention.intervened_at))) {
      let timeDiff = moment.utc(intervention.resolved_at).diff(moment.utc(intervention.intervened_at),'days');
      let interventionFormat = intervention.stored_sighting.publication;
      let pubstats = pubAverages[interventionFormat];
      if(pubstats) {
        pubAverages[interventionFormat] = {
          ...pubAverages[interventionFormat],
          turnaround_time: Math.ceil((pubstats.turnaround_time + timeDiff)/2),
          interventions_count: ++pubstats.interventions_count
        };
      } else {
        pubAverages[interventionFormat] = {
          publication: interventionFormat,
          author_name: intervention.stored_sighting.author_name || '',
          turnaround_time: timeDiff || 1,
          interventions_count: 1
        };
      }
    }
  });
  let averages = [];
  for (let publication in pubAverages) {
    averages.push(pubAverages[publication]);
  }
  averages = averages.filter(average => (average.interventions_count > 1));
  averages.sort(function(x, y) {
    if (x.turnaround_time > y.turnaround_time) {
      return -1;
    }
    if (x.turnaround_time < y.turnaround_time) {
      return 1;
    }
    return 0;
  });
  return averages;
}


function formatIntervention(state, intervention) {

  let formattedIntervention  =  {
    action_due_to_afc: intervention.action_due_to_afc,
    intervened_at: intervention.intervened_at,
    intervention_id: intervention.intervention_id,
    intervention_type: intervention.intervention_type,
    intervention_type_label: getInterventionTypeLabel(state, intervention.intervention_type),
    intervention_target: intervention.intervention_target || '',
    wikidata_intervention_target_id: intervention.wikidata_intervention_target_id,
    resolution_summary: intervention.resolution_summary || '',
    status: intervention.status,
    status_label: getStatusLabel(state, intervention.status),
    stored_sighting_id: intervention.stored_sighting_id,
    tracked_claim_id: intervention.tracked_claim_id,
    tracked_claim_text: claimSelectors.getClaimText(state, intervention.tracked_claim_id),
    tracked_claim_factcheck: claimSelectors.getClaimFackcheck(state, intervention.tracked_claim_id),
    resolution_type: intervention.resolution_type,
    resolution_url: intervention.resolution_url,
    resolution_type_label: getResolutionTypeLabel(state, intervention.resolution_type),
    updated_at: intervention.updated_at,
    media_hash: intervention.stored_sighting.media_hash,
    publication_date: intervention.stored_sighting.publication_date,
    publication: intervention.stored_sighting.publication,
    wikidata_publication_id: intervention.stored_sighting.wikidata_publication_id,
    author_name: intervention.stored_sighting.author_name || '',
    wikidata_author_name_id: intervention.stored_sighting.wikidata_author_name_id,
    sighting_type: intervention.stored_sighting.sighting_type,
    url: intervention.stored_sighting.url,
    category: intervention.stored_sighting.category,
    category_label: getInterventionSightingCategoryTitle(state, intervention.stored_sighting.category),
    text: intervention.stored_sighting.text,
    resolved_at: intervention.resolved_at,
    notes: intervention.notes
  };
  return formattedIntervention;
}

export function getFormattedInterventions(state){
  let items = [];

  state.interventionState.interventions.all.forEach((intervention_id) => {

    let intervention = getInterventionById(state, intervention_id);
    let modifiedIntervention = formatIntervention(state, intervention);

    items.push(modifiedIntervention);
  });

  return items;

}

export function getFormattedInterventionById(state, interventionId){
  let intervention = getInterventionById(state, interventionId);
  let modifiedIntervention;
  if(intervention) {
    modifiedIntervention = formatIntervention(state, intervention);
  }
  return modifiedIntervention;

}

export function getInterventionsFilteredByWikiIdPublication(state, wikiId) {
   let formattedInterventions = getFormattedInterventions(state);
   formattedInterventions = formattedInterventions.filter(intervention => intervention.wikidata_publication_id === wikiId);
   return formattedInterventions;
}

export function getInterventionsFilteredByWikiIdAuthor(state, wikiId) {
  let formattedInterventions = getFormattedInterventions(state);
  formattedInterventions = formattedInterventions.filter(intervention => intervention.wikidata_author_name_id === wikiId);
  return formattedInterventions;
}

export function getAllWikiPublications(state) {
  let formattedInterventions = getFormattedInterventions(state);
  formattedInterventions = formattedInterventions.filter(intervention => intervention.publication && intervention.wikidata_publication_id);
  let pubNames = [];
  let publications = [];
  formattedInterventions.forEach((intervention) => {
    let count = 1;
    if(!pubNames.includes(intervention.publication)) {
      pubNames.push(intervention.publication);
      publications[intervention.publication] = intervention.wikidata_publication_id;
    } else {
      count = publications[intervention.publication].count + 1;
    }
    publications[intervention.publication] = {
      id: intervention.wikidata_publication_id,
      count: count
    };
  });
  pubNames.sort();
  return pubNames.map(pub => ({
    name: pub,
    id: publications[pub].id,
    count: publications[pub].count
  }));
}

export function getAllWikiAuthors(state) {
  let formattedInterventions = getFormattedInterventions(state);
  formattedInterventions = formattedInterventions.filter(intervention => intervention.author_name && intervention.wikidata_author_name_id);
  let authNames = [];
  let authors = [];
  formattedInterventions.forEach((intervention) => {
    let count = 1;
    if(!authNames.includes(intervention.author_name)) {
      authNames.push(intervention.author_name);
    } else {
      count = authors[intervention.author_name].count + 1;
    }
    authors[intervention.author_name] = {
      id: intervention.wikidata_author_name_id,
      count: count
    };
  });
  authNames.sort();
  return authNames.map(auth => ({
    name: auth,
    id: authors[auth].id,
    count: authors[auth].count
  }));
}

export function getSortedAndFilteredInterventions(state){
  let items = [];
  state.interventionState.interventions.all.forEach((intervention_id) => {

    let intervention = getInterventionById(state, intervention_id);
    intervention = formatIntervention(state, intervention);
    let modifiedIntervention = {
      action_due_to_afc: intervention.action_due_to_afc,
      author_name: intervention.author_name,
      intervened_at: intervention.intervened_at,
      intervention_type_label: intervention.intervention_type_label,
      publication: intervention.publication,
      resolution_type_label: intervention.resolution_type_label,
      resolution_url: intervention.resolution_url, 
      resolved_at: intervention.resolved_at,
      status_label: intervention.status_label,
      tracked_claim_factcheck: intervention.tracked_claim_factcheck,
      tracked_claim_id: intervention.tracked_claim_id,
      tracked_claim_text: intervention.tracked_claim_text,
      status: intervention.status,
      intervention_id: intervention.intervention_id
    };
    items.push(modifiedIntervention);
  });

  let sort = state.interventionState.sortedBy;
  let order = state.interventionState.orderedBy;


  switch (sort) {
    case 'status':
      items.sort((a,b) => {
        if (a.status > b.status)
          return -1;
        if (a.status < b.status)
          return 1;
        return 0;
      });
      break;
    case 'created_at':
      items = items.sort((a,b) => {
        let aMoment = moment(a.created_at);
        let bMoment = moment(b.created_at);
        if (aMoment.isAfter(bMoment))
          return -1;
        if (aMoment.isBefore(bMoment))
          return 1;
        return 0;
      });

      break;
    case 'publication':
      items.sort((a,b) => {
        let aClaim = a.publication.toLowerCase();
        let bClaim = b.publication.toLowerCase();
        if (aClaim > bClaim)
          return -1;
        if (aClaim < bClaim)
          return 1;
        return 0;
      });
      break;
    case 'publication_date':
      items = items.sort((a,b) => {
        let aMoment = moment(a.stored_sighting.publication_date);
        let bMoment = moment(b.stored_sighting.publication_date);
        if (aMoment.isAfter(bMoment))
          return -1;
        if (aMoment.isBefore(bMoment))
          return 1;
        return 0;
      });
      break;
    case 'intervened_at':
    items = items.sort((a,b) => {
      let aMoment = moment(a.intervened_at);
      let bMoment = moment(b.intervened_at);
      if (aMoment.isAfter(bMoment))
        return -1;
      if (aMoment.isBefore(bMoment))
        return 1;
      return 0;
    });
      break;
    case 'resolved_at':
    items = items.sort((a,b) => {
      let aMoment = moment(a.resolved_at);
      let bMoment = moment(b.resolved_at);
      if (aMoment.isAfter(bMoment))
        return -1;
      if (aMoment.isBefore(bMoment))
        return 1;
      return 0;
    });
      break;
  }
  if(order === 'desc') items = items.reverse();
  return items;
}

export function getSortedBy(state){
  return state.interventionState.sortedBy;
}

export function getOrderedBy(state){
  return state.interventionState.orderedBy;
}

export function getMonthlyStatisticsByYear(state){
  let claims = claimSelectors.getClaims(state);
  let filteredClaims = claims.filter(claim => claim.factcheck_url && claim.factcheck_url !== '');
  let statsByYear = {};
  if(filteredClaims) {
    filteredClaims.sort(function(a, b) {
      return (new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    });

    let uniqueFactcheckUrls = [];
    let uniqueClaimIds = [];
    filteredClaims.forEach(claim => {
      const claimDate = claim.created_at.split('-');
      const claimYear = parseInt(claimDate[0],10);
      const claimMonth = parseInt(claimDate[1],10) - 1;
      const isFacebook =  state.authState.user.organisation === 'fullfact' && claim.is_tpfc;
      let factcheckCount = 0;
      let facebookCount = 0;

      if(!uniqueFactcheckUrls.includes(claim.factcheck_url)) {
        uniqueFactcheckUrls.push(claim.factcheck_url);
        factcheckCount = isFacebook ? 0 : 1;
        facebookCount = isFacebook ? 1 : 0;
      }
      if(!uniqueClaimIds.includes(claim.tracked_claim_id)) uniqueClaimIds.push(claim.tracked_claim_id);

      let defaultYear = [];
      for (let i = 0; i < 12; i++) {
        defaultYear.push({
          title: (i + 1) + '',
          factcheckCount: 0,
          facebookFactchecks: 0,
          publishedCount: 0,
          actionsCount: 0,
          actionsAFCCount: 0,
          factchecksActedOn: 0,
          actionRatePercent: 0,
        });
      }

      if(!statsByYear[claimYear]) statsByYear[claimYear] = [...defaultYear];

      statsByYear[claimYear][claimMonth] = {
        ...statsByYear[claimYear][claimMonth],
        title: claimDate[0] + '-' + claimDate[1] + '-01',
        factcheckCount: statsByYear[claimYear][claimMonth].factcheckCount + factcheckCount,
        facebookFactchecks: statsByYear[claimYear][claimMonth].facebookFactchecks + facebookCount,
        publishedCount: statsByYear[claimYear][claimMonth].publishedCount + factcheckCount + facebookCount
      };

    });

    let actions = [];
    actions = getInterventionsList(state);
    let filteredActions = actions.filter(action => action.status && action.status !== 'not_started');

    filteredActions.sort(function(a, b) {
      return (new Date(a.intervened_at).getTime() - new Date(b.intervened_at).getTime());
    });

    let factchecksActedOn = [];
    if(filteredActions.length) {
      filteredActions.forEach(action => {
        let intervenedAt = action.intervened_at.split('-');
        let actionYear = parseInt(intervenedAt[0],10);
        let actionMonth = parseInt(intervenedAt[1],10) -1;
        if(statsByYear[actionYear]) {
          statsByYear[actionYear][actionMonth].actionsCount = statsByYear[actionYear][actionMonth].actionsCount  + 1;
          if(action.action_due_to_afc) {
            statsByYear[actionYear][actionMonth].actionsAFCCount = statsByYear[actionYear][actionMonth].actionsAFCCount  + 1;
          }
          if(!factchecksActedOn.includes(action.tracked_claim_factcheck)) {
            factchecksActedOn.push(action.tracked_claim_factcheck);
            statsByYear[actionYear][actionMonth].factchecksActedOn = statsByYear[actionYear][actionMonth].factchecksActedOn + 1;
          }
        }
      });
    }


    for (let year in statsByYear) {
      if (Object.prototype.hasOwnProperty.call(statsByYear, year)) {
        statsByYear[year] = statsByYear[year].map(month => {
          return {
            ...month,
            actionRatePercent: month.factcheckCount ? ((month.factchecksActedOn / month.factcheckCount) * 100).toFixed(0) : 0
          };
        });
      }
    }

  }

  return statsByYear;
}

function generateActionStatisticsByYear(claims, actions, isYearToDate){
  let statisticsByYear = {};
  let uniqueClaimIds = [];
  let uniqueUrls = [];

  if(claims.length) {
    let filteredClaims = claims.filter(claim => claim.factcheck_url && claim.factcheck_url !== '');
    filteredClaims.forEach(claim => {
      if(!uniqueClaimIds.includes(claim.tracked_claim_id)) {
        uniqueClaimIds.push(claim.tracked_claim_id);
        let claimYear = claim.created_at.split('-')[0];
        if(!statisticsByYear[claimYear]) {
          statisticsByYear[claimYear] = {
            nonFacebookFactchecks: 0,
            claimIds: [],
            facebookFactchecks: 0,
            totalPublished: 0
          };
        }
        if(moment(claim.created_at).isBefore(moment().format(claimYear+'-MM-DDTHH:mm:ss')) || !isYearToDate) {
          if(!uniqueUrls.includes(claim.factcheck_url)) {
            uniqueUrls.push(claim.factcheck_url);
            if(claim.is_tpfc){
              statisticsByYear[claimYear].facebookFactchecks = statisticsByYear[claimYear].facebookFactchecks + 1;
            } else {
              statisticsByYear[claimYear].nonFacebookFactchecks = statisticsByYear[claimYear].nonFacebookFactchecks + 1;
            }
            statisticsByYear[claimYear].totalPublished = statisticsByYear[claimYear].totalPublished + 1;
          }
          if(!statisticsByYear[claimYear].claimIds.includes(claim.tracked_claim_id)) {
            statisticsByYear[claimYear].claimIds.push(claim.tracked_claim_id);
          }
        }
      }
    });
  }

  let finalStats = [];
  for (let year of Object.keys(statisticsByYear)) {
    let stats = statisticsByYear[year];
    let uniqueActionClaimIds = [];
    let filteredActions = actions.filter(action => action.status && action.status !== 'not_started');
    filteredActions = filteredActions.filter(interv => {
      let intervenedMoment = moment(interv.intervened_at);

      if(intervenedMoment.isAfter(year+'-01-01') && (intervenedMoment.isBefore(moment().format(year+'-MM-DDTHH:mm:ss'))  || (!isYearToDate && intervenedMoment.isBefore(moment().format(year+'-12-31T23:59:59'))))) {
        return true;
      } else {
        return false;
      }
    });

    filteredActions.forEach(intervention => {
      if(intervention.tracked_claim_id && stats.claimIds.includes(intervention.tracked_claim_id)) {
        if(!uniqueActionClaimIds.includes(intervention.tracked_claim_id)) {
          uniqueActionClaimIds.push(intervention.tracked_claim_id);
        }
      }
    });

    let actionRatePercent = 0;
    if(stats.nonFacebookFactchecks && uniqueActionClaimIds.length) {
      actionRatePercent = ((uniqueActionClaimIds.length/stats.nonFacebookFactchecks) * 100).toFixed(2);
    }
    finalStats.push({
      title: year,
      year: year,
      factcheckCount: stats.nonFacebookFactchecks,
      actionsCount: filteredActions.length,
      actionsAFCCount: filteredActions.filter(action => action.action_due_to_afc).length,
      factchecksActedOn: uniqueActionClaimIds.length,
      actionRatePercent: actionRatePercent,
      facebookFactchecks: stats.facebookFactchecks,
      publishedCount: stats.totalPublished
    });
  }

  return finalStats;
}

export function getActionStats(state){
  let claims = claimSelectors.getClaims(state);
  let interventions = getInterventionsList(state);
  let finalStats = generateActionStatisticsByYear(claims, interventions, false);
  return finalStats;
}

export function getActionStatsYTD(state){
  let claims = claimSelectors.getClaims(state);
  let interventions = getInterventionsList(state);
  let finalStats = generateActionStatisticsByYear(claims, interventions, true);
  return finalStats;
}

export function getIsRequestingActions(state){
  return state.interventionState.interventions.requestState === 'pending';
}

export function getPublicationsFromActions(state) {
  let publications = [];
  if(state.interventionState.interventions) {
    let actions = getFormattedInterventions(state);
    actions.forEach((action) => {
      let publication = action.publication;
      if(!publications.includes(publication)) publications.push(publication);
    });
  }
  publications.sort();
  return publications;
}

export function getAuthorsFromActions(state) {
  let authors = [];
  if(state.interventionState.interventions) {
    let actions = getFormattedInterventions(state);
    actions.forEach((action) => {
      let author = action.author_name;
      if(!authors.includes(author)) authors.push(author);
    });
  }
  authors.sort();
  return authors;
}

export function getInterventionsByClaimId(state, claimId) {
  let interventions = [];

  if(state.interventionState.interventions.all.length) {

    interventions = state.interventionState.interventions.all.map((id) => {
      return state.interventionState.interventions.byId[id];
    });

    interventions = interventions.filter(intervention => intervention.tracked_claim_id+'' === claimId+'');

  }

  return interventions;
}

export function getActionSightingsByClaimId(state, claimId) {
  let uniqueActionSightings = [];
  let allStoredSightingIds = [];
  if(state.interventionState.interventions.all.length) {

    let actions = state.interventionState.interventions.all.map((id) => {
      return state.interventionState.interventions.byId[id];
    });

    actions = actions.filter(action => action.tracked_claim_id+'' === claimId);

    actions.forEach((action) => {
      if(!allStoredSightingIds.includes(action.stored_sighting.stored_sighting_id)) {
        uniqueActionSightings.push(action.stored_sighting);
        allStoredSightingIds.push(action.stored_sighting.stored_sighting_id);
      }
    });
  }

  return uniqueActionSightings;
}

export function getUniqueActionClaimCount(state) {
  let actionClaimIds = [];

  let actions = state.interventionState.interventions.all.map((id) => {
    return state.interventionState.interventions.byId[id];
  });
  actions.forEach((action) => {
    if(!actionClaimIds.includes(action.tracked_claim_id)) actionClaimIds.push(action.tracked_claim_id);
  });

  return actionClaimIds.length;
}

export function getUniqueActionSightingsCount(state) {
  let actionClaimIds = [];

  let actions = state.interventionState.interventions.all.map((id) => {
    return state.interventionState.interventions.byId[id];
  });
  actions.forEach((action) => {
    if(!actionClaimIds.includes(action.tracked_claim_id)) actionClaimIds.push(action.tracked_claim_id);
  });

  return actionClaimIds.length;
}

export function getUniqueAlphaActionSightingsIds(state) {
  let storedSightingIds = [];

  let actions = state.interventionState.interventions.all.map((id) => {
    return state.interventionState.interventions.byId[id];
  });
  actions.forEach((action) => {
    if(action.action_due_to_afc && !storedSightingIds.includes(action.stored_sighting.stored_sighting_id)) {
      storedSightingIds.push(action.stored_sighting.stored_sighting_id);
    }
  });

  return storedSightingIds;
}

export function getUniqueAlphaActionSightingsCount(state) {
  let storedSightingIds = [];

  let actions = state.interventionState.interventions.all.map((id) => {
    return state.interventionState.interventions.byId[id];
  });
  actions.forEach((action) => {
    if(action.action_due_to_afc && !storedSightingIds.includes(action.stored_sighting.stored_sighting_id)) {
      storedSightingIds.push(action.stored_sighting.stored_sighting_id);
    }
  });

  return storedSightingIds.length;
}

export function getConfirmedAlphaSightingsCount(state) {
  let sightingIds = [];
  let actionSightingsIds = getUniqueAlphaActionSightingsIds(state);
  let matchedSightingsIds = claimSelectors.getMatchedSightingsIds(state);

  actionSightingsIds.forEach((id) => {
    if(!sightingIds.includes(id)) sightingIds.push(id);
  });

  matchedSightingsIds.forEach((id) => {
    if(!sightingIds.includes(id)) sightingIds.push(id);
  });

  return sightingIds.length || 0;
}

export function getActionTypes(state) {
  let types = [];
  const config = authSelectors.getConfig(state);
  if(config.actionTypes) {
    for(let type in config.actionTypes) {
      types.push({
        value: type,
        label: config.actionTypes[type]
      });
    }
  }

  return types;
}

export function getActionResolutionTypes(state) {
  let types = [];
  const config = authSelectors.getConfig(state);
  if(config.actionResolutionTypes) {
    for(let type in config.actionResolutionTypes) {
      types.push({
        value: type,
        label: config.actionResolutionTypes[type]
      });
    }
  }
  return types;
}

export function getActionStatuses(state) {
  let statuses = [];
  const config = authSelectors.getConfig(state);
  if(config.actionStatuses) {
    for(let status in config.actionStatuses) {
      statuses.push({
        value: status,
        label: config.actionStatuses[status]
      });
    }
  }

  return statuses;
}

export function hasFacebookActions(state) {
  let claimIds = getUniqueActionClaimIds(state);
  let totalTPFCClaims = 0;

  claimIds.forEach((claimId) => {
    let claim = claimSelectors.getClaimById(state, claimId);
    if(claim && claim.is_tpfc) totalTPFCClaims++;
  });

  return totalTPFCClaims > 0;
}

export function getActionsByAuthor(state) {
  let authors = [];
  let authorsByName = {};

  let actions = state.interventionState.interventions.all.map((id) => {
    return state.interventionState.interventions.byId[id];
  });
  actions.forEach((action) => {
    let authorName = action.stored_sighting.author_name;
    if(authorName) {
      if(!authors.includes(authorName) ) {
        authors.push(authorName);
        authorsByName[authorName] = 1;
      } else {
        authorsByName[authorName]++;
      }
    }
  });
  authors = authors.map((author) => ({
    authorName: author,
    numberOfActions: authorsByName[author]
  }));
  authors.sort(function(x, y) {
    if (x.numberOfActions > y.numberOfActions) {
      return -1;
    }
    if (x.numberOfActions < y.numberOfActions) {
      return 1;
    }
    return 0;
  });
  return authors;
}


export function getActionsByPublication(state) {
  let publications = [];
  let publicationsByName = {};

  let actions = state.interventionState.interventions.all.map((id) => {
    return state.interventionState.interventions.byId[id];
  });

  actions.forEach((action) => {
    let publication = action.stored_sighting.publication;
    if(!publications.includes(publication) ) {
      publications.push(publication);
      publicationsByName[publication] = 1;
    } else {
      publicationsByName[publication]++;
    }
  });

  publications = publications.map((publication) => ({
    name: publication,
    numberOfActions: publicationsByName[publication]
  }));

  publications.sort(function(x, y) {
    if (x.numberOfActions > y.numberOfActions) {
      return -1;
    }
    if (x.numberOfActions < y.numberOfActions) {
      return 1;
    }
    return 0;
  });
  return publications;
}

export default ({
  getHasInterventions: getHasInterventions,
  getInterventionById: getInterventionById,
  getInterventionsList: getInterventionsList,
  getIsEditing: getIsEditing,
  getAverageInterventionChartData: getAverageInterventionChartData,
  getInterventionTurnaroundAverages: getInterventionTurnaroundAverages,
  getIsRequestingInterventions: getIsRequestingInterventions,
  getSortedAndFilteredInterventions: getSortedAndFilteredInterventions,
  getSortedBy: getSortedBy,
  getOrderedBy: getOrderedBy,
  getActionStats: getActionStats,
  getActionStatsYTD: getActionStatsYTD,
  getFormattedInterventions: getFormattedInterventions,
  getIsRequestingActions: getIsRequestingActions,
  getFilterableFields: getFilterableFields,
  getMonthlyStatisticsByYear: getMonthlyStatisticsByYear,
  getPublicationsFromActions: getPublicationsFromActions,
  getInterventionsByClaimId: getInterventionsByClaimId,
  getActionSightingsByClaimId: getActionSightingsByClaimId,
  getActionsCount: getActionsCount,
  getUniqueActionClaimCount: getUniqueActionClaimCount,
  getUniqueActionSightingsCount: getUniqueActionSightingsCount,
  getConfirmedAlphaSightingsCount: getConfirmedAlphaSightingsCount,
  getUniqueAlphaActionSightingsCount: getUniqueAlphaActionSightingsCount,
  getActionTypes: getActionTypes,
  getActionResolutionTypes: getActionResolutionTypes,
  getActionStatuses: getActionStatuses,
  hasFacebookActions: hasFacebookActions,
  getActionsByAuthor: getActionsByAuthor,
  getActionsByPublication: getActionsByPublication,
  getAuthorsFromActions: getAuthorsFromActions,
  getInterventionsFilteredByWikiIdPublication: getInterventionsFilteredByWikiIdPublication,
  getInterventionsFilteredByWikiIdAuthor: getInterventionsFilteredByWikiIdAuthor,
  getAllWikiPublications: getAllWikiPublications,
  getAllWikiAuthors: getAllWikiAuthors,
  getFormattedInterventionById: getFormattedInterventionById
});
