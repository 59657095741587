import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import NoticeContainer from 'Components/Common/NoticeContainer';
import NotAuthorisedPage from 'Components/Pages/NotAuthorisedPage';
import NavContainer from 'Components/Nav/NavContainer';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import privateRoutes from 'Config/privateRoutes';
import authSelectors from 'Selectors/authSelectors';

const isFixedHeight = (path) => {
  let route = privateRoutes.filter(route => route.path === path)[0];
  return Boolean(route.fixedHeight);
};

function PrivateRoute({ classes, component: Component, permissions, hasPagePermission, ...rest }) {
    return (<Route {...rest} render={(props) => {
      const {
        match
      } = props;
      return(
          <div className={classes.privateRoute + ' '+(isFixedHeight(match.path) ? classes.fixedContainer : classes.pageContainer)}>
              <NavContainer className={isFixedHeight(match.path) ? classes.fixedHeight : classes.pageHeader} {...props} />

              <div className={(isFixedHeight(match.path) ? classes.fixedContent : "grid " + classes.pageContent)}>
                {hasPagePermission ? (
                   <Component {...props} />
                ) : (
                  <NotAuthorisedPage />
                )}
              </div>
              <NoticeContainer displayCode="general_notice" dismissible={true} />
          </div>
        );
  }} />);
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object,
  fixedHeight: PropTypes.bool,
  classes: PropTypes.object,
  permissions: PropTypes.array,
  hasPagePermission: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  return {
    hasPagePermission: authSelectors.getHasPagePermission(state, ownProps.name)
  };
}
export default compose(
  connect(
    mapStateToProps,
    null
  ),
  withStyles((theme) => ({
    privateRoute: {
      backgroundColor: theme.palette.background.default,
      minHeight: '100vh'
    },
    pageHeader: {
      position: 'fixed',
      top: '0'
    },
    pageContent: {
      paddingTop: '69px'
    },
    fixedContainer: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column'
    },
    fixedContent: {
      flexGrow: '1',
      height: '100%'
    }
  })),
)(PrivateRoute);
