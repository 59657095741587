import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';

import channelActions from 'Actions/channelActions';
import noticeActions from 'Actions/noticeActions';

import MQTTFeed from 'Components/Live/MQTTFeed';
import LiveChannelSentencesContainer from 'Components/Live/LiveChannelSentencesContainer';
import LiveChannelDetails from 'Components/Live/LiveChannelDetails';
import NoticeContainer from 'Components/Common/NoticeContainer';
import moment from 'moment';

import { withTranslation } from 'react-i18next';
import liveSelectors from 'Selectors/channelSelectors';
import authSelectors from 'Selectors/authSelectors';
import transcriptActions from 'Actions/transcriptActions';
import transcriptSelectors from 'Selectors/transcriptSelectors';
import claimSelectors from 'Selectors/claimSelectors';
import demoChannel from 'Config/demoChannel';

import * as utils from '../../utils';

export class ChannelPage extends React.Component {

  constructor(props){
    super(props);

    let channel = this.props.match.params['channelId'];
    if(channel) {
      if(channel === 'demo'){
        this.state = {
          channel: channel,
          feed: new MQTTFeed(this.props.mqttUrl,'', this.announceSubtitle(this))
        };

        let demoSentences = [
          ...demoChannel
        ];

        let getDemoSentences = () => {
          if(demoSentences.length > 0) {
            let demoSentence = demoSentences.shift();
            setTimeout(() => {
              this.props.channelActions.subtitleArrived(JSON.stringify({
                sentence_id: utils.generateNumberId(),
                channel_canonical_name: 'demo',
                text: demoSentence,
                broadcast_time: moment().format('YYYY-MM-DDTHH:mm:ss')
              }));
              getDemoSentences();
            }, 2000);
          }
        };
        getDemoSentences();
      } else {
        this.props.channelActions.loadChannel(channel);

        this.state = {
          channel: channel,
          feed: null
        };
      }
    }

  }

  componentDidUpdate() {
    if(this.props.channel && !this.state.feed && this.props.channel !== 'demo'){
      this.setState({
        feed: new MQTTFeed(this.props.mqttUrl,'channels/' + this.state.channel, this.announceSubtitle(this))
      });
    }
  }

  componentWillUnmount() {
    if(this.state.feed) {
      this.state.feed.tune('');
    }
    this.props.channelActions.clearLiveSubtitles();
    this.props.channelActions.clearChannel();
    this.props.noticeActions.clearNotices();
  }

  announceSubtitle(that){
    return function(subtitle) {
      that.props.channelActions.subtitleArrived(subtitle);
    };
  }

  render() {
    const { channel } = this.props;
    let channelDate = moment(Date.now()).format('YYYY-MM-DD');
    if(channel && channel.channel_canonical_name && channel !== 'demo') {
      return (
        <section>
          <div className="row">
            <div className="col-xs4-4 col-lg-12">
            <LiveChannelDetails
              display={channel.channel_display_name}
              code={channel.channel_canonical_name}
              dateTime={channelDate}
            />
            </div>
          </div>
          <div className="row">
            <div className="col-full-row">
              <NoticeContainer displayCode="page_notice" dismissible={true} />
              <LiveChannelSentencesContainer display={channel.channel_display_name} hasClaimDetection={true} />
            </div>
          </div>
        </section>
      );
    } else if (this.state.channel === 'demo') {
      return (
        <section>
          <div className="row">
            <div className="col-xs4-4 col-lg-12">
            <LiveChannelDetails
              display="Demo"
              code="demo"
              dateTime={channelDate}
            />
            </div>
          </div>
          <div className="row">
            <div className="col-full-row">
              <LiveChannelSentencesContainer display="Demo" />
            </div>
          </div>
        </section>
      );
    }
    return null;
  }
}

ChannelPage.propTypes = {
  channelActions: PropTypes.object.isRequired,
  noticeActions: PropTypes.object,
  match: PropTypes.object.isRequired,
  channel: PropTypes.object,
  display: PropTypes.string,
  i18n: PropTypes.object,
  mqttUrl: PropTypes.string
};

function mapStateToProps(state) {
  return {
    channel: liveSelectors.getChannel(state),
    mqttUrl: authSelectors.getMqttUrl(state),
    sentences: transcriptSelectors.getSentences(state),
    matches: claimSelectors.getMatches(state),
    checkingSentences: claimSelectors.getCheckingSentences(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    channelActions: bindActionCreators(channelActions, dispatch),
    noticeActions: bindActionCreators(noticeActions, dispatch),
    transcriptActions: bindActionCreators(transcriptActions, dispatch)
  };
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  
)(ChannelPage);
