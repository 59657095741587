import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

const TranscriptFormText = (props) => {
    return (
      <div className={props.classes.formText}>
      <TextField
        className={props.classes.bodyText}
        fullWidth={true}
        type="text"
        multiline
        label={props.label}
        name="body"
        value={props.body}
        aria-label="block text"
        onChange={props.setValue} />
      </div>

    );
};


TranscriptFormText.propTypes = {
    setValue: PropTypes.func,
    body: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string
};

export default compose(
  withStyles(() => ({
    bodyText: {
      '& textarea': {
        minHeight: '190px'
      }
    },
    formText: {
      marginBottom: '12px'
    }
  }))
)(TranscriptFormText);
