import { put, takeEvery, select } from 'redux-saga/effects';

export function* processGetSuggestions(action) {
    yield put({
        type: 'FETCH',
        requestType: action.type,
        payload: {
        url: 'https://www.wikidata.org/w/api.php',
        excludeToken: true,
        method: 'GET',
        body: {
            action: 'wbsearchentities',
            format: 'json',
            errorformat: 'plaintext',
            language: 'en',
            uselang: 'en',
            type: 'item',
            origin: '*',
            search: action.inputValue
        }
        },
        request: {
        ref: action.ref
        }
    });
}

export function* processGetWikiEntity(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      url: 'https://www.wikidata.org/wiki/Special:EntityData/',
      excludeToken: true,
      method: 'GET',
      body: {
        id: action.wikiId,
        format: 'json',
        errorformat: 'plaintext',
        language: 'en',
        uselang: 'en',
        type: 'item',
        origin: '*'
      }
    },
    request: {
      ref: action.wikiId
    }
  });
}

export function* entitySagas(){
    yield takeEvery('GET_SUGGESTIONS', processGetSuggestions);
    yield takeEvery('GET_ENTITY', processGetWikiEntity);
    
}
