import transcriptConstants from '../constants/transcriptConstants';
import initialState from './initialState';


const TYPE_TRANSCRIPTS_PAGE_SIZE = 10;
const ALL_TRANSCRIPTS_PAGE_SIZE = 100;

function formatTranscript(transcript) {
  let formattedTranscript = {
    createdAt: transcript.created_at,
    isOpen: transcript.is_open || false,
    transcriptId: transcript.transcript_id,
    transcriptName: transcript.transcript_name,
    transcriptType: transcript.type_name,
    createdByDisplayName: (transcript.metadata.full_created_by && transcript.metadata.full_created_by.display_name) || '',
    statistics: transcript.statistics && transcript.statistics.enrichment_statistics || {},
    channelName: transcript.metadata.channel_name || '',
    publicationName: transcript.schedule?.publication_name || ''
  };

  if(formattedTranscript.transcriptType === 'radio_v2') {
    formattedTranscript.transcriptType = 'radio'
  }

  if(formattedTranscript.transcriptType === 'channel') {
    formattedTranscript.endTime = transcript.metadata.validated_end_time;
    formattedTranscript.startTime = transcript.metadata.validated_start_time;
  }

  if(formattedTranscript.transcriptType === 'adhoc') {
    formattedTranscript.createdByDisplayName = '';
  }

  if(formattedTranscript.transcriptType === 'radio') {
    formattedTranscript.startTime =  transcript.metadata.start_time;
    formattedTranscript.channelName = transcript.metadata.transcript_schedule && transcript.metadata.transcript_schedule.radio_station;
    formattedTranscript.taskUuid = transcript.metadata.task_uuid;
    formattedTranscript.publicationName = transcript.schedule?.publication_name || transcript.metadata.transcript_schedule?.radio_station || '';
  }

  if(formattedTranscript.transcriptType === 'youtube') {
    formattedTranscript.sourceUrl = transcript.metadata.source_url;
    formattedTranscript.isErrored = transcript.metadata.is_errored || false;

  }

  if(formattedTranscript.transcriptType === 'podcast') {
    formattedTranscript.startTime =  transcript.metadata.start_time;
    formattedTranscript.sourceUrl = transcript.metadata.source_url;
    formattedTranscript.isErrored = transcript.metadata.is_errored || false;

  }

  return formattedTranscript;
}

export default function transcriptState(state = initialState.transcriptState, action) {
  switch (action.type) {
    case `${transcriptConstants.GET_ALL_TRANSCRIPTS}_SUCCESS`: {
      const hasNextPage = action.response.transcripts.length === ALL_TRANSCRIPTS_PAGE_SIZE;
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          all: {
            hasNextPage: hasNextPage,
            nextPageOffset: ALL_TRANSCRIPTS_PAGE_SIZE,
            items: action.response.transcripts.map((t) => formatTranscript(t))
          }
        }
      };
    }
    case `${transcriptConstants.GET_BLOCK_TRANSCRIPTS}_SUCCESS`: {
      const hasNextPage = action.response.transcripts.length === TYPE_TRANSCRIPTS_PAGE_SIZE;
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          block: {
            hasNextPage: hasNextPage,
            nextPageOffset: TYPE_TRANSCRIPTS_PAGE_SIZE,
            items: action.response.transcripts.map((t) => formatTranscript(t))
          }
        }
      };
    }
    case `${transcriptConstants.GET_CHANNEL_TRANSCRIPTS}_SUCCESS`: {
      const hasNextPage = action.response.transcripts.length === TYPE_TRANSCRIPTS_PAGE_SIZE;
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          channel: {
            hasNextPage: hasNextPage,
            nextPageOffset: TYPE_TRANSCRIPTS_PAGE_SIZE,
            items: action.response.transcripts.map((t) => formatTranscript(t))
          }
        }
      };
    }
    case `${transcriptConstants.GET_ADHOC_TRANSCRIPTS}_SUCCESS`: {
      const hasNextPage = action.response.transcripts.length === TYPE_TRANSCRIPTS_PAGE_SIZE;
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          adhoc: {
            hasNextPage: hasNextPage,
            nextPageOffset: TYPE_TRANSCRIPTS_PAGE_SIZE,
            items: action.response.transcripts.map((t) => formatTranscript(t))
          }
        }
      };
    }
    case `${transcriptConstants.GET_YOUTUBE_TRANSCRIPTS}_SUCCESS`: {
      const hasNextPage = action.response.transcripts.length === TYPE_TRANSCRIPTS_PAGE_SIZE;
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          youtube: {
            hasNextPage: hasNextPage,
            nextPageOffset: TYPE_TRANSCRIPTS_PAGE_SIZE,
            items: action.response.transcripts.map((t) => formatTranscript(t))
          }
        }
      };
    }
    case  `${transcriptConstants.GET_RADIO_TRANSCRIPTS}_SUCCESS`: {
      const hasNextPage = action.response.transcripts.length === TYPE_TRANSCRIPTS_PAGE_SIZE;
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          radio: {
            hasNextPage: hasNextPage,
            nextPageOffset: TYPE_TRANSCRIPTS_PAGE_SIZE,
            items: action.response.transcripts.map((t) => formatTranscript(t))
          }
        }
      };
    }
    case `${transcriptConstants.GET_PODCAST_TRANSCRIPTS}_SUCCESS`: {
      const hasNextPage = action.response.transcripts.length === TYPE_TRANSCRIPTS_PAGE_SIZE;
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          podcast: {
            hasNextPage: hasNextPage,
            nextPageOffset: TYPE_TRANSCRIPTS_PAGE_SIZE,
            items: action.response.transcripts.map((t) => formatTranscript(t))
          }
        }
      };
    }
    case `${transcriptConstants.GET_CHANNEL_TRANSCRIPT}_SUCCESS`:
      return {
        ...state,
        details: formatTranscript(action.response, 'channel')
      };
    case `${transcriptConstants.GET_BLOCK_TRANSCRIPT}_SUCCESS`:
      return {
        ...state,
        details: formatTranscript(action.response, 'block')
      };
    case `${transcriptConstants.GET_RADIO_TRANSCRIPT}_SUCCESS`:
      return {
        ...state,
        details: {
          ...formatTranscript(action.response, 'radio'),
          mediaUrl: state.details.mediaUrl || ''
        }
      };
    case `${transcriptConstants.GET_ADHOC_TRANSCRIPT}_SUCCESS`:
      return {
        ...state,
        details: formatTranscript(action.response, 'adhoc')
      };
    case `${transcriptConstants.GET_YOUTUBE_TRANSCRIPT}_SUCCESS`:
      return {
        ...state,
        details: formatTranscript(action.response, 'youtube')
      };
    case `${transcriptConstants.GET_PODCAST_TRANSCRIPT}_SUCCESS`:
      return {
        ...state,
        details: {
          ...formatTranscript(action.response, 'podcast'),
          mediaUrl: state.details.mediaUrl || ''
        }
      };
    case `${transcriptConstants.GET_RADIO_TRANSCRIPT_URL}_SUCCESS`:
      return {
        ...state,
        details: {
          ...state.details,
          mediaUrl:  action.response.source_url
        }
      };
    case `${transcriptConstants.GET_PODCAST_TRANSCRIPT_URL}_SUCCESS`:
      return {
        ...state,
        details: {
          ...state.details,
          mediaUrl:  action.response.source_url
        }
      };
    case `${transcriptConstants.GET_CHANNEL_TRANSCRIPT_SENTENCES}_PENDING`:
      return {
        ...state,
        sentences: {
          ...state.sentences,
          requestState: 'pending'
        }
      };

    case `${transcriptConstants.GET_CHANNEL_TRANSCRIPT_SENTENCES}_FAILURE`:
      return {
        ...state,
        sentences: {
          ...state.sentences,
          requestState: 'failure'
        }
      };

    case transcriptConstants.LOAD_TRANSCRIPT_SENTENCES: {
      let allSentenceIds  = state.sentences.all.slice();
      let sentencesById = {
        ...state.sentences.byId
      };
      let newSentenceIds = [];
      let sentencesByClaims = {};
      let claimIds = [];
      let totalMatchCount = 0;
      let totalStatsCount = 0;
      let statsCheckBySentenceId = {};
      
      action.response.transcript_sentences.forEach((sentence, i) => {
        if(!sentencesById[sentence.transcript_sentence_id]){
          sentencesById[sentence.transcript_sentence_id] = {
            ...sentence
          };
        }

        if(sentence.enrichments) {
          if(!sentencesById[sentence.transcript_sentence_id].enrichments) {
            sentencesById[sentence.transcript_sentence_id].enrichments = {};
          }

          sentencesById[sentence.transcript_sentence_id].enrichments.matched_claims = sentence.enrichments.matched_claim_ids.map(
            (trackedClaimid) => {
              let filteredClaim = action.response.enrichment_data.matched_claims.filter((claim) => claim.tracked_claim_id === trackedClaimid)[0];
              return filteredClaim;
            }
          );
          delete sentencesById[sentence.transcript_sentence_id].enrichments.matched_claim_ids;

          if(sentencesById[sentence.transcript_sentence_id].enrichments.matched_claims.length > 0) {
            totalMatchCount += sentencesById[sentence.transcript_sentence_id].enrichments.matched_claims.length;

            sentencesById[sentence.transcript_sentence_id].enrichments.matched_claims.forEach((match) => {
              if(!sentencesByClaims[match.tracked_claim_id]) {
                sentencesByClaims[match.tracked_claim_id] = {
                  claim: match.claim,
                  sentences: []
                };
                claimIds.push(match.tracked_claim_id);
              }
              sentencesByClaims[match.tracked_claim_id].sentences.push({
                sentenceText: sentence.sentence_text,
                sentenceId: sentence.transcript_sentence_id,
                type: 'matched'
              });
            });
          }
          if(sentencesById[sentence.transcript_sentence_id].enrichments.stats_checks && sentencesById[sentence.transcript_sentence_id].enrichments.stats_checks.length) {
            totalStatsCount += sentencesById[sentence.transcript_sentence_id].enrichments.stats_checks.length;
            statsCheckBySentenceId[sentence.transcript_sentence_id] = {
              sentenceText: sentencesById[sentence.transcript_sentence_id].sentence_text,
              statsText: sentencesById[sentence.transcript_sentence_id].enrichments.stats_checks.map(stats => stats.short_msg || stats.topic).join(', '),
              type: 'stats'
            };
          }
        }


        if(sentence.metadata?.relative_time_seconds) {
          sentencesById[sentence.transcript_sentence_id].metadata.time_offset_ms =  sentence.metadata.relative_time_seconds * 1000;
        } else if( sentence.metadata?.relative_time_seconds === 0) {
          sentencesById[sentence.transcript_sentence_id].metadata.time_offset_ms =  sentence.metadata.relative_time_seconds
        }

        newSentenceIds.push(sentence.transcript_sentence_id);
        
      });

      allSentenceIds = newSentenceIds;

      return {
        ...state,
        sentences: {
          byId: sentencesById,
          all: allSentenceIds,
          requestState: 'success'
        },
        matches: {
          sentencesByClaims: sentencesByClaims,
          statsChecks: statsCheckBySentenceId,
          claimIds: claimIds,
          totalMatchCount: totalMatchCount + totalStatsCount,
          totalStatsCount: totalStatsCount
        }
      };
    }
    case transcriptConstants.RECEIVED_SENTENCE: {
      let allSentenceIds = [
        ...state.sentences.all
      ];

      let sentencesById = {
        ...state.sentences.byId
      };

      if(action.sentence.metadata?.relative_time_seconds) {
        action.sentence.metadata.time_offset_ms =  action.sentence.metadata.relative_time_seconds * 1000;
      } else if(action.sentence.metadata?.relative_time_seconds === 0) {
        sentencesById[action.sentence.transcript_sentence_id].metadata.time_offset_ms =  action.sentence.metadata.relative_time_seconds
      }

      if(!sentencesById[action.sentence.transcript_sentence_id]) {
        sentencesById[action.sentence.transcript_sentence_id] = {
          ...action.sentence
        };
      } else {
        sentencesById = {
          ...state.sentences.byId,
          [action.sentence.transcript_sentence_id] : {
            ...action.sentence
          }
        };
      }

      if(allSentenceIds.indexOf(action.sentence.transcript_sentence_id) === -1) {
        allSentenceIds.push(action.sentence.transcript_sentence_id);
      }




      // Swap out the last sub?
      let lastSub = allSentenceIds[allSentenceIds.length - 1];
      if (lastSub && (lastSub == action.sentence.transcript_sentence_id)) {
        allSentenceIds[allSentenceIds.length - 1] = action.sentence.transcript_sentence_id;
      } else {
        // No, push the new one
        allSentenceIds.push(action.sentence.transcript_sentence_id);
      }

      return {
        ...state,
        sentences : {
          ...state.sentences,
          byId: sentencesById,
          all: allSentenceIds

        }
      };
    }
    case transcriptConstants.MODIFICATION_HIGHLIGHT_SENTENCE:
      return {
        ...state,
        sentences: {
          ...state.sentences,
          byId: {
            ...state.sentences.byId,
            [action.sentenceId] : {
              ...state.sentences.byId[action.sentenceId],
              is_highlighted: true
            }
          }
        }
      };

    case transcriptConstants.MODIFICATION_UNHIGHLIGHT_SENTENCE:
      return {
        ...state,
        sentences: {
          ...state.sentences,
          byId: {
            ...state.sentences.byId,
            [action.sentenceId] : {
              ...state.sentences.byId[action.sentenceId],
              is_highlighted: false
            }
          }
        }
      };
    
    case transcriptConstants.MODIFICATION_NAME_CHANGE:
      return {
        ...state,
        details: {
          ...state.details,
          transcriptName: action.name
        }
      };

    case transcriptConstants.MODIFICATION_METADATA_CHANGE:
      return {
        ...state,
        details: {
          ...state.details,
          isErrored: action.isErrored
        }
      };
      
    case transcriptConstants.MODIFICATION_ENRICHMENT:
      let summaryMatches = {
        ...state.matches
      };
      let statsCheckBySentence = {};
      
      if(action.enrichment.matched_claims.length) {
        action.enrichment.matched_claims.forEach((claim) => {
          if(!summaryMatches.claimIds.includes(claim.tracked_claim_id)) {
            summaryMatches.claimIds.push(claim.tracked_claim_id);
            summaryMatches.sentencesByClaims[claim.tracked_claim_id] = {
              claim: claim.claim,
              sentences: []
            };
          }
          let matchingEnrichedSentences = summaryMatches.sentencesByClaims[claim.tracked_claim_id].sentences.filter((stc) => stc.sentenceId === action.enrichment.sentence_id);
          if(!matchingEnrichedSentences.length) {
            summaryMatches.totalMatchCount++;
            summaryMatches.sentencesByClaims[claim.tracked_claim_id].sentences.push({
              sentenceId: action.enrichment.sentence_id,
              sentenceText: state.sentences.byId[action.enrichment.sentence_id].sentence_text
            });
          }
        });
      }
      
      if(action.enrichment.stats_checks && action.enrichment.stats_checks.length && !summaryMatches.statsChecks[action.enrichment.sentence_id]) {
          summaryMatches.totalMatchCount += action.enrichment.stats_checks.length;
          statsCheckBySentence[action.enrichment.sentence_id] = action.enrichment.stats_checks.map(stats => stats.short_msg || stats.topic);
          summaryMatches.statsChecks[action.enrichment.sentence_id] = {
            sentenceText: state.sentences.byId[action.enrichment.sentence_id].sentence_text,
            statsText: action.enrichment.stats_checks.map(stats => stats.short_msg || stats.topic).join(', ')
          };
      }

      return {
          ...state,
          sentences: {
            ...state.sentences,
            byId: {
              ...state.sentences.byId,
              [action.enrichment.sentence_id] : {
                ...state.sentences.byId[action.enrichment.sentence_id],
              enrichments: {
                detected_claims: action.enrichment.detected_claims,
                matched_claims: action.enrichment.matched_claims,
                stats_checks: action.enrichment.stats_checks || []
              }
            }
          }
        },
        matches: summaryMatches
      };
      
    case transcriptConstants.CLEAR_TRANSCRIPT:
      return {
        ...initialState.transcriptState
      };
      

    case  `${transcriptConstants.GET_SCHEDULE_TRANSCRIPTS}_SUCCESS`: {
      const hasNextPage = action.response.transcripts.length === TYPE_TRANSCRIPTS_PAGE_SIZE;
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          radio: {
            hasNextPage: hasNextPage,
            nextPageOffset: TYPE_TRANSCRIPTS_PAGE_SIZE,
            requestState: 'success',
            items: action.response.transcripts.map((t) => formatTranscript(t, 'radio'))
          }
        }
      };
    }


    case  `${transcriptConstants.GET_LATEST_TRANSCRIPT_SCHEDULES}_SUCCESS`: {
      let enrichedSchedules = {
        ...state.schedules.byId
      };
      state.schedules.all.forEach((uuid) => {
       let matchedSchedules = action.response.transcripts.filter(t => t.metadata.schedule_uuid === uuid);
       
       if(matchedSchedules.length === 1){
        enrichedSchedules[uuid].recent_transcripts = [formatTranscript(matchedSchedules[0], 'radio')];
       } else {
        enrichedSchedules[uuid].recent_transcripts = [];
       }
      })

      return {
        ...state,
        schedules: {
          ...state.schedules,
          byId: {
            ...enrichedSchedules
          }
        }
      };
    }

    case `${transcriptConstants.GET_MORE_SCHEDULE_TRANSCRIPTS}_PENDING`: {
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          radio: { 
            ...state.transcripts.radio,
            hasNextPage: false,
            requestState: 'pending'
          }
        }
      };
    }
    case `${transcriptConstants.GET_MORE_SCHEDULE_TRANSCRIPTS}_FAILURE`: {
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          radio: { 
            ...state.transcripts.radio,
            hasNextPage: false,
            requestState: 'failure'
          }
        }
      };
    }

    case  `${transcriptConstants.GET_MORE_SCHEDULE_TRANSCRIPTS}_SUCCESS`: {
      const hasNextPage = action.response.transcripts.length === TYPE_TRANSCRIPTS_PAGE_SIZE;
      const offset = state.transcripts.offset + TYPE_TRANSCRIPTS_PAGE_SIZE;
      const moreTranscripts = action.response.transcripts.map((t) => formatTranscript(t, 'radio'));
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          radio: {   
            hasNextPage: hasNextPage,
            nextPageOffset: offset,
            requestState: 'success',
            items: [
              ...state.transcripts.radio.items,
              ...moreTranscripts
            ]
          }
        }
      };
    }
    case  `${transcriptConstants.GET_TRANSCRIPT_SCHEDULES}_SUCCESS`: {
      let scheduleIds = Object.keys(action.response);

      scheduleIds.sort((a,b) => {
        return action.response[b].created_at.localeCompare(action.response[a].created_at)
      })

      return {
        ...state,
        schedules: {
          all: scheduleIds,
          byId: {...action.response}
        }
      };
    }

    case  `${transcriptConstants.GET_TRANSCRIPT_SCHEDULE}_SUCCESS`: {
      return {
        ...state,
        schedules: {
          all: [action.response.uuid],
          byId: {
            [action.response.uuid] : {...action.response}
          }
        }
      };
    }

    case  `${transcriptConstants.CLOSE_TRANSCRIPT}`:
      return {
        ...state,
        details: {
          ...state.details,
          isOpen: false,
        }
      };

    case  `${transcriptConstants.STOP_CHANNEL_TRANSCRIPT}_SUCCESS`:
      return {
        ...state,
        details: {
          ...state.details,
          isOpen: false,
        }
      };
    case  `${transcriptConstants.GET_TRANSCRIPT_RADIO_STATIONS}_SUCCESS`:
      let radioPubIds = [];
      let radioPubs = {};
      
      action.response.publications.forEach((pub) => {
        if(pub.media_feed.category === 'radio'){
          radioPubIds.push(pub.publication_id);
          radioPubs[pub.publication_id] = { 
            display_name: pub.publication_name,
            media_code: pub.media_feed.media_code 
          };
        }
      });
      
      return {
        ...state,
        radioStations: {
          all: radioPubIds,
          byId: radioPubs
        }
      };

    case `${transcriptConstants.CREATE_BLOCK_TRANSCRIPT}_PENDING`:
      return {
        ...state,
        transcript: {
          requestState: 'pending'
        }
      };
    case `${transcriptConstants.CREATE_BLOCK_TRANSCRIPT}_SUCCESS`:
      return {
        ...state,
        transcript: {
          requestState: 'success'
        }
      };
    case `${transcriptConstants.CREATE_BLOCK_TRANSCRIPT}_FAILURE`:
      return {
        ...state,
        transcript: {
          requestState: 'failure'
        }
      };
    case `${transcriptConstants.CREATE_CHANNEL_TRANSCRIPT}_PENDING`:
      return {
        ...state,
        transcript: {
          requestState: 'pending'
        }
      };
    case `${transcriptConstants.CREATE_CHANNEL_TRANSCRIPT}_SUCCESS`:
      return {
        ...state,
        transcript: {
          requestState: 'success'
        }
      };
    case `${transcriptConstants.CREATE_CHANNEL_TRANSCRIPT}_FAILURE`:
      return {
        ...state,
        transcript: {
          requestState: 'failure'
        }
      };
    case `${transcriptConstants.CREATE_YOUTUBE_TRANSCRIPT}_PENDING`:
      return {
        ...state,
        transcript: {
          requestState: 'pending'
        }
      };
    case `${transcriptConstants.CREATE_YOUTUBE_TRANSCRIPT}_SUCCESS`:
      return {
        ...state,
        transcript: {
          requestState: 'success'
        }
      };
    case `${transcriptConstants.CREATE_YOUTUBE_TRANSCRIPT}_FAILURE`:
      return {
        ...state,
        transcript: {
          requestState: 'failure'
        }
      };
    case `${transcriptConstants.CREATE_RADIO_TRANSCRIPT}_PENDING`:
      return {
        ...state,
        transcript: {
          requestState: 'pending'
        }
      };
    case `${transcriptConstants.CREATE_RADIO_TRANSCRIPT}_SUCCESS`:
      return {
        ...state,
        transcript: {
          requestState: 'success'
        }
      };
    case `${transcriptConstants.CREATE_RADIO_TRANSCRIPT}_FAILURE`:
      return {
        ...state,
        transcript: {
          requestState: 'failure'
        }
      };
    case transcriptConstants.START_EDITING_SCHEDULE:
      return {
          ...state,
          editingSchedule: true
      };

    case transcriptConstants.STOP_EDITING_SCHEDULE:
      return {
          ...state,
          editingSchedule: false
      };

    case `${transcriptConstants.GET_MORE_TRANSCRIPTS}_PENDING`: {
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          all: { 
            ...state.transcripts.all,
            hasNextPage: false,
            requestState: 'pending'
          }
        }
      };
    }
    case `${transcriptConstants.GET_MORE_TRANSCRIPTS}_FAILURE`: {
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          all: { 
            ...state.transcripts.all,
            hasNextPage: false,
            requestState: 'failure'
          }
        }
      };
    }
    case `${transcriptConstants.GET_MORE_TRANSCRIPTS}_SUCCESS`: {
      const hasNextPage = action.response.transcripts.length === ALL_TRANSCRIPTS_PAGE_SIZE;
      const offset = state.transcripts.offset + ALL_TRANSCRIPTS_PAGE_SIZE;
      const moreTranscripts = action.response.transcripts.map((t) => formatTranscript(t));
      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          all: {   
            hasNextPage: hasNextPage,
            nextPageOffset: offset,
            requestState: 'success',
            items: [
              ...state.transcripts.all.items,
              ...moreTranscripts
            ]
          }
        }
      }
    }
    case `${transcriptConstants.SET_TRANSCRIPT_TIME}`: {
      return {
        ...state,
        transcriptTime: action.data
      };
    }
    case `${transcriptConstants.SET_LIVE_LANDING_VIEW}`: {
      return {
        ...state,
        liveLandingView: action.data
      };
    }

    
    default:
      return state;
  }
}
