import React from 'react';
import PropTypes from 'prop-types';
import VirtualizedTable from './VirtualizedTable';

export default function EnhancedTable(props) {
  const { height, fixedWidth, columns } = props;

  const [sortBy, setSortBy] = React.useState();
  const [sortDirection, setSortDirection] = React.useState('');

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSortingFunction(order, orderBy) {
    return order === 'DESC' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  function setSortFields(orderBy, order) {
    setSortDirection(order);
    if(order === '') {
      setSortBy('');
    } else {
      setSortBy(orderBy);
    }
  }
  if(props.rows && props.rows.length) {
    let rows = props.rows;

    rows = stableSort(rows, getSortingFunction(sortDirection, sortBy));

    return (
      <VirtualizedTable
        sortBy={sortBy}
        sortDirection={sortDirection}
        rowCount={rows.length}
        rowGetter={({ index }) => rows[index]}
        setSortFields={setSortFields}
        height={height}
        fixedWidth={fixedWidth}
        columns={columns}
      />
    );
  } else {
    return null;
  }
    
}

EnhancedTable.propTypes = {
  rows: PropTypes.array,
  height: PropTypes.string,
  columns: PropTypes.array,
  fixedWidth: PropTypes.number
};
