export interface ApiClaimType {
    claim: string;
    conclusion: string;
    created_at: string;
    external_claim_id: string;
    factcheck_url: string;
    language: string;
    starred: false;
    tracked_claim_id: number;
    updated_at: string;
    claim_review: {
        "@context": string;
        "@id": string;
        "@type": string;
        author: {
            "@type": string;
            logo: {
                "@type": string;
                height: number;
                url: string;
                width: number;
            },
            name: string;
            sameAs: string;
            url: string;
        }
        keywords?: string[]; 
        claimReviewed: string;
        dateModified: string;
        datePublished: string;
        description: string
        itemReviewed: {
            "@type": string;
            appearance: [
                {
                    "@type": string;
                    author: {
                        "@type": string;
                        name: string;
                        sameAs: string;
                    }
                    datePublished: string;
                    url: string;
                }
            ]
            author: {
                "@type": string;
                name: string;
                sameAs: string;
            },
            datePublished: string;
        }
        reviewBody: string;
        reviewRating: {
            "@type": string;
            alternateName: string;
            bestRating?: number;
            ratingValue?: number;
            worstRating?: number;
        }
        url: string
    }
}

export interface ClaimType {
    claim: string
    conclusion: string
    createdAt: string
    externalClaimId?: string
    factcheckUrl: string
    starred: boolean
    trackedClaimId: number
    updatedAt: string
    authorId: number
    authorName: string
    isTpfc: boolean
}

export const Claim = {
    fromApi: (obj: ApiClaimType) => {
        const isTpfc = Boolean(obj?.claim_review?.keywords?.includes('third-party-fact-check'));
        let authorId = '';
        let authorName = '';
        if(obj?.claim_review?.itemReviewed?.author?.sameAs?.includes('https://www.wikidata.org/entity')) {
        authorName = obj.claim_review.itemReviewed.author.name;
        authorId = obj.claim_review.itemReviewed.author.sameAs.split('/').pop();
        }

        return {
            claim: obj.claim,
            conclusion: obj.conclusion,
            createdAt: obj.created_at,
            externalClaimId: obj.external_claim_id || null,
            factcheckUrl: obj.factcheck_url,
            starred: obj.starred,
            trackedClaimId: obj.tracked_claim_id,
            updatedAt: obj.updated_at,
            authorId: authorId,
            authorName: authorName,
            isTpfc: isTpfc
        }
    },
    toApi: (obj: ClaimType) => {
        return {
            claim: obj.claim,
            conclusion: obj.conclusion, 
            factcheck_url: obj.factcheckUrl
        }
    }
};

export default Claim;


