
import actionResolutionTypes from 'Config/default/actionResolutionTypes';
import actionStatuses from 'Config/default/actionStatuses';
import actionTypes from 'Config/default/actionTypes';
import channels from 'Config/default/channels';
import claimTypePriorities from 'Config/default/claimTypePriorities';
import claimTypes from 'Config/default/claimTypes';
import mediaCategories from 'Config/default/mediaCategories';
import monitoredMedia from 'Config/default/monitoredMedia';
import permissions from 'Config/default/permissions';
import sightingCategories from 'Config/default/sightingCategories';
import thresholds from 'Config/default/thresholds';

export const config = {
  uiLanguage: 'en',
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  permissions: permissions,
  mediaCategories: mediaCategories,
  claimTypes: claimTypes,
  claimTypePriorities: claimTypePriorities,
  sightingCategories: sightingCategories,
  channels: channels,
  actionTypes: actionTypes,
  actionResolutionTypes: actionResolutionTypes,
  actionStatuses: actionStatuses,
  monitoredMedia: monitoredMedia,
  thresholds: thresholds,
  hasTPFC: false,
  canChangeLanguage: false,
  canChangeTheme: false,
  checkworthyTopics: [],
  latestTranscripts: [
    { code: 'channel'},
    { code: 'block' },
    { code: 'youtube' },
    { code: 'radio' },
    { code: 'podcast' },
    { code: 'adhoc' }
  ],
  transcript: {
    schedulePeriods: {
      daily_not_weekend: {
        display: 'Daily Not Weekend',
      },
      weekly: {
        display: 'Weekly',
      }
    }
  },
  rtlLanguages: ['ar','he','fa','ur','ks','ps','ug','sd']
};

export default config;
