import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import TranscriptScheduleDays from 'Components/Transcript/TranscriptScheduleDays';


export function TranscriptScheduleDetails(props){
    const { 
      details, 
      t,
      classes
    } = props;

    return (
        <div className="col-full-row">
            <div>
                <Typography variant="h3" component="h3" className={classes.scheduleTranscriptsHeader}>
                    {t('transcript_schedule_details.field.schedule')} {details.display_name}
                </Typography>
            </div>
            <Card className={classes.mainContent}  variant="outlined">
            <Typography variant="subtitle2" color="textSecondary">
                {t('transcript_schedule_details.field.source')} {details.displayRadioStation}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
                {t('transcript_schedule_details.field.time')} {details.start_time} - {details.end_time}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
                {t('transcript_schedule_details.field.timezone')} {details.timezone}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
                {t('transcript_schedule_details.field.days')} <TranscriptScheduleDays days={details.days} />
            </Typography>
            {details.created_by_name && details.created_at && (
                <Typography variant="subtitle2" color="textSecondary">
                    {t('transcript_schedule_details.field.created_by')} {details.created_by_name} on {details.created_at.split('.')[0].replace('T', ' ')}
                </Typography>
            )}
            {details.updated_by_name && details.updated_at && details.created_at !== details.updated_at && (
                <Typography variant="subtitle2" color="textSecondary">
                {t('transcript_schedule_details.field.last_updated')} {details.updated_at.split('.')[0].replace('T', ' ')}
                </Typography>
            )}
            {details.next_expected_transcript_time && (
                <Typography variant="subtitle2" color="textSecondary">
                {t('transcript_schedule_details.next_transcript', { time: moment(details.next_expected_transcript_time).format('YYYY-MM-DD HH:mm')})}
                </Typography>
            )}
            </Card>
            <p><Link to={'/media/schedules'} className={classes.schedulesLink}>{t('transcript_schedule_details.back_to_all_schedules')}</Link></p>
        </div>
    );
}

TranscriptScheduleDetails.propTypes = {
    details: PropTypes.object,
    t: PropTypes.func,
    classes: PropTypes.object
};


export default compose(
  withTranslation(),
  withStyles(() => ({
    scheduleTranscriptsHeader: {
        marginTop: '24px',
        marginBottom: '20px'
    },
    mainContent: {
        padding: '12px',
        '& h6': {
          marginBottom: '4px'
        }
      },
    schedulesLink: {
        color: '#009688'
    }
  }))
)(TranscriptScheduleDetails);
