import transcriptConstants from '../constants/transcriptConstants';
import * as utils from '../utils';

// BLOCK TRANSCRIPTS
export function createBlockTranscript(data){
  return {
    type: transcriptConstants.CREATE_BLOCK_TRANSCRIPT,
    transcript: {
      transcript_name : data.name,
      text: data.text
    },
    transcriptType: 'block'
  };
}


export function getBlockTranscripts(){
  return { 
    type: transcriptConstants.GET_BLOCK_TRANSCRIPTS,
    transcriptType: 'block'
  };
}

export function getBlockTranscript(id){
  return {
    type: transcriptConstants.GET_BLOCK_TRANSCRIPT,
    id: id,
    transcriptType: 'block'
  };
}

export function getBlockTranscriptSentences(id){
  return {
    type: transcriptConstants.GET_BLOCK_TRANSCRIPT_SENTENCES,
    id: id,
    transcriptType: 'block'
  };
}

export function updateBlockTranscriptName(transcriptId, name){
  return {
    type: transcriptConstants.UPDATE_BLOCK_TRANSCRIPT_NAME,
    transcriptId: transcriptId,
    name: name,
    transcriptType: 'block'
  };
}

// CHANNEL TRANSCRIPTS
export function createChannelTranscript(data){
  let formattedData = {
    transcript_name : data.name,
    is_live: data.isLive || false,
    channel_name: data.source
  };
  if(data.startDate) {
    formattedData['start_time'] = data.startDate;
  }
  if(data.endDate) {
    formattedData['end_time'] = data.endDate;
  }
  return {
    type: transcriptConstants.CREATE_CHANNEL_TRANSCRIPT,
    transcript: formattedData,
    transcriptType: data.type,
  };
}

export function getChannelTranscripts(){
  return { 
    type: transcriptConstants.GET_CHANNEL_TRANSCRIPTS,
    transcriptType: 'channel'
  };
}

export function getChannelTranscript(id){
  return {
    type: transcriptConstants.GET_CHANNEL_TRANSCRIPT,
    id: id,
    transcriptType: 'channel'
  };
}

export function getChannelTranscriptSentences(transcriptId, sentenceId){
  return {
    type: transcriptConstants.GET_CHANNEL_TRANSCRIPT_SENTENCES,
    id: transcriptId,
    sentenceId: sentenceId,
    transcriptType: 'channel'
  };
}
export function deleteTranscript(id, type) {
  return {
    type: transcriptConstants['DELETE_'+type.toUpperCase()+'_TRANSCRIPT'],
    id: id,
    transcriptType: type
  };
}

export function stopChannelTranscript(id, time){
  return {
    type: transcriptConstants.STOP_CHANNEL_TRANSCRIPT,
    id: id,
    time: time,
    transcriptType: 'channel'
  };
}

export function updateChannelTranscriptName(transcriptId, name){
  return {
    type: transcriptConstants.UPDATE_CHANNEL_TRANSCRIPT_NAME,
    transcriptId: transcriptId,
    name: name,
    transcriptType: 'channel'
  };
}


// ADHOC TRANSCRIPTS
export function getAdhocTranscripts(){
  return { 
    type: transcriptConstants.GET_ADHOC_TRANSCRIPTS,
    transcriptType: 'adhoc'
  };
}

export function getAdhocTranscript(id){
  return {
    type: 'GET_ADHOC_TRANSCRIPT',
    id: id,
    transcriptType: 'adhoc'
  };
}

export function getAdhocTranscriptSentences(id){
  return {
    type: 'GET_ADHOC_TRANSCRIPT_SENTENCES',
    id: id,
    transcriptType: 'adhoc'
  };
}

export function stopAdhocTranscript(id, time){
  return {
    type: transcriptConstants.STOP_ADHOC_TRANSCRIPT,
    id: id,
    time: time,
    transcriptType: 'adhoc'
  };
}

export function updateAdhocTranscriptName(transcriptId, name){
  return {
    type: transcriptConstants.UPDATE_ADHOC_TRANSCRIPT_NAME,
    transcriptId: transcriptId,
    name: name,
    transcriptType: 'adhoc'
  };
}


// RADIO TRANSCRIPTS
export function createRadioTranscript(data){
  return {
    type: transcriptConstants.CREATE_RADIO_TRANSCRIPT,
    transcript: {
      transcript_name : data.name,
      media_code: data.source,
      start_time: data.startDate,
      end_time: data.endDate,
    },
    transcriptType: 'radio_v2'
  };
}

export function getRadioTranscripts(){
  return {
    type: transcriptConstants.GET_RADIO_TRANSCRIPTS,
    transcriptType: 'radio_v2'
  };
}

export function getRadioTranscriptSentences(id){
  return {
    type: transcriptConstants.GET_RADIO_TRANSCRIPT_SENTENCES,
    id: id,
    transcriptType: 'radio_v2'
  };
}

export function getRadioTranscript(id){
  return {
    type: transcriptConstants.GET_RADIO_TRANSCRIPT,
    id: id,
    transcriptType: 'radio_v2'
  };
}

export function getRadioStations(){
  return {
    type: transcriptConstants.GET_TRANSCRIPT_RADIO_STATIONS
  };
}

export function createSchedule(schedule) {
  return {
    type: transcriptConstants.CREATE_RADIO_TRANSCRIPT_SCHEDULE,
    schedule: schedule
  };
}

export function updateSchedule(schedule) {
  return {
    type: transcriptConstants.UPDATE_TRANSCRIPT_SCHEDULE,
    data: schedule
  };
}

export function deleteSchedule(scheduleId){
  return {
    type: transcriptConstants.DELETE_TRANSCRIPT_SCHEDULE,
    data: scheduleId
  };
}

export function getSchedules(){
  return {
    type: transcriptConstants.GET_TRANSCRIPT_SCHEDULES
  };
}

export function getSchedule(uuid){
  return {
    type: transcriptConstants.GET_TRANSCRIPT_SCHEDULE,
    data: uuid
  };
}

export function setTranscriptTime(time){
  return {
    type: transcriptConstants.SET_TRANSCRIPT_TIME,
    data: time
  };
}

// YOUTUBE TRANSCRIPTS
export function createYoutubeTranscript(data){
  return {
    type: transcriptConstants.CREATE_YOUTUBE_TRANSCRIPT,
    transcript: {
      transcript_name : data.name,
      source_url: data.url,
      language: data.locale
    },
    transcriptType: 'youtube'
  };
}
export function getYoutubeTranscripts(){
  return { 
    type: transcriptConstants.GET_YOUTUBE_TRANSCRIPTS,
    transcriptType: 'youtube'
  };
}

export function getYoutubeTranscript(id){
  return {
    type: transcriptConstants.GET_YOUTUBE_TRANSCRIPT,
    id: id,
    transcriptType: 'youtube'
  };
}

export function getRadioTranscriptUrl(id){
  return {
    type: transcriptConstants.GET_RADIO_TRANSCRIPT_URL,
    id: id,
    transcriptType: 'radio_v2'
  };
}

export function getYoutubeTranscriptSentences(transcriptId, sentenceId){
  return {
    type: transcriptConstants.GET_YOUTUBE_TRANSCRIPT_SENTENCES,
    id: transcriptId,
    sentenceId: sentenceId,
    transcriptType: 'youtube'
  };
}

export function stopYoutubeTranscript(id, time){
  return {
    type: transcriptConstants.STOP_YOUTUBE_TRANSCRIPT,
    id: id,
    time: time,
    transcriptType: 'youtube'
  };
}

export function updateYoutubeTranscriptName(transcriptId, name){
  return {
    type: transcriptConstants.UPDATE_YOUTUBE_TRANSCRIPT_NAME,
    transcriptId: transcriptId,
    name: name,
    transcriptType: 'youtube'
  };
}

export function getTranscripts(type){
  return {
    type: transcriptConstants['GET_'+type.toUpperCase()+'_TRANSCRIPTS'],
    transcriptType: type
  };
}


export function getMoreTranscripts(page){
  return { 
    type: transcriptConstants.GET_MORE_TRANSCRIPTS,
    data: {
      page: page
    }
  };
}

export function sentenceArrived(sentence, isInterim) {
  sentence = JSON.parse(sentence);
  sentence = utils.transformSentence(sentence);
  return {
    type: transcriptConstants.RECEIVED_SENTENCE,
    sentence: sentence,
    interim: isInterim
  };
}

export function modificationArrived(changeEvent) {
  const payload = changeEvent.data || {};
  switch (changeEvent.change_type) {
    case 'highlight_sentence':
      return {
        type: transcriptConstants.MODIFICATION_HIGHLIGHT_SENTENCE,
        sentenceId: payload.sentence_id
      };
    case 'unhighlight_sentence':
      return {
        type: transcriptConstants.MODIFICATION_UNHIGHLIGHT_SENTENCE,
        sentenceId: payload.sentence_id
      };
    case 'sentence_enriched':
      return {
        type: transcriptConstants.MODIFICATION_ENRICHMENT,
        enrichment: payload
      };
    case 'transcript_name_change':
      return {
        type: transcriptConstants.MODIFICATION_NAME_CHANGE,
        name: payload.new_title
      };
    case 'transcript_metadata_update':
      return {
        type: transcriptConstants.MODIFICATION_METADATA_CHANGE,
        isErrored: payload.is_errored || false
      };
    default:
        return {
          type: transcriptConstants.MODIFICATION_UNKNOWN
        };
  }
}

export function changeTranscriptToStopped(id){
  return {
    type: transcriptConstants.MODIFICATION_STOP_TRANSCRIPT,
    request: {
      id: id
    }
  };
}

export function highlightSentence(transcriptType, transcriptId, sentenceId){
  return {
    type: transcriptConstants.HIGHLIGHT_SENTENCE,
    transcriptId: transcriptId,
    sentenceId: sentenceId,
    transcriptType: transcriptType
  };
}

export function unhighlightSentence(transcriptType, transcriptId, sentenceId){
  return {
    type: transcriptConstants.UNHIGHLIGHT_SENTENCE,
    transcriptId: transcriptId,
    sentenceId: sentenceId,
    transcriptType: transcriptType
  };
}

export function clearTranscript(){
  return {
    type: transcriptConstants.CLEAR_TRANSCRIPT
  };
}

export function sendTranscriptTiming(){
  return {
    type: 'NO_LIVE_TRANSCRIPT_SENTENCES_FAILURE'
  };
}

export function startEditingSchedule() {
  return {
    type: transcriptConstants.START_EDITING_SCHEDULE,
  };
}

export function stopEditingSchedule() {
  return {
    type: transcriptConstants.STOP_EDITING_SCHEDULE,
  };
}

export function getScheduleTranscripts(scheduleId) {
  return {
    type: transcriptConstants.GET_SCHEDULE_TRANSCRIPTS,
    id: scheduleId
  };
}

export function getMoreScheduleTranscripts(scheduleId, page) {
  return {
    type: transcriptConstants.GET_MORE_SCHEDULE_TRANSCRIPTS,
    id: scheduleId,
    page: page
  };
}

export function getLatestTranscriptSchedules() {
  return {
    type: transcriptConstants.GET_LATEST_TRANSCRIPT_SCHEDULES
  };
}

export function setLiveLandingView(view){
  return { 
    type: transcriptConstants.SET_LIVE_LANDING_VIEW,
    data: view
  };
}

export function getPodcastTranscripts() {
  return { 
    type: transcriptConstants.GET_PODCAST_TRANSCRIPTS,
    transcriptType: 'podcast'
  };
}

export function getPodcastTranscript(id){
  return {
    type: transcriptConstants.GET_PODCAST_TRANSCRIPT,
    id: id,
    transcriptType: 'podcast'
  };
}

export function getPodcastTranscriptUrl(id){
  return {
    type: transcriptConstants.GET_PODCAST_TRANSCRIPT_URL,
    id: id,
    transcriptType: 'podcast'
  };
}

export function getPodcastTranscriptSentences(id){
  return {
    type: transcriptConstants.GET_PODCAST_TRANSCRIPT_SENTENCES,
    id: id,
    transcriptType: 'podcast'
  };
}

export function getAllTranscripts(type){
  return {
    type: transcriptConstants.GET_ALL_TRANSCRIPTS,
   transcriptType: type
  };
}


export default {
  getAllTranscripts,
  getTranscripts,
  sentenceArrived,
  modificationArrived,
  getBlockTranscript,
  getBlockTranscripts,
  getBlockTranscriptSentences,
  createBlockTranscript,
  updateBlockTranscriptName,
  getChannelTranscript,
  getChannelTranscripts,
  getChannelTranscriptSentences,
  createChannelTranscript,
  updateChannelTranscriptName,
  stopChannelTranscript,
  getRadioTranscript,
  getRadioTranscripts,
  getRadioTranscriptSentences,
  changeTranscriptToStopped,
  highlightSentence,
  unhighlightSentence,
  clearTranscript,
  getSchedules,
  getSchedule,
  getRadioStations,
  createSchedule,
  sendTranscriptTiming,
  getAdhocTranscript,
  getAdhocTranscriptSentences,
  getAdhocTranscripts,
  stopAdhocTranscript,
  updateAdhocTranscriptName,
  createYoutubeTranscript,
  getYoutubeTranscripts,
  getYoutubeTranscript,
  getYoutubeTranscriptSentences,
  stopYoutubeTranscript,
  updateYoutubeTranscriptName,
  startEditingSchedule,
  stopEditingSchedule,
  updateSchedule,
  deleteSchedule,
  getScheduleTranscripts,
  getMoreTranscripts,
  getMoreScheduleTranscripts,
  getLatestTranscriptSchedules,
  setTranscriptTime,
  getRadioTranscriptUrl,
  setLiveLandingView,
  getPodcastTranscripts,
  getPodcastTranscript,
  getPodcastTranscriptUrl,
  getPodcastTranscriptSentences,
  deleteTranscript,
  createRadioTranscript
};
