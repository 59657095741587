const actionResolutionTypes =  {
    article_deleted: "Article deleted",
    article_amended: "Article amended",
    partial_change: "Partial change",
    correction_made: "Correction made",
    deleted_post: "Deleted post",
    correction_note: "Correction note",
    added_clarification: "Added clarification",
    disputed_no_change: "Disputed - No change",
    disputed_wrong_change: "Disputed - Wrong change",
    awaiting_response: "Awaiting response",
    unresolved: "Unresolved",
  };
  
  export default actionResolutionTypes;
  