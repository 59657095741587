import React from 'react';
import PropTypes from 'prop-types';

import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

const AddEditDrawer = (props) => {

  const {
    isActive,
    handleToggle,
    title,
    children,
    classes
  } = props;

  return (
    <Drawer open={isActive} onClose={handleToggle} anchor="right">
    <div className={classes.addEditDrawer}>
      <div className={classes.close} onClick={handleToggle}>
        <CloseIcon />
      </div>
      <h2 className={classes.title}>{title}</h2>
      {children}
      </div>
    </Drawer>
  );
};

AddEditDrawer.propTypes  = {
  children: PropTypes.object,
  handleToggle: PropTypes.func,
  isActive: PropTypes.bool,
  title: PropTypes.string,
  classes: PropTypes.object
};

export default withStyles(() => ({
  close: {
    float: 'right',
    cursor: 'pointer',
    lineHeight: '3.5'
  },
  addEditDrawer: {
    padding: '12px',
    width: '500px'
  }
  
}))(AddEditDrawer);
