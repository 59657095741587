import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import claimActions from 'Actions/claimActions';
import claimSelectors from 'Selectors/claimSelectors';
import candidateSelectors from 'Selectors/candidateSelectors';
import interventionSelectors from 'Selectors/interventionSelectors';
import authSelectors from 'Selectors/authSelectors';
import { Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import interventionActions from 'Actions/interventionActions';
import AddEditDrawer from 'Components/Common/AddEditDrawer';
import ActionFormContainer from 'Components/Action/ActionFormContainer';
import NoticeContainer from 'Components/Common/NoticeContainer';
import DigestConfirmedSightings from 'Components/Digest/DigestConfirmedSightings';
import DigestDismissedSightings from 'Components/Digest/DigestDismissedSightings';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';

export class SightingsPage extends React.Component {

  constructor(props){
    super(props);
    props.claimActions.loadClaims();
    props.claimActions.getConfirmedSightings();
    props.claimActions.getDismissedSightings();
    props.interventionActions.loadInterventions();
    this.state = {
      claimsToExpand: [],
      tabValue: 0,
      selectedIntervention: null,
      isGettingConfirmed: false,
      isGettingDismissed: false
    };

  }

  handleTabChange = (e, newValue) => {
    this.setState({ tabValue: newValue });
  }

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };


  removeDismissedSighting = (claimId, mediaHash) =>{
    this.props.claimActions.removeDismissedSighting(claimId, mediaHash);
  }

  getMoreConfirmedSightings = () => {
    if(!this.props.isGettingConfirmedSightings) {
      this.props.claimActions.getMoreConfirmedSightings(this.props.confirmedSightingsCursor);
    }
  }

  getMoreDismissedSightings = () => {
    if(!this.props.isGettingDismissedSightings) {
      this.props.claimActions.getMoreDismissedSightings(this.props.dismissedSightingsCursor);
    }
  }

  handleCreateIntervention = (mediaSighting) => {
    this.setState({
      mediaHash: mediaSighting.media_hash,
      claimId: mediaSighting.tracked_claim_id,
      selectedIntervention: {
        tracked_claim_id: mediaSighting.tracked_claim_id,
        sighting_type: mediaSighting.sighting_type,
        author_name: mediaSighting.author_name,
        publication: mediaSighting.publication,
        publication_date:  mediaSighting.publication_date,
        url: mediaSighting.url,
        media_hash: mediaSighting.media_hash,
        text: mediaSighting.text,
        category: mediaSighting.category,
        wikidata_publication_id: mediaSighting.wikidata_publication_id
      }
    });
    this.startEditingIntervention();
  }

  stopEditingIntervention = () => {
    this.props.interventionActions.stopEditingIntervention();
  }

  startEditingIntervention = () => {
    this.props.interventionActions.startEditingIntervention();
  }

  handleInterventionPaneToggle = () => {
    if(this.props.isEditingIntervention) {
      this.setState({ selectedIntervention: null});
      this.stopEditingIntervention();
    } else {
      this.startEditingIntervention();
    }
  }

  render () {
    const {
      classes,
      t,
      permissions,
      confirmedSightings,
      confirmedSightingsCursor,
      confirmedSightingsCount,
      dismissedSightings,
      dismissedSightingsCursor,
      dismissedSightingsCount,
    } = this.props;
    const { tabValue } = this.state;

    let confirmedCount = '';
    if(confirmedSightingsCount && confirmedSightingsCount>0) confirmedCount = confirmedSightingsCount;
    let dismissedCount = '';
    if(dismissedSightingsCount && dismissedSightingsCount>0) dismissedCount = dismissedSightingsCount;

    return (
      <div className="row container">
        <div className="col-xs4-4">
        <div className={classes.content}>
          <Typography variant="h2" component="h2">{t('sightings_page.reviewed_alerts')}</Typography>
          <Link className={classes.digestLink} to="/alerts">{t('sightings_page.back_to_alerts')}</Link>
          {confirmedSightings.length !== 0 || dismissedSightings.length !== 0? (
          <Box>
            <Box className={classes.tabSelector}>
              <Tabs value={tabValue} onChange={this.handleTabChange}>
                <Tab label={t('sightings_page.confirmed_alerts', { count: confirmedCount})} />
                <Tab label={t('sightings_page.dismissed_alerts', { count: dismissedCount})} />
              </Tabs>
            </Box>

            {/* First Tab - Confirmed Alerts */}
            <Box hidden={tabValue !== 0}>
              <InfiniteScroll
                pageStart={0}
                loadMore={this.getMoreConfirmedSightings}
                hasMore={Boolean(confirmedSightingsCursor)}
                loader={<CircularProgress key="infinite-scroll-loader" />}
              >
                <DigestConfirmedSightings
                  confirmedSightings={confirmedSightings}
                  handleCreateIntervention={this.handleCreateIntervention}
                  canCreateAction={permissions.includes('actions')}
                  canViewClaim={permissions.includes('claim')}
                />
                </InfiniteScroll>
            </Box>

            {/* Second Tab - Dismissed Alerts */}
            <Box hidden={tabValue !== 1}>
              <InfiniteScroll
                pageStart={0}
                loadMore={this.getMoreDismissedSightings}
                hasMore={Boolean(dismissedSightingsCursor)}
                loader={<CircularProgress key="infinite-scroll-loader" />}
              >
                <DigestDismissedSightings
                  dismissedSightings={dismissedSightings}
                  removeMatchedSighting={this.removeDismissedSighting}
                  canViewClaim={permissions.includes('claim')}
                />
              </InfiniteScroll>
            </Box>
          </Box>
          ) : (
            <div className={classes.numberOfSightings}>
              <Typography variant="h3" component="h3">{t('sightings_page.no_reviewed_alerts')}</Typography>
            </div>
          )}
        </div>
      </div>
      <AddEditDrawer
          isActive={this.props.isEditingIntervention}
          handleToggle={this.handleInterventionPaneToggle}
          title={this.state.selectedIntervention && this.state.selectedIntervention.intervention_id ? t('sightings_page.edit_action_title') : t('sightings_page.add_action_title')}
        >
          <section>
            <ActionFormContainer
              handlePaneToggle={this.handleInterventionPaneToggle}
              claimId={this.state.claimId}
              intervention={this.state.selectedIntervention}
            />
            <NoticeContainer displayCode="edit_intervention_error" />
          </section>
        </AddEditDrawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reviewedSightings: claimSelectors.getReviewedSightings(state),
    confirmedSightings: claimSelectors.getConfirmedSightings(state),
    confirmedSightingsCursor: claimSelectors.getConfirmedSightingsCursor(state),
    confirmedSightingsCount: claimSelectors.getConfirmedSightingsCount(state),
    isGettingConfirmedSightings: claimSelectors.getIsGettingConfirmedSightings(state),
    dismissedSightings: claimSelectors.getDismissedSightings(state),
    dismissedSightingsCursor: claimSelectors.getDismissedSightingsCursor(state),
    dismissedSightingsCount: claimSelectors.getDismissedSightingsCount(state),
    isGettingDismissedSightings: claimSelectors.getIsGettingDismissedSightings(state),
    isEditingIntervention: interventionSelectors.getIsEditing(state),
    permissions: authSelectors.getPermissions(state),
    claimMatchThreshold: candidateSelectors.getClaimMatchThreshold(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    claimActions: bindActionCreators(claimActions, dispatch),
    interventionActions: bindActionCreators(interventionActions, dispatch)
  };
}

SightingsPage.propTypes = {
  t: PropTypes.func,
  classes:  PropTypes.object,
  claimActions: PropTypes.object,
  interventionActions: PropTypes.object,
  isEditingIntervention: PropTypes.bool,
  confirmedSightings: PropTypes.array,
  confirmedSightingsCursor: PropTypes.string,
  confirmedSightingsCount: PropTypes.number,
  dismissedSightings: PropTypes.array,
  dismissedSightingsCursor: PropTypes.string,
  dismissedSightingsCount: PropTypes.number,
  permissions: PropTypes.array,
  claimMatchThreshold: PropTypes.number,
  isGettingConfirmedSightings: PropTypes.bool,
  isGettingDismissedSightings: PropTypes.bool

};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(),
  withStyles((theme) => ({
    content: {
      marginTop: '24px',
      maxWidth: '950px',
      margin: '0 auto'
    },
    actionCount: {
      textAlign: 'center',
      display: 'block',
      marginBottom: '6px'
    },
    digestLink: {
      display: 'block',
      margin: '12px 0',
      color: theme.palette.primary[500],
      fontSize: '14px'
    },
    numberOfSightings: {
      marginTop: '20px'
    }
}))
)(SightingsPage);
