const actionTypes =  {
    correction_request: "Correction request",
    clarification_request: "Clarification request",
    missing_context_request: "Missing context request",
    made_aware: "Made claimant aware",
    right_of_reply: "Right of reply",
    contacted_regulator: "Contacted regulator",
    contacted_other: "Contacted other"
};
  
export default actionTypes;