import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import NoticeContainer from 'Components/Common/NoticeContainer';
import AddEditDrawer from 'Components/Common/AddEditDrawer';
import ActionFormContainer from 'Components/Action/ActionFormContainer';
import interventionActions from 'Actions/interventionActions';
import claimActions from 'Actions/claimActions';
import noticeActions  from 'Actions/noticeActions';
import ActionManager from 'Components/Action/ActionManager';
import interventionSelectors from 'Selectors/interventionSelectors';
import claimSelectors from 'Selectors/claimSelectors';
import noticeSelectors from 'Selectors/noticeSelectors';
import authSelectors from 'Selectors/authSelectors';
import JSURL from 'jsurl';
import { addComparisonsToFilter } from 'Components/Action/ActionTableFilters';
import { withStyles } from '@material-ui/core/styles';

export class ActionManagerContainer extends React.Component {

  constructor(props){
    super(props);

    const urlParams = new URLSearchParams(location.search);
    const params = {
      cases: urlParams.get('cases'),
      from: urlParams.get('from'),
      until: urlParams.get('until'),
      text: urlParams.get('text')
    };
    let actionsFieldTypes = {
      status: 'single',
      publication: 'single',
      author_name: 'single',
      tracked_claim_id: 'text',
      category: 'single'
    };
    let hasValidUrlFilters = true;

    this.state = {
      selectedIntervention: null,
      showAverageGraph: false,
      showDialog: false,
      interventionId: null
    };

    let cases = null;
    if(params.cases && params.cases.length) {
      let hasValidCases = this.validateCases(params.cases);
      if(hasValidCases) {
        cases = JSURL.parse(params.cases);
        cases.map(c => {
          c.type = actionsFieldTypes[c.field];
          c = addComparisonsToFilter(c, props.t);
          return c;
        });
      } else {
        hasValidUrlFilters = false;
      }
    }

    let fromDate = null;
    if(hasValidUrlFilters && params.from && params.from.length) {
      let momentFromDate = moment(params.from);
      if(momentFromDate.isValid()) {
        fromDate = momentFromDate;
      } else {
        hasValidUrlFilters = false;
      }
    }

    let untilDate = null;
    if(hasValidUrlFilters && params.until && params.until.length) {
      let momentUntilDate = moment(params.until);
      if(momentUntilDate.isValid()) {
        untilDate = momentUntilDate;
      } else {
        hasValidUrlFilters = false;
      }
    }

    let filterText = null;
    if(hasValidUrlFilters && params.text && params.text.length) filterText = decodeURIComponent(params.text);

    if(hasValidUrlFilters) {
      props.interventionActions.loadInterventions();
      props.claimActions.loadClaims();
      this.initialFilters = {
        text: filterText || '',
        from: fromDate,
        until: untilDate,
        cases: cases || []
      };
    } else {
      props.noticeActions.addNotice('page_notice', {
        message: props.t('action_manager.invalid_filters'),
        type: 'error'
      });
    }
  }

  validateCases = (cases) => {
    let hasValidCases = false;
    // there can only be one cases param
    if(!Array.isArray(cases)) {
      let unCheckedCases;
      try {
        unCheckedCases = JSURL.parse(cases);
        let hasCasesWithValues = true;
        // all cases must have a field and values
        unCheckedCases.forEach((c,i) => {
          hasCasesWithValues = hasCasesWithValues && (c.field && c.values);
        });
        if(hasCasesWithValues) {
          hasValidCases = true;
        }
      }
      catch(err) {
        hasValidCases = false;
      }
    }

    return hasValidCases;
  }

  handleFilterChange = (filters) => {
    this.props.interventionActions.changeActionsFilter();
    let queryParams = [];
    if(filters.text) {
      queryParams.push('text='+encodeURIComponent(filters.text));
    }

    if(filters.from) queryParams.push('from='+moment(filters.from).format('YYYY-MM-DD'));
    if(filters.until) queryParams.push('until='+moment(filters.until).format('YYYY-MM-DD'));

    if(filters.cases && filters.cases.length) {
      let urlcases = [
        ...filters.cases
      ];
      urlcases.forEach((c,i) => {
        delete urlcases[i].comparison;
        delete urlcases[i].type;
      });

      queryParams.push('cases='+JSURL.stringify(urlcases));
    }

    if(queryParams.length){
      let generatedURI = '?'+queryParams.join('&');
      this.props.history.push(generatedURI);
      if((generatedURI.length + window.location.href.length) >= 2000) {
        this.props.interventionActions.longActionsUrl();
      }
    } else {
      this.props.history.push('/actions/');
    }
  }

  handleShowDelete = (id) => {
    this.setState({
      showDialog: !this.state.showDialog,
      interventionId: id
    });
  }

  handleInterventionDelete = () => {
    if(this.state.interventionId){
      this.props.interventionActions.deleteIntervention(this.state.interventionId);
      this.handleShowDelete();
    }
  }

  handleStartEditing = (intervention) => {
    if(intervention) {
      this.setState({
        selectedIntervention: {
          ...intervention
        }
      });
    }
    this.props.interventionActions.startEditingIntervention();
  }

  handleInterventionPaneToggle = () => {
    if(this.props.isEditing) {
      this.props.interventionActions.stopEditingIntervention();
    } else {
      this.props.interventionActions.startEditingIntervention();
    }
  }

  handleAddIntervention = () => {
    this.setState({
      selectedIntervention: {
        stored_sighting: {
          sighting_type: 'manual_entry'
        }
      }
    });
    this.props.interventionActions.startEditingIntervention();
  }


  render() {
    const {
      isEditing,
      interventions,
      t,
      isRequestingActions,
      isRequestingClaims,
      filterableFields,
      classes,
      errorNotice,
      permissions
    } = this.props;

    const {
      selectedIntervention
    } = this.state;

      if(isRequestingActions || isRequestingClaims) {
        return (
          <div style={{height: '100%'}}>
            <div className={classes.progress}>
             <CircularProgress />
            </div>
          </div>
        );
      } else {
       return (
        <div style={{height: '100%'}}>
        <ActionManager
          actions={interventions}
          handleDelete={this.handleShowDelete}
          handleEdit={this.handleStartEditing}
          handleCreate={this.handleAddIntervention}
          filterableFields={filterableFields}
          initialFilters={this.initialFilters}
          handleFilterChange={this.handleFilterChange}
          errorNotice={errorNotice}
          canViewClaim={permissions.includes('claim')}
        />

           <AddEditDrawer
             isActive={isEditing}
             handleToggle={this.handleInterventionPaneToggle}
             title={selectedIntervention && selectedIntervention.intervention_id  ? t('action_manager.edit_title') : t('action_manager.add_title')}
           >
             <section>
                <ActionFormContainer
                  handlePaneToggle={this.handleInterventionPaneToggle}
                  intervention={selectedIntervention}
                  withCustomSighting={true}
                />
                <NoticeContainer displayCode="edit_intervention_error" />
            </section>
            </AddEditDrawer>
            <Dialog
              open={this.state.showDialog}
              onClose={this.handleShowDelete}
            >
              <DialogTitle id="deleteaction-dialog-title">{t('action_manager.delete_title')}</DialogTitle>
              <DialogContent>
                <DialogContentText id="deleteaction-dialog-description">
                  {t('action_manager.delete_confirmation')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleShowDelete} role="button" aria-label="cancel" color="secondary" variant="contained" autoFocus>
                  {t('action_manager.cancel_button')}
                </Button>
                <Button className="delete-action-dialog-button" onClick={this.handleInterventionDelete} role="button" aria-label="delete"  color="primary" variant="contained" autoFocus>
                  {t('action_manager.delete_button')}
                </Button>
              </DialogActions>
            </Dialog>
      </div>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    interventionActions: bindActionCreators(interventionActions, dispatch),
    claimActions: bindActionCreators(claimActions,dispatch),
    noticeActions: bindActionCreators(noticeActions,dispatch)
  };
}

function mapStateToProps(state) {
  return {
    filterableFields: interventionSelectors.getFilterableFields(state),
    isRequestingActions: interventionSelectors.getIsRequestingActions(state),
    isRequestingClaims: claimSelectors.getIsRequestingClaims(state),
    interventions: interventionSelectors.getFormattedInterventions(state),
    claims: claimSelectors.getClaims(state),
    isEditing: interventionSelectors.getIsEditing(state),
    sortedBy: interventionSelectors.getSortedBy(state),
    orderedBy: interventionSelectors.getOrderedBy(state),
    errorNotice: noticeSelectors.getNoticeById(state, 'page_notice'),
    permissions: authSelectors.getPermissions(state)
  };
}

ActionManagerContainer.propTypes = {
  claimActions: PropTypes.object,
  claims: PropTypes.array,
  filterableFields: PropTypes.array,
  history: PropTypes.object,
  i18n: PropTypes.object,
  interventionActions: PropTypes.object,
  interventions: PropTypes.array,
  isEditing: PropTypes.bool,
  isRequestingActions: PropTypes.bool,
  isRequestingClaims: PropTypes.bool,
  noticeActions : PropTypes.object,
  orderedBy:  PropTypes.string,
  sortedBy: PropTypes.string,
  t: PropTypes.func,
  classes: PropTypes.object,
  errorNotice: PropTypes.object,
  permissions: PropTypes.array
};


export default compose(
  withStyles(() => ({
    progress: {
      textAlign: 'center',
      marginTop: '24px'
    }
  })),
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ActionManagerContainer);
