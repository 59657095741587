import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { compose } from 'redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import {
  DateRangePicker
} from 'react-dates';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const START_DATE = 'startDate';
const END_DATE = 'endDate';
const HORIZONTAL_ORIENTATION = 'horizontal';
const ANCHOR_LEFT = 'left';


const isSameDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return a.date() === b.date()
    && a.month() === b.month()
    && a.year() === b.year();
};

const propTypes = {

  // example props for the demo
  autoFocus: PropTypes.bool,
  autoFocusEndDate: PropTypes.bool,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  presets: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    start: momentPropTypes.momentObj,
    end: momentPropTypes.momentObj,
  })),
  handleDatesChange: PropTypes.func,
  classes: PropTypes.object,
  presetButtons: PropTypes.array,
  initialStartDate: PropTypes.object,
  initialEndDate: PropTypes.object,
  i18n: PropTypes.object,
  t: PropTypes.func,
  tReady: PropTypes.bool
};

const defaultProps = {
  // example props for the demo
  autoFocus: false,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  presets: [],

  // input related props
  startDateId: START_DATE,
  endDateId: END_DATE,
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},

  // day presentation and interaction related props
  renderDayContents: null,
  minimumNights: 0,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: day => false,
  isDayHighlighted: () => false,

  // internationalization
  monthFormat: 'MMMM YYYY'
};

export class EnhancedTableDateRange extends React.Component {
  constructor(props) {
    super(props);

    let focusedInput = null;
    if (props.autoFocus) {
      focusedInput = START_DATE;
    } else if (props.autoFocusEndDate) {
      focusedInput = END_DATE;
    }

    this.state = {
      focusedInput
    };
  }

  onDatesChange = ({ startDate, endDate }) => {
    this.props.handleDatesChange({ startDate, endDate });
  }

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  }

  renderDatePresets = () => {
    const { classes, presetButtons, t } = this.props;
    const today = moment();
    let presets;
    const yesterday = moment().subtract(1, 'day');
    if(presetButtons) {
      presets = presetButtons;
    } else {
      presets = [{
      text: t('enhanced_table_date_range.today'),
      start: today,
      end: today,
    },
    {
      text: t('enhanced_table_date_range.yesterday'),
      start: yesterday,
      end: yesterday,
    },
    {
      text: t('enhanced_table_date_range.last_week'),
      start: moment().subtract(1, 'week'),
      end: today,
    },
    {
      text: t('enhanced_table_date_range.last_month'),
      start: moment().subtract(1, 'month'),
      end: today,
    }];
    }
    const { startDate, endDate } = this.props;

    return (

      <div className={classes.PresetDateRangePicker_panel}>
        {presets.map(({ text, start, end }) => {
          let buttonClassName = classes.PresetDateRangePicker_button;
          const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);
          if (isSelected) buttonClassName += ' ' + classes.PresetDateRangePicker_button__selected;

          return (
            <div className={classes.presetButton} key={text}>
            <Button
              onClick={() => this.onDatesChange({ startDate: start, endDate: end })}
              color="primary"
              variant="contained"
            >{text}</Button>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { focusedInput } = this.state;
    const { startDate, endDate } = this.props;

    // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
    // example wrapper but are not props on the SingleDatePicker itself and
    // thus, have to be omitted.

    let {
      autoFocus,
      autoFocusEndDate,
      initialStartDate,
      initialEndDate,
      presets,
      handleDatesChange,
      classes,
      presetButtons,
      i18n,
      t,
      tReady,
      ...props} = this.props;
    return (
          <DateRangePicker
            {...props}
            renderCalendarInfo={this.renderDatePresets}
            onDatesChange={this.onDatesChange}
            onFocusChange={this.onFocusChange}
            focusedInput={focusedInput}
            startDate={startDate}
            endDate={endDate}
            small={true}
            displayFormat="YYYY-MM-DD"
            startDatePlaceholderText={t('claim_manager.date_range.start_date_placeholder')}
            endDatePlaceholderText={t('claim_manager.date_range.end_date_placeholder')}
          />
    );
  }
}

EnhancedTableDateRange.propTypes = propTypes;
EnhancedTableDateRange.defaultProps = defaultProps;

export default compose(
  withTranslation(),
  withStyles(() => ({
    presetButton: {
      display: 'inline-block',
      marginRight: '6px'
    },
    PresetDateRangePicker_panel: {
       padding: '0 22px 11px 22px',
    },
     PresetDateRangePicker_button: {
       position: 'relative',
       height: '100%',
       textAlign: 'center',
       background: 'none',
       padding: '4px 12px',
       marginRight: 8,
       font: 'inherit',
       fontWeight: 700,
       lineHeight: 'normal',
       overflow: 'visible',
       boxSizing: 'border-box',
       backgroundColor: 'rgb(0, 166, 153)',
       color: '#fff',
       cursor: 'pointer',

       ':active': {
         outline: 0,
       },
     },
     PresetDateRangePickerInput_arrow: {
       border: '1px solid blue'
     }
}))
)(EnhancedTableDateRange);
