import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { default as OutboundLink } from '@material-ui/core/Link';
import DescriptionIcon from '@material-ui/icons/Description';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export function LiveMatchExact(props) {
  const { t, classes } = props;
  return(
    <div className={props.className}>
      <h4 className={classes.claim}>
        <i className="fa fa-exclamation-circle" aria-hidden="true" /> {props.match.claim}
      </h4>
      <div className={classes.conclusion}>{props.match.conclusion}</div>
      <div className={classes.articleLink}>
        <OutboundLink
          href={props.match.factcheck_url}
          target="_blank"
        >
          {t('live_match_exact.read_the_factcheck')}
        </OutboundLink>
      </div>
      {props.showLinks === true && (
        <div className={classes.icons}>
          <span className={classes.trendLink}>
            <Tooltip title={t('live_match_exact.match.view_claim')}>
              <Link to={"/alerts/claims/"+props.match.tracked_claim_id}>
                <DescriptionIcon />
              </Link>
            </Tooltip>
          </span>
          <Tooltip title={t('live_match_exact.tweet_claim')}>
              <OutboundLink
                href={"https://twitter.com/intent/tweet?text="+encodeURIComponent(props.match.factcheck_url)+"&data-size=large"}
                target="_blank"
                className={classes.twitter}
              >
                <i className="icon ion-social-twitter" />
              </OutboundLink>
            </Tooltip>
        </div>
      )}
    </div>
  );
}

LiveMatchExact.propTypes = {
  match: PropTypes.object.isRequired,
  showLinks: PropTypes.bool,
  className: PropTypes.string,
  t: PropTypes.func,
  classes: PropTypes.object
};


export default compose(
  withStyles(() => ({
    match: {
      '& a': {
        color: '#009688'
      }
    },
    icons: {
      marginTop: '12px',
      '& a': {
        color: '#ccc',
        '&:hover': {
          color: '#000'
        }
      }
    },
    claim: {
      fontSize: '16px',
      lineHeight: '1.4',
      margin: '8px 0',
      fontWeight: '300'
    },
    conclusion: {
      fontSize: '19px',
      lineHeight: '1.5'
    },
    trendLink: {
      marginRight: '3px',
      display: 'inline-block'
    },
    articleLink: {
      fontSize: '14px',
      marginTop: '8px'
    },
    twitter: {
      fontSize: '19px',
      verticalAlign: 'super'
    }
  })),
  withTranslation()
  )(LiveMatchExact);
