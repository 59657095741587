import LoginPage from '../components/Pages/LoginPage';
import ForgotPasswordPage from '../components/Pages/ForgotPasswordPage';
import VerifyPasswordPage from '../components/Pages/VerifyPasswordPage';
import RedirectPage from '../components/Pages/RedirectPage';

export default (
  [
    { path: '/',
      exact: true,
      page: LoginPage,
      name: 'login'
    },
    { path: '/login',
      exact: true,
      page: LoginPage,
      name: 'login'
    },
    { path: '/change-password',
      page: ForgotPasswordPage,
      exact: true,
      name: 'change-password'
    },
    { path: '/verify',
      page: VerifyPasswordPage,
      exact: true,
      name: 'verify'
    },
    {
      page: RedirectPage,
      name: 'redirect'
    }
  ]
);
