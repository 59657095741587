import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

export function CheckworthyTopicControls(props) {
  const {
    classes,
    t,
    checkworthyTopics,
    handleTopicDateChange,
    currentTopic,
    currentDate
  } = props;


  function handleSetTopic(e){
    handleTopicDateChange(currentDate, e.target.value)
  }

  function handleSetTopicDate(date){
    if(date && date.isValid()) {
      const currentDate = date.format('YYYY-MM-DD');
      handleTopicDateChange(currentDate, currentTopic);
    }
  }

  let topicCodes = Object.keys(checkworthyTopics);
  topicCodes.sort();

  return (
    <div className={classes.topicChooser}>
        <div className={classes.backToTopicsLink}>
            <Link className={classes.showMoreLink} to={"/home/"}>{t('checkworthy_topic_controls.all_topics')}</Link>
        </div>
        <div className={classes.topicSelect}>
        <FormControl fullWidth={true} margin="none">
            <InputLabel htmlFor="type">{t('checkworthy_topic_controls.topics')}</InputLabel>
            <Select
              value={currentTopic}
              onChange={handleSetTopic}
              fullWidth={true}
              inputProps={{
                  name: 'topic',
                  id: 'topic',
              }}
            >
            {topicCodes.map((code) => (
              <MenuItem key={code} value={code}>
                {t(checkworthyTopics[code])}
            </MenuItem>
            ))}
            </Select>
        </FormControl>
        </div>
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} >
        <KeyboardDatePicker
          margin="none"
          id="topicDate"
          name="topicDate"
          label={t('checkworthy_topic_controls.topic_date_label')}
          format="YYYY-MM-DD"
          value={currentDate}
          onChange={handleSetTopicDate}
          cancelLabel={t('checkworthy_topic_controls.cancel')}
          okLabel={t('checkworthy_topic_controls.ok')}
        />
        </MuiPickersUtilsProvider>
  </div>
  );
}


CheckworthyTopicControls.propTypes  = {
  checkworthyTopics: PropTypes.object,
  classes: PropTypes.object,
  handleTopicDateChange: PropTypes.func,
  t: PropTypes.func,
  currentTopic: PropTypes.string,
  currentTopicDate: PropTypes.string,
  history: PropTypes.object,
  currentDate: PropTypes.string
};


export default compose(
  withTranslation(),
  withStyles((theme) => ({
    topicChooser: {
      marginTop: '24px',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '3px',
      background: '#fff',
      padding: '12px 0 12px 12px'
    },
    backToTopicsLink: {
      float: 'right',
      marginTop: '3px',
      marginRight: '24px'
    },
    topicSelect: {
      display: 'inline-flex',
      marginRight: '24px',
    },
    showMoreLink: {
      color: theme.palette.primary[500],
      margin: '12px 0  0 24px',
      fontSize: '15px',
      textDecoration: 'none',
      display: 'block',
      '&:hover' : {
        textDecoration: 'underline'
      }
    }
  }
)))(CheckworthyTopicControls);
