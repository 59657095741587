import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Link from '@material-ui/core/Link';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

export function CheckworthyTopicsTopTen(props) {

  const {
    classes,
    checkworthyArticles,
    t,
    topicDisplayName,
    dismissSighting,
    removeReviewedSighting,
    confirmSighting,
    topicCode,
    isGettingTopics
  } = props;

  const [currentSentenceHoverMediaHash, setCurrentSentenceHoverMediaHash] = React.useState('');

  function renderSentences(){
    return checkworthyArticles.articles.map((article,i) => {
      const listNumber = i+1;

      const {
        authorName,
        publicationName
      } = article;

      const sentence = article.sentences[0];

      let interestedClass = '';
      let isInteresting = checkworthyArticles.reviewedMediaHashes[sentence.mediaHash];
      if(isInteresting === true) interestedClass = classes.confirmedMatch;
      if(isInteresting === false) interestedClass = classes.dismissedMatch;

      let sentenceButtonClass = classes.sentenceButtons;
      if(isInteresting === true || isInteresting === false || currentSentenceHoverMediaHash === sentence.mediaHash) sentenceButtonClass = classes.sentenceButtonsVisible;

      return (
        <li
          key={sentence.mediaHash}
          className={classes.listItemWithTitle}
          onMouseEnter={() => { setCurrentSentenceHoverMediaHash(sentence.mediaHash); }}
          onMouseLeave={() => { setCurrentSentenceHoverMediaHash(''); }}
        >
          <div className={interestedClass + ' ' + classes.listItem}>
            <div className={classes.listGrid}>
            <div className={classes.listItemNumber}>
              {listNumber}.
            </div>
            <div className={classes.listItemContent}>
              {authorName.length > 0 && (<div className={classes.authorName}>{authorName}</div>)}
              <div className={classes.listItemControls + ' ' + (!authorName.length > 0 && classes.listItemNoName)}>
                <Link target="_blank" href={sentence.url} className={classes.link}>
                  <div className={classes.checkworthySentence}>
                    <div className={classes.publicationIcon}>
                        <Tooltip title={publicationName}>
                          <img src={article.favicon} />
                        </Tooltip>
                      </div>
                    <div className={classes.sentenceText}>{sentence.text}{sentence.url.startsWith('http') && (<OpenInNewIcon className={classes.openInNewIcon} />)}</div>
                  </div>
                </Link>
                  <span className={sentenceButtonClass}>
                  {isInteresting === false ? (
                      <span className={classes.sentenceConfirmButton} onClick={() => {
                      removeReviewedSighting(topicCode, sentence.mediaHash);}}><NotInterestedIcon style={{ color:'#000' }} />
                    </span>
                  ): (
                        <span className={classes.sentenceConfirmButton} onClick={() => {
                      dismissSighting(topicCode, sentence.mediaHash);
                      }}><NotInterestedIcon />
                    </span>
                  )}

                  {isInteresting === true ? (
                      <span className={classes.sentenceConfirmButton} onClick={() => {
                      removeReviewedSighting(topicCode, sentence.mediaHash);}}><DoneIcon style={{ color:'#009688' }} />
                    </span>
                  ): (
                        <span className={classes.sentenceConfirmButton} onClick={() => {
                      confirmSighting(topicCode, sentence.mediaHash);
                      }}><DoneIcon />
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
          </div>
        </li>
      );
    });
  }
  return (
    <div>
      {isGettingTopics ? (
        <CircularProgress className={classes.loader} />
      ) : (
        <section>
          {checkworthyArticles.articles.length !== 0 ? (
            <div>
              <ul className={classes.unorderList}>
                  {renderSentences()}
              </ul>
            </div>
            ) : (
            <div className={classes.noTopicsMessage}>
                  {t('checkworthy_topics_top_ten.no_claims_for_topics', {
                    topic: t(topicDisplayName),
                    interpolation: { escapeValue: false }
                  })} <br /><br />
            </div>
            )}
        </section>
      )}
    </div>
  );
}
CheckworthyTopicsTopTen.propTypes  = {
  classes: PropTypes.object,
  title: PropTypes.string,
  noResultsText: PropTypes.string,
  keywords: PropTypes.array,
  onKeywordClick: PropTypes.func,
  icon: PropTypes.element,
  showListOrder: PropTypes.bool,
  description: PropTypes.string,
  useKeywordsQuery: PropTypes.bool,
  checkworthyArticles: PropTypes.object,
  toggleStarred: PropTypes.func,
  starredSentences: PropTypes.array,
  t: PropTypes.func,
  topicDisplayName: PropTypes.string,
  isUnordered: PropTypes.bool,
  topicCode: PropTypes.string,
  confirmSighting: PropTypes.func,
  removeReviewedSighting: PropTypes.func,
  dismissSighting: PropTypes.func,
  isGettingTopics: PropTypes.bool
};

export default compose(
  withTranslation(),
  withStyles((theme) => ({
    loader: {
      margin: '12px'
    },
    listGrid: {
      display: 'grid',
      gridTemplateColumns: '36px 1fr',
      paddingLeft: '6px'
    },
    listItemNumber: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginTop: '18px',
      color: '#666'
    },
    listItemControls: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '1fr 50px'
    },
    listControlButtons: {
      textAlign: 'right',
      cursor: 'pointer',
      '& svg' : {
        color: '#bbb'
      },
    },
    listItemNoName: {
      paddingTop: '24px'
    },
    confirmedMatch: {
      paddingLeft: '0 !important',
      backgroundColor: '#fff',
      borderLeft: '2px solid ' + theme.palette.primary[100] + ' !important',
    },
    dismissedMatch: {
      color: '#eee',
      opacity: '0.5',
      '& img': {
        filter: 'grayscale(100%)'
      }
    },
    starIcon: {
      cursor: 'pointer',
      flex: '0 0 25px',
      padding: '0 0 0 12px'
    },
    listItem: {
      display: 'inline-block',
      borderLeft: '2px solid white',
      width: '100%',
      marginBottom: '12px',
    },
    unorderList: {
      padding: '0',
      marginBottom: '0',
      fontSize: '16px',
      color: '#555',
      listStyle: 'none'
    },
    link : {
      color: '#555',
      fontSize: '16px',
      '&:hover' : {
        color: theme.palette.primary[500]
      },
      flex: 1
    },
    checkworthySentence: {
      display: 'flex',
      flexDirection: 'row'
    },
    publicationIcon: {
      padding: '0 12px',
      verticalAlign: 'top'
    },
    sentenceText: {
      verticalAlign: 'top',
      wordBreak: 'break-word'
    },
    noTopicsMessage: {
      marginTop: '24px'
    },
    openInNewIcon: {
      width: '15px',
      margin: '0 0 0 6px',
      verticalAlign: 'middle'
    },
    authorName: {
      fontWeight: 'bold',
      color: '#777',
      fontSize: '14px',
      paddingLeft: '40px',
      paddingBottom: '6px'
    },
    listItemWithTitle: {
      listStylePosition: 'inside'
    },
    sentenceButtons: {
      minWidth: '100px',
      marginLeft: '6px',
      cursor: 'pointer',
      '& svg' : {
        color: '#bbb'
      },
      visibility: 'hidden',
      paddingTop: '3px'
    },
    sentenceButtonsVisible: {
      minWidth: '100px',
      marginLeft: '6px',
      cursor: 'pointer',
      '& svg' : {
        color: '#bbb'
      },
      paddingTop: '3px'
    },
  }))
)(CheckworthyTopicsTopTen);