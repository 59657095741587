import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import TranscriptForm from './TranscriptForm';
import transcriptActions from 'Actions/transcriptActions';
import noticeSelectors from 'Selectors/noticeSelectors';
import authSelectors from 'Selectors/authSelectors';
import transcriptSelectors from 'Selectors/transcriptSelectors';


export class TranscriptFormContainer extends Component {

  constructor(props) {
    super(props);
    props.transcriptActions.getRadioStations();
  }

  handleFormSubmit = (data) => {
    switch (data.type) {
      case 'channel':
        this.props.transcriptActions.createChannelTranscript(data);
        break;
      case 'block':
        this.props.transcriptActions.createBlockTranscript(data);
        break;
      case 'youtube':
        this.props.transcriptActions.createYoutubeTranscript(data);
        break;
      case 'radio':
        this.props.transcriptActions.createRadioTranscript(data);
        break;
    }
  }
  render() {
    const {
      t,
      defaultType,
      requestState,
      radioStations
    } = this.props;

    let channelSources = this.props.channels.map(channel => {
      return {
        value: channel.code,
        label: channel.display
      };
    });
    
      return (
        <TranscriptForm
          handleFormSubmit={this.handleFormSubmit}
          channelSources={channelSources}
          transcriptTypes={this.props.transcriptTypes}
          noticeError={this.props.noticeError}
          defaultType={defaultType}
          requestState={requestState}
          defaultLocale={this.props.locale}
          radioStations={radioStations}
        />
      );
    }
}

TranscriptFormContainer.propTypes = {
  transcriptActions: PropTypes.object.isRequired,
  channels: PropTypes.array,
  defaultType: PropTypes.string,
  t: PropTypes.func,
  noticeError: PropTypes.object,
  speechAllowance: PropTypes.number,
  permissions: PropTypes.array,
  requestState: PropTypes.string,
  transcriptTypes: PropTypes.array,
  locale: PropTypes.string,
  radioStations: PropTypes.array
};

function mapStateToProps(state) {
  return {
      noticeError: noticeSelectors.getNoticeById(state, 'transcript_add_error'),
      permissions: authSelectors.getPermissions(state),
      requestState: transcriptSelectors.getRequestState(state, 'transcript'),
      transcriptTypes: transcriptSelectors.getTranscriptTypes(state),
      locale: authSelectors.getMediaLocale(state),
      radioStations: transcriptSelectors.getRadioStationMediaCodes(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transcriptActions: bindActionCreators(transcriptActions, dispatch),
    noticeSelectors: bindActionCreators(noticeSelectors, dispatch)
  };
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(TranscriptFormContainer);
