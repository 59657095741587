import React, { useRef } from 'react';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation} from 'react-i18next';
import { bindActionCreators, compose } from 'redux';
import entitySelectors from  'Selectors/entitySelectors';
import entityActions from 'Actions/entityActions';
import { connect } from 'react-redux';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

function WikiAutosuggest(props) {

  const {
    classes,
    handleChange,
    suggestions,
    placeholder,
    handleInputKeyPress,
    handleSuggestionSelect,
    value,
    wikiId,
    isDisabled,
    t
  } = props;

  const [inputValue, setInputValue] = React.useState(value || '');
  const [selectId, setSelectId] = React.useState(wikiId || '');
  const [selectValue, setSelectValue] = React.useState(value || '');

  const suggestionRef = useRef(null);

  const handleSuggestionsFetchRequested = ({ value }) => {
    clearTimeout(suggestionRef.current);
    suggestionRef.current = setTimeout(() => {
      props.entityActions.getSuggestions(value);
    }, 500);
  };

  const renderSuggestion = (item) => {
    return (
      <MenuItem component="div" id={item.value} data-label={item.label}>
        <div>
          <strong>{item.label} ({item.value})</strong><br/>
          <span>{item.description}</span>
        </div>
      </MenuItem>
    );
  };

  const getSuggestionValue = (item)  =>{
    return item.label;
  };

  const handleSuggestionSelected = (e) => {
    let target = e.currentTarget.querySelectorAll('[role="menuitem"]')[0];
    setSelectId(target.id);
    handleSuggestionSelect({
      label: target.getAttribute('data-label'),
      value: target.id
    });
  };

  const handleValueChange = (e) => (event, { newValue }) => {
    setInputValue(newValue);
    if(selectValue !== inputValue) {
      setSelectId('');
      setSelectValue('');
    }
    handleChange(newValue);
  };

  const renderInputComponent = (inputProps) => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;
    return (
      <div className={classes.formField}>
        <TextField
          variant="outlined"
          fullWidth={true}
          inputProps={{
            onKeyDown: handleInputKeyPress
          }}
          inputRef={ref}
          {...other}
          disabled={isDisabled}
        />
      </div>
    );
  };


  const autosuggestProps = {
    renderInputComponent,
    suggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: ()=>{},
    onSuggestionSelected: handleSuggestionSelected,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
    <div className={classes.autoSuggestContainer}>
      <Autosuggest
        {...autosuggestProps}
        focusInputOnSuggestionClick={false}
        inputProps={{
          classes,
          placeholder: placeholder,
          value: inputValue,
          onChange: handleValueChange('single'),
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
      <div>{inputValue && selectId ? (
        <span className={classes.confirmLink}>
          {t('wiki_autosuggest.confirm_message')} <a target="_blank" href={"https://www.wikidata.org/wiki/"+ selectId}>{inputValue} ({selectId}) <OpenInNewIcon className={classes.openInNewIcon} /></a>
        </span>
      ) : ''}
        </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    suggestions: entitySelectors.getSuggestions(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    entityActions: bindActionCreators(entityActions, dispatch)
  };
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles((theme) => ({
    formField: {
      '& .MuiOutlinedInput-input': {
        padding: '9px 14px'
      }
    },
    confirmLink: {
      fontSize: '14px',
      margin: '12px 0',
      display: 'block',
      '& a': {
        color: theme.palette.primary[500]
      }
    },
    inputRoot: {
      padding: '2px 4px',
      marginTop: '6px',
      display: 'flex',
      alignItems: 'center'
    },
    container: {
      position: 'relative',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    divider: {
      height: theme.spacing(2),
    },
    openInNewIcon: {
      width: '15px',
      margin: '0 0 0 0',
      verticalAlign: 'middle',
      fontSize: '14px'
    }
  }))
)(WikiAutosuggest);

WikiAutosuggest.propTypes = {
  classes: PropTypes.object,
  handleChange: PropTypes.func,
  handleSuggestionSelect: PropTypes.func,
  handleInputKeyPress: PropTypes.func,
  suggestionsRef: PropTypes.string,
  placeholder: PropTypes.string,
  t: PropTypes.func,
  entityActions: PropTypes.object,
  suggestions: PropTypes.array,
  value: PropTypes.string,
  wikiId: PropTypes.string,
  isDisabled: PropTypes.bool
};
