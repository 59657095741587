import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from  '@material-ui/icons/Visibility';

import { withStyles } from '@material-ui/core/styles';

function PasswordField(props) {

  const {
    onFieldChange,
    password,
    label,
    name,
    classes
  } = props;

  const [isVisible, setIsVisible] = React.useState(false);
  const visibility = isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />;
  const fieldType = isVisible ? "text" : "password";

  const onVisibilityToggle = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <span className={classes.visibilityToggle} onClick={onVisibilityToggle}>{visibility}</span>
      <div className={classes.passwordInput}>
        <TextField
          type={fieldType}
          name={name}
          value={password}
          onChange={onFieldChange}
          label={label}
          required={true}
          fullWidth={true}
        />
      </div>

    </div>
  );
}

PasswordField.propTypes  = {
  onFieldChange: PropTypes.func,
  password: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  classes: PropTypes.object
};

export default withStyles(() => ({
  passwordInput: {
    marginRight: '30px'
  },
  visibilityToggle: {
    width: '30px',
    marginLeft: '-30px',
    float: 'right',
    marginTop: '1em',
    color: '#bbb',
    opacity: '1',
    '&:hover': {
      color: '#000',
      cursor: 'pointer'
    }
  }
}))(PasswordField);
