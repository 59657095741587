import React from 'react';
import PropTypes from 'prop-types';

import LiveChannelLink from 'Components/Live/LiveChannelLink';
import { withStyles } from '@material-ui/core/styles';

const LiveChannelList = React.memo(function MyComponent(props) {
  return (
    <ul className={props.classes.channelList}>
      {props.channels.map((channel) => {
        return (
          <li key={channel.code} className={props.classes.channelListItem}>
            <LiveChannelLink code={channel.code} display={channel.display} channelImage={channel.channelImage} />
          </li>
        );
      })}
    </ul>
  );
});


LiveChannelList.propTypes = {
    channels: PropTypes.array.isRequired,
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    channelListItem: {
      display: 'inline-block',
      margin: '12px'
    },
    channelList: {
      padding: '40px',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid '+ theme.palette.divider,
      borderWidth: '1px 0',
      textAlign: 'center',
      marginTop: '-42px',
      marginLeft: '-12px',
      marginRight: '-12px',
      marginBottom: '42px'
    }
}))(LiveChannelList);
