export interface ApiClaimant {
    claimant_name: string;
    link: string;
    entity_type: string;
    is_human?: boolean;
    has_proper_noun?: boolean;
}

export interface ApiEnrichments {
    checkworthiness: {
        [org: string]: [{
            topic: string;
            score: number;
        }]
    };
    claim_types: [string];
    claimants: [ApiClaimant];
    claims_matched: [{
        tracked_claim_id: number;
        score: number;
    }];
    matched_claims: [number];
}

export interface ApiSentence {
    media_hash: string;
    sequence: number;
    text: string;
    enrichments: ApiEnrichments;
}

export interface ApiArticle {
    category: string;
    publication: string;
    publication_date: string;
    title: string;
    url: string;
    sentences: [ApiSentence];
    author_name?: string;
}

export interface ClaimMatchType {
    trackedClaimId: number;
    score: number;
}

export interface ClaimantType {
    name: string;
    link: string;
    entityType: string;
    isHuman: boolean;
    hasProperNoun: boolean;
}

export interface CheckworthinessType {
    [org: string]: [{
        topic: string;
        score: number;
    }]
}

export interface SentenceType {
    mediaHash: string;
    sequence: string;
    text: string;
    url: string;
    claimTypes: [string];
    matchedClaims: [number],
    claimsMatched: [ClaimMatchType],
    claimants: [ClaimantType],
    checkworthiness: CheckworthinessType
}

export interface ArticleType {
    category: string;
    publicationName: string;
    publicationCode: string;
    publicationDate: string;
    title: string;
    url: string;
    authorName?: string;
    sentences: [SentenceType]
    favicon?: string; 
}

export const ClaimMatch = (obj) => {
    return {
        trackedClaimId: obj.tracked_claim_id,
        score: obj.score
    };
};

export const Claimant = (obj) => {
    return {
        name: obj.claimant_name,
        link: obj.link,
        entityType: obj.entity_type,
        isHuman: obj.is_human,
        hasProperNoun: obj.has_proper_noun
    };
};

function createUrlWithSentenceText(url, sentenceText){
    if(!url.includes('#')) url = url + '#';
    url = url+':~:text='+ encodeURIComponent(sentenceText);
    return url;
  }

export const Sentence = (obj, url) => {
    return {
        mediaHash: obj.media_hash,
        sequence: obj.sequence,
        text: obj.text,
        url: createUrlWithSentenceText(url, obj.text),
        claimTypes: obj.enrichments.claim_types,
        matchedClaims: obj.enrichments.matched_claims || [],
        claimsMatched: obj.enrichments.claims_matched.map(cm => ClaimMatch(cm)),
        claimants: obj.enrichments.claimants.map(clm => Claimant(clm)),
        checkworthiness: []
    };
};
function getAuthorName(obj) {
    let authorName = obj.author_name || '';
    if(!authorName && obj.url.includes('twitter')) {
        authorName = obj.url.split('/')[3];
    }
    return authorName
}

function getFavicon(url) {
    try {
        // special handling for YouTube transcripts
        if (url.startsWith('/youtube-transcript/')) {
            return 'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://youtube.com';
        }

        let domain = url.split('//').slice(-1)[0].split('/')[0];
        
        // special handling for YouTube domains
        if (domain.includes('youtu.be') || domain.includes('youtube.com')) {
            domain = 'youtube.com';
        }
        
        // default for radio / other non domains
        if (!domain) {
            return 'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://';
        }
        return `https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${encodeURIComponent('https://' + domain)}`;
    } catch (e) {
        return 'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://';
    }
}

export const Article = {


    fromApi: (obj) => {
        return {
            category: obj.category,
            publicationCode: obj.publication,
            publicationName: obj.publication,
            publicationDate: obj.publication_date,
            title: obj.title,
            url: obj.url,
            sentences: obj.sentences.map(stc => Sentence(stc, obj.url)),
            authorName: getAuthorName(obj),
            favicon: getFavicon(obj.url)
        };
    }
};