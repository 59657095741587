import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddEditDrawer from 'Components/Common/AddEditDrawer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import UserChangePasswordForm from 'Components/User/UserChangePasswordForm';
import UserForm from 'Components/User/UserForm';
import Notice from 'Components/Common/Notice';
import { withTranslation, Trans } from 'react-i18next';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

export class UserManager extends Component {

  constructor(props) {
    super(props);

    this.state =  {
      isChangingPassword: false,
      selectedUser: null,
      selectedUserEmail: null,
      showDeleteUserDialog: false
    };

  }

  handleToggleAddEditUser = (user) => {
    if(this.props.isEditingUser){
      this.props.stopEditingUser();
    } else {
      this.props.startEditingUser();
      this.setState({
        isChangingPassword: false
      });
    }
  }

  handleToggleChangingPassword = () => {
    this.setState({
      isChangingPassword: !this.state.isChangingPassword
    });
  }

  handleToggleDeleteUser = () => {
    this.setState({
      showDeleteUserDialog: !this.state.showDeleteUserDialog
    });
  }

  handleEditUser = (user) => {
    this.setState({
      selectedUser: user
    },() => {
      this.handleToggleAddEditUser(user);
    });
  }

  handleAddUser = (user) => {
    this.setState({
      selectedUser: null
    },() => {
      this.handleToggleAddEditUser(user);
    });
  }

  handleDeleteUser = (user) => {
    this.setState({
      selectedUser: user
    },() => {
      this.handleToggleDeleteUser(user);
    });
  }

  deleteUser = () => {
    this.props.deleteUser(this.state.selectedUser.userId);
    this.setState({
      showDeleteUserDialog: false,
      selectedUser: null
    });
  }

  handleChangeUserPassword = (password) => {
    if(this.state.selectedUser) {
      const user = this.state.selectedUser;
      this.props.changeUserPassword(user.userId, password, user.email);

    }
  }

  handleResendVerifyEmail = (user) => {
    this.props.resendVerify(user);
  }

  render(){

    const {
      isChangingPassword,
      selectedUser,
      showDeleteUserDialog
    } = this.state;

    const {
      isEditingUser,
      isCreatingUser,
      isUpdatingUser,
      users,
      currentUser,
      generalNotice,
      t,
      passwordNotice,
      userNotice,
      userRoles,
      handleUserFormSubmit,
      currentUserId,
      classes
    } = this.props;

    return (
      <section>
        <h2>{t('user_manager.manage_users')}</h2>
        {generalNotice ? (
          <Notice message={generalNotice.message} type={generalNotice.type} />
        ) : ''}

        <AddEditDrawer
          isActive={isEditingUser}
          handleToggle={this.handleToggleAddEditUser}
          title={selectedUser ? t('user_manager.edit_user') : t('user_manager.add_user')}
        >
          <section>
          {isChangingPassword ? (
            <section>
              <UserChangePasswordForm user={selectedUser} onSubmit={this.handleChangeUserPassword} />
              {passwordNotice ? (
                <Notice message={passwordNotice.message} type={passwordNotice.type} />
              ) : ''}
              <div className={classes.changePasswordToggle} onClick={this.handleToggleChangingPassword}>{t('user_manager.cancel_button')}</div>
            </section>
          ) : (
            <section>
              <UserForm
                user={selectedUser}
                roles={userRoles.list}
                onSubmit={handleUserFormSubmit}
                currentUserId={currentUserId}
              />
              {userNotice ? (
                <Notice message={userNotice.message} type={userNotice.type} />
              ) : ''}
              {selectedUser  ? (
                <div className={classes.changePasswordToggle} onClick={this.handleToggleChangingPassword}>{t('user_manager.change_password')}</div>
              ): ''}

            </section>
          )}
          { isCreatingUser || isUpdatingUser ? (
            <CircularProgress />
          ) : ''}
          </section>
        </AddEditDrawer>
        <Paper className={classes.usersContainer}>
          <div className={classes.addUserButton}>
            <Button color="primary" variant="contained" onMouseUp={this.handleAddUser}>{t('user_manager.add_user')}</Button>
          </div>
          {users && users.length > 0 && (
            <Table>
                <TableHead>
                  <TableRow>
                  <TableCell align="left" />
                  <TableCell align="left">{t('user_manager.user_table.name')}</TableCell>
                  <TableCell align="left">{t('user_manager.user_table.email')}</TableCell>
                  <TableCell align="left">{t('user_manager.user_table.role')}</TableCell>
                  <TableCell align="left" />
                  <TableCell align="left" />
                </TableRow>
              </TableHead>
              <TableBody>

              {users.map(user => (
                    <TableRow key={user.userId}>
                    <TableCell align="left">
                      {this.handleEditUser ? (
                        <Button variant="contained" color="primary" onClick={()=>{this.handleEditUser(user);}}>{t('user_manager.button.edit')}</Button>
                      ) : ''}
                    </TableCell>
                    <TableCell align="left">
                      {user.name}
                    </TableCell>
                    <TableCell align="left">
                      {user.email}
                    </TableCell>
                    <TableCell align="left">
                      {user.role}
                    </TableCell>
                    <TableCell align="left">
                      {this.handleDeleteUser && currentUser.userId !== user.userId ? (
                        <div className={classes.deleteUserButton}>
                          <Tooltip title={t('user_manager.delete_user_title')}>
                            <IconButton size="small" onClick={() => { this.handleDeleteUser(user);}}><DeleteIcon /></IconButton>
                          </Tooltip>
                        </div>
                      ) : ''}
                    </TableCell>
                    <TableCell align="left">
                      {!user.verified ? (
                        <Button variant="contained" color="primary" onClick={() => {this.handleResendVerifyEmail(user);}}>{t('user_manager.button.resend')}</Button>
                      ) : '' }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Paper>
        <Dialog
        open={showDeleteUserDialog}
        onClose={this.handleToggleDeleteUser}
      >
        <DialogTitle id="delete-dialog-title">{t('user_manager.delete_user_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
          <Trans key="user_manager.delete_user_description" parent="span">
          Are you sure you want to delete the user with email {selectedUser ? selectedUser.email : ''}? This cannot be easily undone.</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleToggleDeleteUser} color="secondary" variant="contained">
            {t('user_manager.cancel_button')}
          </Button>
          <Button onClick={this.deleteUser} color="primary" variant="contained" autoFocus>
            {t('user_manager.delete_button')}
          </Button>
        </DialogActions>
      </Dialog>
      </section>
    );
  }
}

UserManager.propTypes = {
    users: PropTypes.array,
    isEditingUser: PropTypes.bool,
    isCreatingUser: PropTypes.bool,
    isUpdatingUser: PropTypes.bool,
    currentUser: PropTypes.object,
    resendVerify: PropTypes.func,
    stopEditingUser: PropTypes.func,
    startEditingUser: PropTypes.func,
    deleteUser: PropTypes.func,
    generalNotice: PropTypes.object,
    userNotice: PropTypes.object,
    passwordNotice: PropTypes.object,
    t: PropTypes.func,
    userRoles: PropTypes.object,
    handleUserFormSubmit: PropTypes.func,
    currentUserId: PropTypes.number,
    changeUserPassword: PropTypes.func,
    classes: PropTypes.object
};

export default compose(
  withStyles(() => ({
    addUserButton: {
      fontSize: '18px',
      '& button': {
        marginRight: '12px'
      },
    },
    usersContainer: {
      padding: '12px'
    },
    changePasswordToggle: {
      display: 'block',
      marginTop: '24px',
      color: '#009688',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  })),
  withTranslation()
)(UserManager);

