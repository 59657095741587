import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import SearchSentence from 'Components/Search/SearchSentence';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

const DigestPossibleSightings = (props) => {
    const {
      classes,
      digestData,
      removeCandidateClaimFromDigest,
      removeMatchedSighting,
      addMatchedSighting,
      includeFacebookTPFC,
      t
    } = props;

    let claimData = digestData.filter(data => includeFacebookTPFC || !data.claim.isTpfc);
    
    return (
      <div>
        <div className={classes.numberOfClaims}>
          <Typography variant="h3" component="h3">
          {t('digest_possible_sightings.alerts', { count: claimData.length})}
          </Typography>
        </div>
        <div className={classes.claimLayout}>
          {claimData.map(data => (
            <div className={classes.claimContent} key={data.claim.trackedClaimId}>
              <Typography variant="h5" component="h5">
                {t('digest_possible_sightings.claim', { 
                  claim: data.claim.claim, 
                  interpolation: { escapeValue: false }
                })} <a href={data.claim.factcheckUrl} target="_blank" className={classes.claimTitleLink}>
                <OpenInNewIcon className={classes.openInNewIcon} /></a>
              </Typography>
              {data.checkedSentences.map(stc => (
                  <SearchSentence
                      isConfirmed={stc.isConfirmedMatch}
                      confirmSighting={addMatchedSighting}
                      dismissSighting={removeCandidateClaimFromDigest}
                      mediaHash={stc.sentence.mediaHash}
                      url={stc.sentence.url}
                      publication={stc.publication}
                      text={stc.sentence.text}
                      category={data.claim.trackedClaimId + ''}
                      removeReviewedSighting={removeMatchedSighting}
                      key={stc.sentence.mediaHash+'_'+data.claim.trackedClaimId}
                      authorName={stc.sentence.authorName}
                      favicon={stc.favicon}
                  />
              ))}
            </div>
          ))}
        </div>
      </div>
    );
};


DigestPossibleSightings.propTypes = {
  classes: PropTypes.object,
  digestData: PropTypes.array,
  removeCandidateClaimFromDigest: PropTypes.func,
  removeMatchedSighting: PropTypes.func,
  addMatchedSighting: PropTypes.func,
  loadDigestCandidates: PropTypes.func,
  includeFacebookTPFC: PropTypes.bool,
  t:  PropTypes.func
};


export default compose(
  withTranslation(),
  withStyles((theme) => ({
    tpfcToggle: {
      float: 'right'
    },
    numberOfClaims: {
      marginTop: '20px'
    },
    digestHistoryDate: {
      marginBottom: '10px',
      marginRight: '4px',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    digestHistory: {
      float: 'right',
      color: '#555',
      clear: 'right',
      margin: '6px 0'
    },
    digestHistoryBack : {
      cursor: 'pointer',
      '& svg' : {
        fontSize: '20px'
      }
    },
    digestHistoryForward : {
      cursor: 'pointer',
      '& svg' : {
        fontSize: '20px'
      }
    },
    digestHistoryForwardDisabled : {
      '& svg' : {
        fontSize: '20px',
        color: '#ccc'
      }
    },
    claimLayout: {
      columnCount: '1',
      width: '100%'
    },
    '@media (min-width: 800px)' : {
      claimLayout: {
        columnCount: '2',
      }
    },
    claimContent: {
      breakInside: 'avoid-column',
      marginBottom: '16px'
    },
    openInNewIcon: {
        width: '15px',
        margin: '0 0 0 6px',
        verticalAlign: 'middle'
      },
    claimTitleLink: {
      color: theme.palette.primary[500]
    },
    content: {
      marginTop: '24px',
      maxWidth: '950px',
      margin: '0 auto'
    }
  })),
React.memo
)(DigestPossibleSightings);
