
const sightingCategories = {
  'online-newspapers': {
    title: 'Online Newspapers',
  },
  'social-media': {
    title: "Social Media",
  },
  'parliament': {
    title: "Parliament",
  },
  'podcast': {
    title: "Podcast"
  },
  'youtube-channel': {
    title: "YouTube Channel"
  },
  'newspaper': {
    title: "Newspaper"
  },
  'radio': {
    title: "Radio"
  },
  'government-data': {
    title: "Government Data"
  },
  'tv': {
    title: "TV"
  },
  'charity': {
    title: "Charity"
  },
  'press-release': {
    title: "Press Release"
  },
  'university': {
    title: "University"
  },
  'select-committee-hearing': {
    title: "Select Committee Hearing"
  },
  'website': {
    title: "Website"
  },
  'speech': {
    title: "Speech"
  }
};


export default sightingCategories;
