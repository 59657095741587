import { teal } from '@material-ui/core/colors';

const typography = {
  fontFamily: 'Roboto, Arial, sans-serif',
  h1: {
    marginBottom: '12px 0',
    fontSize: '34px',
    fontWeight: '400',
    lineHeight: '34px'
  },
  h2: {
    fontFamily: 'Nunito, Arial, sans-serif',
    fontStyle: 'italic',
    fontWeight: '400',
    marginBottom: '12px',
    fontSize: '28px'
  },
  h3: {
    fontFamily: 'Nunito, Arial, sans-serif',
    fontStyle: 'italic',
    fontSize: '24px',
    marginBottom: '12px',
    lineHeight: '1.2'
  },
  h4: {
    marginBottom: '12px',
    fontStyle: 'italic',
    fontSize: '20px'
  },
  h5: {
    marginBottom: '12px',
    fontSize: '18px',
    fontWeight: 'bold'
  },
  h6: {
    marginBottom: '12px',
    fontSize: '18px',
    fontWeight: '400'
  }
};

const theme = {
  light: {
    palette: {
      primary: teal
    },
    typography: {
      fontFamily: typography.fontFamily,
      h1: {
        ...typography.h1,
        color: '#000'
      },
      h2: {
        ...typography.h2,
        color: '#000'
      },
      h3: {
        ...typography.h3,
        color: '#555'
      },
      h4: {
        ...typography.h4,
        color: '#555'
      },
      h5: {
        ...typography.h5,
        color: '#000'
      },
      h6: {
        ...typography.h6,
        color: '#000'
      }
    },
    outline: {
      boxShadow: 'none',
      border: '1px solid rgba(0, 0, 0, 0.12)'
    }
  },
  dark: {
    palette: {
      primary: teal,
      type: 'dark'
    },
    outline: {
      boxShadow: 'none',
      border: '1px solid rgba(0, 0, 0, 0.12)'
    },
    typography: {
      fontFamily: typography.fontFamily,
      h1: {
        ...typography.h1,
        color: '#fff'
      },
      h2: {
        ...typography.h2,
        color: '#fff'
      },
      h3: {
        ...typography.h3,
        color: '#555'
      },
      h4: {
        ...typography.h4,
        color: '#555'
      },
      h5: {
        ...typography.h5,
        color: '#fff'
      },
      h6: {
        ...typography.h6,
        color: '#fff'
      },
      subtitle1: {
        color: '#fff'
      }
    }
  }
};

export default theme;