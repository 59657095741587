const claimTypePriorities =  {
  quantity: 10,
  correlation: 6,
  not_claim: 1,
  opinion: 3,
  other: 2,
  personal: 4,
  predictions: 7,
  rules: 8,
  support: 5,
  voting: 9
};

export default claimTypePriorities;
