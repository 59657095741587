const mediaCategories = {
  "online-newspapers": "media_categories.online_newspapers",
  "social-media": "media_categories.social_media",
  "parliament": "media_categories.parliament",
  "charity": "media_categories.charity",
  "government-data": "media_categories.government-data",
  "newspaper": "media_categories.newspaper",
  "podcast": "media_categories.podcast",
  "youtube-channel": "media_categories.youtube_channel",
  "press-release": "media_categories.press-release",
  "radio": "media_categories.radio",
  "select-committee-hearing": "media_categories.select-committee-hearing",
  "tv":"media_categories.tv",
  "website": "media_categories.website",
  "speech": "media_categories.speech"
};

export default mediaCategories;
