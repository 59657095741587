import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import UserChangePasswordFormContainer from 'Components/User/UserChangePasswordFormContainer';
import Notice from 'Components/Common/Notice';
import NavSiteLogo from 'Components/Nav/NavSiteLogo';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

export class VerifyPasswordPage extends React.Component {

  constructor(props){
    super(props);

    const urlParams = new URLSearchParams(this.props.location.search);

    this.state = {
      token: urlParams.get('token')
    };

  }

  render() {
    const {t, classes} = this.props;
    const {token} = this.state;
    return (
      <section>
        <NavSiteLogo />
      {token ? (
        <div>
          <h4 className={classes.verifyText}>{t('verify_password_page.set_a_password')}</h4>
          <UserChangePasswordFormContainer token={token} />
        </div>
      ): (
        <Notice message={t('verify_password_page.not_token_summary')} />
      )}
      </section>
    );
  }
}

VerifyPasswordPage.propTypes = {
  location: PropTypes.object,
  t:  PropTypes.func,
  classes: PropTypes.object
};

export default compose(
  withTranslation(),
  withStyles(() => ({
    verifyText: {
      textAlign: 'left',
      fontSize: '16px'
    }
  }))
)(VerifyPasswordPage);
