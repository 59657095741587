import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
export function SearchClaim(props) {

  const {
    classes,
    claim
  } = props;

  return (
    <Card className={classes.claimCard} variant="outlined">
      <a className={classes.contentLink} href={claim.url} target="_blank">
        {claim.text}<OpenInNewIcon className={classes.openInNewIcon} />
      </a>
    </Card>
  );
}

SearchClaim.propTypes  = {
  classes: PropTypes.object,
  claim: PropTypes.object,
  url: PropTypes.string,
};

export default compose(
  withStyles((theme) => ({
    claimCard: {
      position: 'relative',
      boxShadow: theme.outline.boxShadow,
      border: theme.outline.border,
      marginBottom: '15px'
    },
    openInNewIcon: {
      width: '15px',
      margin: '0 0 0 6px',
      verticalAlign: 'middle'
    },
    contentLink: {
      display: 'block',
      color: '#000',
      textDecoration: 'none',
      lineHeight: '1.4',
      padding: '15px',
      '&:hover' : {
        color: theme.palette.primary[500]
      }
    }
  }))
)(SearchClaim);