import { put, takeEvery, select } from 'redux-saga/effects';
import candidateConstants from 'Constants/candidateConstants';

export function* processGetCandidates(action) {

  let body = {};

  if (action.exactPhrase && action.exactPhrase.length) body.fulltext = action.exactPhrase.map(word => word.toLowerCase());
  if (action.hasWords && action.hasWords.length) body.included_words = action.hasWords.map(word => word.toLowerCase());
  if (action.excludeWords && action.excludeWords.length) body.excluded_words = action.excludeWords.map(word => word.toLowerCase());
  if (action.startDate && action.endDate) body.date_range = {
    start: action.startDate,
    end: action.endDate
  };
  if (action.publications && action.publications.length) body.publications = action.publications;
  if (action.claimants && action.claimants.length) body.claimants = action.claimants;
  if (action.categories && action.categories.length) body.media_types = action.categories;
  if (action.types && action.types.length) body.claim_types = action.types;
  if (action.claimantGroups && action.claimantGroups.length) body.claimant_groups = action.claimantGroups;
  if (action.checkedClaims && action.checkedClaims.length) body.matched_claims = action.checkedClaims[0] === 'exists' ? action.checkedClaims[0] : action.checkedClaims.map(claim => (parseInt(claim, 10)));

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      method: 'POST',
      path: '/candidates/search/',
      body: body
    }
  });
}

export function* processGetExactCandidates(action) {

  let body = {};

  if (action.hasWords && action.hasWords.length) body.fulltext = ["\"" + action.hasWords.map(word => word.toLowerCase()).join(' ') + "\""];
  if (action.excludeWords && action.excludeWords.length) body.excluded_words = action.excludeWords.map(word => word.toLowerCase());
  if (action.startDate && action.endDate) body.date_range = {
    start: action.startDate,
    end: action.endDate
  };
  if (action.publications && action.publications.length) body.publications = action.publications;
  if (action.claimants && action.claimants.length) body.claimants = action.claimants;
  if (action.categories && action.categories.length) body.media_types = action.categories;
  if (action.types && action.types.length) body.claim_types = action.types;
  if (action.claimantGroups && action.claimantGroups.length) body.claimant_groups = action.claimantGroups;
  if (action.checkedClaims && action.checkedClaims.length) body.matched_claims = action.checkedClaims[0] === 'exists' ? action.checkedClaims[0] : action.checkedClaims.map(claim => (parseInt(claim, 10)));

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      method: 'POST',
      path: '/candidates/search/',
      body: body
    }
  });
}



export function* processGetDigestCandidates(action) {


  let body = {};

  if (action.startDate && action.endDate) body.date_range = {
    start: action.startDate,
    end: action.endDate
  };
  body.matched_claims = 'exists';
  body.matched_claims_threshold = action.threshold;

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      method: 'POST',
      path: '/candidates/alerts/',
      body: body
    }

  });
}


export function* processGetMoreCandidates(action) {

  let body = {};

  if (action.exactPhrase && action.exactPhrase.length) body.fulltext = action.exactPhrase.map(word => word.toLowerCase());
  if (action.hasWords && action.hasWords.length) body.included_words = action.hasWords.map(word => word.toLowerCase());
  if (action.excludeWords && action.excludeWords.length) body.excluded_words = action.excludeWords.map(word => word.toLowerCase());
  if (action.startDate && action.endDate) body.date_range = {
    start: action.startDate,
    end: action.endDate
  };
  if (action.publications && action.publications.length) body.publications = action.publications;
  if (action.claimants && action.claimants.length) body.claimants = action.claimants;
  if (action.categories && action.categories.length) body.media_types = action.categories;
  if (action.types && action.types.length) body.claim_types = action.types;
  if (action.claimantGroups && action.claimantGroups.length) body.claimant_groups = action.claimantGroups;
  if (action.checkedClaims && action.checkedClaims.length) body.matched_claims = action.checkedClaims[0] === 'exists' ? action.checkedClaims[0] : action.checkedClaims.map(claim => (parseInt(claim, 10)));

  if (action.nextCursor) {
    body.next_cursor = action.nextCursor;

    yield put({
      type: 'FETCH',
      requestType: action.type,
      payload: {
        method: 'POST',
        path: '/candidates/search/',
        body: body
      }
    });
  }
}


export function* processGetClaimants(action) {

  let body = { "prefix": action.query || '' };

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      method: 'POST',
      path: '/candidates/autocomplete/',
      body: body
    }
  });
}

export function* processGetPublications(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/publications_v2/`,
    }
  });

}

export function* processGetTopicsCandidates(action) {
  let body = {
    page_size: 40,
  };
  if (action.startDate && action.endDate) body.date_range = {
    start: action.startDate,
    end: action.endDate,
  };
  if (action.topic) body.checkworthy_topics = [action.topic];

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      method: 'POST',
      path: '/candidates/checkworthy/',
      body: body
    }
  });
}

export function* processGetTopicSummaries(action) {
  yield put({
    type: 'FETCH',
    requestType: candidateConstants.CANDIDATES_GET_TOPICS,
    payload: {
      method: 'POST',
      path: '/candidates/checkworthy/',
      body: {
        page_size: 20,
        date_range: {
          start: action.startDate,
          end: action.endDate,
        },
      }
    },
  });
}

export function* processDismissCheckworthySighting(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      method: 'PATCH',
      path:  `/reviewed_media/checkworthy/`,
      body: {
        media_hash: action.mediaHash,
        topic: action.topic,
        feedback: -1
      }
    },
    request: {
      topic: action.topic,
      mediaHash: action.mediaHash
    }
  });
}

export function* processConfirmCheckworthySighting(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      method: 'PATCH',
      path:  `/reviewed_media/checkworthy/`,
      body: {
        media_hash: action.mediaHash,
        topic: action.topic,
        feedback: 1
      }
    },
    request: {
      topic: action.topic,
      mediaHash: action.mediaHash
    }
  });
}

export function* processDeleteCheckworthySighting(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      method: 'PATCH',
      path:  `/reviewed_media/checkworthy/`,
      body: {
        media_hash: action.mediaHash,
        topic: action.topic,
        feedback: 0
      }
    },
    request: {
      topic: action.topic,
      mediaHash: action.mediaHash
    }
  });
}


export function* candidateSagas() {
  yield takeEvery(candidateConstants.CANDIDATES_GET, processGetCandidates);
  yield takeEvery(candidateConstants.CANDIDATES_GET_DIGEST, processGetDigestCandidates);
  yield takeEvery(candidateConstants.CANDIDATES_GET_MORE, processGetMoreCandidates);
  yield takeEvery(candidateConstants.CLAIMANTS_GET_AUTOCOMPLETE, processGetClaimants);
  yield takeEvery(candidateConstants.GET_PUBLICATIONS, processGetPublications);
  yield takeEvery(candidateConstants.CANDIDATES_GET_TOPICS, processGetTopicsCandidates);
  yield takeEvery(candidateConstants.CANDIDATES_GET_TOPIC_SUMMARIES, processGetTopicSummaries);
  yield takeEvery(candidateConstants.DISMISS_CHECKWORTHY_SIGHTING, processDismissCheckworthySighting);
  yield takeEvery(candidateConstants.DELETE_CHECKWORTHY_SIGHTING, processDeleteCheckworthySighting);
  yield takeEvery(candidateConstants.CONFIRM_CHECKWORTHY_SIGHTING, processConfirmCheckworthySighting);
}
