import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UserAreaContainer from 'Components/User/UserAreaContainer';

export class LoginPage extends Component {

  render() {
    return <UserAreaContainer />;
  }
}

LoginPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default LoginPage;
