export interface ApiUserType {
    display_name: string;
    email: string,
    organisation_id: number,
    organisation_name: string,
    role: string,
    user_id: number,
    verified: boolean
}

export interface UserType {
    userId: number;
    email: string;
    name: string;
    role: string;
    verified: boolean;
}

export const User = {
    fromApi: (obj) => {
        return {
            userId: obj.user_id,
            email: obj.email,
            name: obj.display_name,
            role: obj.role,
            verified: obj.verified
        }
    },
    toApi: (obj) => {
        return {
            email: obj.email,
            display_name: obj.name,
            role: obj.role
        }
    }
};

export default User;


