// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
(function () {
function id(x) { return x[0]; }
var grammar = {
    Lexer: undefined,
    ParserRules: [
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", "wschar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": function(d) {return null;}},
    {"name": "__$ebnf$1", "symbols": ["wschar"]},
    {"name": "__$ebnf$1", "symbols": ["__$ebnf$1", "wschar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "__", "symbols": ["__$ebnf$1"], "postprocess": function(d) {return null;}},
    {"name": "wschar", "symbols": [/[ \t\n\v\f]/], "postprocess": id},
    {"name": "Query$ebnf$1", "symbols": []},
    {"name": "Query$ebnf$1", "symbols": ["Query$ebnf$1", "SubQueryTail"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "Query", "symbols": ["SubQuery", "Query$ebnf$1"], "postprocess": 
        ([headQuery, tailQuery]) => {
        	tailQuery = tailQuery.map(([query]) => ( query ))
        	return headQuery.concat(tailQuery)
        }
        },
    {"name": "SubQueryTail", "symbols": ["__", "SubQuery"], "postprocess": 
        ([whitespace, query]) => ( query )
        },
    {"name": "SubQuery$subexpression$1", "symbols": ["CodeQuery"]},
    {"name": "SubQuery$subexpression$1", "symbols": ["ExactPhrase"]},
    {"name": "SubQuery$subexpression$1", "symbols": ["HasExcludedWords"]},
    {"name": "SubQuery$subexpression$1", "symbols": ["HasIncludedWords"]},
    {"name": "SubQuery", "symbols": ["SubQuery$subexpression$1"], "postprocess": 
        ([query]) => ( query )
        },
    {"name": "HasExcludedWords", "symbols": [{"literal":"-"}, "Atom"], "postprocess": 
        ([hyphen, word]) => ( { excludedWords: [word] } )
        },
    {"name": "HasIncludedWords", "symbols": ["Atom"], "postprocess": 
        ([word]) => ( { hasWords: [word] } )
        },
    {"name": "ExactPhrase", "symbols": ["QuotedString"], "postprocess": 
        ([phrase]) => ( { exactPhrase: [phrase] } )
        },
    {"name": "CodeQuery$ebnf$1", "symbols": [/[a-z]/]},
    {"name": "CodeQuery$ebnf$1", "symbols": ["CodeQuery$ebnf$1", /[a-z]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "CodeQuery", "symbols": ["CodeQuery$ebnf$1", {"literal":":"}, "StringQueries"], "postprocess": 
        ([code, colon, list]) => ( {  [code.join("")]: list } )
        },
    {"name": "StringQueries$ebnf$1", "symbols": []},
    {"name": "StringQueries$ebnf$1", "symbols": ["StringQueries$ebnf$1", "StringQueriesTail"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "StringQueries", "symbols": ["StringQuery", "StringQueries$ebnf$1"], "postprocess": 
        ([headQuery, tailQuery]) => {
        	tailQuery = tailQuery.map(([query]) => {
        	 	return query
        	})
        	return headQuery.concat(tailQuery)
        }
        },
    {"name": "StringQueriesTail", "symbols": [{"literal":","}, "StringQuery"], "postprocess": 
        ([comma, query]) => ( query )
        },
    {"name": "StringQuery$subexpression$1", "symbols": ["StringAtom"]},
    {"name": "StringQuery$subexpression$1", "symbols": ["QuotedString"]},
    {"name": "StringQuery", "symbols": ["StringQuery$subexpression$1"], "postprocess": 
        ([text]) => ( text )
        },
    {"name": "StringAtom$ebnf$1", "symbols": []},
    {"name": "StringAtom$ebnf$1", "symbols": ["StringAtom$ebnf$1", /[^:", \t\n\v\f]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "StringAtom", "symbols": [/[^-", \t\n\v\f]/, "StringAtom$ebnf$1"], "postprocess": 
        ([firstChar, text]) => ( firstChar + text.join("") )
        },
    {"name": "Atom$ebnf$1", "symbols": []},
    {"name": "Atom$ebnf$1", "symbols": ["Atom$ebnf$1", /[^:" \t\n\v\f]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "Atom", "symbols": [/[^-" \t\n\v\f]/, "Atom$ebnf$1"], "postprocess": 
        ([firstChar, text]) => ( firstChar + text.join("") )
        },
    {"name": "QuotedString$ebnf$1", "symbols": []},
    {"name": "QuotedString$ebnf$1", "symbols": ["QuotedString$ebnf$1", "QuotedCharacter"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "QuotedString", "symbols": [{"literal":"\""}, "QuotedString$ebnf$1", {"literal":"\""}], "postprocess": 
        ([quote, text]) => ( text.join("") )
        },
    {"name": "QuotedCharacter", "symbols": [/[^"]/], "postprocess": 
        ([text]) => ( text )
        }
]
  , ParserStart: "Query"
}
if (typeof module !== 'undefined'&& typeof module.exports !== 'undefined') {
   module.exports = grammar;
} else {
   window.grammar = grammar;
}
})();
