import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import TranscriptScheduleDays from 'Components/Transcript/TranscriptScheduleDays';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import SortableTable from 'Components/Common/SortableTable';
import InfoIcon from '@material-ui/icons/Info';
import DoneAllIcon from '@material-ui/icons/DoneAll';

function MediaSchedulesTable(props) {

  const {
    classes,
    addSchedule,
    schedules,
    editSchedule,
    t,
    canEdit,
    showDeleteScheduleDialog
  } = props;

  
  return (
    <div>
        <div className={classes.addMediaButton}>
        {canEdit && <Button type="submit" className="addScheduleButton" variant="contained" color="primary" onClick={addSchedule}>{t('organisation_media.add_schedule')}</Button>}
        </div>
        {schedules?.length > 0 && (
        <SortableTable
        rows={schedules}
        columns={[
            {
                width: 90,
                label: ' ',
                disableSort: true,
                dataKey: 'edit',
                formatting: (data, rowData) => {
                if(canEdit) {
                    return (
                        <Button onClick={() => {
                        editSchedule(rowData);
                        }} color="primary" variant="contained">{t('organisation_media.button.edit')}</Button>       
                    );
                } else {
                    return null;
                }
                }
            },
            {
                label: t('organisation_media.column_header.schedule_name'),
                dataKey: 'display_name'
            },

            {
                label: t('organisation_media.column_header.source'),
                dataKey: 'displayRadioStation'
            },
            {
                label: t('organisation_media.column_header.days'),
                dataKey: 'days',
                formatting: (data) => {
                    return (<TranscriptScheduleDays days={data} />);
                }
            },
            { 
                label: t('organisation_media.column_header.timezone'),
                dataKey: 'timezone'
            },
            {
                
                label: t('organisation_media.column_header.most_recent'),
                dataKey: 'most_recent_transcript_time',
                formatting: (data, rowData) => {
                    if(rowData.recent_transcripts && rowData.recent_transcripts.length) {
                        return (
                        <span>
                            <Link to={"/radio-transcript/" + rowData.recent_transcripts[0].transcriptId}>
                            {moment.utc(data).local().format('YYYY-MM-DD HH:mm:ss')}
                            {rowData.recent_transcripts[0].statistics && (<span>
                                {rowData.recent_transcripts[0].statistics.matched_claim_count > 0 && (<Tooltip title="Claim matches found"><span className={classes.statsCount}><DoneAllIcon /></span></Tooltip>)}
                            </span> )}
                            </Link>
                        </span>
                        );
                    }
                    return '';
                }
            },
            {
                label: t('organisation_media.column_header.next_transcript'),
                dataKey: 'next_expected_transcript_time',
                formatting: (data) => {
                    if(data && data.length) {
                        return moment.utc(data).local().format('YYYY-MM-DD HH:mm:ss');
                    }
                    return '';
                }
            },
            {
                label: t('organisation_media.column_header.details'),
                disableSort: true,
                dataKey: 'transcript_schedule_id',
                formatting: (data, rowData) => {
                    return (
                    <Tooltip title={t('organisation_media.button.view_schedule_details')}>
                    <Link to={"/schedules/" + rowData.uuid}>
                        <InfoIcon />
                    </Link>
                    </Tooltip>
                );
                }
            },
            {
                width: 90,
                label: ' ',
                disableSort: true,
                dataKey: 'Delete',
                formatting: (data, rowData) => {
                if(canEdit) {
                    return (
                    <Tooltip title={t('organisation_media.delete_schedule')}>
                        <IconButton size="small" onClick={() => { showDeleteScheduleDialog(rowData);}}><DeleteIcon /></IconButton>
                    </Tooltip>
                    );
                } else {
                    return null;
                }
                }
            }
            ]}
        />
        )}
    </div>
  );
}

export default compose(
  withTranslation(),
  withStyles((theme) => ({
    addMediaButton: {
        marginBottom: '12px',
        paddingTop: '16px',
        marginLeft: '16px',
        display: 'block'
    },
    viewAllTranscriptsLink: {
        color: theme.palette.primary[500],
        margin: '0 0 16px 16px',
        fontSize: '14px',
        display: 'inline-block'
      }
  }))
)(MediaSchedulesTable);

MediaSchedulesTable.propTypes = {
  classes: PropTypes.object,
  t: PropTypes.func,
  addSchedule: PropTypes.func,
  schedules: PropTypes.array,
  editSchedule: PropTypes.func,
  canEdit: PropTypes.bool,
  showDeleteScheduleDialog: PropTypes.func
};
