import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Notice from 'Components/Common/Notice';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import candidateActions from 'Actions/candidateActions';
import claimActions from 'Actions/claimActions';
import candidateSelectors from 'Selectors/candidateSelectors';
import authSelectors from 'Selectors/authSelectors';
import claimSelectors from 'Selectors/claimSelectors';
import DigestPossibleSightings from 'Components/Digest/DigestPossibleSightings';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export class AlertsPage extends React.Component {

  constructor(props){
    super(props);

    let yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
    this.handleGetCandidates(yesterday);
    this.state = {
      claimsToExpand: [],
      includeFacebookTPFC: false,
      currentDay: yesterday,
      daysToSubtract: 1
    };
  }

  handleGetCandidates = (day) => {
    this.props.loadPublications();

    let startTime = moment(day+ " "+ "00:00:00").locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');
    let endTime =  moment(day+ " "+ "23:59:59").locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');
    this.props.loadDigestCandidates(
      startTime,
      endTime,
      this.props.claimMatchThreshold
    );
  }

  removeCandidateClaimFromDigest = (claimId, mediaHash) => {
    this.props.dismissSighting(claimId, mediaHash);
  };

  addMatchedSighting = (claimId, mediaHash) => {
    this.props.addMatchedSighting(claimId, mediaHash);
  }

  removeMatchedSighting = (claimId, mediaHash) =>{
    this.props.removeMatchedSighting(claimId, mediaHash);
  }

  handleSetIncludeFacebookTPFC = () => {
    this.setState({ includeFacebookTPFC : !this.state.includeFacebookTPFC });
  };

  handleForwardOneDay = () => {
    if(this.state.daysToSubtract > 1){
      let selectedDay = this.state.daysToSubtract - 1;
      let day = moment().subtract(selectedDay, 'days').format('YYYY-MM-DD');
      this.handleGetCandidates(day);
      this.setState({
        currentDay: day,
        daysToSubtract: selectedDay
      });
    }
  };

  handleBackwardOneDay = () => {
      let selectedDay = this.state.daysToSubtract + 1;
      let day = moment().subtract(selectedDay, 'days').format('YYYY-MM-DD');
      this.handleGetCandidates(day);
      this.setState({
        currentDay: day,
        daysToSubtract: selectedDay
      });
  };

  render () {
    const {
      classes,
      t,
      digestData,
      loadDigestCandidates,
      candidatesRequest,
      reviewedRequest,
      permissions,
      hasTPFC
    } = this.props;
    const {
      includeFacebookTPFC,
      daysToSubtract,
      currentDay
    } = this.state;

    return (
      <div className="row container">

        <div className="col-xs4-4">
        <div className={classes.content}>

        <Typography variant="h2" component="h2">{t('digest_page.alerts')}</Typography>
        <div className={classes.explanationText}>
        <Trans i18nKey="digest_page.alerts_are_generated" parent="div">
          Alerts are generated for your organisation’s <Link className={classes.sightingsLink} to="/alerts/claims">claims</Link>. They can be confirmed or dismissed, with a record kept for all <Link className={classes.sightingsLink} to="/alerts/reviewed">previously reviewed alerts</Link>.
          </Trans>
        </div>
          {hasTPFC && (<div className={classes.tpfcToggle}>
            <Switch
              checked={includeFacebookTPFC}
              onChange={this.handleSetIncludeFacebookTPFC}
              name="includeFacebookTPFC"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            /> {t('digest_page.include_tpfc')}
          </div>)
          }
        <div className={classes.digestHistory}>
          <span className={classes.digestHistoryBack} onClick={()=>{ this.handleBackwardOneDay();}}><ArrowBackIosIcon className={classes.arrorBackRoot} /></span>
          <span className={classes.digestHistoryDate}>{currentDay}</span>
          {daysToSubtract === 1 ? (
            <span className={classes.digestHistoryForwardDisabled}><ArrowForwardIosIcon className={classes.arrorForwardRoot} /></span>
          ) : (
            <span className={classes.digestHistoryForward} onClick={()=>{ this.handleForwardOneDay();}}><ArrowForwardIosIcon  className={classes.arrorForwardRoot} /></span>
          )}
        </div>
          <Box>
          {candidatesRequest === 'pending' || reviewedRequest === 'pending' ? (
            <div> <CircularProgress /> </div>
          ) : (
            <div>
              {candidatesRequest === 'failed' || reviewedRequest === 'failed' ? (
                  <div> <Notice message={t('digest_page.an_error_occurred')} type={'error'}/> </div>
              ): (
                <DigestPossibleSightings
                  digestData={digestData}
                  includeFacebookTPFC={includeFacebookTPFC}
                  removeCandidateClaimFromDigest={this.removeCandidateClaimFromDigest}
                  removeMatchedSighting={this.removeMatchedSighting}
                  addMatchedSighting={this.addMatchedSighting}
                  loadDigestCandidates={loadDigestCandidates}
                />
              )}
            </div>
          )}
          </Box>
        </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    digestData: candidateSelectors.getDigestData(state),
    claimMatchThreshold: candidateSelectors.getClaimMatchThreshold(state),
    candidatesRequest: candidateSelectors.getCandidatesRequestState(state),
    reviewedRequest: claimSelectors.getReviewedSightingsRequestState(state),
    permissions: authSelectors.getPermissions(state),
    hasTPFC: claimSelectors.getHasTPFC(state)
  };
}

const mapDispatchToProps = {
  getMatchedSightings: claimActions.getMatchedSightings,
  loadDigestCandidates: candidateActions.loadDigestCandidates,
  loadPublications: candidateActions.loadPublications,
  addMatchedSighting: claimActions.addMatchedSighting,
  removeMatchedSighting: claimActions.removeMatchedSighting,
  dismissSighting: claimActions.dismissSighting
};

AlertsPage.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object,
  themeColor: PropTypes.string,
  locale: PropTypes.string,
  authActions: PropTypes.object,
  classes:  PropTypes.object,
  candidateActions: PropTypes.object,
  claimActions: PropTypes.object,
  digestData: PropTypes.array,
  interventionActions: PropTypes.object,
  isEditingIntervention: PropTypes.bool,
  publications: PropTypes.array,
  sightingCategories: PropTypes.array,
  loadDigestCandidates: PropTypes.func,
  getDigestSentences: PropTypes.func,
  reviewedRequest: PropTypes.string,
  candidatesRequest: PropTypes.string,
  getMatchedSightings: PropTypes.func,
  dismissSighting: PropTypes.func,
  removeMatchedSighting: PropTypes.func,
  addMatchedSighting: PropTypes.func,
  permissions: PropTypes.array,
  loadPublications: PropTypes.func,
  claimMatchThreshold: PropTypes.number,
  hasTPFC: PropTypes.bool
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(),
  withStyles((theme) => ({
    arrorForwardRoot: {
      transform: theme.direction === "rtl" ? "scaleX(-1)" : undefined
    },
    arrorBackRoot: {
      transform: theme.direction === "rtl" ? "scaleX(-1)" : undefined
    },
    explanationText: {
      marginBottom: '24px',
      border: '1px solid #ccc',
      padding: '15px',
      borderRadius: '5px',
      lineHeight: '1.4'
    },
    content: {
      marginTop: '24px',
      maxWidth: '950px',
      margin: '0 auto'
    },
    actionCount: {
      textAlign: 'center',
      display: 'block',
      marginBottom: '6px'
    },
    sightingsLink: {
      color: theme.palette.primary[500]
    },
    tpfcToggle: {
      float: 'right'
    },
    digestHistory: {
      float: 'right',
      color: '#555',
      clear: 'right',
      margin: '6px 0',
    },
    digestHistoryBack : {
      cursor: 'pointer',
      '& svg' : {
        fontSize: '20px'
      }
    },
    digestHistoryForward : {
      cursor: 'pointer',
      '& svg' : {
        fontSize: '20px'
      }
    },
    digestHistoryForwardDisabled : {
      '& svg' : {
        fontSize: '20px',
        color: '#ccc'
      }
    },
    digestHistoryDate: {
      marginBottom: '10px',
      marginRight: '4px',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
}))
)(AlertsPage);
