import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TranscriptCardList from 'Components/Transcript/TranscriptCardList';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

export function TranscriptTypeOverview(props){
  const { 
    classes, 
    t,
    typeTitle,
    code,
    channels,
    transcripts,
    handleDeleteTranscript,
    handleFormToggle,
    handleViewAll
  } = props;

  const [typeLimit, setTypeLimit] = React.useState(3);

  return (
      <div className={classes.transcriptCardsContainer}>
        <Typography variant="h3">{!['adhoc','podcast'].includes(code) && (<Fab color="primary" aria-label={"add "+code+" transcript"}  size="small" onClick={handleFormToggle} className={classes.addButton}><AddIcon /></Fab>)} <span className={classes.typeTitle}>{typeTitle}</span></Typography> 
        <TranscriptCardList
          transcripts={transcripts}
          channels={channels}
          limit={typeLimit}
          handleDelete={handleDeleteTranscript}
          hasCols={true}
        />
        {transcripts?.length !== 0 && (
          <>
          {typeLimit < 6 ? (
          <div className={classes.viewTranscripts} style={{marginLeft: '12px', cursor: 'pointer'}}  onClick={() => { setTypeLimit(6);}}>{t('live_page.button.view_more')}</div>
          ) : (
            <span className={classes.viewTranscripts} onClick={() => { handleViewAll() }}style={{marginLeft: '12px'}} to={"/live/transcripts/" + code}>{t('live_page.button.view_all')}</span>
          )}
          </>
        )}
      </div>
  );
}

TranscriptTypeOverview.propTypes = {
    classes: PropTypes.object,
    t:  PropTypes.func,
    typeTitle: PropTypes.string,
    code: PropTypes.string,
    channels: PropTypes.array,
    transcripts: PropTypes.array,
    count: PropTypes.number,
    handleDeleteTranscript: PropTypes.func,
    handleFormToggle: PropTypes.func,
    handleViewAll: PropTypes.func
};


export default compose(
  withTranslation(),
  withStyles((theme) => ({
    transcriptCardsContainer: {
      marginBottom: '24px'
    },
    addScheduleButton: {
        marginLeft: '24px'
      },
      latestTranscripts: {
        marginTop: '24px'
      },
      viewTranscripts: {
        cursor: 'pointer',
        color: theme.palette.primary[500],
        display: 'inline-block',
        marginBottom: '24px',
        fontSize: '16px',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      },
      '@media (min-width: 640px)' : {
        transcriptsRow: {
          padding: '0 12px'
        }
      },
      pageTitle: {
        color: theme.palette.text.primary
      },
      channelList: {
        padding: '40px',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid '+ theme.palette.divider,
        borderWidth: '1px 0',
        textAlign: 'center',
        marginTop: '0',
        marginLeft: '-12px',
        marginRight: '-12px',
        marginBottom: '24px'
      },
      linkRow: {
        marginTop: '-24px'
      },
      radioTranscriptsMovedMessage: {
        padding: '8px'
      },
      typeTitle: {
        marginRight: '12px',
        verticalAlign: 'middle'
      },
      addButton : {
        marginRight: '8px'
      }
  }))
)(TranscriptTypeOverview);
