import React from 'react';
import PropTypes from 'prop-types';
import OrganisationMedia from 'Components/User/OrganisationMedia';

export class MediaPage extends React.Component {

  constructor(props){
    super(props);
  }

  render() {

    const sectionName = this.props.match.params['section'] || null;
    return (
      <div className="row container">
        <div className="col-xs4-4">
          <OrganisationMedia sectionName={sectionName} history={this.props.history} />
        </div>
      </div>
    );
  }
}

MediaPage.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object
};


export default MediaPage;
