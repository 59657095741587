import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

export function UserForm(props){

  const existingUser = Boolean(props.user && props.user.name);

  const [user, setUser] = React.useState(props.user || { name: '', role: 'factchecker', email: ''});

  const {
    currentUserId,
    onSubmit,
    roles,
    t,
    classes
  } = props;

  const onFieldChange = (e) => {
    setUser({
        ...user,
        [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      onSubmit(user);
  };

  return (
      <form className={classes.userForm} onSubmit={handleSubmit}>
        <TextField
          type="text"
          name="name"
          value={user.name}
          onChange={onFieldChange}
          label={t('user_form.name_label')}
          fullWidth={true}
          margin="normal"
          required
        />
        {currentUserId !== user.userId  ? (
        <TextField
          type="text"
          name="email"
          value={user.email}
          onChange={onFieldChange}
          label={t('user_form.email_label')}
          fullWidth={true}
          margin="normal"
          required
          disabled={existingUser}
          inputProps={{ 'data-testid': 'email-input' }} 
        /> ) : ""}
        <div className={classes.roleFormControl}>
        <FormControl fullWidth={true}>
          <InputLabel htmlFor="role">{t('user_form.role_label')}</InputLabel>
          <Select
            fullWidth={true}
            value={user.role}
            onChange={onFieldChange}
            inputProps={{
              name: 'role',
              id: 'role',
            }}
          >
            {roles.map(item => (
              <MenuItem key={item.value} value={item.value}>{t(item.label)}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
        <div className={classes.userFormSubmit}>
        {existingUser ? (
          <Button role="button" type="submit" variant="contained" color="primary">{t('user_form.update_button')}</Button>
        ): (
          <Button role="button"  type="submit" variant="contained" color="primary">{t('user_form.add_button')}</Button>
        )}
        </div>
      </form>
  );
}

UserForm.propTypes = {
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentUserId: PropTypes.number,
  t: PropTypes.func,
  classes: PropTypes.object
};

export default compose(
  withStyles(() => ({
    userForm: {
      marginBottom: '12px'
    },
    userFormSubmit: {
      marginTop: '23px'
    },
    roleFormControl: {
      marginTop: '23px'
    }
  })),
  withTranslation()
)(UserForm);
