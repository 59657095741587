import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import EnhancedTable from 'Components/Common/EnhancedTable';
import Button from '@material-ui/core/Button';
import Papa from 'papaparse';
import StarIcon from '@material-ui/icons/Star';
import InfoIcon from '@material-ui/icons/Info';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import ClaimControlPanel from 'Components/Claim/ClaimControlPanel';
import IconButton from '@material-ui/core/IconButton';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

export function ClaimManager(props) {
  const [filterText, setFilterText] = React.useState('');
  const [filters, setFilters] = React.useState({});
  const [dates, setDates] = React.useState(null);
  const {
    claims,
    classes,
    handleDelete,
    handleEditClaim,
    handleStarClaim,
    handleUnstarClaim,
    canAddClaims,
    canViewClaimDetails,
    height,
    t
  } = props;

  function applyFilters(modifiedFilters) {
    setFilters(modifiedFilters);
  }

  function generateCSV(claims) {

    let csvContent;
    if(claims) {
      csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(Papa.unparse(claims, { columns: ['claim', 'conclusion', 'factcheckUrl', 'createdAt', 'updatedAt']}));
    }
    return csvContent;
  }

    let startDateNum;
    let endDateNum;

    let modifiedData = claims;

    if(dates) {
      if(dates.startDate) startDateNum = parseInt((dates.startDate.format('YYYYMMDD')),10);
      if(dates.endDate) endDateNum = parseInt((dates.endDate.format('YYYYMMDD')),10);
    }

    modifiedData = modifiedData.filter(item => {
      let includeItem = true;
      if(filterText && filterText !== '') {
        includeItem = item.claim.toLowerCase().includes(filterText.toLowerCase());
      }

      if(includeItem && (startDateNum || endDateNum)) {
        let withinRange = false;
        if(item.createdAt) {
          let simpleDate = item.createdAt.split('T')[0];
          let numberDate = parseInt(simpleDate.replace(/-/gi, ''),10);
          let afterStart = numberDate >= startDateNum;
          let beforeEnd = numberDate <= endDateNum;

          if(startDateNum && endDateNum) {
            withinRange = afterStart && beforeEnd;
          } else if(!endDateNum) {
            withinRange = afterStart;
          } else {
            withinRange = beforeEnd;
          }
        }
        includeItem = withinRange;
      }

      if(includeItem && filters.starred) {
        if(filters.starred === 'starred') {
          includeItem = item.starred;
        } else  if(filters.starred === 'not-starred') {
          includeItem = !item.starred;
        }
      }

      return includeItem;

  });
  let tableColumns = [
    {
      width: 90,
      label: ' ',
      disableSort: true,
      dataKey: 'edit',
      formatting: (data, rowData) => {
        if(rowData.externalClaimId !== null) {
          return (<Tooltip className={classes.claimReviewIcon} title={t('claim_manager.synced_claims_cannot_edit')}>
            <CheckBoxOutlinedIcon />
           </Tooltip>
          )
        } else {
          return <Button onClick={() => { handleEditClaim(rowData.trackedClaimId);}} color="primary" variant="contained">{t('claim_manager.edit_claim_button')}</Button>;
        }
      }
    },
    {
      width: 70,
      label: t('claim_filters.options_starred'),
      disableSort: true,
      dataKey: 'starred',
      formatting: (data, rowData) => {
        if(data) {
          return (
            <IconButton size="small" onClick={() => { handleUnstarClaim(rowData.trackedClaimId);}}>
              <StarIcon />
            </IconButton>
          );
        } else {
          return (
            <IconButton size="small" onClick={() => { handleStarClaim(rowData.trackedClaimId);}}>
                <StarBorderIcon />
            </IconButton>
          );
        }
      }
    },
    {
      width: 90,
      label: t('claim_manager.table_header_id'),
      dataKey: 'trackedClaimId'
    },
    {
      width: 300,
      label: t('claim_manager.table_header_claim'),
      dataKey: 'claim',
      flexGrow: 1
    },
    {
      width: 150,
      label: t('claim_manager.table_header_publication_date'),
      dataKey: 'createdAt',
      formatting: (data) => {
        return data?.split('T')[0] || '';
      }
    },
    {
      width: 60,
      label: t('claim_manager.details'),
      disableSort: true,
      dataKey: 'viewclaim',
      formatting: (data, rowData) => {
        return (
          <Tooltip title={t('claim_manager.view_claim_details')}>
            <Link to={"/alerts/claims/" + rowData.trackedClaimId}>
              <InfoIcon />
            </Link>
        </Tooltip>
        );
      }
    },
    {
      width: 60,
      label: t('claim_manager.table_header_factcheck_url'),
      disableSort: true,
      dataKey: 'factcheckUrl',
      formatting: (data) => {
        return data ? (
          <Tooltip title={t('claim_manager.go_to_factcheck')}>
            <a href={data}><LinkIcon /></a>
          </Tooltip>
        ): '';
      }
    },
    {
      width: 90,
      label: ' ',
      disableSort: true,
      dataKey: 'delete',
      formatting: (data, rowData) => {
        return (
          <Tooltip title={t('claim_manager.delete_claim')}>
            <IconButton size="small" onClick={() => { handleDelete(rowData.trackedClaimId);}}><DeleteIcon /></IconButton>
          </Tooltip>
        );
      }
    }
  ];

  tableColumns = tableColumns.filter(col => (canViewClaimDetails || col.dataKey !== 'viewclaim'));

  return (
    <div style={{height: height }}className={height ? classes.claimsManagerFixed : classes.claimsManager}>
      <div>
        <ClaimControlPanel
          dateRangeChange={setDates}
          filtersChange={applyFilters}
          filterTextChange={setFilterText}
          handleEditClaim={handleEditClaim}
          resultsCount={modifiedData.length}
          resultsCSV={generateCSV(modifiedData)}
          canAddClaims={canAddClaims}
        />
      </div>
      <div className={classes.claimsTable}>
        <EnhancedTable
          rows={modifiedData}
          height="100%"
          fixedWidth={900}
          columns={tableColumns}
        />
      </div>
  </div>
  );
}


export default compose(
  withTranslation(),
  withStyles(() => ({
    claimsManagerFixed: {
      display: 'flex',
      flexDirection: 'column'
    },
    claimsManager: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    claimsTable: {
      flexGrow: 1,
      height: '100%',
      width: '100%',
      overflowY: 'scroll'
    },
    claimReviewIcon: {
      marginLeft: '24px',
      color: '#e37262'
    },
  }))
)(ClaimManager);

ClaimManager.propTypes = {
  classes: PropTypes.object,
  claims: PropTypes.array,
  handleDelete: PropTypes.func,
  handleEditClaim: PropTypes.func,
  handleStarClaim: PropTypes.func,
  handleUnstarClaim: PropTypes.func,
  canAddClaims: PropTypes.bool,
  canViewClaimDetails: PropTypes.bool,
  t: PropTypes.func,
  height: PropTypes.string
};
