import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NoticeContainer from 'Components/Common/NoticeContainer';
import TranscriptSentencesContainer  from "Components/Transcript/TranscriptSentencesContainer";
import TranscriptDetails from 'Components/Transcript/TranscriptDetails';

import noticeActions from 'Actions/noticeActions';
import transcriptActions from 'Actions/transcriptActions';
import transcriptSelectors from 'Selectors/transcriptSelectors';

class TranscriptBlockPage extends React.Component {

  constructor(props){
    super(props);
    props.transcriptActions.getBlockTranscript(props.match.params['transcriptId']);
  }

  componentWillUnmount = () => {
      this.props.transcriptActions.clearTranscript();
      this.props.noticeActions.clearNotices();
  }

  loadTranscriptSentences = (transcriptId) => {
    this.props.transcriptActions.getBlockTranscriptSentences(transcriptId);
  }

  handleSentenceHighlight = (transcriptId, sentenceId, hasHighlight) => {
    if(hasHighlight) {
      this.props.transcriptActions.unhighlightSentence('block', transcriptId, sentenceId);
    } else {
      this.props.transcriptActions.highlightSentence('block', transcriptId, sentenceId);
    }
  }

  render() {
    const {
      transcript,
      transcriptActions
    } = this.props;

    return (
      <section>
        <div className="row">
          <div className="col-xs4-4 col-lg-12">
            {transcript && transcript.transcriptName ? (
              <TranscriptDetails
                transcript={transcript}
                handleChangeName={transcriptActions.updateBlockTranscriptName}
                canChangeName={true}
              />
            ): ''}
          </div>
        </div>
        <NoticeContainer displayCode="page_notice" dismissible={true} />
        {transcript && transcript.transcriptName ? (
          <TranscriptSentencesContainer
            isOpen={transcript.isOpen}
            transcriptId={transcript.transcriptId}
            colClass="col-full-row"
            loadTranscriptSentences={this.loadTranscriptSentences}
            showTime={false}
            handleSentenceHighlight={this.handleSentenceHighlight}
            initialSentenceId={parseInt(this.props.history.location.hash?.substring(1),10)}
          />
        ): ''}
      </section>
    );
  }
}

TranscriptBlockPage.propTypes = {
  transcriptActions: PropTypes.object.isRequired,
  noticeActions: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  transcript: PropTypes.object
};

function mapStateToProps(state) {
  return {
    transcript: transcriptSelectors.getTranscriptDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transcriptActions: bindActionCreators(transcriptActions, dispatch),
    noticeActions: bindActionCreators(noticeActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranscriptBlockPage);
