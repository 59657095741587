interface ApiMediaFeedType {
    category: string;
    created_at: string;
    is_valid?: boolean;
    last_scraped?: string;
    media_code: string;
    media_feed_id: number;
    media_url: string;
}

export interface ApiMediaType {
    publication_id: number;
    publication_name: string;
    wikidata_publication_id?: number;
    media_feed: ApiMediaFeedType;
}

export interface MediaType {
    publicationId: number;
    publicationName: string;
    wikidataPublicationId: number;
    mediaCategory: string;
    mediaCreatedAt: string;
    mediaIsValid: boolean;
    mediaLastScraped: string;
    mediaCode: string;
    mediaFeedId: number;
    mediaUrl: string;
}

export const Media = {
    fromApi: (obj: ApiMediaType) => {
        return {
            publicationId: obj.publication_id,
            publicationName: obj.publication_name,
            wikidataPublicationId: obj.wikidata_publication_id,
            mediaCategory: obj.media_feed?.category,
            mediaCreatedAt: obj.media_feed?.created_at,
            mediaIsValid: obj.media_feed?.is_valid,
            mediaLastScraped: obj.media_feed?.last_scraped,
            mediaCode: obj.media_feed?.media_code,
            mediaFeedId: obj.media_feed?.media_feed_id,
            mediaUrl: obj.media_feed?.media_url
        };
    },
    toApi: (obj: MediaType) => {
        return {
            publication_name: obj.publicationName,
            wikidata_publication_id: obj.wikidataPublicationId,
            media_url: obj.mediaUrl
        }
    }
};




