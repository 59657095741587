import { put, takeEvery } from 'redux-saga/effects';
import interventionConstants from 'Constants/interventionConstants';
export function* processGetInterventions(action) {
   yield put({
     type: 'FETCH',
     requestType: action.type,
     payload: {
       path: `/interventions/`
     }
   });
}

export function* processGetInterventionsByClaimId(action) {
   yield put({
     type: 'FETCH',
     requestType: action.type,
     payload: {
       path: `/interventions/?tracked_claim_id=${action.id}`
     }
   });
}

export function* processCreateIntervention(action) {
   yield put({
     type: 'FETCH',
     requestType: action.type,
     payload: {
       path: `/interventions/`,
       method: 'POST',
       body: action.intervention
     }
   });
}

export function* processUpdateIntervention(action){
  let intervention  = { ...action.intervention };

  // not required by API
  delete intervention.sighting_type;
  delete intervention.media_hash;

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/interventions/${action.interventionId}/`,
      method: 'PATCH',
      body: intervention
    }
  });
}

export function* processDeleteIntervention(action) {
   yield put({
     type: 'FETCH',
     requestType: action.type,
     payload: {
       path: `/interventions/${action.id}/`,
       method: 'DELETE'
     },
     request: {
       interventionId: action.id
     }
   });
}

export function* processCreateInterventionSuccess() {
  yield put({
    type: interventionConstants.STOP_EDITING_INTERVENTION
  });
}



export function* processUpdateInterventionSuccess(action){
  yield put({
    type: interventionConstants.STOP_EDITING_INTERVENTION
  });
}

export function* interventionSagas(){
    yield takeEvery(interventionConstants.GET_INTERVENTIONS, processGetInterventions);
    yield takeEvery(interventionConstants.GET_INTERVENTION, processGetInterventions);
    yield takeEvery(interventionConstants.GET_INTERVENTIONS_BY_CLAIM_ID, processGetInterventionsByClaimId);
    yield takeEvery(`${interventionConstants.CREATE_INTERVENTION}_SUCCESS`, processCreateInterventionSuccess);
    yield takeEvery(interventionConstants.CREATE_INTERVENTION, processCreateIntervention);
    yield takeEvery(interventionConstants.DELETE_INTERVENTION, processDeleteIntervention);
    yield takeEvery(interventionConstants.UPDATE_INTERVENTION, processUpdateIntervention);
    yield takeEvery(`${interventionConstants.UPDATE_INTERVENTION}_SUCCESS`, processUpdateInterventionSuccess);
}
