import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'redux';
import Notice from './Notice';
import noticeSelectors from 'Selectors/noticeSelectors';
import noticeActions from 'Actions/noticeActions';

export class NoticeContainer extends Component {

  constructor(props) {
    super(props);
  }

  render(){

    const {
      displayCode,
      noticeActions,
      notice,
      dismissible
    } = this.props;

    if(notice) {
      const {
        type,
        position,
        messageKey,
        message
      } = notice;

      let onRemove;
      if(dismissible) {
        onRemove = () => {
          noticeActions.removeNotice(displayCode);
        };
      }


      
      return (
        <Notice
          type={type}
          message={message}
          messageKey={messageKey}
          onRemove={onRemove}
          position={position}
        />
      );
    }
    return null;
  }
}

NoticeContainer.propTypes = {
  notice: PropTypes.object,
  noticeActions: PropTypes.object,
  displayCode: PropTypes.string,
  dismissible: PropTypes.bool,
  t: PropTypes.func
};

const mapDispatchToProps = {
    removeNotice: noticeActions.removeNotice
};

function mapStateToProps(state, ownProps) {
  return {
    notice: noticeSelectors.getNoticeById(state, ownProps.displayCode)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
 )
)(NoticeContainer);
