import { all, fork } from 'redux-saga/effects';
import { authSagas } from './authSagas';
import { adminSagas } from './adminSagas';
import { candidateSagas } from './candidateSagas';
import { channelSagas } from './channelSagas';
import { transcriptSagas } from './transcriptSagas';
import { claimSagas } from './claimSagas';
import { interventionSagas } from './interventionSagas';
import { noticeSagas } from './noticeSagas';
import { entitySagas } from './entitySagas';
import { fetch } from './fetchSaga';
 
export default function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(adminSagas),
    fork(candidateSagas),
    fork(channelSagas),
    fork(transcriptSagas),
    fork(claimSagas),
    fork(interventionSagas),
    fork(noticeSagas),
    fork(entitySagas),
    fork(fetch)
  ]);
}
