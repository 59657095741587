import initialState from './initialState';
import appConstants from 'Constants/appConstants';

export default function appState(state = initialState.appState, action) {
  switch (action.type) {
    case `${appConstants.LOAD_APP_CONFIG}_SUCCESS`:
      return  {
        ...state,
        apiBase: action.response.apiBase,
        mqtt: action.response.mqtt,
        theme: 'light'
      };

    case appConstants.CHANGE_THEME_COLOR:
      return {
        ...state,
        theme: action.color
      };

    case appConstants.CHANGE_CURRENT_LOCALE:
      return {
        ...state,
        locale: action.locale
      };

    default:
      return state;
  }
}