import analytics from 'Config/analytics';
import { config as defaultConfig } from 'Config/default/config';

const config = {
  ga: analytics,
  user: {
    roles: [
      { name: 'factchecker', display: 'user_roles.factchecker' },
      { name: 'administrator', display: 'user_roles.administrator' }
    ]
  },
  defaultOrg: defaultConfig
};

export default config;
