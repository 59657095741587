import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import LiveMatchStats from './LiveMatchStats';
import LiveMatchExact from './LiveMatchExact';
import { withTranslation, Trans } from 'react-i18next';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';

export class LiveMatch extends Component {

    constructor(props){
      super(props);

      this.state = {
        isExanded: true
      };
    }

    toggleMatchExpander = () => {
      this.setState({
        isExanded: !this.state.isExanded
      });
    }

    render() {

      const { isExanded }  = this.state;
      
      const {
        matches,
        t,
        classes,
        sentence,
        showLinks,
        channel
      } = this.props;

      return (
        <div className={classes.match}>
          {!isExanded ? (
            <div className={classes.collapsedChip}>
              <Chip label={t('live_match.matches_count', { count: matches.exactMatches.length + matches.autoMatches.length })+' ⋮'} onClick={this.toggleMatchExpander} />
            </div>
          ): (
          <Paper className={classes.hit}>
            <div className={classes.matchCard}>
              <div className={classes.expandedChip}>
                <Chip label={t('live_match.matches_count', { count: matches.exactMatches.length + matches.autoMatches.length })+' ⋮'} onClick={this.toggleMatchExpander} />
              </div>
              {matches.exactMatches && matches.exactMatches.length > 0 && isExanded && (
                <div>
                  <h3 className={classes.success + " " + classes.expandedHeader}>
                    {t('live_match.exact_matches_count', { count: matches.exactMatches.length })}
                  </h3>
                  {matches.exactMatches.map((match, i) => (
                    <div className={classes.innerMatch} key={'exact'+i}>
                      <LiveMatchExact
                        match={match}
                        sentence={sentence}
                        channel={channel}
                        showLinks={showLinks}
                      />
                    </div>
                  ))}
                </div>
              )}
              {(matches.autoMatches && matches.autoMatches.length > 0 && isExanded && 
                <div>
                    <h3 className={classes.success + " " + classes.expandedHeader}>
                      {t('live_match.stats_matches_count', { count: matches.autoMatches.length })}
                    </h3>
                    {matches.autoMatches.map((match, i) => (
                      <div className={classes.innerMatch} key={'stat'+i}>
                        <LiveMatchStats
                          match={match}
                          sentence={this.props.sentence}
                          channel={channel}
                        />
                      </div>
                     ))}
                </div>
                )}
            </div>
          </Paper>
          )}
        </div>
      );

    }
}

LiveMatch.propTypes = {
  matches: PropTypes.object,
  sentence: PropTypes.object.isRequired,
  channel: PropTypes.string,
  showLinks: PropTypes.bool,
  isRobo: PropTypes.bool,
  t: PropTypes.func,
  classes: PropTypes.object
};


export default compose(
  withStyles(() => ({
    hit: {
      borderTop: "3px solid #000" 
    },
    match: {
      '& a': {
        color: '#009688'
      }
    },
    success: {
      lineHeight: '2.6rem',
      color: '#444',
      fontSize: '14px',
      margin: '0'
    },
    matchExpander: {
      float: 'right',
      fontSize: '12px',
      color: '#888',
      width: '20px',
      cursor: 'pointer'
    },
    matchCard: {
      padding: '6px 16px 0 16px !important'
    },
    innerMatch: {
      paddingBottom: '16px'
    },
    expandedHeader: {
      marginTop: '12px'
    },
    claimTypeChip: {
      margin: '3px 3px 6px 0',
      display: 'inline-block'
    },
    chipStyle: {
      backgroundColor: '#777',
      color: '#fff',
      display: 'inline-block',
      padding: '6px',
      borderRadius: '4px',
      fontWeight: 'bold'
    },
    collapsedChip: {
      marginLeft: '16px',
      marginTop: '9px',
      fontWeight: 'bold'
    },
    expandedChip: {
      fontWeight: 'bold'
    }
  })),
  withTranslation())(LiveMatch);
