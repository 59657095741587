import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import interventionSelectors from 'Selectors/interventionSelectors';
import claimSelectors from 'Selectors/claimSelectors';
import ActionStats from 'Components/Action/ActionStats';
import ActionChartAverage from 'Components/Action/ActionChartAverage';
import Paper from '@material-ui/core/Paper';
import claimActions from 'Actions/claimActions';
import interventionActions from 'Actions/interventionActions';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SortableTable from 'Components/Common/SortableTable';

export class ActionStatsPage extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      isYTD: false,
      tabValue: 0
    };

    this.props.claimActions.loadClaims();
    this.props.claimActions.getConfirmedSightings();
    this.props.interventionActions.loadInterventions();

  }

  handleTabChange = (e, newValue) => {
    this.setState({ tabValue: newValue });
  }

  render() {
    const {
      chartData,
      actionStatistics,
      actionStatsYTD,
      monthlyStatisticsByYear,
      actionsByPublication,
      t,
      hasFacebookActions,
      confirmedSightingsCount,
      classes,
      actionsByAuthor
    } = this.props;

    const { tabValue } = this.state;
    return (
      <div className="row container">
        <div className="col-xs4-4 col-lg-12">
          <div className={classes.statisticsTitle}>
            <Typography variant="h2" component="h2" id="aggregated-action-stats">
              {t('action_stats_page.title_aggregated')}
            </Typography>
          </div>
          <p>{t('action_stats_page.number_of_confirmed', { count: confirmedSightingsCount})}</p>
          {chartData ? (
            <Box>
              <Typography variant="h6">
                {t('action_stats_page.title_number_of_actions_by_month')}
              </Typography>
              <Paper>
                <ActionChartAverage data={chartData} />
              </Paper>
            </Box>
          ): ''}
          <ActionStats
            actionStatistics={actionStatistics}
            actionStatsYTD={actionStatsYTD}
            hasFacebook={hasFacebookActions}
            monthlyStatisticsByYear={monthlyStatisticsByYear}
          />
          <Box m={2} />
          {actionsByPublication.length || actionsByAuthor.length ? (
            <div>
            <Box>
                <Tabs value={tabValue} onChange={this.handleTabChange}>
                  <Tab label={t('action_stats_page.actions_by_publication')} />
                  <Tab label={t('action_stats_page.actions_by_author')} />
                </Tabs>
              </Box>
            {/* First Tab - Actions by Publication */}
            <Box hidden={tabValue !== 0}>
              <SortableTable
                rows={actionsByPublication}
                columns={[
                  {
                      width: 60,
                      label: t('action_stats.turnaround_publication'),
                      dataKey: 'name'
                  },
                  {
                    label: t('action_stats.turnaround_number_actions'),
                    dataKey: 'numberOfActions'
                  }
                  ]}
              />
            </Box>

            {/* Second Tab - Actions by Author */}
            <Box hidden={tabValue !== 1}>
              <SortableTable
                rows={actionsByAuthor}
                columns={[
                  {
                    width: 60,
                    label: t('action_stats_page.author'),
                    dataKey: 'authorName'
                  },
                  {
                    label: t('action_stats_page.number_of_actions'),
                    dataKey: 'numberOfActions',
                    dataType: 'number'
                  }
                  ]}
              />
            </Box>
            </div>
          ): ''}

        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    chartData: interventionSelectors.getAverageInterventionChartData(state),
    actionStatistics: interventionSelectors.getActionStats(state),
    actionStatsYTD: interventionSelectors.getActionStatsYTD(state),
    monthlyStatisticsByYear: interventionSelectors.getMonthlyStatisticsByYear(state),
    hasFacebookActions: interventionSelectors.hasFacebookActions(state),
    actionsByPublication: interventionSelectors.getActionsByPublication(state),
    actionsByAuthor: interventionSelectors.getActionsByAuthor(state),
    confirmedSightingsCount: claimSelectors.getConfirmedSightingsCount(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    interventionActions: bindActionCreators(interventionActions, dispatch),
    claimActions: bindActionCreators(claimActions, dispatch)
  };
}

ActionStatsPage.propTypes = {
  chartData: PropTypes.array,
  t: PropTypes.func,
  actionStatistics: PropTypes.array,
  actionStatsYTD: PropTypes.array,
  claimActions: PropTypes.object,
  user: PropTypes.object,
  actionsByPublication: PropTypes.array,
  interventionActions: PropTypes.object,
  monthlyStatisticsByYear: PropTypes.object,
  confirmedSightingsCount: PropTypes.number,
  classes: PropTypes.object,
  hasFacebookActions: PropTypes.bool,
  actionsByAuthor: PropTypes.array
};

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(() => ({
    statisticsTitle: {
      margin: '24px 0 12px 0'
    }
}))
)(ActionStatsPage);
