import { call, put, takeEvery, select } from 'redux-saga/effects';
import fetchCall from '../helpers/fetchCall';
import * as utils from '../utils';
import authConstants from 'Constants/authConstants';
import errorMessages from 'Config/default/errorMessages';
import noticeActions from 'Actions/noticeActions';

const getAuth = state => state.authState;
const getAppConfig = state => state.appState;

export function* processFetchRequest(action) {
  const requestTypes = utils.createRequestTypes(action.requestType);
  let appConfig = yield select(getAppConfig);

  yield put({
    type: 'CLEAR_NOTICES'
  });

  yield put({
    type: requestTypes.REQUEST
  });

  let auth = yield select(getAuth);
  let response;
  let request =  action.request || {};

  try {
    if(action.requestType === authConstants.LOAD_APP_CONFIG) {
      yield put({
        type: requestTypes.PENDING,
        request: request
      });

      action.payload.url = window.location.protocol.slice(0, -1) +'://' + window.location.host + action.payload.path;
      response = yield call(fetchCall, action.payload);

      if(response.status >= 200 && response.status < 300) {
        let json = {};
        if(response.statusText !== 'NO CONTENT'){
          json = yield response.json();
        }
        yield put({
          type: requestTypes.SUCCESS,
          response: json,
          request: request
        });

      } else {
        throw response;
      }
    } else if(action.requestType === authConstants.REFRESH_TOKEN) {

      action.payload.url = appConfig.apiBase + action.payload.path;

      yield put({
        type: requestTypes.PENDING,
        request: request
      });
      response = yield call(fetchCall, action.payload);

      if(response.status >= 200 && response.status < 300) {
        let json = {};
        if(response.statusText !== 'NO CONTENT'){
          json = yield response.json();
        }
        yield put({
          type: requestTypes.SUCCESS,
          response: json,
          request: request
        });
      } else {
        yield put({
          type: requestTypes.FAILURE,
          request: request
        });
      }
    } else if(appConfig.apiBase !== ''){
      yield put({
        type: requestTypes.PENDING,
        request: request
      });
      action.payload.token = auth.accessToken;
      if(!action.payload.url) {
        action.payload.url = appConfig.apiBase + action.payload.path;
      }

      response = yield call(fetchCall, action.payload);
      //check for 401 and push to postponedCalls

      if(response.status === 401 && action.requestType !== authConstants.LOGIN && action.requestType !== authConstants.CHANGE_PASSWORD_WITH_TOKEN){
        auth = yield select(getAuth);
        yield put({
          type: authConstants.UNAUTHORISED_ACTION,
          action: action
        });
          if(!auth.pendingRefreshingToken){
          yield put({
            type: authConstants.REFRESH_TOKEN
          });
        }
      } else if(response.status >= 200 && response.status < 300) {
        let json = {};
        if(response.statusText !== 'NO CONTENT'){
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            json = yield response.json();
          }
        }

        yield put({
          type: requestTypes.SUCCESS,
          response: json,
          request: request
        });

      } else {
        throw response;
      }
    } else {
      throw {};
    }
  } catch (error){
    if(action.requestType !== authConstants.REFRESH_TOKEN) {

      let errorMessageFromJsonResponse = null;
      let errorMessage = errorMessages[action.requestType] || errorMessages.DEFAULT;

      error.message = errorMessage.message;

      // handle errors with json responses
      let json = {};
      if(error.headers){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          json = yield response.json();
          if(json.reason) {
            if(errorMessage.responseMessageCodes && errorMessage.responseMessageCodes[json.reason]){
              error.message = errorMessage.responseMessageCodes[json.reason];
            }
          } else if(json.message) {
            if(errorMessage.responseMessageCodes && errorMessage.responseMessageCodes[json.message]){
              error.message = errorMessage.responseMessageCodes[json.message];
            } else {
              errorMessageFromJsonResponse = json.message;
            }
          }
        }
      }
      
      let resp = yield response;
      if(errorMessage.codes && errorMessage.codes[error.status+'']) {
          error.message = errorMessage.codes[error.status+''];
      }

      error.displayCode = errorMessage.displayCode;
      yield put({ type: requestTypes.FAILURE, error: error, response: json });
      
      let errorAction = noticeActions.addNotice(error.displayCode, {
        message: errorMessageFromJsonResponse,
        messageKey: error.message,
        type: 'error',
        position: errorMessage.position || ''
      });

      yield put(errorAction);

    }
  }
}


export function* fetch() {
  yield takeEvery('FETCH', processFetchRequest);
}
