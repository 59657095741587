import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Table
} from 'react-virtualized';


class VirtualizedTable extends React.Component {

  static defaultProps = {
    headerHeight: 48
  };

  constructor(props) {
   super(props);

   this.cellCache = new CellMeasurerCache({
     fixedWidth: true,
     defaultHeight: 100
   });
  } 

  componentWillUpdate = () => {
    this.cellCache.clearAll();
  }

  getRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;

    let allClasses = [classes.tableRow, classes.flexContainer, ];
    if(index !== -1 && onRowClick != null) allClasses.push(classes.tableRowHover);

    return allClasses.join(' ');
  };

  cellRenderer = ({ rowData, cellData, columnIndex, key, parent, rowIndex, style }) => {
    const { columns, classes } = this.props;
    return (
      <CellMeasurer
        cache={this.cellCache}
        columnIndex={columnIndex}
        parent={parent}
        rowIndex={rowIndex}
        key={key}>
        <TableCell
            style={style}
            component="div"
            variant="body"
            align="left"
            className={classes.tableCell}
          >
            {(columnIndex != null && columns[columnIndex].formatting) ? columns[columnIndex].formatting(cellData, rowData): cellData}
          </TableCell>
      </CellMeasurer>
    );
  };

  headerRenderer = ({ disableSort, label, sortDirection, sortBy, dataKey }) => {
    const { headerHeight, classes, setSortFields } = this.props;

    let currentDirection = sortDirection.toLowerCase();
    let nextDirection = '';
    if(sortDirection === '') nextDirection = 'ASC';
    if(sortDirection === 'ASC') nextDirection = 'DESC';

    return (
      <TableCell
        component="div"
        variant="head"
        style={{
          height: headerHeight,
          minWidth: '20px',
        }}
        align={'left'}
        className={classes.tableCell}
        onClick={() => {
          if(!disableSort) {
            setSortFields(dataKey, nextDirection);
          }
        }}
      >
      {currentDirection !== '' && !disableSort ? (
        <TableSortLabel
          active={sortBy === dataKey}
          direction={currentDirection}
        >
          {label}
        </TableSortLabel>
      ) : label}

      </TableCell>
    );
  };

  render() {
    const {
      classes,
      columns,
      height,
      fixedWidth,
      headerHeight,
      sortDirection,
      ...tableProps
    }
     = this.props;

    let tableHeight = height || '400px';
    let minWidth = fixedWidth || 0;

    return (
      <div style={{height: tableHeight, minWidth: minWidth}}>
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            headerHeight={headerHeight}
            rowHeight={this.cellCache.rowHeight}
            rowClassName={this.getRowClassName}
            overscanRowCount={10}
            {...tableProps}
          >
            {columns.map(({ flexShrink, flexGrow, disableSort, dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  flexShrink={flexShrink ? flexShrink : 0}
                  disableSort={disableSort}
                  flexGrow={flexGrow ? flexGrow : 0}
                  headerRenderer={headerProps =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                      sortDirection: sortDirection,
                      disableSort: disableSort

                    })
                  }
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
      </div>
    );
  }
}

export default withStyles((theme) => ({
    flexContainer: {
      display: 'flex',
      alignItems: 'stretch',
      boxSizing: 'border-box',
    },
    tableRow: {
      cursor: 'pointer',
      borderBottom: '1px solid #eee',
      direction: 'ltr'
    },
    tableRowHover: {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    tableCell: {
      flex: 1,
      borderBottom: 'none',
      display: 'block',
      '& a': {
        color: theme.palette.primary[500]
      }
    },
    noClick: {
      cursor: 'initial',
    },
    typeChip: {
      display: 'inline-block',
      marginBottom: '3px'
    }
}))(VirtualizedTable);

VirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
  setSortFields: PropTypes.func,
  height: PropTypes.string,
  fixedWidth: PropTypes.number
};
