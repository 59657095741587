import authSelectors from 'Selectors/authSelectors';

export function getChannels(state) {
  let channels = state.channelState.channels;
  const config = authSelectors.getConfig(state);
  if(state.authState.user && state.authState.user.organisation){
    if(config.channels) {
      channels = state.channelState.channels.map(channel => {
        if(config.channels[channel.code]) {
          return {
            ...channel,
            channelImage: config.channels[channel.code].image
          };
        } else {
          return channel;
        }
      });
    }
  }
  return channels;
}

export function getChannel(state) {
  return state.channelState.channel;
}


export function getChannelSubtitles(state) {
  return state.channelState.subtitles;
}

export default ({
  getChannels: getChannels,
  getChannel: getChannel,
  getChannelSubtitles: getChannelSubtitles
});

