const claimsConstants = {
  GET_CLAIM: 'GET_CLAIM',
  GET_CLAIMS: 'GET_CLAIMS',
  CREATE_CLAIM: 'CREATE_CLAIM',
  UPDATE_CLAIM: 'UPDATE_CLAIM',
  DELETE_CLAIM: 'DELETE_CLAIM',
  LOADING_INTERVENTIONS: 'LOADING_INTERVENTIONS',
  GET_INTERVENTIONS: 'GET_INTERVENTIONS',
  CREATE_INTERVENTION: 'CREATE_INTERVENTION',
  DELETE_INTERVENTION: 'DELETE_INTERVENTION',
  CLEAR_CLAIMS: 'CLEAR_CLAIMS',
  EXACT_CLAIM_MATCHED: 'EXACT_CLAIM_MATCHED',
  ROBO_CHECK_MATCHED: 'ROBO_CHECK_MATCHED',
  STAR_CLAIM: 'STAR_CLAIM',
  UNSTAR_CLAIM: 'UNSTAR_CLAIM',
  SET_CLAIMS_SORT: 'SET_CLAIMS_SORT',
  SET_CLAIMS_ORDER: 'SET_CLAIMS_ORDER',
  SET_CLAIMS_FILTER_TEXT: 'SET_CLAIMS_FILTER_TEXT',
  SET_CLAIMS_STARRED_FILTER: 'SET_CLAIMS_STARRED_FILTER',
  SET_CLAIMS_PATTERN_FILTER: 'SET_CLAIMS_PATTERN_FILTER',
  SET_CLAIMS_URL_ONLY: 'SET_CLAIMS_URL_ONLY',
  START_EDITING_CLAIM: 'START_EDITING_CLAIM',
  STOP_EDITING_CLAIM: 'STOP_EDITING_CLAIM',
  SET_CLAIMS_FILTER_DATE_RANGE: 'SET_CLAIMS_FILTER_DATE_RANGE',
  GET_REVIEWED_SIGHTINGS: 'GET_REVIEWED_SIGHTINGS',
  MATCHED_SIGHTINGS_FOR_CLAIM_ID: 'MATCHED_SIGHTINGS_FOR_CLAIM_ID',
  ADD_MATCHED_SIGHTING: 'ADD_MATCHED_SIGHTING',
  REMOVE_MATCHED_SIGHTING: 'REMOVE_MATCHED_SIGHTING',
  CLEAR_PATTERN_SIGHTINGS: 'CLEAR_PATTERN_SIGHTINGS',
  GET_CONFIRMED_SIGHTINGS: 'GET_CONFIRMED_SIGHTINGS',
  GET_MORE_CONFIRMED_SIGHTINGS: 'GET_MORE_CONFIRMED_SIGHTINGS',
  GET_DISMISSED_SIGHTINGS: 'GET_DISMISSED_SIGHTINGS',
  GET_MORE_DISMISSED_SIGHTINGS: 'GET_MORE_DISMISSED_SIGHTINGS',
  DISMISS_SIGHTING: 'DISMISS_SIGHTING',
  REMOVE_DISMISSED_SIGHTING: 'REMOVE_DISMISSED_SIGHTING'
};

export default claimsConstants;