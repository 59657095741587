const errorMessages = {
  'DEFAULT' : {
    message: 'error_message.default',
    displayCode: 'page_notice'
  },
  'CHANGE_PASSWORD_WITH_TOKEN': {
    message: 'error_message.change_password_with_token',
    displayCode: 'page_notice',
    codes: {
      '401' : 'error_message.change_password_with_token_invalid'
    }
  },
  'GET_CHANNELS' : {
    message : 'error_message.get_channels',
    displayCode: 'page_notice'
  },
  'GET_CLAIM' : {
    message: 'error_message.get_claim',
    displayCode: 'page_notice'
  },
  'GET_CLAIMS' : {
    message: 'error_message.get_claims',
    displayCode: 'page_notice',
  },
  'UPDATE_CLAIM': {
    message: 'error_message.update_claim',
    displayCode: 'claim_edit_error'
  },
  'CREATE_CLAIM': {
    message: 'error_message.create_claim',
    displayCode: 'claim_edit_error'
  },
  'CLAIM_SEARCH': {
    message: 'error_message.claim_search',
    displayCode: 'general_notice',
    position: 'bottom'
  },
  'GET_CHANNEL_TRANSCRIPT': {
    message: 'error_message.get_transcript',
    displayCode: 'page_notice'
  },
  'CREATE_CHANNEL_TRANSCRIPT': {
    message: 'error_message.create_transcript',
    displayCode: 'transcript_add_error'
  },
  'DELETE_CHANNEL_TRANSCRIPT': {
    message: 'error_message.delete_transcript',
    displayCode: 'page_notice'
  },
  'STOP_TRANSCRIPT': {
    message: 'error_message.stop_transcript',
    displayCode: 'page_notice'
  },
  'GET_CHANNEL_TRANSCRIPT_SENTENCES': {
    message: 'error_message.get_transcript_sentences',
    displayCode: 'page_notice'
  },
  'DELETE_INTERVENTION' : {
    message: 'error_message.delete_action',
    displayCode: 'page_notice'
  },
  'CREATE_INTERVENTION' : {
    message: 'error_message.create_action',
    displayCode: 'edit_intervention_error'
  },
  'GET_INTERVENTIONS' : {
    message: 'error_message.get_actions',
    displayCode: 'page_notice'
  },
  'UPDATE_INTERVENTION' : {
    message: 'error_message.update_action',
    displayCode: 'edit_intervention_error'
  },
  'GET_CHANNEL' : {
    message: 'error_message.get_channel',
    displayCode: 'page_notice'
  },
  'CREATE_USER' : {
    message: 'error_message.create_user',
    displayCode: 'change_user_notice'
  },
  'UPDATE_USER' : {
    message: 'error_message.update_user',
    displayCode: 'change_user_notice'
  },
  'CHANGE_USER_PASSWORD' : {
    message: 'error_message.change_user_password',
    displayCode: 'change_password_notice'
  },
  'LOAD_APP_CONFIG': {
    message: 'error_message.unable_to_get_config',
    displayCode: 'root_notice'
  },
  'CREATE_YOUTUBE_TRANSCRIPT': {
    message: 'error_message.create_transcript',
    displayCode: 'transcript_add_error',
    responseMessageCodes: {
      'PRIVATE_VIDEO': 'error_message.youtube_transcript.private_video',
      'CANNOT_EXTRACT': 'error_message.youtube_transcript.cannot_extract',
      'INVALID_URL': 'error_message.youtube_transcript.invalid_url'
    }
  },
  'WRONG_EMAIL_PASSWORD': {
    message: 'error_message.wrong_email_password',
    displayCode: 'page_notice'
  },
  'COULD_NOT_LOGIN': {
    message: 'error_message.could_not_login',
    displayCode: 'page_notice'
  },
  'CREATE_ORG_MEDIA': {
    message: 'error_message.create_media',
    displayCode: 'form_notice',
    responseMessageCodes: {
      'INVALID_FEED': 'error_message.media_manager.url_invalid_feed',
      'DATA_VALIDATION_ERROR': 'error_message.media_manager.url_data_validation',
      'FEED_UNREACHABLE': 'error_message.media_manager.url_unreachable',
      'FEED_BLOCKED': 'error_message.media_manager.url_blocked'
    }
  },
  'UPDATE_ORG_MEDIA': {
    message: 'error_message.edit_media',
    displayCode: 'form_notice',
    responseMessageCodes: {
      'INVALID_FEED': 'error_message.media_manager.url_invalid_feed',
      'DATA_VALIDATION_ERROR': 'error_message.media_manager.url_data_validation',
      'FEED_UNREACHABLE': 'error_message.media_manager.url_unreachable',
      'FEED_BLOCKED': 'error_message.media_manager.url_blocked'
    }
  },
  'CREATE_RADIO_TRANSCRIPT_SCHEDULE': {
    message: 'error_message.media_manager.create_schedule',
    displayCode: 'form_notice'
  },
  'UPDATE_TRANSCRIPT_SCHEDULE': {
    message: 'error_message.media_manager.edit_schedule',
    displayCode: 'form_notice'
  }
};

export default errorMessages;