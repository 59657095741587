import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

const LiveChannelLink =  (props) => {
  const {
    display,
    channelImage,
    classes,
    hideWithoutImage,
    code
  } = props;
  let image = <div className={classes.channelText}>{display}</div>;
  if(channelImage) {
    image = <img className={classes.channelImage} src={channelImage} alt={display} />;
  }

  if(hideWithoutImage && !channelImage) {
    return null;
  } else {
    return (
      <Link to={"/live/channel/" + code} className={classes.link}>
        {image}
      </Link>
    );
  }
};


LiveChannelLink.propTypes = {
    code: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    channelImage: PropTypes.string,
    hideWithoutImage: PropTypes.bool,
    classes: PropTypes.object
};

export default compose(
  withStyles(() => ({
    channelText: {
      backgroundColor: '#009688',
      textAlign: 'center',
      color: '#fff !important',
      fontWeight: 'bold',
      width: '100px',
      height: '100px',
      borderRadius: '2px',
      boxShadow: '0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12)',
      lineHeight: '100px',
      verticalAlign: 'bottom',
      '&:hover': {
        opacity: '0.9'
      },
      display: 'inline-block'
    },
    channelImage: {
      width: '100px',
      height: '100px',
      borderRadius: '2px',
      boxShadow: '0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12)',
      lineHeight: '100px',
      verticalAlign: 'bottom',
      '&:hover': {
        opacity: '0.9'
      },
      display: 'inline-block'
    },
    link: {
      display: 'inline-block'
    },
    translink: {
      display: 'inline-block',
      '& i': {
        fontSize: '61px',
        verticalAlign: 'middle',
        color: '#ccc'
      }
    }
  })),
  React.memo
)(LiveChannelLink);
