import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import candidateActions from 'Actions/candidateActions';
import noticeActions  from 'Actions/noticeActions';
import claimActions from 'Actions/claimActions';
import interventionActions from 'Actions/interventionActions';
import candidateSelectors from 'Selectors/candidateSelectors';
import noticeSelectors from 'Selectors/noticeSelectors';
import SearchManager from 'Components/Search/SearchManager';
import { stringToFilters } from 'Components/Search/SearchManager';
import Notice from 'Components/Common/Notice';
import moment from 'moment';

export class SearchPage extends React.Component {

  constructor(props){
    super(props);

    const urlParams = new URLSearchParams(location.search);
    const params ={
      q: urlParams.get('q')
    };

    let filterText = '';
    if(params.q && params.q.length) filterText = params.q;

    this.filterText = filterText;
    this.initialFilters = { text: filterText };

    this.loadData(filterText);
    props.candidateActions.loadPublications();

  }
  
  createApiReadyFiltersFromText = (filterText) => {
    let filters = stringToFilters(filterText || '');

    filters.startDate = moment(filters.startDate+ " "+ "00:00:00").locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');
    filters.endDate =  moment(filters.endTime).locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');
    
    if(this.props.mediaCategories.length && filters.categories.length) {
      filters.categories = filters.categories.map((cat) => {
        let matches = this.props.mediaCategories.filter(catPair => catPair.label.toLowerCase() === cat.toLowerCase());
        if(matches.length)  return matches[0].value;
        return cat;
      });
    }
    if(this.props.claimTypes.length && filters.types.length) {
      filters.types = filters.types.map((type) => {
        let matches = this.props.claimTypes.filter(typePair => typePair.label.toLowerCase() === type.toLowerCase());
        if(matches.length)  return matches[0].value;
        return type;
      });
    }

    if(this.props.claimantGroups.length && filters.claimantGroups.length) {
      filters.claimantGroups = filters.claimantGroups.map((type) => {
        let matches = this.props.claimantGroups.filter(typePair => typePair.label.toLowerCase() === type.toLowerCase());
        if(matches.length)  return matches[0].value;
        return type;
      });
    }

    return filters;
  }
  // filterText is the full text in the query string (text)
  loadData = (filterText) => {
    // Break filterText into its components
    let filters = this.createApiReadyFiltersFromText(filterText || '');

    this.props.candidateActions.loadCandidates(filters.exactPhrase,
                                               filters.hasWords,
                                               filters.excludeWords,
                                               filters.startDate,
                                               filters.endDate,
                                               filters.publications,
                                               filters.claimants,
                                               filters.categories,
                                               filters.types,
                                               filters.claimantGroups,
                                               filters.checkedClaims);

    this.props.candidateActions.candidatesSearchFromUrl(filterText);
  }

  getMoreCandidates = (filterText) => {
    // in case the get more candidates is called mutliple times only get it if it isn't already getting it
    if(!this.props.isGettingMoreCandidates) {

      let filters = this.createApiReadyFiltersFromText(filterText || '');

      this.props.candidateActions.loadMoreCandidates(filters.exactPhrase,
                                                   filters.hasWords,
                                                   filters.excludeWords,
                                                   filters.startDate,
                                                   filters.endDate,
                                                   filters.publications,
                                                   filters.claimants,
                                                   filters.categories,
                                                   filters.types,
                                                   filters.claimantGroups,
                                                   filters.checkedClaims,
                                                   this.props.nextCursor);
    }
  }

  handleSearchFromAdvancedForm = (text) => {
    this.props.candidateActions.candidatesSearchFromAdvancedForm(text);
    this.handleSearchTextChange(text);
  }

  handleSearchFromUserInput = (text) => {
    this.props.candidateActions.candidatesSearchFromInput(text);
    this.handleSearchTextChange(text);
  }

  handleSearchTextChange = (text) => {
    // action purely for analytics

    this.props.candidateActions.changeCandidatesFilter();

    let queryParams = [];
    this.filterText =  text;

    // Add text
    if(text) {
      queryParams.push('q='+encodeURIComponent(text));
    }

    // Generate URI
    if(queryParams.length){
      let generatedURI = '?'+queryParams.join('&');
      this.props.history.push(generatedURI);
      if((generatedURI.length + window.location.href.length) >= 2000) {
        this.props.candidateActions.longCandidatesUrl();
      }
    } else {
      this.props.history.push('/search/');
    }

    // Call API
    this.loadData(text || '');
  }

  getClaimantsAutoComplete  = (text) => {
    if(text && text !== '') {
      this.props.candidateActions.loadClaimantsAutocomplete(text);
    }
  }

  render() {
    const {
      candidates,
      candidatesCount,
      candidatesRequestState,
      claimants,
      trendingClaimants,
      claimTypes,
      publications,
      publicationsRequestState,
      mediaCategories,
      notice,
      hasMoreCandidates,
      claimantGroups,
      classes,
    } = this.props;

    let fullCandidateCount = candidatesCount;

    return (
      <div className={classes.candidatesPage}>
        {notice && (
          <div className={classes.candidatesPageNotice}>
            <Notice message={notice.message} messageKey={notice.messageKey} type={notice.type}/>
          </div>
        )}
        <SearchManager
          candidates={candidates}
          candidatesCount={fullCandidateCount}
          candidatesRequestState={candidatesRequestState}
          claimants={claimants}
          trendingClaimants={trendingClaimants}
          claimTypes={claimTypes}
          publications={publications}
          publicationsRequestState={publicationsRequestState}
          mediaCategories={mediaCategories}
          initialFilters={this.initialFilters}
          handleSearchFromAdvancedForm={this.handleSearchFromAdvancedForm}
          handleSearchFromUserInput={this.handleSearchFromUserInput}
          hasMoreCandidates={hasMoreCandidates}
          getMoreCandidates={this.getMoreCandidates}
          getClaimantsAutoComplete={this.getClaimantsAutoComplete}
          claimantGroups={claimantGroups}
          hasQuery={this.filterText && this.filterText.length > 0}
        />
      </div>
    );
  }
}

SearchPage.propTypes = {
  candidateActions: PropTypes.object,
  candidates: PropTypes.array,
  candidatesCount: PropTypes.number,
  candidatesRequestState: PropTypes.string,
  claimants: PropTypes.array,
  history: PropTypes.object,
  i18n: PropTypes.object,
  location: PropTypes.object,
  mediaCategories: PropTypes.array,
  notice: PropTypes.object,
  noticeActions: PropTypes.object,
  t: PropTypes.func,
  trendingClaimants: PropTypes.array,
  claimTypes: PropTypes.array,
  publications: PropTypes.array,
  publicationsRequestState: PropTypes.string,
  hasMoreCandidates: PropTypes.bool,
  nextCursor: PropTypes.object,
  isGettingMoreCandidates: PropTypes.bool,
  claimantGroups: PropTypes.array,
  claimActions: PropTypes.object,
  interventionActions: PropTypes.object,
  classes: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    candidates: candidateSelectors.getCandidates(state),
    candidatesCount: candidateSelectors.getCandidatesCount(state),
    candidatesRequestState: candidateSelectors.getRequestState(state,'candidates'),
    claimants: candidateSelectors.getClaimants(state),
    trendingClaimants: candidateSelectors.getTrendingClaimants(state),
    claimTypes: candidateSelectors.getClaimTypes(state),
    mediaCategories: candidateSelectors.getMediaCategories(state),
    publications: candidateSelectors.getPublications(state),
    publicationsRequestState: candidateSelectors.getRequestState(state,'publications'),
    claimantGroups: candidateSelectors.getClaimantGroups(state),
    hasMoreCandidates: candidateSelectors.hasMoreCandidates(state),
    notice: noticeSelectors.getNoticeById(state,'page_notice'),
    nextCursor: candidateSelectors.getNextCursor(state),
    isGettingMoreCandidates: candidateSelectors.getRequestState(state,'moreCandidates') === 'pending',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    candidateActions: bindActionCreators(candidateActions, dispatch),
    claimActions: bindActionCreators(claimActions, dispatch),
    noticeActions: bindActionCreators(noticeActions, dispatch),
    interventionActions: bindActionCreators(interventionActions, dispatch)
  };
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(() => ({
    candidatesPage: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    candidatesPageNotice: {
      marginTop: '10px'
    }
}))
)(SearchPage);
