import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import MQTTFeed from 'Components/Live/MQTTFeed';
import TranscriptTools from 'Components/Transcript/TranscriptTools';
import TranscriptMatchesSummary from 'Components/Transcript/TranscriptMatchesSummary';
import TranscriptSentences from 'Components/Transcript/TranscriptSentences';
import transcriptActions from 'Actions/transcriptActions';
import authSelectors from 'Selectors/authSelectors';
import transcriptSelectors from 'Selectors/transcriptSelectors';
import Notice from 'Components/Common/Notice';

export class TranscriptSentencesContainer extends Component {
  constructor(props) {
    super(props);

    let sentenceFeed;
    this.props.loadTranscriptSentences(this.props.transcriptId);
    sentenceFeed = new MQTTFeed(this.props.mqttUrl,['transcripts_v2/' + this.props.transcriptId + "/#"], this.announceSentence(this));
   
    let autoScrollEnabled = false;
    if(this.props.isOpen) autoScrollEnabled = true;

    this.state = {
      sentenceFeed: sentenceFeed,
      firstFeedSentenceId: null,
      secondsSinceSentence: 0,
      showSummaryBox: false,
      autoScrollEnabled: autoScrollEnabled
    };
  }


  componentDidMount() {
    if(this.props.isOpen) window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if(this.props.sentences.length !== prevProps.sentences.length) {
      let stream = this.liveStream;
      if((this.props.isOpen) && stream && this.state.autoScrollEnabled){
        stream.scrollIntoView(false);
      }
    } else if (this.props.sentenceIdAtTime !== prevProps.sentenceIdAtTime){
      const sentenceToScrollTo = document.getElementById(this.props.sentenceIdAtTime);
      if(sentenceToScrollTo) {
        let yOffset = -190; 
        if(this.props.hasVideo && window.innerWidth < 960) yOffset = -430;
        const y = sentenceToScrollTo.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    } 
  }

  componentWillUnmount() {
    const { sentenceFeed } = this.state;
    if(sentenceFeed && sentenceFeed.tuned_channel !== "" && sentenceFeed.client) {
      if(sentenceFeed.client.isConnected()) {
        sentenceFeed.client.disconnect();
      }
    }
    if(this.props.isOpen) window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (ev) => {
    let element = ev.target.scrollingElement;
    let atBottom = Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 1;

    if(atBottom) {
      this.enableAutoScroll();
    } else {
      this.disableAutoScroll();
    }
  }

  checkSecondsSinceSentence() {
    if(this.state.secondsSinceSentence > 29 && this.state.secondsSinceSentence < 31 ){
      this.props.transcriptActions.sendTranscriptTiming();
    }
  }

  announceSentence(that){
    return function(rawSentence) {
      let changeEvent = JSON.parse(rawSentence);
      if(changeEvent.change_type === 'new_sentences' && changeEvent.data && changeEvent.data.new_sentences && changeEvent.data.new_sentences.length){
        let sentenceId = changeEvent.data.new_sentences[0].transcript_sentence_id;
        if(!that.state.firstFeedSentenceId) {
          that.setState({
            firstFeedSentenceId: sentenceId
          });
        }
        if(that.props.hasReceivedSentences && that.props.sentences.length){
          const lastSentence = that.props.sentences[that.props.sentences.length-1];
          if(lastSentence.transcript_sentence_id !== sentenceId) {
            changeEvent.data.new_sentences.forEach((sentence) => {
              that.props.transcriptActions.sentenceArrived(JSON.stringify(sentence));
            });
          }
        } else {
          changeEvent.data.new_sentences.forEach((sentence) => {
            that.props.transcriptActions.sentenceArrived(JSON.stringify(sentence));
          });
        }
      } else if (changeEvent.change_type === 'transcript_closed') {
        that.props.transcriptActions.changeTranscriptToStopped(that.props.transcriptId);
      } else {
        that.props.transcriptActions.modificationArrived(changeEvent);
      }
    };
  }

  handleSentenceSelect = (sentenceId, hasHighlight) => {
    const {
      transcriptId
    } = this.props;

    this.props.handleSentenceHighlight(transcriptId, sentenceId, hasHighlight);
  }

  toggleMatchSummary = () => {
    this.setState({
      showSummaryBox: !this.state.showSummaryBox
    });
  }

  enableAutoScroll = () => {
    this.setState({
      autoScrollEnabled: true
    });
  }

  disableAutoScroll = () => {
    this.setState({
      autoScrollEnabled: false
    });
  }

  handlePlaySentence = (time) => {
    this.props.handlePlaySentence(Math.floor(time /1000));
  }

  render() {
    const {
      colClass,
      hasReceivedSentences,
      isDemo,
      isOpen,
      isRequestingSentences,
      sentenceIds,
      transcriptId,
      summarisedMatches,
      showTime,
      isErrored,
      canPlaySentences,
      initialSentenceId,
      hasVideo
    } = this.props;

    let sentences = this.props.sentences;

    const canHighlight = !isDemo;

    return (
      <div>
        <TranscriptMatchesSummary 
          toggleMatchSummary={this.toggleMatchSummary} 
          summarisedMatches={summarisedMatches} 
          showSummaryBox={this.state.showSummaryBox} 
          />
        <TranscriptTools 
          enableAutoScroll={this.enableAutoScroll} 
          disableAutoScroll={this.disableAutoScroll}
        />
        <div ref={el => this.liveStream = el}>
          <TranscriptSentences
            canHighlight={canHighlight}
            colClass={colClass}
            handleSentenceSelect={this.handleSentenceSelect}
            hasMatchLinks={true}
            hasReceivedSentences={hasReceivedSentences}
            isDemo={isDemo}
            isOpen={isOpen}
            isRequestingSentences={isRequestingSentences}
            sentenceIds={sentenceIds}
            sentences={sentences}
            transcriptId={transcriptId}
            floatMatches={!this.state.showSummaryBox}
            showTime={showTime}
            currentlyPlayingSentence={this.props.sentenceIdAtTime}
            canPlaySentences={canPlaySentences}
            handlePlaySentence={this.handlePlaySentence}
            initialSentenceId={initialSentenceId}
            hasVideo={hasVideo}
          />
        </div>
        {isErrored && sentences.length >= 10 && (
          <Notice messageKey="transcript_sentences.transcript_errored" displayCode="page_notice" />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sentenceIds: transcriptSelectors.getSentenceIds(state),
    sentences: transcriptSelectors.getSentences(state),
    isRequestingSentences: transcriptSelectors.getIsRequestingSentences(state),
    hasReceivedSentences:  transcriptSelectors.getHasReceivedSentencesSuccess(state),
    summarisedMatches: transcriptSelectors.getTranscriptMatches(state),
    mqttUrl: authSelectors.getMqttUrl(state),
    sentenceIdAtTime: transcriptSelectors.getSentenceIdAtTime(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transcriptActions: bindActionCreators(transcriptActions, dispatch)
  };
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TranscriptSentencesContainer);

TranscriptSentencesContainer.propTypes = {
  colClass: PropTypes.string,
  isOpen: PropTypes.bool,
  transcriptActions: PropTypes.object,
  transcriptId: PropTypes.number,
  sentenceIds: PropTypes.array,
  isRequestingSentences: PropTypes.bool,
  hasReceivedSentences: PropTypes.bool,
  t: PropTypes.func,
  sentences: PropTypes.array,
  isDemo: PropTypes.bool,
  i18n: PropTypes.object,
  checkingSentences: PropTypes.array,
  mqttUrl: PropTypes.string,
  summarisedMatches: PropTypes.object,
  loadTranscriptSentences: PropTypes.func,
  showTime: PropTypes.bool,
  handleSentenceHighlight: PropTypes.func,
  isErrored: PropTypes.bool,
  canPlaySentences: PropTypes.bool,
  handlePlaySentence: PropTypes.func,
  sentenceIdAtTime: PropTypes.number,
  initialSentenceId: PropTypes.number,
  hasVideo: PropTypes.bool
};
