import channelConstants from 'Constants/channelConstants';
import initialState from './initialState';

export default function channelState(state = initialState.channelState, action) {

  switch (action.type) {
    case channelConstants.SUBTITLE_ARRIVED:
      // Make a copy of the list, as it's immutable
      let subtitleList = state.subtitles.slice();
        // Swap out the last sub?
        let lastSub = subtitleList[subtitleList.length - 1];
        if (lastSub && (lastSub.sentence_id == action.subtitle.sentence_id)) {
          subtitleList[subtitleList.length - 1] = action.subtitle;
        } else {
          // No, push the new one
          subtitleList.push(action.subtitle);
        }

      return {
        ...state,
        subtitles: subtitleList
      };

    case channelConstants.CLEAR_SUBS:
      return Object.assign({}, state, {subtitles: []});

    case channelConstants.CLEAR_CHANNEL:
      return Object.assign({}, state, {channel: null});

    case channelConstants.SUBTITLE_SEARCHED:
      let newResults = Object.assign({}, state.results, {});
      newResults[action.subtitle.sentence_id] = action.results;
      return Object.assign({}, state, {results: newResults});

    case `${channelConstants.GET_CHANNELS}_SUCCESS`:
      let channels = action.response.channels.map((channel) => ({
          code: channel.channel_canonical_name,
          display: channel.channel_display_name
      }));
      return Object.assign({}, state, {channels: channels});

    case `${channelConstants.GET_CHANNEL}_SUCCESS`:
      return Object.assign({}, state, {channel: action.response});

    case channelConstants.CLEAR_LIVE:
      return Object.assign({}, initialState.channelState, {});

    default:
      return state;


    }
}
