import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';

export class TranscriptMatchesSummary extends Component {

    constructor(props){
        super(props);
    }

    handleSummaryLink = (sentenceId) => {
      const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if(width < 950) {
        this.props.toggleMatchSummary();
      }
      this.scrollToSentence(sentenceId);
      
    }
    scrollToSentence = (sentenceId) =>{
      let matchCard = document.getElementsByClassName("hit-"+sentenceId);
       
      if(matchCard[0]){
        let element = matchCard[0];
        let headerOffset = 70;
        let elementPosition = element.getBoundingClientRect().top;
        let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
             top: offsetPosition,
             behavior: "smooth"
        });
        matchCard[0].focus();
      }
    }
    render() {
      const {
        classes,
        toggleMatchSummary,
        showSummaryBox,
        t
      } = this.props;

      const {
        sentencesByClaims,
        claimIds,
        totalMatchCount,
        statsChecks
      } = this.props.summarisedMatches;

      let statsSentenceIds = [];
      
      if(statsChecks) statsSentenceIds = Object.keys(statsChecks);
      const openClass = showSummaryBox ? classes.openBox : '';
        return (
          <div className={classes.tools + ' ' + openClass}>
            {totalMatchCount > 0 ? (
              <div>
                {!showSummaryBox ? (
                  <Tooltip title={t('transcript_matches_summary.show_matching_claims')}>
                  <div className={classes.outerCounter}>
                      <Fab color="primary" onClick={toggleMatchSummary} size="small">
                          {totalMatchCount} ⋮
                      </Fab>
                  </div>
                  </Tooltip>
                ): (
                    <div className={classes.matchSummary}>
                      <div className={classes.innerCounter}>
                        <Fab color="primary" onClick={toggleMatchSummary} size="small">
                            {totalMatchCount} ⋮
                        </Fab>
                        </div>
                        <div className={classes.claimSentences}>
                          {claimIds.length > 0 && (<h4>{t('transcript_matches_summary.matched_claims')}</h4>)}
                          {claimIds.map((claimId) => (
                            <div key={claimId}>
                              <h5><span>{sentencesByClaims[claimId].claim}</span></h5>
                              <ol>
                                {sentencesByClaims[claimId].sentences.map((sentence) => (
                                  <li key={sentence.sentenceId} className={classes.sentenceLink} onClick={()=>{this.handleSummaryLink(sentence.sentenceId);}}>{sentence.sentenceText}</li>
                                ))}
                              </ol>
                            </div>
                          ))}
                          {statsSentenceIds.length > 0 && (<h4>{t('transcript_matches_summary.stats_checked')}</h4>)}
                          {statsSentenceIds.map((sentenceId) => (
                            <div key={sentenceId}>
                              <h5><span>{statsChecks[sentenceId].sentenceText}</span></h5>
                              <div className={classes.sentenceLink} onClick={()=>{this.handleSummaryLink(sentenceId);}}>{statsChecks[sentenceId].statsText}
                              </div>
                            </div>
                          ))}
                        </div>
                    </div>
                )}
            </div>
            ):(
              <Tooltip title={t('transcript_matches_summary.no_claims_found')}>
                <div className={classes.outerCounter}>
                    <Fab color="primary" disabled={true} size="small">
                        {0} ⋮
                    </Fab>
                </div>
              </Tooltip>
            )}
          </div>
        );
    }
}

TranscriptMatchesSummary.propTypes = {
  matches: PropTypes.object,
  classes: PropTypes.object,
  showSummaryBox: PropTypes.bool,
  toggleMatchSummary: PropTypes.func,
  summarisedMatches: PropTypes.object,
  t: PropTypes.func
};

export default compose(
  withTranslation(),
  withStyles(() => ({
    count: {
      marginBottom: ' 6px'
    },
    tools: {
      zIndex: '3',
      position: 'fixed',
      top: '101px',
      fontSize: '12px',
      right: '0',
      padding: '5px',
      minWidth: '37px',
      cursor: 'pointer',
      textAlign: 'right',
    },
    openBox: {
      top: '0 !important'
    },
    matchSummary: {
      width: '100%',
      textAlign: 'left',
      fontSize: '15px',
      lineHeight: '1.5',
      height: '100vh',
      backgroundColor: '#fff',
      '& h4': {
        marginTop: '0',
        paddingTop: '12px'
      },
      overflowY: 'scroll',
    },
    claimSentences: {
      width: '100%'
    },
    '@media (min-width: 960px)' : {
        openBox: {
          top: '74px !important'
        },
        matchSummary: {
          height: '70vh',
          width: '352px',
          padding: '12px',
          boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)'
        },
        claimSentences: {
          height: '60vh',
          overflowY: 'scroll',
          width: '100%'
        },
    },

    sentenceText: {
      fontSize: '17px'
    },
    innerCounter: {
      textAlign: 'right',
      padding: '15px 0 0 0',
      float: 'right',
      margin: '0 4px 0 0'
    
    },
    outerCounter: {
      padding: '0 16px 0 0'
    },
    sentenceLink: {
      color: '#009688',
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }))
)(TranscriptMatchesSummary);
