import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Nav from './Nav';
import authActions  from 'Actions/authActions';
import authSelectors from 'Selectors/authSelectors';

export class NavContainer extends React.Component {

  constructor(props){
      super(props);
  }

  handleLogout = () => {
    this.props.logout();
  }

  render(){
    const {
      user,
      orgDisplayName,
      permissions,
      orgAvatarImg,
      className,
      history,
      authorisedRoutes,
      additionalLogos,
    } = this.props;
    return (
      <Nav
        user={user}
        orgDisplayName={orgDisplayName}
        permissions={permissions}
        orgAvatarImg={orgAvatarImg}
        className={className}
        history={history}
        handleLogout={this.handleLogout}
        authorisedRoutes={authorisedRoutes}
        additionalLogos={additionalLogos}
      />
    );
  }
}

NavContainer.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.array,
  className: PropTypes.string,
  authActions: PropTypes.object,
  user: PropTypes.object,
  orgDisplayName: PropTypes.string,
  orgAvatarImg: PropTypes.string,
  authorisedRoutes: PropTypes.array,
  logout: PropTypes.func,
  additionalLogos: PropTypes.array
};

const mapDispatchToProps = {
    logout: authActions.logout
};

function mapStateToProps(state) {
  return {
    permissions: authSelectors.getPermissions(state),
    user: authSelectors.getUser(state),
    orgDisplayName: authSelectors.getOrgDisplayName(state),
    orgAvatarImg: authSelectors.getOrgAvatarImg(state),
    authorisedRoutes: authSelectors.getAuthorisedRoutes(state),
    additionalLogos: authSelectors.getAdditionalLogos(state)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavContainer);


