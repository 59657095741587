import { put, takeEvery } from 'redux-saga/effects';
import claimConstants from 'Constants/claimConstants';
import { Claim } from 'Models/Claim';

export function* processGetClaim(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/tracked-claims/${action.id}/`
    }
  });
}

export function* processGetClaims(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/tracked-claims/`
    }
  });
}

export function* processUpdateClaim(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/tracked-claims/${action.id}/`,
      method: 'PUT',
      body: Claim.toApi(action.claim)
    },
    request: {
      context: action.context
    }
  });
}

export function* processCreateClaim(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/tracked-claims/`,
      method: 'POST',
      body: Claim.toApi(action.claim)
    }
  });
}

export function* processDeleteClaim(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path: `/tracked-claims/${action.id}/`,
      method: 'DELETE'
    },
    request: {
      id: action.id
    }
  });
}

export function* processStarClaim(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      method: 'PUT',
      path:  `/tracked-claims/${action.id}/star/`,
    },
    request: {
      claimId: action.id
    }
  });
}

export function* processUnstarClaim(action) {

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      method: 'DELETE',
      path:  `/tracked-claims/${action.id}/star/`,
    },
    request: {
      claimId: action.id
    }
  });
}

export function* processSaveClaimSuccess(action) {
  yield put({
    type: claimConstants.STOP_EDITING_CLAIM
  });
}


export function* processStopEditingClaim(action) {
  yield put({
    type: claimConstants.CLEAR_PATTERN_SIGHTINGS
  });
}

export function* processGetMatchedSightingsForClaim(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path:  `/reviewed_sightings/${action.claimId}/`,
    },
    request: {
      claimId: action.claimId
    }
  });
}

export function* processGetReviewedSightings(action) {

  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path:  `/reviewed_sightings/`,
    }
  });
}

export function* processGetConfirmedSightings(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path:  '/reviewed_sightings/?is_matched=true'
    }
  });
}

export function* processGetDismissedSightings(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path:  '/reviewed_sightings/?is_matched=false'
    }
  });
}

export function* processGetMoreConfirmedSightings(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path:  '/reviewed_sightings/?is_matched=true&next_cursor=' + action.cursor
    }
  });
}

export function* processGetMoreDismissedSightings(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      path:  '/reviewed_sightings/?is_matched=false&next_cursor=' + action.cursor,
    }
  });
}

export function* processAddMatchedSighting(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      method: 'POST',
      path:  `/reviewed_sightings/`,
      body: {
        tracked_claim_id: action.claimId,
        media_hash: action.mediaHash,
        is_matched: true
      }
    },
    request: {
      claimId: action.claimId,
      mediaHash: action.mediaHash
    }
  });
}


export function* processRemoveMatchedSighting(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      method: 'DELETE',
      path:  `/reviewed_sightings/`,
      body: {
        tracked_claim_id: action.claimId,
        media_hash: action.mediaHash
      }
    },
    request: {
      claimId: action.claimId,
      mediaHash: action.mediaHash
    }
  });
}

export function* processDismissSighting(action) {
  yield put({
    type: 'FETCH',
    requestType: action.type,
    payload: {
      method: 'POST',
      path:  `/reviewed_sightings/`,
      body: {
        tracked_claim_id: action.claimId,
        media_hash: action.mediaHash,
        is_matched: false
      }
    },
    request: {
      claimId: action.claimId,
      mediaHash: action.mediaHash
    }
  });
}


export function* claimSagas(){
  yield takeEvery(claimConstants.GET_CLAIM, processGetClaim);
  yield takeEvery(claimConstants.GET_CLAIMS, processGetClaims);
  yield takeEvery(claimConstants.UPDATE_CLAIM, processUpdateClaim);
  yield takeEvery(claimConstants.CREATE_CLAIM, processCreateClaim);
  yield takeEvery(claimConstants.DELETE_CLAIM, processDeleteClaim);
  yield takeEvery(claimConstants.STAR_CLAIM, processStarClaim);
  yield takeEvery(claimConstants.UNSTAR_CLAIM, processUnstarClaim);
  yield takeEvery(`${claimConstants.UPDATE_CLAIM}_SUCCESS`, processSaveClaimSuccess);
  yield takeEvery(`${claimConstants.CREATE_CLAIM}_SUCCESS`, processSaveClaimSuccess);
  yield takeEvery(claimConstants.STOP_EDITING_CLAIM, processStopEditingClaim);
  yield takeEvery(claimConstants.MATCHED_SIGHTINGS_FOR_CLAIM_ID, processGetMatchedSightingsForClaim);
  yield takeEvery(claimConstants.ADD_MATCHED_SIGHTING, processAddMatchedSighting);
  yield takeEvery(claimConstants.REMOVE_MATCHED_SIGHTING, processRemoveMatchedSighting);
  yield takeEvery(claimConstants.GET_REVIEWED_SIGHTINGS, processGetReviewedSightings);
  yield takeEvery(claimConstants.GET_CONFIRMED_SIGHTINGS, processGetConfirmedSightings);
  yield takeEvery(claimConstants.GET_DISMISSED_SIGHTINGS, processGetDismissedSightings);
  yield takeEvery(claimConstants.GET_MORE_CONFIRMED_SIGHTINGS, processGetMoreConfirmedSightings);
  yield takeEvery(claimConstants.GET_MORE_DISMISSED_SIGHTINGS, processGetMoreDismissedSightings);
  yield takeEvery(claimConstants.DISMISS_SIGHTING, processDismissSighting);
  yield takeEvery(claimConstants.REMOVE_DISMISSED_SIGHTING, processRemoveMatchedSighting);
}

