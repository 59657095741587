import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MomentUtils from "@date-io/moment";
import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from '@material-ui/pickers';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

class TranscriptFormChannelDates extends Component {

  constructor(props) {
      super(props);

      this.state = {
        start: "now"
      };

  }

  handleDateToggle = (e) => {
      this.setState({start: e.target.value});
      this.props.setValue(e.target.value, e);
  }

  setFromDate = (date) => {
    this.props.setDateTime('fromDate', date);
  }

  setFromTime = (date) => {
    this.props.setDateTime('fromTime', date);
  }

  setToDate = (date) => {
    this.props.setDateTime('toDate', date);
  }

  setToTime = (date) => {
    this.props.setDateTime('toTime', date);
  }

  render() {
    const {
      t,
      isLive,
      fromDate,
      fromTime,
      toDate,
      toTime,
      classes
    } = this.props;

    const { start } = this.state;

    return(
      <section>
      {isLive ? (
        <RadioGroup
            margin="normal"
            className={classes.radioOption}
            name="start"
            value={start}
            onChange={this.handleDateToggle}
            row
          >
          <FormControlLabel
            value="now"
            control={<Radio color="primary" />}
            label={t('transcript_form_channel_dates.now')}
            labelPlacement="end"
          />
          <FormControlLabel
            value="atTime"
            control={<Radio color="primary" />}
            label={t('transcript_form_channel_dates.set_time')}
            labelPlacement="end"
          />
        </RadioGroup>
      ) : ''}
      {start === "atTime" || !isLive ? (
        <div className={classes.fromPicker}>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <div className={classes.fromDate}>
              <KeyboardDatePicker
                margin="normal"
                id="fromDate"
                name="fromDate"
                label={t('transcript_form_channel_dates.from_date')}
                format="YYYY-MM-DD"
                value={fromDate}
                onChange={this.setFromDate}
                cancelLabel={t('transcript_form_channel_dates.cancel')}
                okLabel={t('transcript_form_channel_dates.ok')}
              />
              </div>
              <KeyboardTimePicker
                className={classes.fromTime}
                margin="normal"
                id="fromTime"
                name="fromTime"
                label={t('transcript_form_channel_dates.from_time')}
                value={fromTime}
                onChange={this.setFromTime}
                cancelLabel={t('transcript_form_channel_dates.cancel')}
                okLabel={t('transcript_form_channel_dates.ok')}
              />
          </MuiPickersUtilsProvider>
          </div>
      ): ''}
      {!isLive ? (
        <div className={classes.untilPicker}>
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <div className={classes.toDate}>
          <KeyboardDatePicker
            margin="normal"
            id="toDate"
            name="toDate"
            label={t('transcript_form_channel_dates.to_date')}
            format="YYYY-MM-DD"
            value={toDate}
            onChange={this.setToDate}
            cancelLabel={t('transcript_form_channel_dates.cancel')}
            okLabel={t('transcript_form_channel_dates.ok')}
          />
          </div>
          <KeyboardTimePicker
            className={classes.toTime}
            margin="normal"
            id="toTime"
            name="toTime"
            label={t('transcript_form_channel_dates.to_time')}
            value={toTime}
            onChange={this.setToTime}
            cancelLabel={t('transcript_form_channel_dates.cancel')}
            okLabel={t('transcript_form_channel_dates.ok')}
          />
        </MuiPickersUtilsProvider>
      </div>
      ) : ''}
      </section>
    );
  }
}

TranscriptFormChannelDates.propTypes = {
  fromDate: PropTypes.instanceOf(Date),
  fromTime: PropTypes.instanceOf(Date),
  toDate: PropTypes.instanceOf(Date),
  toTime: PropTypes.instanceOf(Date),
  isLive: PropTypes.bool,
  t: PropTypes.func,
  setValue: PropTypes.func,
  setDateTime: PropTypes.func,
  i18n: PropTypes.object,
  classes: PropTypes.object
};

export default compose(
  withStyles(() => ({
    radioOption: {
      display: 'inline-block',
      marginRight: '36px',
      marginTop: '12px'
    },
    untilPicker: {
      marginBottom: '12px'
    },
    fromPicker: {
      marginBottom: '12px'
    },
    fromDate: {
      display: 'inline-block',
      marginRight: '12px'
    },
    toDate: {
      display: 'inline-block',
      marginRight: '12px'
    }
  })),
  withTranslation('translation')
)(TranscriptFormChannelDates);
