import claimConstants from 'Constants/claimConstants';

export function clearClaims() {
  return {
    type: claimConstants.CLEAR_CLAIMS
  };
}

export function deleteClaim(id) {
  return {
    type: claimConstants.DELETE_CLAIM,
    id: id
  };
}

export function loadClaim(id) {
  return {
    type: claimConstants.GET_CLAIM,
    id: id
  };
}

export function loadClaims() {
  return {
    type: claimConstants.GET_CLAIMS
  };
}

export function saveClaim(claim, context) {
  if (claim.trackedClaimId) {
    return {
      type: claimConstants.UPDATE_CLAIM,
      id: claim.trackedClaimId,
      claim: claim,
      context: context
    };
  } else {
    return {
      type: claimConstants.CREATE_CLAIM,
      claim: claim
    };
  }
}

export function updateClaim(claim, context) {
  return {
      type: claimConstants.UPDATE_CLAIM,
      id: claim.trackedClaimId,
      claim: claim,
      context: context
  };
}

export function createClaim(claim, context) {
  return {
    type: claimConstants.CREATE_CLAIM,
    claim: claim
  };
}

export function setClaimsOrderBy(order) {
  return {
    type: claimConstants.SET_CLAIMS_ORDER,
    order: order
  };
}

export function setClaimsSortBy(sort) {
  return {
    type: claimConstants.SET_CLAIMS_SORT,
    sort: sort
  };
}

export function setClaimsFilterText(text) {
  return {
    type: claimConstants.SET_CLAIMS_FILTER_TEXT,
    text: text
  };
}

export function setFilter(field, value) {
  if(field === 'starred'){
    return {
      type: claimConstants.SET_CLAIMS_STARRED_FILTER,
      starredFilter: value
    };
  } else if (field === 'pattern'){
    return {
      type: claimConstants.SET_CLAIMS_PATTERN_FILTER,
      patternFilter: value
    };
  }
}

export function starClaim(claimId) {
  return {
    type: claimConstants.STAR_CLAIM,
    id: claimId
  };
}

export function unstarClaim(claimId) {
  return {
    type: claimConstants.UNSTAR_CLAIM,
    id: claimId
  };
}

export function startEditingClaim(claimId) {
  return {
    type: claimConstants.START_EDITING_CLAIM,
    claimId: claimId || 0
  };
}

export function stopEditingClaim() {
  return {
    type: claimConstants.STOP_EDITING_CLAIM
  };
}

export function setClaimsFilterDateRange(dateRange) {
  return {
    type: claimConstants.SET_CLAIMS_FILTER_DATE_RANGE,
    dateFrom: dateRange.dateFrom,
    dateUntil: dateRange.dateUntil
  };

}

export function getConfirmedSightings() {
  return {
    type: claimConstants.GET_CONFIRMED_SIGHTINGS
  };
}

export function getDismissedSightings() {
  return {
    type: claimConstants.GET_DISMISSED_SIGHTINGS
  };
}

export function getMoreConfirmedSightings(cursor) {
  return {
    type: claimConstants.GET_MORE_CONFIRMED_SIGHTINGS,
    cursor: cursor
  };
}

export function getMoreDismissedSightings(cursor) {
  return {
    type: claimConstants.GET_MORE_DISMISSED_SIGHTINGS,
    cursor: cursor
  };
}


export function getMatchedSightingsForClaim(claimId) {
  return {
    type: claimConstants.MATCHED_SIGHTINGS_FOR_CLAIM_ID,
    claimId: claimId,
  };
}

export function addMatchedSighting(claimId, mediaHash) {
  return {
    type: claimConstants.ADD_MATCHED_SIGHTING,
    claimId: claimId,
    mediaHash: mediaHash
  };
}

export function removeMatchedSighting(claimId, mediaHash) {
  return {
    type: claimConstants.REMOVE_MATCHED_SIGHTING,
    claimId: claimId,
    mediaHash: mediaHash
  };
}

export function dismissSighting(claimId, mediaHash) {
  return {
    type: claimConstants.DISMISS_SIGHTING,
    claimId: claimId,
    mediaHash: mediaHash
  };
}

export function removeDismissedSighting(claimId, mediaHash) {
  return {
    type: claimConstants.REMOVE_DISMISSED_SIGHTING,
    claimId: claimId,
    mediaHash: mediaHash
  };
}

export default {
  clearClaims,
  deleteClaim,
  loadClaim,
  loadClaims,
  saveClaim,
  updateClaim,
  createClaim,
  setClaimsOrderBy,
  setClaimsSortBy,
  setClaimsFilterText,
  setClaimsFilterDateRange,
  setFilter,
  starClaim,
  startEditingClaim,
  stopEditingClaim,
  unstarClaim,
  getMatchedSightingsForClaim,
  addMatchedSighting,
  removeMatchedSighting,
  dismissSighting,
  getConfirmedSightings,
  getDismissedSightings,
  getMoreConfirmedSightings,
  getMoreDismissedSightings,
  removeDismissedSighting
};
