import React from "react";
import PropTypes from 'prop-types';
import LiveChannelSentence from "Components/Live/LiveChannelSentence";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

export class LiveChannelSentences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options : {
          ...props.options,
          claimScoreThreshold: 14,
          numItems: 2,
      },
      hasAutoScrolled: false,
      atBottom: true,
      showClaimDetection: true
    };
  }

  scrollIntoView = () => {
    let stream = this.liveStream;
    let grid = document.getElementsByClassName('grid')[0];
    let topContent = window.pageYOffset + window.innerHeight;
    let gridBottom = grid.scrollHeight;

    if(stream){
      if(Math.abs( topContent - gridBottom ) <  window.innerHeight / 2) {
         stream.scrollIntoView(false);
      }
    }
  }

  handleClaimDetectionToggle = () => {
    this.setState({
      showClaimDetection: !this.state.showClaimDetection
    });
  }

  createTranscript = (data) =>{
    this.props.createTranscript(data);
  }


  render() {
    const { t, subtitles, results, classes } = this.props;

    setTimeout(() => {
      this.scrollIntoView();
    },1000);

    let subtitleList = subtitles.map(sub => {
      let resultsForSentence = results[sub.sentence_id];
        return (
          <LiveChannelSentence
            key={sub.sentence_id}
            sentence={sub}
            results={resultsForSentence}
            options={this.state.options}
            channel={this.props.channel.channel_canonical_name}
            display={this.props.channel.channel_display_name}
            createTranscript={this.createTranscript}
            showClaimDetection={this.state.showClaimDetection}
            isFactchecker={false}
          />
        );
      }
    );
    return (
      <div className={classes.feed} ref={el => this.liveStream = el}>
        {subtitleList}
      </div>
    );
  }
}

export default compose(
  withStyles(() => ({
    feed: {
      ['@media (min-width: 960px)']: {
        padding: '24px'
      }
    },
    claimDetectionToggle:  {
      display: 'inline-block',
      marginRight: '12px',
      verticalAlign: 'middle'
    }
    
  })),
  withTranslation()
)(LiveChannelSentences);

LiveChannelSentences.propTypes = {
  subtitles : PropTypes.array.isRequired,
  results : PropTypes.object.isRequired,
  options :  PropTypes.object,
  channel : PropTypes.object.isRequired,
  user: PropTypes.object,
  permissions: PropTypes.array,
  createTranscript: PropTypes.func,
  t: PropTypes.func,
  hasClaimDetection: PropTypes.bool,
  classes: PropTypes.object
};
