import React from 'react';
import PropTypes from 'prop-types';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import IconButton from '@material-ui/core/IconButton';
import { withTranslation } from 'react-i18next';

const TablePagination = (props) => {
    const {
      start,
      end,
      totalPages,
      currentPage,
      handleSetPage,
      totalResults,
      classes,
      t
    } = props;

    let backButtonEnabled = currentPage !== 1;
    let forwardButtonEnabled = currentPage !== totalPages;

    return (
      <div className={classes.paginationContainer}>
        <span className={classes.pageSummary}>
        {t('table_pagination.range', { start, end, totalResults })}
        <IconButton disabled={!backButtonEnabled} className={classes.clearSearchButton} size="small" onClick={() => {
            handleSetPage(currentPage-1);
        }}>
        <NavigateBeforeIcon />
        </IconButton>
        <IconButton disabled={!forwardButtonEnabled} className={classes.clearSearchButton} size="small" onClick={() => {
            handleSetPage(currentPage+1);
        }}>
          <NavigateNextIcon />
        </IconButton>
        </span>
      </div>
    );
};

TablePagination.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  start: PropTypes.number,
  end: PropTypes.number,
  totalResults: PropTypes.number,
  classes: PropTypes.object,
  handleSetPage: PropTypes.func,
  t:  PropTypes.func
};


export default compose(
  withTranslation(),
  withStyles(() => ({
    paginationContainer: {
      padding: '12px'
    },
    pageSummary: {
      verticalAlign: 'middle',
      fontSize: '14px;',
      textAlign: 'right',
      display: 'block'
    }
  }))
)(TablePagination);
