import { put, takeEvery } from 'redux-saga/effects';
import channelConstants from 'Constants/channelConstants';

export function* processGetChannels(action) {
   yield put({
     type: 'FETCH',
     requestType: action.type,
     payload: {
       path: '/channels/'
     }
   });
}

export function* processGetChannel(action) {
   yield put({
     type: 'FETCH',
     requestType: action.type,
     payload: {
       path: `/channels/${action.id}/`
     }
   });
}

export function* channelSagas(){
  yield takeEvery(channelConstants.GET_CHANNELS, processGetChannels);
  yield takeEvery(channelConstants.GET_CHANNEL, processGetChannel);
}