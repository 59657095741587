import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

export class TranscriptTools extends Component{

  constructor(props){
    super(props);
  }

  jumpToBottom = () => {
    window.scrollTo(0,document.body.scrollHeight);
    this.props.enableAutoScroll();
  }

  jumpToTop = () => {
    window.scrollTo(0,0);
    this.props.disableAutoScroll();
  }

  render(){

    const {
      classes,
      t
    } = this.props;


    return (
      <div className={classes.tools}>
        <Tooltip title={t('transcript_tools.jump_to_top')}>
          <Fab color="primary" aria-label="jump to top" onClick={this.jumpToTop} size="small">
            <ArrowUpwardIcon />
          </Fab>
        </Tooltip>
        <Tooltip title={t('transcript_tools.jump_to_bottom')}>
          <Fab color="primary" aria-label="jump to bottom" onClick={this.jumpToBottom} size="small">
              <ArrowDownwardIcon />
          </Fab>
        </Tooltip>
      </div>
    );
  }
}


TranscriptTools.propTypes  = {
  t: PropTypes.func,
  classes: PropTypes.object,
  enableAutoScroll: PropTypes.func,
  disableAutoScroll: PropTypes.func
};

export default compose(
  withTranslation(),
  withStyles(() => ({
    count: {
      marginBottom: ' 6px'
    },
    tools: {
      zIndex: '1',
      position: 'fixed',
      bottom: '0',
      fontSize: '12px',
      right: '0',
      padding: '5px',
      minWidth: '37px',
      margin: '16px',
      cursor: 'pointer',
      '& button': {
        display: 'inherit',
        marginTop: '6px'
      },
      '& .MuiFab-label': {
        marginTop: '6px'
      }
    }
  }))
)(TranscriptTools);
