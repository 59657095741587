import authSelectors from 'Selectors/authSelectors';

export function getUserById(state, id) {
  return state.adminState.users.items.byId[id];
}

export function getUsers(state) {
  if(state.adminState.users.items.allIds){
    return state.adminState.users.items.allIds.map(userId => getUserById(state, userId));
  }
  return [];
}

export function getIsEditing(state) {
  return state.adminState.editingUser;
}

export function getIsCreating(state) {
  return state.adminState.users.isCreating;
}

export function getIsUpdating(state) {
  return state.adminState.users.isUpdating;
}

export function getOrganisationMediaById(state, mediaId) {
  return state.adminState.organisationMedia.byId[mediaId];
}

export function getOrganisationMedia(state) {
  let media = [];
  if(state.adminState.organisationMedia.allIds){
    media = state.adminState.organisationMedia.allIds.map(media_id => {
      return getOrganisationMediaById(state, media_id);
    });
  }
  return media;
}

export function getIsEditingMedia(state) {
  return state.adminState.editingMedia;
}

export function getIsCreatingMedia(state) {
  return state.adminState.organisationMedia.isCreating;
}

export function getIsUpdatingMedia(state) {
  return state.adminState.organisationMedia.isUpdating;
}

export function getHasSchedulesPermission(state) {
  const permissions = authSelectors.getPermissions(state);
  if (permissions.includes('schedules')) return true;
  return false;
}

export default ({
  getUserById,
  getUsers,
  getIsEditing,
  getIsCreating,
  getIsUpdating,
  getOrganisationMedia,
  getIsEditingMedia,
  getIsCreatingMedia,
  getIsUpdatingMedia,
  getHasSchedulesPermission
});