import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SearchSentence from 'Components/Search/SearchSentence';
import CircularProgress from '@material-ui/core/CircularProgress';

export function CheckworthyAllTopics(props) {
  const {
    classes,
    t,
    topicMediaSentences,
    dismissSighting,
    removeReviewedSighting,
    confirmSighting,
    topicsWithoutSentences,
    isGettingTopics,
    currentDay,
    handleTopicDateChange
  } = props;

  let topicData = [[],[]];
  let columnFlag = false;

  topicMediaSentences.topics.forEach((topic) => {
    if(columnFlag) {
      topicData[1].push(topic);
      columnFlag=false;
    } else {
      topicData[0].push(topic);
      columnFlag=true;
    }
  });

  function handleTopicSelect(e, topic){
    e.preventDefault();
    handleTopicDateChange(currentDay, topic)
  }

  return (
    <div>
      <div>
        <div className={classes.contentContainer + ' ' + classes.topicContentContainer}>
          <Typography variant="h2">
            {t('checkworthy_all_topics.title')}
          </Typography>
        </div>
        {isGettingTopics ? (
          <CircularProgress className={classes.loader} />
        ) : (
          <section>
            {topicData[0].length > 0 ? (
              <section>
              <div className={classes.summaryChooser}>
                <div className={classes.sentenceGridLayout}>
                  {topicData.map((data, i) => (
                    <div key={i}>
                      {data.map((topic) => (
                        <div className={classes.topicBox} key={t(topic.code)}>
                          <Card>
                            <CardContent>
                              <SearchSentence
                                isConfirmed={topicMediaSentences.reviewedMediaHashes[topic.sentence.mediaHash]}
                                mediaHash={topic.sentence.mediaHash}
                                url={topic.sentence.url}
                                publication={topic.sentence.publicationName}
                                text={topic.sentence.text}
                                category={topic.code}
                                dismissSighting={dismissSighting}
                                removeReviewedSighting={removeReviewedSighting}
                                confirmSighting={confirmSighting}
                                key={topic.sentence.mediaHash+"_"+topic.code}
                                authorName={topic.sentence.authorName}
                                favicon={topic.favicon}
                              />
                              <a href={"/home/" + topic.code} onClick={(e)=>{ handleTopicSelect(e, topic.code)}}>
                                <Chip
                                  label={t(topic.displayName)}
                                  className={classes.showMoreChip}
                                  style={{backgroundColor: '#009688', color: '#fff'}}
                                />
                              </a>
                            </CardContent>
                          </Card>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div className={classes.contentContainer}>
                  {topicsWithoutSentences.length > 0 && (
                    <div className={classes.notInterestedSubheader}>{t('checkworthy_all_topics.nothing_of_interest')}</div>
                  )}
                  <div className={classes.notInterestedTopics}>
                    {topicsWithoutSentences.map(topic => (
                      <a key={topic.code} href={"/home/" + encodeURIComponent(topic.code)} onClick={(e)=>{ handleTopicSelect(e, topic.code)}}>
                        <Chip
                          label={t(topic.displayName)}
                          style={{backgroundColor: '#ccc', color: '#000'}}
                          className={classes.showMoreChip}
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              </section>
            ) : (
              <div className={classes.contentContainer}>
                {!isGettingTopics && (
                  <div className={classes.trendingSubheader}>
                    {t('checkworthy_all_topics.no_claims')}
                      </div>
                )}
                  </div>
            )}
          </section>
        )}
      </div>
    </div>
  );

}

CheckworthyAllTopics.propTypes = {
  dismissSighting: PropTypes.func,
  removeReviewedSighting: PropTypes.func,
  confirmSighting: PropTypes.func,
  yesterday: PropTypes.string,
  classes: PropTypes.object,
  topicsWithoutSentences: PropTypes.array,
  topicMediaSentences: PropTypes.object,
  t: PropTypes.func,
  isGettingTopics: PropTypes.bool,
  currentDay: PropTypes.string,
  handleTopicDateChange: PropTypes.func,
};

export default compose(
  withTranslation(),
  withStyles((theme) => ({
    contentContainer: {
      padding: '12px 36px 12px 24px'
    },
    loader: {
      marginLeft: '24px'
    },
    topicContentContainer: {
      marginTop: '24px'
    },
    summaryChooser: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0 36px 24px 24px'
    },
    sentenceGridLayout: {
      display: 'block',
    },
    topicBox: {
      marginBottom: '18px',
      marginRight: '18px'
    },
    '@media screen and (max-width: 960px)': {
      topicBox: {
        width: '100%'
      }
    },
    '@media screen and (min-width: 960px)': {
      sentenceGridLayout: {
        display: 'grid',
        gridTemplateColumns: '50% 50%'
      }
    },
    showMoreLink: {
      color: theme.palette.primary[500],
      margin: '12px 0  0 24px',
      fontSize: '15px',
      textDecoration: 'none',
      display: 'block',
      '&:hover' : {
        textDecoration: 'underline'
      }
    },
    showMoreChip: {
      cursor: 'pointer',
      marginRight: '6px',
      marginBottom: '6px'
    },
    notInterestedTopics: {
      display: 'block',
      width: '100%'
    },
    notInterestedSubheader: {
      fontSize: '14px',
      color: '#777',
      marginBottom: '12px'
    }
  }
)))(CheckworthyAllTopics);