export const trendsConstants = {
  GET_LAST_DAY: 'GET_LAST_DAY',
  GET_LAST_WEEK: 'GET_LAST_WEEK',
  GET_LAST_MONTH: 'GET_LAST_MONTH',
  GET_LATEST_TRENDS: 'GET_LATEST_TRENDS',
  GET_AGGREGATED: 'GET_AGGREGATED',
  GET_QUERY_AGGREGATED: 'GET_QUERY_AGGREGATED',
  GET_SIGHTINGS_BY_QUERY: 'GET_SIGHTINGS_BY_QUERY',
  GET_SIGHTINGS_BY_CLAIMS: 'GET_SIGHTINGS_BY_CLAIMS',
  GET_SIGHTINGS: 'GET_SIGHTINGS',
  GET_TRENDS_BY_QUERY: 'GET_TRENDS_BY_QUERY',
  GET_TRENDS_BY_CLAIMS: 'GET_TRENDS_BY_CLAIMS',
  GET_PUBLICATIONS: 'GET_PUBLICATIONS',
  CHANGE_GRAPH_DISTRIBUTION: 'CHANGE_GRAPH_DISTRIBUTION',
  SET_FILTERS: 'SET_FILTERS',
  CLEAR_TRENDS: 'CLEAR_TRENDS',
  CLEAR_TRENDS_SIGHTINGS: 'CLEAR_TRENDS_SIGHTINGS',
  GET_TREND_CLAIMS_WEEK_AGGREGATED: 'GET_TREND_CLAIMS_WEEK_AGGREGATED',
  GET_TREND_CLAIMS_DAY_AGGREGATED: 'GET_TREND_CLAIMS_DAY_AGGREGATED',
  GET_STARRED_TRENDS: 'GET_STARRED_TRENDS',
  GET_STARRED_CLAIMS_AGGREGATED: 'GET_STARRED_CLAIMS_AGGREGATED',
  CLEAR_PATTERN_SIGHTINGS: 'CLEAR_PATTERN_SIGHTINGS',
  UPDATE_SIGHTINGS_BY_CLAIMS: 'UPDATE_SIGHTINGS_BY_CLAIMS',
  UPDATE_SIGHTINGS_BY_QUERY: 'UPDATE_SIGHTINGS_BY_QUERY',
  GET_PATTERN_SIGHTINGS: 'GET_PATTERN_SIGHTINGS',
  GET_INTERVENTIONS_BY_CLAIM_ID: 'GET_INTERVENTIONS_BY_CLAIM_ID',
  SET_TREND_CLAIM_IDS: 'SET_TREND_CLAIM_IDS',
  SET_TREND_QUERY: 'SET_TREND_QUERY',
  UPDATE_SIGHTINGS: 'UPDATE_SIGHTINGS'
};

export default trendsConstants;
