
import entityConstants from 'Constants/entityConstants';

export function getSuggestions(inputValue){
  return {
      type: entityConstants.GET_SUGGESTIONS,
      inputValue: inputValue
  };
}

export function getEntity(wikiId){
    return {
        type: entityConstants.GET_ENTITY,
        wikiId: wikiId
    };
  }

export default {
  getSuggestions: getSuggestions,
  getEntity: getEntity
};
