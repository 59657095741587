import {default as ReactGA4} from 'react-ga4';
import config from '../config';

ReactGA4.initialize([
  {
    trackingId: config.ga.ga4account,
  }
]);

const gaMiddleware = store => next => action => {
  let result = next(action);
  let auth = store.getState().authState;
  if(auth.user && auth.user.organisation && auth.user.userId){
    ReactGA4.set({
      user_id: auth.user.userId+'',
      user_properties: {
        org: auth.user.organisation+''
      }
    });
  } else {
    ReactGA4.set({
      user_id: null,
      user_properties: {
        org: null
      }
    });
  }
  
  if(result) {
    if(result.type === 'LOCATION_CHANGED') {
      ReactGA4.send({ hitType: "pageview", page: result.location.pathname + result.location.search });
    } else if(result.type.substr(-8) === '_FAILURE' && result.error) {
      ReactGA4.event({
        exception: {
          description: result.type + ': ' + result.error.message,
          fatal: false
        }
      });
    } else {
      let eventConfig = config.ga.events[result.type];
      if(eventConfig) {
        if(eventConfig.label) {
          eventConfig.label = result[eventConfig.label];
        }
        ReactGA4.event({...eventConfig});
      }
    }
  }

  return result;
};


export default gaMiddleware;