import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import ActionManagerContainer from 'Components/Action/ActionManagerContainer';

export class ActionsPage extends React.Component {

  constructor(props){
    super(props);
  }
  render() {
    return (
      <div className={this.props.classes.page}>
        <ActionManagerContainer history={this.props.history}/>
      </div>
    );
  }
}

ActionsPage.propTypes = {
  history: PropTypes.object,
  classes: PropTypes.object
};

export default compose(
  withTranslation(),
  withStyles(() => ({
    page: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
}))
)(ActionsPage);