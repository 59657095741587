import adminConstants from 'Constants/adminConstants';

export function startEditingUser() {
  return {
    type: adminConstants.START_EDITING_USER,
  };
}

export function stopEditingUser() {
  return {
    type: adminConstants.STOP_EDITING_USER,
  };
}

export function startEditingMedia() {
  return {
    type: adminConstants.START_EDITING_MEDIA,
  };
}

export function stopEditingMedia() {
  return {
    type: adminConstants.STOP_EDITING_MEDIA,
  };
}

export function changeUserPassword(userId, password, email) {
  return {
    type: adminConstants.CHANGE_USER_PASSWORD,
    userId: userId,
    password: password,
    email: email
  };
}

export function updateUser(user) {
  return {
    type: adminConstants.UPDATE_USER,
    user: user
  };
}

export function createUser(user) {
  return {
    type: adminConstants.CREATE_USER,
    user: user
  };
}

export function deleteUser(userId) {
  return {
    type: adminConstants.DELETE_USER,
    userId: userId
  };
}

export function resendVerify(userId, email) {
  return {
    type: adminConstants.RESEND_VERIFY_USER,
    userId: userId,
    email: email
  };
}

export function getUsers() {
  return {
    type: adminConstants.GET_USERS
  };
}


export function getOrganisationMedia() {
  return {
    type: adminConstants.GET_ORG_MEDIA
  };
}

export function createOrganisationMedia(pub) {
  return {
    type: adminConstants.CREATE_ORG_MEDIA,
    data: pub
  };
}

export function updateOrganisationMedia(pub) {
  return {
    type: adminConstants.UPDATE_ORG_MEDIA,
    data: pub
  };
}

export function deleteOrganisationMedia(pubId) {
  return {
    type: adminConstants.DELETE_ORG_MEDIA,
    data: pubId
  };
}


export default {
  startEditingUser,
  stopEditingUser,
  changeUserPassword,
  updateUser,
  createUser,
  deleteUser,
  resendVerify,
  getUsers,
  getOrganisationMedia,
  createOrganisationMedia,
  updateOrganisationMedia,
  deleteOrganisationMedia,
  startEditingMedia,
  stopEditingMedia
};