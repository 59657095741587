import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const NotAuthorisedPage = (props) => {
  const {t} = props;
  return (
    <div className="container content">
      <h4>
        {t('not_authorised_page.not_authorised_message')}
      </h4>
      <Link to="/">{t('not_authorised_page.go_back')}</Link>
    </div>
  );
};

NotAuthorisedPage.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(NotAuthorisedPage);
