const successMessages = {
    'USER_PASSWORD_CHANGED' : {
      message: 'success_message.user_password_changed',
      displayCode: 'general_notice'
    },
    'CHANGE_TRANSCRIPT_NAME' : {
      message: 'success_message.change_transcript_name_successful',
      displayCode: 'change_name'
    },
    'VERIFICATION_SENT' : {
      message: 'success_message.verification_sent',
      displayCode: 'general_notice'
    },
    'PASSWORD_CHANGED' : {
      message: 'success_message.password_changed',
      displayCode: 'page_notice'
    },
    'YOUR_PASSWORD_CHANGED' : {
      message: 'success_message.your_password_changed',
      displayCode: 'page_notice'
    },
    'RESET_PASSWORD_EMAIL' : {
      message: 'success_message.reset_password_email',
      displayCode: 'page_notice'
    }
};

export default successMessages;