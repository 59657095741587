import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SortableTable from 'Components/Common/SortableTable';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const DigestConfirmedSightings = (props) => {
    const {
      classes,
      confirmedSightings,
      handleCreateIntervention,
      canCreateAction,
      canViewClaim,
      t
    } = props;

    return (
      <SortableTable
          rows={confirmedSightings}
          columns={[
            {
                width: 90,
                label: ' ',
                disableSort: true,
                dataKey: 'edit',
                formatting: (data, rowData) => {
                  return (
                    <div>
                      {canCreateAction ? (
                        <div>
                          {rowData.actionsCount ? (
                          <span className={classes.actionCount}>
                            {t('digest_confirmed_sightings.action_taken')}
                          </span>
                          ): (
                            <Button onClick={() => {
                              handleCreateIntervention(rowData);
                            }} color="primary" variant="contained">
                              {t('digest_confirmed_sightings.add_action')}
                            </Button>
                          )}
                      </div>
                      ):''}
                    </div>
                  );
                }
            },
            {
                width: 60,
                label: t('digest_confirmed_sightings.claim'),
                dataKey: 'tracked_claim_text',
                formatting: (data, rowData) => {
                  if(rowData.tracked_claim_id && canViewClaim) {
                    return (<Link to={"/alerts/claims/"+rowData.tracked_claim_id}>{data}</Link>);
                  } else {
                    return <span>{data}</span>;
                  }
                }
            },
            {
              width: 60,
              label: t('digest_confirmed_sightings.alert_text'),
              dataKey: 'text',
              formatting: (data, rowData) => {
                if(rowData.url) {
                  return <a href={rowData.url}>{data}</a>;
                } else {
                  return <span>{data}</span>;
                }
              }
            },
            {
              width: 60,
              label: t('digest_confirmed_sightings.publication_date'),
              dataKey: 'publication_date',
              formatting: (data) => {
                if(data && data !== '') {
                  return data.split('T')[0];
                } else {
                  return data;
                }
              }
            }
            ]}
        />
    );
};

DigestConfirmedSightings.propTypes = {
  classes: PropTypes.object,
  confirmedSightings: PropTypes.array,
  handleCreateIntervention: PropTypes.func,
  canCreateAction: PropTypes.bool,
  canViewClaim: PropTypes.bool,
  t: PropTypes.func
};

export default compose(
  withTranslation(),
  withStyles(() => ({
    actionCount: {
      textAlign: 'center',
      display: 'block',
      marginBottom: '6px'
    }
  })),
  React.memo
)(DigestConfirmedSightings);
