import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

export function SearchTrendingCard(props) {

  const {
    classes,
    title,
    noResultsText,
    keywords,
    onKeywordClick,
    icon,
    showListOrder,
    description,
    useKeywordsQuery,
    t
  } = props;

  const [showMore, setShowMore] = React.useState(false);

  let hasKeywords = false;
  let cardClassName;
  
  let modifiedKeywords = [...keywords];
  init();
  function init() {
    hasKeywords = keywords && keywords.length > 0;
    cardClassName = classes.container;
    cardClassName += (hasKeywords ? '' : ' ' + classes.containerEmpty);

    if(!showMore) {
      modifiedKeywords = modifiedKeywords.slice(0,10);
    } else {
      modifiedKeywords = modifiedKeywords.slice(0,50);
    }
  }
  
  function handleShowMoreToggle(e) {
    setShowMore(!showMore);
  }

  return (
    <Card className={cardClassName} variant="outlined">
      <h3 className={classes.trendingListTitle}>{icon ? icon : ''} {title}</h3>
      {description ? (<div className={classes.listDescription}>{description}</div>) : ''}
      {
        hasKeywords ?
        <Box>
          <List className={classes.list}>
            {
              modifiedKeywords.map((keyword, index) =>
                (
                  <li key={index} className={classes.keywordRow} onClick={() => {
                    if(useKeywordsQuery) {
                      onKeywordClick(keyword.query);
                    } else {
                      onKeywordClick(keyword.text);
                    }
                    
                  }}>
                    {showListOrder ? (<span className={classes.index}>{index + 1}. </span>) : ''}
                    <span className={classes.text}>{keyword.text}</span>
                    <span className={classes.count}>{keyword.count || ''}</span>
                  </li>
                )
              )
            }
          </List>
          <h5 className={classes.showMore} onClick={handleShowMoreToggle}>
          {
            (keywords.length > 10) &&
              (
                showMore ?
                <Box><span>{t('candidates_manager.saved_search_show_less')}</span> <ExpandLessIcon className={classes.icon}/></Box> :
                <Box><span>{t('candidates_manager.saved_search_show_more')}</span> <ExpandMoreIcon className={classes.icon}/></Box>
              )
          }
          </h5>
        </Box> :
        <Box className={classes.noneFound}>{noResultsText}</Box>
      }
    </Card>
  );
}

SearchTrendingCard.propTypes  = {
  classes: PropTypes.object,
  title: PropTypes.string,
  noResultsText: PropTypes.string,
  keywords: PropTypes.array,
  onKeywordClick: PropTypes.func,
  icon: PropTypes.element,
  showListOrder: PropTypes.bool,
  description: PropTypes.string,
  useKeywordsQuery: PropTypes.bool,
  t: PropTypes.func
};

function areEqual(prevProps, nextProps) {
  if(JSON.stringify(prevProps.keywords) === JSON.stringify(nextProps.keywords)) {
    return true;
  }
  return false;
}

export default React.memo(compose(
  withTranslation(),
  withStyles((theme) => ({
    trendingListTitle: {
      marginBottom: '12px',
      '& svg': {
        verticalAlign: 'middle',
        marginRight: '10px'
      }
    },
    listDescription: {
      marginBottom: '12px',
      border: '1px solid #ccc',
      padding: '12px',
      fontSize: '14px',
      borderRadius: '5px',
      lineHeight: '1.4',
      color: '#555'
    },
    container: {
      boxShadow: theme.outline.boxShadow,
      border: theme.outline.border,
      padding: '0 15px 15px 15px'
    },
    containerEmpty: {
      height: '100px',
    },
    icon: {
      verticalAlign: 'middle',
      marginRight: '10px'
    },
    keywordRow: {
      padding: '2px 0',
      '&:hover': {
        backgroundColor: theme.palette.primary[50],
        cursor: 'pointer'
      }
    },
    text: {
      maxWidth: '210px',
      overflow: 'hidden',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    count: {
      position: 'absolute',
      right: 0
    },
    showMore: {
      margin: '10px 0',
      cursor: 'pointer'
    },
    noneFound: {
      textAlign: 'center'
    }
  }))
)(SearchTrendingCard), areEqual);