import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Notice from 'Components/Common/Notice';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { t } from 'i18next';

export function OrganisationMediaForm(props){

  const {
    onSubmit,
    classes,
    errorNotice,
    processingSubmit,
    formFieldChange
  } = props;

  let publication = props.publication || {
    publicationName: '',
    wikidataPublicationId: '',
    mediaUrl: ''
  };

  const [formPublication, setFormPublication] = React.useState(publication);

  const onFieldChange = (e) => {
    if(errorNotice) formFieldChange()
    setFormPublication({
        ...formPublication,
        [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!processingSubmit) onSubmit(formPublication);
  };

  return (
      <form id="add-media-form" className={classes.userForm} onSubmit={handleSubmit}>
        <TextField
          type="text"
          name="publicationName"
          value={formPublication.publicationName}
          onChange={onFieldChange}
          label={t('entities_page.pub_name')}
          fullWidth={true}
          margin="normal"
          required
        />
        <TextField
          type="text"
          name="mediaUrl"
          value={formPublication.mediaUrl}
          onChange={onFieldChange}
          label={t('organisation_media.url')}
          fullWidth={true}
          margin="normal"
          required
        />
        {errorNotice &&  <Notice message={errorNotice.message} messageKey={errorNotice.messageKey} type={errorNotice.type} />}
        <div className={classes.userFormSubmit}>
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
          >
              {formPublication.publicationId ? t('transcript_schedule_form.update_button') : t('transcript_schedule_form.create_button')}
          </Button>
        </div>
        {processingSubmit && <CircularProgress />}
      </form>
  );
}

OrganisationMediaForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func,
  classes: PropTypes.object,
  publication: PropTypes.object,
  errorNotice: PropTypes.object,
  processingSubmit: PropTypes.bool,
  formFieldChange: PropTypes.func
};

export default compose(
  withStyles(() => ({
    userForm: {
      marginBottom: '12px'
    },
    userFormSubmit: {
      marginTop: '23px',
      marginBottom:'12px'
    },
    roleFormControl: {
      marginTop: '23px'
    }
  })),
  withTranslation()
)(OrganisationMediaForm);
