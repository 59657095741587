export const adminConstants = {
  START_EDITING_USER: 'START_EDITING_USER',
  STOP_EDITING_USER: 'STOP_EDITING_USER',
  CHANGE_USER_PASSWORD: 'CHANGE_USER_PASSWORD',
  RESEND_VERIFY_USER: 'RESEND_VERIFY_USER',
  DELETE_USER: 'DELETE_USER',
  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',
  GET_USERS: 'GET_USERS',
  GET_ORG_MEDIA: 'GET_ORG_MEDIA',
  CREATE_ORG_MEDIA: 'CREATE_ORG_MEDIA',
  UPDATE_ORG_MEDIA: 'UPDATE_ORG_MEDIA',
  DELETE_ORG_MEDIA: 'DELETE_ORG_MEDIA',
  START_EDITING_MEDIA: 'START_EDITING_MEDIA',
  STOP_EDITING_MEDIA: 'STOP_EDITING_MEDIA'
};

export default adminConstants;