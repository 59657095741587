import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import { withTranslation, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

export const ActionSummary = (props) => {
  const {
    t
  } = props;

  const {
    intervention_id,
    intervened_at,
    intervention_type_label,
    wikidata_intervention_target_id,
    intervention_target,
    status_label,
    tracked_claim_id,
    updated_at,
    notes,
    resolution_type_label,
    resolution_summary,
    wikidata_author_name_id,
    author_name,
    category,
    wikidata_publication_id,
    publication,
    publication_date,
    url,
    text
  } = props.action;

  return (
    <div>
      <Typography variant="h2" component="h2">
        {t('action_summary.action_id', {id: intervention_id})}
      </Typography>
      <ul>
        <li>
          {t('action_summary.action_date', {date: intervened_at.split('T').shift()})}
        </li>
        <li>
          {t('action_summary.action_type_label', {type: intervention_type_label})}
        </li>
        {wikidata_intervention_target_id ? (
          <li>
          <Trans i18nKey="action_summary.action_target_link" parent="div" intervention_target={intervention_target}>Target:
            <Link to={'/actions/entities/' + wikidata_intervention_target_id}>
              {{intervention_target}}
            </Link>
          </Trans>
        </li>
        ): ''}
        <li>
          {t('action_summary.status', {status: t(status_label)})}
        </li>
        <li>
          <Trans i18nKey="action_summary.claim_link" parent="div" tracked_claim_id={tracked_claim_id}>
            Claim: <Link to={"/claims/" + tracked_claim_id}>{{tracked_claim_id}}</Link>
          </Trans>
        </li>
        <li>
          {t('action_summary.last_updated', {date: updated_at.split('T').shift()})}
        </li>
        <li>
          {t('action_summary.notes', {notes: notes})}
        </li>
        <li>
          {t('action_summary.resolution_type_label', {type: resolution_type_label})}
        </li>
        <li>
          {t('action_summary.resolution_summary', {summary: resolution_summary})}
        </li>
      </ul>
      <Typography variant="h4" component="h4">{t('action_summary.sighting')}</Typography>
      <ul>
        <li>
        {wikidata_author_name_id ? (
          <Trans i18nKey="action_summary.author_link" parent="div" author_name={author_name}>Author: 
            <Link to={'/actions/entities/' + wikidata_author_name_id}>{{author_name}}</Link>
          </Trans>
        ): t('action_summary.author_label', {author: author_name})}
        </li>
        <li>
          {t('action_summary.category', {category: category})}
        </li>
        <li>
        {wikidata_publication_id ? (
          <Trans i18nKey="action_summary.publication_link" parent="div" publication={publication}>
            Publication: 
            <Link to={'/actions/entities/' + wikidata_publication_id}>{{publication}}</Link>
          </Trans>
        ): t('action_summary.publication', {publication: publication})}
        </li>
        <li>
          {t('action_summary.publication_date', {date: publication_date.split('T').shift()})}
        </li>
        <li>
          <Trans i18nKey="action_summary.text_link" parent="div" text={text}>
            Text: <a href={url} target="_blank">{{text}}</a>
          </Trans>
        </li>
      </ul>
    </div>
  );
};

ActionSummary.propTypes = {
    action: PropTypes.object.isRequired,
    t: PropTypes.func
};

export default compose(
  withTranslation(),
)(ActionSummary);
