import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import TranscriptCardList from 'Components/Transcript/TranscriptCardList';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EnhancedTableSearch from 'Components/Common/EnhancedTableSearch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export function TranscriptTableList(props){
  const { 
    classes, 
    t,
    code,
    channels,
    transcripts,
    count,
    handleDeleteTranscript,
    handleFormToggle,
  } = props;


  const [typeFilter, setTypeFilter] = React.useState('all');
  const [textFilter, setTextFilter] = React.useState('');

  let filteredTranscripts = transcripts;

  if(textFilter.length) {
    filteredTranscripts = filteredTranscripts.filter(tr => tr.transcriptName.toLowerCase().includes(textFilter.toLowerCase()))
  }
  if(typeFilter !== 'all'){
    filteredTranscripts = filteredTranscripts.filter(tr => tr.transcriptType === typeFilter)
  } 
  filteredTranscripts = filteredTranscripts.sort((a, b) => {
    return new Date( b.createdAt) - new Date(a.createdAt)
});

  const handleFilterTextChange  = (text) => {
    setTextFilter(text)
  }
  return (
      <>
        <Fab color="primary" aria-label="add" size="small" onClick={handleFormToggle} className={classes.addButton}><AddIcon /></Fab>
        <div className={classes.textFilter}>
        <EnhancedTableSearch 
              filterText={textFilter}
              filterTextLabel={t('action_control_panel.filter_by_text')}
              handleFilterTextChange={handleFilterTextChange}
        />
        </div>
          <div className={classes.dropdownFilter}>
            <FormControl fullWidth={true}>
              <Select
                value={typeFilter}
                autoWidth={true}
                className={classes.selectMenu}
                onChange={(e) => { setTypeFilter(e.target.value);}}
                inputProps={{
                  name: 'transcript-type',
                  id: 'ranscript-type-filter',
                }}
              >
                {[
                  { value: 'all', label: props.t("live_page.transcripts.all_transcripts")},
                  { value: 'channel', label: props.t("live_page.transcripts.channel")},
                  { value: 'radio', label: props.t( "live_page.transcripts.radio")},
                  { value: 'block', label: props.t("live_page.transcripts.block")},
                  { value: 'adhoc', label: props.t("live_page.transcripts.adhoc")},
                  { value: 'youtube', label: props.t("live_page.transcripts.youtube")},
                  { value: 'podcast', label: props.t("live_page.transcripts.podcast")},
                ].map(item => (
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        <TranscriptCardList
          transcripts={filteredTranscripts}
          channels={channels}
          limit={count}
          handleDelete={handleDeleteTranscript}
          hasCols={false}
          typeFilter={typeFilter}
          textFilter={textFilter}
        />
      </>
  );
}

TranscriptTableList.propTypes = {
    classes: PropTypes.object,
    t:  PropTypes.func,
    typeTitle: PropTypes.string,
    code: PropTypes.string,
    channels: PropTypes.array,
    transcripts: PropTypes.array,
    count: PropTypes.number,
    handleDeleteTranscript: PropTypes.func,
    handleFormToggle: PropTypes.func,
    showType: PropTypes.bool
};


export default compose(
  withTranslation(),
  withStyles((theme) => ({
    textFilter: {
      display: 'inline-block',
      verticalAlign: 'top',
      marginTop: '-35px',
      marginLeft: '12px'
    },
    dropdownFilter: {
      display: 'inline-block',
      verticalAlign: 'top',
      marginTop: '-14px',
      marginLeft: '18px'
    },
    addScheduleButton: {
        marginLeft: '24px'
      },
      latestTranscripts: {
        marginTop: '24px'
      },
      viewTranscripts: {
        color: theme.palette.primary[500],
        display: 'inline-block',
        marginBottom: '24px',
        fontSize: '16px',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      },
      '@media (min-width: 640px)' : {
        transcriptsRow: {
          padding: '0 12px'
        }
      },
      pageTitle: {
        color: theme.palette.text.primary
      },
      channelList: {
        padding: '40px',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid '+ theme.palette.divider,
        borderWidth: '1px 0',
        textAlign: 'center',
        marginTop: '0',
        marginLeft: '-12px',
        marginRight: '-12px',
        marginBottom: '24px'
      },
      linkRow: {
        marginTop: '-24px'
      },
      radioTranscriptsMovedMessage: {
        padding: '8px'
      },
      typeTitle: {
        marginRight: '12px',
        verticalAlign: 'middle'
      },
      addButton : {
        marginBottom: '24px',
        marginTop: '-18px'
      }
  }))
)(TranscriptTableList);
