import claimConstants from 'Constants/claimConstants';
import candidateConstants from 'Constants/candidateConstants';
import initialState from './initialState';
import { Claim } from 'Models/Claim';
import type { ApiClaimType } from 'Models/Claim';

function normalize(arr, key) {
  let byId = {};
  arr.forEach((obj) => {
    byId[obj[key]] = obj;
  });
  return {
    allIds: arr.map(obj => obj[key]),
    byId: byId
  };
}

export default function claimState(state = initialState.claimState, action) {
  let modifiedClaims, claimsFilters, modifiedMediaHashes;
  switch (action.type) {

    case `${claimConstants.GET_CLAIM}_PENDING`:
      return {
        ...state,
        requestState: 'pending'
      };

    case `${claimConstants.GET_CLAIM}_SUCCESS`: {
      let allClaimIds = [ ...state.claims.allIds ];
      const claim = Claim.fromApi(action.response);

      if(!allClaimIds.includes(claim.trackedClaimId)) allClaimIds.push(claim.trackedClaimId);
      return {
        ...state,
        claims: {
         allIds: allClaimIds,
         byId: {
           ...state.claims.byId,
           [claim.trackedClaimId]: claim
         }
        },
        hasReceivedClaims: true,
        requestState: 'success'
      };
    }
    case `${claimConstants.GET_CLAIMS}_PENDING`:
      return {
        ...state,
        requestState: 'pending'
      };

    case `${claimConstants.GET_CLAIMS}_SUCCESS`:
      action.response.sort(function (a, b) {
        return('' + b.created_at).localeCompare(a.created_at);
      });
      action.response = action.response.map((claim: ApiClaimType) => Claim.fromApi(claim));
      return {
        ...state,
        claims: normalize(action.response, 'trackedClaimId'),
        hasReceivedClaims: true,
        requestState: 'success'
      };


    case `${claimConstants.CREATE_CLAIM}_SUCCESS`:
      return {
        ...state,
        claims: {
          allIds: [
            action.response.tracked_claim_id,
            ...state.claims.allIds,
          ],
          byId: {
            ...state.claims.byId,
            [action.response.tracked_claim_id]: {
              ...Claim.fromApi(action.response),
              externalClaimId: null
            }

          }
        }
      };

    case `${claimConstants.DELETE_CLAIM}_SUCCESS`:
      modifiedClaims = { ...state.claims.byId };
      delete modifiedClaims[action.request.id];
      return {
        ...state,
        claims: {
          allIds: state.claims.allIds.filter(claimId => claimId !== action.request.id),
          byId: modifiedClaims
        }
      };

    case `${claimConstants.UPDATE_CLAIM}_SUCCESS`:
      return { ...state,
        claims: {
          ...state.claims,
          byId: {
            ...state.claims.byId,
            [action.response.tracked_claim_id]: {
              ...Claim.fromApi(action.response),
              externalClaimId: null
            }
          }
        }
      };

    case `${claimConstants.UNSTAR_CLAIM}_SUCCESS`:
      return { ...state,
        claims: {
          ...state.claims,
          byId: {
            ...state.claims.byId,
            [action.request.claimId]: {
              ...state.claims.byId[action.request.claimId],
              starred: false
            }
          }
        }
      };

    case `${claimConstants.STAR_CLAIM}_SUCCESS`:
      return { ...state,
        claims: {
          ...state.claims,
          byId: {
            ...state.claims.byId,
            [action.request.claimId]: {
              ...state.claims.byId[action.request.claimId],
              starred: true
            }
          }
        }
      };

    case claimConstants.SET_CLAIMS_SORT:
      return Object.assign({}, state,
        {
          sortedBy: action.sort
      });

    case claimConstants.SET_CLAIMS_ORDER:
      return Object.assign({}, state,
        {
          orderedBy: action.order
      });

    case claimConstants.SET_CLAIMS_FILTER_TEXT: {
      claimsFilters = Object.assign({}, state.filters);
      claimsFilters.containingText = action.text;
      return Object.assign({}, state,
        {
          filters: claimsFilters
      });
    }

    case claimConstants.SET_CLAIMS_FILTER_DATE_RANGE: {
      claimsFilters = Object.assign({}, state.filters);
      claimsFilters.dateRange = {
        dateFrom: action.dateFrom,
        dateUntil: action.dateUntil
      };
      return Object.assign({}, state,
        {
          filters: claimsFilters
      });
    }

    case claimConstants.SET_CLAIMS_STARRED_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          starred: action.starredFilter || null
        }
      };
    }

    case claimConstants.SET_CLAIMS_PATTERN_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          pattern: action.patternFilter || null
        }
      };
    }

    case claimConstants.START_EDITING_CLAIM:
      return {
         ...state,
         editingClaim: true,
         editingClaimId: action.claimId
      };

    case claimConstants.STOP_EDITING_CLAIM:
      return {
         ...state,
         editingClaim: false,
         editingClaimId: 0
      };

    case claimConstants.CLEAR_CLAIMS:
      return {...initialState.claimState};

    case `${claimConstants.MATCHED_SIGHTINGS_FOR_CLAIM_ID}_SUCCESS`:

      if(action.response.reviewed_sightings.length) {
        let matchedSightings = {
          byClaimId: {},
          allIds: []
        };

        action.response.reviewed_sightings.forEach((sighting) => {
          matchedSightings.allIds.push(sighting.stored_sighting_id);
            if(!matchedSightings.byClaimId[sighting.tracked_claim_id]) {
              matchedSightings.byClaimId[sighting.tracked_claim_id] = {
                byMediaHash : {},
                allMediaHashes : [],
              };
            }
            matchedSightings.byClaimId[sighting.tracked_claim_id].allMediaHashes.push(sighting.media_hash);
            matchedSightings.byClaimId[sighting.tracked_claim_id].byMediaHash[sighting.media_hash] = sighting;
          });
        return {
          ...state,
          reviewedSightings: matchedSightings
        };
      } else {
        return state;
      }

    case `${claimConstants.GET_REVIEWED_SIGHTINGS}_SUCCESS`: {
      let reviewedSightings = {
        byClaimId: {},
        allIds: []
      };

      if(action.response) {
        action.response.forEach((sighting) => {
          reviewedSightings.allIds.push(sighting.stored_sighting_id);
          if(!reviewedSightings.byClaimId[sighting.tracked_claim_id]) {
            reviewedSightings.byClaimId[sighting.tracked_claim_id] = {
              byMediaHash : {},
              allMediaHashes : [],
            };
          }
          reviewedSightings.byClaimId[sighting.tracked_claim_id].allMediaHashes.push(sighting.media_hash);
          reviewedSightings.byClaimId[sighting.tracked_claim_id].byMediaHash[sighting.media_hash] = sighting;
        });
      }
      return {
        ...state,
        reviewedSightings: reviewedSightings
      };
    }

    case `${claimConstants.GET_CONFIRMED_SIGHTINGS}_PENDING`: {
      return {
        ...state,
        confirmedSightings: {
          ...state.confirmedSightings,
          requestState: 'pending'
        }
      };
    }

    case `${claimConstants.GET_CONFIRMED_SIGHTINGS}_FAILURE`: {
      return {
        ...state,
        confirmedSightings: {
          allIds : [], 
          byClaimId : {}, 
          nextCursor: null,  
          requestState: 'failure'
        }
      };
    }

    case `${claimConstants.GET_CONFIRMED_SIGHTINGS}_SUCCESS`: {
      let confirmedSightings = {
        byClaimId: {},
        allIds: [],
        nextCursor: null,
        count: 0
      };

      if(action.response) {
        confirmedSightings.nextCursor = action.response.next_cursor;
        confirmedSightings.count = action.response.count;
        action.response.reviewed_sightings.forEach((sighting) => {
          confirmedSightings.allIds.push(sighting.stored_sighting_id);
          if(!confirmedSightings.byClaimId[sighting.tracked_claim_id]) {
            confirmedSightings.byClaimId[sighting.tracked_claim_id] = {
              byMediaHash : {},
              allMediaHashes : [],
            };
          }
          confirmedSightings.byClaimId[sighting.tracked_claim_id].allMediaHashes.push(sighting.media_hash);
          confirmedSightings.byClaimId[sighting.tracked_claim_id].byMediaHash[sighting.media_hash] = sighting;
        });
      }
      return {
        ...state,
        confirmedSightings: {
          ...confirmedSightings,
          requestState: 'success'
        }
      };
    }

    case `${claimConstants.GET_MORE_CONFIRMED_SIGHTINGS}_PENDING`: {
      return {
        ...state,
        confirmedSightings: {
          ...state.confirmedSightings,
          requestState: 'pending'
        }
      };
    }

    case `${claimConstants.GET_MORE_CONFIRMED_SIGHTINGS}_FAILURE`: {
      return {
        ...state,
        confirmedSightings: {
          ...state.confirmedSightings,
          nextCursor: null,
          requestState: 'failure'
        }
      };

    }

    case `${claimConstants.GET_MORE_CONFIRMED_SIGHTINGS}_SUCCESS`: {
      let confirmedSightings = {
        byClaimId: {...state.confirmedSightings.byClaimId},
        allIds: [...state.confirmedSightings.allIds],
        nextCursor: null,
        count: 0
      };

      if(action.response) {
        confirmedSightings.nextCursor = action.response.next_cursor;
        confirmedSightings.count = action.response.count;
        
        action.response.reviewed_sightings.forEach((sighting) => {
          if (!confirmedSightings.allIds.includes(sighting.stored_sighting_id)) {
            confirmedSightings.allIds.push(sighting.stored_sighting_id);
          }

          if(!confirmedSightings.byClaimId[sighting.tracked_claim_id]) {
            confirmedSightings.byClaimId[sighting.tracked_claim_id] = {
              byMediaHash : {},
              allMediaHashes : [],
            };
          }
          
          if (!confirmedSightings.byClaimId[sighting.tracked_claim_id].allMediaHashes.includes(sighting.media_hash)) {
            confirmedSightings.byClaimId[sighting.tracked_claim_id].allMediaHashes.push(sighting.media_hash);
          }
          
          confirmedSightings.byClaimId[sighting.tracked_claim_id].byMediaHash[sighting.media_hash] = sighting;
        });
      }

      return {
        ...state,
        confirmedSightings: {
          ...confirmedSightings,
          requestState: 'success'
        }
      };
    }


    case `${claimConstants.GET_DISMISSED_SIGHTINGS}_PENDING`: {
      return {
        ...state,
        dismissedSightings: {
          ...state.dismissedSightings,
          requestState: 'pending'
        }
      };
    }

    case `${claimConstants.GET_DISMISSED_SIGHTINGS}_FAILURE`: {
      return {
        ...state,
        dismissedSightings: {
          allIds : [], 
          byClaimId : {}, 
          nextCursor: null,  
          requestState: 'failure'
        }
      };

    }

    case `${claimConstants.GET_DISMISSED_SIGHTINGS}_SUCCESS`: {
      let dismissedSightings = {
        byClaimId: {},
        allIds: [],
        nextCursor: null,
        count: 0
      };

      if(action.response) {
        dismissedSightings.nextCursor = action.response.next_cursor;
        dismissedSightings.count = action.response.count;
        action.response.reviewed_sightings.forEach((sighting) => {
          dismissedSightings.allIds.push(sighting.stored_sighting_id);
          if(!dismissedSightings.byClaimId[sighting.tracked_claim_id]) {
            dismissedSightings.byClaimId[sighting.tracked_claim_id] = {
              byMediaHash : {},
              allMediaHashes : [],
            };
          }
          dismissedSightings.byClaimId[sighting.tracked_claim_id].allMediaHashes.push(sighting.media_hash);
          dismissedSightings.byClaimId[sighting.tracked_claim_id].byMediaHash[sighting.media_hash] = sighting;
        });
      }
      return {
        ...state,
        dismissedSightings: {
          ...dismissedSightings,
          requestState: 'success'
        }
      };
    }

    case `${claimConstants.GET_MORE_DISMISSED_SIGHTINGS}_PENDING`: {
      return {
        ...state,
        dismissedSightings: {
          ...state.dismissedSightings,
          requestState: 'pending'
        }
      };
    }

    case `${claimConstants.GET_MORE_DISMISSED_SIGHTINGS}_FAILURE`: {
      return {
        ...state,
        dismissedSightings: {
          ...state.dismissedSightings,
          nextCursor: null,
          requestState: 'failure'
        }
      };

    }

    case `${claimConstants.GET_MORE_DISMISSED_SIGHTINGS}_SUCCESS`: {
      let dismissedSightings = {
        byClaimId: {...state.dismissedSightings.byClaimId},
        allIds: [...state.dismissedSightings.allIds],
        nextCursor: null,
        count: 0
      };

      if(action.response) {
        dismissedSightings.nextCursor = action.response.next_cursor;
        dismissedSightings.count = action.response.count;
        
        action.response.reviewed_sightings.forEach((sighting) => {
          if (!dismissedSightings.allIds.includes(sighting.stored_sighting_id)) {
            dismissedSightings.allIds.push(sighting.stored_sighting_id);
          }

          if(!dismissedSightings.byClaimId[sighting.tracked_claim_id]) {
            dismissedSightings.byClaimId[sighting.tracked_claim_id] = {
              byMediaHash : {},
              allMediaHashes : [],
            };
          }
          
          if (!dismissedSightings.byClaimId[sighting.tracked_claim_id].allMediaHashes.includes(sighting.media_hash)) {
            dismissedSightings.byClaimId[sighting.tracked_claim_id].allMediaHashes.push(sighting.media_hash);
          }
          
          dismissedSightings.byClaimId[sighting.tracked_claim_id].byMediaHash[sighting.media_hash] = sighting;
        });
      }

      return {
        ...state,
        dismissedSightings: {
          ...dismissedSightings,
          requestState: 'success'
        }
      };
    }

    case `${candidateConstants.CANDIDATES_GET_DIGEST}_SUCCESS`: {

      let reviewedSightings = {
        byClaimId: {},
        allIds: []
      };

      if(action.response) {
        action.response.reviewed_sightings.forEach((sighting) => {
          reviewedSightings.allIds.push(sighting.stored_sighting_id);
          if(!reviewedSightings.byClaimId[sighting.tracked_claim_id]) {
            reviewedSightings.byClaimId[sighting.tracked_claim_id] = {
              byMediaHash : {},
              allMediaHashes : [],
            };
          }
          reviewedSightings.byClaimId[sighting.tracked_claim_id].allMediaHashes.push(sighting.media_hash);
          reviewedSightings.byClaimId[sighting.tracked_claim_id].byMediaHash[sighting.media_hash] = sighting;
        });
      }
      return {
        ...state,
        reviewedSightings: reviewedSightings
      };
    }

    case `${claimConstants.ADD_MATCHED_SIGHTING}_SUCCESS`:
      let reviewedSightingsForClaim = state.reviewedSightings.byClaimId[action.request.claimId] || {
        byMediaHash: {},
        allMediaHashes: []
      };

      return {
          ...state,
          reviewedSightings: {
            byClaimId: {
              ...state.reviewedSightings.byClaimId,
              [action.request.claimId] : {
                ...reviewedSightingsForClaim,
                byMediaHash: {
                  ...reviewedSightingsForClaim.byMediaHash,
                  [action.request.mediaHash]: action.response
                },
                allMediaHashes: [
                  ...reviewedSightingsForClaim.allMediaHashes,
                  action.request.mediaHash
                ]
              }
            }
          }
      };

    case `${claimConstants.REMOVE_MATCHED_SIGHTING}_SUCCESS`:

      modifiedMediaHashes = { ...state.reviewedSightings.byClaimId[action.request.claimId].byMediaHash };
      delete modifiedMediaHashes[action.request.mediaHash];

      return {
        ...state,
        reviewedSightings: {
          byClaimId: {
            ...state.reviewedSightings.byClaimId,
            [action.request.claimId]: {
              allMediaHashes: state.reviewedSightings.byClaimId[action.request.claimId].allMediaHashes.filter(hash => hash !== action.request.mediaHash),
              byMediaHash: modifiedMediaHashes
            }
          }
        }
      };

      case `${claimConstants.REMOVE_DISMISSED_SIGHTING}_SUCCESS`:

      modifiedMediaHashes = { ...state.dismissedSightings.byClaimId[action.request.claimId].byMediaHash };
      delete modifiedMediaHashes[action.request.mediaHash];

      return {
        ...state,
        dismissedSightings: {
          byClaimId: {
            ...state.dismissedSightings.byClaimId,
            [action.request.claimId]: {
              allMediaHashes: state.dismissedSightings.byClaimId[action.request.claimId].allMediaHashes.filter(hash => hash !== action.request.mediaHash),
              byMediaHash: modifiedMediaHashes
            }
          }
        }
      };

    case `${claimConstants.DISMISS_SIGHTING}_SUCCESS`: {
      let claimMediaHashes = {
        byMediaHash: {},
        allMediaHashes: []
      };
      if(state.reviewedSightings.byClaimId[action.request.claimId]) {
        claimMediaHashes = {
          byMediaHash: { ...state.reviewedSightings.byClaimId[action.request.claimId].byMediaHash },
          allMediaHashes: state.reviewedSightings.byClaimId[action.request.claimId].allMediaHashes
        };
      }

      return {
        ...state,
        reviewedSightings: {
          byClaimId: {
            ...state.reviewedSightings.byClaimId,
            [action.request.claimId]: {
              allMediaHashes: claimMediaHashes.allMediaHashes,
              byMediaHash: {
                ...claimMediaHashes.byMediaHash,
                [action.request.mediaHash]: {
                  ...claimMediaHashes.byMediaHash[action.request.mediaHash],
                  is_matched: false
                }
              }
            }
          }
        }
      };
    }

    default:
      return state;
  }
}
