import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export class NavAvatarMenu extends React.PureComponent {


  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null
    };
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  }

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  }

  handleGoUser = () => {
    this.props.history?.push('/user/');
  }

  handleGoOrg = () => {
    this.props.history?.push('/media/');
  }

  handleGoAdmin = () => {
    this.props.history?.push('/admin/');
  }

  handleGoClaims = () => {
    this.props.history?.push('/alerts/claims/');
  }
  handleLogout = () => {
    this.props.handleLogout();
  }
  handleGoSupport = () => {
    this.props.history?.push('/docs/');
  }

  render(){
    const {
      userName,
      orgDisplayName,
      orgAvatarImg,
      t,
      classes,
      isAdmin,
      additionalLogos
    } = this.props;
    const { anchorEl } = this.state;
    let avatar = orgAvatarImg;
    return (
      <div className={classes.avatarFlex}>
        {additionalLogos?.map(name => (
            <div className={classes.avatarContainer}>
            <Tooltip title={t('nav_avatar_menu.logo_title.' + name)} enterDelay={600}>
              <img src={'/assets/images/avatars/additional/' + name + '.png'} className={classes.orgAvatar} />
              </Tooltip>
            </div>
        ))}
          <div className={classes.avatarContainer}>
          <IconButton
            aria-controls="avatar-menu"
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <Tooltip title={orgDisplayName} enterDelay={600}>
              <Avatar src={avatar} />
            </Tooltip>
          </IconButton>
          <Menu
            id="avatar-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem
              value="/user"
              onClick={this.handleGoUser}
              classes={{ root: classes.menuItem }}
            >
              <Trans i18nKey="nav_avatar_menu.logged_in_as" parent="div">Logged in as: {userName}</Trans>
            </MenuItem>
            <Divider />
            <MenuItem
              value="/alerts/claims"
              icon={<img className={classes.smallAvatar} src={avatar} />}
              onClick={this.handleGoClaims}
              classes={{ root: classes.menuItem }}
            >{t('nav_avatar_menu.manage_claims')}</MenuItem>
            {isAdmin ? (
              <MenuItem
              value="/media"
              icon={<img className={classes.smallAvatar} src={avatar} />}
              onClick={this.handleGoOrg}
              classes={{ root: classes.menuItem }}
            >{t('nav_avatar_menu.manage_publications')}</MenuItem>
            ) : (
              <MenuItem
              value="/media"
              icon={<img className={classes.smallAvatar} src={avatar} />}
              onClick={this.handleGoOrg}
              classes={{ root: classes.menuItem }}
            >{t('nav_avatar_menu.view_publications')}</MenuItem>
            )}
            {isAdmin ? (
              <MenuItem
                value="/admin"
                caption={t('nav_avatar_menu.manage_users')}
                onClick={this.handleGoAdmin}
                classes={{ root: classes.menuItem }}
              >{t('nav_avatar_menu.manage_users')}</MenuItem>
            ) : ''}
            {isAdmin ? <Divider /> : ''}
            <MenuItem
              value="signout"
              icon="exit_to_app"
              onClick={this.handleGoSupport}
              classes={{ root: classes.menuItem }}>{t('nav_avatar_menu.support')}</MenuItem>
            <MenuItem
              value="signout"
              icon="exit_to_app"
              onClick={this.handleLogout}
              classes={{ root: classes.menuItem }}>{t('nav_avatar_menu.logout')}</MenuItem>
          </Menu>
          </div>
          </div>
    );
  }
}

NavAvatarMenu.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  userName: PropTypes.string,
  orgDisplayName: PropTypes.string,
  orgAvatarImg: PropTypes.string,
  handleLogout: PropTypes.func,
  additionalLogos: PropTypes.array,
  t: PropTypes.func,
  classes: PropTypes.object,
  isAdmin: PropTypes.bool
};


export default compose(
  withStyles(() => ({
    smallAvatar:  {
      border: 'solid 1px #000',
      backgroundColor: '#000',
      width: '26px',
      height: '26px',
      borderRadius: '100px'
    },
    menuItem: {
      padding: '12px 16px'
    },
    avatarFlex: {
      display: 'flex'
    },
   orgAvatar: {
      marginTop: '8px',
      marginRight: '8px',
      width: '35px !important',
      height: '50px !important'
    },
    avatarContainer: {
      textAlign: 'center'
    }
  })),
  withTranslation()
)(NavAvatarMenu);

