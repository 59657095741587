import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';

export function Notice(props) {
  const {
    onRemove,
    t,
    classes,
    messageKey,
    messageElement
  } = props;

  let message = '';
  if (props.message && props.message.length) {
    message = props.message;
  } else if (messageKey && messageKey.length) {
    message = t(messageKey);
  } else if (messageElement) {
    message = messageElement
  }
  const type = props.type || 'default';
  const position = props.position || 'default';

  const typeStyles = {
    'error': 'typeError',
    'success': 'typeSuccess',
    'default': 'typeError',
    'info': 'typeInfo'
  };

  const positionStyles = {
    'bottom': 'positionBottom',
    'default': ''
  };

  return (
      <div className={classes.notice + ' ' + classes[typeStyles[type]] + ' ' + classes[positionStyles[position]]}>
        <span className={classes.message}>{message}</span>
        {onRemove ? (
          <span className={classes.closeLink}>
            <span onClick={onRemove} ><CloseIcon /></span>
          </span>
        ) : ""}
      </div>
  );
}

Notice.propTypes = {
  message: PropTypes.string,
  messageKey: PropTypes.string,
  messageElement: PropTypes.string,
  type: PropTypes.string,
  position: PropTypes.string,
  onRemove: PropTypes.func,
  t: PropTypes.func,
  classes: PropTypes.object
};

export default compose(
  withStyles((theme) => ({
    notice: {
      padding: '16px 12px',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '1.2em',
      color: '#333',
      '& a': {
        color: '#0A7B70',
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    },
    closeLink: {
      verticalAlign: 'top',
      float: 'right',
      marginTop: '-3px',
      cursor: 'pointer'
    },
    message: {
      verticalAlign: 'top'
    },
    typeError: {
      backgroundColor: '#ffcdd2'
    },
    typeSuccess: {
      backgroundColor: '#DAF0ED'
    },
    typeInfo: {
      backgroundColor: theme.palette.primary[50]
    },
    positionBottom: {
      position: 'fixed',
      bottom: '0',
      width: '100%',
      marginLeft: '-6px',
      marginRight: '-6px'
    }
  })),
  withTranslation()
)(Notice);
