import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'redux';
import noticeActions from 'Actions/noticeActions';
import transcriptActions from 'Actions/transcriptActions';
import transcriptSelectors from 'Selectors/transcriptSelectors';
import authSelectors from 'Selectors/authSelectors';
import { withTranslation } from 'react-i18next';
import TranscriptCardList from 'Components/Transcript/TranscriptCardList';
import TranscriptScheduleDetails from 'Components/Transcript/TranscriptScheduleDetails';

class SchedulePage extends React.Component {

  constructor(props){
    super(props);
    props.transcriptActions.getSchedule(props.match.params['scheduleId']);
    props.transcriptActions.getScheduleTranscripts(props.match.params['scheduleId']);
  }

  render() {
    const {
      scheduleDetails,
      scheduleTranscripts,
      classes,
      t
    } = this.props;
    
    return (
      <section>
      <div className={" row"}>
        {scheduleDetails && (
          <TranscriptScheduleDetails details={scheduleDetails} />  
        )}
      </div>
      <div className="row">
        <div className="col-xs4-4 col-md-12">
          <TranscriptCardList
            transcripts={scheduleTranscripts}
            handleDelete={this.handleDelete}
          />
        </div>
      </div>
  </section>
    );
  }
}

SchedulePage.propTypes = {
  transcriptActions: PropTypes.object.isRequired,
  noticeActions: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  transcript: PropTypes.object,
  permissions: PropTypes.array,
  transcriptSentences: PropTypes.array,
  scheduleDetails: PropTypes.object,
  scheduleTranscripts: PropTypes.array,
  classes: PropTypes.object,
  t: PropTypes.func
};

function mapStateToProps(state, ownProps) {
  return {
    permissions: authSelectors.getPermissions(state),
    scheduleDetails: transcriptSelectors.getScheduleDetails(state, ownProps.match.params['scheduleId']),
    scheduleTranscripts: transcriptSelectors.getTranscriptsByType(state, 'radio')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transcriptActions: bindActionCreators(transcriptActions, dispatch),
    noticeActions: bindActionCreators(noticeActions, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(SchedulePage);

