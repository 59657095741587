import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

export function TranscriptScheduleDays(props){
  const { 
    classes, 
    t,
    days
  } = props;

  const dayDisplayMap = {
    1: {
      displayName: t('transcript_schedule_days.day_of_the_week.monday')
    },
    2: {
      displayName: t('transcript_schedule_days.day_of_the_week.tuesday')
    },
    3: {
      displayName: t('transcript_schedule_days.day_of_the_week.wednesday')
    },
    4: {
      displayName: t('transcript_schedule_days.day_of_the_week.thursday')
    },
    5: {
      displayName: t('transcript_schedule_days.day_of_the_week.friday')
    },
    6: {
      displayName: t('transcript_schedule_days.day_of_the_week.saturday')
    },
    7: {
      displayName: t('transcript_schedule_days.day_of_the_week.sunday')
    }
  };

  if(days.sort().join('') === "12345") return <span className={classes.dayValue}>{t('transcript_schedule_days.period.weekdays')}</span>;
  if(days.sort().join('') === "67") return <span className={classes.dayValue}>{t('transcript_schedule_days.period.weekends')}</span>;
  if(days.sort().join('') === "1234567") return <span className={classes.dayValue}>{t('transcript_schedule_days.period.everyday')}</span>;
  return (days.map(day => (<span key={day} className={classes.dayValue}>{dayDisplayMap[day].displayName}</span>)));
}

TranscriptScheduleDays.propTypes = {
  days: PropTypes.array,
  classes: PropTypes.object,
  t:  PropTypes.func,
};


export default compose(
  withTranslation(),
  withStyles(() => ({
    dayValue: {
      backgroundColor: '#eee',
      padding: '3px 8px',
      marginBottom: '3px',
      borderRadius: '20px',
      display: 'inline-block',
      fontSize: '13px'
    }
  }))
)(TranscriptScheduleDays);
