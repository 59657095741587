import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CloseIcon  from '@material-ui/icons/Close';
import moment from 'moment';
import { compose } from 'redux';
import { withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Tooltip from '@material-ui/core/Tooltip';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Chip from '@material-ui/core/Chip';

function renderSubtitle(props) {
  const {
    classes, 
    t
  } = props;

  if(!props.createdAt) return '';
  if(props.isOpen){
    return(
      <span className={classes.liveTranscript}>
        <RadioButtonCheckedIcon className={classes.transcriptLiveIcon} /> 
        <span> {t('transcript_card.currently_live')}</span>
      </span>
    );
  }

  if(!props.startTime) {
    return moment.utc(props.createdAt).local().format('DD/MM/YY HH:mm');
  }

  let startTime = moment.utc(props.startTime);
  if(!props.endTime) {
    return startTime.local().format('DD/MM/YY HH:mm');
  }

  let endDateFormat = 'DD/MM/YY HH:mm';
  let endTime = moment.utc(props.endTime);
  if(startTime.format('DD/MM/YY') === endTime.format('DD/MM/YY')) {
    endDateFormat= 'HH:mm';
  }
  
  return (
    <Trans i18nKey="transcript_card.date_range" parent="span">
      {startTime.local().format('DD/MM/YY HH:mm')} to {endTime.local().format(endDateFormat)}
    </Trans>
  )
}

export function TranscriptCard(props){
  const { 
    classes, 
    t,
    statistics,
    singleLine,
    canDelete,
    type,
    title,
    handleDelete,
    id,
    createdBy
  } = props;

  const subtitle = renderSubtitle(props); 

  const titleUrls = {
    channel: '/channel-transcript/',
    block: '/block-transcript/',
    radio: '/radio-transcript/',
    adhoc: '/ondemand-transcript/',
    youtube: '/youtube-transcript/',
    podcast: '/podcast-transcript/'
  }

  let cardClasses = classes.card
  if(singleLine) {
    cardClasses += " "+classes.cardSingleLine
  } else {
    cardClasses += " "+classes.cardMultiLine
  }

  return (
    <Card className={cardClasses+' transcript-card-type-'+type}>
      <CardContent className={singleLine && classes.singleLineContent}>
      { canDelete && <a className={classes.close+' transcript-card-delete'} onClick={() => { handleDelete(props.id, type); }}><CloseIcon /></a>}
      <div className={singleLine &&  classes.cardRow}>
      <Typography variant="h5" component="h2" className={singleLine && classes.singleLineHeader}>
        <Link key={id} className={classes.titleLink} to={titleUrls[type]+id}>{title}</Link>
      </Typography>
      <div className={singleLine && classes.singleLineMetadata}>
        {singleLine && (
          <div className={classes.chipContainer}>
            <Chip size="small" label={type} className={classes.typeChip} />
          </div>
        )}
        <div className={classes.createdIcon}>
          {!createdBy ? (
            <Tooltip title={t('transcript_card.created_from_media')}>
              <ScheduleIcon />
            </Tooltip>
          ): (
            <Tooltip title={t('transcript_card.created_by_x', { creator: createdBy})}>
              <AccountCircleIcon />
            </Tooltip>
          )}
        </div>
        <Typography className={classes.datetimeMetadata} variant="subtitle1" color="textSecondary">
        {subtitle}
        </Typography>
        {statistics && (<div className={classes.statsSummary}>
          {statistics.matched_claim_count > 0 && (<Tooltip title={t('transcript_card.match_known_claims')}><span className={classes.statsCount}><DoneAllIcon /> <span>{statistics.matched_claim_count}</span></span></Tooltip>)}
        </div> )}
        </div>
      </div>
      </CardContent>
    </Card>
  );
}

TranscriptCard.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number.isRequired,
  createdBy: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  handleDelete: PropTypes.func,
  isOpen: PropTypes.bool,
  canDelete: PropTypes.bool,
  classes: PropTypes.object,
  t:  PropTypes.func,
  type: PropTypes.string,
  statistics: PropTypes.object,
  singleLine:  PropTypes.bool
};


export default compose(
  withTranslation(),
  withStyles((theme) => ({
    typeChip: {
      marginRight: '12px',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    chipContainer: {
      minWidth: '80px',
      display: 'inline-block'
    },
    statsSummary: {
      display: 'inline-block',
      marginLeft: '12px',
      verticalAlign: 'top',
      minWidth: '100px'
    },
    datetimeMetadata: {
      display: 'inline-block',
      verticalAlign: 'top',
      marginLeft: '12px',
      lineHeight: '1.5',
      minWidth: '100px'
    },
    createdIcon: {
      display: 'inline-block',
      color: '#999',
      verticalAlign: 'middle'
    },
    statsCount: {
      '& svg': {
        verticalAlign: 'middle'
      },
      marginRight: '3px'

    },
    titleLink: {
      color: theme.palette.primary[500],
      textDecoration: 'none',
      fontStyle: 'normal',
      marginRight: '14px',
      display: 'inline-block'
    },
    transcriptLiveIcon: {
      verticalAlign: 'middle'
    },
    text: {
      color: '#4A4A4A',
      marginBottom: '12px'
    },
    close: {
      position: 'absolute',
      top: '16px',
      right: '2px',
      width: '30px',
      color: '#bbb',
      cursor: 'pointer',
      '&:hover': {
        color: '#000'
      }
    },
    card: {
      marginBottom: '6px',
      position: 'relative',
    },
    singleLineContent: {
      padding: '16px',
      '&:last-child': {
        padding: '16px',
      }
    },
    cardRow: {
      display: 'block'
    },
    '@media (min-width: 960px)' : {
      cardRow: {
        display: 'flex',
        '& h2': {
          display: 'inline-block',
          flexGrow: '1'
        }
      }
    },
    singleLineHeader: {
      marginBottom: '0'
    },
    liveTranscript: {
      '& svg': {
        color: '#D93025',
        verticalAlign: 'top',
        fontSize: '20px',
        marginTop: '2px'
      },
      '& span': {
        fontStyle: 'italic',
        verticalAlign: 'top'
      }
    },
    cardSingleLine: {
      marginBottom: '3px',
      width: '100%',
      background: 'none',
      borderBottom: '1px solid #888',
      borderRadius: '0',
      boxShadow: 'none'
    },
    cardMultiLine: {
      marginBottom: '24px'
    },
    singleLineMetadata: {
      minWidth: '300px',
      marginRight: '100px'
    }
  }))
)(TranscriptCard);
