import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import Typography from '@material-ui/core/Typography';
import adminActions from 'Actions/adminActions';
import transcriptActions from 'Actions/transcriptActions';
import noticeActions from 'Actions/noticeActions';
import adminSelectors from 'Selectors/adminSelectors';
import authSelectors from 'Selectors/authSelectors';
import transcriptSelectors from 'Selectors/transcriptSelectors';
import noticeSelectors from 'Selectors/noticeSelectors';
import OrganisationMediaForm from './OrganisationMediaForm';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddEditDrawer from 'Components/Common/AddEditDrawer';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import TranscriptScheduleForm from 'Components/Transcript/TranscriptScheduleForm';
import MediaPublicationsTable from 'Components/Media/MediaPublicationsTable';
import MediaSchedulesTable from 'Components/Media/MediaSchedulesTable';

export class OrganisationMedia extends React.Component {

  constructor(props) {
      super(props);
      props.adminActions.getOrganisationMedia();
      props.transcriptActions.getSchedules();
      props.transcriptActions.getRadioStations();

      let tabIndex = 0
      if(props.hasSchedulesPermission && props.sectionName === 'schedules') {
        tabIndex = 1;
      }

      this.state =  {
        selectedMedia: null,
        selectedSchedule: null,
        shouldShowDeleteMediaDialog: false,
        shouldShowDeleteScheduleDialog: false,
        tabIndex: tabIndex,
      };

  }

  setTabIndex = (event, newIndex) => {
    if(newIndex == 1) {
      this.props.history.push('/media/schedules');
    } else {
      this.props.history.push('/media/');
    }
    this.setState({
      tabIndex: newIndex
    });
  }

  toggleEditSchedule = () => {
    if(this.props.isEditingSchedule){
      this.props.noticeActions.removeNotice('form_notice');
      this.props.transcriptActions.stopEditingSchedule();
    } else {
      this.props.transcriptActions.startEditingSchedule();
    }
  };

  toggleEditMedia = () => {
    if(this.props.isEditingMedia){
      this.props.noticeActions.removeNotice('form_notice');
      this.props.adminActions.stopEditingMedia();
    } else {
      this.props.adminActions.startEditingMedia();
    }
  }

  addSchedule = () => {
    this.setState({
      selectedSchedule: null
    },() => {
      this.toggleEditSchedule();
    });
  }

  addMedia = () => {
    this.setState({
      selectedMedia: null
    },() => {
      this.toggleEditMedia();
    });
  }

  toggleDeleteScheduleDialog = () => {
    this.setState({
      shouldShowDeleteScheduleDialog: !this.state.shouldShowDeleteScheduleDialog
    });
  }

  toggleDeleteMediaDialog = () => {
    this.setState({
      shouldShowDeleteMediaDialog: !this.state.shouldShowDeleteMediaDialog
    });
  }

  mediaSubmit = (media) => {
    if(media.publicationId) {
      this.props.adminActions.updateOrganisationMedia(media);
    } else {
      this.props.adminActions.createOrganisationMedia(media);
    }
  }

  scheduleSubmit = (schedule) => {
    if(schedule.uuid) {
      this.props.transcriptActions.updateSchedule(schedule);
    } else {
      this.props.transcriptActions.createSchedule(schedule);
    }
  }

  showDeleteMediaDialog = (media) => {
    this.setState({
      selectedMedia: media
    },() => {
      this.toggleDeleteMediaDialog();
    });
  }

  showDeleteScheduleDialog = (schedule) => {
    this.setState({
      selectedSchedule: schedule
    },() => {
      this.toggleDeleteScheduleDialog();
    });
  }

  deleteMedia = () => {
    this.props.adminActions.deleteOrganisationMedia(this.state.selectedMedia.publicationId);
    this.toggleDeleteMediaDialog();
  }

  deleteSchedule = () => {
    this.props.transcriptActions.deleteSchedule(this.state.selectedSchedule.uuid);
    this.toggleDeleteScheduleDialog();
  }

  editMedia = (media) => {
    this.setState({
      selectedMedia: {
        ...media
      }
    },() => {
      this.toggleEditMedia();
    });
  }

  formFieldChange = () => {
    this.props.noticeActions.removeNotice('form_notice');
  }
  editSchedule = (schedule) => {
    this.setState({
      selectedSchedule: {
        "display_name": schedule.display_name,
        "publication_id": schedule.publication_id,
        "timezone": schedule.timezone,
        "days": schedule.days,
        "start_time": schedule.start_time,
        "end_time": schedule.end_time,
        "uuid": schedule.uuid
      }
    },() => {
      this.toggleEditSchedule();
    });
  }

  render() {
    const { 
      organisationMedia,
      t, 
      isEditingMedia,
      isCreatingMedia,
      isUpdatingMedia,
      isEditingSchedule,
      isCreatingSchedule,
      isUpdatingSchedule,
      classes,
      canEdit,
      schedules,
      radioStations,
      formErrorNotice,
      hasSchedulesPermission
    } = this.props;

    const {
      selectedMedia,
      selectedSchedule,
      shouldShowDeleteScheduleDialog,
      shouldShowDeleteMediaDialog,
      tabIndex
    } = this.state;

    return (
        <div className={classes.publicationMediaContainer}>
          <Typography variant="h2" gutterBottom>{t('organisation_media.publication_media')}</Typography>
          <Tabs 
            indicatorColor="primary"
            value={this.state.tabIndex} 
            onChange={this.setTabIndex}>
            <Tab label={t('organisation_media.media_feeds')} />
            {hasSchedulesPermission && <Tab label={t('organisation_media.schedules')} />}
          </Tabs>
          <Paper className={classes.mediaTabs}>
          {tabIndex === 0 && (
            <MediaPublicationsTable
              addMedia={this.addMedia}
              canEdit={canEdit}
              organisationMedia={organisationMedia}
              showDeleteMediaDialog={this.showDeleteMediaDialog}
              editMedia={this.editMedia}
            />
          )}
          {tabIndex === 1 && (
            <MediaSchedulesTable
              addSchedule={this.addSchedule}
              schedules={schedules}
              editSchedule={this.editSchedule}
              canEdit={canEdit}
              showDeleteScheduleDialog={this.showDeleteScheduleDialog}
            />
          )}
          </Paper>
      <Dialog
        open={shouldShowDeleteMediaDialog}
        onClose={this.toggleDeleteMediaDialog}
      >
        <DialogTitle id="delete-dialog-title">{t('organisation_media.delete_feed_dialog', {name: (selectedMedia && selectedMedia.publicationName) })}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
              {t('organisation_media.delete_confirm')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.toggleDeleteMediaDialog} color="secondary" variant="contained">
            {t('organisation_media.cancel_button')}
          </Button>
          <Button className="delete-media-dialog-button" onClick={this.deleteMedia} color="primary" variant="contained" autoFocus>
            {t('organisation_media.delete_media_button')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={shouldShowDeleteScheduleDialog}
        onClose={this.toggleDeleteScheduleDialog}
      >
        <DialogTitle id="delete-dialog-title">{t('organisation_media.delete_schedule_dialog', {name: selectedSchedule && selectedSchedule.display_name})}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {t('organisation_media.delete_schedule_confirm')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.toggleDeleteScheduleDialog} color="secondary" variant="contained">
            {t('organisation_media.cancel_button')}
          </Button>
          <Button className="deletes-schedule-dialog-button" onClick={this.deleteSchedule} color="primary" variant="contained" autoFocus>
            {t('organisation_media.delete_media_button')}
          </Button>
        </DialogActions>
      </Dialog>
      <AddEditDrawer
          isActive={isEditingMedia}
          handleToggle={this.toggleEditMedia}
          title={selectedMedia ? t('organisation_media.edit_media') : t('organisation_media.add_media')}
        >
          <section>
            <OrganisationMediaForm
              publication={selectedMedia}
              onSubmit={this.mediaSubmit}
              errorNotice={formErrorNotice}
              processingSubmit={isCreatingMedia || isUpdatingMedia}
              formFieldChange={this.formFieldChange}
            />
          </section>
        </AddEditDrawer>
        <AddEditDrawer
          handleToggle={this.toggleEditSchedule}
          isActive={isEditingSchedule}
          title={selectedSchedule ? t('organisation_media.edit_schedule') : t('organisation_media.create_radio_schedule')}
        >
          <section>
            <TranscriptScheduleForm 
              handleSubmit={this.scheduleSubmit}
              radioStations={radioStations}
              schedule={selectedSchedule}
              errorNotice={formErrorNotice}
              processingSubmit={isCreatingSchedule || isUpdatingSchedule}
              formFieldChange={this.formFieldChange}
            />
          </section>
        </AddEditDrawer>
      </div>
    );
  }
}

OrganisationMedia.propTypes = {
    orgDisplayName: PropTypes.string,
    transcriptsCount: PropTypes.number,
    claimsCount: PropTypes.number,
    patternPercentage: PropTypes.number,
    claimActions: PropTypes.object,
    transcriptActions: PropTypes.object,
    publications: PropTypes.array,
    adminActions: PropTypes.object,
    isEditingMedia: PropTypes.bool,
    isCreatingMedia: PropTypes.bool,
    isUpdatingMedia: PropTypes.bool,
    i18n: PropTypes.object,
    classes: PropTypes.object,
    mediaNotice: PropTypes.object,
    t: PropTypes.func,
    organisationMedia: PropTypes.array,
    canEdit: PropTypes.bool,
    schedules: PropTypes.array,
    isEditingSchedule: PropTypes.bool,
    radioStations: PropTypes.array,
    isCreatingSchedule: PropTypes.bool,
    isUpdatingSchedule: PropTypes.bool,
    sectionName: PropTypes.string,
    history: PropTypes.func,
    formErrorNotice: PropTypes.object,
    noticeActions: PropTypes.object,
    hasSchedulesPermission: PropTypes.bool
};


function mapStateToProps(state) {
  return {
    organisationMedia: adminSelectors.getOrganisationMedia(state),
    isEditingMedia: adminSelectors.getIsEditingMedia(state),
    isCreatingMedia: adminSelectors.getIsCreatingMedia(state),
    isUpdatingMedia: adminSelectors.getIsUpdatingMedia(state),
    isEditingSchedule: transcriptSelectors.getIsEditingSchedule(state),
    isCreatingSchedule: transcriptSelectors.getIsCreatingSchedule(state),
    isUpdatingSchedule: transcriptSelectors.getIsUpdatingSchedule(state),
    canEdit: authSelectors.getIsAdmin(state),
    schedules: transcriptSelectors.getSchedules(state),
    radioStations: transcriptSelectors.getRadioStations(state),
    formErrorNotice:  noticeSelectors.getNoticeById(state, 'form_notice'),
    hasSchedulesPermission: adminSelectors.getHasSchedulesPermission(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
     adminActions: bindActionCreators(adminActions, dispatch),
     transcriptActions: bindActionCreators(transcriptActions, dispatch),
     noticeActions: bindActionCreators(noticeActions, dispatch),
  };
}

export default compose(
  withStyles((theme) => ({
    statsCount: {
      paddingLeft: '6px',
      fontSize: '14px',
      '& svg': {
        verticalAlign: 'middle'
      }
    },
    publicationMediaContainer: {
      marginTop: '24px'
    },
    addMediaButton: {
      marginBottom: '12px',
      paddingTop: '16px',
      marginLeft: '16px',
      display: 'block'
    },
    addUserButton: {
      fontSize: '18px',
      '& button': {
        marginRight: '12px'
      },
      marginBottom: '24px',
      marginTop: '12px'
    },
    changePasswordToggle: {
      display: 'block',
      marginTop: '24px',
      color: '#009688',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    mediaTabs: {
      overflow: 'auto'
    },
    viewAllTranscriptsLink: {
      color: theme.palette.primary[500],
      margin: '0 0 16px 16px',
      fontSize: '14px',
      display: 'inline-block'
    }
  })),
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(OrganisationMedia);