import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

function CreateButton(props) {

  const {
    classes,
    onClick,
  } = props;

  
  return (
    <div className={classes.createButton}>
        <Fab color="primary" aria-label="add" size="small" onClick={onClick}><AddIcon /></Fab>
    </div>
  );
}

export default compose(
  withStyles((theme) => ({
    createButton: {
        verticalAlign: 'bottom',
        marginTop: '12px',
        marginRight: '12px',
        display: 'inline-block'
    }
  }))
)(CreateButton);

CreateButton.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func
};
