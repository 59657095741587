import React from 'react';
import ActionTableFilterForm from './ActionTableFilterForm';
import Popover from '@material-ui/core/Popover';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

export const singleFieldComparitors = {
  'is any of': (fieldValue, filterValues) => {
    return filterValues.includes(fieldValue);
  },
};

export const textFieldComparitors = {
  'is': (fieldValue, filterValues) => {
    return filterValues.includes(fieldValue+'');
  },
};

export const multipleFieldComparitors = {
  'has any of': (fieldValues, filterValues) => {
    let hasValue = false;
    fieldValues.forEach(fieldValue => {
      if(filterValues.includes(fieldValue)){
        hasValue = true;
        return;
      }
    });
    return hasValue;
  },
};

export function addComparisonsToFilter(filter,t) {
  if(filter.type === "multiple") {
    filter.comparison = {
      key: "has any of",
      value: t('action_table_filters.comparison.has_any_of')
    };
    filter.compareFunc = multipleFieldComparitors["has any of"];
  }
  if(filter.type === "single"){
      filter.comparison = {
        key: "is any of",
        value: t('action_table_filters.comparison.is_any_of')
      };
      filter.compareFunc = singleFieldComparitors["is any of"];
  }
  if(filter.type === "text"){
    filter.comparison = {
      key: "is",
      value: t('action_table_filters.comparison.equals')
    };
    filter.compareFunc = textFieldComparitors["is"];
  }
  return filter;
}

function ActionTableFilters(props) {
  const {
    classes,
    initialFilters,
    filterableFields,
    applyFilter,
    t
   } = props;

  const [filters, setFilters] = React.useState(initialFilters);
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  function handleFilterChange(index, filterObj) {
    const { t } = props;
    let selectedField = filterableFields.filter(field => filterObj.field === field.name)[0];
    filterObj.type = selectedField.type;
    filterObj = addComparisonsToFilter(filterObj, t);
    let updatedFilters = filters.slice();
    updatedFilters[index] = filterObj;
    setFilters(updatedFilters);
    applyFilter(updatedFilters);
  }

  function handleFilterDelete(index) {
    let updatedFilters = filters.slice();
    updatedFilters.splice(index, 1);
    setFilters(updatedFilters);
    applyFilter(updatedFilters);
  }

  function createNewFilter() {
    let updatedFilters = filters.slice();
    updatedFilters.push({
      'field' : '',
      'values': []
    });
    setFilters(updatedFilters);
  }


  return (
    <div>
    <div onClick={handleClick} className={classes.addNewFilter}>
    <span className={classes.filterCount}>
      {filters && filters.length ? t('action_table_filters.filters_count', {count: filters.length}) : t('action_table_filters.filters_title') }
      </span>
      <div className={classes.FilterIconToggle}>
        <div aria-describedby={id} className={classes.FilterListIcon}>
          <FilterListIcon/>
        </div>
      </div>
    </div>
   <Popover
     id={id}
     open={open}
     anchorEl={anchorEl}
     onClose={handleClose}
     anchorOrigin={{
       vertical: 'bottom',
       horizontal: 'right',
     }}
     transformOrigin={{
       vertical: 'top',
       horizontal: 'right',
     }}
     marginThreshold={0}
     PaperProps={{
      classes: {
        root: classes.paperRoot
      }
     }}
   >
   <div className={classes.FilterPopover}>
   {filters && filters.map((filter, i) => {
     let fieldType = '';
     let possibleValues = [];
     let currentField = filterableFields.filter(field => field.name === filter.field);

     if(currentField.length === 1){
       fieldType = currentField[0].type;
       possibleValues = currentField[0].options;
     }
     return (
       <div className={classes.filterForm} key={filter.field+i}>
         <div className={classes.filterWord}>{i === 0 ? t('action_table_filters.logic_word_first') : t('action_table_filters.logic_word_joining')}</div>
         <ActionTableFilterForm
           key={filter.field+i}
           comparison={filter.comparison}
           field={filter.field}
           fieldType={fieldType}
           filterableFields={filterableFields}
           possibleValues={possibleValues}
           values={filter.values}
           handleFilterChange={(modifiedFilter) => { handleFilterChange(i, modifiedFilter); }}
         />
         <CloseIcon className={classes.deleteFilterIcon} onClick={() => { handleFilterDelete(i);}} />
     </div>);
   })}
     <div className={classes.addNewFilter} onClick={createNewFilter}>
      <AddIcon className={classes.addNewFilterIcon} /> <span className={classes.addNewFilterText}> {t('action_table_filters.add_filter_button')}</span>
     </div>
     </div>
   </Popover>
    </div>
  );
}

export default compose(
  withTranslation(),
  withStyles(() => ({
    paperRoot: {
      overflow: 'visible'
    },
    filterWord: {
      display: 'inline-block',
      marginRight: '12px'
    },
    filterForm: {
      display: 'block',
      marginBottom: '12px'
    },
    addNewFilter: {
      display: 'block',
      cursor: 'pointer',
      verticalAlign: 'top',
      marginTop: '14px',
    },
    filterCount: {
      marginTop: '3px',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    addNewFilterIcon: {
      cursor: 'pointer',
      verticalAlign: 'middle'
    },
    FilterPopover: {
      padding: '12px',
      width: '500px',
    },
    FilterListIcon: {
      margin: '0 12px 0 12px'
    },
    FilterIconToggle: {
      display: 'inline-block'
    },
    deleteFilterIcon : {
      verticalAlign: 'middle',
      cursor: 'pointer',
      fontSize: '16px',
      color: '#555',
      fontWeight: 'bold',
      marginLeft: '12px'
    },
    addNewFilterText: {
      verticalAlign: 'middle'
    }
  }))
)(ActionTableFilters);

ActionTableFilters.propTypes = {
  initialFilters: PropTypes.array,
  filterableFields: PropTypes.array,
  classes: PropTypes.object,
  applyFilter: PropTypes.func,
  t: PropTypes.func
};
