export default {
  appState: {
    apiBase: '',
  },
  channelState: {
    channel : {
      channelId: '',
      sentences: {
        byId: {},
        all: []
      }
    },
    subtitles: [],
    results: {},
    isOpen: true,
    nextPage: 0,
    channels: []
  },
  candidateState: {
    experiments: {},
    moreCandidates: {},
    candidates: {
      articles: [],
      matchedClaimsById: {}
    },
    candidatesExact: {
      items: []
    },
    candidatesByClaimer: {
      items: []
    },
    topics: {
      requestState: '',
      topics: {},
      displayNames: {},
    },
    socialMedia: {
      requestState: '',
      topics: {}
    },
    claimants: {
      items: []
    },
    trendingClaimants: {
      items: []
    },
    publications: {
      byId: {},
      all: [],
      mediaCodeMap: {},
      requestState: ''
    }
  },
  entityState: {
    entities: {},
    suggestions: []
  },
  claimState: {
    claims : { byId : {}, allIds : [], starredIds: [] },
    interventions : { byId : {}, allIds : [] },
    sightings : { byMediaHash : {}, allMediaHashes : [] },
    reviewedSightings: {  allIds : [], byClaimId : {} }, // byMediaHash, allMediaHashes
    confirmedSightings: {
      allIds: [],
      byClaimId: {},
      nextCursor: null,
      requestState: ''
    },
    dismissedSightings: {
      allIds: [],
      byClaimId : {},
      nextCursor: null,
      requestState: ''
    },
    hasReceivedClaims: false,
    hasReceivedInterventions: false,
    results: {},
    orderedBy: 'asc',
    sortedBy: '',
    filters: {
      containingText: '',
      starred: null,
      pattern: null
    },
    editingClaim: false,
    editingClaimId: null,
    checkingSentences: [],
  },
  transcriptState: {
    liveLandingView: 'card',
    schedules: {
      byId: {},
      all: [],
      requestState: ''
    },
    editingSchedule: false,
    transcript: {
      requestState: ''
    },
    details: {},
    sentences: {
      byId: {},
      all: [],
      requestState: ''
    },
    matches: {
      sentencesByClaims: {},
      claimIds: [],
      totalMatchCount: 0
    },
    radioStations: {
      byId: {},
      all: []
    },
    transcripts: {
      all: {
        items: []
      },
      channel: {
        items: []
      },
      block: {
        items: []
      },
      radio: {
        items: []
      },
      youtube: {
        items: []
      },
      adhoc: {
        items: []
      },
      podcast: {
        items: []
      }
    }
  },
  interventionState: {
    interventions: {
      byId: {},
      all: [],
      requestState: ''
    },
    orderedBy: 'asc',
    sortedBy: ''
  },
  noticeState: {
    notices: {}
  },
  adminState: {
    editingUser: false,
    users: {
      isFetching: false,
      items: {
        byId: {},
        allIds: []
      },
      isUpdating: false,
      isCreating: false
    },
    organisationMedia:{
      byId: {},
      allIds: [],
      requestState: ''
    }
  },
  authState : {
    initialised: false,
    loginError: null,
    accessToken: null,
    refreshToken: null,
    tokenIsValid: null,
    pendingRefreshingToken: null,
    user: null,
    unauthorisedActions: [],
    loginRequestState: '',
    config: {}
  }
};
