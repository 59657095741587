import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import entityActions from 'Actions/entityActions';
import interventionActions from 'Actions/interventionActions';
import claimActions from 'Actions/claimActions';
import entitySelectors from 'Selectors/entitySelectors';
import interventionSelectors from 'Selectors/interventionSelectors';
import claimSelectors from 'Selectors/claimSelectors';
import Typography from '@material-ui/core/Typography';
import EntityDetails from 'Components/Entities/EntityDetails';

export class EntityPage extends React.Component {

  constructor(props){
    super(props);
    this.props.interventionActions.loadInterventions();
    this.props.claimActions.loadClaims();

    this.props.entityActions.getEntity(props.match.params['entityId']);
    this.state = {
      entityId: props.match.params['entityId']
    };
  }

  updateEntity  = (id) => {
    this.setState({'entityId': id });
    this.props.entityActions.getEntity(id);
  }

  render() {
    const {
      t,
      classes,
      actionsByPub,
      actionsByAuthor,
      claims
    } = this.props;

      return (
        <div className={classes.content}>
          <EntityDetails entityId={this.state.entityId} />
          {claims.length ? (
          <div>
          <Typography variant="h4" component="h4">
          {t('entity_page.author_in_factcheck', { count: claims.length})}
          </Typography>
          <ul>
            {claims.map(claim => (
              <li key={'claims'+claim.tracked_claim_id}>{claim.created_at.split('T').shift()} - <a href={claim.factcheck_url} target="_blank">{claim.claim}</a></li>
            ))}
          </ul>
          </div>
          ): ''}
          {actionsByPub.length ? (
          <div>
          <Typography variant="h4" component="h4">
          {t('entity_page.pub_in_action', { count: actionsByPub.length})}
          </Typography>
          <ul>
            {actionsByPub.map(action => (
              <li key={'actbypub'+action.intervention_id}>{action.publication_date.split('T').shift()} - <Link onClick={() => {this.updateEntity(action.wikidata_publication_id);}} to={'/actions/entities/'+action.wikidata_publication_id}>{action.publication}</Link> - <Link onClick={() => {this.updateEntity(action.wikidata_author_name_id);}} to={'/actions/entities/'+action.wikidata_author_name_id}>{action.author_name}</Link> - 
              {" "}<Link to={'/actions/'+action.intervention_id}>{t(action.status_label)}</Link></li>
            ))}
          </ul>
          </div>
          ): ''}
          {actionsByAuthor.length ? (
          <div>
          <Typography variant="h4" component="h4">
          {t('entity_page.author_in_action', { count: actionsByAuthor.length})}
          </Typography>
          <ul>
            {actionsByAuthor.map(action => (
              <li key={'actbyauth'+action.intervention_id} >{action.publication_date.split('T').shift()} - <Link onClick={() => {this.updateEntity(action.wikidata_publication_id);}}to={'/actions/entities/'+action.wikidata_publication_id}>{action.publication}</Link> - <Link onClick={() => {this.updateEntity(action.wikidata_author_name_id);}} to={'/actions/entities/'+action.wikidata_author_name_id}>{action.author_name}</Link> - 
              {" "}<Link to={'/actions/'+action.intervention_id}>{t(action.status_label)}</Link></li>
            ))}
          </ul>
          </div>
          ): ''}
        </div>
      );
  }
}

EntityPage.propTypes = {
  claim: PropTypes.object,
  match: PropTypes.object.isRequired,
  claimActions: PropTypes.object,
  interventionActions: PropTypes.object,
  noticeActions: PropTypes.object,
  classes: PropTypes.object,
  matchedSightings: PropTypes.array,
  actions: PropTypes.array,
  t: PropTypes.object,
  isEditingClaim: PropTypes.bool,
  claims: PropTypes.array,
  actionsByAuthor: PropTypes.array,
  actionsByPub: PropTypes.array,
  entity: PropTypes.object,
  entityActions: PropTypes.object
};


function mapStateToProps(state, ownProps) {
  const entityId = ownProps.match.params['entityId'];
  return {
    entity: entitySelectors.getEntity(state, entityId),
    actionsByAuthor: interventionSelectors.getInterventionsFilteredByWikiIdAuthor(state, entityId),
    actionsByPub: interventionSelectors.getInterventionsFilteredByWikiIdPublication(state, entityId),
    claims: claimSelectors.getClaimsByAuthorId(state,entityId)
  };
}


function mapDispatchToProps(dispatch) {
  return {
    entityActions: bindActionCreators(entityActions, dispatch),
    claimActions: bindActionCreators(claimActions, dispatch),
    interventionActions: bindActionCreators(interventionActions, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(),
  withStyles((theme) => ({
    grid: {
      display: 'grid',
      gridTemplateColumns: 'auto 300px',
      gridGap: '30px'
    },
    mainContent: {
      gridColumn: '1',
      alignSelf: 'start',
      padding: '12px'
    },
    secondaryContent: {
      gridColumn: '2'
    },
    cardContent: {
      padding: '12px',
      marginBottom: '24px'
    },
    content: {
      marginTop: '24px',
      margin: '0 auto',
      '& a': {
        color: theme.palette.primary[500]
      }
    },
    claimCardLink: {
      color: '#009688',
    },
    paragraphText: {
      lineHeight: '1.3',
      marginBottom: '12px'
    }
}))
)(EntityPage);
