const interventionConstants = {
  CREATE_INTERVENTION: 'CREATE_INTERVENTION',
  DELETE_INTERVENTION: 'DELETE_INTERVENTION',
  GET_INTERVENTION: 'GET_INTERVENTION',
  GET_INTERVENTIONS: 'GET_INTERVENTIONS',
  GET_INTERVENTIONS_BY_CLAIM_ID: 'GET_INTERVENTIONS_BY_CLAIM_ID',
  UPDATE_INTERVENTION: 'UPDATE_INTERVENTION',
  START_EDITING_INTERVENTION: 'START_EDITING_INTERVENTION',
  STOP_EDITING_INTERVENTION: 'STOP_EDITING_INTERVENTION',
  SET_INTERVENTIONS_ORDER: 'SET_INTERVENTIONS_ORDER',
  SET_INTERVENTIONS_SORT: 'SET_INTERVENTIONS_SORT',
  CHANGE_ACTIONS_FILTERS: 'CHANGE_ACTIONS_FILTERS',
  LONG_ACTIONS_URL: 'LONG_ACTIONS_URL'
};

export default interventionConstants;