import moment from 'moment';

export function generateId() {
  let id = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 5; i++)
    id += possible.charAt(Math.floor(Math.random() * possible.length));
  return id;
}

export function generateNumberId() {
  let id = 0;
  let possible = "123456789";
  for (let i = 0; i < 2; i++)
    id += possible.charAt(Math.floor(Math.random() * possible.length));

  id += Date.now();
  id = parseInt(id, 10);
  return id;
}

export function generateHash(str) {
  let hash = 0, i, chr;
  if (!str || str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr   = str.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function createRequestTypes(base) {
  return {
    REQUEST : `${base}_REQUEST`,
    PENDING : `${base}_PENDING`,
    SUCCESS : `${base}_SUCCESS`,
    FAILURE : `${base}_FAILURE`
  };
}

export function transformSentence(sentence) {
  sentence.transcript_sentence_id = sentence.transcript_sentence_id || generateId();
  return sentence;
}

export function formatMinutes(mins){
  let hours = Math.floor(mins / 60);
  hours = hours ? hours + 'h ' : '';
  let minutes = Math.floor(mins % 60);
  minutes = minutes ? minutes + 'm' : '';
  if(hours === '' && minutes === '') minutes = '0m';
  return hours + minutes;
}

export function formatCategoryData(data, distribution, filteredCategories){
  const isCumulative = distribution === 'cumulative';
  let formattedData = [];
  if(data) {
    for (let category of Object.keys(data)) {
      let catData, datePairs = [], totalValue = 0;
      for (let key of Object.keys(data[category])) {
        if(!isCumulative){
          totalValue = 0;
        }
        totalValue += data[category][key];
        datePairs.push({
          date: moment(key).format('YYYY-MM-DDT12:00:00'),
          value: totalValue
        });
      }
      let labels = [], values = [];

      for (const pair of datePairs) {
        labels.push(pair.date);
        values.push(pair.value);
      }
      let type = 'bar';

      if(isCumulative) type = 'line';
      catData = {
        x: labels,
        y: values,
        type: type,
        name: category,
        width: 1000*3600*23
      };

      let coloursByType = {
        'parliament': 'rgb(99, 176, 54)',
        'social-media' : 'rgb(0, 168, 198)',
        'online-newspapers' : 'rgb(239, 100, 111)'
      };

      if(filteredCategories && filteredCategories.length && filteredCategories.indexOf(category) === -1) {
        catData.visible = 'legendonly';
      }
      if(coloursByType[catData.name]){
        catData.marker = {
          color: coloursByType[catData.name]
        };
      }
      formattedData.push(catData);
    }
  }
  return formattedData;
}

export function normaliseById(objectArray, idFieldName) {
  /*
   takes an array of objects, with a field identified as the id field
   returns {
      byId: {}
      allIds: []
    }
   */

   let newObjectArray = Object.assign(objectArray, {});
   let allIds = [];
   let byId = {};

   newObjectArray.forEach((obj, i) => {
     if(!byId[obj[idFieldName]]){
       byId[obj[idFieldName]] = Object.assign(obj, {});
     }
     allIds.push(obj[idFieldName]);
   });

   return {
       byId: byId,
       allIds: allIds
    };
}

