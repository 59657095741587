import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SiteLogo from 'Components/Nav/NavSiteLogo';
import UserLoginForm from 'Components/User/UserLoginForm';
import Notice from 'Components/Common/Notice';
import UserForgotPasswordForm from 'Components/User/UserForgotPasswordForm';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

export class UserArea extends Component {

  constructor(props) {
      super(props);

      this.state = {
        hasForgottenPassword: false,
        email: '',
        organisation: ''
      };

      props.handleAreaToggle();
  }

  handleForgottenToggle = () => {
    this.props.handleAreaToggle();
    this.setState({
      hasForgottenPassword: !this.state.hasForgottenPassword
    });
  }

  handleFieldChange = (name, value) => {
    this.setState({[name]: value});
  }

  render() {
    const {
      t,
      notice,
      possibleOrgs,
      handleLoginSubmit,
      handleForgotSubmit,
      classes,
      addErrorNotice,
      removeErrorNotice,
      isLoggingIn
    } = this.props;
    return (
      <div className={classes.container}>
        <SiteLogo />
        {notice && (
          <div className={classes.noticeMessage}>
            <Notice
              message={notice.message}
              messageKey={notice.messageKey}
              type={notice.type}
            />
          </div>
        )}
        {this.state.hasForgottenPassword ? (
          <section>
            <h2 className={classes.forgotPasswordHeader}>{t('user_area.forgotten_password')}</h2>
            <UserForgotPasswordForm
              onSubmit={handleForgotSubmit}
              organisation={this.state.organisation}
              email={this.state.email}
              onFieldChange={this.handleFieldChange}
            />
            <div className={classes.forgotPassword}>
              <a onClick={this.handleForgottenToggle}>{t('user_area.cancel')}</a>
            </div>
          </section>
        ) : (
          <section>
            <UserLoginForm
              possibleOrgs={possibleOrgs}
              organisation={this.state.organisation}
              email={this.state.email}
              onFieldChange={this.handleFieldChange}
              handleSubmit={handleLoginSubmit}
              addErrorNotice={addErrorNotice}
              removeErrorNotice={removeErrorNotice}
              isLoggingIn={isLoggingIn}
            />
            <div className={classes.forgotPassword}>
              <a onClick={this.handleForgottenToggle}>{t('user_area.forgot_password')}</a>
            </div>
          </section>
        )}
      </div>
    );
  }
}

UserArea.propTypes = {
  t: PropTypes.func,
  notice: PropTypes.object,
  defaultOrganisation: PropTypes.string,
  handleLoginSubmit: PropTypes.func,
  handleForgotSubmit: PropTypes.func,
  handleAreaToggle: PropTypes.func,
  classes: PropTypes.object,
  addErrorNotice: PropTypes.func,
  removeErrorNotice: PropTypes.func,
  possibleOrgs: PropTypes.array,
  isLoggingIn: PropTypes.bool
};


export default compose(
  withStyles(() => ({
    container: {
      textAlign: 'center',
      padding: '24px'
    },
    forgotPassword: {
      fontSize: '16px',
      margin: '24px 0',
      '& a': {
        color: '#009688'
      },
      '&:hover': {
        cursor: 'pointer'
      }
    },
    forgotPasswordHeader: {
      textAlign: 'left',
      fontSize: '24px',
      marginTop: '24px',
      marginLeft: '24px'
    },
    noticeMessage: {
      marginTop: '12px',
      marginBottom: '12px'
    }
  })),
  withTranslation()
)(UserArea);
