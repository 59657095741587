import initialState from './initialState';
import constants from 'Constants/candidateConstants';
import { Article } from 'Models/Article';
import type { ApiArticle } from 'Models/Article';
import { Claim } from 'Models/Claim';
import type { ApiClaimType } from 'Models/Claim';

export default function candidateState(state = initialState.candidateState, action) {
  switch (action.type) {

    case `${constants.CANDIDATES_GET}_PENDING`:
      return {
        ...state,
        candidates: {
          requestState: 'pending',
          articles: []
        }
      };

    case `${constants.CANDIDATES_GET}_SUCCESS`: {
      const {
        articles,
        cursor,
        full_count,
        popular_claimants
      } = action.response;

      const popularClaimants = popular_claimants || [];
      if (articles) {
        return {
          ...state,
          candidates: {
            articles: articles.map((item: ApiArticle) => Article.fromApi(item)),
            fullCount: full_count,
            requestState: 'success',
            cursor: cursor
          },
          trendingClaimants: {
            items: popularClaimants.map((data) => {
              return {
                claimant: data.claimant_name,
                count: data.count
              };
            })
          }
        };
      } else {
        return {
          ...state
        };
      }
    }
    case `${constants.CANDIDATES_GET}_FAILURE`:
      return {
        ...state,
        candidates: {
          requestState: 'failure',
          articles: []
        }
      };

    case `${constants.CANDIDATES_GET_DIGEST}_PENDING`:
      return {
        ...state,
        candidates: {
          requestState: 'pending',
          articles: []
        }
      };

    case `${constants.CANDIDATES_GET_DIGEST}_SUCCESS`: {
      const {
        articles,
        cursor,
        full_count,
        popular_claimants,
        matched_tracked_claims
      } = action.response.matched_sightings;

      let matchedClaimsById = {};
      matched_tracked_claims.forEach((claim: ApiClaimType) => {
        matchedClaimsById[claim.tracked_claim_id] = Claim.fromApi(claim)
      });
      const popularClaimants = popular_claimants || [];
      if (articles) {
        return {
          ...state,
          candidates: {
            articles: articles.map((item: ApiArticle) => Article.fromApi(item)),
            fullCount: full_count,
            requestState: 'success',
            cursor: cursor,
            matchedClaimsById: matchedClaimsById
          },
          trendingClaimants: {
            items: popularClaimants.map((data) => {
              return {
                claimant: data.claimant_name,
                count: data.count
              };
            })
          }
        };
      } else {
        return {
          ...state
        };
      }
    }

    case `${constants.CANDIDATES_GET_DIGEST}_FAILURE`:
      return {
        ...state,
        candidates: {
          requestState: 'failure',
          articles: []
        }
      };

    case `${constants.CANDIDATES_GET_TOPICS}_PENDING`:
      return {
        ...state,
        topics: {
          requestState: 'pending',
          topics: {},
          displayNames: {},
        },
      };

    case `${constants.CANDIDATES_GET_TOPICS}_FAILURE`:
      return {
        ...state,
        topics: {
          requestState: 'failure',
          topics: {},
          displayNames: {},
        },
      };

    case `${constants.CANDIDATES_GET_TOPICS}_SUCCESS`: {
      let checkworthy_topics = action.response.checkworthy_topics;

      let allTopics = {};

      if (checkworthy_topics && checkworthy_topics.length) {
        checkworthy_topics.forEach((topic_response) => {
          allTopics[topic_response.checkworthy_topic] = {
            displayName: topic_response.display_name,
            articles: topic_response.media_articles.map((article: ApiArticle) => Article.fromApi(article)),
            reviewedMedia: topic_response.reviewed_media
          };
        });

        return {
          ...state,
          topics: {
            ...state.topics,
            requestState: 'success',
            topics: {
              ...state.topics.topics,
              ...allTopics,
            },
            displayNames: action.response.topic_display_names,
          },
        };
      } else {
        return {
          ...state
        };
      }
    }

    case `${constants.CANDIDATES_GET_MORE}_FAILURE`:
      return {
        ...state,
        candidates: {
          ...state.candidates,
          cursor: null
        },
        moreCandidates: {
          requestState: 'failure',
        }
      };

    case `${constants.CANDIDATES_GET_MORE}_PENDING`:
      return {
        ...state,
        moreCandidates: {
          requestState: 'pending',
        }
      };

    case `${constants.CANDIDATES_GET_MORE}_SUCCESS`: {
      let newArticles = [];
      if (action.response) {
        let candidates = action.response;
        newArticles = candidates.articles.map((item: ApiArticle) => Article.fromApi(item))
      }

      let oldArticles = [...state.candidates.articles];

      /*
        combine candidates response with the existing candidates checking
        if the last article of the current candidates has the same url as the
        first article of the more candidates
      */
      let combinedArticle = [];
      if (newArticles.length && oldArticles.length) {
        let lastArticle = oldArticles[oldArticles.length - 1];
        let firstArticle = newArticles[0];
        if (lastArticle.url === firstArticle.url) {
          newArticles.shift();
          oldArticles.pop();
          combinedArticle = [{
            ...lastArticle,
            sentences: [
              ...lastArticle.sentences,
              ...firstArticle.sentences
            ]
          }];
        }
      }

      let modifiedArticles = [
        ...oldArticles,
        ...combinedArticle,
        ...newArticles
      ];

      modifiedArticles = modifiedArticles.map(article => {
        return {
          ...article
        };
      });

      let cursor = action.response.cursor;
      if (!newArticles.length) cursor = null;
      return {
        ...state,
        candidates: {
          ...state.candidates,
          articles: [
            ...modifiedArticles
          ],
          cursor: cursor
        },
        moreCandidates: {
          requestState: 'success',
        }
      };
    }
    case `${constants.CLAIMANTS_GET_AUTOCOMPLETE}_SUCCESS`:
      return {
        ...state,
        claimants: {
          items: action.response.claimant_name_suggestions || []
        }
      };

    case `${constants.GET_PUBLICATIONS}_PENDING`: {
      let pubs = {
        byId: {},
        all: [],
        mediaCodeMap: {}
      };
      return {
        ...state,
        publications: {
          ...pubs,
          requestState: 'pending'
        }
      };
    }

    case `${constants.GET_PUBLICATIONS}_FAILURE`: {
      let pubs = {
        byId: {},
        all: [],
        mediaCodeMap: {}
      };
      return {
        ...state,
        publications: {
          ...pubs,
          requestState: 'failure'
        }
      };
    }

    case `${constants.DELETE_CHECKWORTHY_SIGHTING}_SUCCESS`: {
      if (action.request.topic && action.request.mediaHash && state.topics.topics[action.request.topic]) {
        return {
          ...state,
          topics: {
            ...state.topics,
            topics: {
              ...state.topics.topics,
              [action.request.topic]: {
                ...state.topics.topics[action.request.topic],
                reviewedMedia: state.topics.topics[action.request.topic].reviewedMedia.filter(review => review.media_hash !== action.request.mediaHash)
              }
            }
          }
        };
      } else {
        return {
          ...state
        };
      }
    }

    case `${constants.CONFIRM_CHECKWORTHY_SIGHTING}_SUCCESS`: {
      if (action.request.topic && action.request.mediaHash && state.topics.topics[action.request.topic]) {
        return {
          ...state,
          topics: {
            ...state.topics,
            topics: {
              ...state.topics.topics,
              [action.request.topic]: {
                ...state.topics.topics[action.request.topic],
                reviewedMedia: [
                  ...state.topics.topics[action.request.topic].reviewedMedia,
                  {
                    media_hash: action.request.mediaHash,
                    topic: action.request.topic,
                    feedback: 1
                  }
                ]
              }
            }
          }
        };
      } else {
        return {
          ...state
        };
      }
    }

    case `${constants.DISMISS_CHECKWORTHY_SIGHTING}_SUCCESS`: {
      if (action.request.topic && action.request.mediaHash && state.topics.topics[action.request.topic]) {
        return {
          ...state,
          topics: {
            ...state.topics,
            topics: {
              ...state.topics.topics,
              [action.request.topic]: {
                ...state.topics.topics[action.request.topic],
                reviewedMedia: [
                  ...state.topics.topics[action.request.topic].reviewedMedia,
                  {
                    media_hash: action.request.mediaHash,
                    topic: action.request.topic,
                    feedback: -1
                  }
                ]
              }
            }
          }
        };
      } else {
        return {
          ...state
        };
      }
    }


    case `${constants.GET_PUBLICATIONS}_SUCCESS`: {
      let pubs = {
        byId: {},
        all: [],
        mediaCodeMap: {}
      };

      action.response.publications.forEach(pub => {
        pubs.mediaCodeMap[pub.media_feed.media_code] = pub.publication_id;
        pubs.all.push(pub.publication_id);
        pubs.byId[pub.publication_id] = pub;
      });

      return {
        ...state,
        publications: {
          ...pubs,
          requestState: 'success'
        }
      };
    }
    default:
      return state;
  }
}
