import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';
import {bindActionCreators, compose} from 'redux';
import UserManager from 'Components/User/UserManager';

import adminSelectors from 'Selectors/adminSelectors';
import authSelectors from 'Selectors/authSelectors';
import noticeSelectors from 'Selectors/noticeSelectors';

import noticeActions from 'Actions/noticeActions';
import authActions from 'Actions/authActions';
import adminActions from 'Actions/adminActions';


import config from '../../config';

const userRoles =  {
  list: config.user.roles.map(org => { return {value: org.name, label: org.display };}),
  default: 'factchecker'
};

export class UserManagerContainer extends Component {


  constructor(props) {
    super(props);

    this.state =  {
      isChangingPassword: false,
      selectedUserId: null,
      selectedUserEmail: null,
      showDeleteUserDialog: false
    };

    this.props.adminActions.getUsers();

  }

  handleToggleAddEditUser = (user) => {
    if(this.props.isEditingUser){
      this.props.adminActions.stopEditingUser();
    } else {
      this.props.adminActions.startEditingUser();
      this.setState({
        isChangingPassword: false
      });
    }
  }

  handleToggleChangingPassword = () => {
    this.setState({
      isChangingPassword: !this.state.isChangingPassword
    });
  }

  handleToggleDeleteUser = (user) => {

    this.setState({

      showDeleteUserDialog: !this.state.showDeleteUserDialog
    });
  }

  handleUserFormSubmit = (user) => {
    if(user.userId) {
      this.props.adminActions.updateUser(user);
    } else {
      this.props.adminActions.createUser(user);
    }
  }

  handleDeleteUser = (user) => {
    this.setState({
      selectedUser: user
    },() => {
      this.handleToggleDeleteUser(user);
    });
  }

  deleteUser = (userId) => {
    this.props.adminActions.deleteUser(userId);
    this.setState({
      showDeleteUserDialog: false,
      selectedUser: null,
      selectedUserEmail: null
    });
  }

  handleResendVerifyEmail = (user) => {
    this.props.adminActions.resendVerify(user.userId, user.email);
  }

  render(){

    const {
      isChangingPassword,
      selectedUser,
      showDeleteUserDialog
    } = this.state;

    const currentUserId = this.props.currentUser.userId;

    const {
      isEditingUser,
      isCreatingUser,
      isUpdatingUser,
      users,
      currentUser,
      t,
      userNotice,
      generalNotice,
      passwordNotice,
      adminActions
    } = this.props;

    return (
      <UserManager
        currentUserId={currentUserId}
        changeUserPassword={adminActions.changeUserPassword}
        currentUser={currentUser}
        deleteUser={this.deleteUser}
        generalNotice={generalNotice}
        handleUserFormSubmit={this.handleUserFormSubmit}
        isCreatingUser={isCreatingUser}
        isEditingUser={isEditingUser}
        isUpdatingUser={isUpdatingUser}
        passwordNotice={passwordNotice}
        resendVerify={this.handleResendVerifyEmail}
        startEditingUser={adminActions.startEditingUser}
        stopEditingUser={adminActions.stopEditingUser}
        userNotice={userNotice}
        userRoles={userRoles}
        users={users}
      />
    );
  }
}


function mapDispatchToProps(dispatch) {
  return {
    noticeActions: bindActionCreators(noticeActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    adminActions: bindActionCreators(adminActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    users: adminSelectors.getUsers(state),
    isEditingUser: adminSelectors.getIsEditing(state),
    currentUser: authSelectors.getUser(state),
    isCreatingUser: adminSelectors.getIsCreating(state),
    isUpdatingUser: adminSelectors.getIsUpdating(state),
    userNotice: noticeSelectors.getNoticeById(state,'change_user_notice'),
    generalNotice: noticeSelectors.getNoticeById(state,'page_notice'),
    passwordNotice: noticeSelectors.getNoticeById(state,'change_password_notice'),
    permissions: authSelectors.getPermissions(state)
  };
}

UserManagerContainer.propTypes = {
    noticeActions:  PropTypes.object.isRequired,
    authActions:  PropTypes.object,
    adminActions:  PropTypes.object,
    users: PropTypes.array,
    isEditingUser: PropTypes.bool,
    isCreatingUser: PropTypes.bool,
    isUpdatingUser: PropTypes.bool,
    currentUser: PropTypes.object,
    t: PropTypes.func,
    userNotice: PropTypes.object,
    generalNotice: PropTypes.object,
    passwordNotice: PropTypes.object
};

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserManagerContainer);

