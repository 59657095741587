import React from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import NavAvatarMenu from './NavAvatarMenu';
import { withStyles } from '@material-ui/core/styles';
import NavSiteLogo from 'Components/Nav/NavSiteLogo';


export class Nav extends React.PureComponent {

  constructor(props){
      super(props);

      this.state = {
        showMenu: false
      };
  }

  handleToggle = () => {
      this.setState({'showMenu': !this.state.showMenu});
  }


  handleLogout = () => {
    this.props.handleLogout();
  }

  render(){
    const {
      user,
      orgDisplayName,
      permissions,
      orgAvatarImg,
      className,
      history,
      handleLogout,
      t,
      classes,
      authorisedRoutes,
      additionalLogos
    } = this.props;

    const navSections = {
      checkworthy: 'nav.home',
      search: 'nav.candidates',
      alerts: 'nav.digest',
      live: 'nav.live',
      actions: 'nav.actions'
    }

    let navLinks = (
      <section>
        {authorisedRoutes.map(route => (<NavLink key={route.key} to={route.to} activeClassName={classes.active}>{t(navSections[route.key])}</NavLink>))}
      </section>
    );
    return (
      <div className={this.props.className + ' ' + classes.navBar}>
        <NavSiteLogo />
        <a className={classes.menuBurger} onClick={this.handleToggle}><MenuIcon /></a>
        <div className={classes.navLinksArea}>
          {navLinks}
        </div>
        <div className={classes.navAvatar}>
          <NavAvatarMenu
            history={history}
            handleLogout={handleLogout}
            userName={user.displayName}
            orgDisplayName={orgDisplayName}
            isAdmin={permissions.includes('admin')}
            orgAvatarImg={orgAvatarImg}
            className={className}
            additionalLogos={additionalLogos}
          />
        </div>
        <Drawer open={this.state.showMenu} onClose={this.handleToggle}>
         <div className={classes.drawerLinks}>
           <a className={classes.closeButton} onClick={this.handleToggle}><CloseIcon /></a>
           <div className={classes.drawerLinksList}>
           {navLinks}
           </div>
         </div>
        </Drawer>
      </div>
    );
  }
}

Nav.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.array,
  className: PropTypes.string,
  classes: PropTypes.object,
  authActions: PropTypes.object,
  user: PropTypes.object,
  orgDisplayName: PropTypes.string,
  orgAvatarImg: PropTypes.string,
  handleLogout: PropTypes.func,
  t: PropTypes.func,
  authorisedRoutes: PropTypes.array,
  additionalLogos: PropTypes.array
};

export default compose(
  withTranslation(),
  withStyles((theme) => ({
    navBar: {
      backgroundColor: theme.palette.background.default,
      borderBottom: '1px solid '+ theme.palette.divider,
      width: '100%',
      height: '70px',
      zIndex: '3',
      padding: '4px 0',
      textAlign: 'center',
      '& h1': {
        display: 'inline-block',
        textAlign: 'left',
        margin: '8px auto 0 auto'
      }
    },
    drawerLinksList: {
      paddingTop: '40px'
    },
    drawerLinks: {
      padding: '12px',
      display: 'block',
      '& a': {
        display: 'block',
        color: theme.palette.text.primary,
        textDecoration: 'none',
        padding: '12px',
      }
    },
    closeButton: {
      position: 'relative',
      float: 'right',
      top: '0'
    },
    navAvatar: {
      position: 'absolute',
      right: '9px',
      top: '3px',
      '& img': {
        width: '48px',
        height: '48px'
      }
    },
    hidden: {
      display: 'none'
    },
    visible: {
      display: 'block'
    },
    menuBurger: {
      position: 'absolute',
      left: '12px',
      top: '23px',
      cursor: 'pointer',
      display: 'block',
      fontSize: '22px',
      margin: '0'
    },
    navLinksArea: {
      display: 'none',
    },
    '@media (min-width: 880px)': {
      navBar: {
        textAlign: 'left'
      },
      navLinksArea: {
        display: 'inline-block',
        margin: '19px 0 0 48px',
        verticalAlign: 'super',
        '& a': {
          margin: '0',
          marginRight: '24px',
          padding: '12px',
          color: theme.palette.text.primary,
          textDecoration: 'none',
        },
        '& a.active': {
            fontWeight: 'bold'
        }
      },
      menuBurger: {
        display: 'none'
      }
    }
  }))
)(Nav);