import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import ContentEditable from 'react-simple-contenteditable';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import moment from 'moment';
import { withTranslation, Trans } from 'react-i18next';

export class TranscriptDetailsCondensed extends React.Component {

  constructor(props){
    super(props);

    let date = moment(Date.now()).format('DD/MM/YY');

    this.state = {
      date: date,
      name: props.transcript.transcriptName,
      currentTime: props.selectedSentenceTime || 0
    };
    this.props.handleSentenceTimeChange(props.selectedSentenceTime)
  }

  handleStop = () => {
    this.props.handleStopTranscript(this.props.transcript.transcriptId);
  }

  handleNameOnChange = () =>{
    return null;
  }

  handleTimeChange = (e) =>{
    const time = parseInt(Math.floor(e.currentTarget.currentTime),10)
    if(Number.isInteger(time) && (time !== this.state.currentTime)) {
      this.setState({
        currentTime: time
      },() => {
        this.props.handleSentenceTimeChange(time)
      })
      
    }
  }

  handleNameKeyDown = (e) => {
    if(e.key === 'Enter'){
      e.preventDefault();
      if(e.target.innerHTML !== '') {
        this.props.handleChangeName(this.props.transcript.transcriptId, e.target.innerHTML);
        this.setState({ name: e.target.innerHTML });
        e.target.blur();
      }
    }
  }

  render() {
    const {
      canChangeName,
      classes,
      notice,
      t,
      selectedSentenceTime,
    } = this.props;

    const {
      startTime,
      endTime,
      mediaUrl,
      publicationName,
    } = this.props.transcript;

    let dates = '';
    if(startTime) {
      let momentStartDate = moment.utc(startTime).local();
      if(!endTime) {
          dates = (<div className={classes.startDate}>{momentStartDate.format('YYYY-MM-DD')}</div>);
      } else {
        let momentEndDate = moment.utc(endTime).local();
        if(momentStartDate.format('YYYY-MM-DD') === momentEndDate.format('YYYY-MM-DD')){
          let momentDuration = moment.duration(momentEndDate.diff(momentStartDate));
          let duration = parseFloat(momentDuration.asHours()).toFixed(2);
          if(duration < 1) {
            duration = (duration * 100) + 'mins';
          } else {
            duration = duration+'hrs';
          }
          dates = (
            <div className={classes.startDate}>
              {momentStartDate.format('YYYY-MM-DD HH:mm') +' to '+ momentEndDate.format('HH:mm')} — {duration}
            </div>
          );
        } else {
          dates = (
            <div className={classes.startDate}>
              <Trans i18nKey="transcript_details.date_range" parent="div">
                {momentStartDate.format('YYYY-MM-DD')} to  {momentEndDate.format('YYYY-MM-DD')}
              </Trans>
            </div>
          );
        }
      }
    }

    let radioPlayer = '';
    if (mediaUrl) {
      radioPlayer = (
      <audio 
        style={{width:'100%', height: '33px'}} 
        controls 
        src={mediaUrl + '#t=' + selectedSentenceTime}
        onTimeUpdate={this.handleTimeChange}
        id="transcript-audio-player"
      >
        <a href={mediaUrl}>Download audio</a>
      </audio>);
    }
    
    let showNotice = '';
    if(notice) {
      showNotice = (<CheckIcon />);
    }
    return (
      <div className={classes.details}>
        <div className={classes.mainHeader}>
          <div className={classes.mainHeaderDetails}>
            <Typography variant="h3" color="textPrimary" className={classes.transcriptTitleContainer}>
              {canChangeName ? (
                  <ContentEditable
                    html={this.state.name}
                    tagName="span"
                    className={classes.transcriptTitle}
                    onChange={this.handleNameOnChange}
                    onKeyPress={this.handleNameKeyDown}
                    contentEditable="plaintext-only"
                  />
              ): (
                <span>{this.state.name}</span>
              )}
            </Typography>
            <span className={classes.nameUpdate}>{showNotice}</span>
            <Typography variant="subtitle1" color="textSecondary" className={classes.transcriptDate}>
            {publicationName && dates ? (
              <span>
                <span className={classes.source}>{publicationName}</span> — {dates}
              </span>
            ) : (
              dates && <span>{dates}</span>
            )}
            </Typography>
          </div>
          <div className={classes.radioPlayer}>
            {radioPlayer}
          </div>
        </div>
      </div>
    );
  }
}

TranscriptDetailsCondensed.propTypes = {
    classes: PropTypes.object,
    transcript: PropTypes.object,
    notice: PropTypes.object,
    t: PropTypes.func,
    i18n: PropTypes.object,
    canChangeName: PropTypes.bool,
    handleChangeName: PropTypes.func,
    handleStopTranscript: PropTypes.func,
    selectedSentenceTime: PropTypes.number,
    handleSentenceTimeChange: PropTypes.func,
    showDate: PropTypes.bool
};

export default compose(
  withTranslation(),
  withStyles((theme) => ({
    mainHeader: {
      marginRight: '45px',
    },
    avatar: {
      display: 'inline-block',
      verticalAlign: 'top',
      margin: '8px 24px 8px 0',
      '& img': {
        width: '56px',
        height: '56px'
      }
    },
    [theme.breakpoints.up('md')] : {
      avatar: {
        marginLeft: '24px'
      }
    },
    startDate: {
      display: 'inline-block',
      fontSize: '15px',
      marginTop: '0',
      marginBottom: '0',
      marginRight: '12px'
    },
    name: {
      margin: '12px 12px 6px 0',
      fontSize: '34px',
      fontWeight: '400',
      lineHeight: '34px'
    },
    radioPlayer: {
      margin: '0 70px 0 12px'
    },
    details: {
      padding: '24px 0 16px 0', 
      position: 'fixed',
      zIndex: '1',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid ' + theme.palette.divider,
      borderWidth: '1px 0',
      marginTop: '0',
      marginBottom: '12px',
      marginLeft: '-12px',
      marginRight: '-12px',
      '& h3 span' : {
        fontSize: '28px'
      },
      '& label': {
        display: 'inline-block',
        marginLeft: '12px',
        verticalAlign: 'top'
      }
    },
    [theme.breakpoints.up('sm')] : {
      details: {
        marginTop: '0',
        padding: '24px 0 16px 0', 
      }
    },
    [theme.breakpoints.up('md')] : {
      details: {
        padding: '24px 0 16px 0', 
        marginTop: '0',
        '& form' : {
          width: '640px;'
        }
      }
    },
    historic: {
      fontSize: '15px',
      padding: '0 12px',
      display: 'inline-block',
      color: '#666'
    },
    meta: {
      marginTop: '4px',
      marginBottom: '8px'
    },
    source : {
      marginBottom: '4px'
    },
    nameAndDate: {
      display: 'inline-block'
    },
    transcriptTitleContainer: {
      display: 'inline-block',
      marginLeft: '12px'
    },
    transcriptDate: {
      display: 'inline-block',
      marginLeft: '12px'
    },
    transcriptTitle: {
      display: 'inline-block',
      margin: '12px 12px 0 12px',
      fontSize: '34px',
      fontWeight: '400',
      lineHeight: '34px'
    },
    nameUpdate: {
      fontSize: '24px',
      color: '#8BC34A'
    },
    sourceUrlLink: {
      color: theme.palette.primary[500],
      display: 'inline-block',
      marginRight: '12px'
    },
    openInNewIcon: {
      width: '15px',
      margin: '0 0 0 6px',
      verticalAlign: 'middle'
    }
}))
)(TranscriptDetailsCondensed);
