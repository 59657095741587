import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import ClaimFilters from 'Components/Claim/ClaimFilters';
import EnhancedTableDateRange from 'Components/Common/EnhancedTableDateRange';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import moment from 'moment';

import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

export function ClaimControlPanel(props) {
    const {
      dateRangeChange,
      filtersChange,
      filterTextChange,
      handleEditClaim,
      resultsCSV,
      canAddClaims,
      t,
      classes
    } = props;

    let resultsCount = props.resultsCount || 0;

    const [filterText, setFilterText] = React.useState('');

    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);


    function handleDateRangeChange(dates){
      setStartDate(dates.startDate);
      setEndDate(dates.endDate);
      dateRangeChange(dates);
    }

    function handleFilterTextChange(e){
      setFilterText(e.target.value);
      filterTextChange(e.target.value);
    }

    function handleFiltersChange(filters) {
      filtersChange(filters);
    }

    return (
      <div className={classes.content}>
        <div className={classes.claimFilters}>
          <ClaimFilters
            handleFilter={handleFiltersChange}
          />
        </div>
        {canAddClaims && (
          <div className={classes.addClaimButton} >
            <Fab color="primary" aria-label="add" size="small" onClick={handleEditClaim}><AddIcon /></Fab>
          </div>
        )}
        <div className={classes.claimControlPanel}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <div className={classes.textFilter}>
            <TextField
              name="filter"
              value={filterText}
              onChange={handleFilterTextChange}
              label={t('claim_control_panel.filter_placeholder')}
            />
            </div>
            <div className={classes.claimsRange}>
              <EnhancedTableDateRange
                startDate={startDate}
                endDate={endDate}
                handleDatesChange={handleDateRangeChange}
              />
            </div>
            <div className={classes.resultsCount}>
            {resultsCount && resultsCSV && (
            <a className={classes.showSelectedLink} href={resultsCSV} download={"claims_data_" + moment().format('YYYY-MM-DD_HH-mm-ss')+ ".csv"}><SaveAltIcon /></a>
            )}   {t('claim_control_panel.claims_count', { count: resultsCount })}
            </div>
          </div>
        </div>
    );
}

ClaimControlPanel.propTypes = {
  dateRangeChange: PropTypes.func.isRequired,
  filtersChange: PropTypes.func.isRequired,
  filterTextChange: PropTypes.func.isRequired,
  handleEditClaim: PropTypes.func,
  resultsCount: PropTypes.number,
  resultsCSV: PropTypes.string,
  canAddClaims: PropTypes.bool,
  t: PropTypes.func,
  classes: PropTypes.object
};


export default compose(
  withStyles(() => ({
    content: {
      marginLeft: '12px'
    },
    claimsRange: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      margin: '0 24px',
      boxSizing: 'border-box',
      '& input': {
        boxSizing: 'border-box'
      }
    },
    claimFilters: {
      float: 'right',
      marginRight: '12px',
      verticalAlign: 'bottom',
      display: 'inline-block'
    },
    searchIcon: {
      verticalAlign: 'bottom',
      display: 'inline-block'
    },
    textFilter: {
      verticalAlign: 'bottom',
      display: 'inline-block'
    },
    claimControlPanel: {
      display: 'inline-block'
    },
    addClaimButton: {
      verticalAlign: 'bottom',
      marginTop: '12px',
      marginRight: '12px',
      display: 'inline-block'
    },
    resultsCount: {
      display: 'inline-block'
    },
    showSelectedLink: {
      color: '#009688',
      cursor: 'pointer',
      fontSize: '16px',
      verticalAlign: 'middle'
    },
    selectedLink: {
      '& a': {
        color: '#009688'
      }
    },
    claimsControlTitle: {
      margin: '0 12px 0 0',
      display: 'inline-block',
      verticalAlign: 'middle'
    }
  })),
  withTranslation()
)(ClaimControlPanel);
