import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'redux';

import Notice from 'Components/Common/Notice';
import NoticeContainer from 'Components/Common/NoticeContainer';
import TranscriptSentencesContainer  from "Components/Transcript/TranscriptSentencesContainer";
import TranscriptDetailsVideo from 'Components/Transcript/TranscriptDetailsVideo';
import noticeActions from 'Actions/noticeActions';
import transcriptActions from 'Actions/transcriptActions';
import transcriptSelectors from 'Selectors/transcriptSelectors';
import { withStyles } from '@material-ui/core/styles';

class TranscriptYoutubePage extends React.Component {

  constructor(props){
    super(props);
    props.transcriptActions.getYoutubeTranscript(props.match.params['transcriptId']);
    const urlParams = new URLSearchParams(this.props.location.search);
    this.initialTime = parseInt(urlParams.get('time'),10)
    //const sid = parseInt(urlParams.get('sid'),10);  // example id 611828
    if(this.initialTime) {
      this.props.transcriptActions.setTranscriptTime(this.initialTime);
    }
    this.state = {
      selectedSentence: null,
      selectedSentenceTime: this.initialTime || null,
    }
  }

  componentWillUnmount = () => {
      this.props.transcriptActions.clearTranscript();
      this.props.noticeActions.clearNotices();
  }

  loadTranscriptSentences = (transcriptId) => {
    this.props.transcriptActions.getYoutubeTranscriptSentences(transcriptId);
  }

  handleSentenceHighlight = (transcriptId, sentenceId, hasHighlight) => {
    if(hasHighlight) {
      this.props.transcriptActions.unhighlightSentence('youtube', transcriptId, sentenceId);
    } else {
      this.props.transcriptActions.highlightSentence('youtube', transcriptId, sentenceId);
    }
  }

  handleSentenceTimeChange = (time) => {
    this.setState(
      { selectedSentenceTime: time }, 
      () => {
        this.props.transcriptActions.setTranscriptTime(time)
    });
  }

  handlePlaySentence = (time) => {
    this.setState(
      { selectedSentenceTime: time }, 
      () => {
        this.props.transcriptActions.setTranscriptTime(time)
    });
    window.playVideo(time)
  }

  render() {
    const {
      transcript,
      transcriptActions,
      classes
    } = this.props;

    return (
      <section>
        <div className="row">
          <div className="col-xs4-4 col-lg-12">
            {transcript && transcript.transcriptName ? (
              <TranscriptDetailsVideo
                transcript={transcript}
                handleChangeName={transcriptActions.updateYoutubeTranscriptName}
                canChangeName={true}
                handleStopTranscript={transcriptActions.stopYoutubeTranscript}
                isOpen={transcript.isOpen}
                selectedSentenceTime={this.initialTime}
                handleSentenceTimeChange={this.handleSentenceTimeChange}
              />
            ): ''}
          </div>
        </div>
        <div className={classes.transcriptNoticeContainer}>
        {transcript.isErrored ? (
          <Notice messageKey="transcript_youtube_page.transcript_errored" displayCode="page_notice" />
        ) : (
          <NoticeContainer displayCode="page_notice" dismissible={true} />
        )}
        
        {transcript && transcript.transcriptName ? (
          <TranscriptSentencesContainer
            isOpen={transcript.isOpen}
            transcriptId={transcript.transcriptId}
            colClass="col-full-row"
            loadTranscriptSentences={this.loadTranscriptSentences}
            showTime={true}
            handleSentenceHighlight={this.handleSentenceHighlight}
            isErrored={transcript.isErrored}
            selectedSentenceTime={this.state.selectedSentenceTime}
            canPlaySentences={true}
            handlePlaySentence={this.handlePlaySentence}
            initialSentenceId={parseInt(this.props.history.location.hash?.substring(1),10)}
            setTranscriptTime={this.props.transcriptActions.setTranscriptTime}
            hasVideo={true}
          />
        ): ''}
        </div>
      </section>
    );
  }
}

TranscriptYoutubePage.propTypes = {
  transcriptActions: PropTypes.object.isRequired,
  noticeActions: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  transcript: PropTypes.object,
  location: PropTypes.object,
  classes: PropTypes.object
};

function mapStateToProps(state) {
  return {
    transcript: transcriptSelectors.getTranscriptDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transcriptActions: bindActionCreators(transcriptActions, dispatch),
    noticeActions: bindActionCreators(noticeActions, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles((theme) => ({
    transcriptNoticeContainer: {
      marginTop: '147px',
    },

}))
)(TranscriptYoutubePage);