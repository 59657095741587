import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import { withTranslation } from 'react-i18next';
import UserChangePasswordForm from 'Components/User/UserChangePasswordForm';
import NoticeContainer from 'Components/Common/NoticeContainer';
import authActions from 'Actions/authActions';
import adminActions from 'Actions/adminActions';

export class UserChangePasswordFormContainer extends Component {

  constructor(props) {
    super(props);
  }

  handleSubmit = (password) => {

    const {
      user,
      authActions,
      adminActions,
      token
    } = this.props;

    if(token){
      authActions.changePasswordWithToken(token, password);
    } else if(user && user.userId) {
      adminActions.changeUserPassword(user.userId, password, user.email);
    } else {
      authActions.changePassword(password);
    }
  }

  handleFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render(){
    const { t, token } = this.props;
    return (
      <section>
        <UserChangePasswordForm
          onSubmit={this.handleSubmit}
          isVerifying={token && token.length || false}
        />
        <NoticeContainer displayCode="page_notice" dismissible={false} />
        </section>
    );
  }
}

UserChangePasswordFormContainer.propTypes  = {
  token: PropTypes.string,
  user: PropTypes.object,
  authActions: PropTypes.object,
  adminActions: PropTypes.object,
  t: PropTypes.func

};

function mapDispatchToProps(dispatch) {
  return {
     authActions: bindActionCreators(authActions, dispatch),
     adminActions: bindActionCreators(adminActions, dispatch)
  };
}

export default compose(
  withTranslation(),
  connect(
    null,
    mapDispatchToProps
 )
)(UserChangePasswordFormContainer);
