import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import ChannelList from 'Components/Live/LiveChannelList';
import NoticeContainer from 'Components/Common/NoticeContainer';
import noticeActions from 'Actions/noticeActions';
import channelActions from 'Actions/channelActions';
import transcriptActions from 'Actions/transcriptActions';
import authSelectors from 'Selectors/authSelectors';
import channelSelectors from 'Selectors/channelSelectors';
import transcriptSelectors from 'Selectors/transcriptSelectors';
import TranscriptTypeOverview from 'Components/Transcript/TranscriptTypeOverview';
import TranscriptTableList from 'Components/Transcript/TranscriptTableList';
import AddEditDrawer from 'Components/Common/AddEditDrawer';
import TranscriptFormContainer from 'Components/Transcript/TranscriptFormContainer';
import ListIcon from '@material-ui/icons/List';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';

export class LivePage extends React.Component {
  constructor(props){
    super(props);

    const {
      permissions,
      transcriptActions,
      channelActions
    } = props;

    if(permissions.includes('transcript-channel')) {
      channelActions.loadChannels();
      transcriptActions.getChannelTranscripts();
    }

    transcriptActions.getAllTranscripts();
    
    ['block','youtube','adhoc','radio','podcast'].forEach(type => {
      if(permissions.includes('transcript-'+type)) transcriptActions.getTranscripts(type);
    })


    this.state = {
      transcriptSchedulerActive: false,
      formActive: false,
      formDefaultType: 'channel',
    };
  }

  componentWillUnmount() {
      this.props.noticeActions.clearNotices();
  }

  handleDeleteTranscript = (transcriptId, transcriptType) => {
    this.props.transcriptActions.deleteTranscript(transcriptId, transcriptType)
  }

  handleTranscriptFormToggle = (code) => {
    this.setState({
      formActive: !this.state.formActive,
      formDefaultType: code ?? 'channel'
    });
  }

  setLatestTranscriptView = (code) => {
    this.props.transcriptActions.setLiveLandingView(code); 
  }

  
  getMoreTranscripts = (page) => {
    if(!this.props.isGettingMoreTranscripts) {
      this.props.transcriptActions.getMoreTranscripts(page);
    }
  }

  render() {
    const {
      channels,
      classes,
      permissions,
      t,
      transcripts,
      latestTranscriptConfig,
      allTranscripts,
      latestTranscriptsView,
      isGettingMoreTranscripts,
      hasMoreTranscripts
    } = this.props;
  
    return (
      <div className={classes.latestTranscriptsLayout}>
        {channels && permissions.includes('transcript-channel') && (
          <div className={classes.channelBlock + " row"}>
            <div className="col-xs4-4 col-lg-12">
              {permissions.includes('transcript-channel') && <ChannelList channels={channels} />}
            </div>
          </div>      
        )}
        <div className={classes.toggleCardLayout}>
          <ViewWeekIcon className={classes.cardView + ' ' + (latestTranscriptsView === 'card' ? classes.selectedView: '')} onClick={() => {this.setLatestTranscriptView('card')}} />
          <ListIcon className={latestTranscriptsView === 'list' ? classes.selectedView : ''} onClick={() => {this.setLatestTranscriptView('list')}} />
        </div>
        <div className={classes.latestTranscripts}>
          {latestTranscriptsView === 'card' ? (
          <div>
            {latestTranscriptConfig.map((conf) => (
              <div className={classes.typesOverview} key={conf.code}>
                {permissions.includes('transcript-' + conf.code) && (!['adhoc','radio','podcast'].includes(conf.code) || transcripts[conf.code].length > 0) && (
                  <TranscriptTypeOverview
                    typeTitle={t('live_page.transcripts.'+conf.code)}
                    code={conf.code}
                    channels={channels}
                    transcripts={transcripts[conf.code]}
                    handleDeleteTranscript={this.handleDeleteTranscript}
                    handleFormToggle={() => { this.handleTranscriptFormToggle(conf.code) }}
                    handleViewAll={()=> { this.setLatestTranscriptView('list')}}
                  />
                )}
              </div>
            ))}
          </div>
          ) : (
            <div>
                <InfiniteScroll
                  pageStart={1}
                  loadMore={this.getMoreTranscripts}
                  hasMore={hasMoreTranscripts}
                  loader={''}
                >
                  <TranscriptTableList
                    transcripts={allTranscripts}
                    channels={channels}
                    limit={allTranscripts.length}
                    handleDelete={this.handleDeleteTranscript}
                    handleFormToggle={() => { this.handleTranscriptFormToggle() }}
                    getMoreTranscripts={this.getMoreTranscripts}
                  />
                </InfiniteScroll>
                {isGettingMoreTranscripts && <CircularProgress key="infinite-scroll-loader" />}

            </div>
          )}

          <NoticeContainer displayCode="page_notice" dismissible={true} />
        </div>
        <AddEditDrawer
          handleToggle={this.handleTranscriptFormToggle}
          isActive={this.state.formActive}
          title={t('transcript_drawer.create_transcript')}
        >
          <TranscriptFormContainer
            channels={channels}
            defaultType={this.state.formDefaultType}
          />
        </AddEditDrawer>
      </div>
    );
  }
}

LivePage.propTypes = {
    channels: PropTypes.array,
    channelActions: PropTypes.object.isRequired,
    transcriptActions: PropTypes.object.isRequired,
    noticeActions: PropTypes.object,
    t: PropTypes.func,
    permissions: PropTypes.array,
    i18n: PropTypes.object,
    classes: PropTypes.object,
    latestTranscriptConfig: PropTypes.array,
    transcripts: PropTypes.object,
    allTranscripts: PropTypes.array,
    latestTranscriptsView: PropTypes.string,
    isGettingMoreTranscripts: PropTypes.bool,
    hasMoreTranscripts: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    channels: channelSelectors.getChannels(state),
    transcripts: transcriptSelectors.getTranscripts(state),
    allTranscripts: transcriptSelectors.getCombinedTranscripts(state),
    permissions: authSelectors.getPermissions(state),
    latestTranscriptConfig: transcriptSelectors.getLatestTranscriptConfig(state),
    latestTranscriptsView: transcriptSelectors.getLiveLandingView(state),
    hasMoreTranscripts: transcriptSelectors.getHasMoreTranscripts(state),
    isGettingMoreTranscripts: transcriptSelectors.getIsGettingMoreTranscripts(state)

  };
}

function mapDispatchToProps(dispatch) {
  return {
    channelActions: bindActionCreators(channelActions, dispatch),
    transcriptActions: bindActionCreators(transcriptActions, dispatch),
    noticeActions: bindActionCreators(noticeActions, dispatch)
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles((theme) => ({
      typesOverview: {
        marginTop: '-18px'
      },
      addScheduleButton: {
        marginLeft: '24px'
      },
      latestTranscriptsLayout: {
        marginTop: '42px'
      },
      latestTranscripts: {
        maxWidth: '1680px'
      },
      viewTranscripts: {
        color: theme.palette.primary[500],
        display: 'inline-block',
        marginBottom: '24px',
        fontSize: '16px',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      },
      transcriptsRow: {
        flexDirection: 'row-reverse'
      },
      '@media (min-width: 640px)' : {
        transcriptsRow: {
          padding: '0 12px'
        }
      },
      pageTitle: {
        color: theme.palette.text.primary
      },
      linkRow: {
        marginTop: '-24px'
      },
      cardView: {
        height: '22px',
        verticalAlign: 'top',
        marginTop: '1px',
        marginRight: '6px'
      },
      selectedView: {
        color: '#000 !important'
      },
      toggleCardLayout: {
        cursor: 'pointer',
        float: 'right',
        marginRight: '12px',
        '& svg': {
          color: '#999 '
        },
        '& svg:hover' : {
            color: '#000'
        }
      }
  })),
  withTranslation()
)(LivePage);
