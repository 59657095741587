import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { withTranslation, Trans } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

export function ActionStats(props) {
    const {
      actionStatistics,
      actionStatsYTD,
      monthlyStatisticsByYear,
      hasFacebook,
      t,
      classes
    } = props;

    const [statYear, setStatYear] = React.useState(null);
    const [isYearToDate, setIsYearToDate] = React.useState(true);

    function handleSetStatYear(statYear){
      if(statYear) {
        setStatYear(statYear);
      } else {
        setStatYear(null);
      }
    }

    function handleSetIsYearToDate(){
      if(isYearToDate) {
        setIsYearToDate(false);
      } else {
        setIsYearToDate(true);
      }
    }

    let actionStats = actionStatistics;
    if(statYear) {
      actionStats = monthlyStatisticsByYear[statYear];
    } else if (isYearToDate) {
      actionStats = actionStatsYTD;
    }
    //console.log(actionStats);

    if(actionStats.length){
      let newestYear = actionStats[actionStats.length-1].year;

    return (
      <Paper>
          {statYear ? (
            <div className={classes.headerForYear}>
            <Typography variant="h6">
              {t('action_stats.viewing_stats_for_year', { year: statYear})}
            </Typography>
            </div>
          ): (
            <Typography variant="h6">
              <Checkbox
              checked={isYearToDate}
              onChange={() => { handleSetIsYearToDate(); }}
              value={1}
              color="primary"
            /> {t('action_stats.table_header_year_to_date')}
          </Typography>
          )}
        <Table>
          <TableHead>
            <TableRow>
              {statYear ? (
                <TableCell align="right">
                <span className={classes.statsHeaderLink} onClick={() => { handleSetStatYear(null);}}>{t('action_stats.table_header_statistic')}</span> / <span className={classes.statsHeaderSelected}>{t('action_stats.table_header_statistic_by_month')}</span>
                </TableCell>
              )  : (
                <TableCell align="right">
                <span className={classes.statsHeaderSelected}>{t('action_stats.table_header_statistic')}</span> / <span className={classes.statsHeaderLink} onClick={() => { handleSetStatYear(newestYear);}}>{t('action_stats.table_header_statistic_by_month')}</span>
                </TableCell>
              )}
              {actionStats.map(stats => (
                <TableCell align="right" key={stats.title}>
                {statYear ? (
                  <span>{moment(stats.title).format('MMM')}</span>
                )  : (
                  <span className={classes.statsHeaderLink} onClick={() => { handleSetStatYear(stats.year);}}>
                    {isYearToDate ? (
                      <span>{t('action_stats.title_ytd', { year: stats.title})}</span>
                    ): (
                      <span>{stats.title}</span>
                    )}
                  </span>
                )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {hasFacebook ? (
              <TableRow>
              <TableCell align="right">{t('action_stats.table_header_factcheck_not_fb')}</TableCell>
              {actionStats.map(stats => (
                  <TableCell align="right" key={stats.title}>{stats.factcheckCount}</TableCell>
              ))}
              </TableRow>
            ) : ''}
            <TableRow>
              <TableCell align="right">{t('action_stats.total_actions')}</TableCell>
              {actionStats.map(stats => (
                  <TableCell align="right" key={stats.title}>{stats.actionsCount}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell align="right">{t('action_stats.discovered_through')}</TableCell>
              {actionStats.map(stats => (
                  <TableCell align="right" key={stats.title}>{stats.actionsAFCCount}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell align="right">{t('action_stats.table_header_follow_up')}<Tooltip title={t('action_stats.tooltip_facts_acted_on')} classes={{ tooltip: classes.customWidth }}><HelpIcon className={classes.helpIcon} fontSize="small"/></Tooltip></TableCell>
              {actionStats.map(stats => (
                  <TableCell align="right" key={stats.title}>{stats.factchecksActedOn}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell align="right">{t('action_stats.table_header_action_rate')}<Tooltip title={t('action_stats.tooltip_action_rate')} classes={{ tooltip: classes.customWidth }}><HelpIcon className={classes.helpIcon} fontSize="small"/></Tooltip></TableCell>
              {actionStats.map(stats => (
                  <TableCell align="right"key={stats.title}>{stats.actionRatePercent}%</TableCell>
              ))}
            </TableRow>
            {hasFacebook ? (
            <TableRow>
              <TableCell align="right">{t('action_stats.table_header_factcheck_fb')}</TableCell>
              {actionStats.map(stats => (
                  <TableCell align="right" key={stats.title}>{stats.facebookFactchecks}</TableCell>
              ))}
            </TableRow>
            ) : ''}
            <TableRow>
              <TableCell align="right">{t('action_stats.table_header_factcheck_total')}</TableCell>
              {actionStats.map(stats => (
                  <TableCell align="right" key={stats.title}>{stats.publishedCount}</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  } else {
    return <div className={classes.aggregatedLoader}><CircularProgress /></div>;
  }
}

ActionStats.propTypes = {
    actionStatistics: PropTypes.array.isRequired,
    actionStatsYTD: PropTypes.array.isRequired,
    hasFacebook: PropTypes.bool,
    monthlyStatisticsByYear: PropTypes.object,
    t: PropTypes.func,
    classes: PropTypes.object,
    i18n: PropTypes.object
};


export default compose(
  withTranslation(),
  withStyles((theme) => ({
    aggregatedLoader: {
      marginTop: '12px'
    },
    statsHeaderSelected: {
      fontWeight: 'bold'
    },
    statsHeaderLink: {
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    headerForYear: {
      paddingTop: '12px',
      margin: '0 0 0 12px'
    },
    helpIcon: {
      fontSize: '16px',
      verticalAlign: 'text-bottom',
      marginLeft: '6px',
      color: '#888'
    },
    customWidth: {
      width: '200px'
    }

})))(ActionStats);

