import candidateConstants from 'Constants/candidateConstants';

export function loadCandidates(exactPhrases,
                               hasWords,
                               excludeWords,
                               startDate,
                               endDate,
                               publications,
                               claimants,
                               categories,
                               types,
                               claimantGroups,
                               checkedClaims) {
  return {
    type: candidateConstants.CANDIDATES_GET,
    exactPhrase: exactPhrases,
    hasWords: hasWords,
    excludeWords: excludeWords,
    startDate: startDate,
    endDate: endDate,
    publications: publications,
    claimants: claimants,
    categories: categories,
    types: types,
    claimantGroups: claimantGroups,
    checkedClaims: checkedClaims
  };
}


export function loadDigestCandidates(startDate, endDate, threshold) {
  return {
    type: candidateConstants.CANDIDATES_GET_DIGEST,
    startDate: startDate,
    endDate: endDate,
    threshold: threshold
  };
}


export function loadMoreCandidates(exactPhrases,
                                   hasWords,
                                   excludeWords,
                                   startDate,
                                   endDate,
                                   publications,
                                   claimants,
                                   categories,
                                   types,
                                   claimantGroups,
                                   checkedClaims,
                                   nextCursor) {

  return {
    type: candidateConstants.CANDIDATES_GET_MORE,
    exactPhrase: exactPhrases,
    hasWords: hasWords,
    excludeWords: excludeWords,
    startDate: startDate,
    endDate: endDate,
    publications: publications,
    claimants: claimants,
    categories: categories,
    types: types,
    claimantGroups: claimantGroups,
    checkedClaims: checkedClaims,
    nextCursor: nextCursor
  };
}

export function loadClaimantsAutocomplete(text) {
  return {
    type: candidateConstants.CLAIMANTS_GET_AUTOCOMPLETE,
    query: text
  };
}

export function setExperiment(experimentId, value) {
  return {
    type: candidateConstants.APP_SET_EXPERIMENT,
    experimentId: experimentId,
    value: value
  };
}


export function changeCandidatesFilter(){
  return {
    type: candidateConstants.CANDIDATES_SET_FILTERS
  };
}

export function longCandidatesUrl(){
  return {
    type: candidateConstants.CANDIDATES_LONG_URL
  };
}

export function candidatesSearchFromUrl(text) {
  return {
    type: candidateConstants.CANDIDATES_GET_FROM_URL,
    query: text
  };
}

export function candidatesSearchFromInput(text) {
  return {
    type: candidateConstants.CANDIDATES_GET_FROM_SEARCH_BOX,
    query: text
  };
}

export function candidatesSearchFromAdvancedForm(text) {
  return {
    type: candidateConstants.CANDIDATES_GET_FROM_ADVANCED,
    query: text
  };
}

export function loadPublications() {
  return {
    type: candidateConstants.GET_PUBLICATIONS,
  };
}

export function loadTopics(startDate, endDate, topic) {
  return {
    type: candidateConstants.CANDIDATES_GET_TOPICS,
    startDate: startDate,
    endDate: endDate,
    topic: topic
  };
}

export function loadTopicSummaries(startDate, endDate) {
  return {
    type: candidateConstants.CANDIDATES_GET_TOPIC_SUMMARIES,
    startDate: startDate,
    endDate: endDate
  };
}

export function dismissSighting(topic, mediaHash) {
  return {
    type: candidateConstants.DISMISS_CHECKWORTHY_SIGHTING,
    topic: topic,
    mediaHash: mediaHash
  };
}

export function removeReviewedSighting(topic, mediaHash) {
  return {
    type: candidateConstants.DELETE_CHECKWORTHY_SIGHTING,
    topic: topic,
    mediaHash: mediaHash
  };
}

export function confirmSighting(topic, mediaHash) {
  return {
    type: candidateConstants.CONFIRM_CHECKWORTHY_SIGHTING,
    topic: topic,
    mediaHash: mediaHash
  };
}

export default {
  loadCandidates,
  loadDigestCandidates,
  loadMoreCandidates,
  loadClaimantsAutocomplete,
  longCandidatesUrl,
  candidatesSearchFromUrl,
  candidatesSearchFromInput,
  candidatesSearchFromAdvancedForm,
  changeCandidatesFilter,
  setExperiment,
  loadPublications,
  loadTopics,
  loadTopicSummaries,
  dismissSighting,
  removeReviewedSighting,
  confirmSighting
};
