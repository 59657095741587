import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'redux';

import NoticeContainer from 'Components/Common/NoticeContainer';
import TranscriptSentencesContainer  from "Components/Transcript/TranscriptSentencesContainer";
import TranscriptDetailsCondensed from 'Components/Transcript/TranscriptDetailsCondensed';

import noticeActions from 'Actions/noticeActions';
import transcriptActions from 'Actions/transcriptActions';
import transcriptSelectors from 'Selectors/transcriptSelectors';
import { withStyles } from '@material-ui/core/styles';

class TranscriptPodcastPage extends React.Component {

  constructor(props){
    super(props);
    props.transcriptActions.getPodcastTranscript(props.match.params['transcriptId']);
    props.transcriptActions.getPodcastTranscriptUrl(props.match.params['transcriptId']);
    const urlParams = new URLSearchParams(this.props.location.search);
    this.initialTime = parseInt(urlParams.get('time'),10)

    if(this.initialTime) {
      this.props.transcriptActions.setTranscriptTime(this.initialTime);
    }
    this.state = {
      selectedSentence: null,
      selectedSentenceTime: this.initialTime || null,
    }
  }

  componentWillUnmount = () => {
      this.props.transcriptActions.clearTranscript();
      this.props.noticeActions.clearNotices();
  }

  loadTranscriptSentences = (transcriptId) => {
    this.props.transcriptActions.getPodcastTranscriptSentences(transcriptId);
  }

  handleSentenceHighlight = (transcriptId, sentenceId, hasHighlight) => {
    if(hasHighlight) {
      this.props.transcriptActions.unhighlightSentence('podcast', transcriptId, sentenceId);
    } else {
      this.props.transcriptActions.highlightSentence('podcast', transcriptId, sentenceId);
    }
  }

  handleSentenceTimeChange = (time) => {
    this.setState(
      { selectedSentenceTime: time }, 
      () => {
        this.props.transcriptActions.setTranscriptTime(time)
    });
  }

  handlePlaySentence = (time) => {
    this.setState(
      { selectedSentenceTime: time }, 
      () => {
        this.props.transcriptActions.setTranscriptTime(time)
    });
    const audioElement = document.getElementById("transcript-audio-player");
    audioElement.currentTime = time;
    audioElement.play(time);
  }

  render() {
    const {
      transcript,
      transcriptSentences,
      classes
    } = this.props;

    return (
      <section>
        <div className="row">
          <div className="col-xs4-4 col-lg-12">
            {transcript && transcript.transcriptName && (
              <TranscriptDetailsCondensed
                transcript={transcript}
                canChangeName={false}
                selectedSentenceTime={this.initialTime}
                handleSentenceTimeChange={this.handleSentenceTimeChange}
              />
            )}
          </div>
        </div>
        <div className={classes.transcriptNoticeContainer}>
        <NoticeContainer displayCode="page_notice" dismissible={true} />
        {transcript && transcript.transcriptName && (
          <TranscriptSentencesContainer
              transcriptId={transcript.transcriptId}
              colClass="col-full-row"
              sentences={transcriptSentences}
              loadTranscriptSentences={this.loadTranscriptSentences}
              showTime={true}
              handleSentenceHighlight={this.handleSentenceHighlight}
              selectedSentenceTime={this.state.selectedSentenceTime}
              canPlaySentences={true}
              handlePlaySentence={this.handlePlaySentence}
              initialSentenceId={parseInt(this.props.history.location.hash?.substring(1),10)}
            />
        )}
        </div>
      </section>
    );
  }
}

TranscriptPodcastPage.propTypes = {
  transcriptActions: PropTypes.object.isRequired,
  noticeActions: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  transcript: PropTypes.object,
  transcriptSentences: PropTypes.array,
  location: PropTypes.object,
  classes: PropTypes.object
};

function mapStateToProps(state) {
  return {
    transcript: transcriptSelectors.getTranscriptDetails(state),
    transcriptSentences: transcriptSelectors.getSentences(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transcriptActions: bindActionCreators(transcriptActions, dispatch),
    noticeActions: bindActionCreators(noticeActions, dispatch)
  };
}


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles((theme) => ({
    transcriptNoticeContainer: {
      marginTop: '147px',
    },

}))
)(TranscriptPodcastPage);