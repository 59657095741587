import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import Notice from 'Components/Common/Notice';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import TranscriptFormChannelDates from './TranscriptFormChannelDates';
import TranscriptFormText from './TranscriptFormText';

import moment from 'moment';

import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

export class TranscriptForm extends Component {

  constructor(props) {
    super(props);

    let fromDate = new Date(Date.now());
    let fromTime = new Date(Date.now());
    fromDate.setHours(fromDate.getHours() - 1);
    fromTime.setHours(fromTime.getHours() - 1);
    let toDate = new Date(Date.now());
    let toTime = new Date(Date.now());

      let radioStations = props.radioStations || [];

      this.config = {
        transcriptTypes: {
          block: {
            type: 'block',
            name: "",
            body: ""
          },
          channel: {
            type: 'channel',
            name: "",
            isLive: false,
            source: props.channelSources[0]?.value || "",
            fromDate: fromDate,
            fromTime: fromTime,
            toDate: toDate,
            toTime: toTime,
            start: "",
          },
          youtube: {
            type: 'youtube',
            name: "",
            url: "",
            locale: 'auto'
          },
          radio: {
            type: 'radio',
            name: "",
            source: radioStations[0]?.value || "",
            fromDate: fromDate,
            fromTime: fromTime,
            toDate: toDate,
            toTime: toTime,
            locale: props.defaultLocale
          },
        }
      };

    this.state = {
      submitted: false,
      transcript: {
        ...this.config.transcriptTypes[props.defaultType]
      }
    };
  }

  combineDateAndTime = (date, time) => {
    date = moment(date).format('YYYY-MM-DD');
    time = moment(time).format('HH:mm:ss');
    let dateTime = moment(date + 'T' + time, 'YYYY/MM/DDT HH:mm:ss').utcOffset(0);
    return dateTime.format('YYYY-MM-DDTHH:mm:ss');
  }

  formatRequestBodyFromState = () => {
    const { transcript } = this.state;

    let data = {
      type: transcript.type,
      name: transcript.name
    };

    switch (transcript.type) {
      case 'channel':
        if(!transcript.isLive) {
          //combine time and date
          if(transcript.fromDate && transcript.fromTime) {
            data.startDate = this.combineDateAndTime(transcript.fromDate, transcript.fromTime);
          }
          if(transcript.toDate && transcript.toTime) {
            data.endDate = this.combineDateAndTime(transcript.toDate, transcript.toTime);
          }
        } 
        data.isLive = transcript.isLive;
        if(transcript.isLive) delete data.startDate;
        data.source = transcript.source;
        break;
      case 'radio':
        if(transcript.fromDate && transcript.fromTime) {
          data.startDate = this.combineDateAndTime(transcript.fromDate, transcript.fromTime);
        }
        if(transcript.toDate && transcript.toTime) {
          data.endDate = this.combineDateAndTime(transcript.toDate, transcript.toTime);
        }
        data.source = transcript.source;
        data.locale = transcript.locale;
        break;
      case 'block':
        if(transcript.body && transcript.body !== "") {
          data.text = transcript.body;
        }
        break;
      case 'youtube':
        data.url = transcript.url;
        data.locale = transcript.locale;
        break;
    }

    return data
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const requestBody = this.formatRequestBodyFromState();
    this.props.handleFormSubmit(requestBody);
    this.setState({ submitted: true });
  }

  setValue = (e) => {
    const field = e.target.name;
    this.setState({
      transcript: {
        ...this.state.transcript, [field]: e.target.value
      }
    });
  }

  setTranscriptType = (e) => {
    this.setState({
      transcript: {
        ...this.config.transcriptTypes[e.target.value],
        name: this.state.transcript.name
      }
    });
  }

  setDateTime = (field, date) => {
    this.setState({
      transcript: {
        ...this.state.transcript, [field]: date
      }
    });
  }


  handleLiveToggle = (e) => {
    let isLive = e.target.checked;
    let fromDate = new Date(Date.now());
    let fromTime = new Date(Date.now());
    fromTime.setSeconds(fromTime.getSeconds() - 5);
    let toDate = new Date(Date.now());
    let toTime = new Date(Date.now());

    if (!isLive) {
      fromDate.setHours(fromDate.getHours() - 1);
      fromTime.setHours(fromTime.getHours() - 1);
    }

    this.setState({
      transcript: {
        ...this.state.transcript,
        isLive: isLive,
        fromDate: fromDate,
        fromTime: fromTime,
        toDate: toDate,
        toTime: toTime
      }
    });
  }

  render() {
    const {
      t,
      noticeError,
      channelSources,
      classes,
      requestState,
      radioStations
    } = this.props;

    const transcriptTypesLabels = {
      channel: 'transcript_form.transcript_types.channel',
      youtube: 'transcript_form.transcript_types.youtube',
      block: 'transcript_form.transcript_types.block',
      radio: 'transcript_form.transcript_types.radio',
    }

    const transcriptTypes = this.props.transcriptTypes.map((type) => ({
      value: type,
      label: t(transcriptTypesLabels[type])
    }));

    const locales = [
      {
        value: 'auto',
        label: t('transcript_form.language.auto_detect'),
      },
      {
        value: 'en',
        label: t('transcript_form.language.en'),
      },
      {
        value: 'fr',
        label: t('transcript_form.language.fr'),
      },
      {
        value: 'ar',
        label: t('user_page.settings.language.ar'),
      }
    ];
    return (
      <form id="create-transcript-form" onSubmit={this.handleSubmit}>
        {noticeError ? (
          <div className={classes.noticeMessage}>
            <Notice
              message={noticeError.message}
              messageKey={noticeError.messageKey}
              type={"error"}
            />
          </div>
        ) : ""}

              <TextField
                type="text"
                name="name"
                fullWidth={true}
                value={this.state.transcript.name}
                onChange={this.setValue}
                label={t('transcript_form.name')}
                inputProps={{ 'aria-label': 'name' }}
                required
              />
              <FormControl fullWidth={true} margin="normal">
                <InputLabel htmlFor="type">{t('transcript_form.type')}</InputLabel>
                <Select
                  value={this.state.transcript.type}
                  onChange={this.setTranscriptType}
                  defaultValue={transcriptTypes[0].value}
                  fullWidth={true}
                  inputProps={{
                    name: 'type',
                    id: 'type',
                  }}
                >
                  {transcriptTypes.map(item => (
                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {(()=>{
                if(this.state.transcript.type === 'channel'){
                  return (
                    <section>
                      <div className={classes.liveField}>
                        <label className={classes.liveToggleLabel} htmlFor="isLive">{t('transcript_form.live_toggle')}</label>
                        <Checkbox
                          checked={this.state.transcript.isLive}
                          className={classes.checkbox}
                          onChange={this.handleLiveToggle}
                          color="primary"
                          value="isLive"
                        />
                      </div>
                      <FormControl fullWidth={true} margin="normal">
                        <InputLabel htmlFor="source">{t('transcript_form.source')}</InputLabel>
                        <Select
                          value={this.state.transcript.source}
                          onChange={this.setValue}
                          fullWidth={true}
                          inputProps={{
                            name: 'source',
                            id: 'source',
                          }}
                        >
                          {channelSources.map(item => (
                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {!this.state.transcript.isLive ? (
                      <TranscriptFormChannelDates
                        isLive={this.state.transcript.isLive}
                        fromDate={this.state.transcript.fromDate}
                        fromTime={this.state.transcript.fromTime}
                        toDate={this.state.transcript.toDate}
                        toTime={this.state.transcript.toTime}
                        setValue={this.setValue}
                        setDateTime={this.setDateTime}
                      />
                      ): ''}
                    </section>
                  );
                } else if(this.state.transcript.type === 'block'){
                  return(
                    <TranscriptFormText
                      body={this.state.transcript.body}
                      setValue={this.setValue}
                      label={t('transcript_form.block.text')}
                    />);
                } else if(this.state.transcript.type === 'youtube'){
                  return(
                    <section>
                      <TextField
                        type="text"
                        name="url"
                        fullWidth={true}
                        value={this.state.transcript.url}
                        onChange={this.setValue}
                        label={t('transcript_form.youtube_video_label')}
                        inputProps={{ 'aria-label': 'url' }}
                        required
                      />
                      <FormControl fullWidth={true} margin="normal">
                        <InputLabel htmlFor="channelSource">{t('transcript_form.language')}</InputLabel>
                        <Select
                          value={this.state.transcript.locale}
                          onChange={this.setValue}
                          fullWidth={true}
                          inputProps={{
                            name: 'locale',
                            id: 'locale',
                          }}
                        >
                          {locales.map(item => (
                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </section>
                  );
                } else if(this.state.transcript.type === 'radio'){
                  return (
                    <section>
                      <FormControl fullWidth={true} margin="normal">
                        <InputLabel htmlFor="source">{t('transcript_form.source')}</InputLabel>
                        <Select
                          value={this.state.transcript.source}
                          onChange={this.setValue}
                          fullWidth={true}
                          inputProps={{
                            name: 'source',
                            id: 'source',
                          }}
                        >
                          {radioStations.map(item => (
                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TranscriptFormChannelDates
                        isLive={this.state.transcript.isLive}
                        fromDate={this.state.transcript.fromDate}
                        fromTime={this.state.transcript.fromTime}
                        toDate={this.state.transcript.toDate}
                        toTime={this.state.transcript.toTime}
                        setValue={this.setValue}
                        setDateTime={this.setDateTime}
                      />
                    </section>
                  );
                }
              })()}
        {requestState === 'pending' ? (
          <div className={classes.button}>
            <Button role="button" className={classes.loadingButton} type="submit" color="primary" disabled variant="contained"><CircularProgress size={20} /></Button>
          </div>
        ) : (
          <div className={classes.button}>
            <Button role="button" type="submit" color="primary" variant="contained">{t('transcript_form.create_button')}</Button>
          </div>
        )}
      </form>
    );
  }
}

TranscriptForm.propTypes = {
  channelSources: PropTypes.array.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  streamAllowance: PropTypes.number,
  t: PropTypes.func,
  types: PropTypes.object,
  noticeError: PropTypes.object,
  i18n: PropTypes.object,
  transcriptTypes: PropTypes.array,
  classes: PropTypes.object,
  defaultType: PropTypes.string,
  requestState: PropTypes.string,
  defaultLocale: PropTypes.string,
  radioStations: PropTypes.array
};

export default compose(
  withStyles(() => ({
    loadingButton: {
      minWidth: '82px',
      height: '36px'
    },
    checkbox: {
      display: 'inline-block',
      marginRight: '6px'
    },
    liveField: {
      marginTop: '12px'
    },
    noticeMessage: {
      marginBottom: '12px'
    },
    fromDate: {
      display: 'inline-block',
      marginRight: '12px'
    },
    toDate: {
      display: 'inline-block',
      marginRight: '12px'
    },
    liveToggleLabel: {
      verticalAlign: 'middle',
      display: 'inline-block'
    },
    button: {
      marginTop: '24px'
    }
  })),
  withTranslation()
)(TranscriptForm);
