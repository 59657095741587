import React from 'react';
import PropTypes from 'prop-types';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js/dist/plotly-basic';
import { useTheme } from '@material-ui/core/styles';

export const ActionChartAverage = (props) => {
  const Plot = createPlotlyComponent(Plotly);
  const theme = useTheme();
  const layoutConfig = {
    margin: { l: 30, r: 10, b: 30, t: 70, pad: 0, x: 0 },
    autosize: true,
    barmode: 'relative',
    plot_bgcolor: theme.palette.background.paper,
    paper_bgcolor: theme.palette.background.paper,
    font: {
      color: theme.palette.text.primary
    },
    legend: {
      x: '0.97',
      y: '1',
      xanchor: 'right',
      yanchor: 'top'
    },
    yaxis : {
      tickInterval: 1,
      tickOptions: {
        formatString: '%d'
      },
      gridcolor: theme.palette.divider,
      zeroline: false
    },
    xaxis : {
      tickInterval: 1,
      gridcolor: theme.palette.divider

    }
  };
  if(props.data[0] && props.data[0].x[0]){
    return (
        <Plot
          data={props.data}
          style={{ width: '100%',  height: '400px', clear: 'both'}}
          useResizeHandler={false}
          layout={layoutConfig}
          config={{
            'displayModeBar': false
          }}
        />
      );
  } else {
    return null;
  }

};

ActionChartAverage.propTypes = {
    data: PropTypes.array.isRequired
};

export default ActionChartAverage;
