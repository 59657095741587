import adminConstants from './adminConstants';
import authConstants from './authConstants';
import candidateConstants from './candidateConstants';
import channelConstants from './channelConstants';
import claimConstants from './claimConstants';
import interventionConstants from './interventionConstants';
import noticeConstants from './noticeConstants';
import transcriptConstants from './transcriptConstants';
import trendConstants from './trendConstants';
import entityConstants from './entityConstants';

const constants = {
  admin: adminConstants,
  auth: authConstants,
  candidate: candidateConstants,
  claims: claimConstants,
  live: channelConstants,
  intervention: interventionConstants,
  notices: noticeConstants,
  transcript: transcriptConstants,
  trends: trendConstants,
  entity: entityConstants
};

export default constants;
