import React from 'react';
import PropTypes from 'prop-types';

const RedirectPage = (props) => {
  props.history.push('/?next='+encodeURIComponent(props.location.pathname + props.location.search));
  return null;
};

RedirectPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};

export default RedirectPage;

