import initialState from './initialState';
import adminConstants from 'Constants/adminConstants';
import * as utils from '../utils';
import { User } from 'Models/User';
import { Media } from 'Models/Media';
import type { ApiMediaType } from 'Models/Media';
import type { ApiUserType } from 'Models/User';

export default function adminState(state = initialState.adminState, action) {

  switch (action.type) {

      case adminConstants.START_EDITING_USER:
        return {
           ...state,
           editingUser: true
        };

      case adminConstants.STOP_EDITING_USER:
        return {
           ...state,
           editingUser: false
        };

      case adminConstants.START_EDITING_MEDIA:
        return {
            ...state,
            editingMedia: true
        };

      case adminConstants.STOP_EDITING_MEDIA:
        return {
            ...state,
            editingMedia: false
        };

      case `${adminConstants.GET_USERS}_SUCCESS`: {
        const formattedUser = action.response.users.map((user: ApiUserType) => {
          return User.fromApi(user)
        });
        const filteredUsers = formattedUser.filter((user) => (
          user.email !== 'afc@fullfact.org' && user.email !== 'afc+fc@fullfact.org'
        ));
        const normalisedUsers = utils.normaliseById(filteredUsers, 'userId');
        return {
           ...state,
           users: {
             isFetching: false,
             items: normalisedUsers
           }
        };
      }
      case `${adminConstants.UPDATE_USER}_PENDING`:
        return {
           ...state,
           users: {
             ...state.users,
             isUpdating: true
           }
        };
      case `${adminConstants.UPDATE_USER}_SUCCESS`:
        return {
           ...state,
           users: {
             ...state.users,
             isUpdating: false
           }
        };

      case `${adminConstants.UPDATE_USER}_FAILURE`:
        return {
           ...state,
           users: {
             ...state.users,
             isUpdating: false
           }
        };

      case `${adminConstants.CREATE_USER}_PENDING`:
        return {
           ...state,
           users: {
             ...state.users,
             isCreating: true
           }
        };

      case `${adminConstants.CREATE_USER}_SUCCESS`:
        return {
           ...state,
           users: {
             ...state.users,
             isCreating: false
           }
        };

      case `${adminConstants.CREATE_USER}_FAILURE`:
        return {
           ...state,
           users: {
             ...state.users,
             isCreating: false
           }
        };

      case `${adminConstants.DELETE_USER}_SUCCESS`:
        let allIds = state.users.items.allIds.slice();
        allIds = allIds.filter(id => id !== action.request.userId);
        let byId = Object.assign(state.users.items.byId, {});
        if (byId[action.request.userId]) {
          delete byId[action.request.userId];
        }

        return {
           ...state,
           users: {
             isFetching: false,
             items: {
               byId: byId,
               allIds: allIds
             }
           }
        };

      case `${adminConstants.GET_ORG_MEDIA}_SUCCESS`: {
        const formattedMedia = action.response.publications.map((media: ApiMediaType) => {
          return Media.fromApi(media)
        });
        const filteredMedia = formattedMedia.filter(pub => pub.mediaCategory === 'online-newspapers' || pub.mediaUrl.startsWith('http'));
        const normalisedMedia = utils.normaliseById(filteredMedia, 'publicationId');
        return {
           ...state,
           organisationMedia: normalisedMedia
        };
      }
      
      default:
        return state;
  }
}
