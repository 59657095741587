/* eslint-disable import/default */

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import Root from './components/Root';
import configureStore, { history } from './configureStore';

export const store = configureStore();

render(
      <Provider store={store}>
        <Root history={history} />
      </Provider>,
  document.getElementById('app')
);
