import React from "react";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import LiveChannelSentences from "./LiveChannelSentences";
import transcriptActions  from 'Actions/transcriptActions';

import authSelectors from 'Selectors/authSelectors';
import liveSelectors from 'Selectors/channelSelectors';
import claimSelectors from 'Selectors/claimSelectors';

export class LiveChannelSentencesContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  createTranscript = (data) => {
    this.props.transcriptActions.createChannelTranscript(data);
  }

  render() {
    const {
      subtitles,
      results,
      options,
      channel,
      user,
      permissions,
      hasClaimDetection
    } = this.props;
    return (
      <LiveChannelSentences
        createTranscript={this.createTranscript}
        subtitles={subtitles}
        results={results}
        options={options}
        channel={channel}
        user={user}
        permissions={permissions}
        hasClaimDetection={hasClaimDetection}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    channel: liveSelectors.getChannel(state),
    subtitles: liveSelectors.getChannelSubtitles(state),
    results: claimSelectors.getMatches(state),
    user: authSelectors.getUser(state),
    permissions: authSelectors.getPermissions(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transcriptActions: bindActionCreators(transcriptActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveChannelSentencesContainer);


LiveChannelSentencesContainer.propTypes = {
  subtitles : PropTypes.array.isRequired,
  results : PropTypes.object.isRequired,
  options :  PropTypes.object,
  channel : PropTypes.object.isRequired,
  transcriptActions: PropTypes.object.isRequired,
  user: PropTypes.object,
  permissions: PropTypes.array,
  hasClaimDetection: PropTypes.bool
};
