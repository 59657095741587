const generalEvents = {
  GET_TRANSCRIPTS: 'GET_TRANSCRIPTS',
  GET_MORE_TRANSCRIPTS: 'GET_MORE_TRANSCRIPTS',
  RECEIVED_SENTENCE: 'RECEIVED_SENTENCE',
  CLEAR_TRANSCRIPT: 'CLEAR_TRANSCRIPT',
  HIGHLIGHT_SENTENCE: 'HIGHLIGHT_SENTENCE',
  UNHIGHLIGHT_SENTENCE: 'UNHIGHLIGHT_SENTENCE',
  MODIFICATION_HIGHLIGHT_SENTENCE: 'MODIFICATION_HIGHLIGHT_SENTENCE',
  MODIFICATION_UNHIGHLIGHT_SENTENCE: 'MODIFICATION_UNHIGHLIGHT_SENTENCE',
  MODIFICATION_NAME_CHANGE: 'MODIFICATION_NAME_CHANGE',
  MODIFICATION_ENRICHMENT: 'MODIFICATION_ENRICHMENT',
  MODIFICATION_UNKNOWN: 'MODIFICATION_UNKNOWN',
  MODIFICATION_METADATA_CHANGE: 'MODIFICATION_METADATA_CHANGE',
  LOAD_TRANSCRIPT_SENTENCES: 'LOAD_TRANSCRIPT_SENTENCES',
  CLOSE_TRANSCRIPT: 'CLOSE_TRANSCRIPT',
  GET_SCHEDULE_TRANSCRIPTS: 'GET_SCHEDULE_TRANSCRIPTS',
  GET_MORE_SCHEDULE_TRANSCRIPTS: 'GET_MORE_SCHEDULE_TRANSCRIPTS',
  GET_LATEST_TRANSCRIPT_SCHEDULES: 'GET_LATEST_TRANSCRIPT_SCHEDULES',
  SET_LIVE_LANDING_VIEW: 'SET_LIVE_LANDING_VIEW',
  GET_ALL_TRANSCRIPTS: 'GET_ALL_TRANSCRIPTS'
};

const blockTranscriptEvents = {
  CREATE_BLOCK_TRANSCRIPT: 'CREATE_BLOCK_TRANSCRIPT',
  GET_BLOCK_TRANSCRIPTS: 'GET_BLOCK_TRANSCRIPTS',
  GET_BLOCK_TRANSCRIPT: 'GET_BLOCK_TRANSCRIPT',
  GET_BLOCK_TRANSCRIPT_SENTENCES: 'GET_BLOCK_TRANSCRIPT_SENTENCES',
  DELETE_BLOCK_TRANSCRIPT: 'DELETE_BLOCK_TRANSCRIPT',
  UPDATE_BLOCK_TRANSCRIPT_NAME: 'UPDATE_BLOCK_TRANSCRIPT_NAME'
};

const channelTranscriptEvents = {
  CREATE_CHANNEL_TRANSCRIPT: 'CREATE_CHANNEL_TRANSCRIPT',
  GET_CHANNEL_TRANSCRIPT: 'GET_CHANNEL_TRANSCRIPT',
  GET_CHANNEL_TRANSCRIPTS: 'GET_CHANNEL_TRANSCRIPTS',
  GET_CHANNEL_TRANSCRIPT_SENTENCES: 'GET_CHANNEL_TRANSCRIPT_SENTENCES',
  UPDATE_CHANNEL_TRANSCRIPT_NAME: 'UPDATE_CHANNEL_TRANSCRIPT_NAME',
  DELETE_CHANNEL_TRANSCRIPT: 'DELETE_CHANNEL_TRANSCRIPT',
  STOP_CHANNEL_TRANSCRIPT: 'STOP_CHANNEL_TRANSCRIPT',
  MODIFICATION_STOP_TRANSCRIPT: 'MODIFICATION_STOP_TRANSCRIPT'
};

const radioTranscriptEvents = {
  GET_RADIO_TRANSCRIPTS: 'GET_RADIO_TRANSCRIPTS',
  GET_RADIO_TRANSCRIPT: 'GET_RADIO_TRANSCRIPT',
  GET_RADIO_TRANSCRIPT_SENTENCES: 'GET_RADIO_TRANSCRIPT_SENTENCES',
  GET_TRANSCRIPT_SCHEDULES: 'GET_TRANSCRIPT_SCHEDULES',
  GET_TRANSCRIPT_SCHEDULE: 'GET_TRANSCRIPT_SCHEDULE',
  CREATE_RADIO_TRANSCRIPT_SCHEDULE: 'CREATE_RADIO_TRANSCRIPT_SCHEDULE',
  GET_TRANSCRIPT_RADIO_STATIONS: 'GET_TRANSCRIPT_RADIO_STATIONS',
  DELETE_TRANSCRIPT_SCHEDULE: 'DELETE_TRANSCRIPT_SCHEDULE',
  START_EDITING_SCHEDULE: 'START_EDITING_SCHEDULE',
  STOP_EDITING_SCHEDULE: 'STOP_EDITING_SCHEDULE',
  UPDATE_TRANSCRIPT_SCHEDULE: 'UPDATE_TRANSCRIPT_SCHEDULE',
  SET_TRANSCRIPT_TIME: 'SET_TRANSCRIPT_TIME',
  GET_RADIO_TRANSCRIPT_URL: 'GET_RADIO_TRANSCRIPT_URL',
  CREATE_RADIO_TRANSCRIPT: 'CREATE_RADIO_TRANSCRIPT'
};

const adhocTranscriptEvents = {
  GET_ADHOC_TRANSCRIPT: 'GET_ADHOC_TRANSCRIPT',
  GET_ADHOC_TRANSCRIPT_SENTENCES: 'GET_ADHOC_TRANSCRIPT_SENTENCES',
  GET_ADHOC_TRANSCRIPTS: 'GET_ADHOC_TRANSCRIPTS',
  DELETE_ADHOC_TRANSCRIPT: 'DELETE_ADHOC_TRANSCRIPT',
  STOP_ADHOC_TRANSCRIPT: 'STOP_ADHOC_TRANSCRIPT',
  UPDATE_ADHOC_TRANSCRIPT_NAME: 'UPDATE_ADHOC_TRANSCRIPT_NAME'
};

const youtubeTranscriptEvents = {
  CREATE_YOUTUBE_TRANSCRIPT: 'CREATE_YOUTUBE_TRANSCRIPT',
  GET_YOUTUBE_TRANSCRIPT: 'GET_YOUTUBE_TRANSCRIPT',
  GET_YOUTUBE_TRANSCRIPT_SENTENCES: 'GET_YOUTUBE_TRANSCRIPT_SENTENCES',
  GET_YOUTUBE_TRANSCRIPTS: 'GET_YOUTUBE_TRANSCRIPTS',
  DELETE_YOUTUBE_TRANSCRIPT: 'DELETE_YOUTUBE_TRANSCRIPT',
  STOP_YOUTUBE_TRANSCRIPT: 'STOP_YOUTUBE_TRANSCRIPT',
  UPDATE_YOUTUBE_TRANSCRIPT_NAME: 'UPDATE_YOUTUBE_TRANSCRIPT_NAME'
};


const podcastTranscriptEvents = {
  GET_PODCAST_TRANSCRIPT: 'GET_PODCAST_TRANSCRIPT',
  GET_PODCAST_TRANSCRIPT_SENTENCES: 'GET_PODCAST_TRANSCRIPT_SENTENCES',
  GET_PODCAST_TRANSCRIPTS: 'GET_PODCAST_TRANSCRIPTS',
  DELETE_PODCAST_TRANSCRIPT: 'DELETE_PODCAST_TRANSCRIPT',
  GET_PODCAST_TRANSCRIPT_URL: 'GET_PODCAST_TRANSCRIPT_URL'
};


const transcriptConstants = {
  ...generalEvents,
  ...blockTranscriptEvents,
  ...channelTranscriptEvents,
  ...radioTranscriptEvents,
  ...adhocTranscriptEvents,
  ...youtubeTranscriptEvents,
  ...podcastTranscriptEvents
};

export default transcriptConstants;

  