import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

function EnhancedTableSearch(props) {
  const {
    filterText,
    filterTextLabel,
    handleFilterTextChange,
    classes
  } = props;

  function filterChange(e){
    handleFilterTextChange(e.target.value);
  }
  return (
    <div>
      <SearchIcon className={classes.searchIcon} />
      <TextField
        className={classes.searchClaimTextField}
        id="standard-name"
        label={filterTextLabel}
        value={filterText}
        onChange={filterChange}
        margin="dense"
      />
    </div>
  );
}

export default withStyles(() => ({
  searchIcon: {
    margin: '0 6px 6px 0 ',
    verticalAlign: 'bottom'
  }
}))(EnhancedTableSearch);

EnhancedTableSearch.propTypes = {
  handleFilterTextChange: PropTypes.func,
  filterText: PropTypes.string,
  filterTextLabel: PropTypes.string,
  classes: PropTypes.object
};