import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import SearchClaim from './SearchClaim';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import InfiniteScroll from 'react-infinite-scroller';

export function SearchResults(props) {
  const {
    classes,
    candidates,
    candidatesCount,
    getMoreCandidates,
    hasMoreCandidates,
    t
  } = props;

  const socialPublications = [
    'Twitter',
    'Twitter Government Departments',
    'Twitter MPs',
    'Twitter Committees',
    'Twitter Parties Campaigns',
    'Twitter Media',
    'Twitter Ad'
  ];

  return (

    <Box><Box>
        <h5 className={classes.resultsCount}>
          {t('candidates_results.number_of_claims_found', { count: candidatesCount })}
        </h5>
        <Box className={classes.clearBoth}/>
      </Box>
      <InfiniteScroll
          pageStart={0}
          loadMore={getMoreCandidates}
          hasMore={hasMoreCandidates}
          loader={<CircularProgress key="infinite-scroll-loader" />}
      >
        {candidates.map((article, i) => {
          if(socialPublications.includes(article.publicationName)) {
            article.authorName = '@'+article.url.split('/')[3];
          }
          return (
            <Box key={i} className={classes.articleBox}>
              <div>
                {article.title && article.title !== '' &&
                  <h3 className={classes.headline}>{article.title}</h3>
                }
                <span className={classes.publicationIcon}>
                  <Tooltip title={article.publicationName}>
                    <img src={'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://' + article.url.split('//').slice(-1)[0].split('/')[0]} />
                  </Tooltip>
                </span>
                <Link className={classes.publicationLink} target="_blank" href={article.url}>
                  {article.category === 'social-media' && article.authorName !== '' ? (
                    <span className={classes.publication}>{article.authorName}</span>
                  ):(
                    <span className={classes.publication}>{article.publicationName}</span>
                  )}
                  <span className={classes.publicationSeparator}> - </span>
                  <span className={classes.publicationDate}>{article.publicationDate}</span>
                  <OpenInNewIcon className={classes.openInNewIcon} />
                </Link>
                <Box m={2}/>
                </div>
                {article.sentences.map((stc, sindex) => {
                  let claimant;
                  let avatar = null;
                  if(stc.claimants?.length) {
                    claimant = {
                      name: stc.claimants[0].name,
                      wikiUrl: stc.claimants[0].link,
                      entityType: stc.claimants[0].entityType
                    };

                    avatar = <Avatar alt={claimant.name} className={classes.img}><BusinessIcon/></Avatar>;
                    if (claimant.entityType === 'PERSON'){
                      avatar = <Avatar alt={claimant.name} className={classes.img}><PersonIcon/></Avatar>;
                    }
                  }
                  return (
                    <div key={sindex + stc.mediaHash} className={classes.claimgrid}>
                      <div>
                      {claimant && (
                        <a className={classes.claimantLink} href={claimant.wikiUrl} target="_blank">
                          <Tooltip title={claimant.name}>
                            {avatar}
                          </Tooltip>
                        </a>
                      )}
                      </div>
                      <div>
                        <div>
                          <SearchClaim
                            claim={stc}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Box>
            );
          }
        )}
      </InfiniteScroll>
    </Box>
  );
}

SearchResults.propTypes  = {
  classes: PropTypes.object,
  candidates: PropTypes.array.isRequired,
  candidatesCount: PropTypes.number,
  hasMoreCandidates: PropTypes.bool,
  getMoreCandidates: PropTypes.func,
  t: PropTypes.func
};

export default compose(
  withTranslation(),
  withStyles((theme) => ({
    articleBox: {
      marginBottom: '18px'
    },
    clearBoth: {
      clear: 'both'
    },
    resultsCount: {
      float: 'left',
      marginBottom: '12px',
      marginTop: '0'
    },
    headline: {
      margin: '0 0 15px 0'
    },
    publication: {
      verticalAlign: 'middle'
    },
    publicationSeparator: {
      verticalAlign: 'middle'
    },
    publicationDate: {
      verticalAlign: 'middle'
    },
    openInNewIcon: {
      width: '15px',
      margin: '-4px 0 0 8px',
      verticalAlign: 'middle'
    },
    wikiURL: {
      display: 'block',
      marginLeft: '10px'
    },
    claimgrid: {
      display: 'grid',
      gridTemplateColumns: 'min-content 1fr',
      alignItems: 'top',
    },
    claimantLink: {
      marginTop: '6px',
      display: 'block',
      marginRight: '12px'
    },
    img: {
      height: '45px',
      width: '45px'
    },
    authorName: {
      fontWeight: 'bold',
      color: '#777',
      fontSize: '14px'
    },
    publicationIcon: {
      margin: '6px 8px 0 0',
      verticalAlign: 'middle',
      display: 'inline-block'
    }
  }))
)(SearchResults);