import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import UserArea from 'Components/User/UserArea';
import Paper from '@material-ui/core/Paper';
import Nav from 'Components/Nav/Nav';
import { withTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CheckworthyAllTopics from 'Components/Checkworthy/CheckworthyAllTopics';
import CheckworthyTopicControls from 'Components/Checkworthy/CheckworthyTopicControls';
import CheckworthyTopicList from 'Components/Checkworthy/CheckworthyTopicList';
import SearchField from 'Components/Search/SearchField';
import SearchResults from 'Components/Search/SearchResults';
import DigestPossibleSightings from 'Components/Digest/DigestPossibleSightings';
import TranscriptTypeOverview from 'Components/Transcript/TranscriptTypeOverview';
import CreateButton from 'Components/Common/CreateButton';
import ActionForm from 'Components/Action/ActionForm';
import ClaimForm from 'Components/Claim/ClaimForm';
import UserForm from 'Components/User/UserForm';
import MediaPublicationsTable from 'Components/Media/MediaPublicationsTable';
import MediaSchedulesTable from 'Components/Media/MediaSchedulesTable';
import authSelectors from 'Selectors/authSelectors';
import RssFeedIcon from '@material-ui/icons/RssFeed';

import HowToTextEn from '../../docs/en/how-to.mdx';
import HowToTextAr from '../../docs/ar/how-to.mdx';
import HowToTextFr from '../../docs/fr/how-to.mdx';
import CheckworthyTextEn from '../../docs/en/home.mdx';
import CheckworthyTextAr from '../../docs/ar/home.mdx';
import CheckworthyTextFr from '../../docs/fr/home.mdx';
import SearchTextEn from '../../docs/en/search.mdx';
import SearchTextAr from '../../docs/ar/search.mdx';
import SearchTextFr from '../../docs/fr/search.mdx';
import AlertsTextEn from '../../docs/en/alerts.mdx';
import AlertsTextAr from '../../docs/ar/alerts.mdx';
import AlertsTextFr from '../../docs/fr/alerts.mdx';
import LiveTextEn from '../../docs/en/live.mdx';
import LiveTextAr from '../../docs/ar/live.mdx';
import LiveTextFr from '../../docs/fr/live.mdx';
import ActionsTextEn from '../../docs/en/actions.mdx';
import ActionsTextAr from '../../docs/ar/actions.mdx';
import ActionsTextFr from '../../docs/fr/actions.mdx';
import AdminTextEn from '../../docs/en/admin.mdx';
import AdminTextAr from '../../docs/ar/admin.mdx';
import AdminTextFr from '../../docs/fr/admin.mdx';

export class DocsPage extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      currentSection: 'howto'
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let sectionHeaders = document.getElementsByClassName('beginSection');
    for (let elm of sectionHeaders) {
      let rect = elm.getBoundingClientRect();
      let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      if(!(rect.bottom < 0 || rect.top - viewHeight >= 0) &&  rect.top <= viewHeight/3) {

        if(elm.id && this.state.currentSection !== elm.id){
          this.setState({currentSection: elm.id })
        }
      }
    }

  }

  render () {
    const {
      classes,
      pages,
      t,
      i18n
    } = this.props;


    const {
      currentSection
    } = this.state;

    let secKeys = [
      'howto', 'checkworthy', 'search', 'alerts',
      'live', 'actions', 'admin'
    ];
    const sections = {
      howto: {
        title: t('nav.how_to_signin'),
        text: {
          en: HowToTextEn,
          ar: HowToTextAr,
          fr: HowToTextFr,
        },
        components: {
          UserArea: () => (
            <Paper style={{
              width: '400px'
            }}>
              <UserArea  handleAreaToggle={()=>{}}/>
            </Paper>
          ),
          Nav: () => (
            <Nav
              user={{}}
              permissions={[]}
              handleLogout={()=> {}}
              authorisedRoutes={[{key:'checkworthy'}, {key:'search'}, {key:'live'}]}
            />
          )
        }
      },
      checkworthy: {
        title: t('nav.home'),
        text: {
          en: CheckworthyTextEn,
          ar: CheckworthyTextAr,
          fr: CheckworthyTextFr,
        },
        components: {
          Checkworthy1: (props) => (
              <CheckworthyAllTopics
                dismissSighting={()=>{}}
                removeReviewedSighting={()=>{}}
                confirmSighting={()=>{}}
                topicMediaSentences={{
                  reviewedMediaHashes: [],
                  topics: [{
                    sentence: { text: props.sentence, url: '', publication: '', mediaHash: '' },
                    code: 'climate',
                    name: t('checkworthy_topics.climate'),
                  }]
                }}
                topicsWithoutSentences={[]}
                topicRoute="#"
              />
          ),
          Checkworthy2: (props) => (
              <>
              <CheckworthyTopicControls
                  checkworthyTopics={[{
                    code: 'health',
                    displayName: t('checkworthy_topics.health')}]}
                  currentTopic="health"
                  handleTopicDateChange={()=>{}}
                  currentTopicDate={'2024-01-01'}
                />
                <CheckworthyTopicList
                  checkworthyTopics={[{
                    code: 'health',
                    displayName: t('checkworthy_topics.health')
                  }]}
                  currentTopic="health"
                  dismissSighting={()=>{}}
                  removeReviewedSighting={()=>{}}
                  confirmSighting={()=>{}}
                  checkworthyArticles={{
                    reviewedMediaHashes: [],
                    articles: [{
                      publication: '',
                      authorName: '',
                      sentences: [{
                        mediaHash: '',
                        text: props.sentence,
                        url: '#',
                      }]
                    }]
                  }}
                />
              </>
            )
        }
      },
      search: {
        title: t('nav.candidates'),
        text: {
          en: SearchTextEn,
          ar: SearchTextAr,
          fr: SearchTextFr,
        },
        components: {
          SearchResults1: (props) => (
            <SearchResults
            candidates={[{
              publicationName: props.publication,
              title: props.title,
              publicationDate: '2025-01-17 11:33:58',
              url: 'https://ai.fullfact.org/docs',
              sentences: [{
                text: props.sentence1,
                url: 'https://ai.fullfact.org/docs'
              },
                {
                  text: props.sentence2,
                  url: 'https://ai.fullfact.org/docs2'
              }]
            }]}
              candidatesCount={2}
              hasMoreCandidates={false}
            getMoreCandidates={()=>{}}
          />
          ),
          SearchResults2: (props) => (
            <SearchResults
              candidates={[{
                publicationName: props.publication,
                title: props.title,
                publicationDate: '2024-01-24 15:22:13',
                url: 'https://ai.fullfact.org/docs',
                sentences: [{
                  text: props.sentence,
                  url: 'https://ai.fullfact.org/docs',
                  claimants: [{
                    name: props.claimant,
                    link: '',
                    entityType: 'PERSON'
                  }]
                }]
            }]}
              candidatesCount={1}
            hasMoreCandidates={false}
            getMoreCandidates={()=>{}}
          />
          ),
          SearchField1: () => (
            <SearchField
              fieldsData=""
              searchText={t('checkworthy_topics.economy')}
              onTextSearch={()=>{}}
              onFilterSearch={()=>{}}
              onFiltersChange={()=>{}}
              onSearchTextChange={()=>{}}
              onAdvancedSearchToggle={()=>{}}
              claimants={[]}
              forceOpenAdvancedSearch={false}
              handleClaimantSearch={()=>{}}
              possibleClaims={[]}
              publications={[]}
            />
          ),
          SearchField2: (props) => (
            <div style={{ paddingBottom: '525px' }}>
              <SearchField
                fieldsData={{
                  hasWords: t('checkworthy_topics.economy'),
                  claimants: [{ value: 'test', label: props.claimant }],
                  publications: [{ value: 'test', label: props.publication }],
                }}
                searchText={t('checkworthy_topics.economy') + ' ' + 'who:"' + props.claimant + '" pub:"' + props.publication + '"'}
                onTextSearch={() => { }}
                onFilterSearch={() => { }}
                onFiltersChange={() => { }}
                onSearchTextChange={() => { }}
                onAdvancedSearchToggle={() => { }}
                claimants={[props.claimant]}
                forceOpenAdvancedSearch={true}
                handleClaimantSearch={() => { }}
                possibleClaims={[]}
                publications={[props.publication]}
            />
            </div>
          ),
          SearchField3: (props) => (
            <SearchField
              fieldsData=""
              searchText={'who:"'+props.claimant+'"'}
              onTextSearch={()=>{}}
              onFilterSearch={()=>{}}
              onFiltersChange={()=>{}}
              onSearchTextChange={()=>{}}
              onAdvancedSearchToggle={()=>{}}
              claimants={[]}
              forceOpenAdvancedSearch={false}
              handleClaimantSearch={()=>{}}
              possibleClaims={[]}
              publications={[]}
            />
          ),
          SearchField4: (props) => (
            <SearchField
              fieldsData=""
              searchText={'pub:"' + props.publication + '" group:"' + props.group + '"'}
              onTextSearch={() => { }}
              onFilterSearch={() => { }}
              onFiltersChange={() => { }}
              onSearchTextChange={() => { }}
              onAdvancedSearchToggle={() => { }}
              claimants={[]}
              forceOpenAdvancedSearch={false}
              handleClaimantSearch={() => { }}
              possibleClaims={[]}
              publications={[props.publication]}
            />
          ),
          SearchField5: () => (
            <SearchField
              fieldsData=""
              searchText={'"' + t('checkworthy_topics.jobs') + '" type:' + t('candidates_search.claim_types.quantity') + ' cat:"' + t('media_categories.online_newspapers') + '" group:SNP when:7d'}
              onTextSearch={() => { }}
              onFilterSearch={() => { }}
              onFiltersChange={() => { }}
              onSearchTextChange={() => { }}
              onAdvancedSearchToggle={() => { }}
              claimants={[]}
              forceOpenAdvancedSearch={false}
              handleClaimantSearch={() => { }}
              possibleClaims={[]}
              publications={[]}
            />
          )
        }
      },
      alerts: {
        title: t('nav.digest'),
        text: {
          en: AlertsTextEn,
          ar: AlertsTextAr,
          fr: AlertsTextFr,
        },
        components: {
          DigestPossibleSightings: (props) => (
            <DigestPossibleSightings
              digestData={[{
                claim: { claim: props.claim },
                claimId: 43999,
                createdAt: "2024-01-31T00:00:00",
                url: "#",
                checkedSentences: [
                  {
                  isConfirmedMatch: true,
                  score: 0.2287358189934144,
                  sentence: {
                    mediaHash: "4d22405c4e3caf0cb83d8ec6ee28358db3341aa370df6c52d4af3f75",
                    publication: props.publication,
                    publicationDate: "2024-02-07T09:33:01",
                    text: props.sentence,
                    url: "#"
                    },
                }]
              }]}
              removeCandidateClaimFromDigest={()=>{}}
              removeMatchedSighting={() => { }}
              addMatchedSighting={() => { }}
              includeFacebookTPFC={false}
            />
          ),
        }
      },
      live: {
        title: t('nav.live'),
        text: {
          en: LiveTextEn,
          ar: LiveTextAr,
          fr: LiveTextFr,
        },
        components: {
          TranscriptTypeOverview: (props) => (
            <TranscriptTypeOverview
              typeTitle={t('live_page.transcripts.channel')}
              code="Channel"
              channels={[]}
              transcripts={[{
                transcriptName: props.name,
                transcriptId: 101,
                createdAt:"2024-02-07T09:33:01",
                createdByDisplayName:"Admin",
                startTime:"2024-02-07T09:33:01",
                endTime:"2024-02-07T09:33:01",
                transcriptType:"channel",
                statistics: {}
              }]}
              count={1}
              handleDeleteTranscript={()=>{}}
              handleFormToggle={()=>{}}
            />
          ),
          CreateButton: () => (
            <CreateButton onClick={()=>{}} />
          )
        }
      },
      actions: {
        title: t('nav.actions'),
        text: {
          en: ActionsTextEn,
          ar: ActionsTextAr,
          fr: ActionsTextFr,
        },
        components: {
          ActionForm: () => (
            <Card className={classes.card}>
              <ActionForm
                handleSubmit={()=>{}}
                intervention={{}}
                suggestedCategories={[]}
                trackedClaims={[]}
                statusTypes={[]}
                actionTypes={[]}
                actionResolutionTypes={[]}
              />
            </Card>
          ),
          CreateButton: () => (
            <CreateButton onClick={()=>{}} />
          )
        }
      },
      admin: {
        title: t('nav.admin'),
        text: {
          en: AdminTextEn,
          ar: AdminTextAr,
          fr: AdminTextFr,
        },
        components: {
          RssFeedIcon: () => (
            <RssFeedIcon />
          ),
          ClaimForm: () => (
            <ClaimForm handleSaveClaim={()=>{}} />
          ),
          CreateButton: () => (
            <CreateButton onClick={()=>{}} />
          ),
          UserForm: () => (
            <Card className={classes.card}>
            <UserForm
              onSubmit={()=>{}}
              roles={[{
                label: t('user_roles.factchecker'), value: 'factchecker'},
                {label: t('user_roles.administrator'), value: 'adminstrator'}]}
            />
            </Card>
          ),
          MediaPublicationsTable: (props) => (
            <MediaPublicationsTable
            addMedia={()=>{}}
            canEdit={true}
            showDeleteMediaDialog={false}
            editMedia={()=>{}}
            organisationMedia={[{
              media_feed: {
                mediaCategory: 'online-newspapers', mediaCreatedAt: '2022-11-15T09:20:37+00:00', mediaIsValid: true, mediaLastScraped: '2024-02-08T14:00:00+00:00', mediaCode: "bbc", mediaFeedId: 77, mediaUrl: "https://ai.fullfact.org/docs"
              }, publicationId: 115, publicationName: props.name, wikidataPublicationId: "Q4704926"
            }]}
          />
          ),
          MediaSchedulesTable: (props) => (
            <MediaSchedulesTable
            addSchedule={()=>{}}
            editSchedule={()=>{}}
            canEdit={true}
            showDeleteScheduleDialog={()=>{}}
            schedules={[{
              created_at: "2023-11-16T15:12:25.772464+00:00",
              created_by_name: "AI Admin",
              days: [1, 2, 3, 4, 5],
              displayRadioStation: props.source,
              displaySchedulePeriod: "Weekly",
              display_name: "Tonight Show",
              end_time: "19:00",
              next_expected_transcript_time: "2024-02-08T18:00:00+00:00",
              publication_id:  1195,
              recent_transcripts: [],
              scheduleName:  props.name,
              start_time: "18:00",
              timezone: props.timezone,
              updated_at: "2023-11-16T15:12:25.772464+00:00",
              updated_by_name: "AI Admin",
              uuid: "0c0308e6-a214-4649-9894-d586c07befac"
            }]}
          />
          )
        }
      }
    }

    const sectionKeys = secKeys.filter(key => pages.includes(key) || key == 'howto' || key == 'admin');
    return (
      <div className="row container">
        <div className="cli-xs4-4">
        <div className={classes.grid + ' ' + classes.docsContainer}>
          <div className={classes.nav}>
          <MenuList>
            {sectionKeys.map(key => (
              <MenuItem key={key}>
                <a href={"#"+key} className={currentSection === key && classes.selected} onClick={()=> {window.scrollTo(0, 0);}}>
                  {sections[key].title}
                </a>
              </MenuItem>
            ))}
          </MenuList>
          </div>
          <div className={classes.content}>
            {sectionKeys.map((key,i) => (
              <>
              <Typography variant="h3" component="h3" id={key} className={i !== 0 ? 'beginSection ' + classes.sectionHeader : "beginSection"}>{sections[key].title}</Typography>
              {sections[key].text[i18n.language]({components: sections[key].components})}
              </>
              ))}
          </div>
        </div>
      </div>
      </div>
    );
  }
}

DocsPage.propTypes = {
  t: PropTypes.func,
  themeClior: PropTypes.string,
  locale: PropTypes.string,
  authActions: PropTypes.object,
  classes:  PropTypes.object,
  canChangeTheme: PropTypes.bool,
  canChangeLanguage: PropTypes.bool,
  pages: PropTypes.array,
  i18n: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return {
    pages: authSelectors.getPagePermissions(state),
  };
}

export default compose(
  connect(
    mapStateToProps,
    null
  ),
  withTranslation(),
  withStyles((theme) => ({
    shortWidth: {
       width: '400px'
    },
    grid: {
      marginTop: '24px'

    },
    '@media screen and (min-width: 800px)': {
      nav: {
        position: 'fixed',
        width: '250px',
        padding: '0 12px 12px 12px',
        '& a': {
          color: theme.palette.primary[500],
          textDecoration: 'none'
        }
      },
      content: {
        marginLeft: '300px',
        marginRight: '34px',
        paddingTop: '10px'
      }
    },
    sectionHeader: {
      paddingTop: '100px',
      marginTop: '-90px'
    },
    list: {
      padding: '6px 0 6px 46px',
      '& li': {
        marginBottom: '12px'
      }
    },
    card: {
      padding: '12px'
    },
    selected: {
      fontWeight: 'bold',
      textDecoration: 'underline !important'
    },
    docsContainer: {
      '& a': {
          color: theme.palette.primary[500],
          textDecoration: 'none'
      },
      '& h4' : {
        color: '#555',
        fontSize: '20px',
        fontStyle: 'italic',
        fontFamily: 'Roboto, Arial, sans-serif',
        fontWeight: '400',
        lineheight: '1.235',
        marginBottom: '12px'
      },
      '& h5' : {
        color: '#000',
        fontSize: '18px',
        fontFamily: 'Roboto, Arial, sans-serif',
        fontWeight: 'bold',
        lineHeight: '1.334',
        marginBottom: '12px'
      },
      '& img' : {
        maxWidth: '700px'
      },
      '& td' : {
        padding: '10px',
        border: '1px solid #666'
      }
    }
}))
)(DocsPage);
