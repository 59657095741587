import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import ActionForm from './ActionForm';
import interventionActions  from 'Actions/interventionActions';
import claimSelectors from 'Selectors/claimSelectors';
import interventionSelectors from 'Selectors/interventionSelectors';
import sightingSelectors from 'Selectors/sightingSelectors';
import moment from 'moment';

export class ActionFormContainer extends Component {

  constructor(props) {
      super(props);

      this.state = {
        errorNotice: null
      };
  }

  handleInterventionSubmit = (intervention) => {
    this.setState({
      errorNotice: null
    });

    if(intervention && intervention.intervention_id){
      this.handleUpdateIntervention(intervention);
    } else {
      this.handleCreateIntervention(intervention);
    }
  }

  handleUpdateIntervention = (intervention) => {
    let interventionWithoutId = { ...intervention };
    if(interventionWithoutId.publication_date) {
      interventionWithoutId.publication_date = moment(interventionWithoutId.publication_date).locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');
    }
    if(interventionWithoutId.intervened_at) {
      interventionWithoutId.intervened_at = moment(interventionWithoutId.intervened_at).locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');
    }
    if(interventionWithoutId.resolved_at) {
      interventionWithoutId.resolved_at = moment(interventionWithoutId.resolved_at).locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');
    }
    delete interventionWithoutId.intervention_id;

    this.props.updateIntervention(intervention.intervention_id, interventionWithoutId);

  }

  handleCreateIntervention = (intervention) => {
    let newIntervention = { ...intervention };
    if(newIntervention.publication_date) {
      newIntervention.publication_date = moment(newIntervention.publication_date).locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');
    }
    if(newIntervention.intervened_at) {
      newIntervention.intervened_at = moment(newIntervention.intervened_at).locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');
    }
    if(newIntervention.resolved_at) {
      newIntervention.resolved_at = moment(newIntervention.resolved_at).locale('en').utc().format('YYYY-MM-DDTHH:mm:ss');
    }
    if(newIntervention.media_hash) {
      delete newIntervention.author_name;
      delete newIntervention.category;
      delete newIntervention.publication;
      delete newIntervention.publication_date;
      delete newIntervention.text;
      delete newIntervention.url;
    }

    this.props.createIntervention(newIntervention);
  }

  render() {
    const {
      intervention,
      trackedClaims,
      sightingCategories,
      actionTypes,
      actionResolutionTypes,
      actionStatuses,
      t
    } = this.props;
    return (
      <ActionForm
        handleSubmit={this.handleInterventionSubmit}
        intervention={intervention}
        trackedClaims={trackedClaims}
        suggestedCategories={sightingCategories}
        statusTypes={actionStatuses.map(x => ({ value: x.value, label: t(x.label)}))}
        actionTypes={actionTypes.map(x => ({ value: x.value, label: t(x.label)}))}
        actionResolutionTypes={actionResolutionTypes.map(x => ({ value: x.value, label: t(x.label)}))}
        errorNotice={this.state.errorNotice}
      />
    );
  }
}

ActionFormContainer.propTypes = {
  createIntervention: PropTypes.func,
  updateIntervention: PropTypes.func,
  intervention: PropTypes.object,
  trackedClaims: PropTypes.array,
  t: PropTypes.func,
  i18n: PropTypes.object,
  actionTypes: PropTypes.array,
  actionResolutionTypes: PropTypes.array,
  actionStatuses: PropTypes.array,
  sightingCategories: PropTypes.array
};

function mapStateToProps(state) {
  return {
    trackedClaims: claimSelectors.getClaims(state),
    actionTypes: interventionSelectors.getActionTypes(state),
    actionStatuses: interventionSelectors.getActionStatuses(state),
    actionResolutionTypes: interventionSelectors.getActionResolutionTypes(state),
    sightingCategories: sightingSelectors.getSightingCategoriesList(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createIntervention: bindActionCreators(interventionActions.createIntervention, dispatch),
    updateIntervention: bindActionCreators(interventionActions.updateIntervention, dispatch)
  };
}

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ActionFormContainer);
