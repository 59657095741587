import authSelectors from 'Selectors/authSelectors';
import * as moment from 'moment';
import { ArticleType, SentenceType } from 'Models/Article';

function getPublicationName(state, pub) {
  let publication = pub;
  let pubId = state.candidateState.publications.mediaCodeMap[publication];
  if (pubId) publication = state.candidateState.publications.byId[pubId].publication_name;
  return publication;
}

function getIsInteresting(topicFeedback, mediaHash) {
  let isInteresting;
  let review = topicFeedback.filter(review => review.media_hash === mediaHash);
  if (review && review[0]) {
    if (review[0].feedback === 1) {
      isInteresting = true;
    } else if (review[0].feedback === -1) {
      isInteresting = false;
    }
  }
  return isInteresting;
}

export function getCandidates(state) {
  let enrichedItems = [];
  const config = authSelectors.getConfig(state);
  let response = state.candidateState.candidates?.articles
  if (response) {
    enrichedItems = state.candidateState.candidates.articles.map((article) => {
      return {
        ...article,
        publicationName: getPublicationName(state, article.publicationCode),
        publicationDate: moment.utc(article.publicationDate).local().format(config.dateTimeFormat)
      };
    });
  }
  return enrichedItems;
}


export function getCandidatesRequestState(state) {
  return state.candidateState.candidates.requestState;
}

export function getDigestData(state) {
  let sentencesByClaimId = {};
  const { matchedClaimsById } = state.candidateState.candidates;
  const { reviewedSightings } = state.claimState;
  state.candidateState.candidates.articles.forEach((article: ArticleType) => {
    article.sentences.forEach((stc: SentenceType) => {
      if (stc.text.length < 300) {
        stc.claimsMatched.forEach(claim => {
          let reviewedSentenceIsMatched;
          let claimMatchThreshold = getClaimMatchThreshold(state)
          if (claim.score >= claimMatchThreshold) {
            let reviewedSightingsForClaim = reviewedSightings.byClaimId[claim.trackedClaimId];
            if (reviewedSightingsForClaim && reviewedSightings.byClaimId[claim.trackedClaimId].byMediaHash[stc.mediaHash]) {
              reviewedSentenceIsMatched = reviewedSightings.byClaimId[claim.trackedClaimId].byMediaHash[stc.mediaHash].is_matched;
            }

            if (reviewedSentenceIsMatched !== false && matchedClaimsById) {
              if (!sentencesByClaimId[claim.trackedClaimId + '']) {
                sentencesByClaimId[claim.trackedClaimId + ''] = {
                  claim: matchedClaimsById[claim.trackedClaimId],
                  checkedSentences: []
                };
              }
              if (sentencesByClaimId[claim.trackedClaimId + ''].checkedSentences.filter(checkedStc => checkedStc.mediaHash === stc.mediaHash) < 1) {
                sentencesByClaimId[claim.trackedClaimId + ''].checkedSentences.push({
                  sentence: { ...stc },
                  isConfirmedMatch: reviewedSentenceIsMatched || false,
                  score: claim.score,
                  publication: getPublicationName(state, article.publicationName),
                  favicon: article.favicon
                });
              }
            }
          }
        });
      }
    });
  });

  let claims = Object.values(sentencesByClaimId);
  claims = claims.map(claim => {
    claim.checkedSentences.sort(function (a, b) {
      if (a.score > b.score) return -1;
      if (a.score < b.score) return 1;
      return 0;
    });
    return claim;
  });


  claims.sort(function (a, b) {
    if (a.claim.createdAt > b.claim.createdAt) return -1;
    if (a.claim.createdAt < b.claim.createdAt) return 1;
    return 0;
  });

  claims = claims.map((claim) => {
    let filteredClaimSentences = [...claim.checkedSentences];
    let pubTitles = [];
    let pubMediaHashes = [];
    filteredClaimSentences.forEach((stc) => {
      if (!pubTitles.includes(stc.publication + '_' + stc.text)) {
        pubTitles.push(stc.publication + '_' + stc.text);
        pubMediaHashes.push(stc.mediaHash);
      }
    });
    filteredClaimSentences = claim.checkedSentences.filter(stc => pubMediaHashes.includes(stc.mediaHash));

    return {
      ...claim,
      checkedSentences: [
        ...filteredClaimSentences
      ]
    };
  });

  claims = claims.filter(claim => claim.checkedSentences.length > 0);
  return claims || [];

}

export function getCandidatesCount(state) {
  return state.candidateState.candidates.fullCount;
}


export function getCandidatesByClaimer(state) {
  return state.candidateState.candidatesByClaimer.items;
}

export function getCandidatesByClaimerCount(state) {
  return state.candidateState.candidatesByClaimer.fullCount;
}

export function getTopics(state) {
  return state.candidateState.topics.items.map(topic => {
    return {
      value: topic, label: topic
    };
  });
}

export function getTrendingClaimants(state) {
  let claimants = [];

  if (state.candidateState.trendingClaimants.items.length) {
    claimants = state.candidateState.trendingClaimants.items;
  }

  return claimants.map(claimant => {
    return {
      text: claimant.claimant, count: claimant.count
    };
  });
}

// TODO Get this data from the API
export function getClaimants(state) {
  let claimants = { "claimants": [] };
  claimants = claimants.claimants;

  // Remap the array to a format suitable for CandidatesSearch
  claimants = state.candidateState.claimants.items.map(claimant => {
    return { value: claimant, label: claimant };
  });

  return claimants;
}

export function getClaimTypes(state) {
  const config = authSelectors.getConfig(state);
  let options = [];
  for (const key of Object.keys(config.claimTypes)) {
    options.push({ value: key, label: config.claimTypes[key] });
  }
  return options;
}

export function getPublications(state) {
  if (state.candidateState.publications.all.length) {
    return state.candidateState.publications.all.map(pubId => state.candidateState.publications.byId[pubId].publication_name);
  }
  return [];
}

export function getMediaCategories(state) {
  const config = authSelectors.getConfig(state);
  let options = [];
  for (const key of Object.keys(config.mediaCategories)) {
    if (config.monitoredMedia.includes(key)) options.push({ value: key, label: config.mediaCategories[key] });
  }
  return options;
}

export function getClaimantGroups(state) {
  let options = [];
  const clamantGroups = state.authState.config.claimantGroups;
  if (clamantGroups) {
    for (const key of Object.keys(clamantGroups)) {
      options.push({ value: key, label: clamantGroups[key] });
    }
  }
  return options;
}

export function isGettingCandidates(state) {
  return state.candidateState.candidates.requestState === 'pending';
}

export function isGettingMoreCandidates(state) {
  return state.candidateState.moreCandidates.requestState === 'pending';
}

export function hasReceivedCandidates(state) {
  return state.candidateState.candidates.requestState === 'success';
}

export function hasReceivedCandidatesExact(state) {
  return state.candidateState.candidatesExact.requestState === 'success';
}

export function hasMoreCandidates(state) {
  return Boolean(getNextCursor(state));
}

export function getNextCursor(state) {
  return state.candidateState.candidates.cursor;
}

export function getCompletedAnnotationTasksCount(state) {
  let annotationCount = '0';
  if (state.candidateState.completedAnnotationTasksCount) {
    annotationCount = state.candidateState.completedAnnotationTasksCount.toLocaleString();
  }
  return annotationCount;
}

export function getCheckworthySentences(state, topic) {
  let articles = [];
  let hashes = [];
  let reviewedMediaHashes = {};
  let topicResponse;

  if (topic && state.candidateState.topics.topics && state.candidateState.topics.topics[topic]) {
    topicResponse = state.candidateState.topics.topics[topic];
    if (topicResponse.articles && topicResponse.reviewedMedia) {
      topicResponse.articles.forEach(article => {
        if (hashes.length < 21) {
          article.sentences.forEach((stc) => {
            if (hashes.length < 21 && !hashes.includes(stc.url + stc.text)) {
              hashes.push(stc.url + stc.text);
              reviewedMediaHashes[stc.mediaHash] = getIsInteresting(topicResponse.reviewedMedia, stc.mediaHash)
              articles.push({
                ...article,
                sentences: [{
                  ...stc,
                  publicationName: getPublicationName(state, stc.publicationName)
                }]
              })
            }
          });
        }
      });
    }
  }
  return {
    articles: articles,
    reviewedMediaHashes: reviewedMediaHashes
  };
}

export function getRequestState(state, subState) {
  return state.candidateState[subState].requestState;
}

export function getClaimMatchThreshold(state) {
  if (state.authState.config?.thresholds) {
    return state.authState.config?.thresholds.claimMatch
  } else {
    const config = authSelectors.getConfig(state);
    return config.thresholds.claimMatch;
  }
}

export function getCheckworthyTopics(state) {
  return Object.keys(state.candidateState.topics.displayNames);
}


export function getCheckworthyDisplayNames(state) {
  return state.candidateState.topics.displayNames;
}


export function getCheckworthyTopicSummaries(state) {
  let topics = [];
  let topicCodes = getCheckworthyTopics(state);
  let reviewedMediaHashes = {};

  if (topicCodes) {
    topicCodes.sort();
    topicCodes.forEach((code) => {
      const checkworthyResponse = state.candidateState.topics.topics[code];
      const displayName = state.candidateState.topics.displayNames[code];
      let uniqueSentence;
      let favicon;
      if (checkworthyResponse && checkworthyResponse.articles) {
        for (let i = 0; i < checkworthyResponse.articles.length; i++) {
          let article = checkworthyResponse.articles[i];
          for (let j = 0; j < article.sentences.length; j++) {
            {
              let sentence = article.sentences[j];
              let isInteresting = getIsInteresting(checkworthyResponse.reviewedMedia, sentence.mediaHash);
              if (isInteresting === false || isInteresting === true) {
                reviewedMediaHashes[sentence.mediaHash] = isInteresting;
              }
              if (!uniqueSentence && (isInteresting !== false)) {
                uniqueSentence = {
                  ...sentence,
                  publicationName: getPublicationName(state, article.publicationName)
                };
                favicon = article.favicon;
              }
            }
          }
        }
      }
      if (uniqueSentence) {
        topics.push({
          displayName: displayName,
          code: code,
          sentence: uniqueSentence,
          favicon: favicon
        });
      }
    });
  }

  return {
    topics,
    reviewedMediaHashes
  };
}

export function getCheckworthyTopicsWithoutSentences(state) {
  const allTopics = getCheckworthyTopics(state);
  const topicWithData = getCheckworthyTopicSummaries(state);
  let topics = [];
  allTopics.forEach((code) => {
    if (!(topicWithData.topics.filter(data => data.code === code).length > 0)) {
      const displayName = state.candidateState.topics.displayNames[code];
      topics.push({
        displayName: displayName,
        code: code,
      });
    }
  });
  return topics;
}


export default ({
  getCandidates,
  getCandidatesCount,
  getClaimants,
  getTrendingClaimants,
  getCandidatesByClaimer,
  getCandidatesByClaimerCount,
  getTopics,
  getClaimTypes,
  getPublications,
  isGettingCandidates,
  hasReceivedCandidates,
  getMediaCategories,
  isGettingMoreCandidates,
  hasMoreCandidates,
  getNextCursor,
  getClaimantGroups,
  getDigestData,
  hasReceivedCandidatesExact,
  getCompletedAnnotationTasksCount,
  getCandidatesRequestState,
  getCheckworthySentences,
  getRequestState,
  getClaimMatchThreshold,
  getCheckworthyDisplayNames,
  getCheckworthyTopics,
  getCheckworthyTopicSummaries,
  getCheckworthyTopicsWithoutSentences,
});
