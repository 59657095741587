import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import interventionActions from 'Actions/interventionActions';
import claimActions from 'Actions/claimActions';
import interventionSelectors from 'Selectors/interventionSelectors';
import ActionSummary from 'Components/Action/ActionSummary';

export class ActionPage extends React.Component {

  constructor(props){
    super(props);
    this.props.interventionActions.getIntervention(props.match.params['actionId']);
    this.props.claimActions.loadClaims();
  }

  render() {
    const {
      action,
      classes
    } = this.props;
      return (
        <div className={classes.content}>
          {action ? (
            <ActionSummary action={action} />
          ): ''}
        </div>
      );
  }
}

ActionPage.propTypes = {
  claim: PropTypes.object,
  match: PropTypes.object.isRequired,
  claimActions: PropTypes.object,
  interventionActions: PropTypes.object,
  noticeActions: PropTypes.object,
  classes: PropTypes.object,
  matchedSightings: PropTypes.array,
  actions: PropTypes.array,
  t: PropTypes.object,
  isEditingClaim: PropTypes.bool,
  actionId: PropTypes.string,
  action: PropTypes.object
};


function mapStateToProps(state, ownProps) {
  const actionId = ownProps.match.params['actionId'];
  return {
    action: interventionSelectors.getFormattedInterventionById(state, actionId)
  };
}


function mapDispatchToProps(dispatch) {
  return {
    interventionActions: bindActionCreators(interventionActions, dispatch),
    claimActions: bindActionCreators(claimActions, dispatch),
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles((theme) => ({
    content: {
      marginTop: '24px',
      margin: '0 auto',
      '& a': {
        color: theme.palette.primary[500]
      }
    },
}))
)(ActionPage);
